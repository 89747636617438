import React from "react";
import { useCheckMobileScreen } from "../hooks";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { selectUser } from "../store/slices/authenticationSlice";
import { UserRoles } from "../enums";

function PublicRoute({ component: Component, ...rest }) {
  const user = useSelector(selectUser);
  const location = useLocation();
  const orderBeingPaidForId = localStorage.getItem('orderBeingPaidForId')
  const areWeOnLoginPage = location.pathname.includes('/auth/login');

  const isMobile = useCheckMobileScreen();

  if (user && orderBeingPaidForId && areWeOnLoginPage)
    return <Redirect to={`/order/onboardedpayment/${orderBeingPaidForId}`} />;

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          isMobile ?
            MobileUserRoleToRedirectMapper[user.role]
            :
            UserRoleToRedirectMapper[user.role]
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PublicRoute;


const UserRoleToRedirectMapper = {
  [UserRoles.Patient]: <Redirect to="/prescriptions/active" />,
  [UserRoles.Carer]: <Redirect to="/prescriptions/active" />,
  [UserRoles.OrganisationEmployee]: <Redirect to="/clinicPrescriptions" />,
}

const MobileUserRoleToRedirectMapper = {
  [UserRoles.Patient]: <Redirect to="/prescriptions/active" />,
  [UserRoles.Carer]: <Redirect to="/prescriptions/active" />,
  [UserRoles.OrganisationEmployee]: <Redirect to="/upload/prescriber" />,
}
