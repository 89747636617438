import { apiClient } from "../ApiClient";
import { productConverter } from "../converters/ProductsConverter";

class ProductService {

  async getProductsForClinic(filter = "", organisationId, organisationEmployeeId) {
    const products = await apiClient.getAllProductsForClinicUser(filter, organisationId, organisationEmployeeId);
    const convertedProducts = productConverter.productsApiToClient(products);
    return convertedProducts.reverse()
  }

  async getProductsForPatient(filter = "") {
    const products = await apiClient.getAllProductsForPatient(filter);
    const convertedProducts = productConverter.patientProductsApiToClient(products);
    return convertedProducts.reverse()
  }

  async getAllTerpenes() {
    const terpenes = await apiClient.getAllTerpenes();
    return terpenes
  }

  async getAllSuppliers(organisationId) {
    const suppliers = await apiClient.getAllSuppliers(organisationId);
    return suppliers
  }

  async getAllSuppliersForPatient() {
    const suppliers = await apiClient.getAllSuppliersForPatient();
    return suppliers
  }

  async getCurrentProductImages(productId, organisationId) {
    const images = await apiClient.getAllProductImagesById(productId, organisationId);
    return images
  }

  async getCurrentProductDocs(productId, organisationId) {
    const docs = await apiClient.getAllProductDocsById(productId, organisationId);
    return docs
  }

  async getCurrentProductImagesForPatient(productId) {
    const images = await apiClient.getAllProductImagesByIdForPatient(productId);
    return images
  }

  async getCurrentProductDocsForPatient(productId) {
    const docs = await apiClient.getAllProductDocsByIdForPatient(productId);
    return docs
  }
}

export const productService = new ProductService();
