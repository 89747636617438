import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TertiaryCta } from "../../../components/buttons";
import { setIsT21Patient } from "../../../store/slices/doctorOnboardingSlice";
import { trackAnalyticsEvent } from "../../../util/util";

function T21(props) {
  const { setNextStep } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onClick(isT21) {
    dispatch(setIsT21Patient(isT21));

    trackAnalyticsEvent("Doctor Onboarding", `Is Prescription T21: ${isT21}`);
    setNextStep(props.steps.doctorPatientName);
  }

  return (
    <section className="t21">
      <div className="t21__content">
        <div className="t21__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Is this a T21 patient?</h1>
        </div>

        <div className="t21__buttons">
          <div>
            <TertiaryCta
              color="white"
              onClick={() => onClick(true)}
              text="Yes"
            />
          </div>
          <div>
            <TertiaryCta
              color="white"
              onClick={() => onClick(false)}
              text="No"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default T21;
