import React from 'react'
import { useLocation } from 'react-router'
import { AttentionIcon } from '../../../assets/icons'
import GenericBanner from '../../banners/GenericBanner/GenericBanner'

const PaymentFormOrderedProducts = ({ order }) => {
    const location = useLocation();
    const areWeOnGhostPaymentPage = location.pathname.includes('/order/payment/');

    const constructRows = () => {
        return order.orderedProducts.map((p, i) => {
            return <div key={i} className='payment_form_ordered_products__table__body__row' >
                <div className='payment_form_ordered_products__table__body__row__cell payment_form_ordered_products__table__body__row__cell--left table-col-8' >
                    {p.name}
                </div>
                <div className='payment_form_ordered_products__table__body__row__cell payment_form_ordered_products__table__body__row__cell--center table-col-8' >
                    {p.amount}
                </div>
                <div className='payment_form_ordered_products__table__body__row__cell payment_form_ordered_products__table__body__row__cell--right table-col-8' >
                    £ {p.price}
                </div>
            </div>
        })
    }
    return (
        <div className='payment_form_ordered_products' >
            <div className='payment_form_ordered_products__title m-b-s' >
                Order summary
            </div>
            {areWeOnGhostPaymentPage &&
                <div className='m-b-s'>
                    <GenericBanner
                        text={`Please note, due to data protection you will not see the names of the products you are purchasing.
                        If you would like to see your full order details, please sign up for the secure patient account.`
                        }
                        icon={<AttentionIcon color={'#3B5EDB'} />}
                    />
                </div>
            }

            <div className='payment_form_ordered_products__table' >
                <div className='payment_form_ordered_products__table__header' >
                    <div className='payment_form_ordered_products__table__header__cell payment_form_ordered_products__table__header__cell--left table-col-8' >
                        product
                    </div>
                    <div className='payment_form_ordered_products__table__header__cell payment_form_ordered_products__table__header__cell--center table-col-8' >
                        amount
                    </div>
                    <div className='payment_form_ordered_products__table__header__cell payment_form_ordered_products__table__header__cell--right table-col-8' >
                        price
                    </div>

                </div>

                <div className='payment_form_ordered_products__table__body' >
                    {constructRows()}
                </div>

            </div>
            <div className='payment_form_ordered_products__total'>
                <div className='payment_form_ordered_products__total__row'>
                    <span>Sub-total:</span> <span> &nbsp; £ {order?.amount}</span>
                </div>
                <div className='payment_form_ordered_products__total__row'>
                    <span>Shipping:</span> <span > &nbsp; £ {order?.shipmentCost}</span>
                </div>
                <div className='payment_form_ordered_products__total__row'>
                    <span>Total:</span> <span> &nbsp; £ {(order?.amount + order?.shipmentCost).toFixed(2)}</span>
                </div>
            </div>

        </div>
    )
}

export default PaymentFormOrderedProducts
