import React from "react";

function ClinicsBottomBar({ contentRight, contentLeft, className = "" }) {
  return (
    <div className={`clinic_bottom_bar ${className}`} >
      <div className={`clinic_bottom_bar__left`}>
        {contentLeft}
      </div>
      <div className={`clinic_bottom_bar__right`}>
        {contentRight}
      </div>
    </div>
  );
}

export default ClinicsBottomBar;