import React from "react";

function GreenCircle2(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#13ae7d" />
      <path
        d="M15.1136 25.398C17.0576 24.066 18.6116 22.764 19.7756 21.492C20.9516 20.208 21.5396 19.02 21.5396 17.928C21.5396 17.28 21.3776 16.764 21.0536 16.38C20.7416 15.996 20.2316 15.804 19.5236 15.804C18.7916 15.804 18.2396 16.044 17.8676 16.524C17.4956 16.992 17.2856 17.664 17.2376 18.54H15.2396C15.3236 17.088 15.7676 15.978 16.5716 15.21C17.3876 14.442 18.4256 14.058 19.6856 14.058C20.9216 14.058 21.8876 14.388 22.5836 15.048C23.2916 15.696 23.6456 16.596 23.6456 17.748C23.6456 18.672 23.3576 19.608 22.7816 20.556C22.2176 21.504 21.4976 22.38 20.6216 23.184C19.7456 23.976 18.8396 24.66 17.9036 25.236H23.9336V26.91H15.1136V25.398Z"
        fill="black"
      />
    </svg>
  );
}

export default GreenCircle2;
