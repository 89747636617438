import React from "react";

import Tooltip from "./Tooltip";

function TableRow({ rowData, onItemEdit = null, onItemDelete = null }) {
	function resolveTextClass(item) {
		return item.color ? `colored ${item.color}` : "";
	}

	function resolveRowClass(item) {
		// .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
		return `table-col-${item.column * 2} ${item.right ? "right" : ""}`;
	}

	function renderRowData(rowData) {
		return rowData.map((item, index) => {
			if (item.href) {
				return (
					<td key={index} className={resolveRowClass(item)}>
						<a href={item.href} className={resolveTextClass(item)}>
							{item.text}
						</a>
					</td>
				);
			} else if (item.actions) {
				// Update/Delete actions
				return (
					<td key={index} className={resolveRowClass(item)}>
						{onItemEdit && (
							<span className="table-item-action" title="Edit" onClick={() => onItemEdit(item.actions.identificator)}>
								<i className="icon-pencil"></i>
							</span>
						)}
						{onItemDelete && (
							<span className="table-item-action" title="Cancel" onClick={() => onItemDelete(item.actions.identificator)}>
								<i className="icon-close"></i>
							</span>
						)}
					</td>
				);
			} else {
				// Regular text
				return (
					<td key={index} className={resolveRowClass(item)}>
						<span className={resolveTextClass(item)}>{item.text}</span>

						{item.tooltip && <Tooltip data={item.tooltip} />}
					</td>
				);
			}
		});
	}

	return <tr>{renderRowData(rowData)}</tr>;
}

export default TableRow;
