import React, { useEffect, useState } from "react";
import OrderPaymentCard from "../../cards/OrderPaymentCard/OrderPaymentCard";
import { AttentionIcon } from "../../../assets/icons";
import Spinner2 from "../../sharedComponents/Spinner/Spinner2";

function LoadingOrderToBePaidForm(props) {
  const [showDelay, setShowDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowDelay(true);
    }, 10000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="loading_order_to_be_paid_form">
      <OrderPaymentCard>
        <div className="loading_order_to_be_paid_form__content">


          <div className="loading_order_to_be_paid_form__content__text">
            Your order is loading
          </div>

          <div className="loading_order_to_be_paid_form__content__spinner">
            <Spinner2 color={"#00a372"} width={"60"} />
          </div>
          {
            showDelay &&
            <div className="loading_order_to_be_paid_form__content__delay">
              <div className="loading_order_to_be_paid_form__content__delay__title">
                <AttentionIcon /> &nbsp; Sorry for the delay
              </div>
              <div className="loading_order_to_be_paid_form__content__delay__text">
                Apologies for the delay opening the payment portal. If the page is taking a while, please call us on  <a href="tel:020 8194 6638">020 8194 6638</a> or email: <a href="mailto: info@lyphe.com">
                  info@lyphe.com
                </a>
              </div>

            </div>

          }

        </div>
      </OrderPaymentCard>
    </div>
  );
}

export default LoadingOrderToBePaidForm;