import React from "react";

function GreenCircle3(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#13ae7d" />
            <path d="M15.2003 17.118C15.2723 16.002 15.6983 15.132 16.4783 14.508C17.2703 13.884 18.2783 13.572 19.5023 13.572C20.3423 13.572 21.0683 13.722 21.6803 14.022C22.2923 14.322 22.7543 14.73 23.0663 15.246C23.3783 15.762 23.5343 16.344 23.5343 16.992C23.5343 17.736 23.3363 18.372 22.9403 18.9C22.5443 19.428 22.0703 19.782 21.5183 19.962V20.034C22.2263 20.25 22.7783 20.646 23.1743 21.222C23.5703 21.786 23.7683 22.512 23.7683 23.4C23.7683 24.108 23.6063 24.738 23.2823 25.29C22.9583 25.842 22.4783 26.28 21.8423 26.604C21.2063 26.916 20.4443 27.072 19.5563 27.072C18.2603 27.072 17.1923 26.742 16.3523 26.082C15.5243 25.41 15.0803 24.45 15.0203 23.202H17.0003C17.0483 23.838 17.2943 24.36 17.7383 24.768C18.1823 25.164 18.7823 25.362 19.5383 25.362C20.2703 25.362 20.8343 25.164 21.2303 24.768C21.6263 24.36 21.8243 23.838 21.8243 23.202C21.8243 22.362 21.5543 21.768 21.0143 21.42C20.4863 21.06 19.6703 20.88 18.5663 20.88H18.0983V19.188H18.5843C19.5563 19.176 20.2943 19.014 20.7983 18.702C21.3143 18.39 21.5723 17.898 21.5723 17.226C21.5723 16.65 21.3863 16.194 21.0143 15.858C20.6423 15.51 20.1143 15.336 19.4303 15.336C18.7583 15.336 18.2363 15.51 17.8643 15.858C17.4923 16.194 17.2703 16.614 17.1983 17.118H15.2003Z" fill="black" />
        </svg>

    );
}

export default GreenCircle3;
