import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectResubmitModal,
  setResubmitModal,
} from "../../../store/slices/modalsSlice";
import { PrimaryCta } from "../../buttons";
import { VerificationCard } from "../../cards";
import { FullScreenOverlay } from "../../overlays";

function ResubmitPopup(props) {
  const dispatch = useDispatch();

  const resubmitModal = useSelector(selectResubmitModal);

  function onClose() {
    dispatch(setResubmitModal({ show: false, text: "", title: "" }));
  }

  return (
    <FullScreenOverlay show={resubmitModal.show}>
      <VerificationCard
        cardTitle={resubmitModal.title}
        cardText={resubmitModal.text}
        onClick={onClose}
      >
        <div className="h-40">
          <PrimaryCta text="Close" onClick={onClose} />
        </div>
      </VerificationCard>
    </FullScreenOverlay>
  );
}

export default ResubmitPopup;
