import React, { useState } from "react";

// import { isUKPostcode } from "../../../util/validators";
import { AddressForm } from "..";
import { AutoComplete } from "../../inputs";

import { addressService } from "../../../services/AddressService";

function AddressAutoCompleteForm({
  autoComplete,
  address1,
  address2,
  zipCode,
  country,
  filter,
}) {
  // this function will be set on change of autocomplete input
  const [timeoutFunction, setTimeoutFunction] = useState(null);
  const [options, setOptions] = useState([]);

  /**
   * Set value for autoComplete field
   * and set a timeout to get the addresses
   * from the API
   * @param {*} e
   */
  function onChange(e) {
    const searchText = e.target.value;
    autoComplete.setValue(searchText);

    // remove old timeout function if present
    // and set a new one with new input
    if (timeoutFunction) clearTimeout(timeoutFunction);
    setTimeoutFunction(setTimeout(() => getAddress(searchText), 300));
  }

  /**
   * Gets addresses for a given postcode.
   * Processes the response and sets the state
   * Called onChange after a timeout of 300ms.
   */
  async function getAddress(searchText) {
    try {
      const addressOptions = await addressService.getAddress(searchText);

      setOptions(addressOptions);
    } catch (error) {
      setOptions([]);
    }
  }

  function onOptionClick(key) {
    const address = options[key];
    address1.setValue(address.addressLine1);
    address2.setValue(address.addressLine2);
    zipCode.setValue(address.zipCode);
    if (country) {
      country.setValue(address.country);
    }
  }

  function onRemove() {
    address1.reset();
    address2.reset();
    zipCode.reset();
    autoComplete.reset();
    if (country) {
      country.reset();
    }
    setOptions([]);
  }

  return (
    <>
      <div style={{ paddingBottom: "40px" }}>
        <AutoComplete
          name="Enter postcode"
          {...autoComplete}
          onChange={onChange}
          options={Object.keys(options)}
          onClick={onOptionClick}
          filter={filter}
          onRemove={onRemove}
        />
      </div>
      <div className="contact_information__line">
        <hr />
        <p className="label--caps">or</p>
        <hr />
      </div>
      <div className="contact_information__manually">
        <p className="p4-a">Enter your details manually</p>
      </div>

      <AddressForm address1={address1} address2={address2} zipCode={zipCode} country={country} />
    </>
  );
}

export default AddressAutoCompleteForm;
