import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { usePasswordInput, useProps } from "../../../hooks";
import { notEmptyValidator } from "../../../util/validators";
import { changePassword, selectAccountLoading } from "../../../store/slices/accountSlice";
import { isFormValid, propsToValues } from "../../../util/util";
import { PasswordInputField } from "../../../components/inputs";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";
import { ValidationErrorMessages } from "../../../enums";
import InputValidationBulletPoints
  from "../../../components/inputs/InputValidationBulletPoints/InputValidationBulletPoints";

function ChangingPassword({ setNextStep, steps }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectAccountLoading);

  const oldPassword = useProps("", notEmptyValidator("Current password"));
  const [
    newPassword,
    confirmNewPassword,
    checkIfPasswordsMatch,
  ] = usePasswordInput();
  const form = { oldPassword, newPassword };

  const isButtonEnabled = isFormValid(form) && confirmNewPassword.isValid() && !loading;

  async function clickedNext() {
    try {
      checkIfPasswordsMatch();
      await setNewPassword();

      setNextStep(steps.success);
    } catch (error) { }
  }

  async function setNewPassword() {
    const payload = propsToValues(form);
    const { error } = await dispatch(changePassword(payload));

    if (error) {
      oldPassword.setErrors(["Password is incorrect"]);
      throw new Error("Password is incorrect");
    }
  }

  return (
    <section className="changing_password">
      <div className="changing_password__content">
        <div className="changing_password__title">
          <p className="label--caps--normal">Account Security</p>
          <h1 className="h3-a">Changing your password</h1>
          <p className="p5">
            Your password must be a minimum of 8 characters and contain at least
            1 uppercase and 1 special character.
          </p>
        </div>

        <form className="changing_password__form">
          <div className="form-spacing">
            <PasswordInputField
              {...oldPassword}
              name="Current password"
              type="password"
            />
          </div>

          <div className="form-spacing p-t-s">
            <PasswordInputField
              {...newPassword}
              name="New password"
              type="password"
            />
          </div>

          <PasswordInputField
            {...confirmNewPassword}
            name="Re-enter new password"
            type="password"
          />
        </form>

        <InputValidationBulletPoints errors={newPassword.errors} value={newPassword.value} points={passwordBulletPoints} />
        <div className="changing_password__button m-t-s">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={clickedNext}
            text={loading ? <Spinner /> : "Confirm"}
          />
        </div>
      </div>
    </section>
  );
}

export default ChangingPassword;

const passwordBulletPoints = [
  {
    text: "Minimum 8 characters",
    errorString: ValidationErrorMessages.PasswordLength,
  },
  {
    text: "One number",
    errorString: ValidationErrorMessages.PasswordAtLeastOneNumber,
  },
  {
    text: "One uppercase character",
    errorString: ValidationErrorMessages.PasswordCapitalLetter,
  },
  {
    text: "One special character",
    errorString: ValidationErrorMessages.PasswordAtLeastOneSymbol,
  },
  {
    text: "One lowercase character",
    errorString: ValidationErrorMessages.PasswordLowercaseLetter,
  },
  {
    text: "No space characters",
    errorString: ValidationErrorMessages.PasswordContainsASpace,
  },
]
