import React from "react";

function RedDangerCircle(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#FF0C0C" />
      <path
        d="M17.2128 8.896L16.9428 18.022H15.2148L14.9448 8.896H17.2128ZM17.2668 19.732V22H14.9268V19.732H17.2668Z"
        fill="white"
      />
    </svg>
  );
}

export default RedDangerCircle;
