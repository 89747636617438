import React, { useEffect } from "react";
import { TertiaryCta } from "../../../components/buttons";
import { useDispatch } from "react-redux";
import { setRelationship } from "../../../store/slices/carerOnboardingSlice";
import { trackAnalyticsEvent } from "../../../util/util";

function CarerRelationshipToPatient(props) {
  const dispatch = useDispatch();
  const { setNextStep } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function nextClicked(relationship) {
    dispatch(setRelationship(relationship));

    trackAnalyticsEvent("Carer Onboarding", "Relationship with Patient Selected");
    setNextStep(props.steps.carerPatientsName);
  }
  return (
    <section className="is_over_18">
      <div className="is_over_18__content">
        <div className="is_over_18__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">What is your relationship to them?</h1>
          <p className="p5">
            Please tell us what your relationship to the patient.
          </p>
        </div>

        <div className="is_over_18__buttons">
          <div>
            <TertiaryCta
              color="white"
              onClick={() => nextClicked("Parent")}
              text="Parent"
            />
          </div>
          &nbsp;&nbsp;
          <div>
            <TertiaryCta
              color="white"
              onClick={() => nextClicked("Guardian")}
              text="Guardian"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CarerRelationshipToPatient;
