import React from 'react'

function RepeatPrescritpionCompletedIcon() {
    return (
        <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.7462 46.9191C29.9652 46.1381 28.6989 46.1381 27.9178 46.9191C27.1368 47.7002 27.1368 48.9665 27.9178 49.7475L30.7462 46.9191ZM44.332 63.3333L42.9178 64.7475L44.332 66.1618L45.7462 64.7475L44.332 63.3333ZM74.0796 36.4142C74.8606 35.6332 74.8606 34.3668 74.0796 33.5858C73.2985 32.8047 72.0322 32.8047 71.2511 33.5858L74.0796 36.4142ZM27.9178 49.7475L42.9178 64.7475L45.7462 61.9191L30.7462 46.9191L27.9178 49.7475ZM45.7462 64.7475L74.0796 36.4142L71.2511 33.5858L42.9178 61.9191L45.7462 64.7475Z" fill="#51C378" />
            <path d="M90 50.5C90 72.327 72.5274 90 51 90C29.4726 90 12 72.327 12 50.5C12 28.673 29.4726 11 51 11C72.5274 11 90 28.673 90 50.5Z" stroke="#51C378" stroke-width="2" />
        </svg>
    )
}
export default RepeatPrescritpionCompletedIcon
