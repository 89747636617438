import React from "react";
import ProductDetails from "../ProductDetails/ProductDetails";

function ProductDescription(props) {
  return (
    <div
      className={`product_description ${props.showBody ? "product_description--show" : ""
        }`}
    >
      <div className="product_description__text">
        <p className="p5">{props.description}</p>
      </div>
      <ProductDetails content={props.productDetailsBottom} column />
    </div>
  );
}

export default ProductDescription;
