import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MagnifyingGlass from '../../assets/icons/MagnifyingGlass';
import XIcon from '../../assets/icons/XIcon';
import SpinnerGrey from '../sharedComponents/Spinner/SpinnerGrey';
import { selectFromProductSliceByKey, setPatientSearchInputValue, resetProductsForPatient, getProductsForPatient } from "../../store/slices/productSlice";

const PatientProductSearch = ({
    onChange = () => null,
    placeholder = "Search",
    onKeyChange = () => null,
    loading = false,
}) => {
    const dispatch = useDispatch()
    const [focused, setFocused] = useState(false);
    const inputValue = useSelector(selectFromProductSliceByKey("patientSearchInputValue"));

    const handleOnChange = (e) => {
        onChange(e);
        dispatch(setPatientSearchInputValue(e.target.value));
    }

    const handleOpen = () => {
        setFocused(true);
    }

    const handleClose = () => {
        setFocused(false);
        setTimeout(() => {
        }, 300);
    }

    const handleClear = () => {
        dispatch(setPatientSearchInputValue(""));

        dispatch(resetProductsForPatient())
        dispatch(getProductsForPatient({ query: "" }))

        setFocused(true);
    }

    return (
        <div>
            <div onBlur={() => handleClose()} className={`search_field ${focused ? "search_field--focused" : ""}`}>
                <label>
                    {loading && <SpinnerGrey />}
                    <input
                        placeholder={placeholder}
                        value={inputValue}
                        onFocus={() => handleOpen()}
                        onKeyDown={(value) => onKeyChange(value)}
                        className="search_field__input"
                        type="text"
                        onChange={(e) => handleOnChange(e)}
                    />
                    {inputValue &&
                        <div className="search_field__icon" onClick={() => { handleClear() }} >
                            <XIcon />
                        </div>
                    }
                    {!inputValue &&
                        <div className="search_field__icon">
                            <MagnifyingGlass />
                        </div>
                    }
                </label>
            </div>
        </div>
    )
}

export default PatientProductSearch
