import React from "react";

import { AttentionIcon } from "../../../assets/icons";

function NoticeCard({
  titleTop = "",
  textTop = "",
  titleBottom = "",
  textBottom = "",
}) {
  return (
    <div className="notice_card">
      <div className="notice_card__top">
        <div className="notice_card__header">
          <AttentionIcon />
          <p className="p3--semi-bold">{titleTop}</p>
        </div>
        <div
          className={`notice_card__text--${titleBottom ? "padding" : "no-padding"
            } p5`}
        >
          {textTop}
        </div>
      </div>

      {titleBottom && (
        <div className="notice_card__bottom">
          <p className="label--caps--semi-bold">{titleBottom}</p>
          <p className="p6">{textBottom}</p>
        </div>
      )}
    </div>
  );
}

export default NoticeCard;
