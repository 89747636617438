import React from "react";
import { useDispatch } from "react-redux";

import { setPrescriptionModal } from "../../../store/slices/modalsSlice";
import pdfPlacehodler from '../../../assets/images/pdf_placeholder.jpg'

/**
 * This card is used to display a prescription.
 * It will take all available height and width.
 * A parent component can control its size.
 *
 * imageName should be imageUrl because that is an url not a name
 */
function PrescriptionCard({
  title = "",
  imageName = "",
  image = null,
  onClick = () => null,
  actionName = "",
}) {
  const dispatch = useDispatch();

  function onPreview() {
    dispatch(
      setPrescriptionModal({
        show: true,
        image: image,
      })
    );
  }
  return (
    <div className="prescription_card">
      <div className="prescription_card__title">
        <p className="p3--semi-bold">{title}</p>
        <p onClick={onClick} className="text_link">
          {actionName}
        </p>
      </div>

      <div className="prescription_card__body">
        {imageName && imageName.substring(imageName.lastIndexOf('.')) === '.pdf' && <img src={pdfPlacehodler} alt="" />}
        {imageName && imageName.substring(imageName.lastIndexOf('.')) !== '.pdf' && <img src={image} alt="" />}
        {/* <img src={image} alt=""/> */}
        <div>
          <p className="label--caps--normal">FILENAME</p>
          <p className="p5--normal">{imageName}</p>
          <p onClick={onPreview} className="text_link">
            Preview
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrescriptionCard;
