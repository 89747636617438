import React from "react";

function FilterIcon(props) {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.2 0.149902C5.4 0.149902 4 1.3499 3.6 3.0499H0.9C0.4 3.0499 0 3.3499 0 3.8499C0 4.3499 0.4 4.6499 0.9 4.6499H3.6C4 6.6499 6 7.9499 8 7.4499C9.4 7.1499 10.4 6.0499 10.7 4.7499H21.2C21.7 4.7499 22.1 4.3499 22.1 3.9499C22.1 3.5499 21.7 3.0499 21.2 3.0499H10.8C10.4 1.3499 8.9 0.149902 7.2 0.149902ZM7.2 5.8499C6.1 5.8499 5.2 4.9499 5.2 3.8499C5.2 2.7499 6.1 1.8499 7.2 1.8499C8.3 1.8499 9.2 2.7499 9.2 3.8499C9.2 4.9499 8.3 5.8499 7.2 5.8499Z"
        fill="black"
      />
      <path
        d="M15 8.44995C13.3 8.44995 11.8 9.64995 11.4 11.35H0.9C0.4 11.35 0 11.65 0 12.15C0 12.65 0.4 12.95 0.8 12.95H11.3C11.8 14.95 13.8 16.15 15.8 15.65C17.2 15.35 18.2 14.25 18.5 12.95H21.2C21.7 12.95 22 12.55 22 12.05C22 11.55 21.6 11.25 21.2 11.25H18.5C18.2 9.64995 16.7 8.44995 15 8.44995ZM15 14.15C13.9 14.15 13 13.25 13 12.15C13 11.05 13.9 10.15 15 10.15C16.1 10.15 17 11.05 17 12.15C17 13.25 16.1 14.15 15 14.15Z"
        fill="black"
      />
    </svg>
  );
}

export default FilterIcon;
