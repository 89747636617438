import React from "react";


function PostDatedBanner({ text }) {
  return (
    <div className="postDated_banner">
      <div className="label--smaller--caps--poppins">
        Post-dated prescriptions ({text})
      </div>
    </div>
  );
}

export default PostDatedBanner;