import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoticeBanner, SplitOrderBanner } from "../../banners";
import { PrimaryCta } from "../../buttons";
import { VerticalSeparator } from "../../../components";

import {
  selectShowTracker,
  setShowMobileTracker,
  setTrackingOrderIndex,
  selectTrackedOrderIndex,
  selectActivePrescriptions,
} from "../../../store/slices/prescriptionsSlice";


import {
  MinusIcon,
  PlusIcon,
  GreenCircle1,
  GreenCircle2,
  GreenCircle3,
  GreenCircle4,
  GreenCircle5,
} from "../../../assets/icons";
import moment from "moment";

function ProcessingFutureDatedPrescription({ order, allOrders = [] }) {
  const dispatch = useDispatch();

  const trackedOrderIndex = useSelector(selectTrackedOrderIndex);
  const showMobileTracker = useSelector(selectShowTracker);
  const activePrescriptions = useSelector(selectActivePrescriptions);

  const [show, setShow] = useState(false);

  const buttonClassName = `processing_order__details_btn ${show ? "processing_order__details_btn--active" : ""
    }`;

  const buttonContent = show ? (
    <>
      <span className="p5--semi-bold">Hide next steps</span>
      <MinusIcon />
    </>
  ) : (
    <>
      <span className="p5--semi-bold">View next steps</span>
      <PlusIcon />
    </>
  );

  function handleTrackOrderClick() {
    dispatch(setTrackingOrderIndex(order.index));
    dispatch(setShowMobileTracker(true));
  }

  const isPrescriptionTracked = trackedOrderIndex === order.index;

  const ordersForThisPrescriptionId = allOrders.filter((_order) => _order.prescriptionId === order.prescriptionId);
  const splitOrders = ordersForThisPrescriptionId.filter((_order) => _order.isSplit);
  const numberOfSplitOrders = splitOrders.length;
  const indexOfSplitOrder = splitOrders.findIndex(_order => _order.orderId === order.orderId) + 1;

  return (
    <>
      <div className="processing_order">
        <div className="processing_order__header">
          <div className={isPrescriptionTracked ? "processing_order__header__top__tracked" : "processing_order__header__top"}>
            <h2 className="h5-a--serif">Post-dated prescription received</h2>
            <div className="desktop_order__header__details">
              <div className="desktop_order__header__details__left">
                <div className='product_details--row'>
                  <div>
                    <span className="p6--semi-bold ">PRESCRIPTION UPLOADED: {order.createdAt} </span>
                  </div>
                  <div>
                    <span className="p6--semi-bold">PRESCRIBED DATE: {moment(order.prescribedDateTime).format("DD/MM/YYYY")}</span> {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p style={{ maxWidth: "100%" }} className="p5">
            We have received a post-dated prescription from your clinic. Once the presribed date {moment(order.prescribedDateTime).format("DD/MM/YYYY")} is reached, we will start processing it.
          </p>
        </div>

        <div
          className={`processing_order__body ${show ? "processing_order__body--show" : ""
            }`}
        >
          <h3 className="h8-a m-b-s">What happens now?…</h3>
          <ol className="processing_order__list">
            <li className="processing_order__item">
              <div className="number_image">
                <GreenCircle1 />
              </div>
              <div className="processing_order__item__text">
                <p className="p3--semi-bold">Receive Paper Copy</p>
                <p className="p5">
                  Your Doctor will send us a paper copy of your prescription, we will only be able to dispense your medication once we have received this.
                </p>
              </div>
            </li>
            <li className="processing_order__item">
              <div className="number_image">
                <GreenCircle2 />
              </div>
              <div className="processing_order__item__text">
                <p className="p3--semi-bold">Order and Pack</p>
                <p className="p5">
                  Once we have your prescription paper copy, we will order your medication and prepare it to be dispensed.
                </p>
              </div>
            </li>
            <li className="processing_order__item">
              <div className="number_image">
                <GreenCircle3 />
              </div>
              <div className="processing_order__item__text">
                <p className="p3--semi-bold">Make Payment</p>
                <p className="p5">
                  When your medication is ready, we will send you an email to let you know it is ready so that you can make the payment.
                </p>
              </div>
            </li>
            <li className="processing_order__item">
              <div className="number_image">
                <GreenCircle4 />
              </div>
              <div className="processing_order__item__text">
                <p className="p3--semi-bold">Dispense &amp; Send</p>
                <p className="p5">
                  You will have 7 days to pay for your medication, once you have made a payment it will be shipped to you. We will not be able to hold your medication for longer than 7 days.
                </p>
              </div>
            </li>
            <li className="processing_order__item">
              <div className="number_image">
                <GreenCircle5 />
              </div>
              <div className="processing_order__item__text">
                <p className="p3--semi-bold">Tracking &amp; Delivery</p>
                <p className="p5">
                  Following payment, your medication will be dispensed, and you will receive an email with your tracking details.
                </p>
              </div>
            </li>
          </ol>
        </div>

        <div className="processing_order__banner">
          <NoticeBanner text="You will be notified by email when your prescription is ready" />
        </div>

        {
          order.isSplit && (
            <div className="processing_order__banner">
              <SplitOrderBanner numberOfSplitOrders={numberOfSplitOrders} splitOrderIndex={indexOfSplitOrder} />
            </div>
          )
        }
      </div>
    </>
  );
}

export default ProcessingFutureDatedPrescription;
