import React, { useRef, useLayoutEffect } from "react";

function SidePreview({ show, onClose = () => null, onClick = () => null, children }) {

  const handleOnClick = (e) => {
    onClick(e)
    onClose();
  }

  const ref = useRef();

  useLayoutEffect(() => {

    const transformElement = () => {
      const parrentHeight = ref.current.parentElement.getBoundingClientRect().height
      ref.current.style.height = `${parrentHeight}px`
    }
    transformElement()
    window.addEventListener("resize", transformElement)

    return () => {
      window.removeEventListener("resize", transformElement)
    }
  }, [children]);

  return (
    <div
      ref={ref}
      onClick={(e) => handleOnClick(e)} className={`side_preview ${show ? "side_preview--show" : ""}`}>
      {children}

    </div>
  );
}

export default SidePreview;