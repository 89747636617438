import React from "react";
import CustomSelect from "../CustomSelect/CustomSelect";

function AgeSelect(props) {
  const {
    props: { dayProps, yearProps, monthProps },
  } = props;

  const {
    options: { dayOptions, yearOptions, monthOptions },
  } = props;

  return (
    <div className="age_select">
      <div className="age_select__content form-spacing">
        <div>
          <CustomSelect {...dayProps} name="Day" options={dayOptions} />
        </div>
        <div>
          <CustomSelect {...monthProps} name="Month" options={monthOptions} />
        </div>

        <div>
          <CustomSelect {...yearProps} name="Year" options={yearOptions} />
        </div>
      </div>
      <div className="age_select__error">
        <p className="label">{props.error}</p>
      </div>
    </div>
  );
}

export default AgeSelect;
