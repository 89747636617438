import React from 'react'
import { Point } from '../../../assets/icons';

const InputValidationBulletPoints = ({ value, errors, points }) => {
    const half = Math.ceil(points.length / 2);

    const firstHalf = points.slice(0, half)
    const secondHalf = points.slice(half, points.length)

    const resolveClassForPoint = (point) => {
        if (!value) return `inputValidationBulletPoints__col__point inputValidationBulletPoints__col__point--invalid`
        return `inputValidationBulletPoints__col__point ${errors.includes(point.errorString) ? "inputValidationBulletPoints__col__point--invalid" : "inputValidationBulletPoints__col__point--valid"}`
    }

    const resolvePointColor = (point) => {
        if (!value) return `#D5D5D5`;
        return errors.includes(point.errorString) ? "#D5D5D5" : "#13ae7d"
    }
    return (
        <div className="inputValidationBulletPoints">
            <div className='inputValidationBulletPoints__col'>
                {firstHalf.map((point, index) => {
                    return <div key={index} className={resolveClassForPoint(point)}> <Point color={resolvePointColor(point)} /> <div className={'m-l-s'}>{point.text}</div> </div>
                })}
            </div>
            <div className='inputValidationBulletPoints__col'>
                {secondHalf.map((point, index) => {
                    return <div key={index} className={resolveClassForPoint(point)}> <Point color={resolvePointColor(point)} /> <div className={'m-l-s'}>{point.text}</div> </div>
                })}
            </div>
        </div>
    )
}

export default InputValidationBulletPoints
