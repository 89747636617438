import React, { useEffect } from "react";
import { PrimaryCta } from "../../components/buttons";
import { AuthHeader } from "../../components/headers";

function PasswordResetSuccess(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <AuthHeader hideClose={true} />
      <div className="auth_wrapper">
        <section className="auth">
          <div className="auth__header">
            <h1 className="h4-p-a">Password changed</h1>
            <p className="p5">You can now log in with your new password</p>
          </div>
          <div className="auth__buttons">
            <div className="h-48">
              <PrimaryCta text="Continue to login" link="/auth/login" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PasswordResetSuccess;
