import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  loginUser,
  selectLoginLoading,
} from "../../store/slices/authenticationSlice";
import { AuthHeader } from "../../components/headers";
import { emailValidator, notEmptyValidator } from "../../util/validators";
import { propsToValues, isFormValid } from "../../util/util";
import { InputField, PasswordInputField } from "../../components/inputs";
import { PrimaryCta } from "../../components/buttons";
import { useProps } from "../../hooks";
import Spinner from "../../components/sharedComponents/Spinner/Spinner";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { UserRoles } from "../../enums";

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const referrer = location.state?.referrer || '/'; // Default to root if no referrer

  const loading = useSelector(selectLoginLoading);

  const email = useProps("", emailValidator());
  const password = useProps("", notEmptyValidator("Password"));
  const form = { email, password };

  const isEnabled = !loading && isFormValid(form);

  async function signInClicked() {
    email.setValue(email.value.toLowerCase().trim());

    const credentials = propsToValues(form);

    try {
      const action = await dispatch(loginUser(credentials));

      if (action.payload) {
        const { user } = action.payload;

        //allow custom redirects when visiting specific pages
        if (user.role == UserRoles.Patient || user.role == UserRoles.Carer) {
          if (referrer === '/repeatPrescription') {
            history.push('/repeatPrescription');
          }
        } else {
          // Redirect to the default landing page or other desired route
          history.push('/');
        }
      }
      if (action.error) {
        email.setErrors([errorMessage]);
        password.setErrors([errorMessage]);
      }
    }
    
    catch {
      email.setErrors([errorMessage]);
      password.setErrors([errorMessage]);
    }
  }

  // once error messages have been set for wrong credentials
  // remove both messages when user changes email or password
  useEffect(() => {
    if (
      email.errors[0] === errorMessage ||
      password.errors[0] === errorMessage
    ) {
      email.setErrors([]);
      password.setErrors([]);
    }
    // eslint-disable-next-line
  }, [email.value, password.value]);

  function onEnter() {
    if (!isEnabled) return;

    signInClicked();
  }

  return (
    <>
      <AuthHeader hideClose={true} />

      <div className="auth_wrapper">
        <section className="auth">
          <div className="auth__header">
            <h1 className="h4-p-a">Login</h1>
            <p className="p5">Please sign in to your account</p>
          </div>
          <form className="auth__form">
            <div className="form-spacing">
              <InputField
                id="email"
                {...email}
                type="email"
                name="Enter your email"
                onEnter={onEnter}
              />
            </div>
            <div>
              <PasswordInputField
                id="password"
                {...password}
                type="password"
                name="Enter your password"
                onEnter={onEnter}
              />
            </div>
          </form>
          <div className="auth__buttons">
            <div className="h-48">
              <PrimaryCta
                isEnabled={isEnabled}
                onClick={signInClicked}
                text={loading ? <Spinner /> : "Sign in"}
              />
            </div>
            <div>
              <Link className="text_link" to="/auth/resetPassword">
                Forgot your password?
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;

const errorMessage = "Email or password are incorrect";
