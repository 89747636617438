import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { UserDetailsCard } from "../../../components/cards";
import { useProps, useAgeSelect } from "../../../hooks";
import { AgeSelect } from "../../../components/inputs";
import {
  NameForm,
  ContactNumberForm,
  AddressForm,
} from "../../../components/forms";

import {
  selectUpdatingCarerDetails,
  selectUserDetails,
  updateCarerDetails,
} from "../../../store/slices/accountSlice";
import { AccountHeader } from "../../../components/headers";
import { AccountFooter } from "../../../components/footers";
import { selectUser } from "../../../store/slices/authenticationSlice";
import {
  addressValidator,
  areaCodeValidator,
  nameValidator,
  phoneNumberValidator,
  zipCodeValidator,
} from "../../../util/validators";
import ChangePasswordCard from "../../../components/cards/ChangePasswordCard/ChangePasswordCard";
import { isFormValid, propsToValues } from "../../../util/util";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";

function CarerProfile(props) {
  const dispatch = useDispatch();

  const carer = useSelector(selectUserDetails);
  const user = useSelector(selectUser);
  const updatingCarerDetails = useSelector(selectUpdatingCarerDetails);

  const carerCardContent = getCarerDetails(carer);
  const patientCardContent = getPatientDetails(carer);

  const [editingCarerDetails, setEditingCarerDetails] = useState(false);
  const [editingPatientDetails, setEditingPatientDetails] = useState(false);

  // CARER
  const title = useProps(carer?.title);
  const firstName = useProps(carer?.firstName, nameValidator("First name"));
  const lastName = useProps(carer?.lastName, nameValidator("Last name"));
  const nameForm = { title, firstName, lastName };

  const [
    carerAgeConfig,
    carerAgeValues,
    carerAgeError,
    resetCarerAge,
  ] = useAgeSelect(
    carer?.dateOfBirth.day,
    carer?.dateOfBirth.month,
    carer?.dateOfBirth.year
  );

  const areaCode = useProps(carer?.contactNumber.areaCode, areaCodeValidator());
  const number = useProps(carer?.contactNumber.number, phoneNumberValidator());
  const numberForm = { areaCode, number };
  const onlyNumberForm = { number };

  // PATIENT
  const patientTitle = useProps(carer?.patientTitle);
  const patientFirstName = useProps(
    carer?.patientFirstName,
    nameValidator("First name")
  );
  const patientLastName = useProps(
    carer?.patientLastName,
    nameValidator("Last name")
  );
  const patientNameForm = { patientTitle, patientFirstName, patientLastName };

  const address1 = useProps(
    carer?.address1,
    addressValidator("Address line 1")
  );
  const address2 = useProps(
    carer?.address2,
    addressValidator("Address line 2")
  );
  const zipCode = useProps(carer?.zipCode, zipCodeValidator());

  const addressForm = { address1, address2, zipCode };

  const [
    patientAgeConfig,
    patientAgeValues,
    patientAgeError,
    resetPatientAge,
  ] = useAgeSelect(
    carer?.patientDateOfBirth?.day,
    carer?.patientDateOfBirth?.month,
    carer?.patientDateOfBirth?.year,
    false
  );

  const isSaveButtonEnabled =
    isFormValid(nameForm) &&
    isFormValid(patientNameForm) &&
    isFormValid(onlyNumberForm) &&
    isFormValid(addressForm) &&
    patientAgeValues.day &&
    patientAgeValues.year &&
    patientAgeValues.month &&
    carerAgeValues.month &&
    carerAgeValues.year &&
    carerAgeValues.day &&
    !carerAgeError &&
    !updatingCarerDetails;

  async function saveClicked() {
    const carerDetails = {
      ...propsToValues(nameForm),
      ...propsToValues(patientNameForm),
      ...propsToValues(addressForm),
      contactNumber: { areaCode: areaCode.value, number: number.value },
      patientRelationship: carer.patientRelationship,
      patientDateOfBirth: {
        year: patientAgeValues.year,
        month: patientAgeValues.month,
        day: patientAgeValues.day,
      },
      dateOfBirth: {
        year: carerAgeValues.year,
        month: carerAgeValues.month,
        day: carerAgeValues.day,
      },
    };

    await dispatch(updateCarerDetails({ carerDetails }));

    setEditingCarerDetails(false);
    setEditingPatientDetails(false);
  }

  function cancelClicked() {
    setEditingCarerDetails(false);
    setEditingPatientDetails(false);
    resetPatientFields();
    resetCarerFields();
  }

  function cancelCarerClicked() {
    resetCarerFields();
    setEditingCarerDetails(false);
  }

  function cancelPatientClicked() {
    resetPatientFields();
    setEditingPatientDetails(false);
  }

  function resetCarerFields() {
    title.reset();
    firstName.reset();
    lastName.reset();
    resetCarerAge();
    areaCode.reset();
    number.reset();
    address1.reset();
    address2.reset();
    zipCode.reset();
  }

  function resetPatientFields() {
    patientTitle.reset();
    patientFirstName.reset();
    patientLastName.reset();
    resetPatientAge();
  }

  return (
    <section className="profile">
      <AccountHeader color="green" />
      <div className="profile__banner">
        <div>
          <h1 className="h2-a">My profile</h1>
          <p className="p4">
            Here you can make changes to your profile. Please ensure that your
            contact details and address are up to date so that we can ensure
            your order is processed on time.
          </p>
        </div>
      </div>
      <div className="profile__body">
        <div className="profile__content">
          <div className="profile__cards">
            <div className="user_details__header">
              <p className="p3--semi-bold">Your details</p>

              {editingCarerDetails ? (
                <p className="text_link" onClick={cancelCarerClicked}>
                  Cancel
                </p>
              ) : (
                <p
                  className="text_link"
                  onClick={() => setEditingCarerDetails(true)}
                >
                  Edit
                </p>
              )}
            </div>

            {editingCarerDetails ? (
              <div className="profile__carer_details">
                <NameForm {...nameForm} />
                <AgeSelect {...carerAgeConfig} error={carerAgeError} />
                <AddressForm {...addressForm} />
                <div className="form-spacing">
                  <ContactNumberForm {...numberForm} />
                </div>
              </div>
            ) : (
              <div>
                <UserDetailsCard content={carerCardContent} />
              </div>
            )}
          </div>

          {/* PATIENT */}
          <div className="profile__cards">
            <div className="user_details__header">
              <p className="p3--semi-bold">Patient details</p>
              {editingPatientDetails ? (
                <p className="text_link" onClick={cancelPatientClicked}>
                  Cancel
                </p>
              ) : (
                <p
                  onClick={() => setEditingPatientDetails(true)}
                  className="text_link"
                >
                  Edit
                </p>
              )}
            </div>

            {editingPatientDetails ? (
              <div>
                <NameForm
                  title={patientTitle}
                  firstName={patientFirstName}
                  lastName={patientLastName}
                />
                <AgeSelect {...patientAgeConfig} error={patientAgeError} />
              </div>
            ) : (
              <div>
                <UserDetailsCard content={patientCardContent} />
                <ChangePasswordCard email={user.email} />
              </div>
            )}
          </div>

          {(editingCarerDetails || editingPatientDetails) && (
            <div className="profile__buttons">
              <div>
                <PrimaryCta
                  text="Cancel changes"
                  color="white"
                  onClick={cancelClicked}
                />
              </div>
              <div>
                <PrimaryCta
                  text={updatingCarerDetails ? <Spinner /> : "Save changes"}
                  color="orange"
                  isEnabled={isSaveButtonEnabled}
                  onClick={saveClicked}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="profile__footer">
        <AccountFooter />
      </div>
    </section>
  );
}

export default CarerProfile;

function getCarerDetails(carer) {
  return [
    {
      title: "FULL NAME",
      text: `${carer?.title} ${carer?.firstName} ${carer?.lastName}`,
    },
    {
      title: "DATE OF BIRTH",
      text: `${carer?.dateOfBirth?.day}-${carer?.dateOfBirth?.month}-${carer?.dateOfBirth?.year}`,
    },

    {
      title: "RELATIONSHIP",
      text: `${carer?.patientRelationship}`,
    },
    {
      title: "ADDRESS",
      text: `${carer?.address1}, ${carer?.address2}, ${carer?.zipCode}`,
    },
    {
      title: "CONTACT NUMBER",
      text: `${carer?.contactNumber?.areaCode} ${carer?.contactNumber?.number}`,
    },
  ];
}

function getPatientDetails(carer) {
  return [
    {
      title: "FULL NAME",
      text: `${carer?.patientTitle} ${carer?.patientFirstName} ${carer?.patientLastName}`,
    },
    {
      title: "DATE OF BIRTH",
      text: `${carer?.patientDateOfBirth?.day}-${carer?.patientDateOfBirth?.month}-${carer?.patientDateOfBirth?.year}`,
    },
  ];
}
