import React from "react";
import { resolveAppendableClasses } from "../../../util/util";

function GenericBanner({ icon, text, className = "", title = "", children, appendableClassNames = "" }) {
  return (
    <div className={`generic_banner ${className} ${resolveAppendableClasses("generic_banner", appendableClassNames)}`}>
      <div className="generic_banner__icon">
        {icon}
      </div>
      <div className="generic_banner__text">
        <div className="generic_banner__text__title">
          {title}
        </div>
        <div className="generic_banner__text__content">
          {text} {children}

        </div>

      </div>
    </div>
  );
}

export default GenericBanner;
