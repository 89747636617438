import React from "react";

function OrderStatusPaymentDeadlineMissedIcon(props) {
  return (
    <svg
      width="48"
      height="35"
      viewBox="0 0 48 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M43.8621 8.27588H0V11.5862H43.8621V8.27588Z" fill="black" />
        <path
          d="M13.2412 17.3794H4.96533V24.0001H13.2412V17.3794Z"
          fill="black"
        />
        <path
          d="M30.6208 21.5173H16.5601V23.1725H30.6208V21.5173Z"
          fill="black"
        />
        <path
          d="M35.5863 18.2068H16.5601V19.862H35.5863V18.2068Z"
          fill="black"
        />
        <path
          d="M40.5518 19.8621C39.0787 19.8621 37.6386 20.2989 36.4138 21.1173C35.1889 21.9357 34.2342 23.099 33.6705 24.46C33.1067 25.821 32.9592 27.3186 33.2466 28.7634C33.534 30.2083 34.2434 31.5354 35.2851 32.5771C36.3267 33.6187 37.6539 34.3281 39.0987 34.6155C40.5435 34.9029 42.0411 34.7554 43.4021 34.1917C44.7631 33.6279 45.9264 32.6733 46.7448 31.4484C47.5632 30.2235 48.0001 28.7835 48.0001 27.3103C48.0001 25.3349 47.2153 23.4404 45.8185 22.0436C44.4217 20.6468 42.5272 19.8621 40.5518 19.8621ZM41.5532 31.4483H39.567V29.5448H41.5532V31.4483ZM41.3794 28.6262H39.7242L39.509 23.1724H41.5946L41.3794 28.6262Z"
          fill="black"
        />
        <path
          d="M36.8524 28.1379H3.31035C2.87137 28.1379 2.45037 27.9636 2.13996 27.6532C1.82956 27.3427 1.65517 26.9217 1.65517 26.4828V3.31035C1.65517 2.87137 1.82956 2.45037 2.13996 2.13996C2.45037 1.82956 2.87137 1.65517 3.31035 1.65517H40.5517C40.9907 1.65517 41.4117 1.82956 41.7221 2.13996C42.0325 2.45037 42.2069 2.87137 42.2069 3.31035V22.2124H43.8621V3.31035C43.8621 2.43239 43.5133 1.59039 42.8925 0.969578C42.2717 0.348768 41.4297 0 40.5517 0L3.31035 0C2.43239 0 1.59039 0.348768 0.969578 0.969578C0.348768 1.59039 0 2.43239 0 3.31035L0 26.4828C0 27.3607 0.348768 28.2027 0.969578 28.8235C1.59039 29.4443 2.43239 29.7931 3.31035 29.7931H36.8524V28.1379Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default OrderStatusPaymentDeadlineMissedIcon;
