import React from "react";

function BrokenLinkIcon() {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="80" height="80" rx="40" fill="#F6F6F6" />
      <path d="M27.584 30.002L28.998 28.588L32.414 32.002L31 33.417L27.584 30.002ZM47.581 49.992L48.996 48.578L52.41 51.994L50.996 53.408L47.581 49.992ZM35 27H37V31H35V27ZM26 36H30V38H26V36ZM50 44H54V46H50V44ZM43 51H45V55H43V51ZM40.58 46.07L36.87 49.79C36.4984 50.1616 36.0572 50.4564 35.5716 50.6576C35.086 50.8587 34.5656 50.9622 34.04 50.9622C33.5144 50.9622 32.994 50.8587 32.5084 50.6576C32.0228 50.4564 31.5816 50.1616 31.21 49.79C30.4594 49.0394 30.0378 48.0215 30.0378 46.96C30.0378 45.8985 30.4594 44.8806 31.21 44.13L34.93 40.41L33.51 39L29.8 42.72C29.2307 43.2741 28.7771 43.9358 28.4655 44.6667C28.1539 45.3975 27.9906 46.183 27.985 46.9775C27.9795 47.7719 28.1317 48.5596 28.433 49.2948C28.7342 50.0299 29.1785 50.6979 29.74 51.26C30.3006 51.8161 30.9655 52.256 31.6966 52.5546C32.4276 52.8532 33.2104 53.0046 34 53C34.8034 53.0008 35.5989 52.8421 36.3405 52.5331C37.082 52.2241 37.7549 51.771 38.32 51.2L42 47.49L40.58 46.07ZM39.41 35.93L43.13 32.21C43.5016 31.8384 43.9428 31.5436 44.4284 31.3424C44.914 31.1413 45.4344 31.0378 45.96 31.0378C46.4856 31.0378 47.006 31.1413 47.4916 31.3424C47.9772 31.5436 48.4184 31.8384 48.79 32.21C49.1616 32.5816 49.4564 33.0228 49.6576 33.5084C49.8587 33.994 49.9622 34.5144 49.9622 35.04C49.9622 35.5656 49.8587 36.086 49.6576 36.5716C49.4564 37.0572 49.1616 37.4984 48.79 37.87L45.07 41.59L46.49 43L50.2 39.28C50.7693 38.7259 51.2229 38.0642 51.5345 37.3333C51.8461 36.6025 52.0094 35.817 52.015 35.0225C52.0205 34.2281 51.8683 33.4404 51.567 32.7052C51.2658 31.9701 50.8215 31.3021 50.26 30.74C49.6994 30.1839 49.0345 29.744 48.3034 29.4454C47.5724 29.1468 46.7896 28.9954 46 29C45.1966 28.9992 44.4011 29.1579 43.6595 29.4669C42.918 29.7759 42.2451 30.229 41.68 30.8L38 34.51L39.41 35.93Z" fill="#D5D5D5" />
    </svg>
  );
}

export default BrokenLinkIcon;
