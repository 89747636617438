import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta, QuaternaryCta } from "../../../../components/buttons";
import ProductDetails from "../../Order/OrderProducts/Product/ProductDetails/ProductDetails";
import { selectUser } from "../../../../store/slices/authenticationSlice";
import { getPluralText } from "../../../../util/util";
import { setPrescriptionModal, setStartLiveChatPopup } from "../../../../store/slices/modalsSlice";
import { DownloadIcon, PrescriptionIcon } from "../../../../assets/icons";
import VerticalSeparator from "../../../VerticalSeparator/VerticalSeparator";

function PastPrescriptionOrderHeader({
  title = "",
  Icon = () => null,
  order = {},
}) {
  const dispatch = useDispatch();


  const user = useSelector(selectUser);

  const numberOfItemsText = useMemo(
    () => `${getPluralText("ITEM", order.products?.length ?? 0)} IN THIS ORDER`,
    [order.products]
  );

  const content = getOrderDetails(user, order);

  return (
    <div>
      <div className="previous__order__header">
        <div className="previous__order__header__top">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="previous__order__header__icon">
              <Icon />
            </div>

            <p className="number_of_items--mobile label--caps">
              {numberOfItemsText}
            </p>
          </div>

          <div>
            <h2 className="h5-a--serif">{title}</h2>

            <div className="previous__order__header__details--mobile">
              <ProductDetails content={content} column />
            </div>
            <div className="previous__order__header__details--desktop">
              <ProductDetails content={content} />
            </div>
          </div>

          <p className="number_of_items--desktop label--caps">
            {numberOfItemsText}
          </p>
        </div>


      </div>
      <div className="previous__order__header__buttons">
        <div>
          <div className="h-40">
            <PrimaryCta
              color="white"
              text="Report a problem"
              onClick={() => dispatch(setStartLiveChatPopup({ show: true }))}
            />
          </div>
        </div>
        <div>
          <QuaternaryCta
            text="View prescription"
            icon={<PrescriptionIcon />}
            onClick={() =>
              dispatch(
                setPrescriptionModal({ show: true, image: order.filePath })
              )
            }
          />

          {order.invoiceFilePath && (
            <>
              {user.role !== 3 && (
                <>
                  <VerticalSeparator />

                  <QuaternaryCta
                    text="Download invoice"
                    icon={<DownloadIcon />}
                    onClick={() => window.open(order.invoiceFilePath, "_blank")}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>

  );
}

export default PastPrescriptionOrderHeader;

function getOrderDetails(user, order) {
  return user?.role !== 3
    ? [
      { title: "PRESCRIPTION NO", text: order.paperPrescriptionId },
      { title: "PRESCRIBED BY", text: order.prescribedBy },
      { title: "DATE DELIVERED", text: order.date },
    ]
    : [
      { title: "PRESCRIPTION NO", text: order.paperPrescriptionId },
      {
        title: "PATIENT NAME",
        text: `${order.patient.firstName} ${order.patient.lastName}`,
      },
      { title: "DATE DELIVERED", text: order.date },
    ];
}
