import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountHeader } from "../../../../components/headers";
import { AccountBottomAppBar } from "../../../../components/account";
import { ProductAvailability, ProductIrradiatedTypeMapper, ProductPhEurMapper, ProductSubtypesMapper, ProductTypesMapper, ProductUnit, TableHeaders, TerpeneOptions } from "../../../../enums";
import { useHistory, useParams } from "react-router-dom";
import { callMapper } from "../../../../util/util";
import Spinner from "../../../../components/sharedComponents/Spinner/Spinner";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "../../../../components/charts/PieChart";
import BarChart from "../../../../components/charts/BarChart";
import { selectCurrentOrganisation } from "../../../../store/slices/accountSlice";
import {
  getAllTerpenes, getAllSuppliers, getProductsForClinic, getCurrentProductImages, getCurrentProductDocs,
  selectClinicProducts, selectClinicTerpenes, selectClinicSuppliers, selectClinicsProductsLoadingFlag, selectClinicTerpenesLoadingFlag, selectClinicSuppliersLoadingFlag, selectCurrentProductImages, selectCurrentProductDocs, selectProductDocsLoadingFlag, selectProductImagesLoadingFlag, selectClinicsFilteredProducts, selectClinicFilterNumberOfChecked,
} from "../../../../store/slices/productSlice";
import ReusableTable from "../../../../components/sharedComponents/ReusableTable/ReusableTable";
import ReusableBadge from "../../../../components/sharedComponents/ReusableBadge/ReusableBadge";
import UniversalTooltip from "../../../../components/tooltips/UniveralTooltip";
import { MessageIcon } from "../../../../assets/icons";
import ProductNoteTooltipContent from "../../../../components/tooltips/tooltipContent/ProductNoteTooltipContent";
import moment from "moment";
import ProductPreview from "../../../../components/account/ProductPreview/ProductPreview";
import BackButtonIcon from "../../../../assets/icons/BackButtonIcon";
import CarbonDocumentIcon from "../../../../assets/icons/CarbonDocumentIcon";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the default CSS styles
import Spinner2 from "../../../../components/sharedComponents/Spinner/Spinner2";
import { ReactComponent as Flower } from "../../../../assets/images/FlowerTransparent.svg";
import HeaderTooltipContent from "../../../../components/tooltips/tooltipContent/HeaderTooltipContent";


Chart.register(CategoryScale);

function ClinicFormulary() {
  const { status } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const products = useSelector(selectClinicProducts);
  const filteredProducts = useSelector(selectClinicsFilteredProducts);
  const clinicFilterNumberOfChecked = useSelector(selectClinicFilterNumberOfChecked);

  const terpenes = useSelector(selectClinicTerpenes);
  const suppliers = useSelector(selectClinicSuppliers);

  const loadingProducts = useSelector(selectClinicsProductsLoadingFlag);
  const loadingTerpenes = useSelector(selectClinicTerpenesLoadingFlag);
  const loadingSuppliers = useSelector(selectClinicSuppliersLoadingFlag);

  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const [showPreview, setShowPreview] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: []
  });

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: []
  });

  const pieChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(tooltipItems) {
            return `${tooltipItems.label} ${tooltipItems.formattedValue}%`
          }
        },
      },
    },
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(tooltipItems) {
            return `${tooltipItems.label} ${tooltipItems.formattedValue}%`
          }
        },
      },
    },
  };

  const [currentProduct, setCurrentProduct] = useState({});
  const [currentProductSupplier, setCurrentProductSupplier] = useState({});
  const [currentProductTerpeneType, setCurrentProductTerpeneType] = useState(1);

  const loadingProductImages = useSelector(selectProductImagesLoadingFlag);
  const loadingProductDocs = useSelector(selectProductDocsLoadingFlag);

  const currentProductImages = useSelector(selectCurrentProductImages);
  const currentProductDocs = useSelector(selectCurrentProductDocs);

  const chartColors = [
    'rgb(147, 79, 57)',
    'rgb(198, 130, 108)',
    'rgb(230, 204, 160)',
    'rgb(99, 127, 96)',
    'rgb(160, 204, 154)',
    'rgb(178, 214, 191)',
    'rgb(51, 122, 183)',
    'rgb(61, 147, 221)',
    'rgb(119, 163, 201)',
  ];

  useEffect(function () {
    if (currentOrganisation.organisationId === null) {
      history.push("/organisation/noaccess")
    }
  }, [currentOrganisation])

  const onRowClick = (e, product) => {
    if (currentOrganisation.organisationId > 0) {
      e.stopPropagation()
      dispatch(getCurrentProductImages({
        productId: product.id,
        organisationId: currentOrganisation.organisationId
      }))
      dispatch(getCurrentProductDocs({
        productId: product.id,
        organisationId: currentOrganisation.organisationId
      }))
      setCurrentProduct(product)
      setCurrentProductSupplier(suppliers.find((supplier) => supplier.id === product.supplierId))
      updatePieChartData(product)
      updateBarChartData(product)
      setShowPreview(true);
    }
  }

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }


  const updatePieChartData = (product) => {
    setCurrentProductTerpeneType(product.productTerpeneProfiles[0]?.type)

    const pieChartData = product.productTerpeneProfiles
      .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
      .map(profile => {
        const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
        return {
          name: matchingItem.name,
          value: profile.value,
          type: resolveTerpeneType(profile)
        }
      })

    setPieChartData(
      {
        labels: pieChartData.map((e) => e.name),
        datasets: [
          {
            data: pieChartData.map((e) => e.value),
            backgroundColor: chartColors,
            hoverOffset: 4
          }
        ]
      }
    )
  }

  const updateBarChartData = (product) => {
    const barChartData = [
      {
        name: "THC",
        value: Math.abs(product.thc)
      },
      {
        name: "CBD",
        value: Math.abs(product.cbd)
      }]
    setBarChartData(
      {
        labels: barChartData.map((e) => e.name),
        datasets: [
          {
            data: barChartData.map((e) => e.value),
            backgroundColor: chartColors,
          }
        ]
      }
    )
  }

  const isRowActive = (product) => {
    return currentProduct?.id === product.id ? "active-row" : ""
  }

  const renderBadge = (product) => {
    const textAndClass = callMapper(ProductAvailabilityBadgeMapper, product.availability, { text: "/", className: "" })
    return <ReusableBadge className={textAndClass.className} text={textAndClass.text} />
  }

  const sortedProducts = React.useMemo(() => {
    let sortableProducts = [...(clinicFilterNumberOfChecked > 0 ? filteredProducts : products)];
    if (sortConfig !== null) {
      sortableProducts.sort((a, b) => {
        //have to check if it is thc or cbd in order to sort correctly since those values use a negative sign to indicate 'less than'
        const aValue = (['thc', 'cbd'].includes(sortConfig.key)) ? Math.abs(a[callMapper(sortConfigKeyMapper, sortConfig.key)]) : a[callMapper(sortConfigKeyMapper, sortConfig.key)];
        const bValue = (['thc', 'cbd'].includes(sortConfig.key)) ? Math.abs(b[callMapper(sortConfigKeyMapper, sortConfig.key)]) : b[callMapper(sortConfigKeyMapper, sortConfig.key)];

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProducts;
  }, [products, filteredProducts, sortConfig, clinicFilterNumberOfChecked]);


  const renderTableBody = (products) => {
    return products.map(product => {
      return [
        {
          text: renderBadge(product),
          column: 1,
          onRowClick: (e) => { onRowClick(e, product) },
          classNameForRow: `${isRowActive(product)} hoverable formulary`,
        },
        {
          text: product.prescriberNoteAdminId != null ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
              {product.name}
              <div style={{ marginLeft: "6px", alignSelf: "center" }}>
                <UniversalTooltip
                  hoverableContent={<MessageIcon squareColor={"#FFF5E6"} flagColor={"#F59404"} />}
                  left={120}
                  contentForTheTooltip={<ProductNoteTooltipContent product={product} />}
                />
              </div>
            </div>
          ) : product.name,
          column: 2.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: callMapper(ProductTypesMapper, product.type),
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: callMapper(ProductSubtypesMapper, product.subtype),
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: callMapper(ProductIrradiatedTypeMapper, product.irradiatedType),
          column: 1,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: callMapper(ProductPhEurMapper, product.phEur),
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: `${product.thc < 0 ? "<" : ""}${Math.abs(product.thc)} %`,
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: `${product.cbd < 0 ? "<" : ""}${Math.abs(product.cbd)} %`,
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: `${product.weight} ${ProductUnit.getName(product.productUnit)}`,
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: product.retailPrice.toFixed(2) + ' £',
          column: 1,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: (product.t21SubsidyPrice && product.isSubsidyEligible) ? (product.t21SubsidyPrice.toFixed(2) + ' £') : "No",
          column: 1,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: product.productTerpeneProfiles.length && terpenes ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {product.productTerpeneProfiles
                .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
                .map(profile => {
                  const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
                  return <div key={matchingItem.id}>{matchingItem.name} {resolveTerpeneType(profile)}</div>;
                })}
            </div>
          ) : " / ",
          column: 2,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
        {
          text: product.isT21Eligible ? "Yes" : "No",
          column: 0.5,
          onRowClick: (e) => { onRowClick(e, product) },
          left: true
        },
      ]
    })
  }

  const renderPreviewPageTerpenes = () => {
    if (Object.keys(currentProduct).length != 0) {
      return <div className="previewTerpene">
        {currentProduct.productTerpeneProfiles
          .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
          .map((profile, index) => {
            const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
            return <div className="previewTerpene__item" key={matchingItem.id}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill={`${chartColors[index]}`} />
              </svg>
              {` ${matchingItem.name} `}
              <div className="previewTerpene__value">
                {resolveTerpeneType(profile)}
              </div>
            </div>;
          })}
      </div>
    }
    return <div>No terpene data</div>
  }

  const renderPreviewPageMinorMajorTerpenes = () => {
    if (Object.keys(currentProduct).length != 0) {
      return <div className="previewTerpene">
        {currentProduct.productTerpeneProfiles
          .filter(profile => terpenes.some(terpene => terpene.id === profile.terpeneId))
          .map((profile, index) => {
            const matchingItem = terpenes.find(terpene => terpene.id === profile.terpeneId);
            return <div className="previewTerpene__item" key={matchingItem.id}>
              {` ${matchingItem.name} `}
              <div className="previewTerpene__value">
                {resolveTerpeneType(profile)}
              </div>
            </div>;
          })}
      </div>
    }
    return <div>No terpene data</div>
  }

  const loadingComponent = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Spinner color={"#13ae7d"} />
      <span className='m-t-s'>LOADING PRODUCTS</span>
    </div>
  }

  const subsidyTooltipConent = () => {
    return <HeaderTooltipContent text={
      <>
        {
          `10% subsidy on 2 units 
        20% subsidy on 3 units or more`
        }
      </>
    } />
  }

  useEffect(() => {
    if (currentOrganisation.organisationId > 0) {
      dispatch(getAllTerpenes())
      dispatch(getAllSuppliers(currentOrganisation.organisationId))
      dispatch(getProductsForClinic({ organisationId: currentOrganisation.organisationId }))
    }
    // eslint-disable-next-line
  }, [status, currentOrganisation])

  useEffect(() => {
    function onOutsideClick() {
      setShowPreview(false)
    }

    window.addEventListener("click", onOutsideClick)
    return () => {
      window.removeEventListener("click", onOutsideClick)
    }
  }, [])

  const [dashboardRef, setDashboardRef] = useState(null);
  const [tableHolderRef, setTableHolderRef] = useState(null);

  useLayoutEffect(() => {
    const updateElementHeights = () => {
      document.body.style.overflow = "hidden";

      if (dashboardRef) {
        const heightForSomething = window.innerHeight - dashboardRef.getBoundingClientRect().top;
        dashboardRef.style.height = `${heightForSomething}px`;
        dashboardRef.style.overflowY = "hidden";
      }

      if (tableHolderRef) {
        const parentHeight = tableHolderRef.parentElement.getBoundingClientRect().height;
        tableHolderRef.style.height = `${parentHeight}px`;
      }
    };

    const transformLayout = () => {
      updateElementHeights();
    };

    transformLayout();
    window.addEventListener("resize", transformLayout);

    return () => {
      document.body.style.overflow = "initial";
      window.removeEventListener("resize", transformLayout);
    };
  }, [products, dashboardRef, tableHolderRef]);

  return (
    <>
      <AccountHeader />
      <AccountBottomAppBar />
      {!showPreview && (<div>
        <section ref={setDashboardRef} className='clinic_prescriptions_dashboard'>
          <div ref={setTableHolderRef} className='clinic_prescriptions_dashboard__table_holder '>
            <ReusableTable
              loadingComponent={{ component: loadingComponent }}
              loadingData={loadingProducts || loadingTerpenes || loadingSuppliers}
              body={renderTableBody(sortedProducts)}
              header={callMapper(TableHeaders, "products")()}
              isSearchActive={clinicFilterNumberOfChecked > 0}
              resetPaginationDependencies={[status]}
              contentForTheTooltip={[subsidyTooltipConent()]} //tooltips should be passed as an array
              currentPage={currentPage}
              onPageChange={setCurrentPage}

              requestSort={requestSort}
              sortConfig={sortConfig}
            />
          </div>
        </section>
      </div>)}
      <ProductPreview onClick={(e) => e.stopPropagation()} show={showPreview}>
        <div className={"product_preview_content"}>
          <div className="product_preview_content__header" >
            <div style={{ cursor: "pointer" }} onClick={() => {
              setShowPreview(!showPreview)
              setCurrentProduct({})
            }} >
              <BackButtonIcon />
            </div>
          </div>
          <div className="product_preview_content__headerRow">
            {currentProduct.name || "N/A"}{renderBadge(currentProduct)}
            <div className="product_preview_content__headerRow__right">
              EXPIRY DATE: {moment(currentProduct.expiryDate).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className={"product_preview_content__informations"}>
            <div className="product_preview_content__informations__gridContainer">
              <div className="div1">
                {loadingProductImages ?
                  <div className='spinner_loader'>
                    <Spinner2 color={"#00a372"} width={"60"} />
                  </div> :
                  currentProductImages.length > 0 ?
                    <ImageGallery items={
                      currentProductImages.map((image) => (
                        {
                          original: image.mediaUrl
                        }))}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showBullets={currentProductImages.length > 1}
                      showNav={true}
                    >
                    </ImageGallery> :
                    <div className='noPhotos'>
                      <Flower />
                      PRODUCT PHOTO WILL BE ADDED SOON
                    </div>
                }
              </div>
              <div className="div2">
                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                  Details
                </div>
                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                  <div className="productPreviewDetailsRow">
                    <div>
                      TYPE
                      <div className="productPreviewDetailsRow__text">
                        {callMapper(ProductTypesMapper, currentProduct.type)}
                      </div>
                    </div>
                    <div>
                      SUBTYPE
                      <div className="productPreviewDetailsRow__text">
                        {callMapper(ProductSubtypesMapper, currentProduct.subtype)}
                      </div>
                    </div>
                    <div>
                      Ph. Euro
                      <div className="productPreviewDetailsRow__text">
                        {callMapper(ProductPhEurMapper, currentProduct.phEur)}
                      </div>
                    </div>
                  </div>
                  <div className="productPreviewDetailsRow">
                    <div>
                      THC
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.thc < 0 ? "<" : ""}${Math.abs(currentProduct.thc)}mg/ml`}
                      </div>
                    </div>
                    <div>
                      CBD
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.cbd < 0 ? "<" : ""}${Math.abs(currentProduct.cbd)}mg/ml`}
                      </div>
                    </div>
                    <div>
                      Irradiated
                      <div className="productPreviewDetailsRow__text">
                        {callMapper(ProductIrradiatedTypeMapper, currentProduct.irradiatedType)}
                      </div>
                    </div>
                  </div>
                  <div className="productPreviewDetailsRow">
                    <div>
                      WEIGHT
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.weight}${ProductUnit.getName(currentProduct.productUnit)}`}
                      </div>
                    </div>
                    <div>
                      RETIAL PRICE
                      <div className="productPreviewDetailsRow__text">
                        {`£${currentProduct.retailPrice}`}
                      </div>
                    </div>
                    <div>
                      T21
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.isT21Eligible ? "Yes" : "No"}`}
                      </div>
                    </div>
                  </div>
                  <div className="productPreviewDetailsRow">
                    <div>
                      SUPPLIER
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProductSupplier ? currentProductSupplier.name : "/"}`}
                      </div>
                    </div>
                    <div>
                      MANUFACTURER
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.manufacturer ? currentProduct.manufacturer : "/"}`}
                      </div>
                    </div>
                    <div>
                      COUNTRY
                      <div className="productPreviewDetailsRow__text">
                        {`${currentProduct.country ? currentProduct.country : "/"}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div3">
                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                  Terpene Profile
                </div>
                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                  {!currentProductTerpeneType &&
                    <>
                      <PieChart chartData={pieChartData} chartOptions={pieChartOptions} />
                      {renderPreviewPageTerpenes()}
                    </> ||
                    renderPreviewPageMinorMajorTerpenes()
                  }
                  {!currentProduct.productTerpeneProfiles?.length &&
                    <div className='noTerpenes'>
                      No terpene profile added yet
                    </div>}
                </div>
              </div>
              <div className="div4">
                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                  Active ingredients
                </div>
                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                  <>
                    <BarChart chartData={barChartData} chartOptions={barChartOptions} />
                  </>
                </div>
              </div>
              <div className="div5">
                <div className="product_preview_content__informations__gridContainer__gridElementHeader">
                  Documentation
                </div>
                <div className="product_preview_content__informations__gridContainer__gridElementContent">
                  <div className="productPreviewDetailsRow">
                    {loadingProductDocs ?
                      <div className='spinner_loader'>
                        <Spinner2 color={"#00a372"} width={"60"} />
                      </div> :
                      currentProductDocs.length > 0 ?
                        currentProductDocs.map(doc => {
                          return <div key={doc.id} className="productPreviewDetailsRow__document" >
                            <CarbonDocumentIcon />
                            <a href={doc.mediaUrl} download target="_blank">{doc.mediaTitle}</a>
                          </div>
                        }) :
                        <div className='noDocs'>
                          No documenation added
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProductPreview>
    </>
  )
}

const resolveTerpeneType = (profile) => {
  if (profile.type === TerpeneOptions.MAJORMINOR)
    return profile.value === 0 ? "Minor" : "Major"
  else if (profile.type === TerpeneOptions.PERCENTAGE)
    return `${profile.value}%`
  else
    return ""
}

const ProductAvailabilityBadgeMapper = {
  [ProductAvailability.Available]: {
    text: "AVAILABLE",
    className: "reusable_badge--greenProduct"
  },
  [ProductAvailability.Unavailable]: {
    text: "UNAVAILABLE",
    className: "reusable_badge--yellowProduct"
  },
  [ProductAvailability.Discontinued]: {
    text: "DISCONTINUED",
    className: "reusable_badge--redProduct"
  },
  undefined: {
    text: "/",
    className: ""
  },
}

const sortConfigKeyMapper = {
  "status": "availability",
  "product name": "name",
  "type": "type",
  "subtype": "subtype",
  "ph. euro": "phEur",
  "thc": "thc",
  "cbd": "cbd",
  "weight": "weight",
  "retail price": "retailPrice",
  "access scheme incl. T21": "t21SubsidyPrice",
  "t21": "isT21Eligible",
  "irradiated": "irradiatedType",
}

export default ClinicFormulary;