import moment from "moment";
import { Relationship } from "../enums";

class UsersConverter {
  patientsForDoctorSelectApiToClient(patients) {
    const result = {};

    for (let patient of patients) {
      const key = patient.patientDetails;

      result[key] = { id: patient.patientId };
    }

    return result;
  }

  _doesPatientDetailsContainDOB(patientDetails) {
    const splitted = patientDetails.split("-");

    return splitted.length > 1;
  }

  updatedPatientApiToClient(patient) {
    patient.contactNumber = this.contactNumberApiToClient(patient.telephoneNo);
    patient.dateOfBirth = this.dobApiToClient(patient.dateOfBirth);

    return patient;
  }

  updatedCarerApiToClient(carer) {
    const patient = carer.patients[0];

    carer.patientId = patient.id;
    carer.patientFirstName = patient.firstName;
    carer.patientLastName = patient.lastName;
    carer.patientTitle = patient.title;
    carer.dateOfBirth = this.dobApiToClient(carer.dateOfBirth);
    carer.patientDateOfBirth = this.dobApiToClient(patient.dateOfBirth);
    carer.contactNumber = this.contactNumberApiToClient(carer.telephoneNo);
    carer.patientRelationship = Relationship.getName(carer.patientRelationship);

    return carer;
  }

  updatedDoctorApiToClient(doctor) {
    doctor.clinicContactNumber = this.contactNumberApiToClient(
      doctor.telephoneNo
    );
    doctor.consultationEmail = doctor.consultationEmail ? doctor.consultationEmail : ""
    doctor.consultationOther = doctor.consultationOther ? doctor.consultationOther : ""
    doctor.consultationPhone = doctor.consultationPhone ? doctor.consultationPhone : ""
    doctor.consultationUrl = doctor.consultationPhone ? doctor.consultationUrl : ""

    return doctor;
  }

  doctorClientToApi(doctor) {
    const result = { ...doctor };
    result.clinicTelephoneNo = `${doctor.clinicContactNumber.areaCode} ${doctor.clinicContactNumber.number}`;
    delete result.clinicContactNumber;

    return result;
  }

  doctorApiToClient({ doctor, lastLoggedIn, isEmailVerified, isIDVerified, acceptedCookies }) {
    const userDetails = { ...doctor };

    userDetails.clinicContactNumber = ""
    userDetails.consultationEmail = doctor.consultationEmail ? doctor.consultationEmail : ""
    userDetails.consultationOther = doctor.consultationOther ? doctor.consultationOther : ""
    userDetails.consultationPhone = doctor.consultationPhone ? doctor.consultationPhone : ""
    userDetails.consultationUrl = doctor.consultationPhone ? doctor.consultationUrl : ""
    userDetails.acceptedCookies = true
    return {
      isEmailVerified,
      isIDVerified,
      userDetails,
      lastLoggedIn: moment(lastLoggedIn).format("DD/MM/YYYY, HH:mm"),
    };
  }

  doctorsPatientClientToApi(patient) {
    const result = { ...patient };

    result.telephoneNo = `${patient.contactNumber.areaCode} ${patient.contactNumber.number}`;
    delete result.contactNumber;

    return result;
  }

  carerApiToClient({ carer, lastLoggedIn, isEmailVerified, isIDVerified, acceptedCookies }) {
    const userDetails = { ...carer };
    const patient = carer.patients[0];

    userDetails.patientId = patient.id;
    userDetails.patientFirstName = patient.firstName;
    userDetails.patientLastName = patient.lastName;
    userDetails.patientTitle = patient.title;
    userDetails.patientAddress1 = patient.address1;
    userDetails.patientAddress2 = patient.address2;
    userDetails.patientZipCode = patient.zipCode;
    userDetails.dateOfBirth = this.dobApiToClient(carer.dateOfBirth);
    userDetails.patientDateOfBirth = this.dobApiToClient(patient.dateOfBirth);
    userDetails.contactNumber = this.contactNumberApiToClient(
      carer.telephoneNo
    );

    userDetails.acceptedCookies = true;

    userDetails.patientRelationship = Relationship.getName(
      carer.patientRelationship
    );

    return {
      isEmailVerified,
      isIDVerified,
      userDetails,
      lastLoggedIn: moment(lastLoggedIn).format("DD/MM/YYYY, HH:mm"),
    };
  }

  carerClientToApi(carerPayload) {
    const carer = { ...carerPayload };

    carer.telephoneNo = `${carer.contactNumber.areaCode} ${carer.contactNumber.number}`;
    delete carer.contactNumber;

    carer.dateOfBirth = this.dobClientToApi(carer.dateOfBirth);
    carer.patientDateOfBirth = this.dobClientToApi(carer.patientDateOfBirth);
    carer.patientRelationship = Relationship[carer.patientRelationship];

    return carer;
  }

  patientCompleteAccountApiToClient(payload) {
    const result = {
      firstName: payload.patient.firstName,
      lastName: payload.patient.lastName,
      title: payload.patient.title,
      email: payload.email,
      contactNumber: this.contactNumberApiToClient(payload.patient.telephoneNo),
      secretId: payload.secretId,
      dateOfBirth: this.dobApiToClient(payload.patient.dateOfBirth),
      address1: payload.patient.address1,
      address2: payload.patient.address2,
      zipCode: payload.patient.zipCode
    };

    return result;
  }

  patientApiToClient({ patient, lastLoggedIn, isEmailVerified, isIDVerified, acceptedCookies, organisationId }) {
    const userDetails = { ...patient };
    userDetails.dateOfBirth = this.dobApiToClient(patient.dateOfBirth);
    userDetails.patientId = patient.id;
    userDetails.contactNumber = this.contactNumberApiToClient(
      patient.telephoneNo
    );

    userDetails.acceptedCookies = true
    userDetails.organisationId = organisationId // organisationId of the org the patient belongs to
    return {
      isEmailVerified,
      isIDVerified,
      userDetails,
      lastLoggedIn: moment(lastLoggedIn).format("DD/MM/YYYY, HH:mm"),
    };
  }

  patientApiToClientClinicUser(patient) {

    return {
      address1: patient.address1 || "",
      address2: patient.address2 || "",
      carerId: patient.carerId || "",
      dateOfBirth: patient.dateOfBirth ? moment(patient.dateOfBirth).format("DD/MM/YYYY") : "",
      firstName: patient.firstName || "",
      id: patient.id || "",
      lastName: patient.lastName || "",
      telephoneNo: patient.telephoneNo || "",
      title: patient.title || "",
      zipCode: patient.zipCode || "",
      email: patient.user ? patient.user.email : "",
      isTempPatient: false
    }
  }

  tempPatientApiToClientClinicUser(patient) {

    return {
      address1: patient.address1 || "",
      address2: patient.address2 || "",
      carerId: patient.carerId || "",
      dateOfBirth: patient.dateOfBirth ? moment(patient.dateOfBirth).format("DD/MM/YYYY") : "",
      firstName: patient.firstName || "",
      id: patient.id || "",
      lastName: patient.lastName || "",
      telephoneNo: patient.telephoneNo || "",
      title: patient.title || "",
      zipCode: patient.zipCode || "",
      email: patient.email || "",
      isTempPatient: true,
      clinicIdNumber: patient.clinicIdNumber || "",
    }
  }

  clinicUserApiToClient({ organisationEmployee, lastLoggedIn, isEmailVerified, isIDVerified, acceptedCookies, clinic }) {
    const organisations = organisationEmployee.organisationUsers.map(e => {
      return {
        organisationId: e.organisationId,
        employeeType: e.employeeType,
        organisationName: e.organisation.name
      }
    });

    delete organisationEmployee.organisationUsers;
    const userDetails = { ...organisationEmployee, clinic, organisations };
    userDetails.acceptedCookies = true

    return {
      isEmailVerified,
      isIDVerified,
      userDetails,
      lastLoggedIn: moment(lastLoggedIn).format("DD/MM/YYYY, HH:mm"),
    };
  }

  patientClientToApi(patientPayload) {
    const patient = { ...patientPayload };

    patient.dateOfBirth = this.dobClientToApi(patient.dateOfBirth);

    patient.telephoneNo = patient.contactNumber.areaCode ? `${patient.contactNumber.areaCode} ${patient.contactNumber.number}` : patient.contactNumber.number;
    delete patient.contactNumber;

    return patient;
  }

  contactNumberApiToClient(telephoneNo) {
    const [first, second] = telephoneNo.split(" ");

    const proccessedPhoneNumber = {
      areaCode: second ? first : '',
      number: !second ? first : second
    }

    return proccessedPhoneNumber;
  }

  dobApiToClient(dateOfBirth) {
    dateOfBirth = moment(dateOfBirth);

    return {
      year: dateOfBirth.format("YYYY"),
      day: dateOfBirth.format("D"),
      month: dateOfBirth.format("MMMM"),
    };
  }

  dobClientToApi(dateOfBirth) {
    const dobString = `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`;
    const formattedDate = moment(dobString, "YYYY-MMMM-DD").format(
      "YYYY-MM-DD"
    );

    return formattedDate;
  }
}

export const usersConverter = new UsersConverter();
