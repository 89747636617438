import { ValidationErrorMessages } from "../enums";

function onlyLetters(string) {
  const lettersRegex = /^[A-Za-z]+(\s{0,1}[a-zA-Z-, ])*$/;
  if (string.match(lettersRegex)) return true;

  return false;
}

function isNumber(value) {
  return value && !isNaN(value);
}

function isNotEmpty(string) {
  if (!string || string.length === 0) {
    return false;
  }

  return true;
}

function isPhoneNumber(string) {
  const numberRegex = /^[0-9]{10,11}$/;
  if (string.match(numberRegex)) return true;

  return false;
}

function isCreditCard(string) {
  let visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  let mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  let amexpRegEx = /^(?:3[47][0-9]{13})$/;
  let discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  let isValid = false;

  if (visaRegEx.test(string)) {
    isValid = true;
  } else if (mastercardRegEx.test(string)) {
    isValid = true;
  } else if (amexpRegEx.test(string)) {
    isValid = true;
  } else if (discovRegEx.test(string)) {
    isValid = true;
  }

  return isValid

}

function isAmexCard(string) {
  let isValid = true;
  let amexpRegEx = /^(?:3[47][0-9]{13})$/;

  if (amexpRegEx.test(string)) {
    isValid = false;
  }
  return isValid;
}

function isCardExpired(string) {
  let regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  let isValid = true;

  if (regex.test(string)) {
    let splitted = string.split('/')
    let month = splitted[0]
    let year = '20' + splitted[1]

    let today = new Date();
    let someday = new Date();

    someday.setFullYear(year, month, 1);
    if (someday < today) {
      isValid = false
    }
  }

  return isValid
}

function isMMYYFormat(string) {
  let regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/
  let isValid = false;

  if (regex.test(string)) {
    isValid = true;
  }
  return isValid
}

function isCvcValid(string) {
  let isValid = false;
  const regex = /^[0-9]{3,4}$/

  if (regex.test(string)) {
    isValid = true;
  }

  return isValid
}

function isAreaCode(string) {
  const areaCodeRegex = /^[+]*[0-9]*$/;

  if (string.match(areaCodeRegex)) return true;

  return false;
}

function isEmail(string) {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (string.match(emailRegex)) return true;

  return false;
}

function stringLength(length) {
  return function (string) {
    if (string.length > length) return false;

    return true;
  };
}

function isPasswordLongEnough(password) {
  if (password.length >= 8) return true;

  return false;
}

function doesPasswordContainNumber(password) {
  if (password.match(/^.*[0-9].*$/)) return true;

  return false;
}

function doesPasswordContainCapital(password) {
  if (password.match(/^.*[A-Z].*$/)) return true;

  return false;
}

function doesPasswordContainLowercase(password) {
  if (password.match(/(?=.*[a-z])/)) return true;

  return false;
}

function doesPasswordContainSymbol(password) {
  // eslint-disable-next-line
  const symbolRegex = /(.*\W.*)/;
  if (password.match(symbolRegex)) return true;

  return false;
}

function noSpaces(string) {
  if (string.includes(" ")) return false;
  return true;
}

function isGmcNumber(number) {
  const gmcNumberRegex = new RegExp("^\\d{7}$");
  if (number.match(gmcNumberRegex)) return true;

  return false;
}

export function isUKPostcode(postcode) {
  // remove space from postcode
  // regex that i copied from stack overflow doesn't work
  // with spaces and IDK how to change it to do so,
  // so thats why I remove the space if there is a space
  postcode = postcode.split(" ").join("");
  const postcodeRegex = new RegExp(
    "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})"
  );

  if (postcode.match(postcodeRegex)) return true;

  return false;
}

export function notEmptyValidator(name) {
  return [{ validatorFunction: isNotEmpty, error: `${name} cannot be empty` }];
}

export function nameValidator(name) {
  return [
    { validatorFunction: isNotEmpty, error: `${name} cannot be empty` },
    {
      validatorFunction: onlyLetters,
      error: `${name} can only contain letters`,
    },
    {
      validatorFunction: stringLength(64),
      error: `${name} cannot be longer than 64 characters`,
    },
  ];
}

export function phoneNumberValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Contact number cannot be empty" },
    {
      validatorFunction: isPhoneNumber,
      error: "Invalid phone number",
    },
  ];
}

export function areaCodeValidator() {
  return [
    {
      validatorFunction: isAreaCode,
      error: "Please check the area code is correct",
    },
  ];
}

export function emailValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Email cannot be empty" },
    { validatorFunction: isEmail, error: "This is not a valid email address" },
    {
      validatorFunction: stringLength(64),
      error: "Email cannot be longer than 64 characters",
    },
  ];
}

export function addressValidator(name) {
  return [
    { validatorFunction: isNotEmpty, error: `${name} cannot be empty` },
    {
      validatorFunction: stringLength(64),
      error: `${name} cannot be longer than 64 characters`,
    },
  ];
}

export function zipCodeValidator() {
  return [
    { validatorFunction: isNotEmpty, error: `Postcode cannot be empty` },
    {
      validatorFunction: stringLength(32),
      error: `Postcode cannot be longer than 32 characters`,
    },
  ];
}

export function passwordValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Password cannot be empty" },
    {
      validatorFunction: isPasswordLongEnough,
      error: ValidationErrorMessages.PasswordLength,
    },
    {
      validatorFunction: doesPasswordContainCapital,
      error: ValidationErrorMessages.PasswordCapitalLetter,
    },
    {
      validatorFunction: doesPasswordContainNumber,
      error: ValidationErrorMessages.PasswordAtLeastOneNumber,
    },
    {
      validatorFunction: doesPasswordContainSymbol,
      error: ValidationErrorMessages.PasswordAtLeastOneSymbol,
    },
    {
      validatorFunction: doesPasswordContainLowercase,
      error: ValidationErrorMessages.PasswordLowercaseLetter,
    },
    {
      validatorFunction: noSpaces,
      error: ValidationErrorMessages.PasswordContainsASpace,
    },
  ];
}

export function clinicNameValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Clinic cannot be empty" },
    {
      validatorFunction: stringLength(64),
      error: "Clinic name too long",
    },
  ];
}

export function gmcValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Gmc number cannot be empty" },
    { validatorFunction: isGmcNumber, error: "This is not a valid gmc number" },
  ];
}

export function creditCardValidator() {
  return [
    { validatorFunction: isNotEmpty, error: "Please enter your card number" },
    { validatorFunction: isAmexCard, error: "American express cards are not supported" },
    { validatorFunction: isCreditCard, error: "Please provide a valid card number" },
  ];
}

export function cvcValidator() {
  return [
    { validatorFunction: isCvcValid, error: "Not valid CVC number" },
    { validatorFunction: isNotEmpty, error: "Provide a CVC number" },
  ];
}

export function cardExpirationDateValidator() {
  return [
    { validatorFunction: isMMYYFormat, error: "Format must be MM/YY" },
    { validatorFunction: isCardExpired, error: "Card expired" },
  ];
}

export function isNumberValidator() {
  return [
    { validatorFunction: isNumber, error: "Value must be numeric" },
  ];
}