import { apiClient } from "../ApiClient";

class AddressService {
  async getAddress(searchText) {
    const addresses = await apiClient.getAddress(searchText);

    const result = {};
    for (let address of addresses.addresses) {
      const zipCode = addresses.postcode;

      const key = this._getAddressLine1(address);

      result[key] = {
        zipCode,
        addressLine1: key,
        addressLine2: address.town_or_city,
        country: address.country
      };
    }

    return result;
  }

  _getAddressLine1 = (address) => {
    let result = ''

    if (address.line_1) {
      result += `${address.line_1}`
    }
    if (address.line_2) {
      result += `, ${address.line_2}`
    }
    if (address.line_3) {
      result += `, ${address.line_3}`
    }
    if (address.line_4) {
      result += `, ${address.line_4}`
    }

    return result
  }
}



export const addressService = new AddressService();
