import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useOpayo from '../../../hooks/useOpayo.js'
import LypheLogo from '../../../assets/icons/LypheLogo';

import RepeatPrescriptionLoaderPage from '../../../pages/repeatPrescription/RepeatPrescriptionLoaderPage'
import { getPatientDetails, selectUserDetails, } from '../../../store/slices/accountSlice';
import {
    getAllByPatientsId,
    requestRepeatPrescription,
    selectPatientProducts,
    selectShowRepeatPrescriptionForm,
    selectShowRepeatPrescriptionFormCompleted,
    selectShowRepeatPrescriptionFormGuideline,
    selectSubmitFormLoader,
    setShowRepeatPrescriptionFormCompleted,
    setShowRepeatPrescriptionFormGuideline,
    selectShowRepeatPrescriptionNoEligibleProductsPage,
    setShowRepeatPrescriptionNoEligibleProductsPage,
    selectPatientAnswers,
    selectSubmittingPayment,
    selectPaymentFailed,
    payRepeat,
} from '../../../store/slices/repeatPrescriptionSlice';
import { PrimaryCta } from '../../buttons';
import RepeatPrescriptionHeader from '../../headers/RepeatPrescriptionHeader/RepeatPrescriptionHeader';
import RepeatPrescriptionCompleted from './RepeatPrescriptionCompleted';
import RepeatPrescriptionFormGuideline from './RepeatPrescriptionFormGuideline';
import RepeatPrescriptionProductSelect from './RepeatPrescriptionProductSelect/RepeatPrescriptionProductSelect';

import RepatPrescriptionFormArrow from '../../../assets/icons/RepatPrescriptionFormArrow';
import RepeatPrescriptionFormInfoBannerIcon from '../../../assets/icons/RepeatPrescriptionFormInfoBannerIcon';
import RepeatPrescriptionErrorBanner from '../../banners/RepeatPrescriptionErrorBanner/RepeatPrescriptionErrorBanner';
import { selectUser } from '../../../store/slices/authenticationSlice';
import Spinner from '../../sharedComponents/Spinner/Spinner';
import RepeatPrescriptionNoEligibleProductsPage from './RepeatPrescriptionNoEligibleProductsPage';
import { SubscriptionTypes, toastTypes } from '../../../enums';
import RepeatPrescriptionInfoBanner from '../../banners/RepeatPrescriptionInfoBanner/RepeatPrescriptionInfoBanner';
import useProps from '../../../hooks/useProps.js';
import { addressValidator, cardExpirationDateValidator, creditCardValidator, cvcValidator, nameValidator, zipCodeValidator } from '../../../util/validators.js';
import OrderPaymentCard from '../../cards/OrderPaymentCard/OrderPaymentCard.js';
import GenericBanner from '../../banners/GenericBanner/GenericBanner.js';
import { InputField } from '../../inputs/index.js';
import { AddressAutoCompleteForm } from '../index.js';
import CreditCardForm from '../CreditCardForm/CreditCardForm.js';
import { setToast } from '../../../store/slices/toastSlice'
import { isFormValid } from '../../../util/util'
import AttentionIcon from '../../../assets/icons/AttentionIcon.js';


export default function RepeatPrescriptionForm() {
    const dispatch = useDispatch();

    const userDetails = useSelector(
        selectUserDetails
    )

    const patientId = userDetails.id

    const submittingPayment = useSelector(selectSubmittingPayment);
    const paymentFailed = useSelector(selectPaymentFailed);

    const autoComplete = useProps("");

    const address1 = useProps("", addressValidator("Address 1"));
    const address2 = useProps("", addressValidator("Address 2"));
    const zipCode = useProps("", zipCodeValidator());

    const addressForm = { address1, address2, zipCode };

    const ref = useRef(false);

    const valueTransformer = (value, event) => {
        if (ref.current) {
            ref.current = false
            return value
        }
        if (value.length === 2 && !value.includes('/')) {
            ref.current = true
            return value + '/'
        }
        return value
    }
    const removeWhiteSpaceFromCardNumberTransformer = (value) => {
        return value.split(" ").join("")
    }

    const cardHolderFirstName = useProps("", nameValidator('Cardholders first name'))
    const cardHolderLastName = useProps("", nameValidator('Cardholders last name'))
    const cardNumber = useProps('', creditCardValidator(), removeWhiteSpaceFromCardNumberTransformer, true)
    const expiryDate = useProps('', cardExpirationDateValidator(), valueTransformer)
    const cvc = useProps('', cvcValidator())

    const { errors: cardNumberErrors } = cardNumber;

    const [repeatPrescriptionProducts, setRepeatPrescriptionProducts] = useState([])
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(true)

    const [showPaymentErrorBanner, setShowPaymentErrorBanner] = useState(false) //TODO Cleanup all these
    const [showQntErrorBanner, setShowQntErrorBanner] = useState(false)
    const [showFeeInfoBanner, setShowFeeInfoBanner] = useState(false)

    useEffect(() => {
        if (cardNumberErrors.includes('American express cards are not supported')) {

            const newToastState = {
                message: 'American Express cards are not supported. Please use a different card type.',
                title: 'Card not supported',
                showToast: true,
                type: toastTypes.Error
            }

            dispatch(setToast(newToastState))
        }
        // eslint-disable-next-line
    }, [cardNumberErrors])

    const { merchantSessionKey,
        cardIdentifier,
        getOpayoTransactionData,
        setMerchantSessionKey,
        setCardIdentifier } = useOpayo({
            cardHolderName: `${cardHolderFirstName.value} ${cardHolderLastName.value}`,
            cardNumber: cardNumber.value,
            expiryDate: expiryDate.value.split('/').join(''),
            cvc: cvc.value
        })

    const form = {
        ...addressForm,
        cardHolderFirstName,
        cardHolderLastName,
        cardNumber,
        expiryDate,
        cvc
    }

    const errorToast = {
        message: 'Please check the validity of entered fields or try again later. If the problem persists, please contact support.',
        title: 'An error occured!',
        showToast: true,
        type: toastTypes.Error
    }

    useEffect(() => {
        //Only patients that arent subscribed are charged for repeats
        if (userDetails.subscriptionType === SubscriptionTypes.None) {
            if (merchantSessionKey && cardIdentifier) {
                const handlePayRepeat = async () => {
                    const result = await dispatch(payRepeat({
                        cardNumber: cardNumber.value,
                        cardHolderFirstName: cardHolderFirstName.value,
                        cardHolderLastName: cardHolderLastName.value,
                        expiryDate: expiryDate.value.split('/').join(''),
                        cvc: cvc.value,
                        address1: address1.value,
                        address2: address2.value,
                        zipCode: zipCode.value,
                        merchantSessionKey,
                        cardIdentifier,
                        products: repeatPrescriptionProducts,
                        comment: comment,
                        patientId: patientId,
                        patientAnswers: patientAnswers
                    }));

                    if (result.meta.requestStatus === 'rejected') {
                        dispatch(setToast(errorToast))
                    }

                    if (result.meta.requestStatus === 'fulfilled') {
                        dispatch(setShowRepeatPrescriptionFormCompleted(true));
                    }
                }

                handlePayRepeat();
                setMerchantSessionKey(null)
                setCardIdentifier(null)
            }
        }
        // eslint-disable-next-line
    }, [merchantSessionKey, cardIdentifier])

    const handleOnClick = async () => {
        //If the user is subscribed, submit the repeat prescription
        if (userDetails.subscriptionType !== SubscriptionTypes.None) {

            const result = await dispatch(requestRepeatPrescription({ products: repeatPrescriptionProducts, comment, patientId: patientId, patientAnswers }))

            if (result.meta.requestStatus === 'fulfilled') {
                dispatch(setShowRepeatPrescriptionFormCompleted(true));
            }
            else {
                dispatch(setToast(errorToast))
            }
        }
        else {
            //This triggers the useEffect that sets the session key and card identifier
            getOpayoTransactionData()
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getAllByPatientsId(patientId)),
                dispatch(getPatientDetails()),
            ]);

            setLoading(false);
        };

        fetchData();

        // eslint-disable-next-line
    }, []);

    const submitLoader = useSelector(
        selectSubmitFormLoader
    )

    const patientProducts = useSelector(
        selectPatientProducts
    );

    const showRepeatPrescriptionForm = useSelector(
        selectShowRepeatPrescriptionForm
    );

    const showRepeatPrescriptionFormCompleted = useSelector(
        selectShowRepeatPrescriptionFormCompleted
    );

    const showRepeatPrescriptionFormGuideline = useSelector(
        selectShowRepeatPrescriptionFormGuideline
    );

    const showRepeatPrescriptionNoEligibleProductsPage = useSelector(
        selectShowRepeatPrescriptionNoEligibleProductsPage
    );

    const hadnleBackButton = () => {
        dispatch(setShowRepeatPrescriptionFormGuideline(true))
    }

    const userInfo = useSelector(
        selectUser
    );

    const patientAnswers = useSelector(
        selectPatientAnswers
    );


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showRepeatPrescriptionNoEligibleProductsPage, showRepeatPrescriptionFormGuideline, showRepeatPrescriptionFormCompleted])

    useEffect(() => {
        const productWithZeroQnt = repeatPrescriptionProducts.find(product => product.quantity === 0)
        productWithZeroQnt ? setShowQntErrorBanner(true) : setShowQntErrorBanner(false)
        userDetails.subscriptionType !== SubscriptionTypes.None ? setShowFeeInfoBanner(true) : setShowFeeInfoBanner(false)

    }, [repeatPrescriptionProducts])


    useEffect(() => {
        if (patientProducts.length === 0) {
            dispatch(setShowRepeatPrescriptionNoEligibleProductsPage(true))
        }
    }, [patientProducts])

    //Handle change quantity for each individual product in patient products
    const handleChange = (value, id, name) => {
        let patientProduct = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.find((product) => product.id == id)
        const patientProductIndex = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.findIndex((product) => product.id == id)
        if (patientProduct) {
            patientProduct = { ...patientProduct, quantity: value }
            let currentProducts = [...repeatPrescriptionProducts]
            currentProducts.splice(patientProductIndex, 1, patientProduct)
            setRepeatPrescriptionProducts(currentProducts)
        } else {
            const newProduct = {
                "name": name,
                "quantity": value,
                "id": id
            }
            setRepeatPrescriptionProducts([...repeatPrescriptionProducts, newProduct])
        }
    };

    const handleChecked = (value, id, name) => {
        let patientProduct = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.find((product) => product.id == id)
        const patientProductIndex = repeatPrescriptionProducts.length > 0 && repeatPrescriptionProducts.findIndex((product) => product.id == id)

        if (patientProduct && value === false) {
            let currentProducts = [...repeatPrescriptionProducts]
            currentProducts.splice(patientProductIndex, 1)
            setRepeatPrescriptionProducts(currentProducts)

            return
        }

        if (!patientProduct && value === true) {
            const newProduct = {
                "name": name,
                "quantity": 0,
                "id": id
            }
            setRepeatPrescriptionProducts([...repeatPrescriptionProducts, newProduct])
        }
    }

    const isButtonEnabled = () => {
        if (repeatPrescriptionProducts.length === 0) {
            return false
        }
        else if (showQntErrorBanner || showPaymentErrorBanner) {
            return false
        }
        else if (userDetails.subscriptionType === SubscriptionTypes.None && !submittingPayment && !isFormValid(form)) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <>
            {!loading ?
                <div className='repeat_prescription_form'>
                    <RepeatPrescriptionHeader showNativeBrowserWarning={showRepeatPrescriptionForm} />
                    <div className='Lyphe_logo'>
                        <LypheLogo />
                    </div>
                    {showRepeatPrescriptionForm && <div onClick={hadnleBackButton} className='back-to-previous-button'>
                        <RepatPrescriptionFormArrow />
                        <p>Back to previous</p>
                    </div>}
                    <div className='repeat_prescription_form--content'>
                        {showRepeatPrescriptionFormCompleted && <RepeatPrescriptionCompleted />}
                        {showRepeatPrescriptionFormGuideline && <RepeatPrescriptionFormGuideline />}
                        {showRepeatPrescriptionNoEligibleProductsPage && <RepeatPrescriptionNoEligibleProductsPage />}

                        {showRepeatPrescriptionForm && <div className='form'>
                            <div className='form--heading'>
                                <h1>Request a repeat prescription</h1>
                                <div className='divider'></div>
                            </div>
                            <div className='form--top-content'>
                                <div className='form--top-content--heading'>
                                    <h2><span>1</span>Your details</h2>
                                </div>
                                <div className='your_details'>
                                    <span>Patient Name</span>
                                    <p>{userDetails.firstName + " " + userDetails.lastName}</p>
                                    <span>Email</span>
                                    <p>{userInfo.email}</p>
                                    <span>Patient ID</span>
                                    <p>{userDetails.clinicIdNumber}</p>
                                </div>
                                <div className='form--top-content--heading'>
                                    <h2><span>2</span>Your medication</h2>
                                </div>
                                <div className='your_medication'>
                                    <p>Select the medication and the quantity you would like to request.</p>
                                    <div className='info-banner'>
                                        <RepeatPrescriptionFormInfoBannerIcon />
                                        <p>Please make sure you are requesting the amount that you need. If you believe a product is missing that should be here, please
                                            <a className='info-banner-link' target="_blank" rel="noopener noreferrer" href="https://lyphe.com/"> contact us</a> via Live Chat.
                                        </p>
                                    </div>
                                    {patientProducts.map(product => {
                                        return (
                                            <RepeatPrescriptionProductSelect
                                                name={product.name}
                                                weight={product.weight}
                                                handleChange={(value) => handleChange(value, product.id, product.name)}
                                                handleChecked={(value) => handleChecked(value, product.id, product.name)} />
                                        )
                                    })}
                                    <p><b>Where medication is unavailable, you will be prescribed a suitable alternative.</b></p>
                                    <div className='comment-section'>
                                        <span>Comment (optional)</span>
                                        <p>Any additional information you would like us to know.</p>
                                        <textarea value={comment} onChange={(event) => setComment(event.target.value)} placeholder="Your comment..." />
                                    </div>
                                </div>
                                {userDetails.subscriptionType === SubscriptionTypes.None && <>
                                    <div className='form--top-content--heading'>
                                        <h2><span>3</span>Your card details</h2>
                                    </div>
                                    <div className='repeat_prescription_fee-banner'>
                                        <p>Repeat prescription fee</p>
                                        <p>£25</p>
                                    </div>
                                    <div className='payment_card'>
                                        <OrderPaymentCard>
                                            {/* orderpaymentcard is misleading - this is a generic payment form*/}
                                            <div className='order_payment_form'>
                                                <div className='order_payment_form__horizontal_marign'>
                                                    <div className='order_payment_form__title m-b-s' >Card details</div>
                                                    <GenericBanner text={'American Express cards are not supported.'} icon={<AttentionIcon color={'#3B5EDB'} />} />
                                                    <h5 className='m-t-s'>Name on card</h5>
                                                    <InputField type='text' name='Cardholders first name' {...cardHolderFirstName} placeholder='Cardholder first name' />
                                                    <div className='m-t-s'>
                                                        <InputField type='text' name='Cardholders last name' {...cardHolderLastName} placeholder='Cardholder last name' />
                                                    </div>
                                                    <div className='m-t-s'>
                                                        <h5>Billing address</h5>
                                                        <AddressAutoCompleteForm
                                                            autoComplete={autoComplete}
                                                            {...addressForm}
                                                        />
                                                        <CreditCardForm cardNumber={cardNumber} expiryDate={expiryDate} cvc={cvc} />
                                                    </div>
                                                    {paymentFailed &&
                                                        <div className='order_payment_form__payment_failed'>
                                                            <AttentionIcon /> <span>Payment failed, please try again</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </OrderPaymentCard>
                                    </div></>}
                                <div className='request_section'>
                                    <h2>Your request</h2>
                                    {repeatPrescriptionProducts.map(requestedProduct => {
                                        return (
                                            <div className='request_section_info'>
                                                <p>{requestedProduct.name}</p>
                                                <p>{requestedProduct.quantity}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {showFeeInfoBanner && <RepeatPrescriptionInfoBanner type={"info"} text={"As a valued subscription member, your repeat prescription fee is included as part of your membership."} />}
                            {showPaymentErrorBanner && userDetails.subscriptionType === SubscriptionTypes.None && <RepeatPrescriptionErrorBanner type={"error"} text={"Payment failed. Please try again"} />}
                            {showQntErrorBanner && <RepeatPrescriptionErrorBanner type={"error"} text={"Your request cannot be sent with a medication quantity set to 0. Uncheck box if the medication is not required."} />}
                            <div className='submit-button'>
                                <PrimaryCta
                                    color='form'
                                    className='primary_cta--form'
                                    onClick={() => { handleOnClick() }}
                                    isEnabled={isButtonEnabled()}
                                >
                                    {submitLoader ? <Spinner width={20} /> : "Submit"}
                                </PrimaryCta>
                            </div>
                            <p className='form_footer-p'>If you have any questions or issues, please contact <a href="mailto:info@lyphe.com">info@lyphe.com</a></p>
                        </div>}
                    </div>
                </div>
                :
                (<RepeatPrescriptionLoaderPage loading={(loading || Object.keys(patientProducts).length === 0)} />)
            }
        </>
    )
}
