import React from "react";

import { Stepper } from "../../../components";
import {
  OrderingRepeatPrescriptionUploaded,
  PatientAccountUploadPrescription,
} from "../../stepperPages";

function PatientCarerUploadPrescription(props) {
  return (
    <Stepper
      steps={patientCarerUploadSteps}
      firstStep={patientCarerUploadSteps.uploadPrescription}
    />
  );
}

const patientCarerUploadSteps = {
  uploadPrescription: {
    component: PatientAccountUploadPrescription,
    closeWithPopup: true,
  },
  uploadSuccess: { component: OrderingRepeatPrescriptionUploaded },
};

export default PatientCarerUploadPrescription;
