import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  selectActivePrescriptions,
  selectAllPrescriptions, selectClinicsPrescriptionsPaginationData,
  selectPastPrescriptions,
} from "../store/slices/prescriptionsSlice";
import { getPluralText } from "../util/util";
import { selectClinicPatientsFromPatientSliceByKey } from "../store/slices/patientSlice";
import { ClinicPatientTypes, OrganisationPrescriptionsTypes } from "../enums";
import { selectClinicProducts, selectClinicsFilteredProducts, selectClinicFilterNumberOfChecked, selectPatientsFilteredProducts, selectPatientFilterNumberOfChecked, selectPatientProducts } from "../store/slices/productSlice";

function useGetNavBarText() {
  const { pathname } = useLocation();

  const connected = pathname.split("/").join("");

  const activePrescriptions = useSelector(selectActivePrescriptions);
  const allPrescriptions = useSelector(selectAllPrescriptions);
  const pastPrescriptions = useSelector(selectPastPrescriptions);

  const basicClinicPatients = useSelector(selectClinicPatientsFromPatientSliceByKey(ClinicPatientTypes.Basic));
  const fullClinicPatients = useSelector(selectClinicPatientsFromPatientSliceByKey(ClinicPatientTypes.Full));

  const clinicPrescriptionsImageOnlyTotalNumber = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ImageOnly));

  const clinicPrescriptionsToBeAssignedTotalNumber = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ToBeAssigned));
  const clinicPrescriptionsReWriteRequestsTotalNumber = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ReWriteRequests));

  const clinicPrescriptionsActiveTotalNumber = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.Active));

  const clinicPrescriptionsCompleteTotalNumber = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.Complete));

  const clinicProducts = useSelector(selectClinicProducts)
  const clinicFilteredProducts = useSelector(selectClinicsFilteredProducts)
  const clinicProductFilterNumberOfChecked = useSelector(selectClinicFilterNumberOfChecked)

  const patientProducts = useSelector(selectPatientProducts)
  const patientFilteredProducts = useSelector(selectPatientsFilteredProducts)
  const patientProductFilterNumberOfChecked = useSelector(selectPatientFilterNumberOfChecked)

  const pageToListMapper = {
    prescriptionsactive: { number: activePrescriptions?.length, text: "PRESCRIPTION" },
    prescriptionsall: { number: allPrescriptions.length, text: "PRESCRIPTION" },
    prescriptionspast: { number: pastPrescriptions?.length, text: "PRESCRIPTION" },

    patientFormulary: { number: patientProductFilterNumberOfChecked ? patientFilteredProducts?.length : patientProducts?.length, text: "PRODUCT" },

    clinicPrescriptionsimageOnly: { number: clinicPrescriptionsImageOnlyTotalNumber?.totalCount, text: "PRESCRIPTION" },
    clinicPrescriptionstoBeAssigned: { number: clinicPrescriptionsToBeAssignedTotalNumber?.totalCount, text: "PRESCRIPTION" },
    clinicPrescriptions: { number: clinicPrescriptionsImageOnlyTotalNumber?.totalCount, text: "PRESCRIPTION" },
    clinicPrescriptionsreWriteRequests: { number: clinicPrescriptionsReWriteRequestsTotalNumber?.totalCount, text: "PRESCRIPTION" },
    clinicPrescriptionsactive: { number: clinicPrescriptionsActiveTotalNumber?.totalCount, text: "PRESCRIPTION" },
    clinicPrescriptionscomplete: { number: clinicPrescriptionsCompleteTotalNumber?.totalCount, text: "PRESCRIPTION" },

    clinicPatients: { number: basicClinicPatients?.length, text: "PATIENT" },
    clinicPatientsbasic: { number: basicClinicPatients?.length, text: "PATIENT" },
    clinicPatientsfull: { number: fullClinicPatients?.length, text: "PATIENT" },

    clinicFormulary: { number: clinicProductFilterNumberOfChecked ? clinicFilteredProducts?.length : clinicProducts?.length, text: "PRODUCT" }
  };

  const item = pageToListMapper[connected];
  const displayText = getPluralText(item?.text, item?.number);

  return displayText;
}

export default useGetNavBarText;
