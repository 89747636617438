import { MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import RepeatPrescriptionChekbox from './RepeatPrescriptionChekbox';

export default function RepeatPrescriptionProductSelect({ name, weight, handleChange, handleChecked }) {
    const [isChecked, setIsChecked] = useState(false)
    const [qnt, setQnt] = useState('0');

    const onChange = () => {
        setIsChecked(!isChecked)
        handleChecked(!isChecked)

        if (isChecked === false) {
            setQnt(0)
        }
    }

    const onQntChange = (value) => {
        handleChange(value)
        setQnt(value)
    }

    const numArray = Array.from(Array(16).keys())

    return (
        <div className="prodcut_select">
            <div className={`product_select--info ${isChecked ? "m-b-0" : ""}`}>
                <p>{name}</p>
                <RepeatPrescriptionChekbox isChecked={isChecked} onChange={onChange} />
            </div>
            {isChecked && <div className='prodcut_select-select'>
                <p style={{ fontWeight: "400" }}>Quantity:</p>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={qnt}
                    label="Quantity"
                    onChange={(event) => onQntChange(event.target.value)}
                >
                    {numArray.map(menuItem => {
                        return <MenuItem value={menuItem}>{menuItem}</MenuItem>
                    })}

                </Select>
            </div>}

        </div>
    )
}
