import React, { useEffect } from "react";

import { Stepper } from "../../../components";
import { ChangingPassword, PasswordHasBeenChanged } from "../../stepperPages";

function ChangePassword(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Stepper
      steps={changePasswordSteps}
      firstStep={changePasswordSteps.changePassword}
    />
  );
}

const changePasswordSteps = {
  changePassword: {
    component: ChangingPassword,
  },

  success: {
    component: PasswordHasBeenChanged,
  },
};

export default ChangePassword;
