import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectGenericSuccessModal, setGenericSuccessModal, setPayModal } from '../../../store/slices/modalsSlice';
import { PrimaryCta } from '../../buttons';
import { FullScreenOverlay } from '../../overlays';
import { ReactComponent as SuccessfulPaymentIcon } from "../../../assets/images/successful_payment.svg";

const GenericSuccessPopup
    = () => {
        const dispatch = useDispatch()
        const { show, title, text } = useSelector(selectGenericSuccessModal);
        function onClose() {
            dispatch(setGenericSuccessModal({ show: false, title, text }));
            dispatch(setPayModal({ show: false, orderId: null }));
        }

        return (
            <FullScreenOverlay show={show}>
                <div className='payment_requested_popup'>
                    <div className="verification_card">
                        <div className="verification_card__content ">
                            <div className='payment_requested_popup__icon' >
                                <SuccessfulPaymentIcon />
                            </div>
                            <div>
                                <h1 className="h8-a">{title}</h1>
                            </div>
                            <p className='payment_requested_popup__text'>
                                {text}
                            </p>
                            <div className="verification_card__buttons">
                                <div className="h-40">
                                    <PrimaryCta
                                        text="Ok"
                                        onClick={onClose}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreenOverlay>
        )
    }

export default GenericSuccessPopup

