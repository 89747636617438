import React from "react";

function BigTickIcon(props) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#57b799"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path d="M50.3,97c-26-0.1-47-21.1-46.9-47.1S24.5,2.9,50.5,3c5,0,9.9,0.8,14.6,2.4c1.1,0.3,1.7,1.4,1.4,2.5S65,9.5,64,9.2  c0,0-0.1,0-0.1,0C41.1,1.7,16.6,14.1,9.2,36.8C7.8,41.1,7,45.5,7,50c0.2,23.9,19.8,43.1,43.7,42.9C71.8,92.7,89.6,77.3,93,56.5  c0.2-1.1,1.2-1.9,2.3-1.7c1.1,0.2,1.9,1.2,1.7,2.3l0,0C93.5,79.9,73.4,97,50.3,97z"></path>
      <path d="M48.5,65.6c-0.5,0-1-0.2-1.4-0.6L27.6,45.6c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l18.1,18L90,19.5  c0.7-0.8,2-0.9,2.8-0.1c0.8,0.7,0.9,2,0.1,2.8c0,0-0.1,0.1-0.1,0.1L49.9,65C49.5,65.4,49,65.6,48.5,65.6z"></path>
    </svg>
  );
}

export default BigTickIcon;
