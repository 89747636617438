import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import axios from "./store/axios";
import { logout } from "./store/slices/authenticationSlice";

interceptor(store);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * Intercept every response and redirect if it's 403
 */
function interceptor(store) {
  axios.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
}
