import React from "react";
import { CloseIcon } from "../../../assets/icons";
import { FullScreenOverlay } from "../../overlays";
import { PrimaryCta } from "../../../components/buttons"

function ViewPrescription(props) {
    const { showPopup, onClose, image } = props;
    const isPdf = image?.toLowerCase().includes('.pdf');

    //Used for opening an image in a new tab
    const openImageInTab = () => {
        const imageUrl = image;
        const imageWindow = window.open('', '_blank');

        imageWindow.document.write('<html><head><title>Your Prescription</title>');

        imageWindow.document.write('<style>');
        imageWindow.document.write('body { margin: 0; display: flex; align-items: center; justify-content: center; height: 100vh; }');
        imageWindow.document.write('img { max-width: 100%; max-height: 100%; transition: transform 0.25s ease-in-out; cursor: zoom-in; }');
        imageWindow.document.write('.zoomed-in { transform: scale(2); cursor: zoom-out; }');
        imageWindow.document.write('</style>');

        imageWindow.document.write('<script type="text/javascript">');
        imageWindow.document.write('function toggleZoom() { document.querySelector("img").classList.toggle("zoomed-in"); }');
        imageWindow.document.write('function closeTab() { window.close(); }');

        imageWindow.document.write('</script></head><body>');
        imageWindow.document.write(`<img src="${imageUrl}" alt="Your Prescription" onclick="toggleZoom()"/>`);
        imageWindow.document.write('</body></html>');
        imageWindow.document.close();
    }

    return (
        <div className='viewPerscriptionPopup' >
            <FullScreenOverlay show={showPopup}>
                <div className='viewPerscriptionPopup__content'>
                    <div className="verification_card">
                        <div style={{ paddingLeft: '0', paddingRight: '0' }} className="verification_card__content ">
                            <div className='viewPerscriptionPopup__content__header' >
                                <h1 className="h8-a viewPerscriptionPopup__content__header__title">Prescription</h1>
                                <div className='viewPerscriptionPopup__content__header__close' onClick={onClose}>
                                    <CloseIcon />
                                </div>
                            </div>
                            <div className="verification_card__content__image_container">
                                {isPdf ?
                                    image && <iframe title='view prescription popup ' key={image} src={image} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                    :
                                    image && <img src={image} alt="Prescription not found" />
                                }
                                <div className="verification_card__Prescriptionbuttons">
                                    <PrimaryCta
                                        onClick={() => isPdf ? window.open(image, "_blank") : openImageInTab()}
                                        text="View in a new tab"
                                    ></PrimaryCta>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FullScreenOverlay>
        </div>
    )
}

export default ViewPrescription;
