import React from "react";

function BlueInfoIcon() {
  return (
    <svg
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M47 0C59.4652 0 71.4198 4.95177 80.234 13.766C89.0482 22.5802 94 34.5348 94 47C94 59.4652 89.0482 71.4198 80.234 80.234C71.4198 89.0482 59.4652 94 47 94C34.5348 94 22.5802 89.0482 13.766 80.234C4.95177 71.4198 0 59.4652 0 47C0 34.5348 4.95177 22.5802 13.766 13.766C22.5802 4.95177 34.5348 0 47 0ZM47 4.94737C35.847 4.94737 25.1507 9.3779 17.2643 17.2643C9.3779 25.1507 4.94737 35.847 4.94737 47C4.94737 58.1531 9.3779 68.8493 17.2643 76.7357C25.1507 84.6221 35.847 89.0526 47 89.0526C58.1531 89.0526 68.8493 84.6221 76.7357 76.7357C84.6221 68.8493 89.0526 58.1531 89.0526 47C89.0526 35.847 84.6221 25.1507 76.7357 17.2643C68.8493 9.3779 58.1531 4.94737 47 4.94737ZM44.5263 24.7368V34.6316H49.4737V24.7368H44.5263ZM44.5263 44.5263V69.2632H49.4737V44.5263H44.5263Z" fill="#5D71BA" />
    </svg>

  );
}

export default BlueInfoIcon;
