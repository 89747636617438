import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganisation } from "../../../store/slices/accountSlice";
import { PrimaryCta } from "../../../components/buttons";
import { AccountHeader } from "../../../components/headers";
import { refreshTokenForUser } from "../../../store/slices/authenticationSlice";
import { useHistory } from "react-router-dom";
import { UnlinkIcon } from "../../../assets/icons";

function OrganisationGetStarted() {
  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const dispatch = useDispatch();
  const history = useHistory();

  const refreshUserToken = async () => {
    const { error } = await dispatch(refreshTokenForUser());

    if (!error) {
      history.push("/clinicPrescriptions");
    }
  }

  return (
    <section>
      <AccountHeader />
      <div className={"organisation-get-started__container"}>
        <div className={"organisation-get-started__icon"}>
          <UnlinkIcon></UnlinkIcon>
        </div>
        <h1 className="h3-a">You have been invited to</h1>
        <h1 className="h3-a">{currentOrganisation.organisationName}</h1>
        <p className="p3 m-t-s">
          You can now upload and track your prescriptions at this clinic.
        </p>
        <div className={"organisation-get-started__cta"}>
          <PrimaryCta onClick={refreshUserToken} text={"Get Started"} color={"dark_blue"}></PrimaryCta>
        </div>
      </div>
    </section>

  );
}

export default OrganisationGetStarted;
