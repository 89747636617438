import React from "react";

function OrderStatusDispatchedIcon(props) {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M44.7326 44.2936H0V46H44.7326V44.2936Z" fill="black" />
        <path
          d="M24.0231 7.45547C24.7632 7.5728 25.4599 7.88117 26.0443 8.35012C26.5759 8.78406 27.2249 9.04943 27.9082 9.11223H30.6501V20.2954H17.396V9.11223H20.138C20.8213 9.04943 21.4703 8.78406 22.0018 8.35012C22.5863 7.88117 23.283 7.5728 24.0231 7.45547ZM24.0231 5.79871C22.2255 5.79871 20.7095 7.45547 20.138 7.45547H17.396C16.9566 7.45547 16.5352 7.63002 16.2245 7.94072C15.9138 8.25143 15.7393 8.67283 15.7393 9.11223V20.2954C15.7393 20.7348 15.9138 21.1562 16.2245 21.4669C16.5352 21.7776 16.9566 21.9521 17.396 21.9521H30.6501C31.0895 21.9521 31.5109 21.7776 31.8216 21.4669C32.1323 21.1562 32.3069 20.7348 32.3069 20.2954V9.11223C32.3069 8.67283 32.1323 8.25143 31.8216 7.94072C31.5109 7.63002 31.0895 7.45547 30.6501 7.45547H27.9082C27.3366 7.45547 25.8207 5.79871 24.0231 5.79871Z"
          fill="black"
        />
        <path
          d="M27.3368 39.7623H20.7097C20.49 39.7623 20.2793 39.8496 20.124 40.005C19.9686 40.1603 19.8813 40.371 19.8813 40.5907C19.8813 40.8104 19.9686 41.0211 20.124 41.1765C20.2793 41.3318 20.49 41.4191 20.7097 41.4191H27.3368C27.5565 41.4191 27.7672 41.3318 27.9225 41.1765C28.0779 41.0211 28.1652 40.8104 28.1652 40.5907C28.1652 40.371 28.0779 40.1603 27.9225 40.005C27.7672 39.8496 27.5565 39.7623 27.3368 39.7623Z"
          fill="black"
        />
        <path
          d="M33.1351 24.8514H29.8215C29.6018 24.8514 29.3911 24.9387 29.2358 25.0941C29.0804 25.2494 28.9932 25.4601 28.9932 25.6798C28.9932 25.8995 29.0804 26.1102 29.2358 26.2656C29.3911 26.4209 29.6018 26.5082 29.8215 26.5082H33.1351C33.3548 26.5082 33.5655 26.4209 33.7208 26.2656C33.8762 26.1102 33.9635 25.8995 33.9635 25.6798C33.9635 25.4601 33.8762 25.2494 33.7208 25.0941C33.5655 24.9387 33.3548 24.8514 33.1351 24.8514Z"
          fill="black"
        />
        <path
          d="M30.6501 30.65V35.6203H17.396V30.65H30.6501ZM30.6501 28.9933H17.396C16.9566 28.9933 16.5352 29.1678 16.2245 29.4785C15.9138 29.7892 15.7393 30.2106 15.7393 30.65V35.6203C15.7393 36.0597 15.9138 36.4811 16.2245 36.7918C16.5352 37.1025 16.9566 37.2771 17.396 37.2771H30.6501C31.0895 37.2771 31.5109 37.1025 31.8216 36.7918C32.1323 36.4811 32.3069 36.0597 32.3069 35.6203V30.65C32.3069 30.2106 32.1323 29.7892 31.8216 29.4785C31.5109 29.1678 31.0895 28.9933 30.6501 28.9933Z"
          fill="black"
        />
        <path
          d="M37.2773 26.5082V44.3184H10.7691V44.7326H9.1123V45.9751H38.934V26.5082H37.2773Z"
          fill="black"
        />
        <path
          d="M11.183 36.8878V44.3433H3.72756V36.8878H11.183ZM11.183 35.2311H3.72756C3.28816 35.2311 2.86676 35.4056 2.55606 35.7163C2.24535 36.027 2.0708 36.4484 2.0708 36.8878V44.3433C2.0708 44.7827 2.24535 45.2041 2.55606 45.5148C2.86676 45.8255 3.28816 46 3.72756 46H11.183C11.6224 46 12.0438 45.8255 12.3545 45.5148C12.6652 45.2041 12.8398 44.7827 12.8398 44.3433V36.8878C12.8398 36.4484 12.6652 36.027 12.3545 35.7163C12.0438 35.4056 11.6224 35.2311 11.183 35.2311Z"
          fill="black"
        />
        <path
          d="M8.69803 39.373L7.45546 38.5446L6.21289 39.373V35.2311H8.69803V39.373Z"
          fill="black"
        />
        <path
          d="M37.2773 0H10.7691C10.3297 0 9.90826 0.174551 9.59756 0.485254C9.28686 0.795958 9.1123 1.21736 9.1123 1.65676V36.4488H10.7691V1.65676H37.2773V44.3184H12.4258V45.9752H37.2773C37.7167 45.9752 38.1381 45.8006 38.4488 45.4899C38.7595 45.1792 38.934 44.7578 38.934 44.3184V1.65676C38.934 1.21736 38.7595 0.795958 38.4488 0.485254C38.1381 0.174551 37.7167 0 37.2773 0V0Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default OrderStatusDispatchedIcon;
