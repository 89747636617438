import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { UserDetailsCard, PrescriptionCard } from "../../../components/cards";
import {
  selectPatient,
  selectPrescription,
  setPatient,
  setPrescription as setPrescriptionAction,
} from "../../../store/slices/patientOnboardingSlice";
import { useAgeSelect, useProps, useUploadFile } from "../../../hooks";
import { AgeSelect } from "../../../components/inputs";
import {
  NameForm,
  AddressForm,
  ContactNumberForm,
} from "../../../components/forms";
import {
  addressValidator,
  areaCodeValidator,
  nameValidator,
  phoneNumberValidator,
  zipCodeValidator,
} from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";
import {
  selectPrescriptionModal,
  setPrescriptionModal,
} from "../../../store/slices/modalsSlice";
import { ViewPrescription } from "../../../components/popups";

function PatientConfirmDetails({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const prescriptionFromStore = useSelector(selectPrescription);
  const patient = useSelector(selectPatient);
  const patientCardContent = getPatientDetailsContent(patient);
  const prescriptionModal = useSelector(selectPrescriptionModal);

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [editingDetails, setEditingDetails] = useState(false);
  const [editingPrescription, setEditingPrescription] = useState(false);

  const title = useProps(patient.title);
  const firstName = useProps(patient.firstName, nameValidator("First name"));
  const lastName = useProps(patient.lastName, nameValidator("Last name"));
  const nameForm = { title, firstName, lastName };

  const areaCode = useProps(
    patient.contactNumber?.areaCode,
    areaCodeValidator()
  );
  const number = useProps(
    patient.contactNumber?.number,
    phoneNumberValidator()
  );
  const contactNumberForm = { areaCode, number };

  const address1 = useProps(patient.address1, addressValidator("Address 1"));
  const address2 = useProps(patient.address2, addressValidator("Address 2"));
  const zipCode = useProps(patient.zipCode, zipCodeValidator());
  const addressForm = { address1, address2, zipCode };

  const [ageConfig, ageValues, ageError] = useAgeSelect(
    patient.dateOfBirth?.day,
    patient.dateOfBirth?.month,
    patient.dateOfBirth?.year
  );

  const fileInputRef = useRef(null);
  const [
    prescription,
    prescriptionError,
    resetPrescription,
    fileSelected,
  ] = useUploadFile(prescriptionFromStore);

  const isSaveButtonEnabled =
    isFormValid(nameForm) &&
    isFormValid(addressForm) &&
    isFormValid(contactNumberForm) &&
    ageValues.day &&
    ageValues.year &&
    ageValues.month &&
    !ageError;

  function cancelClicked() {
    resetPatientFields();
    resetPrescription();
    setEditingPrescription(false);
    setEditingDetails(false);
  }

  function cancelPatientClicked() {
    setEditingDetails(false);
    resetPatientFields();
  }

  function resetPatientFields() {
    address1.reset();
    address2.reset();
    zipCode.reset();
    title.reset();
    firstName.reset();
    lastName.reset();
    areaCode.reset();
    number.reset();
  }

  function saveClicked() {
    const payload = {
      ...propsToValues(nameForm),
      ...propsToValues(addressForm),
      contactNumber: { areaCode: areaCode.value, number: number.value },
      dateOfBirth: {
        year: ageValues.year,
        month: ageValues.month,
        day: ageValues.day,
      },
      secretId: patient.secretId,
      email: patient.email || undefined,
    };

    dispatch(setPatient(payload));

    dispatch(setPrescriptionAction(prescription));

    setEditingDetails(false);
    setEditingPrescription(false);
  }

  function editPrescriptionClicked() {
    fileInputRef.current.click();
  }

  function onFileSelect(e) {
    const error = fileSelected(e);

    if (!error) setEditingPrescription(true);
  }
  return (
    <section className="confirm_details">
      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null }))
        }
      />

      <div className="confirm_details__content">
        <div className="confirm_details__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please confirm that all details are correct</h1>
        </div>

        <div className="confirm_details__cards">
          <div>
            <div className="user_details">
              <div className="user_details__header">
                <p className="p3--semi-bold">Your details</p>

                {editingDetails ? (
                  <p className="text_link" onClick={cancelPatientClicked}>
                    Cancel
                  </p>
                ) : (
                  <p
                    className="text_link"
                    onClick={() => setEditingDetails(true)}
                  >
                    Edit
                  </p>
                )}
              </div>

              {editingDetails ? (
                <div>
                  <NameForm {...nameForm} />
                  <AgeSelect {...ageConfig} error={ageError} />
                  <AddressForm {...addressForm} />
                  <ContactNumberForm {...contactNumberForm} />
                </div>
              ) : (
                <UserDetailsCard content={patientCardContent} />
              )}
            </div>
          </div>

          {!patient.secretId && (
            <div>
              <input
                ref={(element) => (fileInputRef.current = element)}
                type="file"
                hidden
                id="prescription"
                onChange={onFileSelect}
                accept="image/*,application/pdf"
              />
              <PrescriptionCard
                title="Prescription"
                image={prescription.url}
                imageName={prescription.name}
                actionName="Edit"
                onClick={editPrescriptionClicked}
              />

              {prescriptionError && (
                <div className="confirm_details__error">
                  Image size must be under 20 MB
                </div>
              )}
            </div>
          )}
        </div>

        {editingDetails || editingPrescription ? (
          <div className="confirm_details__buttons">
            <div>
              <PrimaryCta
                text="Cancel changes"
                color="white"
                onClick={cancelClicked}
              />
            </div>
            <div>
              <PrimaryCta
                text="Save changes"
                color="orange"
                isEnabled={isSaveButtonEnabled}
                onClick={saveClicked}
              />
            </div>
          </div>
        ) : (
          <div className="confirm_details__button">
            <PrimaryCta
              text="Confirm"
              color="orange"
              onClick={() => {
                setNextStep(steps.patientOneLastStep);
                trackAnalyticsEvent("Patient Onboarding", "Patient confirmed details");
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
}

export default PatientConfirmDetails;

function getPatientDetailsContent(patient) {
  return [
    {
      title: "FULL NAME",
      text: `${patient.title} ${patient.firstName} ${patient.lastName}`,
    },
    {
      title: "AGE",
      text: `${patient.dateOfBirth?.day}-${patient.dateOfBirth?.month}-${patient.dateOfBirth?.year}`,
    },
    {
      title: "MEDICATION FOR",
      text: `Myself`,
    },
    {
      title: "ADDRESS",
      text: `${patient.address1}, ${patient.address2}, ${patient.zipCode}`,
    },
    {
      title: "CONTACT",
      text: `${patient.contactNumber?.areaCode} ${patient.contactNumber?.number}`,
    },
  ];
}
