import React from "react";
import { FullScreenOverlay } from "../../overlays";
import { CloseIcon } from "../../../assets/icons";

function CustomTwoSidedModal(
  { children,
    show = false,
    onClose = () => null,
    leftContent = () => null,
    rightContent = () => null,
    buttons = () => null,
    title = ""
  }
) {

  return (
    <FullScreenOverlay onClick={() => onClose()} show={show}>
      <div onClick={(e) => e.stopPropagation()} className="custom_two_sided_modal">
        <div className="custom_two_sided_modal__header">
          {title}  <span className={"custom_two_sided_modal__header__close"} onClick={() => { onClose() }}><CloseIcon /> </span>
        </div>
        <div className="custom_two_sided_modal__content" >
          <div className="custom_two_sided_modal__content__left">
            {leftContent()}

          </div>
          <div className="custom_two_sided_modal__content__right">
            {rightContent()}
          </div>
        </div>
        <div className="custom_two_sided_modal__footer">
          <div className="custom_two_sided_modal__footer__buttons" >
            {buttons()}
          </div>
        </div>
      </div>
    </FullScreenOverlay>
  );
}

export default CustomTwoSidedModal;