import React from "react";
import { useSelector } from "react-redux";
import WhatHappensNextCard from "../../../components/cards/WhatHappensNextCard/WhatHappensNextCard";
import { TipsCarousel } from "../../../components/carousels";
import { AfterRegistrationTipsWithPrescriptionNewFlow } from "../../../enums";
import { selectPatient } from "../../../store/slices/patientOnboardingSlice";

function CheckYourEmailNewFlow({ setNextStep, steps }) {
  const patient = useSelector(selectPatient);
  return (
    <section className="check_your_email">
      <div className="check_your_email__content">
        <div className="check_your_email__title">
          <p className="label--caps--normal">verify your email</p>
          <h1 className="h3-a">Please check your email</h1>
          <p className="p5">
            We sent an email to {patient.email}. To access your Lyphe Dispensary account,
            please click on the verification link in the email. Remember to check your spam
            folder if a few minutes have passed and you can’t see the email in your inbox.
          </p>
        </div>

        <div className="check_your_email__cards">
          <div className="check_your_email__cards__title">
            <p className="h8-a">What happens next?</p>
          </div>
          <div className="check_your_email__cards__text">
            {AfterRegistrationTipsWithPrescriptionNewFlow.map((step, index) => (
              <div key={index}>
                <WhatHappensNextCard {...step} />
              </div>
            ))}
          </div>
        </div>

        <div className="check_your_email__cards--mobile">
          <TipsCarousel tips={AfterRegistrationTipsWithPrescriptionNewFlow} />
        </div>

      </div>
      <div className="check_your_email__footer">
        <div className='check_your_email__footer__question'>
          It’s been 5 minutes and no email?
        </div>
        <div>
          contact us at <a href="mailto:info@lyphe.com">info@lyphe.com</a>
        </div>
      </div>
    </section>
  );
}

export default CheckYourEmailNewFlow;
