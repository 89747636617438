import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRoleToFunctionMapper } from "../enums";
import { selectUser } from "../store/slices/authenticationSlice";
import ReactGA from 'react-ga';


function useGetUserDetails() {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user) return;

    const getUserDetails = UserRoleToFunctionMapper[user.role];

    ReactGA.initialize('UA-184803219-1', {
      gaOptions: {
        userId: user.id,
      }
    });

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (getUserDetails === undefined) return;

    dispatch(getUserDetails());
  }, [dispatch, user]);
}



export default useGetUserDetails;
