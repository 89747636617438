import { useEffect } from "react";

import { useProps } from ".";
import { passwordValidator } from "../util/validators";

/**
 * This hook can be used when you need a way to
 * check if passwords match and to set errors if
 * they don't
 *
 * @example
 *
 * const [
 *    password,
 *    resetPassword,
 *    checkIfPasswordsMatch
 * ] = usePasswordInput();
 *
 * function onClick(){
 *  try{
 *    checkIfPasswordsMatch();
 *
 *    doSomething();
 *   } catch(error){}
 *
 * }
 *
 * ...
 *
 *
 * <InputField type="password" {...password}  />
 * <InputField type="password" {...confirmPassword}  />
 *
 * <button  onClick={onClick}/>
 *
 *
 */
function usePasswordInput() {
  const password = useProps("", passwordValidator());
  const confirmPassword = useProps("");

  // reset password errors when user changes
  // the input for password or confirm password
  useEffect(() => {
    if (
      password.errors[0] === passwordErrorMessage ||
      confirmPassword.errors[0] === passwordErrorMessage
    ) {
      password.setErrors([]);
      confirmPassword.setErrors([]);
    }
    // eslint-disable-next-line
  }, [password.value, confirmPassword.value]);

  /**
   * If passwords don't match, the function throws an
   * error and it is up the invoking function to catch that
   */
  function checkIfPasswordsMatch() {
    if (password.value !== confirmPassword.value) {
      password.setErrors([passwordErrorMessage]);
      confirmPassword.setErrors([passwordErrorMessage]);
      throw new Error("Passwords do not match");
    }
  }

  return [password, confirmPassword, checkIfPasswordsMatch];
}

const passwordErrorMessage = "Passwords don't match";

export default usePasswordInput;
