import React from "react";

import { PrimaryCta } from "../../../components/buttons";

import { BigTickIcon } from "../../../assets/icons";

function OrderingRepeatPrescriptionUploaded(props) {
  return (
    <section className="repeat_prescription_uploaded">
      <div className="repeat_prescription_uploaded__content">
        <div className="repeat_prescription_uploaded__image">
          <BigTickIcon />
        </div>
        <div className="repeat_prescription_uploaded__title">
          <p className="label--caps--normal">Past prescriptions</p>
          <h1 className="h3-a">Your prescription has been uploaded</h1>
          <p className="p5">
            Your prescription is now being processed. We will notify you on
            email when this is ready.
          </p>
        </div>

        <div className="repeat_prescription_uploaded__button">
          <PrimaryCta
            color="orange"
            text="Close window"
            link="/prescriptions/active"
          />
        </div>
      </div>
    </section>
  );
}

export default OrderingRepeatPrescriptionUploaded;
