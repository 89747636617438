import { configureStore } from "@reduxjs/toolkit";

import authentication from "./slices/authenticationSlice";
import patientOnboarding from "./slices/patientOnboardingSlice";
import carerOnboarding from "./slices/carerOnboardingSlice";
import doctorOnboarding from "./slices/doctorOnboardingSlice";
import prescriptions from "./slices/prescriptionsSlice";
import account from "./slices/accountSlice";
import modals from "./slices/modalsSlice";
import toast from './slices/toastSlice';
import patients from './slices/patientSlice';
import repeatPrescription from './slices/repeatPrescriptionSlice';
import products from './slices/productSlice';

export default configureStore({
  reducer: {
    authentication,
    patientOnboarding,
    carerOnboarding,
    doctorOnboarding,
    prescriptions,
    account,
    modals,
    toast,
    patients,
    repeatPrescription,
    products
  },
});
