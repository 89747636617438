import React from "react";

function FollowLinkIcon(props) {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.0044 6.58984L25.9093 7.49544L16.3157 17.089L15.4108 16.1841L25.0044 6.58984Z" fill="#3B5EDB" />
      <path
        d="M26.1001 12.8004H24.8201V7.68039H19.7001V6.40039H26.1001V12.8004ZM22.9001 24.3204H10.1001C9.01205 24.3204 8.18005 23.4884 8.18005 22.4004V9.60039C8.18005 8.51239 9.01205 7.68039 10.1001 7.68039H17.1401V8.96039H10.1001C9.71605 8.96039 9.46005 9.21639 9.46005 9.60039V22.4004C9.46005 22.7844 9.71605 23.0404 10.1001 23.0404H22.9001C23.2841 23.0404 23.5401 22.7844 23.5401 22.4004V15.3604H24.8201V22.4004C24.8201 23.4884 23.9881 24.3204 22.9001 24.3204Z"
        fill="#3B5EDB" />
    </svg>
  );
}

export default FollowLinkIcon;