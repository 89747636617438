import React from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ chartData, chartOptions }) {
  return (
    <div className="pieChartContainer">
      <Pie
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
}

export default PieChart;
