import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { InputField, TextArea } from "../../../components/inputs";
import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import { selectUser } from "../../../store/slices/authenticationSlice";
import {
  reportProblemWithOrder,
  selectUploadingReport,
} from "../../../store/slices/prescriptionsSlice";
import { notEmptyValidator } from "../../../util/validators";
import { isFormValid, propsToValues } from "../../../util/util";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";

function MakeAComplaint(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { orderId } = useParams();
  const user = useSelector(selectUser);
  const uploadingReport = useSelector(selectUploadingReport);

  const subject = useProps("", notEmptyValidator("Subject"));
  const message = useProps("", notEmptyValidator("Message"));
  const form = { subject, message };
  const isButtonEnabled = isFormValid(form) && !uploadingReport;

  async function submitClicked() {
    dispatch(
      reportProblemWithOrder({
        email: user.email,
        username: user.email,
        orderId,
        ...propsToValues(form),
      })
    );
    history.goBack();
  }

  return (
    <section className="make_a_complaint">
      <div className="make_a_complaint__content">
        <div className="make_a_complaint__title">
          <p className="label--caps--normal">REPORT A PROBLEM</p>
          <h1 className="h3-a">Tell us how we can improve</h1>
          <p className="p5">
            We're sorry to hear there was a problem with you order. Please tell
            us your concerns.
          </p>
        </div>

        <form className="make_a_complaint__form">
          <div>
            <InputField {...subject} id="subject" name="Subject" type="text" />
          </div>
          <div>
            <TextArea
              {...message}
              id="subject"
              name="Enter your message here"
              type="text"
            />
          </div>
        </form>
        <div className="make_a_complaint__button">
          <div className="h-40">
            <PrimaryCta
              isEnabled={isButtonEnabled}
              text={uploadingReport ? <Spinner /> : "Submit"}
              onClick={submitClicked}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MakeAComplaint;
