import axios from "axios";
import { env, apiUrls } from "../config.json";

export default axios.create({
  baseURL: apiUrls[env],
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
