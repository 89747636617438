import React from "react";

function OrderStatusPrescriptionExpiredIcon(props) {
  return (
    <svg
      width="38"
      height="48"
      viewBox="0 0 38 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M18.8948 1.45344C19.4288 1.50419 19.9352 1.71435 20.3482 2.05662L29.9192 11.6276C30.2614 12.0406 30.4716 12.547 30.5223 13.081V34.6574L29.2869 34.4539C29.1312 34.4246 28.973 34.41 28.8145 34.4103C28.1185 34.4116 27.4475 34.6696 26.9299 35.1349C26.4123 35.6002 26.0845 36.2401 26.0094 36.932L25.617 40.5656C25.6001 40.601 25.5747 40.6315 25.5429 40.6543C25.5111 40.6772 25.4741 40.6917 25.4353 40.6964H1.45344V1.45344H18.8948ZM18.8948 0H1.45344C1.06797 0 0.698278 0.15313 0.425704 0.425704C0.15313 0.698278 0 1.06797 0 1.45344L0 40.6964C0 41.0819 0.15313 41.4516 0.425704 41.7242C0.698278 41.9968 1.06797 42.1499 1.45344 42.1499H25.4353C25.8337 42.1409 26.2156 41.9893 26.5117 41.7225C26.8077 41.4558 26.9982 41.0917 27.0486 40.6964L27.441 37.0628C27.4752 36.7262 27.6332 36.4142 27.8842 36.1873C28.1353 35.9604 28.4616 35.8347 28.8 35.8347C28.8774 35.8275 28.9552 35.8275 29.0326 35.8347L30.5296 36.0818C30.6069 36.0889 30.6848 36.0889 30.7622 36.0818C30.9183 36.0856 31.0737 36.0585 31.2194 36.0021C31.365 35.9457 31.4981 35.861 31.6109 35.7529C31.7237 35.6448 31.814 35.5155 31.8766 35.3724C31.9393 35.2293 31.9729 35.0752 31.9758 34.919V13.081C31.9251 12.1627 31.5638 11.2888 30.9511 10.6029L21.3729 1.02468C20.687 0.411972 19.8131 0.0506452 18.8948 0V0Z"
          fill="black"
        />
        <path
          d="M20.348 11.6276V0H18.8945V11.6276C18.8945 12.013 19.0477 12.3827 19.3202 12.6553C19.5928 12.9279 19.9625 13.081 20.348 13.081H31.9755V11.6276H20.348Z"
          fill="black"
        />
        <path
          d="M30.5225 12.2307V29.7593H31.9759V11.6276L20.3484 0H1.45361V1.45344H19.7452L30.5225 12.2307Z"
          fill="black"
        />
        <path
          d="M24.7086 30.5586H4.36035V32.012H24.7086V30.5586Z"
          fill="black"
        />
        <path
          d="M18.8948 34.9189H4.36035V36.3724H18.8948V34.9189Z"
          fill="black"
        />
        <path
          d="M8.53915 26.6416L6.75868 23.5385H6.30811V26.6416H4.31689V18.4224H7.71795C8.28642 18.4031 8.85147 18.5176 9.36761 18.7567C9.78952 18.9557 10.1416 19.2773 10.3778 19.6796C10.6063 20.0902 10.7217 20.5542 10.712 21.024C10.7292 21.5746 10.5545 22.1141 10.2179 22.5501C9.84626 22.9944 9.33302 23.2972 8.76443 23.4077L10.7193 26.6416H8.53915ZM6.30811 22.1723H7.54354C7.85678 22.1978 8.16814 22.1044 8.41561 21.9106C8.51768 21.8108 8.5976 21.6907 8.65019 21.5579C8.70278 21.4252 8.72684 21.2829 8.72083 21.1403C8.72763 20.9937 8.7033 20.8473 8.64944 20.7108C8.59558 20.5742 8.51341 20.4507 8.40834 20.3482C8.16665 20.1477 7.85668 20.0487 7.54354 20.072H6.30811V22.1723Z"
          fill="black"
        />
        <path
          d="M15.7698 26.6416L14.4472 24.7885L13.3643 26.6416H11.1842L13.3643 23.2551L11.1333 20.152H13.3643L14.6797 21.9761L15.7625 20.152H17.9427L15.7625 23.4513L18.059 26.6416H15.7698Z"
          fill="black"
        />
        <path
          d="M30.5221 33.4656C29.0848 33.4656 27.6797 33.8918 26.4847 34.6903C25.2896 35.4889 24.3581 36.6238 23.8081 37.9518C23.258 39.2797 23.1141 40.7409 23.3945 42.1506C23.6749 43.5603 24.3671 44.8552 25.3834 45.8715C26.3997 46.8878 27.6946 47.58 29.1043 47.8604C30.514 48.1408 31.9752 47.9969 33.3032 47.4468C34.6311 46.8968 35.766 45.9653 36.5646 44.7702C37.3631 43.5752 37.7893 42.1701 37.7893 40.7328C37.7893 38.8054 37.0237 36.957 35.6608 35.5941C34.2979 34.2312 32.4495 33.4656 30.5221 33.4656ZM31.4959 44.7734H29.5556V42.913H31.4959V44.7734ZM31.3142 42.0191H29.73L29.5047 36.6995H31.5395L31.3142 42.0191Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default OrderStatusPrescriptionExpiredIcon;
