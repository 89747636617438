import React, { useEffect } from "react";

import { Stepper } from "../../../components";
import {
  OrderingRepeatPrescription,
  OrderingRepeatPrescriptionUploaded,
  PatientAccountUploadPrescription,
} from "../../stepperPages";

function BookAConsultation(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  return (
    <Stepper
      steps={consultationSteps}
      firstStep={consultationSteps.doctorInfo}
    />
  );
}

const consultationSteps = {
  doctorInfo: { component: OrderingRepeatPrescription },
  uploadPrescription: { component: PatientAccountUploadPrescription },
  uploadSuccess: { component: OrderingRepeatPrescriptionUploaded },
};

export default BookAConsultation;
