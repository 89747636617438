import { useState } from "react";

function useRadioButton(initialValue) {
  const [value, setValue] = useState(initialValue);

  function onClick() {
    setValue(!value);
  }

  function reset() {
    setValue(initialValue);
  }

  return { value, onClick, reset };
}

export default useRadioButton;
