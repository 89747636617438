import React, { useRef, useLayoutEffect } from "react";

function ProductPreview({ show, onClose = () => null, onClick = () => null, children, shouldAutoResize = true }) {

  const handleOnClick = (e) => {
    onClick(e)
    onClose();
  }

  const ref = useRef();

  useLayoutEffect(() => {
    if (shouldAutoResize) {

      const transformElement = () => {
        const parrentHeight = ref.current.parentElement.getBoundingClientRect().height
        ref.current.style.height = `${parrentHeight}px`
      }
      transformElement()
      window.addEventListener("resize", transformElement)

      return () => {
        window.removeEventListener("resize", transformElement)
      }
    }
  }, [children]);

  return (
    <div
      ref={ref}
      onClick={(e) => handleOnClick(e)} className={`product_preview ${show ? "product_preview--show" : ""}`}>
      {children}

    </div>
  );
}

export default ProductPreview;