import React, { useEffect } from "react";
import { CrossFailIcon } from "../../../assets/icons";

function SecretExpired(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="secret_expired">
      <div className="secret_expired__content">
        <div className="secret_expired__image">
          <CrossFailIcon />
        </div>
        <div className="secret_expired__title">
          <p className="label--caps--normal">
            Expired
          </p>
          <h1 className="h3-a">Link expired</h1>
          <p className="p5 m-t-s">
            Please email us at <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href="mailto:info@lyphe.com?subject=New onboarding link request&body=Please send me a new onboarding link.">info@lyphe.com</a>
            &nbsp; and one of the Lyphe Dispensary team will send you a new link so you can sign up to your account.
          </p>
        </div>

      </div>
    </section>
  );
}

export default SecretExpired;