import React from "react";
import AccountFooterDesktop from "./AccountFooterDesktop/AccountFooterDesktop";
import AccountFooterMobile from "./AccountFooterMobile/AccountFooterMobile";

function AccountFooter(props) {
  return (
    <>
      <AccountFooterMobile />
      <AccountFooterDesktop />
    </>
  );
}

export default AccountFooter;
