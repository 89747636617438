import React, { useEffect } from "react";
// This component is ment to be used with portal js

const TooltipContainer = ({ children, coords, updateTooltipCoords }) => {
	const updateCoords = updateTooltipCoords
	useEffect(() => {
		window.addEventListener("resize", updateCoords);
		return () => window.removeEventListener("resize", updateCoords);
		// eslint-disable-next-line
	}, []);

	return (
		<div
			style={{ ...styles.popover, ...coords }}
		>
			{children}
		</div>
	);
};

const styles = {
	popover: {
		position: "absolute",
		width: "fit-content",
		transform: "translate(-30%, 0%)",
		zIndex: 100
	}
};

export default TooltipContainer;