import React from "react";

import { InputField } from "../../inputs";

function AddressForm({ address1, address2, zipCode, country }) {
  return (
    <>
      {country &&
        <div className="form-spacing">
          <InputField
            {...country}
            id="country"
            name="Country"
            type="text"
          />
        </div>
      }
      <div className="form-spacing">
        <InputField
          {...address1}
          id="address1"
          name="Address line 1"
          type="text"
        />
      </div>
      <div className="form-spacing">
        <InputField
          {...address2}
          id="address2"
          name="Address line 2"
          type="text"
        />
      </div>
      <div className="form-spacing">
        <InputField {...zipCode} id="postCode" name="Postcode" type="text" />
      </div>
    </>
  );
}

export default AddressForm;
