import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../assets/icons";

function TipsCarousel(props) {
  const { tips } = props;
  const totalTipsNumber = tips.length;

  const mainSlider = useRef(null);
  const [mainSliderState, setMainSliderState] = useState(null);

  const mainSliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    asNavFor: mainSliderState,
  };

  const mobileSliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (mainSlider.current) setMainSliderState(mainSlider.current);
  });

  return (
    <div className="tips_carousel">
      <Slider {...mainSliderSettings}>
        {tips.map(({ title, text, image }, index) => (
          <div key={title} className="tips_carousel__tip">
            <p className="label">
              Tip {index + 1} OF {totalTipsNumber}
            </p>

            {image && (
              <img
                className="tips_carousel__tip__image"
                src={image}
                alt={text}
              />
            )}

            <div className="tips_carousel__tip__text--desktop">
              <p className="p3--semi-bold">{title}</p>
              <p className="p5">{text}</p>
            </div>
          </div>
        ))}
      </Slider>

      <div className="tips_mobile">
        <Slider
          ref={(el) => (mainSlider.current = el)}
          {...mobileSliderSettings}
        >
          {tips.map(({ title, text }) => (
            <div key={title} className="tips_carousel__tip--mobile">
              <div className="tips_carousel__tip__text--mobile">
                <p className="p3--semi-bold">{title}</p>
                <p className="p5">{text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

function SlickArrowLeft({ currentSlide, slideCount, ...props }) {
  return currentSlide !== 0 && <ArrowLeftIcon {...props} color="black" />;
}

function SlickArrowRight({ currentSlide, slideCount, ...props }) {
  return (
    currentSlide !== slideCount - 1 && (
      <ArrowRightIcon {...props} color="black" />
    )
  );
}

export default TipsCarousel;
