import moment from "moment";
import useProps from "./useProps";

/**
 *
 * @example can be found in src/pages/stepperPages/PatientAgeCheck.js
 *
 * @param {*} d
 * @param {*} m
 * @param {*} y
 * @param {*} checkAge
 */
function useAgeSelect(d, m, y, checkAge = true) {
  const currentYear = moment().year();
  let yearOptions = range(currentYear, currentYear - 100);

  const year = useProps(y || "");
  const month = useProps(m || "");
  const day = useProps(d || "");

  // determine how many days are in a selected month/year
  // year is included because of leap year
  const yearMonth = moment(`${year.value}-${month.value}`, "YYYY-MMMM");

  const numberOfDaysInSelectedMonth = yearMonth.daysInMonth();

  // reset the day if it is  selected but it isn't
  // in the span of available days in selected month
  if (
    day.value &&
    numberOfDaysInSelectedMonth &&
    day.value > numberOfDaysInSelectedMonth
  ) {
    day.setValue("");
  }

  // if month isn't selected make a range from 1 to 31
  const dayOptions = range(1, numberOfDaysInSelectedMonth || 31);

  // check if he is over 18
  let error = "";
  if (checkAge) error = isUnderAge(day, month, year);

  function reset() {
    day.reset();
    year.reset();
    month.reset();
  }

  const props = { yearProps: year, monthProps: month, dayProps: day };
  const options = { dayOptions, yearOptions, monthOptions };
  const values = { day: day.value, month: month.value, year: year.value };

  return [{ options, props }, values, error, reset];
}

/**
 * Create an array of numbers from start to end, including the end.
 * @param {*} start
 * @param {*} end
 */
function range(start, end) {
  let list = [];

  // ascending order
  if (start > end) {
    for (let i = start; i >= end; i--) {
      list.push({ text: `${i}`, value: `${i}` });
    }

    // descending order
  } else {
    for (let i = start; i <= end; i++) {
      list.push({ text: `${i}`, value: `${i}` });
    }
  }

  return list;
}

function isUnderAge(day, month, year) {
  if (!day.value || !month.value || !year.value) return "";

  const dob = moment(
    `${year.value}-${month.value}-${day.value}`,
    "YYYY-MMMM-DD"
  );

  const now = moment();
  const age = now.diff(dob, "years");
  if (age < 18) {
    return "Sorry, you have to be over 18 to use our service";
  }

  return "";
}

let monthOptions = [
  { text: "January", value: "January" },
  { text: "February", value: "February" },
  { text: "March", value: "March" },
  { text: "April", value: "April" },
  { text: "May", value: "May" },
  { text: "June", value: "June" },
  { text: "July", value: "July" },
  { text: "August", value: "August" },
  { text: "September", value: "September" },
  { text: "October", value: "October" },
  { text: "November", value: "November" },
  { text: "December", value: "December" },
];

export default useAgeSelect;
