import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../buttons";
import { FullScreenOverlay } from "../../overlays";
import { Table } from "../..";

import { selectPayModal, setGenericSuccessModal, setPayModal } from "../../../store/slices/modalsSlice";
import {
  payForOrder,
  requestPaymentLink,
  selectRequestingPaymentCallback,
  selectRequestingPaymentLink,
} from "../../../store/slices/prescriptionsSlice";

import { BlueInfoIcon, CloseIcon } from "../../../assets/icons";
import { InformationCard } from "../../cards";
import { ReactComponent as PayPopupImage } from "../../../assets/images/pay_popup.svg";
import { unwrapResult } from "@reduxjs/toolkit";
import { setToast } from "../../../store/slices/toastSlice";
import { toastTypes } from "../../../enums";
import Spinner from "../../sharedComponents/Spinner/Spinner";
import { selectUserDetails } from "../../../store/slices/accountSlice";


function PayPopup(props) {
  const dispatch = useDispatch();
  const payModal = useSelector(selectPayModal);
  const requestingPaymentLink = useSelector(selectRequestingPaymentLink);
  const userDetails = useSelector(selectUserDetails);

  const requestingPaymentCallback = useSelector(
    selectRequestingPaymentCallback
  );

  const [tableData, setTableData] = useState({ data: [], total: 0 });
  const [isCallbackRequested, setIsCallbackRequested] = useState(false);
  /* this array holds all the orderNumbers that we have requested a payment link for */
  const [orderNumbers, setOrderNumbers] = useState([]);
  /* this array holds all the orderIds that we have requested a callback  for */
  const [orderIds, setOrderIds] = useState([]);

  useEffect(() => {
    const result = processProducts();

    if (result.data.length) setTableData(result);

    //eslint-disable-next-line
  }, [payModal.order]);

  function onClose() {
    dispatch(setPayModal({ show: false, orderId: null }));
    setIsCallbackRequested(false);
  }

  function processProducts() {
    if (!payModal.order) return { data: [], total: 0, shipmentCost: 0 };
    let shipmentCost = payModal.order.shipmentCost ? payModal.order.shipmentCost : 0;
    let data = [],
      total = 0;
    for (let product of payModal.order.products) {
      data.push([
        { text: product.name, column: 6 },
        { text: product.amount, column: 3, right: true },
        {
          text: `£${product.fullPrice * product.amount}`,
          column: 3,
          right: true,
        },
      ]);

      total += product.fullPrice * product.amount;
    }

    return { data, total, shipmentCost };
  }

  async function requestCallback() {
    await dispatch(payForOrder(payModal.order.orderId));
    setOrderIds([...orderIds, payModal.order.orderId])
    setIsCallbackRequested(false);
    const newGenericSuccesModalState = {
      show: true,
      text: `A member of the Lyphe Dispensary team will contact you on ${userDetails.telephoneNo} during the working hours:  Mon - Fri 9am - 6pm `,
      title: " Call back requested"
    }
    dispatch(setGenericSuccessModal(newGenericSuccesModalState));
  }

  const setToastError = () => {
    const newToastState = {
      showToast: true,
      title: 'Payment link not emailed',
      message: 'There is a problem emailing the payment link. Please select the option ‘Give me a call’ instead. Apologies for the inconvenience.',
      type: toastTypes.Error
    }

    dispatch(setToast(newToastState))
  }

  async function onRequestPaymentLink() {
    dispatch(requestPaymentLink(payModal.order.guid))
      .then(unwrapResult)
      .then(() => {
        setOrderNumbers([...orderNumbers, payModal.order.orderNumber])

        setTimeout(() => {
          setOrderNumbers((prev) => { return prev.filter((orderNumber => orderNumber !== payModal.order.orderNumber)) })
        }, 120000)
      })
      .catch(() => {
        setToastError()
      })
  }


  return (
    <FullScreenOverlay show={payModal.show}>
      <div className="verification_card">
        {!isCallbackRequested && (
          <>
            <div className="verification_card__header">
              <p className="p4-a"></p>
              <div onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="verification_card__close">
              <div onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
          </>
        )}

        {isCallbackRequested ? (
          <InformationCard
            cardTitle="Callback requested"
            cardText="Our team will contact you shortly using the contact details in your profile."
            onClick={onClose}
          >
            <div className="h-40">
              <PrimaryCta text="Ok" onClick={onClose} />
            </div>
          </InformationCard>
        ) : (
          <div className="verification_card__content ">
            <PayPopupImage />
            <div>
              <h1 className="h8-a text-center">How would you <br /> like to pay?</h1>
            </div>

            <div className="m-b-s" style={{ width: "100%" }}>
              <Table
                title="Products"
                small
                tableData={{ body: tableData.data, head: tableHead }}
              />
            </div>

            <div className="verification_card__disclaimer" >
              <BlueInfoIcon />
              <div className="disclaimer-text">
                <p className="p5">By clicking the request payment link below, you acknowledge that you have read your prescription, the details are accurate, and you are ready to proceed.</p>
              </div>
            </div>

            <div className="m-v-s" style={{ width: "100%" }}>
              <p className="p5">Sub-total: £{tableData.total} </p>
              <p className="p5">Shipping: £{tableData.shipmentCost} </p>
              <p className="p5">Total: £{(tableData.total + tableData.shipmentCost).toFixed(2)}</p>
            </div>

            <div className="verification_card__buttons">
              <div className="h-40">
                <PrimaryCta
                  text={requestingPaymentLink ? <Spinner /> : "Email me a payment link"}
                  onClick={onRequestPaymentLink}
                  isEnabled={(!requestingPaymentLink && !orderNumbers.includes(payModal.order?.orderNumber))}
                />
              </div>
              <div className="h-40">
                <PrimaryCta
                  text={requestingPaymentCallback ? <Spinner /> : "Give me a call"}
                  onClick={requestCallback}
                  color='white'
                  isEnabled={!requestingPaymentCallback && !payModal.order?.isPaymentRequested && !orderIds.includes(payModal.order?.orderId)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </FullScreenOverlay>
  );
}

const tableHead = [
  {
    text: "Product name",
    column: 6,
  },
  {
    text: "Quantity",
    column: 3,
    right: true,
  },
  {
    text: "Price",
    column: 3,
    right: true,
  },
];

export default PayPopup;
