import React, { useEffect } from "react";

import { Stepper } from "../../components";
import {
  CarerAddress,
  CarerAgeCheck,
  CarerConfirmDetails,
  CarerContactNumber,
  CarerDoYouHavePrescription,
  CarerEligibilityCriteria,
  CarerOneLastStep,
  CarerPatientAgeCheck,
  CarerPatientName,
  CarerRelationshipToPatient,
  CarerUploadPrescription,
  CheckYourEmail,
  ConfirmDetails,
  PatientAddress,
  PatientAgeCheck,
  PatientBeforeWeStart,
  PatientContactNumber,
  PatientDoYouHavePrescription,
  PatientEligibilityCriteria,
  PatientName,
  PatientOneLastStep,
  PatientUploadPrescription,
} from "../stepperPages";

function PatientCarerOnboarding() {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Stepper
      steps={patientCarerSteps}
      firstStep={patientCarerSteps.beforeWeStart}
    />
  );
}

const patientCarerSteps = {
  totalSteps: 13,
  // patient
  beforeWeStart: {
    component: PatientBeforeWeStart,
    step: 0,
  },

  name: { component: PatientName, step: 1, closeWithPopup: true },

  patientAgeCheck: {
    component: PatientAgeCheck,
    step: 3,
    closeWithPopup: true,
  },

  patientDoYouHavePrescription: {
    component: PatientDoYouHavePrescription,
    step: 4,
    closeWithPopup: true,
  },

  patientCriteria: {
    component: PatientEligibilityCriteria,
    step: 5,
    closeWithPopup: true,
  },

  patientPrescription: {
    component: PatientUploadPrescription,
    step: 7,
    closeWithPopup: true,
  },

  patientAddress: {
    component: PatientAddress,
    step: 8,
    closeWithPopup: true,
  },

  patientContact: {
    component: PatientContactNumber,
    step: 9,
    closeWithPopup: true,
  },

  patientCheckDetails: {
    component: ConfirmDetails,
    step: 10,
    closeWithPopup: true,
  },

  patientOneLastStep: {
    component: PatientOneLastStep,
    step: 11,
    closeWithPopup: true,
  },

  // carer
  carerPatientAgeCheck: {
    component: CarerPatientAgeCheck,
    step: 3,
    closeWithPopup: true,
  },

  carerAgeCheck: {
    component: CarerAgeCheck,
    step: 4,
    closeWithPopup: true,
  },

  carerRelationShipToPatient: {
    component: CarerRelationshipToPatient,
    step: 5,
    closeWithPopup: true,
  },

  carerPatientsName: {
    component: CarerPatientName,
    step: 6,
    closeWithPopup: true,
  },

  carerDoYouHavePrescription: {
    component: CarerDoYouHavePrescription,
    step: 7,
    closeWithPopup: true,
  },

  carerCriteria: {
    component: CarerEligibilityCriteria,
    step: 8,
    closeWithPopup: true,
  },

  carerPrescription: {
    component: CarerUploadPrescription,
    step: 8,
    closeWithPopup: true,
  },

  carerPatientsAddress: {
    component: CarerAddress,
    step: 9,
    closeWithPopup: true,
  },

  carerContact: {
    component: CarerContactNumber,
    step: 10,
    closeWithPopup: true,
  },

  carerCheckDetails: {
    component: CarerConfirmDetails,
    step: 11,
    closeWithPopup: true,
  },

  carerOneLastStep: {
    component: CarerOneLastStep,
    step: 12,
    closeWithPopup: true,
  },

  end: {
    component: CheckYourEmail,
  },
};

export default PatientCarerOnboarding;
