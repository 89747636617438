import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../../assets/icons';
import { OrderStatuses, UserRoles } from '../../../enums';
import { selectUser } from '../../../store/slices/authenticationSlice';
import { setPayModal } from '../../../store/slices/modalsSlice';
import { selectActivePrescriptions, selectTrackedOrderIndex } from '../../../store/slices/prescriptionsSlice';
import { PrimaryCta } from '../../buttons';
import { FullScreenOverlay } from '../../overlays';

const ReadyForPaymentPopup = () => {
  const dispatch = useDispatch();
  const trackedOrderIndex = useSelector(selectTrackedOrderIndex);
  const activePrescriptions = useSelector(selectActivePrescriptions);
  const user = useSelector(selectUser);
  const [trackedOrderIndexes, setTrackedOrderIndexes] = useState([])
  const order = activePrescriptions[trackedOrderIndex];

  const isTrackedOrderStatusInStatusesWhenToDisplayModal = order ? statusesWhenToDisplayModal.includes(order.status) : false;
  const isTrackedInTrackedOrderIndexes = trackedOrderIndexes.includes(trackedOrderIndex);

  const isModalDisplayed = () => {
    return (isTrackedOrderStatusInStatusesWhenToDisplayModal && !isTrackedInTrackedOrderIndexes && user.role !== UserRoles.OrganisationEmployee);
  }

  function onClose() {
    setTrackedOrderIndexes([...trackedOrderIndexes, trackedOrderIndex]);

  }

  const handleOnMakePayment = () => {
    dispatch(setPayModal({ show: true, order: order }));
    setTrackedOrderIndexes([...trackedOrderIndexes, trackedOrderIndex]);
  }

  return (
    <FullScreenOverlay show={isModalDisplayed()}>
      <div className="verification_card">

        <>
          <div className="verification_card__header">
            <p className="p4-a"></p>
            <div onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className="verification_card__close">
            <div onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
        </>

        <div className="verification_card__content ">
          <div className={'m-t-s'}>
            <h1 className="h8-a">Ready for payment</h1>
          </div>
          <p style={{ textAlign: 'center' }} className="p5 m-t-s">
            Your medication has arrived in the pharmacy and is ready to dispense if your hard copy has been received.
          </p>
          <p style={{ textAlign: 'center' }} className="p5 m-t-s">
            Please pay as soon as you can to avoid any delays.
          </p>
          <p style={{ textAlign: 'center' }} className="p5 m-t-s m-b-s">
            Please note: The payment window is 7 days.
          </p>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="verification_card__buttons">
            <div style={{ maxWidth: '55%' }} className="h-40">
              <PrimaryCta
                text={"Make payment"}
                onClick={handleOnMakePayment}
              />
            </div>
          </div>
        </div>
      </div>
    </FullScreenOverlay>
  );
}

export default ReadyForPaymentPopup


const statusesWhenToDisplayModal = [OrderStatuses.NotPaidAndPaper, OrderStatuses.NotPaidAndNoPaper]