import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from "../../../../assets/icons";

function FooterSocialMedia(props) {
  return (
    <div className="account_footer__social_media">
      <div className="account_footer__social_media__text">
        <p className="label--caps">Follow us</p>
      </div>
      <div className="account_footer__social_media__icons">
        <div className="account_footer__social_media__icon">
          <a href="https://www.facebook.com/lypheclinicuk" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
        </div>
        <div className="account_footer__social_media__icon">
          <a href="https://x.com/lypheclinic" target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </a>
        </div>
        <div className="account_footer__social_media__icon">
          <a href="https://instagram.com/lypheuk" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterSocialMedia;
