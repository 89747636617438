import React, { useState } from "react";
import { useEffect } from "react";

function ProgressBar(props) {
  const { totalSteps, currentStep } = props;

  // figure out how many steps are done
  const [val, set] = useState(0);

  useEffect(() => {
    set((currentStep / totalSteps) * 100);
  }, [currentStep, totalSteps]);

  const width = `${val}%`;

  return (
    <div className="onboarding_stepper_container">
      <div style={{ width }} className="onboarding_stepper_blue"></div>
      <div className="onboarding_stepper"></div>
    </div>
  );
}

export default ProgressBar;
