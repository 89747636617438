import React from "react";
import { TertiaryCta } from "../../buttons";
import { Link } from "react-router-dom";

function ChangePasswordCard({ email }) {
  return (
    <div className="change_password_card">
      <div className="change_password_card__head">
        <p className="p3--semi-bold">Security details</p>
      </div>
      <div className="change_password_card__body">
        <div className="change_password_card__your_password">
          <div>
            <p className="label--caps--normal">Your password</p>
            <p className="p4">*******************</p>
          </div>
          <div className="change_password_card__button">
            <TertiaryCta color="white" text="Change" link="/changePassword" />
          </div>
          <div className="change_password_card__change_link">
            <Link to="/changePassword">
              <p className="p4">Change</p>
            </Link>
          </div>
        </div>
        <div className="change_password_card__your_email">
          <p className="label--caps--normal">Your Email</p>
          <p className="p4">{email}</p>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordCard;
