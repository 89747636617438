import React from "react";

function UserIcon({ color = "black" }) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C11.0303 0 13.503 2.47273 13.503 5.50303C13.503 8.55758 11.0303 11.0303 8 11.0303C4.94545 11.0303 2.47273 8.55758 2.47273 5.50303C2.47273 2.47273 4.94545 0 8 0ZM8 1.09091C10.4242 1.09091 12.4121 3.07879 12.4121 5.50303C12.4121 7.95151 10.4242 9.93939 8 9.93939C5.55152 9.93939 3.56364 7.95151 3.56364 5.50303C3.56364 3.07879 5.55152 1.09091 8 1.09091ZM4.94545 12.3636C7.00606 12.3636 9.09091 12.3636 11.1758 12.3636C13.2848 12.3636 15.0303 14.1091 15.0303 16.2182C15.0303 17.4788 15.0303 18.7394 15.0303 20C15.3939 20 15.7576 20 16.1212 20C16.1212 18.7394 16.1212 17.4788 16.1212 16.2182C16.1212 13.503 13.8909 11.2727 11.1758 11.2727C9.09091 11.2727 7.00606 11.2727 4.94545 11.2727C2.20606 11.2727 0 13.503 0 16.2182C0 17.4788 0 18.7394 0 20C0.363636 20 0.727273 20 1.09091 20C1.09091 18.7394 1.09091 17.4788 1.09091 16.2182C1.09091 14.1091 2.81212 12.3636 4.94545 12.3636Z"
        fill={color}
      />
    </svg>
  );
}

export default UserIcon;
