import React from "react";

import OrderHeader from "./OrderHeader/OrderHeader";
import OrderProducts from "./OrderProducts/OrderProducts";

function Order({ order, allOrders }) {

  return (
    <div className="order">
      <OrderHeader order={order} allOrders={allOrders} />
      <OrderProducts order={order} />
    </div>
  );
}

export default Order;
