import React from "react";
import { PrimaryCta } from "../../buttons";

function PatientFeedbackBanner() {
  return (
    <div className="patient_feedback--banner">
      <div className="patient_feedback--content">
        <h3>Your feedback!</h3>
        <p>Let us know how we can make the patient portal work better for you.</p>
      </div>

      <div className="patient_feedback--button">
        <PrimaryCta
          color="white_grey"
          text="Give feedback"
          isLinkExternalLink
          link={"https://lyphe.com/contact-us/"}
        />
      </div>
    </div>
  );
}

export default PatientFeedbackBanner;
