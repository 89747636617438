import React from "react";

function OrderStatusPaperNeededIcon({ color = "black" }) {
  return (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.9091 1.45455C24.4435 1.50533 24.9503 1.71565 25.3636 2.05818L34.9418 11.6364C35.2844 12.0497 35.4947 12.5565 35.5455 13.0909V46.5455H6.45455V1.45455H23.9091ZM23.9091 0H6.45455C6.06878 0 5.69881 0.153246 5.42603 0.426027C5.15325 0.698807 5 1.06878 5 1.45455V46.5455C5 46.9312 5.15325 47.3012 5.42603 47.574C5.69881 47.8468 6.06878 48 6.45455 48H35.5455C35.9312 48 36.3012 47.8468 36.574 47.574C36.8468 47.3012 37 46.9312 37 46.5455V13.0909C36.9493 12.1719 36.5877 11.2974 35.9745 10.6109L26.3891 1.02545C25.7026 0.412284 24.8281 0.0506835 23.9091 0V0Z"
          fill={color}
        />
        <path
          d="M25.3637 11.6364V0H23.9092V11.6364C23.9092 12.0221 24.0624 12.3921 24.3352 12.6649C24.608 12.9377 24.978 13.0909 25.3637 13.0909H37.0001V11.6364H25.3637Z"
          fill={color}
        />
        <path
          d="M35.5455 12.24V46.5455H37V11.6364L25.3637 0H6.45459V1.45455H24.76L35.5455 12.24Z"
          fill={color}
        />
        <path
          d="M31.182 29.8181H10.8184V31.2727H31.182V29.8181Z"
          fill={color}
        />
        <path
          d="M31.182 34.1819H10.8184V35.6364H31.182V34.1819Z"
          fill={color}
        />
        <path d="M25.3638 38.5454H10.8184V40H25.3638V38.5454Z" fill={color} />
        <path
          d="M14.9999 25.8982L13.2181 22.7855H12.7671V25.8982H10.7744V17.6727H14.1781C14.747 17.6535 15.3124 17.7681 15.829 18.0073C16.2481 18.2006 16.6001 18.5146 16.8399 18.9091C17.0686 19.32 17.1841 19.7843 17.1744 20.2546C17.1916 20.8056 17.0168 21.3454 16.6799 21.7818C16.308 22.2264 15.7943 22.5295 15.2253 22.64L17.1817 25.8764L14.9999 25.8982ZM12.7671 21.4255H14.0035C14.317 21.451 14.6286 21.3575 14.8762 21.1636C14.9822 21.0605 15.0644 20.9355 15.1172 20.7973C15.1699 20.6592 15.1919 20.5112 15.1817 20.3636C15.1774 20.0895 15.065 19.8281 14.869 19.6364C14.6271 19.4358 14.3169 19.3367 14.0035 19.36H12.7671V21.4255Z"
          fill={color}
        />
        <path
          d="M22.2362 25.8981L20.9126 24.0436L19.8289 25.8981H17.6471L19.8289 22.509L17.5962 19.4036H19.8289L21.1453 21.229L22.2289 19.4036H24.4107L22.2289 22.7054L24.5271 25.8981H22.2362Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default OrderStatusPaperNeededIcon;
