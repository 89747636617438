import React from "react";

import { InputField } from "../../inputs";

function CredentialsForm({
  email,
  password,
  confirmPassword,
  isEnabled = true,
}) {
  return (
    <form>
      <div className="form-spacing">
        <InputField
          {...email}
          isEnabled={isEnabled}
          type="email"
          name="Enter your email address"
        />
      </div>
      <div className="form-spacing">
        <InputField {...password} type="password" name="Create a password" />
      </div>
      <div>
        <InputField
          {...confirmPassword}
          type="password"
          name="Confirm your password"
        />
      </div>
    </form>
  );
}

export default CredentialsForm;
