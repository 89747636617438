import React from "react";
import { useDispatch } from "react-redux";

import { PrimaryCta } from "..";

import { setPayModal } from "../../../store/slices/modalsSlice";

function PayButton({ order }) {
  const dispatch = useDispatch();

  function handleMakePaymentClick() {
    dispatch(setPayModal({ show: true, order }));
  }

  return (
    <div className={`h-40${order.isPaymentRequested ? " disabled_button" : ""}`}>
      <PrimaryCta
        text="Make a payment"
        color="orange"
        onClick={handleMakePaymentClick}
      />
    </div>
  );
}

export default PayButton;
