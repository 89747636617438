import { ProductType, ProductSubtype } from "../enums";

class ProductsConverter {

  productsApiToClient(products) {
    return products.map((product) => {
      return this.productApiToClient(product)
    })
  }

  patientProductsApiToClient(products) {
    return products.map((product) => {
      return this.patientProductApiToClient(product)
    })
  }

  productApiToClient(product) {
    return {
      id: product.id,
      supplierId: product.supplierId,
      availability: product.availability,
      expiryDate: product.expiryDate,
      sku: product.sku,
      name: product.name,
      type: product.type,
      subtype: product.subtype,
      phEur: product.phEur,
      description: product.description,
      productUnit: product.productunit,
      wholesalePrice: product.wholesalePrice,
      retailPrice: product.retailPrice,
      thc: product.thc,
      cbd: product.cbd,
      activeIngredient: product.activeIngredient,
      stockQuantity: product.stockQuantity,
      weight: product.weight,
      manufacturer: product.manufacturer,
      country: product.country,
      isActive: product.isActive,
      isT21Eligible: product.isT21Eligible,
      isNixAndNatchEligible: product.isNixAndNatchEligible,
      isSubsidyEligible: product.isSubsidyEligible,
      t21BasePrice: product.t21BasePrice,
      t21FirstQuantityCap: product.t21FirstQuantityCap,
      t21FirstQuantityCapPrice: product.t21FirstQuantityCapPrice,
      t21SecondQuantityCap: product.t21SecondQuantityCap,
      t21SecondQuantityCapPrice: product.t21SecondQuantityCapPrice,
      t21AdditionalAmountPrice: product.t21AdditionalAmountPrice,
      t21SubsidyPrice: product.t21SubsidyPrice,
      prescriberNoteAdminId: product.prescriberNoteAdminId,
      prescriberNoteDateTime: product.prescriberNoteDateTime,
      prescriberNote: product.prescriberNote,
      irradiatedType: product.irradiatedType,
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,

      productTerpeneProfiles: product.productTerpeneProfiles.sort((a, b) => (a.value < b.value) ? 1 : -1),
      prescriberNote: product.prescriberNote
    }
  }

  patientProductApiToClient(product) {
    return {
      id: product.id,
      supplierId: product.supplierId,

      availability: product.availability,
      name: product.name,
      retailPrice: product.retailPrice,
      thc: product.thc,
      cbd: product.cbd,
      type: product.type,
      subtype: product.subtype,
      phEur: product.phEur,
      weight: product.weight,
      manufacturer: product.manufacturer,
      country: product.country,
      irradiatedType: product.irradiatedType,

      productTerpeneProfiles: product.productTerpeneProfiles.sort((a, b) => (a.value < b.value) ? 1 : -1),
    }
  }
}

export const productConverter = new ProductsConverter();
