import React from "react";
import { resolveAppendableClasses } from "../../../util/util";

function ColouredProgressBar({ className = "", appendableClassNames = "" }) {

  return (
    <div className={`coloured_progress_bar className ${className}  ${resolveAppendableClasses("coloured_progress_bar", appendableClassNames)} `} >
      <div className={"coloured_progress_bar__progress"}></div>
    </div>
  );
}

export default ColouredProgressBar;