import { apiClient } from "../ApiClient";

class PaymentService {
	merchantSessionKey = async () => {
		const { data } = await apiClient.getMerchantSessionKey();

		return data;
	};
}

export const paymentService = new PaymentService();
