import React from "react";

function OrderStatusOrderWithManufacturerIcon({ color = "black" }) {
  return (
    <svg
      width="43"
      height="40"
      viewBox="0 0 43 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M33.0743 7.18534V30.1483H10.1114V7.18534H33.0743ZM34.5558 5.70386H8.62988V31.6298H34.5558V5.70386Z"
          fill={color}
        />
        <path
          d="M23.8146 7.18534V10.1483H19.3702V7.18534H23.8146ZM25.2961 5.70386H17.8887V11.6298H25.2961V5.70386Z"
          fill={color}
        />
        <path
          d="M26.0369 22.7408V24.2222H17.148V22.7408H26.0369ZM27.5184 21.2593H15.6665V25.7037H27.5184V21.2593Z"
          fill={color}
        />
        <path
          d="M10.1109 39.037C11.7473 39.037 13.0739 37.7104 13.0739 36.074C13.0739 34.4376 11.7473 33.1111 10.1109 33.1111C8.47451 33.1111 7.14795 34.4376 7.14795 36.074C7.14795 37.7104 8.47451 39.037 10.1109 39.037Z"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M33.0743 39.037C34.7107 39.037 36.0373 37.7104 36.0373 36.074C36.0373 34.4376 34.7107 33.1111 33.0743 33.1111C31.4379 33.1111 30.1113 34.4376 30.1113 36.074C30.1113 37.7104 31.4379 39.037 33.0743 39.037Z"
          stroke={color}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M38.2593 30.1481H7.88889C5.43704 30.1481 4.92593 28.1556 4.92593 25.7037V7.92593C4.92593 6.35427 4.30159 4.84699 3.19026 3.73566C2.07894 2.62434 0.571653 2 -1 2L-1 3.48148C0.17874 3.48148 1.3092 3.94973 2.1427 4.78323C2.97619 5.61672 3.44444 6.74719 3.44444 7.92593V25.7037C3.44444 28.963 4.62963 31.6296 7.88889 31.6296H38.2593C38.4557 31.6296 38.6441 31.5516 38.783 31.4127C38.922 31.2738 39 31.0853 39 30.8889C39 30.6924 38.922 30.504 38.783 30.3651C38.6441 30.2262 38.4557 30.1481 38.2593 30.1481Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default OrderStatusOrderWithManufacturerIcon;
