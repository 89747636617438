import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { UserDetailsCard } from "../../../components/cards";

import { accountService } from "../../../services/AccountServices";
import {
  selectUserDetails,
} from "../../../store/slices/accountSlice";

import { getActivePrescriptions } from "../../../store/slices/prescriptionsSlice";

function OrderingRepeatPrescription() {
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetails);

  const [doctorDetails, setDoctorDetails] = useState([]);

  useEffect(() => {
    async function fetchDataFromAPI() {

      await getDoctor();

      dispatch(getActivePrescriptions());
    }

    fetchDataFromAPI();
    // eslint-disable-next-line
  }, []);

  async function getDoctor() {
    const doctor = await accountService.getDoctorDataForCustomer(
      userDetails.patientId
    );

    const doctorDetails = getDoctorDetails(doctor);
    setDoctorDetails(doctorDetails);

    return doctor;
  }

  return (
    <section className="repeat_prescription">
      <div className="repeat_prescription__content">
        <div className="repeat_prescription__title">
          <p className="label--caps--normal">Book a consultation</p>
          <h1 className="h3-a">Need another prescription?</h1>
          <p className="p5" style={{ textAlign: "left" }}>
            {!doctorDetails.length ? (
              <>
                If you need another consultation, please get in touch with your
                doctor, details for whom are included below. If the Doctor is on
                our platform, they have also been notified to arrange a
                consultation with you.
              </>
            ) : (
              <>
                Get in touch with your doctor to arrange a consultation.
                Their details are below.
              </>
            )}
          </p>
        </div>

        <div className="repeat_prescription__doctor_details">
          {!!doctorDetails.length && (
            <>
              <div className="repeat_prescription__test">
                <p className="p3--semi-bold">Doctor details</p>
              </div>
              <UserDetailsCard content={doctorDetails} />
            </>
          )}
        </div>

        <div className="repeat_prescription__buttons">
          <div>
            <PrimaryCta
              color="white"
              text="Back to account"
              link="/prescriptions/active"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function getDoctorDetails(doctor) {
  if (!doctor) return [];

  let doctorEmail = {
    title: "DOCTOR EMAIL",
    text: doctor?.consultationEmail,
  }

  let doctorContactNumber = {
    title: "DOCTOR CONTACT NUMBER",
    text: `${doctor?.consultationPhone}`,
  }

  let doctorWebSite = {
    title: "DOCTOR WEBSITE",
    text: <a href={doctor?.consultationUrl ? doctor?.consultationUrl : "#"} >CONSULTATION LINK</a>,
  }

  let doctorOther = {
    title: "OTHER INFORMATIONS",
    text: `${doctor?.consultationOther}`,
  }

  let doctorInfo = [
    {
      title: "DOCTOR NAME",
      text: `Dr ${doctor?.firstName} ${doctor?.lastName}`,
    },
    {
      title: "CLINIC ADDRESS",
      text: `${doctor?.clinicName}, ${doctor?.clinicAddress1}, ${doctor?.clinicAddress2}, ${doctor?.clinicZIPCode} `,
    }
  ]

  if (doctor?.consultationEmail) {
    doctorInfo.push(doctorEmail);
  }

  if (doctor?.consultationPhone) {
    doctorInfo.push(doctorContactNumber);
  }

  if (doctor?.consultationUrl) {
    doctorInfo.push(doctorWebSite);
  }

  if (doctor?.consultationOther) {
    doctorInfo.push(doctorOther);
  }

  return doctorInfo;
}

export default OrderingRepeatPrescription;
