import React from "react";
import { FlowerIcon } from "../../../assets/icons";
import { PatientFeedbackBanner } from "../../../components/banners";

function NoActivePrescriptions() {

  return (
    <div className="no_prescriptions">
      <div className="no_prescriptions--content">
        <div className='m-b-s' >
          <FlowerIcon />
        </div>
        <p className="h8-a">
          {"You have no active prescriptions"}
        </p>
        <p style={{ fontWeight: '400' }} className="p5">
          <>
            Please upload an active patient prescription that requires
            dispensing. Once we have received the prescription we shall make
            contact with your patient to complete the order. The status of all
            your prescriptions can be found on this dashboard.
          </>
        </p>
      </div>
      <PatientFeedbackBanner />
    </div>
  );
}

export default NoActivePrescriptions;
