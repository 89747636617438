import React from 'react'
import { PrimaryCta } from '../../buttons'
import { VerificationCard } from '../../cards'
import { FullScreenOverlay } from '../../overlays'
import { courierTrackUrl } from "../../../config.json";

const TrackOrderPopup = ({ show, close, data }) => {

  const onClose = () => {
    window.open(courierTrackUrl, '_blank')
    close()
  }
  return (
    <div className='trackOrderPopup'>
      <FullScreenOverlay show={show}>
        <VerificationCard
          onClick={close}
          cardTitle="Track Delivery"

          cardText={`Patient Name: ${data?.patient?.firstName}`}
        >
          <div >
            Tracking order No: <a rel="noopener noreferrer" target="_blank" href={courierTrackUrl}>{data?.externalTrackingCode}</a>
          </div>
          <div>
            <PrimaryCta
              text="Open tracking"
              color="orange"
              onClick={onClose}
            />
          </div>
        </VerificationCard>
      </FullScreenOverlay>
    </div>
  )
}

export default TrackOrderPopup
