import React from "react";

import { Stepper } from "../../../components";
import {
  MobileUploadConfirmation,
  MobilePrescriptionImageUpload,
} from "../../stepperPages";

function PrescriberMobileUploadPrescription() {
  return (
    <Stepper
      steps={doctorUploadSteps}
      firstStep={doctorUploadSteps.mobileUpload}
      showAccountHeader
    />
  );
}

export default PrescriberMobileUploadPrescription;

const doctorUploadSteps = {
  totalSteps: 2,

  mobileUpload: {
    component: MobilePrescriptionImageUpload,
    step: 1,
    hideClose: true,
    hideProgressBar: true
  },

  end: {
    component: MobileUploadConfirmation,
    hideClose: true,
    hideProgressBar: true
  },
};
