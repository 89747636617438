import React from "react";

function PrescriptionIcon({ color = "black" }) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.386364 0.506836C0.173864 0.526154 0 0.719326 0 0.931826V17.0818C0 17.3136 0.2125 17.5068 0.425 17.5068H12.7114C12.9432 17.5068 13.1364 17.2943 13.1364 17.0818V4.56365C13.1364 4.40911 13.0591 4.31251 12.9432 4.1966L9.40795 0.700018C9.27273 0.584109 9.15682 0.526149 9.00227 0.526149C6.12386 0.506831 3.24545 0.506836 0.386364 0.506836ZM0.85 1.35683H8.59659V4.54433C8.59659 4.77615 8.80909 4.96933 9.02159 4.96933H12.267V16.6375H0.85V1.35683ZM9.46591 1.9557L11.6682 4.11933H9.46591V1.9557Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7811 6.22534C2.54928 6.22534 2.3561 6.39921 2.33678 6.65034C2.31746 6.90148 2.51064 7.07534 2.76178 7.09466H2.7811H7.08906C7.32087 7.09466 7.51405 6.9208 7.53337 6.66966C7.55269 6.41852 7.35951 6.24466 7.10837 6.22534H7.08906H2.7811Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80063 8.56274C2.56881 8.56274 2.37563 8.73661 2.35631 8.98775C2.33699 9.23888 2.53018 9.41274 2.78131 9.43206H2.80063H10.3347C10.5665 9.43206 10.7597 9.25821 10.779 9.00707C10.779 8.77525 10.6052 8.58206 10.354 8.56274H10.3347H2.80063Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80063 10.9197C2.56881 10.9197 2.37563 11.0935 2.35631 11.3447C2.33699 11.5958 2.53018 11.7697 2.78131 11.789H2.80063H10.3347C10.5665 11.789 10.7597 11.6151 10.779 11.364C10.779 11.1322 10.6052 10.939 10.354 10.9197H10.3347H2.80063Z"
        fill={color}
      />
    </svg>
  );
}

export default PrescriptionIcon;
