import { useState } from "react";
import { imageSizeLimit } from "../config.json";

/**
 * Handles file uploading. Stores url of selected file
 * in its state and returns that state to the calling component
 *
 * If the selected file is larger than the limit set in config.json
 * it wont save the file and it will flag an error
 *
 *
 * @param {*} initialFile
 *
 * @returns {file, error, setFile, fileSelected}
 *      file: url object of file
 *      error: boolean used to display an error where needed
 *      reset: function that resets the file to initialValue
 *      fileSelected: function that handles the event when
 *                    a file is selected
 *
 *
 * @example
 * const [
 *   prescription,
 *   prescriptionError,
 *   resetPrescription,
 *   fileSelected
 * ] = useUploadFile({name: "", url: ""})
 *
 *  ...
 *
 * <input type="file" onChange={fileSelected} />
 *
 */
function useUploadFile(initialFile = { url: "", name: "" }) {
  const [file, setFile] = useState(initialFile);
  const [error, setError] = useState(false);

  function reset() {
    setFile(initialFile);
  }

  /**
   * Returns true if there is an error
   *
   * @param {*} e
   */
  function fileSelected(e) {
    const file = e.target.files[0];
    const { name } = file;
    const url = URL.createObjectURL(file);

    if (file.size > imageSizeLimit) {
      setError(true);

      return true;
    }

    setFile({ name, url });
    setError(false);
  }

  return [file, error, reset, fileSelected];
}

export default useUploadFile;
