import React, { useEffect } from "react";
import { CrossFailIcon } from "../../../assets/icons";

function PrescriptionExpired(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="prescription_expired">
      <div className="prescription_expired__content">
        <div className="prescription_expired__image">
          <CrossFailIcon />
        </div>
        <div className="prescription_expired__title">
          <p className="label--caps--normal">
            Expired
          </p>
          <h1 className="h3-a">Prescription Expired</h1>
          <p className="p5 m-t-s">
            Once a prescription has been issued by your doctor for a Schedule 2 controlled drug, you have 28 days to have that prescription fulfilled.
            This prescription has passed the 28 days.
          </p>
          <p className="p5 m-t-s">
            Please contact your clinic to get a new prescription is issued to you.
          </p>
        </div>

      </div>
    </section>
  );
}

export default PrescriptionExpired;