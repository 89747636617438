import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { selectIsIdVerified, selectIsVerified } from '../store/slices/accountSlice';
import { selectUser } from '../store/slices/authenticationSlice';

const ProtectedPatientRoute = ({
  component: Component,
  checkEmail = true,
  checkId = true,
  ...rest
}) => {
  const isEmailVerified = useSelector(selectIsVerified);
  const isIDVerified = useSelector(selectIsIdVerified);
  const user = useSelector(selectUser);

  const location = useLocation();

  const areWeOnPaymentPage = location.pathname.includes('/order/onboardedpayment/');
  const orderNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];

  if (areWeOnPaymentPage) {
    localStorage.setItem('orderBeingPaidForId', orderNumber);
  }

  // this is just in case this check runs before
  // the user data is fetched from the API
  if (
    user &&
    ((checkEmail && isEmailVerified === undefined) ||
      (checkId && isIDVerified === undefined))
  )
    return null;

  // explicit check in case it's undefined/null
  if (checkEmail && isEmailVerified === false)
    return <Redirect to="/emailnotverified" />;

  // explicit check in case it's undefined/null
  if (checkId && isIDVerified === false) return <Redirect to="/verification" />;

  return (
    <Route
      {...rest}
      render={(props) =>
        (user && user?.role === 1) || (user && user?.role === 2) ? <Component {...props} /> : <Redirect to="/auth/login" />
      }
    />
  );
}

export default ProtectedPatientRoute
