import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/authenticationSlice";

import Faq from "./Faq/Faq";

function Faqs(props) {
  const user = useSelector(selectUser);

  const [faqs, setFaqs] = useState(
    user?.role === 3 ? doctorFaqList : patientFaqList
  );

  const [isAtLeastOneShown, setIsAtLeastOneShown] = useState(false);

  function onClick(index) {
    const temp = [...faqs];

    temp[index].show = !temp[index].show;

    setFaqs(temp);
  }

  useEffect(() => {
    const isOneShown = faqs.find((faq) => faq.show === true);

    setIsAtLeastOneShown(isOneShown);
  }, [faqs]);

  const renderedFaqs = faqs.map((faq, index) => (
    <Faq
      isAtLeastOneShown={isAtLeastOneShown}
      {...faq}
      index={index}
      onClick={onClick}
      key={faq.question}
    />
  ));

  return (
    <section className="faqs">
      <h1 className="h7-a">Frequently asked questions</h1>
      <div className="faq_list">{renderedFaqs}</div>
    </section>
  );
}

export default Faqs;

const doctorFaqList = [
  {
    question: "Why is a paper copy required?",
    answer:
      "In order to dispatch the medication, we need to have your paper copy as required by the controlled drugs policy for FP10 prescriptions. We can process your prescription with the electronic copy but to ensure there is no delay, please send the paper copy to us as soon as possible. ",
    show: false,
  },
  {
    question: "When does a prescription expire?",
    answer:
      "After 28 days, the prescription becomes void and we are unable to process it.",
    show: false,
  },
  {
    question: "Can I upload future prescriptions?",
    answer:
      "Please upload prescriptions as they are needed by the patient rather than adding future prescriptions which may become void due to being older than 28 days by the time the medication is needed. ",
    show: false,
  },
];

const patientFaqList = [
  {
    question: "Why is a paper copy required?",
    answer:
      "In order to dispatch your medication, we need to have your physical paper copy in the Pharmacy. This is known as FP10CD prescription and is a regulation requirement to dispense for all unlicensed controlled drug prescriptions. We can process your prescription with the electronic copy but to ensure there is no delay, please send or ask your doctor to send the paper copy to us as soon as possible.",
    show: false,
  },
  {
    question: "When do I pay for my prescription?",
    answer: "The Lyphe Dispensary Patient Services team will send you a payment link when we have processed the scan of your prescription. You can pay whenever you are ready. Your medication will be dispensed and shipped once your medication arrives at the pharmacy.",
    show: false,
  },
  {
    question: "Can I pay for only some of the products in my prescription?",
    answer:
      "Should you wish to pay for only some or one of the products on your prescription, simply contact our customer service team for guidance, as your clinic/Consultant will need to be made aware of changes/amendments to your prescription so your treatment notes can be updated.",
    show: false,
  },
  {
    question: "Can you change any of the products on my prescription?",
    answer:
      "We are unable to change any of your medication. Please contact your doctor for a new prescription if any change is required.",
    show: false,
  },
  {
    question: "Why are some products still with the manufacturer?",
    answer: (
      <>
        <span className="p5">
          Some manufacturers are only able to provide products once we have submitted your prescription to them. Oils particularly can be specially made, so can take longer to reach the pharmacy.
        </span>
        <br />
        <span className="p5">
          We are working with them to avoid unnecessary delays.
        </span>
      </>
    ),
    show: false,
  },
  {
    question: "When does my prescription expire?",
    answer:
      "Once a prescription has been issued by your doctor for a Schedule 2 controlled drug, you have 28 days from the prescribing date to have that prescription fulfilled. After 28 days, that prescription becomes void and we are unable to process it.",
    show: false,
  },
  {
    question: "How long after my appointment will I receive my prescription?",
    answer:
      "Your doctor / clinic will have certain pathways to follow up before they are able to release your prescription to us. Once we have received your prescription, we work hard to ensure your medication is with you within a week (stock levels dependent).",
    show: false,
  },
  {
    question: "What delivery service do you use?",
    answer: "All UK packages are sent via Royal mail Next day Pre 1 pm Signed for and Tracked service. Shipments over water take a little longer to arrive.",
    show: false,
  },
  {
    question: "How do I request a repeat prescription?",
    answer: "Please speak with your doctor or Clinic.",
    show: false,
  },
  {
    question: "Do I take all my medication in one go?",
    answer:
      "Please follow the instructions on dosing found on your medication dispensing label and packaging or speak with your doctor",
    show: false,
  },
  {
    question: "How do I delete my account?",
    answer: (
      <span className="p5">
        Please contact us at:{" "}
        <a href="mailto: info@lyphe.com">
          info@lyphe.com
        </a> to request for your account to be deleted.
      </span>
    ),
    show: false,
  },
];
