import React from "react";

function AttentionIcon(props) {
  const color = props.color || "#FF0C0C";

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        d="M12.9433 6.808L12.7333 13.906H11.3893L11.1793 6.808H12.9433ZM12.9853 15.236V17H11.1653V15.236H12.9853Z"
        fill="white"
      />
    </svg>
  );
}

export default AttentionIcon;
