import React from "react";

function OrderStatusMedicationReadyIcon(props) {
  return (
    <svg
      width="42"
      height="48"
      viewBox="0 0 42 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.547 0.260538L0.461404 12.0261H0.409311C0.339937 12.0729 0.283233 12.1361 0.244272 12.2101C0.205311 12.2841 0.185309 12.3667 0.186055 12.4503L0.334892 35.7508C0.333977 35.8399 0.356965 35.9276 0.401465 36.0048C0.445965 36.0821 0.510349 36.1459 0.587916 36.1898L20.6288 47.628L20.8149 47.7322C20.9353 47.7985 21.0771 47.8146 21.2093 47.7768C21.2486 47.7678 21.2863 47.7528 21.3209 47.7322L41.414 35.9517H41.4512C41.5487 35.9011 41.627 35.8201 41.6744 35.721C41.6786 35.6889 41.6786 35.6564 41.6744 35.6243V35.5573L41.533 12.2568C41.5324 12.1671 41.508 12.0792 41.4622 12.002C41.4165 11.9249 41.351 11.8613 41.2726 11.8177L41.1535 11.7508L21.053 0.253096C20.9762 0.207488 20.8883 0.184041 20.799 0.185355C20.7096 0.186669 20.6224 0.212691 20.547 0.260538ZM20.8372 1.28751L39.8735 12.1303L20.8372 23.308L13.3954 19.1108L18.173 15.7917L11.6093 12.1973L6.74233 15.308L1.73396 12.4577L7.48652 9.08658L20.8372 1.28751ZM40.5507 12.9415L40.6995 35.267L21.4102 46.534L21.2763 24.2084L40.5507 12.9415ZM1.20559 13.3284L6.32559 16.2382V38.3182L1.34699 35.4829L1.20559 13.3284ZM11.6242 13.3731L16.2456 15.8959L12.5247 18.5005L7.8214 15.814L11.6242 13.3731ZM7.34512 16.8187L12.0409 19.4903L12.2344 41.4884L7.34512 38.787V16.8187ZM13.0605 20.0931L20.2568 24.1861L20.3907 46.348L13.2614 42.2847L13.0605 20.0931Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default OrderStatusMedicationReadyIcon;
