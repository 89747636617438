import React from "react";

function VerticalSeparator(props) {
  return (
    <span
      style={{
        margin: "0 16px 0 16px",
        width: "1.5px",
        height: "70%",
        backgroundColor: "#d5d5d5",
      }}
    ></span>
  );
}

export default VerticalSeparator;
