import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddressAutoCompleteForm } from "../../../components/forms";
import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import { selectPatient, setAddress } from "../../../store/slices/patientOnboardingSlice";
import { addressValidator, zipCodeValidator } from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";
import { NoticeCard } from "../../../components/cards";

function PatientAddress({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const patient = useSelector(selectPatient);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const autoComplete = useProps("");
  const address1 = useProps(patient.address1 || "", addressValidator("Address 1"));
  const address2 = useProps(patient.address2 || "", addressValidator("Address 2"));
  const zipCode = useProps(patient.zipCode || "", zipCodeValidator());
  const addressForm = { address1, address2, zipCode };

  const isButtonEnabled = isFormValid(addressForm);

  function nextClicked() {
    dispatch(setAddress(propsToValues(addressForm)));

    trackAnalyticsEvent("Patient Onboarding", "Patient Address added");
    setNextStep(steps.patientContact);
  }

  return (
    <section className="contact_information">
      <div className="contact_information__content">
        <div className="contact_information__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please tell us your address</h1>
          <p className="p5">
            Please tell us your address so we know where to send your medication
          </p>
        </div>

        <div className="contact_information__form">
          <div className="m-b-mm">
            <NoticeCard
              titleTop="Before you proceed"
              textTop="Please note we only dispense to UK addresses"
            />
          </div>

          <AddressAutoCompleteForm
            autoComplete={autoComplete}
            {...addressForm}
          />
        </div>

        <div className="contact_information__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            text="Next"
            onClick={nextClicked}
          />
        </div>
      </div>
    </section>
  );
}

export default PatientAddress;
