import React from 'react'
import { CrossFailIcon } from '../../../assets/icons'
import { PrimaryCta } from '../../buttons'
import { FullScreenOverlay } from '../../overlays'

const PatientDetailMismatchPopup = ({ show, onClose = () => null }) => {
    return (
        <FullScreenOverlay show={show}>

            <div className='patient_detail_mismatch_popup' >
                <div className="verification_card">
                    <div className='patient_detail_mismatch_popup__icon'>
                        <CrossFailIcon />
                    </div>
                    <div className='patient_detail_mismatch_popup__title'>
                        The details you entered <br /> don’t match our records
                    </div>
                    <div className="patient_detail_mismatch_popup__text">
                        Please check for any errors and try again. If you continue
                        seeing this message after entering your details, please
                        contact our support team at <a href='mailto:info@lyphe.com'>info@lyphe.com</a>
                    </div>
                    <div className='patient_detail_mismatch_popup__button' >
                        <PrimaryCta onClick={() => { onClose() }} color='peach' text="OK" />
                    </div>
                </div>
            </div>


        </FullScreenOverlay>
    )
}

export default PatientDetailMismatchPopup
