import React from "react";

function HeaderTooltipContent({ text }) {
	return (
		<div className={'product-note-tooltip-content'} >
			<div className={'product-note-tooltip-content__text'}>
				{text}
			</div>
		</div>
	);
}

export default HeaderTooltipContent;