import React from "react";

import { MinusIcon, PlusIcon } from "../../../../../assets/icons";

function Faq(props) {
  const { question, answer, onClick, index, show, isAtLeastOneShown } = props;

  function toggleShowAnswer() {
    onClick(index);
  }

  const answerClassName = show
    ? "faq__answer faq__answer--display"
    : "faq__answer";

  const textColorClassName = !show && isAtLeastOneShown ? "faq--grey" : "faq";
  const iconColor = textColorClassName === "faq--grey" ? "#d5d5d5" : "black";

  return (
    <div className={textColorClassName}>
      <div className="faq__question" onClick={toggleShowAnswer}>
        <p className="p4--normal">{question}</p>
        <div>
          {show ? (
            <MinusIcon color={iconColor} />
          ) : (
            <PlusIcon color={iconColor} />
          )}
        </div>
      </div>
      <div className={answerClassName}>
        <p className="p5">{answer}</p>
      </div>
      <div className="faq__line--display">
        <hr></hr>
      </div>
    </div>
  );
}

export default Faq;
