import React from "react";

function GreenCircle1(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#13ae7d" />
      <path
        d="M17.4742 15.218V13.292H21.5422V26H19.3822V15.218H17.4742Z"
        fill="black"
      />
    </svg>
  );
}

export default GreenCircle1;
