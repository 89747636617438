import React from "react";
import { PrimaryCta } from "../../../components/buttons";

import { SuccessIcon } from "../../../assets/icons";

function PasswordHasBeenChanged(props) {
  return (
    <section className="password_has_been_changed">
      <div className="password_has_been_changed__content">
        <div className="password_has_been_changed__image">
          <SuccessIcon />
        </div>
        <div className="password_has_been_changed__title">
          <h1 className="h3-a">Your password has been changed</h1>
          <p className="p5">
            Your password has now been updated. You will receive an email
            confirming this change. Please use this password to log into your
            account going forward.
          </p>
        </div>
        <div className="password_has_been_changed__button">
          <PrimaryCta
            color="orange"
            text="Dismiss"
            link="/prescriptions/active"
          />
        </div>
      </div>
    </section>
  );
}

export default PasswordHasBeenChanged;
