import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toastData: {
    message: '',
    title: '',
    showToast: false,
    type: ''
  }
};

const ToastSlice = createSlice({
  name: "toast",
  initialState,

  // sync reducers
  reducers: {
    setToast(state, { payload }) {
      state.toastData = payload;
    },
  },
});

export default ToastSlice.reducer;

// export sync actions
export const {
  setToast
} = ToastSlice.actions;

export const selectToast = (state) =>
  state.toast.toastData;

