import React, { useEffect } from "react";
import { AccountHeader } from "../../../components/headers";
import { AccountFooter } from "../../../components/footers";

import Faqs from "./Faqs/Faqs";

function Support(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="support">
      <AccountHeader color="green" />
      <div className="support__banner">
        <div>
          <h1 className="h4-p-a">We're here to help</h1>
          <p className="p4">
            Take a look at our frequently asked questions for everything you need to know.
            Alternatively, you can send an email to {" "}
            <span>
              <a href="mailto: info@lyphe.com">
                info@lyphe.com
              </a>
            </span>
          </p>
        </div>
      </div>

      <div className="support__body">
        <div className="support__content">
          <div className="support__faqs">
            <Faqs />
          </div>
        </div>
      </div>
      <div className="support__footer">
        <AccountFooter />
      </div>
    </section>
  );
}

export default Support;
