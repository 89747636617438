import React from 'react'
import { useHistory } from 'react-router-dom'
import { PrimaryCta } from '../../buttons'

export default function RepeatPrescriptionNoEligibleProductsPage() {
    const history = useHistory()
    const handleBack = () => {
        history.push("/prescriptions/active")
    }

    return (
        <div className='no_eligible_products_page'>
            <h1>Not eligible for a repeat yet</h1>
            <div className='divider'></div>
            <div className='no_eligible_products_page--top-content'>
                <p>In order to be eligible to request a repeat prescription, you need to have received at least two prescriptions within the last 6 months.</p>
                <p>If you believe you should be eligible for a repeat prescription, please<a className='link' target="_blank" href="https://lyphe.com/"> contact us</a> via Live Chat</p>
            </div>
            <div className='no_eligible_products_page--button'>
                <PrimaryCta
                    text={"Back to Lyphe Dispensary"}
                    color='form'
                    className='primary_cta--form'
                    onClick={handleBack}
                />
            </div>
        </div>
    )
}
