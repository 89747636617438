import React, { useEffect, useState } from "react";

import AutoCompleteOptions from "./AutoCompleteOptions/AutoCompleteOptions";
import { CloseIcon, SearchIcon } from "../../../assets/icons";

function AutoComplete({
  value = "",
  isEditable = true,
  onChange = () => null,
  name = "",
  options = [],
  filter,
  onClick = () => null,
  onRemove = () => null,
  className = "",
  Noicon = false
}) {
  // hides the autocomplete options if input isn't focused
  const [isFocused, setIsFocused] = useState(false);
  const labelClass = value ? "label-top" : "";

  function beforeOnChange(e) {
    if (!isFocused) setIsFocused(true);
    onChange(e);
  }

  const handleOnOptionCLick = (e) => {
    onClick(e)
    setIsFocused(false)
  }

  useEffect(() => {
    const listener = () => {
      setIsFocused(false)
    }
    window.addEventListener('click', listener)
    return () => {
      window.removeEventListener('click', listener)
    }
  }, [])

  return (
    <div onClick={(e) => { e.stopPropagation() }} className={`text-input-container  ${className}`}>
      <input
        onFocus={() => setIsFocused(true)}
        value={value}
        onChange={beforeOnChange}
        disabled={!isEditable}
        type="text"
      />
      <label className={labelClass}>{name}</label>

      <div
        style={{
          position: "absolute",
          right: "5%",
          top: "35%",
          cursor: value ? "pointer" : "auto",
        }}
      >
        {isEditable &&
          <>
            {value ? (
              <div onClick={onRemove}>
                <CloseIcon />
              </div>
            ) : (
              Noicon ? Noicon : <SearchIcon />
            )}
          </>
        }
      </div>

      <div
        className={`animated-border--black ${value ? "animated-border--show" : ""
          }`}
      ></div>
      {isEditable &&
        <AutoCompleteOptions
          onMouseUp={() => setIsFocused(false)}
          onClick={(e) => { handleOnOptionCLick(e) }}
          isFocused={isFocused}
          options={options}
          filter={filter}
          value={value}
        />
      }
    </div>
  );
}

export default AutoComplete;
