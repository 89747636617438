import React from "react";

function AutoCompleteOptions(props) {
  const { filter, options, isFocused, onClick, onMouseUp, value, } = props;

  // don't filter if filter is false
  const optionsToShow = filter
    ? options.filter((option) =>
      option.value ? option.label.toLowerCase().includes(value.toLowerCase()) : option.toLowerCase().includes(value.toLowerCase())
    )
    : options;

  const handleOnKeyDown = (e, option) => {
    const { keyCode, target } = e
    if (keyCode === 40) {
      if (target.nextElementSibling) {
        target.nextElementSibling.focus()
      }
    }
    if (keyCode === 38) {
      if (target.previousElementSibling) {
        target.previousElementSibling.focus()
      }
    }
    if (keyCode === 13) {
      onClick(option)
    }
  }

  /**
   * onMouseDown prevents onBlur from setting isFocused to false
   * and onClick gets triggered.
   * then onMouseUp hides autocomplete options when an option is selected.
   */
  const renderedOptions = optionsToShow.map((option, index) => (
    <div
      className="auto_complete__option"
      tabIndex={0}
      id={`option-${index}`}
      key={index}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => onClick(option)}
      onMouseUp={onMouseUp}
      onKeyDown={(e) => handleOnKeyDown(e, option)}
    >
      {option.label ? option.label : option}
    </div>
  ));

  // hide options when input isn't focused
  const autoCompleteStyles = !isFocused ? { display: "none" } : null;
  return (
    <div style={autoCompleteStyles} className="auto_complete__options">
      {renderedOptions.length ? (
        renderedOptions
      ) : (
        <div className="auto_complete__option">No results</div>
      )}
    </div>
  );
}

export default AutoCompleteOptions;
