import React from "react";

function ProductDetails({ content, column, applyLetterSpacing }) {
  const className = `product_details--${column ? "column" : "row"}`;

  return (
    <div className={className}>
      {content.map((item) => (
        item.text ?
          <div key={item.title}>
            <span
              className={`p6--semi-bold ${applyLetterSpacing ? "letter-spacing" : ""
                }`}
            >
              {item.title}{" "}
            </span>
            <span style={{ textTransform: "uppercase" }} className="p6">
              {item.text}
            </span>
          </div>
          : <span key={item.title}></span>
      ))}
    </div>
  );
}

export default ProductDetails;
