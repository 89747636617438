import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Label, PrimaryCta } from "../../buttons";

import { selectUser } from "../../../store/slices/authenticationSlice";
import { OrderStatuses } from "../../../enums";
import {
  selectActivePrescriptions,
  selectAllPrescriptions,
  selectLoadingActivePrescriptions,
  selectLoadingAllPrescriptions,
  selectShowTracker,
  selectTrackedOrderIndex,
  setShowMobileTracker,
} from "../../../store/slices/prescriptionsSlice";

import { courierTrackUrl } from "../../../config.json";

import {
  RedDangerCircle,
  CloseIcon,
  GreyBlankCircleIcon,
  GreenCheckedCircleIcon,
} from "../../../assets/icons";
import TrackOrderPopup from "../../popups/TrackOrderPopup/TrackOrderPopup";
import { setPayModal } from "../../../store/slices/modalsSlice";

function OrderTracker() {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const trackedOrderIndex = useSelector(selectTrackedOrderIndex);
  const activePrescriptions = useSelector(selectActivePrescriptions);
  const allPrescriptions = useSelector(selectAllPrescriptions);
  const showMobileTracker = useSelector(selectShowTracker);
  const loadingActivePrescriptions = useSelector(
    selectLoadingActivePrescriptions
  );
  const loadingAllPrescriptions = useSelector(
    selectLoadingAllPrescriptions
  );

  const [showTrackOrderPopup, setShowTrackOrderPopup] = useState(false)
  const closeTrackOrderPopup = () => {
    setShowTrackOrderPopup(false)
  }

  const trackedOrder = user?.role === 3 ? allPrescriptions.find(p => p.index === trackedOrderIndex) : activePrescriptions.find(p => p.index === trackedOrderIndex);

  const handleOnMakePayment = () => {
    dispatch(setPayModal({ show: true, order: trackedOrder }));
    closeTracker();
  }

  /**
   * List of all steps for the tracker
   */
  const steps = useMemo(() => ({
    OutOfStock: {
      title: "We have received your prescription.",
      text: "We'll let you know as soon as your prescription is ready for payment.",
      checked: false,
    },

    InStock: {
      title: "Prescription processed",
      text: "",
      checked: true,
    },

    AwaitingPaperPrescription: {
      title: "Awaiting paper prescription",
      text: (
        <span className="p6 ">
          Your paper prescription is on its way to the pharmacy and your order status will be updated once it has arrived.
        </span>
      ),
      checked: false,
    },

    AwaitingPayment: {
      title: ("Awaiting payment"),
      text: (
        <span>
          Please pay within the 7-day payment window to avoid any delays.
          <span style={{ display: 'block' }} className='m-t-s' >
            <PrimaryCta
              text={"Make payment"}
              color='white'
              className='primary_cta--fit_content'
              onClick={handleOnMakePayment}
            />
          </span>
        </span>
      ),
      checked: false,
    },

    PaymentReceived: { title: "Payment received", text: "", checked: true },

    PaperPrescriptionReceived: {
      title: "Paper prescription received ",
      text: "",
      checked: true,
    },

    WaitingToBeDispatched: {
      title: "Waiting to be dispatched",
      text: (
        "We'll send your medication as soon as possible"
      ),
      checked: false,
    },

    Shipped: {
      checked: true,
      title: "Dispatched",
      text: (
        <>
          <p className="p6 m-b-xxs">
            <span className="label--semi-bold">Delivery status: </span>{" "}
            Dispatched
          </p>
          <p className="p6 m-b-xxs">
            <span className="p6--semi-bold">Tracking number: </span>{" "}
            <a href={courierTrackUrl} rel="noopener noreferrer" target="_blank">
              {trackedOrder?.externalTrackingCode}
            </a>
          </p>
        </>
      ),
    },

    paymentMissed: {
      checked: true,
      iconColor: "red",
      title: "Payment deadline missed",
    },

    Void: {
      checked: false,
      title: "Prescription expired",
      text: (
        "Please book an appointment for new medication"
      ),
    },

    NoActivePrescriptions: {
      checked: false,
      title: "Please book an appointment",
      text:
        "To access new medication please book a new consultation to get started",
    },

    Processed: {
      checked: false,
      title: "We have received your prescription.",
      text: "We'll let you know as soon as your prescription is ready for payment",
    },
    //eslint-disable-next-line
  }), [trackedOrder?.externalTrackingCode])
  /**
   * Returns a list of steps for tracker sidebar based on
   * the status of the order.
   * Status of order comes from prescriptionService
   */
  const orderStepsMapperPatientCarer = useMemo(
    () => ({
      [OrderStatuses.NotApproved]: [steps.Processed],

      [OrderStatuses.OutOfStock]: [
        steps.OutOfStock,
        steps.AwaitingPaperPrescription,
      ],

      [OrderStatuses.OutOfStockAndPaper]: [
        steps.PaperPrescriptionReceived,
        steps.OutOfStock,
      ],

      [OrderStatuses.NotPaidAndNoPaper]: [
        steps.InStock,
        steps.AwaitingPaperPrescription,
        steps.AwaitingPayment,
      ],

      [OrderStatuses.NotPaidAndPaper]: [
        steps.InStock,
        steps.PaperPrescriptionReceived,
        steps.AwaitingPayment,
      ],

      [OrderStatuses.PaidAndNoPaper]: [
        steps.InStock,
        steps.PaymentReceived,
        steps.AwaitingPaperPrescription,
      ],

      [OrderStatuses.PaidAndPaper]: [
        steps.InStock,
        steps.PaperPrescriptionReceived,
        steps.PaymentReceived,
        steps.WaitingToBeDispatched,
      ],

      [OrderStatuses.Shipped]: [
        steps.InStock,
        steps.PaperPrescriptionReceived,
        steps.PaymentReceived,
        steps.Shipped,
      ],

      [OrderStatuses.NoPaperAndPaymentMissed]: [
        steps.InStock,
        steps.AwaitingPaperPrescription,
        steps.paymentMissed,
      ],

      [OrderStatuses.PaperAndPaymentMissed]: [
        steps.InStock,
        steps.PaperPrescriptionReceived,
        steps.paymentMissed,
      ],

      [OrderStatuses.Void]: [steps.Void],

      undefined: [steps.NoActivePrescriptions],
      null: [steps.NoActivePrescriptions],
    }),
    //eslint-disable-next-line
    [trackedOrder?.externalTrackingCode]
  );

  let trackingSteps = orderStepsMapperPatientCarer[trackedOrder?.status];
  // if the user is doctor
  // and there is no order to track
  // don't show any steps
  if (user?.role === 3 && !trackedOrder) {
    trackingSteps = [];
  }

  function closeTracker() {
    dispatch(setShowMobileTracker(false));
  }

  const renderPrescriptionNumberOrOrderId = () => {
    return <>
      {trackedOrder && (
        trackedOrder.guid ?
          <>
            <div>
              <span className="p6--semi-bold">Prescription no: </span>{" "}
              <span className="p6">{trackedOrder.paperPrescriptionId}</span>
            </div>
            <div>
              <span className="p6--semi-bold">Order Number: </span>{" "}
              <span className="p6">{trackedOrder.orderId}</span>
            </div>
          </>

          :
          trackedOrder.paperPrescriptionId ?
            <div>
              <span className="p6--semi-bold">Prescription no: </span>{" "}
              <span className="p6">{trackedOrder.paperPrescriptionId}</span>
            </div>
            :
            <></>
      )}
    </>
  }

  return (
    <div
      className={`status_sidebar ${!showMobileTracker ? "status_sidebar--hide" : ""
        }`}
    >
      <div className="status_sidebar__title">
        <p className="label--caps">{user?.role === 3 ? 'Order info' : 'My prescription status'}</p>

        {isAttentionNeededDisplayed(user?.role, trackedOrder?.status) && (
          <Label text="attention needed" color="orange" />
        )}

        <div onClick={closeTracker} className="status_sidebar__close">
          <CloseIcon />
        </div>
      </div>

      <div className="status_sidebar__content">
        <div className="status_sidebar__content__subtitle">
          {!loadingActivePrescriptions && !loadingAllPrescriptions && (
            <>
              {!trackedOrder && user?.role === 3 && (
                <div>
                  <h3 className="h9-a">Please book an appointment</h3>
                  <p className="p6">
                    To access new medication please book
                    a new consultation to get started
                  </p>
                </div>
              )}

              {trackedOrder && (
                <h3 className="h9-a">Current prescription status</h3>
              )}

              {trackedOrder && user?.role === 3 && (
                <div>
                  <span className="p6--semi-bold patient_name">
                    patient name:{" "}
                  </span>{" "}
                  <span className="p6">{`${trackedOrder?.patient.firstName} ${trackedOrder?.patient.lastName}`}</span>
                </div>
              )}
              {renderPrescriptionNumberOrOrderId()}
            </>
          )}
        </div>

        <div className="status_sidebar__steps">
          {trackingSteps?.map((step, index) => (
            <div className="step_container" key={step.title}>
              <div className="step_title ">
                <div className="step_title__circle">{getStepIcon(step)}</div>
                <div className="step_title__text p5--normal">{step.title}</div>
              </div>

              <div className={getStepTextClass(trackingSteps, index)}>
                <div className="step_text__empty"></div>
                <div className="p6">{step.text}</div>
              </div>
            </div>
          ))}
        </div>

        {trackedOrder?.externalTrackingCode && user.role === 3 &&
          <div style={{ maxWidth: '50%', margin: 'auto', marginBottom: '1rem' }}>
            <PrimaryCta
              color="white"
              text="View Tracking"
              onClick={() => { setShowTrackOrderPopup(true) }}
            />
          </div>
        }
        <TrackOrderPopup close={closeTrackOrderPopup} show={showTrackOrderPopup} data={trackedOrder} />
      </div>
    </div>
  );
}

function getStepIcon(step) {
  if (!step.checked) {
    return (
      <div className="checked_circle">
        <GreyBlankCircleIcon />
      </div>
    );
  }

  if (step.iconColor === "red") {
    return (
      <div className="unchecked_circle">
        <RedDangerCircle />
      </div>
    );
  }

  return (
    <div className="unchecked_circle">
      <GreenCheckedCircleIcon />
    </div>
  );
}

function getStepTextClass(trackingSteps, index) {
  let className = "step_text";

  // if this is the last element don't add the border
  if (index === trackingSteps.length - 1) return className;

  if (
    trackingSteps[index + 1]?.checked &&
    trackingSteps[index + 1]?.iconColor !== "red"
  ) {
    className += " border_left--green";

    return className;
  }

  className += " border_left--green-light";

  return className;
}

export default OrderTracker;

/**
 * Doctor will see the label only when paper is needed
 * Patient/Carer will see the label when paper is needed
 * or when payment is needed.
 * @param {*} userRole
 * @param {*} orderState
 */
function isAttentionNeededDisplayed(userRole, orderState) {
  const orderStatesForDoctor = [
    OrderStatuses.OutOfStock,
    OrderStatuses.NotPaidAndNoPaper,
    OrderStatuses.PaidAndNoPaper,
    OrderStatuses.NoPaperAndPaymentMissed,
  ];

  const displayOnDoctorAccount = orderStatesForDoctor.includes(orderState);
  if (userRole === 3 && displayOnDoctorAccount) {
    return true;
  } else if (
    displayOnDoctorAccount ||
    orderState === OrderStatuses.NotPaidAndPaper
  ) {
    return true;
  }

  return false;
}
