import React from "react";

function CloseIcon({ color = "black", width = '20', height = ' 19' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.16113"
        y="17"
        width="24"
        height="2"
        rx="1"
        transform="rotate(-45 1.16113 17)"
        fill={color}
      />
      <rect
        x="1.86841"
        width="24"
        height="2"
        rx="1"
        transform="rotate(45 1.86841 0)"
        fill={color}
      />
    </svg>
  );
}

export default CloseIcon;
