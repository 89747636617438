import React, { useEffect } from "react";

import { PrimaryCta } from "../../components/buttons";
import { BeforeWeStartHeader } from "../../components/headers";
import { learnUrl } from '../../config.json'


function GetStarted() {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="before_we_start">
      <BeforeWeStartHeader />
      <div className="before_we_start__left_slot">
        <div className="before_we_start__overlay"></div>
      </div>
      <div className="before_we_start__right_slot">
        <div>
          <div className="before_we_start__title">
            <p className="label--caps--normal">Getting started</p>
            <h1 className="h3-a">How can we help you?</h1>
          </div>
          <div className="before_we_start__button--wrapper">
            <div className="before_we_start__button">
              <PrimaryCta
                text="I’d like some medication"
                color="orange"
                link="/onboarding"
              />
            </div>
            <div className="before_we_start__button">
              <PrimaryCta
                text="I'd like to refer my patient"
                color="orange"
                link="/onboarding/doctor"
              />
            </div>
          </div>
          <p className="p5">
            Or visit our <a href={learnUrl} className="before_we_start__link">educational section to learn more</a>.
          </p>
        </div>
      </div>
    </section>
  );
}

export default GetStarted;
