import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { BeforeWeStartHeader } from "../../../components/headers";

import { getPatientDataForCompleteAccount } from "../../../store/slices/patientOnboardingSlice";

import GreenCircle1SFPro from "../../../assets/icons/GreenCircle1SFPro";
import GreenCircle2SFPro from "../../../assets/icons/GreenCircle2SFPro";
import GreenCircle3SFPro from "../../../assets/icons/GreenCircle3SFPro";
import { useHistory, useParams } from "react-router";

function PatientBeforeWeStartSecretId(props) {
  const { setNextStep } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { secretId } = useParams();

  // get user data if secret is present
  useEffect(() => {
    if (!secretId) return;

    // handle if secretId has already been used
    async function getPatientData() {
      const { error } = await dispatch(
        getPatientDataForCompleteAccount(secretId)
      );

      if (error) {
        history.push("/linkexpired");
      }
    }

    getPatientData();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="before_we_start">
      <BeforeWeStartHeader showClose={false} />
      <div className="before_we_start__left_slot before_we_start__left_slot--patient">
        <div className="before_we_start__overlay"></div>
      </div>
      <div className="before_we_start__right_slot">
        <div>
          <div className="before_we_start__title">
            <p className="label--caps--normal">Getting started</p>
            <h1 className="h3-a">Welcome to Lyphe Dispensary</h1>
            <p className="p4">
              Signing up is simple and should take no more than a few minutes
            </p>
          </div>
          <div className="before_we_start__steps">
            <ol>
              <li>
                <GreenCircle1SFPro />
                <p>Confirm your details</p>
              </li>
              <li>
                <GreenCircle2SFPro />
                <p>Set up a password</p>
              </li>
              <li>
                <GreenCircle3SFPro />
                <p>Verify your email address</p>
              </li>
            </ol>
          </div>
          <div className="before_we_start__button">
            <PrimaryCta
              onClick={() => setNextStep(props.steps.name)}
              text="Get started"
              color="orange"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PatientBeforeWeStartSecretId;
