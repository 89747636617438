import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import XIcon from '../../../assets/icons/XIcon';
import { Logo } from '../../../assets/images';
import { setShowRepeatPrescriptionFormGuideline } from '../../../store/slices/repeatPrescriptionSlice';

export default function RepeatPrescriptionHeader({ showNativeBrowserWarning = false }) {

    const history = useHistory()
    const dispatch = useDispatch();

    const handleLogoClick = () => {
        if (!showNativeBrowserWarning) {
            history.push("/prescriptions/active");
            dispatch(setShowRepeatPrescriptionFormGuideline(true))
        }

        if (showNativeBrowserWarning && window.confirm("Anything entered in the repeat prescription request form will not be saved")) {
            history.push("/prescriptions/active");
            dispatch(setShowRepeatPrescriptionFormGuideline(true))
        }
    }
    return (
        <div className='repeat_prescription--header'>
            <div style={{ cursor: 'pointer' }}>
                <Logo onClick={handleLogoClick} color="black" />
            </div>
            <div onClick={handleLogoClick} className='xIcon'><p>Close</p><XIcon /></div>
        </div>
    )
}
