import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useProps, useRadioButton } from '../../../hooks'
import { payOrder, selectOrderToBePaid, setTransactionData, selectSubmittingPayment, selectPaymentFailed } from '../../../store/slices/prescriptionsSlice'
import { isFormValid } from '../../../util/util'
import { addressValidator, cardExpirationDateValidator, creditCardValidator, cvcValidator, nameValidator, zipCodeValidator } from '../../../util/validators'
import { PrimaryCta } from '../../buttons'
import OrderPaymentCard from '../../cards/OrderPaymentCard/OrderPaymentCard'
import { InputField, RadioButton } from '../../inputs'
import AddressAutoCompleteForm from '../AddressAutoCompleteForm/AddressAutoCompleteForm'
import CreditCardForm from '../CreditCardForm/CreditCardForm'
import useOpayo from '../../../hooks/useOpayo.js'
import { AttentionIcon } from '../../../assets/icons'
import { termsAndConditionsUrl } from '../../../config.json'
import Spinner from '../../sharedComponents/Spinner/Spinner'
import GenericBanner from '../../banners/GenericBanner/GenericBanner'
import { setToast } from '../../../store/slices/toastSlice'
import { toastTypes } from '../../../enums'
import PaymentFormOrderedProducts from './PaymentFormOrderedProducts'
import { useParams } from 'react-router'

const OrderPaymentForm = () => {
    const dispatch = useDispatch()
    const order = useSelector(selectOrderToBePaid);
    const submittingPayment = useSelector(selectSubmittingPayment);
    const paymentFailed = useSelector(selectPaymentFailed);
    const { orderNumber } = useParams();

    const autoComplete = useProps("");

    const address1 = useProps("", addressValidator("Address 1"));
    const address2 = useProps("", addressValidator("Address 2"));
    const zipCode = useProps("", zipCodeValidator());

    const addressForm = { address1, address2, zipCode };

    const ref = useRef(false);

    const valueTransformer = (value, event) => {
        if (ref.current) {
            ref.current = false
            return value
        }
        if (value.length === 2 && !value.includes('/')) {
            ref.current = true
            return value + '/'
        }
        return value
    }
    const removeWhiteSpaceFromCardNumberTransformer = (value) => {
        return value.split(" ").join("")
    }

    const cardHolderFirstName = useProps("", nameValidator('Cardholders first name'))
    const cardHolderLastName = useProps("", nameValidator('Cardholders last name'))
    const cardNumber = useProps('', creditCardValidator(), removeWhiteSpaceFromCardNumberTransformer, true)
    const expiryDate = useProps('', cardExpirationDateValidator(), valueTransformer)
    const cvc = useProps('', cvcValidator())

    const { errors: cardNumberErrors } = cardNumber;

    useEffect(() => {
        if (cardNumberErrors.includes('American express cards are not supported')) {

            const newToastState = {
                message: 'American Express cards are not supported. Please use a different card type.',
                title: 'Card not supported',
                showToast: true,
                type: toastTypes.Error
            }

            dispatch(setToast(newToastState))
        }
        // eslint-disable-next-line
    }, [cardNumberErrors])

    const agreedToTerms = useRadioButton(false);

    const { merchantSessionKey,
        cardIdentifier,
        getOpayoTransactionData,
        setMerchantSessionKey,
        setCardIdentifier } = useOpayo({
            cardHolderName: `${cardHolderFirstName.value} ${cardHolderLastName.value}`,
            cardNumber: cardNumber.value,
            expiryDate: expiryDate.value.split('/').join(''),
            cvc: cvc.value
        })

    const form = {
        ...addressForm,
        cardHolderFirstName,
        cardHolderLastName,
        cardNumber,
        expiryDate,
        cvc
    }
    const isButtonEnabled = isFormValid(form)


    useEffect(() => {
        if (merchantSessionKey && cardIdentifier) {
            dispatch(setTransactionData({
                cardNumber: cardNumber.value,
                cardHolderFirstName: cardHolderFirstName.value,
                cardHolderLastName: cardHolderLastName.value,
                expiryDate: expiryDate.value.split('/').join(''),
                cvc: cvc.value,
                address1: address1.value,
                address2: address2.value,
                zipCode: zipCode.value,
                merchantSessionKey,
                cardIdentifier,
                orderNumber
            }))
            dispatch(payOrder())
            setMerchantSessionKey(null)
            setCardIdentifier(null)
        }
        // eslint-disable-next-line
    }, [merchantSessionKey, cardIdentifier])

    const handleOnClick = async () => {
        getOpayoTransactionData()
    }


    return (
        <OrderPaymentCard>
            <div className='order_payment_form'>
                <div className='order_payment_form__horizontal_marign'>
                    <PaymentFormOrderedProducts order={order} />
                </div>

                <div className='order_payment_form__divider'></div>

                <div className='order_payment_form__horizontal_marign'>
                    <div className='order_payment_form__title m-b-s' >Card details</div>
                    <GenericBanner text={'American Express cards are not supported.'} icon={<AttentionIcon color={'#3B5EDB'} />} />
                    <h5 className='m-t-s'>Name on card</h5>
                    <InputField type='text' name='Cardholders first name' {...cardHolderFirstName} placeholder='Cardholder first name' />
                    <div className='m-t-s'>
                        <InputField type='text' name='Cardholders last name' {...cardHolderLastName} placeholder='Cardholder last name' />
                    </div>
                    <div className='m-t-s'>
                        <h5>Billing address</h5>
                        <AddressAutoCompleteForm
                            autoComplete={autoComplete}
                            {...addressForm}
                        />
                        <CreditCardForm cardNumber={cardNumber} expiryDate={expiryDate} cvc={cvc} />
                    </div>

                    <div className='order_payment_form__terms radio-container'>
                        <RadioButton
                            id="accept_terms"
                            name={renderRadioButtonText()}
                            {...agreedToTerms}
                        />
                    </div>
                    {paymentFailed &&
                        <div className='order_payment_form__payment_failed'>
                            <AttentionIcon /> <span>Payment failed, please try again</span>
                        </div>
                    }
                    <div className='order_payment_form__confirm_button'>
                        <PrimaryCta
                            onClick={() => { handleOnClick() }}
                            isEnabled={isButtonEnabled && agreedToTerms.value && !submittingPayment}
                            text={submittingPayment ? <Spinner /> : 'Confirm payment'}
                        />
                    </div>
                </div>

            </div>
        </OrderPaymentCard>
    )
}

export default OrderPaymentForm


const renderRadioButtonText = () => {
    return (
        <span>I agree to the Lyphe Dispensary  &nbsp;
            <a href={termsAndConditionsUrl} target='_blank' rel="noopener noreferrer" >
                Terms & Conditions
            </a>
        </span>
    )
}