import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    selectFromPrescriptionSliceByKey,
    getAllFilteredPrescriptionsAsClinicUser, setClinicSearchSelectedValue as setClinicSearchPrescriptionsSelectedValue, setClinicSearchInputValue as setClinicSearchPrescriptionsInputValue, setClinicSearchParameter, setClinicSearchType,
    getPrescriptionsForOrganisation, resetPrescriptionsForClinicUser
} from "../store/slices/prescriptionsSlice";
import {
    selectFromProductSliceByKey, setClinicSearchSelectedValue as setClinicSearchProductsSelectedValue,
    setClinicSearchInputValue as setClinicSearchProductsInputValue, resetProductsForClinicUser, getProductsForClinic,
    getProductsForPatient
} from "../store/slices/productSlice";
import { selectCurrentOrganisation, selectUserDetails } from "../store/slices/accountSlice";
import { headerPageTypes, EmployeeType } from "../enums";

function useSearchHandler() {
    const dispatch = useDispatch();
    const location = useLocation();

    const currentOrganisation = useSelector(selectCurrentOrganisation);
    const userDetails = useSelector(selectUserDetails);

    const noPrescriptionResultsFromClinicSearch = useSelector(selectFromPrescriptionSliceByKey("noResultsFromClinicSearch"));
    const noProductResultsFromClinicSearch = useSelector(selectFromProductSliceByKey("noResultsFromClinicSearch"));
    const noProductResultsFromPatientSearch = useSelector(selectFromProductSliceByKey("noResultsFromPatientSearch"));

    const loadingPrescriptionResultsFromClinicSearch = useSelector(selectFromPrescriptionSliceByKey("loadingResultsFromClinicSearch"));
    const loadingProductResultsFromClinicSearch = useSelector(selectFromProductSliceByKey("loadingResultsFromClinicSearch"));
    const loadingProductResultsFromPatientSearch = useSelector(selectFromProductSliceByKey("loadingResultsFromPatientSearch"));

    const searchListPrescriptionOptions = useSelector(selectFromPrescriptionSliceByKey("searchResultsForClinicUser"));
    const searchListProductOptions = useSelector(selectFromProductSliceByKey("searchResultsForClinicUser"));

    const [searchTerm, setSearchTerm] = useState('');
    const [previousSearchTerm, setPreviousSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(headerPageTypes.prescriptions);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 3 || (previousSearchTerm.length >= 3 && searchTerm.length === 0 && currentPage != headerPageTypes.prescriptions)) { //need to omit prescriptions since they require the user to select from a dropdown
                const payload = {
                    query: searchTerm,
                    organisationId: currentOrganisation.organisationId,
                    organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
                        ? userDetails.id
                        : null
                };

                switch (currentPage) {
                    case headerPageTypes.prescriptions:
                        dispatch(getAllFilteredPrescriptionsAsClinicUser(payload));
                        break;
                    case headerPageTypes.formulary:
                        dispatch(getProductsForClinic(payload));
                        break;
                    case headerPageTypes.patientFormulary:
                        dispatch(getProductsForPatient({ query: searchTerm }));
                        break;
                    default:
                        break;
                }

                setPreviousSearchTerm(searchTerm);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, currentPage, dispatch, currentOrganisation, userDetails]);

    useEffect(() => {
        const path = location.pathname;
        if (path.includes("/clinicPrescriptions")) {
            setCurrentPage(headerPageTypes.prescriptions);
        } else if (path.includes("/clinicPatients")) {
            setCurrentPage(headerPageTypes.patients);
        } else if (path.includes("/clinicFormulary")) {
            setCurrentPage(headerPageTypes.formulary);
        } else if (path.includes("/patientFormulary")) {
            setCurrentPage(headerPageTypes.patientFormulary);
        }
    }, [location.pathname]);

    return {
        searchTerm, setSearchTerm,
        currentPage,
        noPrescriptionResultsFromClinicSearch,
        noProductResultsFromClinicSearch,
        noProductResultsFromPatientSearch,
        searchListPrescriptionOptions,
        searchListProductOptions,
        currentOrganisation,
        userDetails,
        loadingPrescriptionResultsFromClinicSearch,
        loadingProductResultsFromClinicSearch,
        loadingProductResultsFromPatientSearch
    };
}


export default useSearchHandler;