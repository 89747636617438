import React from 'react'

const GreenCircle1SFPro = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#00a372" />
            <path d="M14.9316 22H17.4023V7.9082H14.9121L11.2305 10.4961V12.8105L14.873 10.3105H14.9316V22Z" fill="#F6F6F6" />
        </svg>

    )
}

export default GreenCircle1SFPro
