import React from 'react'
import { Logo } from '../../../assets/images'

const OrderPaymentCard = ({ props, children }) => {
    return (
        <div className='order_payment_card'>
            <div className='order_payment_card__header'>
                <Logo color={'rgba(0, 0, 0, 0.4)'} />
            </div>
            {children}
        </div>
    )
}

export default OrderPaymentCard
