import React from "react";
import { useSelector } from "react-redux";

import { selectUser } from "../../../../store/slices/authenticationSlice";
import { QuaternaryCta } from "../../../buttons";
import { useGetNavBarText } from "../../../../hooks";
import RepeatPrescriptionIcon from "../../../../assets/icons/RepeatPrescriptionIcon";
import { UserRoles } from "../../../../enums";
import { callMapper } from "../../../../util/util";
import ClinicSearch from "../../../ClinicSearch/ClinicSearch";
import PrescriptionsFilter from "../../../ClinicFilter/PrescriptionsFilter"
import ClinicProductsFilter from "../../../ClinicFilter/ClinicProductsFilter"
import PatientProductsFilter from "../../../ClinicFilter/PatientProductsFilter"

import { headerPageTypes } from "../../../../enums";
import PatientProductSearch from "../../../PatientSearch/PatientProductSearch";
import { useSearchHandler } from "../../../../hooks";


function DesktopDashboardNavigation() {
  const user = useSelector(selectUser);
  const navBarText = useGetNavBarText();

  const {
    searchTerm, setSearchTerm,
    currentPage,
    noPrescriptionResultsFromClinicSearch,
    noProductResultsFromClinicSearch,
    noProductResultsFromPatientSearch,
    searchListPrescriptionOptions,
    searchListProductOptions,
    currentOrganisation,
    userDetails,
    loadingPrescriptionResultsFromClinicSearch,
    loadingProductResultsFromClinicSearch,
    loadingProductResultsFromPatientSearch
  } = useSearchHandler();

  //CONTENT
  const patientCarerLeftContent = () => {
    if (currentPage === headerPageTypes.patientFormulary) {
      return <div style={{ display: 'flex' }}>
        <PatientProductSearch loading={loadingProductResultsFromPatientSearch} onChange={(e) => setSearchTerm(e.target.value)} />
        <PatientProductsFilter />
      </div>
    }
    else {
      return <QuaternaryCta
        link="/repeatPrescription"
        text="Request a repeat prescription"
        icon={<RepeatPrescriptionIcon />}
      />
    }
  }

  const clinicUserLeftContent = () => {
    return <div style={{ display: 'flex' }}>
      {currentPage === headerPageTypes.prescriptions &&
        <ClinicSearch loading={loadingPrescriptionResultsFromClinicSearch} onChange={(e) => setSearchTerm(e.target.value)} />}
      {currentPage === headerPageTypes.prescriptions &&
        <PrescriptionsFilter />}

      {currentPage === headerPageTypes.formulary &&
        <ClinicSearch loading={loadingProductResultsFromClinicSearch} onChange={(e) => setSearchTerm(e.target.value)} />}
      {currentPage === headerPageTypes.formulary &&
        <ClinicProductsFilter />}
    </div>
  }

  const leftContentMapper = {
    [UserRoles.OrganisationEmployee]: clinicUserLeftContent,
    [UserRoles.Patient]: patientCarerLeftContent,
    [UserRoles.Carer]: patientCarerLeftContent,
  }

  const rightContentMapper = {
    [UserRoles.OrganisationEmployee]: navBarText,
    [UserRoles.Patient]: navBarText,
    [UserRoles.Carer]: navBarText,
  }

  return (
    <nav className="desktop_dashboard__navigation">
      <div className="desktop_dashboard__links">{callMapper(leftContentMapper, user.role, () => null)()}</div>
      <div className="desktop_dashboard__actions"> {callMapper(rightContentMapper, user?.role, "")}</div>
    </nav>
  );
}

export default DesktopDashboardNavigation;
