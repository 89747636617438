import React from "react";

function TickIcon({ color = "black" }) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76372 10C4.53984 10 4.24133 9.92537 4.09208 9.77612L0.435363 7.16418C-0.0123986 6.79104 -0.161652 6.1194 0.211482 5.59702C0.435363 5.29851 0.73387 5.14925 1.107 5.14925C1.33089 5.14925 1.55477 5.22388 1.77865 5.37313L4.61447 7.38806L10.8085 0.298507C11.0324 0.0746269 11.3309 0 11.5548 0C11.8533 0 12.0772 0.0746269 12.301 0.298507C12.7488 0.746269 12.8234 1.41791 12.3757 1.86567L5.58462 9.62687C5.36074 9.85075 5.06223 10 4.76372 10Z"
        fill={color}
      />
    </svg>
  );
}

export default TickIcon;
