import React, { useEffect, useState } from "react";
import { FullScreenOverlay } from "../../overlays";
import { CloseIcon, MoreInfoIcon, UploadIcon } from "../../../assets/icons";
import PrescriptionPreview from "../../PrescriptionPreview/PrescriptionPreview";
import { PrimaryCta } from "../../buttons";
import ReusableDropZone from "../../sharedComponents/ReusableDropZone/ReusableDropZone";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrescribersForOrganisationEmployee,
  getPatientByClinicId, getPrescriptionsForOrganisation, resetPatientForClinicUser,
  selectPrescribersForOrganisationEmployee,
  selectFromPrescriptionSliceByKey,
  selectPatientForClinicUser,
  selectPrescriptionForUploadAsClinicUser,
  setInStateByKey,
  setPrescriptionForUploadAsClinicUser, uploadPrescriptionAsClinicUser, uploadProtoPrescriptionAsOrganisationEmployee,
} from "../../../store/slices/prescriptionsSlice";
import { AutoComplete, Checkbox, CustomSelect, InputField } from "../../inputs";
import { useProps } from "../../../hooks";
import GenericBanner from "../../banners/GenericBanner/GenericBanner";
import Spinner from "../../sharedComponents/Spinner/Spinner";
import { EmployeeType, OrganisationPrescriptionsTypes, toastTypes } from "../../../enums";
import { callMapper } from "../../../util/util";
import { useParams } from "react-router-dom";
import { setToast } from "../../../store/slices/toastSlice";
import TickIconSuccess from "../../../assets/icons/TickIconSuccess";
import { ReactComponent as TrashBin } from "../../../assets/images/Trash_can.svg";
import { selectCurrentOrganisation, selectUserDetails } from "../../../store/slices/accountSlice";

function UploadPrescriptionOrganisationModal({ show = false, onClose = () => null, }) {

  const dispatch = useDispatch();
  const { status } = useParams();
  const selectedPrescriptions = useSelector(selectPrescriptionForUploadAsClinicUser);
  const prescribersForOrganisationEmployee = useSelector(selectPrescribersForOrganisationEmployee);
  const patientForClinicUser = useSelector(selectPatientForClinicUser);
  const selectedDoctor = useSelector(selectFromPrescriptionSliceByKey("selectedDoctorForClinicUser"));
  const loadingPatientForClinicUser = useSelector(selectFromPrescriptionSliceByKey("loadingPatientForClinicUser"));
  const uploadingPrescription = useSelector(selectFromPrescriptionSliceByKey("uploadingPrescriptionAsClinicUser"));
  const wasPatientFound = useSelector(selectFromPrescriptionSliceByKey("foundPatientForClinicUser"));
  const [currentStep, setCurrentStep] = useState(steps.SelectPrescriberAndPrescription);
  const [showBanners, setShowBanners] = useState(false);
  const [isFirstTimeSearch, setIsFirstTimeSearch] = useState(true);

  const prescriberSearchString = useProps("");
  const clinicPatientId = useProps("");
  const firstName = useProps(patientForClinicUser.firstName);
  const lastName = useProps(patientForClinicUser.lastName);
  const emailAddress = useProps(patientForClinicUser.email);
  const dob = useProps(patientForClinicUser.dateOfBirth);
  const contactNumber = useProps(patientForClinicUser.telephoneNo);
  const address1 = useProps(patientForClinicUser.address1);
  const address2 = useProps(patientForClinicUser.address2);
  const postalCode = useProps(patientForClinicUser.zipCode);
  const trackingNumber = useProps(patientForClinicUser.trackingNumber);
  const t21Patient = useProps(false);
  const passedMdt = useProps(false)

  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const userDetails = useSelector(selectUserDetails);

  const statusToSelectorMapper = {
    [OrganisationPrescriptionsTypes.ReWriteRequests]: OrganisationPrescriptionsTypes.ReWriteRequests,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: OrganisationPrescriptionsTypes.ToBeAssigned,
    [OrganisationPrescriptionsTypes.Active]: OrganisationPrescriptionsTypes.Active,
    [OrganisationPrescriptionsTypes.Complete]: OrganisationPrescriptionsTypes.Complete,
  }

  function setCurrentPrescriberAsDefault() {
    if (userDetails && prescribersForOrganisationEmployee.length) {
      const currentPrescriber = constructOptionsForPrescriberInput(prescribersForOrganisationEmployee)
        .find(e => e.value === userDetails.id);

      if (currentPrescriber) {
        const payload = {
          key: "selectedDoctorForClinicUser",
          data: currentPrescriber || {},
        };

        dispatch(setInStateByKey(payload));
        prescriberSearchString.setValue(currentPrescriber.label || "");
      }
    }
  }

  useEffect(() => {
    setCurrentPrescriberAsDefault();
  }, [userDetails, prescribersForOrganisationEmployee])

  const onFilesAccepted = (files) => {
    dispatch(setPrescriptionForUploadAsClinicUser(files))
  }

  const onPrescriberSelect = (e) => {
    const payload = {
      key: "selectedDoctorForClinicUser",
      data: e
    }

    dispatch(setInStateByKey(payload))
    prescriberSearchString.setValue(e.label)
  }

  const onPrescriberRemove = () => {
    const payload = {
      key: "selectedDoctorForClinicUser",
      data: {}
    }
    dispatch(setInStateByKey(payload))
    prescriberSearchString.setValue("")
  }

  const dispatchSuccessfullUpload = (error) => {
    const newToastState = {
      showToast: true,
      title: 'Prescription(s) uploaded!',
      message: 'The prescription is now with Lyphe Dispensary to process',
      type: toastTypes.Success
    }
    if (!error) {
      dispatch(setToast(newToastState));
    }
  }

  const dispatchSuccessfullProtoPrescriptionUpload = (error) => {
    const newToastState = {
      showToast: true,
      title: 'Prescription image(s) uploaded!',
      message: 'Uploaded to your organisation’s image only area',
      type: toastTypes.Success
    }
    if (!error) {
      dispatch(setToast(newToastState));
    }
  }

  const onNextStepOrUploadPrescription = async () => {
    if (currentStep === steps.SelectPrescriberAndPrescription) {
      if (currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber) {
        setCurrentStep(steps.PrescriberReviewPrescription);
        return;
      }
      if (selectedPrescriptions.length > 1) {
        setCurrentStep(steps.PrescriberReviewPrescription);
        return;
      }
      else {
        setCurrentStep(steps.SelectPatient);
      }
    }
    if (currentStep === steps.SelectPatient) {
      if (wasPatientFound) {

        if (patientForClinicUser.isTempPatient) {
          const { error } = await dispatch(uploadPrescriptionAsClinicUser({
            organisationId: currentOrganisation.organisationId,
            tempPatientId: patientForClinicUser.id,
            tempPatientEmail: patientForClinicUser.email.trim(),
            tempPatientTelephoneNo: patientForClinicUser.telephoneNo.trim(),
            isT21Patient: t21Patient.value,
            envelopeTrackingCode: trackingNumber.value,
          }))
          dispatchSuccessfullUpload(error)
        } else {
          const { error } = await dispatch(uploadPrescriptionAsClinicUser({
            organisationId: currentOrganisation.organisationId,
            patientId: patientForClinicUser.id,
            isT21Patient: t21Patient.value,
            envelopeTrackingCode: trackingNumber.value,
          }))
          dispatchSuccessfullUpload(error)
        }
      }
      if (!wasPatientFound) {
        const { error } = await dispatch(uploadPrescriptionAsClinicUser({
          organisationId: currentOrganisation.organisationId,
          clinicIdNumber: clinicPatientId.value.trim(),
          tempPatientId: patientForClinicUser.id,
          tempPatientEmail: emailAddress.value.trim(),
          tempPatientTelephoneNo: contactNumber.value.trim(),
          isT21Patient: t21Patient.value,
          envelopeTrackingCode: trackingNumber.value,
        }))
        dispatchSuccessfullUpload(error)
      }

      refreshPrescriptions();
    }

    if (currentStep === steps.PrescriberReviewPrescription) {
      await dispatchUploadProtoPrescriptionActionAndRefresh()
    }
  }

  const dispatchUploadProtoPrescriptionActionAndRefresh = async () => {
    const { error } = await dispatch(uploadProtoPrescriptionAsOrganisationEmployee({
      organisationId: currentOrganisation.organisationId,
      envelopeTrackingCode: trackingNumber.value,
    }))
    dispatchSuccessfullProtoPrescriptionUpload(error)

    refreshPrescriptions();
  }

  const refreshPrescriptions = () => {
    const getPrescriptionsPayload = {
      organisationId: currentOrganisation.organisationId,
      organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
        ? userDetails.id
        : null,
      status: callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly),
      pageParams: {
        currentPage: 1,
        pageSize: 25,
        sortColumnName: "",
        sortDirection: 1
      }
    }
    dispatch(getPrescriptionsForOrganisation(getPrescriptionsPayload))
    resetUploadPrescriptionState()
  }

  const onFindPatient = () => {
    dispatch(resetPatientForClinicUser());

    dispatch(getPatientByClinicId({ organisationId: currentOrganisation.organisationId, clinicPatientId: clinicPatientId.value }))
    setShowBanners(true);
    setIsFirstTimeSearch(false);
    emailAddress.reset();
    contactNumber.reset();
    t21Patient.reset();
    passedMdt.reset()
  }

  const onClear = () => {
    dispatch(resetPatientForClinicUser());
    dispatch(setInStateByKey({
      data: false,
      key: "foundPatientForClinicUser"
    }));
    clinicPatientId.reset();
    setShowBanners(false);
    setIsFirstTimeSearch(true);
  }

  const onBackOrCancel = () => {
    if (currentStep === steps.SelectPrescriberAndPrescription) {
      onModalClose()
    }
    if (currentStep === steps.SelectPatient) {
      setCurrentStep(steps.SelectPrescriberAndPrescription);
    }
    if (currentStep === steps.PrescriberReviewPrescription) {
      setCurrentStep(steps.SelectPrescriberAndPrescription);
    }
  }

  const onImageClear = () => {
    dispatch(setPrescriptionForUploadAsClinicUser(false));
  }

  const isNextEnabled = () => {
    if (currentStep === steps.SelectPrescriberAndPrescription) {
      return !!selectedDoctor.value && !!selectedPrescriptions
    }

    if (currentStep === steps.SelectPatient) {
      if (wasPatientFound) {
        if (patientForClinicUser?.isTempPatient) {
          return passedMdt.value && !!selectedPrescriptions
        }
        return !!selectedDoctor.value && !!selectedPrescriptions && !uploadingPrescription && patientForClinicUser?.id
      }
      if (!wasPatientFound) {

        return !!emailAddress.value && !!contactNumber.value && passedMdt.value && !!selectedPrescriptions
      }

      return false
    }

    if (currentStep === steps.PrescriberReviewPrescription) {
      return passedMdt.value && !!selectedPrescriptions
    }
  }

  const textContent = () => {
    return <>
      Drag and drop prescription images here <br /> or <span> select files</span>
    </>
  }

  const clearImageButton = () => {
    return <div className={"prescription_preview_container__buttons__clear_button"}
      onClick={() => { onImageClear() }}
    >
      <TrashBin />
    </div>
  }

  const renderRightContent = () => {
    return selectedPrescriptions ? <PrescriptionPreview buttonsTop={clearImageButton} src={selectedPrescriptions.length === 1 ? selectedPrescriptions[0].url : selectedPrescriptions} /> : <ReusableDropZone textContent={textContent} onFilesAccepted={onFilesAccepted} multiple={true} />
  }

  const renderT21Select = () => {
    return <>
      <CustomSelect
        name={"T21 Patient"}
        showInitialValueWithNoValue={false}
        {...t21Patient}
        hideLabel={true}
        options={[
          {
            text: "Yes",
            value: true,
          },
          {
            text: "No",
            value: false,
          },
        ]}
      />
    </>
  }

  const renderMdtPassedCheckBox = () => {
    return <Checkbox
      name={"I have reviewed the above and made sure to check the prescription(s)"}
      className={"m-t-s"}
      appendableClassNames={"w100"}
      isChecked={passedMdt.value}
      onChange={() => { passedMdt.setValue(!passedMdt.value) }}
      customStyle="custom_width"
    />
  }

  const renderPatientInfoInputs = () => {
    if (isFirstTimeSearch || loadingPatientForClinicUser) {
      return <></>
    }
    if (!patientForClinicUser.id) {
      return <>
        <div className={"text_input_title m-b-xs m-t-s"}>Is T21</div>
        {renderT21Select()}

        <div className={"text_input_title m-b-xs m-t-s"}>Email</div>
        <InputField
          className={""}
          name={"Email"}
          {...emailAddress}
        />
        <div className={"text_input_title m-b-xs m-t-s"}>Contact number</div>
        <InputField
          className={""}
          name={"Contact number"}
          {...contactNumber}
        />
        <div className={"text_input_title m-b-xs m-t-s"}>Tracking number</div>
        <InputField
          className={""}
          name={"Tracking number"}
          {...trackingNumber}
        />
        {renderMdtPassedCheckBox()}
      </>
    }
    if ((!wasPatientFound && !!patientForClinicUser.id)) {
      return <>
        <div className={"text_input_title m-b-xs"}>Is T21 patient</div>
        {renderT21Select()}

        <div className={"text_input_title m-b-xs"}>Email</div>
        <InputField
          className={""}
          name={"Email"}
          {...emailAddress}
        />
        <div className={"text_input_title m-b-xs "}>Contact number  </div>
        <InputField
          className={""}
          name={"Contact number"}
          {...contactNumber}
        />
        <div className={"text_input_title m-b-xs "}>Tracking number</div>
        <InputField
          className={""}
          name={"Tracking number"}
          {...trackingNumber}
        />
        {renderMdtPassedCheckBox()}
      </>
    }
    return <>
      {patientForClinicUser.isTempPatient ?
        <>
          <div className={"text_input_title m-t-s m-b-xs"}>Is T21 patient</div>
          {renderT21Select()}

          <div className={"text_input_title m-t-s m-b-xs"}>Email</div>
          <InputField
            isEnabled={false}
            className={""}
            name={"Email"}
            {...emailAddress}
          />
          <div className={"text_input_title m-t-s m-b-xs"}>Contact number</div>
          <InputField
            isEnabled={false}
            className={""}
            name={"Contact number"}
            {...contactNumber}
          />
          <div className={"text_input_title m-t-s m-b-xs"}>Tracking number</div>
          <InputField
            className={""}
            name={"Tracking number"}
            {...trackingNumber}
          />
          {renderMdtPassedCheckBox()}
        </>
        :
        <>
          <div className={"text_input_title m-b-xs m-t-s"}>T21 Patient</div>
          {renderT21Select()}

          <div className={"text_input_title m-b-xs m-t-s "}>Tracking number</div>
          <InputField
            className={""}
            name={"Tracking number"}
            {...trackingNumber}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>First name</div>
          <InputField
            isEnabled={false}
            appendableClassNames={"permanently_disabled"}
            name={"First name"}
            {...firstName}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Last name</div>
          <InputField
            isEnabled={false}
            name={"Last name"}
            appendableClassNames={"permanently_disabled"}
            {...lastName}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Email</div>
          <InputField
            isEnabled={false}
            name={"Email"}
            appendableClassNames={"permanently_disabled"}
            {...emailAddress}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Date of birth</div>
          <InputField
            isEnabled={false}
            name={"Date of birth"}
            appendableClassNames={"permanently_disabled"}
            {...dob}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Contact number</div>
          <InputField
            isEnabled={false}
            name={"Contact number"}
            appendableClassNames={"permanently_disabled"}
            {...contactNumber}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Address 1</div>
          <InputField
            isEnabled={false}
            name={"Address 1"}
            appendableClassNames={"permanently_disabled"}
            {...address1}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Address 2</div>
          <InputField
            isEnabled={false}
            name={"Address 2"}
            appendableClassNames={"permanently_disabled"}
            {...address2}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Post code</div>
          <InputField
            isEnabled={false}
            className={"m-b-s"}
            name={"Post code"}
            appendableClassNames={"permanently_disabled"}
            {...postalCode}
          />
        </>
      }
    </>
  }

  const renderOrComponent = () => {
    return <div className='upload_prescription_modal_clinics__content__or-component'>
      <div className='upload_prescription_modal_clinics__content__or-component__line'></div>
      <p>OR</p>
      <div className='upload_prescription_modal_clinics__content__or-component__line'></div>
    </div>
  }

  const renderQuickUploadComponent = () => {
    return <div className='upload_prescription_modal_clinics__content__quick-upload'>
      <p className='upload_prescription_modal_clinics__content__quick-upload__title'>Quick upload</p>
      <p className='upload_prescription_modal_clinics__content__quick-upload__content'>
        Upload just the prescription image to the Unprocessed area now and add patient details later
      </p>

      <PrimaryCta
        className='upload_prescription_modal_clinics__content__quick-upload__cta'
        onClick={dispatchUploadProtoPrescriptionActionAndRefresh}
        color={"white"}
      >
        {uploadingPrescription ? <Spinner width={20} /> :
          <>
            <UploadIcon />
            <span>Upload image only</span>
          </>}
      </PrimaryCta>
    </div>
  }

  const renderPatientFoundNotFoundBanners = () => {
    if (!showBanners || loadingPatientForClinicUser) {
      return null;
    }
    return wasPatientFound ?
      patientForClinicUser.isTempPatient ?
        <GenericBanner
          className={"m-t-s generic_banner--blue generic_banner--fontWeight-400"}
          icon={<MoreInfoIcon size="17" color={"#3B5EDB"} />}
          title="Account not created yet. "
        >
          You have already provided the basic details with a previous script.
        </GenericBanner>
        :
        <GenericBanner
          className={"m-t-s generic_banner--green generic_banner--fontWeight-400"}
          icon={<TickIconSuccess size="17" color={"#51C378"} />}
          title="Patient account exists!"
        >
          Please review their details and T21 status before uploading the prescription.
        </GenericBanner>
      :
      <GenericBanner
        className={"m-t-s generic_banner--blue generic_banner--fontWeight-400"}
        icon={<MoreInfoIcon size="17" color={"#3B5EDB"} />}
        title="No account found "
      >
        Please provide the below details so we can create an account for this patient.
      </GenericBanner>
  }

  const renderContentForTheNextUploadButton = () => {
    if (currentStep === steps.SelectPrescriberAndPrescription) {
      return "Next"
    }
    if (currentStep === steps.SelectPatient || currentStep === steps.PrescriberReviewPrescription) {
      return uploadingPrescription ? <Spinner width={20} /> : <> <UploadIcon color="white" /> &nbsp; Upload</>
    }
    if (currentStep === steps.ConfirmUpload) {
      return "Confirm"
    }

  }

  const renderPrescriptionCountBanner = () => {
    return <>
      {selectedPrescriptions && selectedPrescriptions.length === 1 &&
        <div style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
          <GenericBanner
            className={"m-t-s generic_banner--green generic_banner--fontWeight-400"}
            icon={<TickIconSuccess size="17" color={"#51C378"} />}
            title={`${selectedPrescriptions.length} prescription added`}
            text={"Check your prescription image is correct before continuing"}
          />
        </div>
      }
      {selectedPrescriptions && selectedPrescriptions.length > 1 &&
        <div style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
          <GenericBanner
            className={"m-t-s generic_banner--green generic_banner--fontWeight-400"}
            icon={<TickIconSuccess size="17" color={"#51C378"} />}
            title={`${selectedPrescriptions.length} prescription added`}
            text={"You can review the prescriptions using the arrows at the top before continuing"}
          />
        </div>
      }
    </>
  }

  const resetUploadPrescriptionState = () => {
    setCurrentStep(steps.SelectPrescriberAndPrescription)
    dispatch(resetPatientForClinicUser());
    clinicPatientId.reset();
    passedMdt.reset();
    trackingNumber.reset();
    setShowBanners(false);
    setIsFirstTimeSearch(true);
    dispatch(setPrescriptionForUploadAsClinicUser(null))
    dispatch(setInStateByKey({ key: "foundPatientForClinicUser", data: false }))

    setCurrentPrescriberAsDefault();
  }

  const onModalClose = () => {
    onClose()
    resetUploadPrescriptionState()
  }


  useEffect(() => {
    if (currentOrganisation.organisationId > 0)
      dispatch(getPrescribersForOrganisationEmployee(currentOrganisation.organisationId))
    // eslint-disable-next-line
  }, [currentOrganisation])

  return (
    <FullScreenOverlay show={show}>
      <div className="upload_prescription_modal_clinics">
        {currentStep === steps.ConfirmUpload &&
          <div className="upload_prescription_modal_clinics__header">
            Confirm upload  <span className="upload_prescription_modal_clinics__header__close" onClick={() => { onModalClose() }}><CloseIcon /> </span>
          </div>
        }

        {currentStep !== steps.ConfirmUpload &&
          <div className="upload_prescription_modal_clinics__header">
            Upload prescription  <span className="upload_prescription_modal_clinics__header__close" onClick={() => { onModalClose() }}><CloseIcon /> </span>
          </div>
        }

        {currentStep !== steps.ConfirmUpload &&
          <div className="upload_prescription_modal_clinics__content">
            <div className="upload_prescription_modal_clinics__content__left">
              {currentOrganisation.employeeType !== EmployeeType.OrganisationPrescriber &&
                <div className={"upload_prescription_modal_clinics__content__step_title"}>
                  1. Select prescriber <span
                    className={"upload_prescription_modal_clinics__content__step_title__prescriber_name"}>{selectedDoctor.label}</span>
                </div>
              }
              {currentStep === steps.SelectPrescriberAndPrescription &&
                currentOrganisation.employeeType !== EmployeeType.OrganisationPrescriber &&
                <>
                  <div className={"upload_prescription_modal_clinics__content__step_explanation m-t-s"}>
                    Add single or bulk prescription images here and select the clinician that prescribed them.
                  </div>

                  <AutoComplete
                    filter
                    name={"Search prescriber"}
                    onClick={(e) => {
                      onPrescriberSelect(e);
                    }}
                    onRemove={(e) => {
                      onPrescriberRemove(e);
                    }}
                    options={constructOptionsForPrescriberInput(prescribersForOrganisationEmployee)}
                    {...prescriberSearchString}
                    className={"m-t-s"}
                  />
                  {renderPrescriptionCountBanner()}
                </>
              }

              {currentStep === steps.SelectPrescriberAndPrescription &&
                currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber &&
                <>
                  <div className={"upload_prescription_modal_clinics__content__step_explanation m-t-s"}>
                    Add single or bulk prescription images here.
                  </div>
                  <div className="side_preview_content__informations__section_title">Prescriber</div>
                  <AutoComplete
                    isEditable={false}
                    name={"Prescriber"}
                    value={userDetails && constructOptionsForPrescriberInput(prescribersForOrganisationEmployee)
                      .find(e => e.value === userDetails.id)
                    }
                    {...prescriberSearchString}
                    className={"m-t-s"}
                  />
                  {renderPrescriptionCountBanner()}
                </>
              }

              {currentStep === steps.SelectPatient &&
                <>
                  <div className={"upload_prescription_modal_clinics__content__step_title m-t-s"}>
                    2. Select patient &nbsp;
                    <span
                      className={"upload_prescription_modal_clinics__content__step_title__prescriber_name"}>
                      {`${patientForClinicUser.firstName || ""} ${patientForClinicUser.lastName || ""}`}
                    </span>
                  </div>
                  <div className={"upload_prescription_modal_clinics__content__step_explanation m-t-s"}>
                    Enter the ID of the patient. If Lyphe Dispensary has not created an account for them yet,
                    please provide the details required so we can create one for them.
                  </div>
                  <div className={"text_input_title m-t-s m-b-xs "}>Clinic Patient ID</div>
                  <InputField
                    type="number"
                    name={"Clinic number"}
                    {...clinicPatientId}
                  />

                  {clinicPatientId.value &&
                    <div className={"m-t-s "} style={{ display: "flex", justifyContent: "flex-start" }}>
                      <PrimaryCta
                        isEnabled={true}
                        color={"dark_blue"}
                        className="m-r-s primary_cta--fit_content primary_cta--squared"
                        onClick={() => {
                          onFindPatient();
                        }}
                      >
                        {loadingPatientForClinicUser ? <Spinner width={20} /> : "Find"}
                      </PrimaryCta>

                      <PrimaryCta color={"white"} onClick={() => {
                        onClear();
                      }} className={"primary_cta--fit_content primary_cta--squared"} text={"Clear"} />
                    </div>
                  }

                  {renderPatientFoundNotFoundBanners()}
                  {renderPatientInfoInputs()}

                  {isFirstTimeSearch && renderOrComponent()}
                  {isFirstTimeSearch && renderQuickUploadComponent()}
                </>
              }

              {currentStep === steps.PrescriberReviewPrescription &&
                <>
                  <div className={"upload_prescription_modal_clinics__content__step_title m-t-s"}>
                    2. Review prescription(s) &nbsp;
                  </div>
                  <div className={"upload_prescription_modal_clinics__content__step_explanation m-t-s"}>
                    <span>UPLOADING TO</span> &nbsp;
                    <p className={"upload_prescription_modal_clinics__content__step_explanation__heading "}>
                      {currentOrganisation.organisationName}
                    </p>
                  </div>
                  <div className={"upload_prescription_modal_clinics__content__step_contents m-t-s"}>
                    <p>Review the prescription images added before uploading.</p>

                    <p>Please make sure:</p>
                    <p>
                      <ul>
                        <li>
                          All prescriptions have clear lighting and are not blurry
                        </li>
                        <li>
                          The prescriptions are in full and no parts are cut off
                        </li>
                        <li>
                          Prescribed date has been added
                        </li>
                        <li>
                          All relevant patient details are included
                        </li>
                        <li>
                          Each medication includes strength, dosage and frequency where relevant
                        </li>
                      </ul>
                    </p>
                  </div>

                  <div className={"text_input_title m-b-xs m-t-s"}>Tracking number</div>
                  <InputField
                    className={""}
                    name={"Tracking number"}
                    {...trackingNumber}
                  />
                  {renderMdtPassedCheckBox()}

                </>
              }

            </div>
            <div className="upload_prescription_modal_clinics__content__right">
              {renderRightContent()}
            </div>
          </div>
        }
        {currentStep === steps.ConfirmUpload &&
          <div className="upload_prescription_modal_clinics__confirm-content">
            <p> className="upload_prescription_modal_clinics__confirm-content__text"
              You are about to upload your prescription images to:
            </p>
            <p className="upload_prescription_modal_clinics__confirm-content__heading">
              {currentOrganisation.organisationName}
            </p>
            <p className="upload_prescription_modal_clinics__confirm-content">
              Please confirm that this is correct
            </p>
          </div>
        }
        <div className="upload_prescription_modal_clinics__footer">
          <div className="upload_prescription_modal_clinics__footer__buttons" >
            <PrimaryCta
              onClick={() => { onBackOrCancel() }}
              color={"white"}
              text={currentStep === steps.SelectPrescriberAndPrescription ? "Cancel" : "Back"}
            />
            <PrimaryCta
              isEnabled={isNextEnabled()}
              color={"dark_blue"}
              className="m-l-s"
              onClick={() => {
                onNextStepOrUploadPrescription()
              }}
            >
              {renderContentForTheNextUploadButton()}
            </PrimaryCta>
          </div>
        </div>
      </div>
    </FullScreenOverlay>
  );
}

export default UploadPrescriptionOrganisationModal;

const steps = {
  SelectPrescriberAndPrescription: 1,
  SelectPatient: 2,
  PrescriberReviewPrescription: 3,
  ConfirmUpload: 4
}

const constructOptionsForPrescriberInput = (prescribers) => {
  return prescribers.map((prescriber) => {
    return {
      label: `${prescriber.organisationEmployee.firstName} ${prescriber.organisationEmployee.lastName}`,
      value: prescriber.organisationEmployee.id,
    }
  })
}
