import React, { useEffect } from "react";
import { PrimaryCta } from "../../../components/buttons";

import { useSelector, useDispatch } from "react-redux";
import { useAgeSelect } from "../../../hooks";
import {
  setCarerDateOfBirth,
  selectCarer,
} from "../../../store/slices/carerOnboardingSlice";
import { AgeSelect } from "../../../components/inputs";
import { trackAnalyticsEvent } from "../../../util/util";

function CarerIsAgeCheck({ setNextStep, steps }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { dateOfBirth: dob } = useSelector(selectCarer);

  const [ageConfig, values, ageErrorMessage] = useAgeSelect(
    dob?.day,
    dob?.month,
    dob?.year
  );

  const { day, month, year } = values;

  const isButtonEnabled = day && month && year && !ageErrorMessage;

  function confirmClicked() {
    dispatch(setCarerDateOfBirth({ year, month, day }));

    trackAnalyticsEvent("Carer Onboarding", "Date Of Birth Added for Carer");
    setNextStep(steps.carerRelationShipToPatient);
  }

  return (
    <section className="is_over_18">
      <div className="is_over_18__content">
        <div className="is_over_18__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please confirm your age</h1>
          <p className="p5">
            For legal reasons, please confirm that you are over the age of 18
          </p>
        </div>

        <div className="is_over_18__form">
          <AgeSelect {...ageConfig} error={ageErrorMessage} />
        </div>

        <div className="is_over_18__button">
          <PrimaryCta
            onClick={confirmClicked}
            isEnabled={isButtonEnabled}
            color="orange"
            text="Confirm"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerIsAgeCheck;
