import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ClinicPatientTypes } from "../../enums";
import { patientService } from "../../services/PatientService";

export const getPatientsForClinic = createAsyncThunk(
  "patients/getPatientsForClinic",

  async (payload) => {
    const patients = await patientService.getPatientsForClinic(payload.organisationId, payload.status);
    return {
      status: payload.status,
      patients: patients
    };
  }
);



const initialState = {
  clinicPatients: {
    [ClinicPatientTypes.Basic]: [],
    [ClinicPatientTypes.Full]: [],
  },
  clinicPatientsLoadingFlags: {
    [ClinicPatientTypes.Basic]: false,
    [ClinicPatientTypes.Full]: false,
  },
};

const patientOnboardingSlice = createSlice({
  name: "patients",

  initialState,

  // sync actions
  reducers: {
    setInStateByKey(state, { payload }) {
      state[payload.key] = payload.data;
    },

  },

  // async actions
  extraReducers: {
    //getPatientsForClinic

    [getPatientsForClinic.pending]: (state, { meta: { arg: status } }) => {
      state.clinicPatientsLoadingFlags[status] = true;
    },
    [getPatientsForClinic.fulfilled]: (state, { payload }) => {
      const { status, patients } = payload;
      state.clinicPatients[status] = patients;
      state.clinicPatientsLoadingFlags[status] = false;
    },
    [getPatientsForClinic.rejected]: (state, { meta: { arg: status } }) => {
      state.clinicPatientsLoadingFlags[status] = false;
    },

  },
});

export default patientOnboardingSlice.reducer;

// export sync actions
export const {
  setInStateByKey
} = patientOnboardingSlice.actions;

// selectors

export const selectClinicsPatientsLoadingFlag = (key) => (state) =>
  state.patients.clinicPatientsLoadingFlags[key];

export const selectClinicPatientsFromPatientSliceByKey = (key) => (state) =>
  state.patients.clinicPatients[key];


