import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { toastTypes } from "../../../enums";
import { setToast } from "../../../store/slices/toastSlice";

const defaultTextContent = () => {
  return <>
    Drag and drop prescription images here <br /> or <span> select files</span>
  </>
}

function ReusableDropZone(
  { onFilesAccepted = () => null,
    className = "",
    multiple = false,
    textContent = defaultTextContent,
    showAcceptedMessage = false,
    unsoportedFileMessage = {
      title: " Unsupported format",
      message: "Please use an image file",
    }
  }) {

  let { acceptedFiles, getRootProps, getInputProps, fileRejections, } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, application/pdf'
  });

  const dispatch = useDispatch()

  useEffect(() => {
    if (acceptedFiles.length) {
      let prescriptionImages = acceptedFiles.map(file => {
        return (
          {
            name: file.name,
            url: URL.createObjectURL(file),
          }
        )
      })

      onFilesAccepted(prescriptionImages, acceptedFiles)

      const newToastState = {
        showToast: true,
        title: 'Files added',
        message: 'File accepted',
        type: toastTypes.Success
      }
      if (showAcceptedMessage) {
        dispatch(setToast(newToastState));
      }
    }

    if (fileRejections.length) {
      const newToastState = {
        showToast: true,
        ...unsoportedFileMessage,
        type: toastTypes.Error
      }

      dispatch(setToast(newToastState));
    }

    // eslint-disable-next-line
  }, [acceptedFiles, fileRejections])

  const resolveClass = () => {
    let className = 'reusable_drop_zone__zone '
    if (acceptedFiles.length) {
      className += 'reusable_drop_zone__zone--files_selected '
    }

    return className;
  }

  return (
    <div className={`reusable_drop_zone ${className}`}>
      <div {...getRootProps({ className: resolveClass() })}>
        <input {...getInputProps()} multiple={multiple} />
        {(acceptedFiles.length === 0 || fileRejections.length) > 0 &&
          <UploadIcon
            color="#D5D5D5"
          />
        }
        <div className='reusable_drop_zone__zone__text' >
          {textContent()}
        </div>
      </div>
    </div>
  );
}

export default ReusableDropZone;
