import React from 'react'

const EyeIconSlashed = () => {
    return (
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.18 18.385L14.824 15.029C13.376 15.689 11.801 16.02 10.21 16.002C8.56996 16.022 6.94696 15.668 5.46396 14.967C4.31463 14.4062 3.28258 13.6318 2.42296 12.685C1.51043 11.7068 0.793236 10.5633 0.309961 9.31595L0.209961 9.00195L0.314961 8.68595C1.03777 6.84224 2.25422 5.23312 3.83096 4.03495L1.20996 1.41395L2.62296 0.00195312L19.592 16.971L18.182 18.385H18.18ZM5.24596 5.45095C3.96789 6.34687 2.95861 7.57466 2.32696 9.00195C2.98888 10.5291 4.09426 11.8223 5.49982 12.7138C6.90538 13.6053 8.54632 14.0541 10.21 14.002C11.2598 14.0106 12.3035 13.8415 13.297 13.502L11.497 11.702C11.097 11.898 10.657 12.001 10.21 12.002C9.41585 11.997 8.65568 11.6793 8.09415 11.1178C7.53261 10.5562 7.21494 9.79606 7.20996 9.00195C7.20996 8.55495 7.31296 8.11395 7.50996 7.71195L5.24596 5.45095ZM18.062 12.612L16.67 11.221C17.2556 10.5589 17.7356 9.8104 18.093 9.00195C17.4319 7.47411 16.3268 6.18035 14.921 5.2887C13.5152 4.39705 11.8738 3.94872 10.21 4.00195C9.96296 4.00195 9.71496 4.01095 9.47496 4.02795L7.70996 2.26095C8.53196 2.08495 9.36996 1.99795 10.21 2.00195C11.85 1.98195 13.473 2.33595 14.956 3.03695C16.106 3.59695 17.137 4.37195 17.997 5.31895C18.909 6.29592 19.6262 7.43803 20.11 8.68395L20.21 9.00195L20.105 9.31795C19.6368 10.536 18.9441 11.6554 18.063 12.618L18.062 12.612Z" fill="#787878" />
        </svg>
    )
}

export default EyeIconSlashed
