import React from "react";

function EyeIcon(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.21 12C13.115 12 12.21 11.095 12.21 10C12.21 9.646 12.313 9.317 12.478 9.027C12.388 9.02 12.302 9 12.21 9C11.4159 9.00524 10.6559 9.32299 10.0944 9.88447C9.53296 10.4459 9.2152 11.206 9.20996 12C9.20996 13.642 10.568 15 12.21 15C13.851 15 15.21 13.642 15.21 12C15.21 11.908 15.19 11.822 15.183 11.732C14.893 11.897 14.564 12 14.21 12Z" fill="#787878" />
      <path d="M12.2098 5C4.57676 5 2.28276 11.617 2.26176 11.684L2.15576 12L2.26076 12.316C2.28276 12.383 4.57676 19 12.2098 19C19.8428 19 22.1368 12.383 22.1578 12.316L22.2638 12L22.1588 11.684C22.1368 11.617 19.8428 5 12.2098 5ZM12.2098 17C6.85876 17 4.78576 13.154 4.28376 12C4.78776 10.842 6.86176 7 12.2098 7C17.5608 7 19.6338 10.846 20.1358 12C19.6318 13.158 17.5578 17 12.2098 17V17Z" fill="#787878" />
    </svg>
  );
}

export default EyeIcon;
