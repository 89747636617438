import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountService } from "../../services/AccountServices";
import { onboardingService } from "../../services/OnboardingServices";
import { setUserFromToken } from "./authenticationSlice";

export const registerDoctor = createAsyncThunk(
  "doctorOnboarding/registerDoctor",

  async (payload, { getState, dispatch }) => {
    const { doctor } = getState().doctorOnboarding;

    const response = await onboardingService.registerDoctor(doctor);

    await dispatch(setUserFromToken({ token: response.token }));

    return response;
  }
);

export const createPatient = createAsyncThunk(
  "doctorOnboarding/createPatient",

  async (payload, { getState }) => {
    const state = getState().doctorOnboarding;

    const response = await onboardingService.createPatient(
      state.patient,
      state.doctorId
    );

    return response;
  }
);

export const uploadPrescriptionDoctor = createAsyncThunk(
  "doctorOnboarding/uploadPrescription",

  async (payload, { getState }) => {
    const state = getState().doctorOnboarding;

    const response = await accountService.uploadPrescription(
      state.patientId,
      state.prescription,
      state.doctorId,
      state.isT21Patient
    );

    return response;
  }
);

export const checkGmc = createAsyncThunk(
  "doctorOnboarding/checkGmc",

  async (gmcNumber) => {
    try {
      const { data } = await onboardingService.checkGmc(gmcNumber);

      return data;
    } catch (error) {
      throw Error(error.response.data.message);
    }
  }
);

export const checkCqc = createAsyncThunk(
  "doctorOnboarding/checkCqc",

  async (clinicName) => {
    const { data } = await onboardingService.checkCqc(clinicName);

    return data;
  }
);

const initialState = {
  doctor: {},
  patient: {},
  prescription: {},

  prescriptions: [],
  trackingNumber: '',
  confirmedConsent: false,
  unsoportedFileFormatError: false,

  tokenForUploading: undefined,
  patientId: undefined,
  doctorId: undefined,

  registeringDoctor: false,
  uploadingPrescription: false,
  loading: false,
};

const doctorOnboardingSlice = createSlice({
  name: "doctorOnboarding",
  initialState,

  // reducers for async actions
  extraReducers: {
    // registerDoctor
    [registerDoctor.pending]: (state) => {
      state.registeringDoctor = true;
    },

    [registerDoctor.fulfilled]: (state, { payload }) => {
      state.registeringDoctor = false;
      state.tokenForUploading = payload.token;
      state.doctorId = payload.id;

      // reset doctor
      state.doctor = {};
    },

    [registerDoctor.rejected]: (state) => {
      state.registeringDoctor = false;
    },

    // createPatient
    [createPatient.pending]: (state) => {
      state.registeringDoctor = true;
    },

    [createPatient.fulfilled]: (state, { payload }) => {
      state.registeringDoctor = false;
      state.patientId = payload.patientId;

      // reset
      state.patient = {};
    },

    [createPatient.rejected]: (state) => {
      state.registeringDoctor = false;
    },

    // uploadPrescriptionDoctor
    [uploadPrescriptionDoctor.pending]: (state) => {
      state.uploadingPrescription = true;
    },

    [uploadPrescriptionDoctor.fulfilled]: (state) => {
      state.patientId = undefined;
      state.doctorId = undefined;
      state.tokenForUploading = undefined;
      state.prescription = {};
      state.uploadingPrescription = false;
    },

    [uploadPrescriptionDoctor.rejected]: (state) => {
      state.uploadingPrescription = false;
    },

    // checkGmc
    [checkGmc.pending]: (state) => {
      state.loading = true;
    },

    [checkGmc.fulfilled]: (state) => {
      state.loading = false;
    },

    [checkGmc.rejected]: (state) => {
      state.loading = false;
    },

    // checkCqc
    [checkCqc.pending]: (state) => {
      state.loading = true;
    },

    [checkCqc.fulfilled]: (state) => {
      state.loading = false;
    },

    [checkCqc.rejected]: (state) => {
      state.loading = false;
    },
  },

  reducers: {
    setDoctorAndToken(state, { payload }) {
      state.tokenForUploading = payload.token;
      state.doctorId = payload.doctorId;
    },

    setPatientId(state, { payload }) {
      state.patientId = payload;
    },

    setDoctor(state, { payload }) {
      state.doctor = payload;
    },

    setDoctorName(state, { payload }) {
      state.doctor.firstName = payload.firstName;
      state.doctor.lastName = payload.lastName;
      state.doctor.gmc = payload.gmc;
    },

    setSpecializations(state, { payload }) {
      state.doctor.specializations = payload;
    },

    setContactDetails(state, { payload }) {
      state.doctor.clinicName = payload.clinicName;
      state.doctor.clinicAddress1 = payload.clinicAddress1;
      state.doctor.clinicAddress2 = payload.clinicAddress2;
      state.doctor.clinicZIPCode = payload.clinicZIPCode;
      state.doctor.clinicContactNumber = payload.clinicContactNumber;
    },

    setCredentials(state, { payload }) {
      state.doctor.email = payload.email;
      state.doctor.password = payload.password;
      state.doctor.agreedToTerms = payload.agreedToTerms;
    },

    setIsPrescriptionUploaded(state, { payload }) {
      state.doctor.isPrescriptionUploaded = payload;
    },

    setPatient(state, { payload }) {
      state.patient = payload;
    },

    setIsT21Patient(state, { payload }) {
      state.isT21Patient = payload;
    },

    setPatientsName(state, { payload }) {
      state.patient.title = payload.title;
      state.patient.firstName = payload.firstName;
      state.patient.lastName = payload.lastName;
    },

    setPatientContactDetails(state, { payload }) {
      state.patient.email = payload.email;
      state.patient.contactNumber = payload.contactNumber;
    },

    setPatientsPrescription(state, { payload }) {
      state.prescription.name = payload.name;
      state.prescription.url = payload.url;
      state.prescription.confirmedConsent = true;
    },

    resetPatient(state) {
      state.patient = {};
      state.doctorId = undefined;
    },

    resetPrescription(state) {
      state.tokenForUploading = undefined;
      state.prescription = {};
    },

    resetDoctor(state) {
      state.doctor = {};
    },

    setPrescriptions(state, { payload }) {
      state.prescriptions = payload;
    },

    setTrackingNumber(state, { payload }) {
      state.trackingNumber = payload;
    },

    setConfirmedConsent(state, { payload }) {
      state.confirmedConsent = payload;
    },

    setUnsoportedFileFormatError(state, { payload }) {
      state.unsoportedFileFormatError = payload;
    }
  },
});

// export sync actions
export const {
  setDoctorAndToken,
  setDoctorName,
  setSpecializations,
  setContactDetails,
  setIsT21Patient,
  setPatientsName,
  setPatientContactDetails,
  setPatientsPrescription,
  resetDoctor,
  setDoctor,
  setCredentials,
  setPatient,
  setPatientId,
  resetPatient,
  resetPrescription,
  setIsPrescriptionUploaded,
  setPrescriptions,
  setTrackingNumber,
  setConfirmedConsent,
  setUnsoportedFileFormatError
} = doctorOnboardingSlice.actions;

export default doctorOnboardingSlice.reducer;

export const selectDoctor = (state) => state.doctorOnboarding.doctor;

export const selectDoctorsPatient = (state) => state.doctorOnboarding.patient;

export const selectPrescription = (state) =>
  state.doctorOnboarding.prescription;

export const selectRegisteringDoctor = (state) =>
  state.doctorOnboarding.registeringDoctor;

export const selectUploadingPrescription = (state) =>
  state.doctorOnboarding.uploadingPrescription;

export const selectPatientId = (state) => state.doctorOnboarding.patientId;

export const selectDoctorLoading = (state) => state.doctorOnboarding.loading;

export const selectIsT21Patient = (state) =>
  state.doctorOnboarding.isT21Patient;

export const selectPrescriptions = (state) =>
  state.doctorOnboarding.prescriptions;

export const selectTrackingNumber = (state) =>
  state.doctorOnboarding.trackingNumber;

export const selectConfirmedConsent = (state) =>
  state.doctorOnboarding.confirmedConsent;

export const selectUnsoportedFormatFileError = (state) =>
  state.doctorOnboarding.unsoportedFileFormatError;