import React from "react";

function RepeatPrescriptionIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.734 14.0596C16.888 15.7701 15.5158 17.1644 13.819 18.0376C12.1344 18.9026 10.2136 19.1947 8.348 18.8696C6.47659 18.5406 4.76206 17.6144 3.461 16.2296C2.14787 14.8318 1.31181 13.0536 1.073 11.1506C0.830452 9.24334 1.19579 7.30817 2.117 5.62061C3.02975 3.94598 4.45609 2.60867 6.186 1.80561C7.90431 1.00978 9.83536 0.796306 11.686 1.19761C13.536 1.59861 15.052 2.51061 16.306 3.95261C16.457 4.11261 17.041 4.75861 17.526 5.73361" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.0703 5.81284L18.1103 6.71984L19.0013 1.58984" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default RepeatPrescriptionIcon;
