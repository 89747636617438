import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FilterIcon,
} from "../../assets/icons";
import { OrganisationPrescriptionsTypes, clinicFilterTypes, OrderStatuses } from "../../enums";
import { callMapper } from "../../util/util";
import {
  selectClinicsPrescriptions, selectFromPrescriptionSliceByKey,
  selectClinicFilterOptions, selectClinicFilterNumberOfChecked,
  setClinicFilterOptions, setClinicFilterNumberOfChecked, setClinicsFilteredPrescriptions
} from "../../store/slices/prescriptionsSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function PrescriptionsFilter(props) {
  const params = useParams();
  const { status } = params.status ? params : { status: OrganisationPrescriptionsTypes.ImageOnly };
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  const statusToSelectorMapper = {
    [OrganisationPrescriptionsTypes.ReWriteRequests]: OrganisationPrescriptionsTypes.ReWriteRequests,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: OrganisationPrescriptionsTypes.ToBeAssigned,
    [OrganisationPrescriptionsTypes.Active]: OrganisationPrescriptionsTypes.Active,
    [OrganisationPrescriptionsTypes.Complete]: OrganisationPrescriptionsTypes.Complete,
  }

  const clinicsPrescriptions = useSelector(selectClinicsPrescriptions(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));

  const clinicFilterOptions = useSelector(selectClinicFilterOptions(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));
  const clinicFilterNumberOfChecked = useSelector(selectClinicFilterNumberOfChecked(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));

  const clinicSearchSelectedValue = useSelector(selectFromPrescriptionSliceByKey("clinicSearchSelectedValue"));

  const [isPageFilterEligible, setIsPageFilterEligible] = useState(false);

  const location = useLocation();

  //populate filter options
  useEffect(() => {
    if (clinicFilterNumberOfChecked == 0) {
      callMapper(statusToFilterOptionsMapper, status, () => null)()
    }
  }, [status, clinicsPrescriptions])

  //reset filters on tab change or search change
  useEffect(() => {
    dispatch(setClinicFilterNumberOfChecked({ value: 0, status: status }));
  }, [status, clinicSearchSelectedValue])

  //apply filter if number of currently checked filers > 0
  useEffect(() => {
    if (clinicFilterNumberOfChecked > 0) {
      filterPrescriptions();
    }
  }, [clinicFilterNumberOfChecked, clinicsPrescriptions])

  useEffect(() => {
    if (location.pathname.includes("/toBeAssigned"))
      setIsPageFilterEligible(true);
    else if (location.pathname.includes("/active"))
      setIsPageFilterEligible(true);
    else if (location.pathname.includes("/complete"))
      setIsPageFilterEligible(true);
    else if (location.pathname.includes("/reWriteRequests"))
      setIsPageFilterEligible(true);
    else
      setIsPageFilterEligible(false);
  }, [location])

  const statusToFilterOptionsMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: () => {
      dispatch(setClinicFilterOptions({ status: status, filters: createFilterOptionsForImageOnly() }));
    },
    [OrganisationPrescriptionsTypes.ToBeAssigned]: () => {
      dispatch(setClinicFilterOptions({ status: status, filters: createFilterOptionsForToBeAssigned() }));
    },
    [OrganisationPrescriptionsTypes.Active]: () => {
      dispatch(setClinicFilterOptions({ status: status, filters: createFilterOptionsForActive() }));
    },
    [OrganisationPrescriptionsTypes.Complete]: () => {
      dispatch(setClinicFilterOptions({ status: status, filters: createFilterOptionsForCompleted() }));
    },
    [OrganisationPrescriptionsTypes.ReWriteRequests]: () => {
      dispatch(setClinicFilterOptions({ status: status, filters: createFilterOptionsForReWriteRequests() }));
    },
    undefined: () => { dispatch(setClinicFilterOptions({ status: OrganisationPrescriptionsTypes.ToBeAssigned, filters: createFilterOptionsForToBeAssigned() })); }
  }

  const filterPrescriptions = () => {
    let filters = getSelectedFilters();
    let filteredPresc = clinicsPrescriptions;
    //for filtering, we iterate over all the filters, checking filter type each time, and reduce the resulting array each time according to the type of filter

    filters.forEach((filter) => {
      if (filter.values.length != 0) {
        switch (filter.type) {

          case clinicFilterTypes.doctor:
            filteredPresc = filteredPresc.reduce((acc, curr) => {

              let isNodeSatisfied = false;
              //quick fix - null check for situations where the doctor is somehow missing from an active prescription
              if (curr.organisationEmployee == null) {
                isNodeSatisfied = false;
              } else {
                isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(curr.organisationEmployee.firstName + ' ' + curr.organisationEmployee.lastName) > -1);
              }
              if (isNodeSatisfied) {
                acc.push(curr)
              }

              return acc;
            }, []);
            break;

          case clinicFilterTypes.status:
            filteredPresc = filteredPresc.reduce((acc, curr) => {

              let isNodeSatisfied = false;
              isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(callMapper(statusToTextMapper, curr.status)) > -1);

              if (isNodeSatisfied) {
                acc.push(curr)
              }

              return acc;
            }, []);
            break;

          case clinicFilterTypes.t21:
            filteredPresc = filteredPresc.reduce((acc, curr) => {

              let isNodeSatisfied = false;
              isNodeSatisfied = isNodeSatisfied || (filter.values.indexOf(curr.isT21Patient) > -1);

              if (isNodeSatisfied) {
                acc.push(curr)
              }

              return acc;
            }, []);
            break;

          case clinicFilterTypes.rewriteReason:
            filteredPresc = filteredPresc.reduce((acc, curr) => {

              let isNodeSatisfied = false;
              if (curr.rewriteReason == null) {
                isNodeSatisfied = false;
              } else {
                isNodeSatisfied = isNodeSatisfied || (filter.values.some(v => curr.rewriteReason.includes(v)));
              }

              if (isNodeSatisfied) {
                acc.push(curr)
              }

              return acc;
            }, []);
            break;
        }
      }
    })

    dispatch(setClinicsFilteredPrescriptions({ status: status, value: filteredPresc }))
  }

  const getSelectedFilters = () => {
    let filters = [
      { type: clinicFilterTypes.doctor, values: [] },
      { type: clinicFilterTypes.status, values: [] },
      { type: clinicFilterTypes.t21, values: [] },
      { type: clinicFilterTypes.rewriteReason, values: [] }
    ];
    clinicFilterOptions.forEach(function (option) {
      let type = option.type;
      option.values.forEach(function (row) {
        if (row.checked) {
          switch (type) {
            case clinicFilterTypes.doctor:
              filters[0].values.push(row.value)
              break;
            case clinicFilterTypes.status:
              filters[1].values.push(row.value)
              break;
            case clinicFilterTypes.t21:
              filters[2].values.push(row.value === 'Yes' ? true : false)
              break;
            case clinicFilterTypes.rewriteReason:
              filters[3].values.push(row.value)
              break;
          }
        }
      })
    })

    return filters;
  }

  const handleOpen = () => {
    setShowPopup(!showPopup);
  }

  const handleClose = (e) => {
    //this is so the contents of the div (checkboxes) don't close the options menu when checked
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowPopup(false);
    }
  }

  const createFilterOptionsForImageOnly = () => {
    let options = [];
    //// doctors should now be filtered using the search bar
    // options.push({
    //   type: clinicFilterTypes.doctor,
    //   values: [...new Set(clinicsPrescriptions.map(item => item.organisationEmployee != null ? (item.organisationEmployee.firstName + ' ' + item.organisationEmployee.lastName) : {}))]
    //     .sort()
    //     .map(val => {
    //       return { value: val };
    //     })
    // });

    return options;
  }

  const createFilterOptionsForToBeAssigned = () => {
    let options = [];
    //t21
    options.push(
      {
        type: clinicFilterTypes.t21,
        values: ['Yes', 'No']
          .map(val => {
            return { value: val };
          })
      }
    )

    return options;
  }

  const createFilterOptionsForActive = () => {
    let options = [];

    //statuses
    options.push(
      {
        type: clinicFilterTypes.status,
        values: ["Processing", "Ready for payment", "Payment window missed", "With pharmacy team", "Waiting on paper copy", "Shipped"]
          .map(val => {
            return { value: val };
          })
      }
    )

    return options;
  }

  const createFilterOptionsForCompleted = () => {
    let options = [];
    //statuses
    options.push(
      {
        type: clinicFilterTypes.status,
        values: ["Prescription expired", "Delivered", "Payment window missed"]
          .map(val => {
            return { value: val };
          })
      }
    )

    return options;
  }

  const createFilterOptionsForReWriteRequests = () => {
    let options = [];
    //statuses
    options.push(
      {
        type: clinicFilterTypes.rewriteReason,
        values: [
          "Product not in stock",

          "Prescriber product error",
          "Product name missing, incomplete or unreadable",
          "Product dosage missing, incomplete  or unreadable",
          "Product strength missing, incomplete  or unreadable",
          "Product frequency missing, incomplete or unreadable",
          "Product quantity missing, incomplete or unreadable",

          "Prescription expired / close to expiry",

          "Signature and/or date prescribed missing",
          "The contents are unreadable",
          "Patient’s date of birth missing",
          "Patient’s full name missing",
          "Patient’s address missing",

          "Discontinued product",
        ]
          .map(val => {
            return { value: val };
          })
      }
    )

    return options;
  }

  const handleCheckboxChange = (e, i, j) => {
    dispatch(setClinicFilterOptions({ status: status, checked: e.target.checked, i: i, j: j }));

    //count the amount of checked checkboxes and store it
    dispatch(setClinicFilterNumberOfChecked({
      value: document.querySelectorAll('#divFilterCheckboxes input[type="checkbox"]:checked').length,
      status: status
    }));
  }

  const handleFilterClearByType = (type) => {
    let numOfChanges = 0;
    clinicFilterOptions.forEach((option, i) => {
      if (option.type == type) {
        option.values.forEach((value, j) => {
          if (value.checked) {
            dispatch(setClinicFilterOptions({ status: status, checked: false, i: i, j: j }));
            numOfChanges++;
          }
        });
        dispatch(setClinicFilterNumberOfChecked({
          value: clinicFilterNumberOfChecked - numOfChanges,
          status: status
        }));
      }
    });
  }

  function truncateStr(str, num) {
    if (str.length <= num) {
      return str;
    } else {
      str = str.slice(0, num);
      return str + "...";
    }
  }

  const renderOptions = (options, onClick = () => null) => {
    return <div>
      {
        options.map((option, i) => {
          return <div key={"option" + i}>
            <div className="clinic_filter__options__section_title">
              {callMapper(filterTypesToStringMapper, option.type)()}
              <hr />
            </div>
            {
              option.values.map((row, j) => {
                return <div key={"row" + j}>
                  <div id="divFilterCheckboxes" onClick={() => onClick(row)} className={"clinic_filter__options__option"}>
                    <label >
                      <input className="clinic_filter__options__checkbox" type="checkbox" value={row.value} checked={row.checked || false} onChange={(e) => handleCheckboxChange(e, i, j)} />
                      <span className="clinic_filter__options__checkbox__text">&nbsp;{row.value}</span>
                    </label>
                  </div>
                </div>
              })
            }
          </div>
        })
      }
    </div>
  }

  const renderTabs = () => {
    let options = getSelectedFilters();
    return <div>
      {
        options.map((tab, i) => {
          return (tab.values.length > 0)
            && <div key={"tab" + i} className="clinic_filter__tabs__tab">
              <div>{`${filterTypesToStringMapper[tab.type]()}: ${tab.values[0] === true ? "Yes" : tab.values[0] === false ? "No" : truncateStr(tab.values[0], 20)}${tab.values.length > 1 ? ` +${tab.values.length - 1}` : ""}`}</div>
              <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" className="clinic_filter__tabs__tab__icon" onClick={() => handleFilterClearByType(tab.type)}>
                <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z" fill="white" />
              </svg>
            </div>
        })
      }
    </div>
  }

  return (
    <>
      {isPageFilterEligible && <div className="clinic_filter"
        tabIndex="1"
        onBlur={(e) => handleClose(e)}>
        <div className="clinic_filter__content">
          <div className="clinic_filter__text" onClick={() => handleOpen()} >
            <FilterIcon />
            <p className="p5">
              Filters
              {clinicFilterNumberOfChecked > 0
                ? " (" + clinicFilterNumberOfChecked + ")"
                : ""
              }
            </p>
          </div>
        </div>
        {showPopup &&
          <div className="clinic_filter__options">
            {renderOptions(clinicFilterOptions)}
          </div>
        }
      </div>}
      {clinicFilterNumberOfChecked > 0 ?
        <div className="clinic_filter__tabs">
          {renderTabs()}
        </div>
        : ""
      }
    </>
  );
}

export default PrescriptionsFilter;

const statusToTextMapper = {
  [OrderStatuses.OutOfStock]:
    "Processing",
  [OrderStatuses.OutOfStockAndPaper]:
    "Processing",
  [OrderStatuses.NotPaidAndNoPaper]:
    "Ready for payment",
  [OrderStatuses.NotPaidAndPaper]:
    "Ready for payment",
  [OrderStatuses.PaidAndNoPaper]:
    "Waiting on paper copy",
  [OrderStatuses.PaidAndPaper]:
    "With pharmacy team",
  [OrderStatuses.Shipped]:
    "Shipped",
  [OrderStatuses.NoPaperAndPaymentMissed]:
    "Payment window missed",
  [OrderStatuses.PaperAndPaymentMissed]:
    "Payment window missed",
  [OrderStatuses.Void]:
    "Prescription expired",
  [OrderStatuses.CompletedAndVoid]:
    "Delivered",
  [OrderStatuses.NotApproved]:
    "Processing",

  [OrderStatuses.Complete]:
    "Delivered",
  [OrderStatuses.Archived]:
    "Archived",
  [OrderStatuses.Cancelled]:
    "Cancelled",

  undefined:
    "Unknown status"
}

const filterTypesToStringMapper = {
  [clinicFilterTypes.doctor]: () => {
    return "Doctor";
  },
  [clinicFilterTypes.status]: () => {
    return "Status";
  },
  [clinicFilterTypes.t21]: () => {
    return "T21";
  },
  [clinicFilterTypes.rewriteReason]: () => {
    return "Rewrite Reason";
  },
}
