import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getPastPrescriptions,
  selectLoadingPastPrescriptions,
  selectPastPrescriptions,
} from "../../../store/slices/prescriptionsSlice";
import { PastPrescriptionOrder } from "../../../components/account";
import { NoPastPrescriptions } from "..";
import { useFilterPrescriptions } from "../../../hooks";
import AccountLayout from "../../../layouts/AccountLayout/AccountLayout";
import { selectUser } from "../../../store/slices/authenticationSlice";
import Spinner2 from "../../../components/sharedComponents/Spinner/Spinner2";

function PastPrescriptions(props) {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const pastPrescriptions = useSelector(selectPastPrescriptions);
  const prescriptionsLoading = useSelector(selectLoadingPastPrescriptions);

  const filteredPrescriptions = useFilterPrescriptions(pastPrescriptions);

  useEffect(() => {
    if (!user) return;

    dispatch(getPastPrescriptions());
    //eslint-disable-next-line
  }, [user]);

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <AccountLayout>
      {prescriptionsLoading ? <div className="orders_loading--spinner">
        <Spinner2 color={"#00a372"} width={"60"} />
      </div>
        : <>
          <div className="previous">
            <div className="previous__orders">
              {filteredPrescriptions.length === 0 &&
                prescriptionsLoading === false && !prescriptionsLoading && <NoPastPrescriptions />}

              {filteredPrescriptions.map((order) => (
                <PastPrescriptionOrder key={`po${order.index}`} order={order} />
              ))}
            </div>
          </div>
        </>
      }

    </AccountLayout>
  );
}

export default PastPrescriptions;
