import React from "react";
import { selectUser } from "../../store/slices/authenticationSlice";
import { useSelector } from "react-redux";

function RenderForUserRoles({ userRoles = [], children }) {
  const user = useSelector(selectUser);

  return (
    userRoles.includes(user.type) ?
      children
      :
      <></>
  );
}

export default RenderForUserRoles;