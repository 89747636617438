import React from "react";

function MinusIcon({ color = "black" }) {
  return (
    <svg
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14"
        width="2"
        height="14"
        rx="1"
        transform="rotate(90 14 0)"
        fill={color}
      />
    </svg>
  );
}

export default MinusIcon;
