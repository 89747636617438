import React from "react";

function ClinicsBottomBarLinks({ className = "", links = [] }) {

  const resolveLinkClassNameForActive = (link) => {
    return `clinics_bottom_bar_links__link ${link.active ? "clinics_bottom_bar_links__link--active" : ""} `
  }

  const resolveLinkClassNameForCount = (link) => {
    return `clinics_bottom_bar_links__link__text__count ${link.resultsCount == 0 ? "clinics_bottom_bar_links__link__text__count__zero" : ""} `
  }

  const renderLinks = () => {
    return links.map((link) => {
      return <div key={link.text} onClick={() => link.onClick()} className={resolveLinkClassNameForActive(link)} >
        <div className={"clinics_bottom_bar_links__link__text"}>
          {link.text}
          &nbsp;
          {link.searching &&
            <div className={resolveLinkClassNameForCount(link)}>
              {link.resultsCount}
            </div>
          }
        </div>
      </div>
    })
  }
  return (
    <div className={`clinics_bottom_bar_links ${className}`} >
      {renderLinks()}
    </div>
  );
}

export default ClinicsBottomBarLinks;