import React from 'react'
import { TickIcon } from '../../../../assets/icons';
import { resolveAppendableClasses } from '../../../../util/util';

const RepeatPrescriptionChekbox = (
  {
    isChecked = true,
    onChange = () => null,
    className = '',
    appendableClassNames = "",
  }
) => {
  return (
    <div>
      <label className={`repeat_prescription-checkbox ${className} ${resolveAppendableClasses("repeat_prescription-checkbox", appendableClassNames)}`}>
        <div className={`repeat_prescription-checkbox__visual ${isChecked ? "repeat_prescription-checkbox__visual-bg-blue" : ""}`}>{isChecked ? <TickIcon color='white' /> : ""}</div>
        <input type="checkbox" checked={isChecked} onChange={onChange} />
      </label>
    </div>
  )
}

export default RepeatPrescriptionChekbox
