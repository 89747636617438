import React from "react";
import { resolveAppendableClasses } from "../../../../util/util";
import GenericCard from "../../GenericCard/GenericCard";

function PrescriptionRewriteRequiredCard({ className = "", appendableClassNames = "", prescription }) {
  const renderCircle = () => {
    return <div className={`prescription_card_for_side_preview__circle m-r-s  ${resolveAppendableClasses("prescription_card_for_side_preview__circle", "orange ")}`} />
  }

  return (
    <div
      className={` prescription_card_for_side_preview ${className} ${resolveAppendableClasses("prescription_card_for_side_preview", appendableClassNames)}`}
    >
      <GenericCard className={"m-t-s "} appendableClassNames={`orange`} >
        <div style={{ display: 'flex' }}>
          <div  >
            {renderCircle()}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <div className={"prescription_card_for_side_preview__title m-b-xs"}>
              Re-write required
            </div>

            <div className={"prescription_card_for_side_preview__normal_text"}>
              {`${prescription?.rewriteReason}  ${prescription?.rewriteAdditionalComment ? ` - ${prescription?.rewriteAdditionalComment}` : ""}`}
            </div>
          </div>

        </div>


      </GenericCard>
    </div>
  );
}

export default PrescriptionRewriteRequiredCard;