import React from "react";

import { FullScreenOverlay } from "..";
import { LoadingCard } from "../../cards";

import { LoadingIcon } from "../../../assets/icons";

function LoadingOverlay({ show, title, text }) {
  return (
    <FullScreenOverlay show={show}>
      <LoadingCard
        cardTitle={title}
        cardText={text}
        Icon={<LoadingIcon />}
      ></LoadingCard>
    </FullScreenOverlay>
  );
}

export default LoadingOverlay;
