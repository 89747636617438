import React, { useEffect } from 'react'
import { BigTickIcon } from '../../../assets/icons'

const CallBackRequested = () => {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="verify_email">
      <div className="verify_email__content">
        <div className="verify_email__image">
          <BigTickIcon />
        </div>
        <div className="verify_email__title">
          <p className="label--caps--normal">
            We got your request
          </p>
          <h1 className="h3-a">Call back requested</h1>
          <p className="p5">
            One of the Lyphe Dispensary team will reach out to you by your contact number.
          </p>
        </div>

      </div>
    </section>
  )
}

export default CallBackRequested
