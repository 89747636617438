import React from 'react'
import { useDispatch } from 'react-redux'
import { MoreInfoIcon } from '../../../assets/icons'
import { setStartLiveChatPopup } from '../../../store/slices/modalsSlice'
import QuaternaryCta from '../QuaternaryCta/QuaternaryCta'

const AreTheseDetailsCorrectButton = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setStartLiveChatPopup({ show: true }))
    }
    return (
        <div className='are_these_details_correct_button'>
            <QuaternaryCta
                text={
                    <p className="p6" >
                        Are these details correct?
                    </p>
                }
                icon={<MoreInfoIcon />}
                onClick={handleClick}
            />
        </div>
    )
}

export default AreTheseDetailsCorrectButton
