import React from "react";

import { InputField } from "../../inputs";

function ContactNumberForm({ number, areaCode }) {
  return (
    <div className="contact_number">
      <div>
        <InputField {...areaCode} name="Area code (optional)" type="text" />
      </div>
      <div>
        <InputField
          {...number}
          name="Contact number"
          type="tel"
        />
      </div>
    </div>
  );
}

export default ContactNumberForm;
