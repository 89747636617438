import React from "react";

function UploadIcon({ color = "black", size = 18 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.18228 12.3636C9.61865 12.3636 9.90956 12.0727 9.90956 11.6364V2.47273L10.855 3.41818C11.1459 3.70909 11.655 3.63636 11.8732 3.34545C12.0914 3.05455 12.0914 2.69091 11.8732 2.4L9.69137 0.218182C9.40047 -0.0727273 8.9641 -0.0727273 8.67319 0.218182L6.49138 2.4C6.20047 2.69091 6.20047 3.12727 6.49138 3.41818C6.78228 3.70909 7.21865 3.70909 7.50956 3.41818L8.45501 2.47273V11.6364C8.45501 12.0727 8.74592 12.3636 9.18228 12.3636Z"
        fill={color}
      />
      <path
        d="M17.1824 8.72705C16.746 8.72705 16.4551 9.01796 16.4551 9.45432V13.818C16.4551 14.2543 16.1642 14.5452 15.7278 14.5452H2.6369C2.20053 14.5452 1.90962 14.2543 1.90962 13.818V9.45432C1.90962 9.01796 1.61871 8.72705 1.18235 8.72705C0.745987 8.72705 0.455078 9.01796 0.455078 9.45432V13.818C0.455078 15.0543 1.40053 15.9998 2.6369 15.9998H15.7278C16.9642 15.9998 17.9096 15.0543 17.9096 13.818V9.45432C17.9096 9.09069 17.6187 8.72705 17.1824 8.72705Z"
        fill={color}
      />
    </svg>
  );
}

export default UploadIcon;
