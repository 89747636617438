import React, { useEffect } from "react";
import { Stepper } from "../../components";
import {
  CarerAddress,
  CarerAgeCheck,
  CarerConfirmDetails,
  CarerContactNumber,
  CarerOneLastStep,
  CarerPatientAgeCheck,
  CarerPatientName,
  CarerRelationshipToPatient,
} from "../stepperPages";
import PatientBeforeWeStartSecretId from "../stepperPages/BeforeWeStart/PatientBeforeWeStartSecretId";
import CheckYourEmailNewFlow from "../stepperPages/CheckYourEmail/CheckYourEmailNewFlow";
import PatientConfirmDobPatientId from "../stepperPages/PatientConfirmDobPatientId/PatientConfirmDobPatientId";
import ThankYouForConfirming from "../stepperPages/ThankYouForConfirming/ThankYouForConfirming";

function PatientCarerCompleteAccount() {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  return (
    <Stepper
      steps={patientCarerCompleteAccountSteps}
      firstStep={patientCarerCompleteAccountSteps.whoIsItFor}
    />
  );
}
const patientCarerCompleteAccountSteps = {
  totalSteps: 11,

  whoIsItFor: {
    component: PatientBeforeWeStartSecretId,
    step: 0,
    closeWithPopup: true,
    hideClose: true,
  },

  name: {
    component: PatientConfirmDobPatientId,
    step: 1, closeWithPopup: true,
    hideClose: true,
  },

  thankYouForConfirming: {
    component: ThankYouForConfirming,
    step: 2,
    closeWithPopup: true,
    hideClose: true,
  },

  // carer
  carerPatientAgeCheck: {
    component: CarerPatientAgeCheck,
    step: 3,
    closeWithPopup: true,
  },

  carerAgeCheck: {
    component: CarerAgeCheck,
    step: 4,
    closeWithPopup: true,
  },

  carerRelationShipToPatient: {
    component: CarerRelationshipToPatient,
    step: 5,
    closeWithPopup: true,
  },

  carerPatientsName: {
    component: CarerPatientName,
    step: 6,
    closeWithPopup: true,
  },

  carerPatientsAddress: {
    component: CarerAddress,
    step: 7,
    closeWithPopup: true,
  },

  carerContact: {
    component: CarerContactNumber,
    step: 8,
    closeWithPopup: true,
  },

  carerCheckDetails: {
    component: CarerConfirmDetails,
    step: 9,
    closeWithPopup: true,
  },

  carerOneLastStep: {
    component: CarerOneLastStep,
    step: 10,
    closeWithPopup: true,
  },

  end: {
    component: CheckYourEmailNewFlow,
    hideClose: true,
  },
};

export default PatientCarerCompleteAccount;
