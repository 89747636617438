import React, { useEffect, useState } from "react";
import { FullScreenOverlay } from "../../overlays";
import { CloseIcon, MoreInfoIcon } from "../../../assets/icons";
import PrescriptionPreview from "../../PrescriptionPreview/PrescriptionPreview";
import { PrimaryCta } from "../../buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrescribersForOrganisationEmployee,
  getPatientByClinicId,
  getPrescriptionsForOrganisation,
  resetPatientForClinicUser,
  selectFromPrescriptionSliceByKey,
  selectPatientForClinicUser,
  setInStateByKey,
  selectProtoPrescriptionForPromoting, promoteProtoPrescription, deleteProtoPrescription,
} from "../../../store/slices/prescriptionsSlice";
import { Checkbox, CustomSelect, InputField } from "../../inputs";
import { useProps } from "../../../hooks";
import GenericBanner from "../../banners/GenericBanner/GenericBanner";
import Spinner from "../../sharedComponents/Spinner/Spinner";
import { EmployeeType, OrganisationPrescriptionsTypes, toastTypes } from "../../../enums";
import { callMapper } from "../../../util/util";
import { useParams } from "react-router-dom";
import { setToast } from "../../../store/slices/toastSlice";
import TickIconSuccess from "../../../assets/icons/TickIconSuccess";
import { selectCurrentOrganisation, selectUserDetails } from "../../../store/slices/accountSlice";
import TrashBin from "../../../assets/icons/TrashBin";

function PromoteProtoPrescriptionModal({ show = false, onClose = () => null, }) {

  const dispatch = useDispatch();
  const { status } = useParams();
  const selectedProtoPrescription = useSelector(selectProtoPrescriptionForPromoting);
  const patientForClinicUser = useSelector(selectPatientForClinicUser);
  const loadingPatientForClinicUser = useSelector(selectFromPrescriptionSliceByKey("loadingPatientForClinicUser"));
  const uploadingPrescription = useSelector(selectFromPrescriptionSliceByKey("uploadingPrescriptionAsClinicUser"));
  const wasPatientFound = useSelector(selectFromPrescriptionSliceByKey("foundPatientForClinicUser"));
  const [currentStep, setCurrentStep] = useState(steps.SelectPatient);
  const [showBanners, setShowBanners] = useState(false);
  const [isFirstTimeSearch, setIsFirstTimeSearch] = useState(true);

  const prescriberSearchString = useProps("");
  const clinicPatientId = useProps("");
  const firstName = useProps(patientForClinicUser.firstName);
  const lastName = useProps(patientForClinicUser.lastName);
  const emailAddress = useProps(patientForClinicUser.email);
  const dob = useProps(patientForClinicUser.dateOfBirth);
  const contactNumber = useProps(patientForClinicUser.telephoneNo);
  const address1 = useProps(patientForClinicUser.address1);
  const address2 = useProps(patientForClinicUser.address2);
  const postalCode = useProps(patientForClinicUser.zipCode);
  const trackingNumber = useProps(selectedProtoPrescription.envelopeTrackingCode);
  const t21Patient = useProps(false);
  const passedMdt = useProps(false)

  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const userDetails = useSelector(selectUserDetails);

  const statusToSelectorMapper = {
    [OrganisationPrescriptionsTypes.ReWriteRequests]: OrganisationPrescriptionsTypes.ReWriteRequests,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: OrganisationPrescriptionsTypes.ToBeAssigned,
    [OrganisationPrescriptionsTypes.Active]: OrganisationPrescriptionsTypes.Active,
    [OrganisationPrescriptionsTypes.Complete]: OrganisationPrescriptionsTypes.Complete,
  }

  const dispatchSuccessfulPromotion = (error) => {
    const newToastState = {
      showToast: true,
      title: 'Patient details added to Prescription!',
      message: 'The prescription is now with Lyphe Dispensary to process',
      type: toastTypes.Success
    }

    const errorToastState = {
      showToast: true,
      title: 'Error adding patient details!',
      message: `${error ? error.message : 'Something went wrong'}`,
      type: toastTypes.Error
    }

    if (error) {
      dispatch(setToast(errorToastState));
    } else {
      dispatch(setToast(newToastState));
    }
  }

  const dispatchSuccessfulDeletion = (error) => {
    const newToastState = {
      showToast: true,
      title: 'Prescription image deleted successfully!',
      message: 'You deleted prescription image',
      type: toastTypes.Success
    }

    const errorToastState = {
      showToast: true,
      title: 'Error deleting prescription image!',
      message: `${error.message}`,
      type: toastTypes.Error
    }
    if (!error) {
      dispatch(setToast(newToastState));
    }
    dispatch(setToast(errorToastState));
  }

  const onRightButtonClick = async () => {
    if (currentStep === steps.SelectPatient) {
      if (wasPatientFound) {

        if (patientForClinicUser.isTempPatient) {
          const { error } = await dispatch(promoteProtoPrescription({
            protoPrescriptionId: selectedProtoPrescription.prescriptionId,
            organisationId: currentOrganisation.organisationId,
            tempPatientId: patientForClinicUser.id,
            tempPatientEmail: patientForClinicUser.email.trim(),
            tempPatientTelephoneNo: patientForClinicUser.telephoneNo.trim(),
            isT21Patient: t21Patient.value,
            envelopeTrackingCode: trackingNumber.value,
          }))
          dispatchSuccessfulPromotion(error)
        } else {
          const { error } = await dispatch(promoteProtoPrescription({
            protoPrescriptionId: selectedProtoPrescription.prescriptionId,
            organisationId: currentOrganisation.organisationId,
            patientId: patientForClinicUser.id,
            isT21Patient: t21Patient.value,
            envelopeTrackingCode: trackingNumber.value,
          }))
          dispatchSuccessfulPromotion(error)
        }
      }
      if (!wasPatientFound) {
        const { error } = await dispatch(promoteProtoPrescription({
          protoPrescriptionId: selectedProtoPrescription.prescriptionId,
          organisationId: currentOrganisation.organisationId,
          clinicIdNumber: clinicPatientId.value.trim(),
          tempPatientId: patientForClinicUser.id,
          tempPatientEmail: emailAddress.value.trim(),
          tempPatientTelephoneNo: contactNumber.value.trim(),
          isT21Patient: t21Patient.value,
          envelopeTrackingCode: trackingNumber.value,
        }))
        dispatchSuccessfulPromotion(error)
      }

      refreshPrescriptions();
      onClose();
    }

    if (currentStep === steps.ConfirmDeletion) {
      await onDeleteProtoPrescription();
    }
  }

  const onDeleteProtoPrescription = async () => {
    const { error } = await dispatch(deleteProtoPrescription({
      id: selectedProtoPrescription.prescriptionId,
      organisationId: currentOrganisation.organisationId
    }))

    refreshPrescriptions();
    dispatchSuccessfulDeletion(error);
    onClose();
  }

  const refreshPrescriptions = () => {
    const getPrescriptionsPayload = {
      organisationId: currentOrganisation.organisationId,
      organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
        ? userDetails.id
        : null,
      status: callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly),
    }
    dispatch(getPrescriptionsForOrganisation(getPrescriptionsPayload))
    resetPromotePrescriptionState()
  }

  const onFindPatient = () => {
    dispatch(resetPatientForClinicUser());

    dispatch(getPatientByClinicId({ organisationId: currentOrganisation.organisationId, clinicPatientId: clinicPatientId.value }))
    setShowBanners(true);
    setIsFirstTimeSearch(false);
    emailAddress.reset();
    contactNumber.reset();
    t21Patient.reset();
    passedMdt.reset()
  }

  const onClear = () => {
    dispatch(resetPatientForClinicUser());
    dispatch(setInStateByKey({
      data: false,
      key: "foundPatientForClinicUser"
    }));
    clinicPatientId.reset();
    setShowBanners(false);
    setIsFirstTimeSearch(true);
  }

  const onLeftButtonClick = async () => {
    if (currentStep === steps.SelectPatient) {
      setCurrentStep(steps.ConfirmDeletion);
      return;
    }

    resetPromotePrescriptionState()
    onClose();
  }

  const renderLeftButtonText = () => {
    if (currentStep === steps.SelectPatient)
      return <> <TrashBin color={"darkgrey"} /> &nbsp; Delete </>;

    return "Cancel";
  }

  const renderRightButtonText = () => {
    if (currentStep === steps.SelectPatient)
      return "Send to DG";

    return "Delete permanently";
  }

  const isNextEnabled = () => {
    if (currentStep === steps.SelectPatient) {
      if (wasPatientFound) {
        if (patientForClinicUser?.isTempPatient) {
          return passedMdt.value
        }
        return !uploadingPrescription && patientForClinicUser?.id
      }
      if (!wasPatientFound) {

        return !!emailAddress.value && !!contactNumber.value && passedMdt.value
      }

      return false
    }
  }

  const renderRightContent = () => {
    return selectedProtoPrescription ? <PrescriptionPreview src={selectedProtoPrescription.filePath} /> : <></>
  }

  const renderT21Select = () => {
    return <>
      <CustomSelect
        name={"T21 Patient"}
        showInitialValueWithNoValue={false}
        {...t21Patient}
        hideLabel={true}
        options={[
          {
            text: "Yes",
            value: true,
          },
          {
            text: "No",
            value: false,
          },
        ]}
      />
    </>
  }

  const renderMdtPassedCheckBox = () => {
    return <Checkbox
      name={"I have reviewed the above and made sure to check the prescription(s)"}
      className={"m-t-s"}
      appendableClassNames={"w100"}
      isChecked={passedMdt.value}
      onChange={() => { passedMdt.setValue(!passedMdt.value) }}
      customStyle="custom_width"
    />
  }

  const renderPatientInfoInputs = () => {
    if (isFirstTimeSearch || loadingPatientForClinicUser) {
      return <></>
    }
    if (!patientForClinicUser.id) {
      return <>
        <div className={"text_input_title m-b-xs m-t-s"}>Is T21</div>
        {renderT21Select()}

        <div className={"text_input_title m-b-xs m-t-s"}>Email</div>
        <InputField
          className={""}
          name={"Email"}
          {...emailAddress}
        />
        <div className={"text_input_title m-b-xs m-t-s"}>Contact number</div>
        <InputField
          className={""}
          name={"Contact number"}
          {...contactNumber}
        />
        <div className={"text_input_title m-b-xs m-t-s"}>Tracking number</div>
        <InputField
          className={""}
          name={"Tracking number"}
          isEnabled={selectedProtoPrescription.envelopeTrackingCode === null}
          {...trackingNumber}
        />
        {renderMdtPassedCheckBox()}
      </>
    }
    if ((!wasPatientFound && !!patientForClinicUser.id)) {
      return <>
        <div className={"text_input_title m-b-xs"}>Is T21 patient</div>
        {renderT21Select()}

        <div className={"text_input_title m-b-xs"}>Email</div>
        <InputField
          className={""}
          name={"Email"}
          {...emailAddress}
        />
        <div className={"text_input_title m-b-xs "}>Contact number  </div>
        <InputField
          className={""}
          name={"Contact number"}
          {...contactNumber}
        />
        <div className={"text_input_title m-b-xs "}>Tracking number</div>
        <InputField
          className={""}
          name={"Tracking number"}
          {...trackingNumber}
        />
        {renderMdtPassedCheckBox()}
      </>
    }
    return <>
      {patientForClinicUser.isTempPatient ?
        <>
          <div className={"text_input_title m-t-s m-b-xs"}>Is T21 patient</div>
          {renderT21Select()}

          <div className={"text_input_title m-t-s m-b-xs"}>Email</div>
          <InputField
            isEnabled={false}
            className={""}
            name={"Email"}
            {...emailAddress}
          />
          <div className={"text_input_title m-t-s m-b-xs"}>Contact number</div>
          <InputField
            isEnabled={false}
            className={""}
            name={"Contact number"}
            {...contactNumber}
          />
          <div className={"text_input_title m-t-s m-b-xs"}>Tracking number</div>
          <InputField
            className={""}
            name={"Tracking number"}
            {...trackingNumber}
          />
          {renderMdtPassedCheckBox()}
        </>
        :
        <>
          <div className={"text_input_title m-b-xs m-t-s"}>T21 Patient</div>
          {renderT21Select()}

          <div className={"text_input_title m-b-xs m-t-s "}>Tracking number</div>
          <InputField
            className={""}
            name={"Tracking number"}
            {...trackingNumber}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>First name</div>
          <InputField
            isEnabled={false}
            appendableClassNames={"permanently_disabled"}
            name={"First name"}
            {...firstName}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Last name</div>
          <InputField
            isEnabled={false}
            name={"Last name"}
            appendableClassNames={"permanently_disabled"}
            {...lastName}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Email</div>
          <InputField
            isEnabled={false}
            name={"Email"}
            appendableClassNames={"permanently_disabled"}
            {...emailAddress}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Date of birth</div>
          <InputField
            isEnabled={false}
            name={"Date of birth"}
            appendableClassNames={"permanently_disabled"}
            {...dob}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Contact number</div>
          <InputField
            isEnabled={false}
            name={"Contact number"}
            appendableClassNames={"permanently_disabled"}
            {...contactNumber}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Address 1</div>
          <InputField
            isEnabled={false}
            name={"Address 1"}
            appendableClassNames={"permanently_disabled"}
            {...address1}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Address 2</div>
          <InputField
            isEnabled={false}
            name={"Address 2"}
            appendableClassNames={"permanently_disabled"}
            {...address2}
          />

          <div className={"text_input_title m-b-xs m-t-s"}>Post code</div>
          <InputField
            isEnabled={false}
            className={"m-b-s"}
            name={"Post code"}
            appendableClassNames={"permanently_disabled"}
            {...postalCode}
          />
        </>
      }
    </>
  }

  const renderPatientFoundNotFoundBanners = () => {
    if (!showBanners || loadingPatientForClinicUser) {
      return null;
    }
    return wasPatientFound ?
      patientForClinicUser.isTempPatient ?
        <GenericBanner
          className={"m-t-s generic_banner--blue generic_banner--fontWeight-400"}
          icon={<MoreInfoIcon size="17" color={"#3B5EDB"} />}
          title="Account not created yet. "
        >
          You have already provided the basic details with a previous script.
        </GenericBanner>
        :
        <GenericBanner
          className={"m-t-s generic_banner--green generic_banner--fontWeight-400"}
          icon={<TickIconSuccess size="17" color={"#51C378"} />}
          title="Patient account exists!"
        >
          Please review their details and T21 status before uploading the prescription.
        </GenericBanner>
      :
      <GenericBanner
        className={"m-t-s generic_banner--blue generic_banner--fontWeight-400"}
        icon={<MoreInfoIcon size="17" color={"#3B5EDB"} />}
        title="No account found "
      >
        Please provide the below details so we can create an account for this patient.
      </GenericBanner>
  }

  const resetPromotePrescriptionState = () => {
    setCurrentStep(steps.SelectPatient)
    dispatch(resetPatientForClinicUser());
    clinicPatientId.reset();
    passedMdt.reset();
    trackingNumber.reset();
    setShowBanners(false);
    setIsFirstTimeSearch(true);
    prescriberSearchString.setValue("")
    dispatch(setInStateByKey({ key: "foundPatientForClinicUser", data: false }))
  }

  const onModalClose = () => {
    onClose()
    resetPromotePrescriptionState()
  }


  useEffect(() => {
    if (currentOrganisation.organisationId > 0)
      dispatch(getPrescribersForOrganisationEmployee(currentOrganisation.organisationId))
    // eslint-disable-next-line
  }, [currentOrganisation])

  return (
    <FullScreenOverlay show={show}>
      <div className="promote_proto-prescription_modal">
        {currentStep === steps.SelectPatient &&
          <div className="promote_proto-prescription_modal__header">
            Prescription ID: {selectedProtoPrescription.prescriptionId}  <span className="promote_proto-prescription_modal__header__close" onClick={() => { onModalClose() }}><CloseIcon /> </span>
          </div>
        }
        {currentStep === steps.ConfirmDeletion &&
          <div className="promote_proto-prescription_modal__header">
            Confirm deletion  <span className="promote_proto-prescription_modal__header__close" onClick={() => { onModalClose() }}><CloseIcon /> </span>
          </div>
        }
        <div className="promote_proto-prescription_modal__content">
          <div className="promote_proto-prescription_modal__content__left">
            {currentStep === steps.SelectPatient &&
              <>
                <div className={"promote_proto-prescription_modal__content__step_title"}>
                  1. Select prescriber
                  <span
                    className={"promote_proto-prescription_modal__content__step_title__prescriber_name"}>
                    {selectedProtoPrescription.organisationEmployee && ` ${selectedProtoPrescription.organisationEmployee.firstName} ${selectedProtoPrescription.organisationEmployee.lastName}`}
                  </span>
                </div>


                <div className={"promote_proto-prescription_modal__content__step_title m-t-s"}>
                  2. Select patient &nbsp;
                  <span
                    className={"promote_proto-prescription_modal__content__step_title__prescriber_name"}>
                    {`${patientForClinicUser.firstName || ""} ${patientForClinicUser.lastName || ""}`}
                  </span>
                </div>
                <div className={"promote_proto-prescription_modal__content__step_explanation m-t-s"}>
                  Enter the Clinic Patient ID of the patient. If Lyphe Dispensary has not created an account for them
                  yet, please provide the details required so we can create one for them.
                </div>
                <div className={"text_input_title m-t-s m-b-xs "}>Clinic Patient ID</div>
                <InputField
                  type="number"
                  name={"Clinic number"}
                  {...clinicPatientId}
                />

                {clinicPatientId.value &&
                  <div className={"m-t-s "} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <PrimaryCta
                      isEnabled={true}
                      color={"dark_blue"}
                      className="m-r-s primary_cta--fit_content primary_cta--squared"
                      onClick={() => {
                        onFindPatient();
                      }}
                    >
                      {loadingPatientForClinicUser ? <Spinner width={20} /> : "Find"}
                    </PrimaryCta>

                    <PrimaryCta color={"white"} onClick={() => {
                      onClear();
                    }} className={"primary_cta--fit_content primary_cta--squared"} text={"Clear"} />
                  </div>
                }

                {renderPatientFoundNotFoundBanners()}
                {renderPatientInfoInputs()}


              </>
            }

            {currentStep === steps.ConfirmDeletion &&
              <>
                <div className={"promote_proto-prescription_modal__content__step_explanation m-t-s"}>
                  Please confirm you would like to delete this Prescription image.
                  <br />
                  <br />
                  Once deleted, this prescription image cannot be restored.
                </div>
                <div className="side_preview_content__informations__section_title">Prescriber</div>
                <div className={"text_input_title m-t-s m-b-xs "}>Name</div>
                <InputField
                  name={"Name"}
                  isEnabled={false}
                  value={`${selectedProtoPrescription.organisationEmployee.firstName} ${selectedProtoPrescription.organisationEmployee.lastName}`}
                  onChange={() => { }}
                />
              </>
            }

          </div>
          <div className="promote_proto-prescription_modal__content__right">
            {renderRightContent()}
          </div>
        </div>
        <div className="promote_proto-prescription_modal__footer">
          <div className="promote_proto-prescription_modal__footer__buttons" >
            <PrimaryCta
              onClick={() => { onLeftButtonClick() }}
              color={"white"}
            >
              {renderLeftButtonText()}
            </PrimaryCta>

            <PrimaryCta
              isEnabled={isNextEnabled()}
              color={"dark_blue"}
              className="m-l-s"
              onClick={() => {
                onRightButtonClick()
              }}
            >
              {renderRightButtonText()}
            </PrimaryCta>
          </div>
        </div>
      </div>
    </FullScreenOverlay>
  );
}

export default PromoteProtoPrescriptionModal;

const steps = {
  SelectPatient: 1,
  ConfirmDeletion: 2,
}
