import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ chartData, chartOptions }) {
  return (
    <div className="barChartContainer">
      <Bar
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
}

export default BarChart;
