import { useSelector } from "react-redux"
import { selectDoctorsPatients } from "../store/slices/accountSlice"
import { selectPatientIdFilter } from "../store/slices/prescriptionsSlice"

/* extract the name of selected patient from doctors patients by utilising selectPatientIdFilter (the user's id) and pass it to autoComplete .
Each time selectPatientIdFilter changes the selected patient name will change as well*/

export default function useSelectedPatientName() {
    const doctorsPatients = useSelector(selectDoctorsPatients)
    const indentifier = useSelector(selectPatientIdFilter)
    const entries = Object.entries(doctorsPatients)
    // eslint-disable-next-line
    const selectedPatient = entries.find(e => e[1].id == indentifier) ? entries.find(e => e[1].id == indentifier) : []
    const patientsName = selectedPatient[0]?.split(" ")?.slice(0, 2)?.join(" ")
    return patientsName
}