import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AddressAutoCompleteForm } from "../../../components/forms";
import { PrimaryCta } from "../../../components/buttons";
import { NoticeCard } from "../../../components/cards";

import { useProps } from "../../../hooks";
import {
  selectCarer,
  setPatientAddress,
} from "../../../store/slices/carerOnboardingSlice";
import { addressValidator, zipCodeValidator } from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";

function CarerAddress({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const carer = useSelector(selectCarer);

  const autoComplete = useProps("");

  const address1 = useProps(
    carer.address1 || "",
    addressValidator("Address 1")
  );
  const address2 = useProps(
    carer.address2 || "",
    addressValidator("Address 2")
  );
  const zipCode = useProps(carer.zipCode || "", zipCodeValidator());
  const addressForm = { address1, address2, zipCode };

  const isButtonEnabled = isFormValid(addressForm);

  // scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function nextClicked() {
    const payload = propsToValues(addressForm);
    dispatch(setPatientAddress(payload));

    trackAnalyticsEvent("Carer Onboarding", "Carer Address added");
    setNextStep(steps.carerContact);
  }

  return (
    <section className="contact_information">
      <div className="contact_information__content">
        <div className="contact_information__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please tell us the patients address</h1>
          <p className="p5">
            Please tell us your address so we know where to send your medication
          </p>
        </div>

        <div className="contact_information__form">
          <div className="m-b-mm">
            <NoticeCard
              titleTop="Before you proceed"
              textTop="Please note we only dispense to UK addresses"
            />
          </div>

          <AddressAutoCompleteForm
            autoComplete={autoComplete}
            {...addressForm}
          />
        </div>

        <div className="contact_information__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            text="Next"
            onClick={nextClicked}
            color="orange"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerAddress;
