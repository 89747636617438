import React from "react";
import { AccountHeader } from "../../../components/headers";
import { BrokenLinkIcon } from "../../../assets/icons";

function OrganisationNoAccess() {
  return (
    <section>
      <AccountHeader />
      <div className={"organisation-get-started__container"}>
        <div className={"organisation-get-started__icon"}>
          <BrokenLinkIcon />
        </div>
        <p className="p3 m-t-s">
          You are not linked to any Organisations
        </p>
      </div>
    </section>
  );
}

export default OrganisationNoAccess;
