import React from "react";

function ArrowRightIcon({ color = "black", ...props }) {
  return (
    <div {...props}>
      <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 14.5L7 8L1 1.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default ArrowRightIcon;
