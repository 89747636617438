import React, { useState } from "react";

import { Label } from "../../../../../buttons";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductDetails from "../ProductDetails/ProductDetails";

import { MinusIcon, PlusIcon } from "../../../../../../assets/icons";

function MobileProduct({
  productStatus = { text: "", color: "" },
  id = "",
  name = "",
  description = "",
  productDetailsTop,
  productDetailsBottom,
}) {
  const [showBody, setShowBody] = useState(false);
  const detailToggleButton = showBody ? (
    <>
      <p className="p5--semi--bold">Hide detail</p>
      <MinusIcon color="black" />
    </>
  ) : (
    <>
      <p className="p5--semi--bold">View detail</p>
      <PlusIcon color="black" />
    </>
  );
  const buttonTextName = `mobile_product__button ${showBody ? "mobile_product__button--active" : ""
    }`;

  return (
    <div className="mobile_product">
      <Label text={productStatus.text} color={productStatus.color} />
      <div className="mobile_product__title">
        <p className="h7-a">{name}</p>
      </div>

      <ProductDetails
        key={`m1${id}`}
        content={productDetailsTop}
        column
        applyLetterSpacing
      />
      <div className={buttonTextName} onClick={() => setShowBody(!showBody)}>
        {detailToggleButton}
      </div>

      <ProductDescription
        key={`m2${id}`}
        showBody={showBody}
        description={description}
        productDetailsBottom={productDetailsBottom}
      />
    </div>
  );
}

export default MobileProduct;
