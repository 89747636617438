import { useState } from "react";
import { useDispatch } from "react-redux";
import { paymentService } from "../services/PaymentService";
import { setPaymentFailed } from "../store/slices/prescriptionsSlice";

export default function useOpayo({ cardHolderName, cardNumber, expiryDate, cvc }) {
    const [merchantSessionKey, setMerchantSessionKey] = useState(null);
    const [cardIdentifier, setCardIdentifier] = useState(null);
    const dispatch = useDispatch();

    async function getOpayoTransactionData() {
        dispatch(setPaymentFailed(false))
        const mskResponse = await paymentService.merchantSessionKey();
        const sessionKey = mskResponse.merchantSessionKey;
        setMerchantSessionKey(sessionKey);

        window.sagepayOwnForm({ merchantSessionKey: sessionKey }).tokeniseCardDetails({
            cardDetails: {
                cardholderName: cardHolderName,
                cardNumber: cardNumber,
                expiryDate: expiryDate,
                securityCode: cvc,
            },
            onTokenised: async (result) => {
                if (result.success) {
                    const { cardIdentifier } = result;
                    setCardIdentifier(cardIdentifier);
                }
                if (!result.success) {
                    dispatch(setPaymentFailed(true));
                }
            }
        })

    }

    return { merchantSessionKey, cardIdentifier, getOpayoTransactionData, setMerchantSessionKey, setCardIdentifier }
}