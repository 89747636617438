import React from 'react'
import { useSelector } from 'react-redux';
import { selectUserDetails } from "../../../store/slices/accountSlice";
import OrderPaymentCard from '../../cards/OrderPaymentCard/OrderPaymentCard'
import { ReactComponent as SuccessfulPaymentIcon } from "../../../assets/images/successful_payment.svg";
import { PrimaryCta } from '../../buttons';
import { useHistory } from 'react-router-dom';

const SuccessfulPayment = () => {
    const history = useHistory();
    const userDetails = useSelector(selectUserDetails)

    const viewOrderStatus = () => {
        history.push('/prescriptions/active')
    }

    return (
        <OrderPaymentCard >
            <div className='successful_payment'>
                <div className='successful_payment__icon'>
                    <SuccessfulPaymentIcon />
                </div>
                <h5 className='successful_payment__title' >Payment successful</h5>
                {
                    userDetails !== undefined ? (
                        <p className='successful_payment__text' >
                            You have successfully paid for your medication. You can view the next steps from your Lyphe Dispensary account.
                        </p>) :
                        <p className='successful_payment__text'>
                            You have successfully paid for your medication. You can view the next steps from your Lyphe Dispensary account, just sign up from the link in your email.
                        </p>
                }
                <div className='successful_payment__button' hidden={userDetails === undefined} >
                    <PrimaryCta onClick={() => viewOrderStatus()} text='View order status' />
                </div>
            </div>
        </OrderPaymentCard>
    )
}

export default SuccessfulPayment
