import React from "react";

function OrderStatusPaymentNeededIcon(props) {
  return (
    <svg
      width="44"
      height="30"
      viewBox="0 0 44 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M13.2828 17.4341H4.98096V24.0756H13.2828V17.4341Z"
          fill="black"
        />
        <path
          d="M30.7172 21.585H16.6123V23.2453H30.7172V21.585Z"
          fill="black"
        />
        <path
          d="M35.6983 18.2642H16.6123V19.9245H35.6983V18.2642Z"
          fill="black"
        />
        <path
          d="M43.0204 0.971326C42.5663 0.515155 41.9895 0.200772 41.36 0.0664201C41.1359 0.0218642 40.9078 -0.000382726 40.6793 4.98097e-06H3.32078C2.44006 4.98097e-06 1.59541 0.349869 0.972651 0.972632C0.349888 1.59539 2.40603e-05 2.44004 2.40603e-05 3.32076V26.566C-0.00252404 27.2272 0.197376 27.8732 0.572854 28.4174C1.05693 29.1399 1.80512 29.6436 2.65663 29.8204C2.87542 29.8634 3.0978 29.8856 3.32078 29.8868H40.6793C41.56 29.8868 42.4046 29.5369 43.0274 28.9142C43.6502 28.2914 44 27.4468 44 26.566V3.32076C43.9993 2.88396 43.9124 2.45161 43.7443 2.04845C43.5762 1.6453 43.3302 1.27927 43.0204 0.971326ZM42.3396 18.9615V26.566C42.3396 27.0064 42.1647 27.4287 41.8533 27.7401C41.542 28.0515 41.1196 28.2264 40.6793 28.2264H3.32078C2.99 28.2247 2.66689 28.1266 2.39097 27.9442C2.16564 27.7919 1.98117 27.5865 1.85379 27.3462C1.7264 27.1059 1.65999 26.838 1.6604 26.566V11.6226H42.3396V18.9615ZM1.6604 8.30189V3.32076C1.6604 2.8804 1.83533 2.45808 2.14671 2.1467C2.4581 1.83531 2.88042 1.66038 3.32078 1.66038H40.6793C40.9512 1.65997 41.2192 1.72638 41.4595 1.85377C41.6998 1.98115 41.9051 2.16562 42.0574 2.39095C42.2398 2.66687 42.3379 2.98998 42.3396 3.32076V8.30189H1.6604Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default OrderStatusPaymentNeededIcon;
