import React from "react";

function GreenCheckedCircleIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#57b799" />
      <path
        d="M14.7637 21C14.5398 21 14.2413 20.9254 14.0921 20.7761L10.4354 18.1642C9.9876 17.7911 9.83835 17.1194 10.2115 16.597C10.4354 16.2985 10.7339 16.1493 11.107 16.1493C11.3309 16.1493 11.5548 16.2239 11.7786 16.3732L14.6145 18.3881L20.8085 11.2985C21.0324 11.0747 21.3309 11 21.5548 11C21.8533 11 22.0772 11.0747 22.301 11.2985C22.7488 11.7463 22.8234 12.4179 22.3757 12.8657L15.5846 20.6269C15.3607 20.8508 15.0622 21 14.7637 21Z"
        fill="white"
      />
    </svg>
  );
}

export default GreenCheckedCircleIcon;
