import { useState, useEffect, useCallback } from "react";

/**
 * Manages the state of any kind of input.
 * Result of this hook is passed to InputField component.
 * @see InputField.js
 * Input field will then display errors based on
 * the validators that were passed to it
 *
 * Validators are coming from
 * @see validators.js
 *
 * @example
 *
 * with error checking
 * const firstName = useProps("", nameValidator("First name"))
 *
 * without error checking
 * const lastName = useProps("");
 *
 * ...
 *
 * <InputField {...firstName} />
 * <InputField {...lastName} />
 *
 *
 * @param {*} initialValue
 * @param [{validatorFunction: Function, error: string}] validators
 */
function useProps(initialValue, validators = [], valueTransformer = (value, event) => { return value }, validateOnlyOnBlur = false) {
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);
  // Don't run errors until the user starts typing
  const [runErrorCheck, setRunErrorCheck] = useState(false);

  useEffect(() => {
    setValue(initialValue)

  }, [initialValue])

  // Check the value for errors
  useEffect(() => {
    if (!runErrorCheck) return;

    const errorsList = [];
    for (let validator of validators) {
      checkValue(validator, value, errorsList);
    }

    // If there are new errors set them
    // or if there are no new errors and
    // there are old errors replace old errors with
    // empty list
    if (errorsList.length > 0 || errors.length > 0) {
      setErrors(errorsList);
    }
    // eslint-disable-next-line
  }, [value, runErrorCheck]);

  /**
   * Start running error checks only when user starts typing
   */
  const onChange = useCallback((e) => {
    if (!runErrorCheck && !validateOnlyOnBlur) setRunErrorCheck(true);
    if (validateOnlyOnBlur) {
      setErrors([])
      setRunErrorCheck(false)
    }
    e.preventDefault();
    setValue(valueTransformer(e.target.value, e));
    // eslint-disable-next-line
  }, []);

  // TODO: figure out why you wrote this
  function onBlur() {
    if (!runErrorCheck) {
      setRunErrorCheck(true);
    }
  }

  /**
   * Check if the value isn't
   * empty and if there are no errors
   */
  function isValid() {
    return !errors.length && value;
  }

  function reset() {
    setValue(initialValue);
  }

  return {
    value,
    errors,
    setErrors,
    setValue,
    onChange,
    onBlur,
    isValid,
    reset,
  };
}

/**
 * For a given value and a validator
 * checks if that value meets the validators requests
 * if not it adds the error to the errors array
 * @param {*} validator
 * @param {*} value
 * @param {*} errors
 */
function checkValue(validator, value, errors) {
  if (!validator.validatorFunction(value)) {
    errors.push(validator.error);
  }
}

export default useProps;
