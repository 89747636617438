import React from "react";

import { OrderStatuses, ProductUnit } from "../../../../../enums";
import { convertIngredientNumberToText, getPluralText } from "../../../../../util/util";
import DesktopProduct from "./DesktopProduct/DesktopProduct";
import MobileProduct from "./MobileProduct/MobileProduct";

function Product({ product, orderStatus }) {

  const thcText = convertIngredientNumberToText(product.thc);
  const cbdText = convertIngredientNumberToText(product.cbd);

  const productDetailsTop = [
    { title: "DATE PRESCRIBED:", text: product.date },
    { title: "THC | CBD:", text: `${thcText}/${cbdText}` },
    { title: "Quantity:", text: `${product.amount}` },
  ];

  const productUnit = ProductUnit.getName(product.productUnit);

  const productDetailsBottom = [];

  // only show titration if it's above 0
  if (product.titration > 0) {
    const titration = {
      title: "Increased titration:",
      text: `${product.titration}${productUnit} every ${getPluralText(
        "day",
        product.titrationCadence
      )} (Max ${product.maxTitration}${productUnit})`,
    };

    productDetailsBottom.splice(2, 0, titration);
  }

  const productStatus =
    ProductStatusMapper[product.isCanceled ? "Canceled" : orderStatus];

  return (
    <>
      <DesktopProduct
        {...product}
        productStatus={productStatus}
        productDetailsTop={productDetailsTop}
        productDetailsBottom={productDetailsBottom}
      />
      <MobileProduct
        {...product}
        productStatus={productStatus}
        productDetailsTop={productDetailsTop}
        productDetailsBottom={productDetailsBottom}
      />
    </>
  );
}

const ProductStatusMapper = {
  [OrderStatuses.PaidAndNoPaper]: { text: "Payment made", color: "green" },
  [OrderStatuses.PaidAndPaper]: { text: "Payment made", color: "green" },
  [OrderStatuses.Complete]: { text: "Complete", color: "green" },
  [OrderStatuses.CompletedAndVoid]: { text: "Complete", color: "green" },
  [OrderStatuses.OutOfStock]: {
    text: "Currently unavailable",
    color: "red",
  },
  [OrderStatuses.OutOfStockAndPaper]: {
    text: "Currently unavailable",
    color: "red",
  },
  [OrderStatuses.NoPaperAndPaymentMissed]: { text: "Suspended", color: "red" },
  [OrderStatuses.PaperAndPaymentMissed]: { text: "Suspended", color: "red" },

  [OrderStatuses.Archived]: { text: "Archived", color: "red" },
  [OrderStatuses.Void]: { text: "Void", color: "red" },
  [OrderStatuses.Cancelled]: { text: "Cancelled", color: "red" },
  Canceled: { text: "Cancelled", color: "red" },
};

Object.freeze(ProductStatusMapper);

export default Product;
