import React from 'react'

const Spinner2 = ({ color = "white", width = "37" }) => {
  return (
    <svg className='spinner_icon' width="140" height="96" viewBox="0 0 140 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="69.5" cy="48" r="42.5" stroke="#F6F6F6" strokeWidth="10" />
      <mask id="path-2-inside-1_1431_1223" fill="white">
        <path d="M101.534 75.8748C103.632 77.7006 103.87 80.9046 101.832 82.7978C96.1295 88.0964 89.1912 91.9307 81.6147 93.9291C72.4092 96.3572 62.6865 95.9631 53.7077 92.7979C44.7289 89.6327 36.9085 83.8424 31.2608 76.1782C25.6131 68.514 22.3987 59.3295 22.0347 49.8161C21.6707 40.3027 24.1739 30.8995 29.2194 22.8261C34.265 14.7527 41.6201 8.38182 50.3308 4.53977C59.0415 0.697721 68.7056 -0.438166 78.0698 1.27946C85.7768 2.69313 92.9878 5.98605 99.0786 10.8334C101.255 12.5654 101.263 15.7781 99.3104 17.7589C97.3578 19.7397 94.1865 19.7307 91.9616 18.0615C87.3461 14.5986 81.9727 12.2356 76.2526 11.1864C68.8741 9.833 61.2592 10.728 54.3956 13.7554C47.532 16.7827 41.7365 21.8027 37.7608 28.1641C33.7851 34.5256 31.8128 41.9349 32.0996 49.431C32.3864 56.9271 34.9191 64.164 39.3693 70.2031C43.8194 76.2421 49.9816 80.8046 57.0564 83.2987C64.1312 85.7927 71.7923 86.1032 79.0458 84.19C84.669 82.7068 89.8461 79.9401 94.1836 76.1347C96.2744 74.3004 99.4358 74.049 101.534 75.8748Z" />
      </mask>
      <path d="M101.534 75.8748C103.632 77.7006 103.87 80.9046 101.832 82.7978C96.1295 88.0964 89.1912 91.9307 81.6147 93.9291C72.4092 96.3572 62.6865 95.9631 53.7077 92.7979C44.7289 89.6327 36.9085 83.8424 31.2608 76.1782C25.6131 68.514 22.3987 59.3295 22.0347 49.8161C21.6707 40.3027 24.1739 30.8995 29.2194 22.8261C34.265 14.7527 41.6201 8.38182 50.3308 4.53977C59.0415 0.697721 68.7056 -0.438166 78.0698 1.27946C85.7768 2.69313 92.9878 5.98605 99.0786 10.8334C101.255 12.5654 101.263 15.7781 99.3104 17.7589C97.3578 19.7397 94.1865 19.7307 91.9616 18.0615C87.3461 14.5986 81.9727 12.2356 76.2526 11.1864C68.8741 9.833 61.2592 10.728 54.3956 13.7554C47.532 16.7827 41.7365 21.8027 37.7608 28.1641C33.7851 34.5256 31.8128 41.9349 32.0996 49.431C32.3864 56.9271 34.9191 64.164 39.3693 70.2031C43.8194 76.2421 49.9816 80.8046 57.0564 83.2987C64.1312 85.7927 71.7923 86.1032 79.0458 84.19C84.669 82.7068 89.8461 79.9401 94.1836 76.1347C96.2744 74.3004 99.4358 74.049 101.534 75.8748Z" stroke="#00a372" strokeWidth="38" mask="url(#path-2-inside-1_1431_1223)" />
    </svg>


  )
}

export default Spinner2
