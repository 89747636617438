import React from "react";

/**
 * This card is used on multiple pages to
 * display details about patients, caretakers and doctors.
 * To use it you need to pass title and content as props.
 *
 * content should be a list of objects.
 * content = [{title: String, text=String}]
 */
function YourDetailsCard({ content }) {
  return (
    <div className="user_details">
      {content.map((item) => (
        <div key={item.title} className="user_details__row">
          <p className="label--caps--normal">{item.title}</p>

          <p className="p4--normal">{item.text}</p>

          {item.customContent && (
            item.customContent
          )}
        </div>
      ))}
    </div>
  );
}

export default YourDetailsCard;
