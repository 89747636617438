import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FullScreenOverlay } from "../../overlays";
import { PrimaryCta } from "../../buttons";
import { VerificationCard } from "../../cards";
import { ProgressBar } from "../../onboarding";

import { setPatientId } from "../../../store/slices/doctorOnboardingSlice";

import { Logo } from "../../../assets/images";
import { CloseIcon, CrossFailIcon } from "../../../assets/icons";

function StepperHeader({ currentStep, closeWithPopup, totalSteps, hideProgressBar, hideClose }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);

  function closePopup() {
    if (closeWithPopup) {
      setShowPopup(true);

      return;
    }

    setShowPopup(false);
    closeOnboarding();
  }

  function closeOnboarding() {
    resetDataForOnboarding();
    setShowPopup(false);
    history.goBack();
    // if we are on the onboarding page for when the admin creates an account for the user
    // and user has to confirm his account there is no history to go back to so we do
    // history.push() in order to go to the login screen
    if (history.location.pathname.includes('onboarding/')) {
      history.push('/auth/login')
    }

  }

  function resetDataForOnboarding() {
    dispatch(setPatientId(undefined));
  }

  function handleLogoClick() {
    if (closeWithPopup) {
      setShowPopup(true);

      return;
    }

    setShowPopup(false);
  }

  return currentStep === 0 ? null : (
    <header className="onboarding_header">
      <div className="onboarding_header__content">
        <Logo onClick={handleLogoClick} color="black" />

        {!hideClose &&
          <div className="onboarding_header__close" onClick={closePopup}>
            <p className="label">Close</p>
            <div className="close_icon">
              <CloseIcon />
            </div>
          </div>
        }

        <FullScreenOverlay show={showPopup}>
          <VerificationCard
            onClick={() => setShowPopup(false)}
            cardTitle="Are you sure?"
            Image={CrossFailIcon}
            cardText="If you quit now, you will have to restart this process again"
          >
            <div>
              <PrimaryCta
                text="Cancel"
                color="orange"
                onClick={() => setShowPopup(false)}
              />
            </div>
            <div>
              <PrimaryCta
                text="Confirm"
                color="white"
                onClick={closeOnboarding}
              />
            </div>
          </VerificationCard>
        </FullScreenOverlay>
      </div>

      {(currentStep !== -1 && !hideProgressBar) ? (
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      ) : (
        <div className="onboarding_border_bottom"></div>
      )}
    </header>
  );
}

export default StepperHeader;
