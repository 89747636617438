import React, { useEffect } from "react";
import { TertiaryCta } from "../../../components/buttons";
import { trackAnalyticsEvent } from "../../../util/util";

function CarerDoYouHavePrescription({ setNextStep, steps }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="do_you_have_prescription">
      <div className="do_you_have_prescription__content">
        <div className="do_you_have_prescription__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Do you have a prescription?</h1>
          <p className="p5">
            To get you set up, we'll need a copy of your prescription. If your
            doctor is submitting this to us directly, we will send you an email
            on next steps as soon as we have recieved it. Otherwise, tell us
            which applies to you
          </p>
        </div>
        <div className="do_you_have_prescription__buttons">
          <div>
            <TertiaryCta
              color="white"
              onClick={() => {
                setNextStep(steps.carerPrescription);
                trackAnalyticsEvent("Carer Onboarding", "Carer Have Prescription: True");
              }}
              text="I have a prescription"
            />
          </div>
          <div>
            <TertiaryCta
              color="white"
              onClick={() => {
                setNextStep(steps.carerCriteria)
                trackAnalyticsEvent("Carer Onboarding", "Carer Have Prescription: False");
              }}
              text="I need a prescription"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CarerDoYouHavePrescription;
