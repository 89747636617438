import React from "react";

function UnlinkIcon() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="40" fill="#13ae7d" />
      <g clip-path="url(#clip0_3332_58143)">
        <path d="M42.0591 38.9413C40.9669 37.8496 39.4859 37.2363 37.9417 37.2363C36.3975 37.2363 34.9165 37.8496 33.8244 38.9413L29.7057 43.0586C28.6136 44.1508 28 45.6321 28 47.1766C28 48.7212 28.6136 50.2025 29.7057 51.2946C30.7979 52.3868 32.2792 53.0003 33.8237 53.0003C35.3683 53.0003 36.8496 52.3868 37.9417 51.2946L40.0004 49.2359" stroke="#13ae7d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M37.9414 43.0586C39.0335 44.1503 40.5145 44.7635 42.0587 44.7635C43.6029 44.7635 45.0839 44.1503 46.1761 43.0586L50.2947 38.9412C51.3869 37.8491 52.0005 36.3678 52.0005 34.8232C52.0005 33.2787 51.3869 31.7974 50.2947 30.7052C49.2026 29.6131 47.7213 28.9995 46.1767 28.9995C44.6322 28.9995 43.1509 29.6131 42.0587 30.7052L40.0001 32.7639" stroke="#13ae7d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3332_58143">
          <rect width="32" height="32" fill="white" transform="translate(24 24.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnlinkIcon;
