import React from "react";
import DesktopAccountHeader from "./DesktopAccountHeader/DesktopAccountHeader";
import MobileAccountHeader from "./MobileAccountHeader/MobileAccountHeader";

function Header(props) {
  return (
    <>
      <DesktopAccountHeader color={props.color} />
      <MobileAccountHeader color={props.color} />
    </>
  );
}

export default Header;
