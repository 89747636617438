import React from "react";

function ArrowLeftIcon({ color = "black", ...props }) {
  return (
    <div {...props}>
      <svg
        style={{ display: "inline" }}
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 1.5L1.5 8L7.5 14.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default ArrowLeftIcon;
