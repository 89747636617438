import React, { useState } from "react";

import ProductDetails from "../ProductDetails/ProductDetails";
import ProductDescription from "../ProductDescription/ProductDescription";
import { Label } from "../../../../../buttons";

import { MinusIcon, PlusIcon } from "../../../../../../assets/icons";
import { ReactComponent as Cartridge } from "../../../../../../assets/images/Cartridge.svg";
import { ReactComponent as Flower } from "../../../../../../assets/images/Flower.svg";
import { ReactComponent as Oil } from "../../../../../../assets/images/Oil.svg";
import { ProductType } from "../../../../../../enums";

const ProductTypeToIconMapper = Object.freeze({
  [ProductType.Flower]: Flower,
  [ProductType.Cartridge]: Cartridge,
  [ProductType.Oil]: Oil,
});

function DesktopProduct({
  name,
  productStatus = { text: "", color: "" },
  id,
  productDetailsBottom,
  productDetailsTop,
  description,
  type,
}) {
  const [showBody, setShowBody] = useState(false);

  const ProductIcon = ProductTypeToIconMapper[type];

  return (
    <div className="desktop_product">
      <div className="desktop_product__row">
        <div>
          <ProductIcon />
        </div>
        <div className="desktop_product__content">
          <div className="desktop_product__title">
            <p className="p3--semi-bold">{name}</p>
            <Label text={productStatus.text} color={productStatus.color} />

            <div
              className="desktop_product__button m-l-s"
              onClick={() => setShowBody(!showBody)}
            >
              {showBody ? (
                <>
                  <p className="p6--semi--bold">Hide detail</p>
                  <MinusIcon />
                </>
              ) : (
                <>
                  <p className="p6--semi--bold">View detail</p>
                  <PlusIcon />
                </>
              )}
            </div>
          </div>
          <ProductDetails
            key={`d1${id}`}
            content={productDetailsTop}
            applyLetterSpacing
          />
        </div>
      </div>
      <ProductDescription
        key={`d2${id}`}
        showBody={showBody}
        productDetailsBottom={productDetailsBottom}
        description={description}
      />
    </div>
  );
}

export default DesktopProduct;
