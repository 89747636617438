import React from 'react'
import { CloseIcon } from '../../../assets/icons'
import { FullScreenOverlay } from '../../overlays'

const TermsAndConditionsPopup = ({
    show,
    content,
    title,
    onClose = () => null,
}) => {
    return (
        <FullScreenOverlay show={show}>

            <div className='terms_and_conditions_popup' >
                <div className="verification_card">
                    <div className='terms_and_conditions_popup__header' >
                        <div className='terms_and_conditions_popup__header__title' >
                            {title}
                        </div>
                        <div onClick={() => { onClose() }} className='terms_and_conditions_popup__header__close' >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="terms_and_conditions_popup__content">
                        {content}
                    </div>
                </div>
            </div>


        </FullScreenOverlay>
    )
}

export default TermsAndConditionsPopup
