import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import { ArrowLeftIcon, ArrowRightIcon, LearnIcon, MoreInfoIcon } from "../../../assets/icons";
import UniversalTooltip from "../../tooltips/UniveralTooltip";

const ReusableTable = ({
  header,
  body,
  loadingComponent = { component: Spinner },
  loadingData,
  noDataComponent = { component: noDataDefaultComponent },
  resetPaginationDependencies = [],
  pageSize = 25,
  isSearchActive = false,
  bodyLength = null,
  resetFilter = () => { },
  contentForTheTooltip = null,
  currentPage = 1,
  onPageChange = () => { },

  requestSort = null,
  sortConfig = {},
}) => {
  const [page, setPage] = useState(currentPage);

  const totalPages = Math.ceil(body.length / pageSize);
  const dataLength = bodyLength || body.length

  if (isSearchActive) {
    noDataComponent = { component: noSearchResultsComponent }
  } else
    noDataComponent = { component: noDataDefaultComponent }

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line
  }, [...resetPaginationDependencies]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };


  function renderTableHead() {
    // .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
    const renderRowClass = (item) => {
      return `table-col-${item.column * 2} ${item.left && "left"} ${item.right && "right"} ${item.isSortable ? "isSortable" : ''}`;
    }

    let tooltipIndex = 0;
    return header.map((item, index) => (
      <th
        key={index}
        className={renderRowClass(item)}
        onClick={() => item.isSortable ? requestSort(item.text) : {}}
      >
        {item.text}
        {item.isSortable && sortConfig.key === item.text ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : null}
        {item.tooltip &&
          <UniversalTooltip
            hoverableContent={<MoreInfoIcon size={15} />}
            left={100}
            contentForTheTooltip={contentForTheTooltip[tooltipIndex++]}
          />
        }
      </th>
    ));
  }

  const renderTableRow = (cells) => {
    return (
      cells.map((cell, index) =>
        <td
          key={index}
          className={renderRowClass(cell)}
          onClick={(e) => { if (cell.onClick) { cell.onClick(e) } }}
        >
          {cell.text}{cell?.components}
        </td>)
    )
  }

  const renderTableBody = (rows) => {
    return (
      rows.slice((page - 1) * pageSize, page * pageSize).map((item, index) => {
        let classNameForRow = "";
        classNameForRow = item[0] ? item[0].classNameForRow : ""
        const onRowClick = item[0].onRowClick ? item[0].onRowClick : () => null;
        return (
          <tr onClick={(e) => { onRowClick(e) }} className={`${classNameForRow}`} key={index}>
            {renderTableRow(item)}
          </tr>
        )
      })
    )
  }

  const renderRowClass = (item) => `table-col-${item.column * 2} ${item.left ? "left" : ""} ${item.right ? "right" : ""} ${item.cellClasses ? item.cellClasses : ''}`;

  const paginationButtons = Array(totalPages)
    .fill('1')
    .map((p, i) => {
      return (
        <span key={i} className={page === i + 1 ? 'active_page' : ''} onClick={(e) => {
          e.stopPropagation()
          handlePageChange(i + 1)
          if (i + 1 === totalPages) {
            window.scrollTo(0, 0)
          }
        }}>{i + 1} </span>
      )
    })

  const renderPagination = () => {
    if (totalPages <= 3) {
      return paginationButtons
    }
    if (totalPages - 2 < page && totalPages >= page) {
      return paginationButtons.slice(totalPages - 3, totalPages)
    }
    return paginationButtons.slice(page - 1, page + 2)
  }

  return (
    <table className='reusable_table'>
      <thead>
        <tr>
          {renderTableHead()}
        </tr>
      </thead>
      <tbody>
        {
          loadingData ?
            <tr>
              <td className='table-col-24 loading_row'> <loadingComponent.component /> </td>
            </tr>
            :
            dataLength ? renderTableBody(body) : <noDataComponent.component />
        }
      </tbody>
      <tfoot>
        <tr>
          <td className="reusable_table__pagination">
            <button disabled={page === 1} type='button' onClick={(e) => {
              e.stopPropagation()
              handlePageChange(page - 1)
            }
            }
            >
              <ArrowLeftIcon />
            </button>

            {renderPagination()}

            {page !== totalPages && page < totalPages - 2 && <span  >... </span>}
            <button
              disabled={page === totalPages}
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                handlePageChange(page + 1)
                if (page + 1 === totalPages) window.scrollTo(0, 0)
              }}>
              <ArrowRightIcon />
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default ReusableTable;

const noDataDefaultComponent = () => {
  return <tr>
    <td className='table-col-24 loading_row'> TABLE IS EMPTY </td>
  </tr>
}

const noSearchResultsComponent = () => {
  return <tr>
    <td className='table-col-24 loading_row'>
      <div>
        <span>NO RESULTS FOUND</span>
        <br />
        <span>WITH THE SELECTED FILTERS</span>
      </div>
    </td>
  </tr>
}