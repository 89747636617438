import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FooterSocialMedia from "../FooterSocialMedia/FooterSocialMedia";

import { selectUser } from "../../../../store/slices/authenticationSlice";
import { setLogoutModal } from "../../../../store/slices/modalsSlice";

import { ArrowDownIcon, ArrowUpIcon } from "../../../../assets/icons";
import gmpc from "../../../../assets/images/gphc_logo_1440.png";
import lyphe from "../../../../assets/images/lyphe-group-mobile-logo.png";
import nhsLogo from "../../../../assets/images/nhs-logo.svg";
import { termsAndConditionsUrl } from '../../../../config.json'
import { privacyPolicyUrl } from '../../../../config.json'
import { learnUrl, patientFeedback } from '../../../../config.json'
import { complaintsUrl } from '../../../../config.json'
import { nhsPrescriptionServicesUrl } from '../../../../config.json'
import registered_pharmacy from "../../../../assets/images/registered_pharmacy.png"
import { getCurrentYear } from "../../../../util/util";

function AccountFooterMobile(props) {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const [showMenu1, setShowMenu1] = useState(true);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);

  function toggleShowMenu(menu, index) {
    switch (index) {
      case 1:
        setShowMenu1(!menu);
        break;
      case 2:
        setShowMenu2(!menu);
        break;
      case 3:
        setShowMenu3(!menu);
        break;
      default:
        setShowMenu1(!menu);
    }
  }

  function showMenu(menu, index) {
    return menu ? (
      <div onClick={() => toggleShowMenu(menu, index)}>
        <ArrowUpIcon />
      </div>
    ) : (
      <div onClick={() => toggleShowMenu(menu, index)}>
        <ArrowDownIcon />
      </div>
    );
  }

  return (
    <footer className="footer_account_mobile">
      <div className="footer_account_mobile__group">
        <div className="footer_account_mobile__navigation">
          <div className="navigation__group">
            <div className="navigation__group__title">
              <p>About</p>
              {showMenu(showMenu1, 1)}
            </div>
            {showMenu1 && (
              <ul>
                <li>
                  <Link to="/profile">
                    My profile
                  </Link>
                </li>
                <li>
                  <a href={learnUrl}>
                    Learn
                  </a>
                </li>
                <li>
                  <a href={patientFeedback}
                    target="_blank"
                    rel="noopener noreferrer">
                    Help us improve
                  </a>
                </li>
                <li>
                  <Link to="/support">
                    Support
                  </Link>
                </li>
                <li onClick={() => dispatch(setLogoutModal({ show: true }))}>
                  <Link to="#">
                    Log out
                  </Link>
                </li>
              </ul>
            )}
          </div>

          <div className="navigation__group">
            <div className="navigation__group__title">
              <p>Help & Support</p>
              {showMenu(showMenu2, 2)}
            </div>
            {showMenu2 && (
              <ul>
                <li>
                  <a href={complaintsUrl}>
                    Complaints
                  </a>
                </li>
                <li>
                  <a href={nhsPrescriptionServicesUrl}>
                    Prescription services
                  </a>
                </li>
              </ul>
            )}
          </div>

          <div className="navigation__group">
            <div className="navigation__group__title">
              <p>Legal</p>
              {showMenu(showMenu3, 3)}
            </div>
            {showMenu3 && (
              <ul>
                <li>
                  <a href={termsAndConditionsUrl}>
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href={privacyPolicyUrl}>
                    Privacy policy
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="footer_account_mobile__group">
        <FooterSocialMedia />
      </div>

      <div className="footer_account_mobile__group footer_part_of">
        <div>
          <img src={lyphe} alt="" />
        </div>
      </div>

      <div className="footer_account_mobile__group border-top">
        <div className="footer_gmpc">
          <img src={gmpc} alt="General pharmaceutical council logo" />
          <p style={{ marginTop: "20px" }} className="label--smaller">We are regulated by GPhC</p>
          <img style={{ marginTop: "10px" }} src={registered_pharmacy} alt="registered pharmacy logo" />
          <p className="p6--semi-bold">Superintendent pharmacist:</p>
          <p className="p6">Mehraan Sattar
          </p>
          <a href="https://www.pharmacyregulation.org/registers/pharmacist/name/sattar/mehraan" className="p6">GPHC Number: 2211738
          </a>
          <p style={{ marginTop: "16px" }} className="p6--semi-bold">Registered address:</p>
          <p className="p6">
            Lyphe Dispensary
          </p>
          <p className="p6">
            Unit 12 Farnborough Business Centre, Eelmoor Road, Farnborough, Hampshire
          </p>
          <p className="p6">
            GU14 7XA
          </p>
        </div>
      </div>
      <p className="copy_right">Copyright © {getCurrentYear()} Lyphe</p>
    </footer>
  );
}

export default AccountFooterMobile;
