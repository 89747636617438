import React from 'react'
import { useHistory } from 'react-router-dom';
import AkarArrowClockwise from '../../assets/icons/AkarArrowClockwise';
import { PrimaryCta } from '../../components/buttons';

export default function NoPreviousMedication() {
    const history = useHistory()

    const handleLogoClick = () => {
        history.push("/prescriptions/active");
    }
    return (
        <div className='repeat_prescription--content'>
            <div className='content_heading'>
                <AkarArrowClockwise />
                <h1>No past prescriptions</h1>
            </div>
            <div className='content_text'>
                In order to request a repeat prescription from your clinic, you need to have at least one completed prescription within the patient portal.
                <br></br>
                <br></br>
                If you were expecting to be able to request a repeat, please contact your clinic directly.
            </div>

            <div className='content_button'>
                <PrimaryCta
                    text={"Back to account"}
                    color='white'
                    className='primary_cta--fit_content'
                    onClick={handleLogoClick}
                />
            </div>
        </div>
    )
}
