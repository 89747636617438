import React from "react";

function ReusableBadge({ text, children, className = "" }) {
  return (
    <div className={`reusable_badge ${className}`} >
      {text} {children}
    </div>
  );
}

export default ReusableBadge;