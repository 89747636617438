import React from "react";
import { AccountHeader } from "../../../components/headers";
import { UserDetailsCard } from "../../../components/cards";
import ChangePasswordCard from "../../../components/cards/ChangePasswordCard/ChangePasswordCard";
import { AccountFooter } from "../../../components/footers";
import { useSelector } from "react-redux";
import {
  selectUserDetails,
} from "../../../store/slices/accountSlice";
import { selectUser } from "../../../store/slices/authenticationSlice";

function OrganisationEmployeeProfile(props) {

  const organisationEmployee = useSelector(selectUserDetails);

  const user = useSelector(selectUser);

  return (
    <section className="profile">
      <AccountHeader color="green" />
      <div className="profile__banner">
        <div>
          <h1 className="h2-a">My profile</h1>
          <p className="p4">
            Here you can find your account details.
          </p>
        </div>
      </div>

      <div className="profile__body">
        <div className="profile__content">
          <div className="profile__cards">
            <div className="user_details__header">
              <p className="p3--semi-bold">Your details</p>
            </div>


            <div>
              <UserDetailsCard content={[{ title: "Full Name", text: `${organisationEmployee?.firstName} ${organisationEmployee?.lastName}` }]} />
              <ChangePasswordCard email={user?.email} />
            </div>

          </div>
        </div>
      </div>
      <div className="profile__footer">
        <AccountFooter />
      </div>
    </section>
  );
}

export default OrganisationEmployeeProfile;