import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "..";

import {
  selectShowTracker,
  selectTrackedOrderIndex,
  setShowMobileTracker,
  setTrackingOrderIndex,
} from "../../../store/slices/prescriptionsSlice";

function TrackingButton({ order }) {
  const dispatch = useDispatch();

  const trackedOrderIndex = useSelector(selectTrackedOrderIndex);
  const showTracker = useSelector(selectShowTracker);

  const isOrderTracked = order.index === trackedOrderIndex;

  return (
    <div className="h-40">
      <PrimaryCta
        className={isOrderTracked & showTracker ? "disabled_button" : ""}
        text={
          isOrderTracked && showTracker ? "Tracking order" : "Track my order"
        }
        color="orange"
        onClick={() => {
          dispatch(setTrackingOrderIndex(order.index));
          dispatch(setShowMobileTracker(true));
        }}
      />
    </div>
  );
}

export default TrackingButton;
