import React from "react";
import { useSelector } from "react-redux";
import { MoreInfoIcon } from "../../../../../assets/icons";
import { selectUser } from "../../../../../store/slices/authenticationSlice";
import { GenericBanner, SplitOrderBanner } from "../../../../banners";
import { QuaternaryCta } from "../../../../buttons";
import AreTheseDetailsCorrectButton from "../../../../buttons/AreTheseDetailsCorrectButton/AreTheseDetailsCorrectButton";

import ProductDetails from "../../OrderProducts/Product/ProductDetails/ProductDetails";

function DesktopOrderHeader({
  buttons = { left: [], right: [] },
  content = {},
  productDetails = {},
  OrderStatusIcon = null,
  isOrderTracked = false,
  order,
  numberOfSplitOrders,
  indexOfSplitOrder
}) {
  const user = useSelector(selectUser);

  const className = `desktop_order__header__top${isOrderTracked ? " tracked" : ""
    } ${user?.role === 3 ? 'desktop_order__header__top--nopt' : ''}`;

  return (
    <div className={`desktop_order__header `}>

      {user?.role === 3 &&
        <div className='desktop_order__header__report'>
          <QuaternaryCta
            text="Report a problem"
            icon={<MoreInfoIcon size={17} />}
            link={`/report/${order.orderId}`}
          />
        </div>
      }

      <div className={className}>
        <div className="desktop_order__header__icon">
          <OrderStatusIcon />
        </div>
        <div className="desktop_order__header__top__text">
          <h5 className="h5-a--serif">{content.title}</h5>
          <div className="desktop_order__header__details">
            <div className="desktop_order__header__details__left">
              <ProductDetails content={productDetails} />
            </div>
          </div>
        </div>
      </div>

      <div className="desktop_order__header__text">
        <div className="p5">{content.text}</div>
      </div>

      {(user?.role === 1 || user?.role === 2) ? <AreTheseDetailsCorrectButton /> : ''}
      {
        order.isSplit && (
          <SplitOrderBanner numberOfSplitOrders={numberOfSplitOrders} splitOrderIndex={indexOfSplitOrder} />
        )
      }

      <div className="desktop_order__header__buttons">
        <div className="desktop_order__header__buttons__left">
          {buttons.left}
        </div>
        <div className="desktop_order__header__buttons__right">
          {buttons.right}
        </div>
      </div>
    </div>
  );
}

export default DesktopOrderHeader;
