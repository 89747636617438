import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import {
  selectIsIdVerified,
  selectIsVerified,
} from "../store/slices/accountSlice";
import { selectUser } from "../store/slices/authenticationSlice";

function ProtectedRoute({
  component: Component,
  checkEmail = true,
  checkId = true,
  ...rest
}) {
  const isEmailVerified = useSelector(selectIsVerified);
  const isIDVerified = useSelector(selectIsIdVerified);
  const user = useSelector(selectUser);

  // this is just in case this check runs before
  // the user data is fetched from the API
  if (
    user &&
    ((checkEmail && isEmailVerified === undefined) ||
      (checkId && isIDVerified === undefined))
  )
    return null;

  // explicit check in case it's undefined/null
  if (checkEmail && isEmailVerified === false)
    return <Redirect to="/emailnotverified" />;

  // explicit check in case it's undefined/null
  if (checkId && isIDVerified === false) return <Redirect to="/verification" />;

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { referrer: props.location.pathname }
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
