import React from 'react'

function RepatPrescriptionFormArrow() {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7903 7.6129L4.70711 7.70711C4.34662 8.06759 3.77939 8.09532 3.3871 7.79029L3.29289 7.70711L0.292894 4.70711L0.251496 4.66315L0.196335 4.59531L0.124671 4.48406L0.0712255 4.37133L0.0358451 4.26599L0.0069078 4.11809L3.49691e-07 4L0.00278795 3.92476L0.0202402 3.79927L0.0497383 3.68786L0.0936741 3.57678L0.145996 3.47929L0.219689 3.37455L0.292894 3.29289L3.29289 0.292893C3.68342 -0.0976312 4.31658 -0.0976312 4.70711 0.292893C5.06759 0.653377 5.09532 1.22061 4.7903 1.6129L4.70711 1.70711L3.415 3L11 3C11.5523 3 12 3.44772 12 4C12 4.55228 11.5523 5 11 5L3.415 5L4.70711 6.29289C5.06759 6.65338 5.09532 7.22061 4.7903 7.6129L4.70711 7.70711L4.7903 7.6129Z" fill="#defdf3" />
        </svg>

    )
}
export default RepatPrescriptionFormArrow
