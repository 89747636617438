import React from "react";

function GreenCircle5(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#13ae7d" />
            <path d="M22.792 15.642H16.762V19.224C17.014 18.888 17.386 18.606 17.878 18.378C18.382 18.15 18.916 18.036 19.48 18.036C20.488 18.036 21.304 18.252 21.928 18.684C22.564 19.116 23.014 19.662 23.278 20.322C23.554 20.982 23.692 21.678 23.692 22.41C23.692 23.298 23.518 24.09 23.17 24.786C22.834 25.47 22.324 26.01 21.64 26.406C20.968 26.802 20.14 27 19.156 27C17.848 27 16.798 26.676 16.006 26.028C15.214 25.38 14.74 24.522 14.584 23.454H16.582C16.714 24.018 17.008 24.468 17.464 24.804C17.92 25.128 18.49 25.29 19.174 25.29C20.026 25.29 20.662 25.032 21.082 24.516C21.514 24 21.73 23.316 21.73 22.464C21.73 21.6 21.514 20.94 21.082 20.484C20.65 20.016 20.014 19.782 19.174 19.782C18.586 19.782 18.088 19.932 17.68 20.232C17.284 20.52 16.996 20.916 16.816 21.42H14.872V13.842H22.792V15.642Z" fill="black" />
        </svg>

    );
}

export default GreenCircle5;
