import React from "react";

import { InputField, CustomSelect } from "../../inputs";

export const titleOptions = [
  { text: "Ms", value: "Ms" },
  { text: "Mr", value: "Mr" },
  { text: "Miss", value: "Miss" },
  { text: "Mrs", value: "Mrs" },
  { text: "Master", value: "Master" },
];

function NameForm({ title, firstName, lastName }) {
  return (
    <>
      {title && <div className="form-spacing">
        <CustomSelect
          {...title}
          id="title"
          name="Title"
          type="text"
          options={titleOptions}
        />
      </div>
      }
      <div className="form-spacing">
        <InputField
          {...firstName}
          id="firstName"
          name="First name"
          type="text"
        />
      </div>
      <div className="form-spacing">
        <InputField {...lastName} id="lastName" name="Last name" type="text" />
      </div>
    </>
  );
}

export default NameForm;
