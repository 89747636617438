import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetPatient,
  selectPatient,
  setName,
} from "../../../store/slices/patientOnboardingSlice";
import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import { NameForm } from "../../../components/forms";
import { nameValidator } from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";
import {
  selectCarer,
  setCarerName,
} from "../../../store/slices/carerOnboardingSlice";

function PatientName({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const patient = useSelector(selectPatient);
  const carer = useSelector(selectCarer);

  const title = useProps(patient.title || "");
  const firstName = useProps(
    patient.firstName || "",
    nameValidator("First name")
  );
  const lastName = useProps(patient.lastName || "", nameValidator("Last name"));

  const nameForm = { firstName, lastName, title };

  const isButtonEnabled = isFormValid(nameForm);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function clickedNext() {
    // if doing "customer complete account"
    // if patient, set patient name and go to patient age check
    if (patient.secretId) {
      const payload = propsToValues(nameForm);

      dispatch(setName(payload));

      trackAnalyticsEvent("Patient Onboarding", "Patient Name Added");

      setNextStep(steps.patientAgeCheck);

      return;
    }

    // if carer, set carer name and go to carer patient age check
    if (carer.secretId) {
      const payload = propsToValues(nameForm);

      dispatch(setCarerName(payload));
      dispatch(resetPatient());

      setNextStep(steps.carerPatientAgeCheck);

      return;
    }

    const payload = propsToValues(nameForm);
    dispatch(setName(payload));
    setNextStep(steps.whoIsItFor);
  }

  return (
    <section className="name">
      <div className="name__content">
        <div className="name__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Let’s start by taking your name</h1>
        </div>

        <form className="name__form">
          <NameForm {...nameForm} />
        </form>
        <div className="name__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={clickedNext}
            text="Next"
          />
        </div>
      </div>
    </section>
  );
}

export default PatientName;
