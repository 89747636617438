import React from "react";
import { useHistory } from "react-router-dom";

function QuaternaryCta({
  text = "",
  link = "",
  icon = null,
  color = "black",
  isEnabled = true,
  onClick = () => null,
}) {
  const history = useHistory();

  function beforeOnClick() {
    if (link) history.push(link);

    onClick();
  }

  return (
    <button
      className={`quaternary_cta quaternary_cta--${color}`}
      onClick={beforeOnClick}
      disabled={!isEnabled}
    >
      <span>{icon}</span>
      <span style={{ fontWeight: "400" }} className="label">
        {text}
      </span>
    </button>
  );
}

export default QuaternaryCta;
