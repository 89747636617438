import React from "react";

function FullScreenOverlay({ show, children, onClick = () => null, transparent = true }) {
  return (
    <div
      className={`full_screen_overlay ${show ? "full_screen_overlay--show" : ""
        } ${transparent === false ? "full_screen_overlay--white" : ""
        }`}
    >
      <div
        className={`full_screen_overlay__content
     ${show ? "full_screen_overlay__content--show" : ""}
      `}
        onClick={() => { onClick() }}
      >
        {children}
      </div>
    </div>
  );
}

export default FullScreenOverlay;
