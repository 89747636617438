import React from 'react'
import RepeatPrescriptionExclamationMark from '../../../assets/icons/RepeatPrescriptionExclamationMark'

export default function RepeatPrescriptionErrorBanner({ text }) {
  return (
    <div className='repeat_prescription-banner-error'>
      <div style={{ minWidth: "24px" }}>
        <RepeatPrescriptionExclamationMark />
      </div>
      <p>{text}</p>
    </div>
  )
}
