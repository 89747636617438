import React from "react";

function WhatHappensNextCard({ text = "", title = "", image = null }) {
  return (
    <div className="what_happens_next_card">
      <div className="what_happens_next_card__image">
        <img src={image} alt="" />
      </div>
      <div className="what_happens_next_card__title">
        <p className="p4-a">{title}</p>
        <p className="p5">{text}</p>
      </div>
    </div>
  );
}

export default WhatHappensNextCard;
