import React from "react";

function SuccessIcon(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#51C478" />
      <path
        d="M36.6495 52C36.1021 52 35.3721 51.8209 35.0071 51.4627L26.0647 45.194C24.9697 44.2985 24.6047 42.6866 25.5172 41.4328C26.0647 40.7164 26.7947 40.3582 27.7071 40.3582C28.2546 40.3582 28.8021 40.5373 29.3496 40.8955L36.2845 45.7313L51.4319 28.7164C51.9794 28.1791 52.7094 28 53.2569 28C53.9868 28 54.5343 28.1791 55.0818 28.7164C56.1768 29.791 56.3593 31.403 55.2643 32.4776L38.657 51.1045C38.1095 51.6418 37.3795 52 36.6495 52Z"
        fill="black"
      />
    </svg>
  );
}

export default SuccessIcon;
