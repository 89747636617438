import React from "react";

function CrossFailIcon(props) {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#ff5405"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path d="M50,97C24,97,3,76,3,50S24,3,50,3s47,21,47,47S76,97,50,97z M50,7C26.3,7,7,26.3,7,50s19.3,43,43,43s43-19.3,43-43  S73.7,7,50,7z"></path>
      <path d="M50.2,56c-1.1,0-2-0.9-2-2V31.5c0-1.1,0.9-2,2-2s2,0.9,2,2V54C52.2,55.1,51.3,56,50.2,56z"></path>
      <circle cx="50.2" cy="67.7" r="4.5"></circle>
    </svg>
  );
}

export default CrossFailIcon;
