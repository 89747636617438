import React from "react";

function RadioButton({
  id,
  name = "",
  htmlSpecificName = "",
  value = false,
  onClick = () => null,
  isEnabled = true,
}) {
  return (
    <label htmlFor={id} className="radio">
      <input
        disabled={!isEnabled}
        id={id}
        name={htmlSpecificName ? htmlSpecificName : name}
        checked={value}
        onClick={onClick}
        onChange={() => null}
        type="radio"
        className="input--hidden"
      />
      <span className="inner-label"></span>
      <span className="option-text">{name}</span>
    </label>
  );
}

export default RadioButton;
