import { apiClient } from "../ApiClient";
import { createFormData } from "../util/util";
import { usersConverter } from "../converters/UsersConverter";

class OnboardingService {
  async registerCarer(carer) {
    carer = usersConverter.carerClientToApi(carer);
    const carerFormData = createFormData(carer);

    const response = await apiClient.registerCarer(carerFormData);

    return response;
  }

  async registerPatient(patient) {
    patient = usersConverter.patientClientToApi(patient);
    const patientFormData = createFormData(patient);

    const response = await apiClient.registerPatient(patientFormData);

    return response;
  }

  async registerDoctor(doctor) {
    const doctorFormData = createFormData(
      usersConverter.doctorClientToApi(doctor)
    );

    const response = await apiClient.registerDoctor(doctorFormData);

    return response;
  }

  // when doctor adds a patient
  async createPatient(patient, doctorId, token) {
    const patientPayload = { ...patient, doctorId };
    const patientFormData = createFormData(
      usersConverter.doctorsPatientClientToApi(patientPayload)
    );

    const createdPatient = await apiClient.createPatient(patientFormData);

    return createdPatient;
  }

  async checkGmc(gmc) {
    const formData = createFormData({ gmc });

    return apiClient.checkGmc(formData);
  }

  async checkCqc(clinicName) {
    const formData = createFormData({ clinicName });

    return apiClient.checkCqc(formData);
  }

  async getPatientDataForCompleteAccount(secretId) {
    const patient = await apiClient.getPatientDataForCompleteAccount(secretId);

    return usersConverter.patientCompleteAccountApiToClient(patient);
  }

  async completePatientAccount(patient) {
    const patientForApi = usersConverter.patientClientToApi(patient);
    const formData = createFormData(patientForApi);

    const response = await apiClient.completePatientAccount(formData);

    return response;
  }

  async checkPatientDobClinicNumber(payload) {
    const response = await apiClient.checkPatientDobClinicNumber(payload);

    return response;
  }

  async completeCarerAccount(carer) {
    const carerForApi = usersConverter.carerClientToApi(carer);
    const formData = createFormData(carerForApi);

    const response = await apiClient.completeCarerAccount(formData);

    return response;
  }
}

export const onboardingService = new OnboardingService();
