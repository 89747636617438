import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContactNumberForm } from "../../../components/forms";
import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import {
  selectPatient,
  setTelephoneNo,
} from "../../../store/slices/patientOnboardingSlice";
import {
  areaCodeValidator,
  phoneNumberValidator,
} from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";

function PatientContactNumber({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const patient = useSelector(selectPatient);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const areaCode = useProps(
    patient?.contactNumber?.areaCode || "",
    areaCodeValidator()
  );
  const number = useProps(
    patient?.contactNumber?.number || "",
    phoneNumberValidator()
  );
  const contactNumberForm = { number, areaCode };
  const isButtonEnabled = isFormValid({ number });

  function nextClicked() {
    dispatch(setTelephoneNo(propsToValues(contactNumberForm)));

    trackAnalyticsEvent("Patient Onboarding", "Patient contact added");
    setNextStep(steps.patientCheckDetails);
  }

  return (
    <section className="contact_numbers">
      <div className="contact_numbers__content">
        <div className="contact_numbers__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please tell us your contact number</h1>
          <p className="p5">
            What is the best contact number for us to use incase we need to
            contact you to complete your order?
          </p>
        </div>
        <div className="contact_numbers__form form-spacing">
          <ContactNumberForm {...contactNumberForm} />
        </div>
        <div className="contact_numbers__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            text="Next"
            onClick={nextClicked}
          />
        </div>
      </div>
    </section>
  );
}

export default PatientContactNumber;
