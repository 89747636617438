import React from "react";

function HamburgerIcon({ color = "#1e1e1e" }) {
  return (
    <svg
      width="24"
      height="10"
      viewBox="0 0 24 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="2" rx="1" fill={color} />
      <rect y="8" width="24" height="2" rx="1" fill={color} />
    </svg>
  );
}

export default HamburgerIcon;
