import React from "react";

function MessageIcon({ color = "black" }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6667 1.7987C14.6667 0.992031 14.0067 0.332031 13.2 0.332031H1.46667C0.66 0.332031 0 0.992031 0 1.7987V10.5987C0 11.4054 0.66 12.0654 1.46667 12.0654H11.7333L14.6667 14.9987V1.7987Z" fill="#3B5EDB" />
    </svg>
  );
}

export default MessageIcon;


