import React from "react";

import PastPrescriptionOrderHeader from "./PastPrescriptionOrderHeader/PastPrescriptionOrderHeader";
import OrderProducts from "../Order/OrderProducts/OrderProducts";

import { OrderStatuses } from "../../../enums";

import {
  OrderStatusMedicationReadyIcon,
  OrderStatusPrescriptionExpiredIcon,
} from "../../../assets/icons";

const OrderStatusToPastPrescriptionBannerMapper = {
  [OrderStatuses.Complete]: {
    Icon: OrderStatusMedicationReadyIcon,
    text: "This order has been completed",
  },
  [OrderStatuses.CompletedAndVoid]: {
    Icon: OrderStatusMedicationReadyIcon,
    text: "This order has been completed",
  },
  [OrderStatuses.Archived]: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This order has been cancelled",
  },
  [OrderStatuses.Void]: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This prescription is expired",
  },
  [OrderStatuses.NotApproved]: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This prescription is void",
  },
  [OrderStatuses.Shipped]: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This prescription is void",
  },
  [OrderStatuses.PaperAndPaymentMissed]: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "Payment deadline missed",
  },
  null: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This prescription is void",
  },
  undefined: {
    Icon: OrderStatusPrescriptionExpiredIcon,
    text: "This prescription is void",
  },
};

function PreviousPrescriptionOrder({ order }) {
  const test = OrderStatusToPastPrescriptionBannerMapper[order.status];
  return (
    <div className="previous__order">
      <PastPrescriptionOrderHeader
        title={test.text}
        Icon={test.Icon}
        order={order}
      />

      <OrderProducts order={order} />
    </div>
  );
}

export default PreviousPrescriptionOrder;
