import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseIcon } from '../../assets/icons'
import AttentionIconToast from '../../assets/icons/AttentionIconToast'
import TickIconSuccessToast from '../../assets/icons/TickIconSuccessToast'
import { toastTypes } from '../../enums'
import { selectToast, setToast } from '../../store/slices/toastSlice'

const Toast = () => {
    const dispatch = useDispatch()
    const toast = useSelector(selectToast)

    useEffect(() => {

        const timeout = setTimeout(() => {

            dispatch(setToast({ showToast: false }));
        }, 3000)
        return () => {
            clearTimeout(timeout)
        }
        // eslint-disable-next-line
    }, [toast.showToast, toast.message])

    const onClose = () => {
        dispatch(setToast({ showToast: false }))
    }

    const resolveIcon = () => {
        return toastTpeToIconMapper[toast.type];
    }

    const resolveClass = () => {
        return 'toast__side ' + toastTypeToClassMapper[toast.type];
    }

    const resolveContainerClass = () => {
        return "toast" + toastTypeToContainerClassMapper[toast.type]
    }

    return (
        <>
            {toast.showToast &&
                <div className={resolveContainerClass()}>
                    <div className={resolveClass()}></div>
                    <div className='toast__content'>
                        <div className='toast__content__icon'>
                            {resolveIcon()}
                        </div>
                        <div className='toast__content__text'>
                            <h5>{toast.title}</h5>
                            <div className='toast__content__text__message'>
                                {toast.message}
                            </div>
                        </div>
                        <div className='toast__content__close'>
                            <div onClick={onClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Toast

const toastTpeToIconMapper = {
    [toastTypes.Error]: <AttentionIconToast />,
    [toastTypes.Success]: <TickIconSuccessToast />,
}

const toastTypeToClassMapper = {
    [toastTypes.Error]: 'toast__side--error',
    [toastTypes.Success]: 'toast__side--success',
}

const toastTypeToContainerClassMapper = {
    [toastTypes.Error]: '--error',
    [toastTypes.Success]: '--success'
}