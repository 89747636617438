import React from "react";

function MoreInfoIcon({ color = "#353434", size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        d="M10.9783 7.046C10.9783 6.77533 11.0717 6.55133 11.2583 6.374C11.445 6.19667 11.6923 6.108 12.0003 6.108C12.3083 6.108 12.5557 6.19667 12.7423 6.374C12.9383 6.55133 13.0363 6.77533 13.0363 7.046C13.0363 7.31667 12.9383 7.536 12.7423 7.704C12.5557 7.872 12.3083 7.956 12.0003 7.956C11.6923 7.956 11.445 7.872 11.2583 7.704C11.0717 7.536 10.9783 7.31667 10.9783 7.046ZM12.7983 9.286V17H11.2023V9.286H12.7983Z"
        fill="white"
      />
    </svg>
  );
}

export default MoreInfoIcon;
