import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContactNumberForm } from "../../../components/forms";
import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import {
  setContactNumber,
  selectCarer,
} from "../../../store/slices/carerOnboardingSlice";
import {
  areaCodeValidator,
  phoneNumberValidator,
} from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";

function CarerContactNumber({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const carer = useSelector(selectCarer);

  const areaCode = useProps(
    carer?.contactNumber?.areaCode || "+44",
    areaCodeValidator()
  );
  const number = useProps(
    carer?.contactNumber?.number || "",
    phoneNumberValidator()
  );
  const contactNumberForm = { areaCode, number };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function nextClicked() {
    const payload = propsToValues(contactNumberForm);
    dispatch(setContactNumber(payload));

    trackAnalyticsEvent("Carer Onboarding", "Carer Contract added");
    setNextStep(steps.carerCheckDetails);
  }

  const isButtonEnabled = isFormValid(contactNumberForm);

  return (
    <section className="contact_numbers">
      <div className="contact_numbers__content">
        <div className="contact_numbers__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please tell us your contact number</h1>
          <p className="p5">
            What is the best contact number for us to use incase we need to
            contact you to complete your order?
          </p>
        </div>
        <div className="contact_numbers__form">
          <ContactNumberForm {...contactNumberForm} />
        </div>
        <div className="contact_numbers__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={nextClicked}
            text="Next"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerContactNumber;
