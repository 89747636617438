import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { selectUser } from "../../../store/slices/authenticationSlice";
import {
  selectLastLoggedIn,
  selectUserDetails,
} from "../../../store/slices/accountSlice";
import { setLogoutModal } from "../../../store/slices/modalsSlice";

import { ArrowDownIcon, LearnIcon } from "../../../assets/icons";
import { learnUrl, patientFeedback } from '../../../config.json'
import { UserRoles } from "../../../enums";
import { callMapper } from "../../../util/util";


function MyAccountDropdown({ textColor }) {
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetails);
  const lastLoggedIn = useSelector(selectLastLoggedIn);
  const user = useSelector(selectUser);

  const divContainer = useRef(null);

  const [showPopup, setShowPopup] = useState(false);

  const links = callMapper(linksToUserMapper, user?.role, []);

  // add event listener only if the popup was rendered
  useEffect(() => {
    if (!divContainer.current) {
      return;
    }

    document.addEventListener("click", handleClick, false);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  });

  function handleClick(e) {
    // hide popup only if he clicked outside of
    // the popup
    if (!divContainer.current.contains(e.target)) {
      setShowPopup(false);
    }
  }

  const myAccountContentForDoctorCarerPatient = () => {
    return <>
      <h1 className="p5--bold">
        {userDetails?.firstName} {userDetails?.lastName}
      </h1>
      <p className="p6">Last login: {lastLoggedIn}</p>
    </>
  }

  const myAccountContentForClinicUser = () => {
    return <>
      <h1 className="my_account_popup__head__title">
        {userDetails?.firstName} {userDetails?.lastName}
      </h1>
      <p className="my_account_popup__head__small_text">Last login: {lastLoggedIn}</p>
    </>
  }

  const myAccountContentMapper = {
    [UserRoles.Carer]: myAccountContentForDoctorCarerPatient,
    [UserRoles.OrganisationEmployee]: myAccountContentForClinicUser,
    [UserRoles.Patient]: myAccountContentForDoctorCarerPatient,
    [UserRoles.ClinicUser]: myAccountContentForClinicUser,
  }

  return (
    <div className="my_account_popup">
      <li
        onClick={() => setShowPopup(true)}
        className="my_account_popup__header"
      >
        My account
        <span>
          <ArrowDownIcon color={textColor} />
        </span>
      </li>

      {showPopup ? (
        <>
          <div className="up-arrow" />
          <div
            className="my_account_popup__content"
            ref={(el) => {
              divContainer.current = el;
            }}
          >
            <div className="my_account_popup__head">
              {callMapper(myAccountContentMapper, user.role, () => <></>)()}
            </div>
            <div className="my_account_popup__body">
              {[UserRoles.Carer, UserRoles.Patient].includes(user.role) &&
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={learnUrl}
                >
                  <div className="my_account_popup__learn">
                    <p>Learn</p>
                    <div>
                      <LearnIcon />
                    </div>
                  </div>
                </a>
              }
              {[UserRoles.Carer, UserRoles.Patient].includes(user.role) &&
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={patientFeedback}
                >
                  <div className="my_account_popup__learn">
                    <p>Help us improve</p>
                    <div>
                      <LearnIcon />
                    </div>
                  </div>
                </a>
              }
              {links.map((item) => (
                <Link
                  key={item.link}
                  className="my_account_popup__item"
                  to={item.link}
                >
                  {item.text}
                </Link>
              ))}

              <div
                onClick={() => dispatch(setLogoutModal({ show: true }))}
                style={{
                  borderBottom: "none",
                  borderRadius: "0 0 10px 10px",
                }}
                className="my_account_popup__item"
              >
                <p>Log out</p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

const patientCarerLinks = [
  // { text: "Refer a friend", link: "/refer" },
  { text: "My profile", link: "/profile" },
  { text: "Support", link: "/support" },
];

const clinicUserLinks = [
  { text: "My profile", link: "/profile" },
];

const linksToUserMapper = {
  [UserRoles.OrganisationEmployee]: clinicUserLinks,
  [UserRoles.Patient]: patientCarerLinks,
  [UserRoles.Carer]: patientCarerLinks
}

export default MyAccountDropdown;
