import React from "react";

function TextArea({
  id = "",
  name = "",
  type = "text",
  value = "",
  onChange = () => null,
  isEnabled = true,
}) {
  const labelPosition = value ? "label-top" : "";
  const labelColor = isEnabled ? "" : "label-disabled";
  const labelClass = `${labelColor} ${labelPosition}`;

  return (
    <div className="textarea-container">
      <textarea
        autoComplete="off"
        disabled={!isEnabled}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
      />

      <label className={labelClass} htmlFor={id}>
        {name}
      </label>
      <div
        className={`animated-border--black--textarea ${value ? "animated-border--show" : ""
          }`}
        style={{ top: "99%" }}
      ></div>
    </div>
  );
}

export default TextArea;
