import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { PrimaryCta } from '../../../components/buttons'
import { setShowRepeatPrescriptionForm } from '../../../store/slices/repeatPrescriptionSlice';

export default function RepeatPrescriptionFormGuideline() {
    const dispatch = useDispatch();

    const questions = [
        { key: 'question1', text: "Since your last dispensed prescription have you been diagnosed with a new medical condition by your GP, a hospital specialist, or another healthcare professional?" },
        { key: 'question2', text: "Since your last dispensed prescription have you been prescribed any new medication by your GP, a hospital specialist, or another healthcare professional?" },
        { key: 'question3', text: "Since your last dispensed prescription have you had any tests (scans, x-rays, blood tests) for any condition and if so were any of the results abnormal?" },
        { key: 'question4', text: "Since your last dispensed prescription has your primary condition for which you were prescribed CBMP's worsened?" },
    ];

    const initialAnswers = questions.reduce((acc, question) => {
        acc[question.key] = '';
        return acc;
    }, {});

    const initialSelections = questions.reduce((acc, question) => {
        acc[question.key] = ''; // '' indicates no selection
        return acc;
    }, {});

    const [selections, setSelections] = useState(initialSelections);
    const [answers, setAnswers] = useState(initialAnswers);
    const [showTextBox, setShowTextBox] = useState(
        questions.reduce((acc, question) => {
            acc[question.key] = false;
            return acc;
        }, {})
    );

    const handleRadioChange = (event, questionKey) => {
        const { value } = event.target;
        setSelections({ ...selections, [questionKey]: value });
        setShowTextBox({ ...showTextBox, [questionKey]: value === 'yes' });
    };

    const handleTextChange = (event, questionKey) => {
        const { value } = event.target;
        setAnswers({ ...answers, [questionKey]: value });
    };    

    const handleNext = () => {
        dispatch(setShowRepeatPrescriptionForm(answers));
    }

    const [allAnswered, setAllAnswered] = useState(false);

    useEffect(() => {
        const allQuestionsAnswered = Object.values(selections).every(
            (value) => value === 'yes' || value === 'no'
        );
        setAllAnswered(allQuestionsAnswered);
    }, [selections]);

    return (
        <div className='guideline'>
            <h1>Request a repeat prescription</h1>
            <div className='divider'></div>
            <div className='guideline--top-content'>
                <h2>Before you request...</h2>
                <p><span>1</span>You need to have had at least 2 appointments with your doctor</p>
                <p><span>2</span>You are not due a follow-up</p>
                <p><span>3</span>You must answer the following questions</p>
                <div className='questionnaire'>
                    {questions.map(({ key, text }) => (
                        <div key={key} className='questionnaire--item'>
                            <span>
                                <span>{text}</span>
                                <div>
                                    <input
                                        type="radio"
                                        name={key}
                                        value="yes"
                                        onChange={(e) => handleRadioChange(e, key)}
                                    /> Yes
                                    <input
                                        type="radio"
                                        name={key}
                                        value="no"
                                        onChange={(e) => handleRadioChange(e, key)}
                                    /> No
                                </div>
                                {showTextBox[key] && (
                                    <textarea
                                        value={answers[key]}
                                        onChange={(e) => handleTextChange(e, key)}
                                        placeholder="If yes, please provide details"
                                    />
                                )}
                                <hr />
                            </span>
                        </div>))}

                </div>
                <p className='p-link'>If you don't meet the above, please</p>
                <a className='link' target="_blank" href='https://connect-medical-cannabis.pabau.me/index.php?compid=8379'>book a follow-up</a>
            </div>
            <div className='guideline--bottom-content'>
                <p><span></span>Where a medication is unavailable, you will be prescribed a suitable alternative.</p>
                <p><span></span>We may call you to confirm some details about your request.</p>
            </div>
            <div className='form_button'>
                <PrimaryCta
                    text={"Next"}
                    color='form'
                    className='primary_cta--form'
                    onClick={handleNext}
                    isEnabled={allAnswered}
                />
            </div>
        </div>
    )
}
