import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { AuthHeader } from "../../components/headers";
import { InputField } from "../../components/inputs";
import { PrimaryCta } from "../../components/buttons";

import {
  requestPasswordReset,
  selectAccountLoading,
} from "../../store/slices/accountSlice";

import { useProps } from "../../hooks";
import { emailValidator } from "../../util/validators";

function RequestPasswordReset(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(selectAccountLoading);

  const email = useProps("", emailValidator());

  const isButtonEnabled = !loading && email.isValid();

  async function resetLinkClicked() {
    const { error } = await dispatch(requestPasswordReset({ email: email.value.trim() }));

    if (error) {
      history.push("/auth/resetPassword/fail");
      return;
    }

    history.push("/auth/resetPassword/emailSent");
  }

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <AuthHeader hideClose={true} />
      <div className="auth_wrapper">
        <section className="auth">
          <div className="auth__header">
            <h1 className="h4-p-a">Password reset</h1>
            <p className="p5">
              Enter the email address you registered with and if it exists on
              our platform, we’ll send you a reset link.
            </p>
          </div>

          <form className="auth__form">
            <div>
              <InputField {...email} type="email" name="Enter your email" />
            </div>
          </form>

          <div className="auth__buttons">
            <div className="h-48">
              <PrimaryCta
                text="Get reset link"
                onClick={resetLinkClicked}
                isEnabled={isButtonEnabled}
              />
            </div>

            <div>
              <Link className="text_link" to="/auth/login">
                Remembered it? Back to login
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default RequestPasswordReset;
