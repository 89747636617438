import React from "react";

import { QuaternaryCta } from "..";
import { DownloadIcon } from "../../../assets/icons";

function DownloadInvoiceButton({ order }) {
  return (
    <div>
      <QuaternaryCta
        text="Download invoice"
        icon={<DownloadIcon />}
        onClick={() => window.open(order.invoiceFilePath, "_blank")}
      />
    </div>
  );
}

export default DownloadInvoiceButton;
