import React, { useEffect } from "react";
import { resolveAppendableClasses } from "../../../util/util";

/**
 * A generic input field.
 * Just pass these props: id, name, type, value, onChange.
 * value and onChange should be from useProps hook.
 *  @see useProps in the hooks folder.
 */
function InputField({
  name = "",
  type = "text",
  value = "",
  errors = [],
  isEnabled = true,
  onChange = () => null,
  onBlur = () => null,
  onEnter = () => null,
  className = "",
  appendableClassNames = ""
}) {
  // const errors = props.errors || [];
  // const isEnabled = props.isEnabled === undefined || props.isEnabled;

  const labelPosition = value ? "label-top" : "";
  const labelColor = isEnabled ? "" : "label-disabled";
  const labelClass = `${labelColor} ${labelPosition}`;

  function onKeyUp(e) {
    if (e.key === "Enter") onEnter();
  }

  useEffect(() => {
    //eslint-disable-next-line
    const x = value;
  }, [value]);

  return (
    <>
      <div className={`text-input-container ${className} ${resolveAppendableClasses("text-input-container", appendableClassNames)}`}>
        <input
          type={type}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          disabled={!isEnabled}
          autoComplete="off"
        />

        <label className={labelClass}>{name}</label>

        <div
          className={`animated-border--${errors.length > 0 ? "red" : "black"} ${value || errors.length > 0 ? "animated-border--show" : ""
            }`}
        ></div>

        {errors.length > 0 && <p className="text-input--error">{errors[0]}</p>}
      </div>
    </>
  );
}

export default InputField;
