import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { AgeSelect } from "../../../components/inputs";
import { useAgeSelect } from "../../../hooks";
import {
  setDateOfBirth,
  selectPatient,
} from "../../../store/slices/patientOnboardingSlice";
import { trackAnalyticsEvent } from "../../../util/util";

function PatientAgeCheck({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const patient = useSelector(selectPatient);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ageConfig, values, ageErrorMessage] = useAgeSelect(
    patient?.dateOfBirth?.day,
    patient?.dateOfBirth?.month,
    patient?.dateOfBirth?.year
  );

  const { day, month, year } = values;

  function confirmClicked() {
    dispatch(setDateOfBirth({ year, month, day }));

    trackAnalyticsEvent("Patient Onboarding", "Date Of Birth Added");

    if (patient?.secretId) {
      setNextStep(steps.patientAddress);

      return;
    }
    setNextStep(steps.patientDoYouHavePrescription);
  }

  const isButtonEnabled = day && month && year && !ageErrorMessage;

  return (
    <section className="is_over_18">
      <div className="is_over_18__content">
        <div className="is_over_18__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Confirm your age</h1>
          <p className="p5">
            For legal reasons, please confirm your date of birth
          </p>
        </div>
        <div className="is_over_18__form">
          <AgeSelect {...ageConfig} error={ageErrorMessage} />
        </div>
        <div className="is_over_18__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={confirmClicked}
            color="orange"
            text="Confirm"
          />
        </div>
      </div>
    </section>
  );
}

export default PatientAgeCheck;
