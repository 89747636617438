import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganisation,
  selectUserDetails,
  setCurrentOrganisation,
} from "../../../store/slices/accountSlice";
import Select from "@material-ui/core/Select";
import { ArrowDownIcon } from "../../../assets/icons";
import FormControl from "@material-ui/core/FormControl";

function OrganisationSwitchDropdown() {
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetails);
  const currentOrganisation = useSelector(selectCurrentOrganisation);

  const organisations = userDetails ? userDetails.organisations : [];

  const onOrganisationChange = (event) => {
    const newOrganisation = organisations.find(e => e.organisationId === parseInt(event.target.value))
    dispatch(setCurrentOrganisation(newOrganisation))
  }

  return (
    <div>
      {currentOrganisation.organisationId !== null &&
        <FormControl disabled={organisations.length < 2} style={{ minWidth: "250px", width: "100%" }} size="small" >
          <Select
            native
            value={currentOrganisation.organisationId}
            onChange={onOrganisationChange}
            variant="outlined"

            IconComponent={props =>
              <span {...props} style={{ margin: "3px" }}>
                <ArrowDownIcon color={organisations.length < 2 ? "grey" : "black"} />
              </span>
            }
          >
            {organisations.map(e =>
              <option className="option" key={e.organisationId} value={e.organisationId}>
                {e.organisationName}
              </option>
            )}
          </Select>
        </FormControl>}
    </div>
  )
}

export default OrganisationSwitchDropdown;