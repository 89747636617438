import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookie from "../../../assets/images/cookie.png";
import { acceptCookies, selectUserDetails } from "../../../store/slices/accountSlice";
import { PrimaryCta } from '../../buttons';
import { VerificationCard } from '../../cards';
import { FullScreenOverlay } from '../../overlays';

const AcceptCookiesPopup = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);

  const cookieIcon = () => {
    return <img src={Cookie} alt="cookie" />
  }

  const [showPopup, setShowPopup] = useState(!userDetails?.acceptedCookies);

  const acceptCookiesClicked = () => {
    dispatch(acceptCookies(userDetails.userId))
    setShowPopup(false);
  }
  return (
    <FullScreenOverlay show={showPopup}>
      <VerificationCard
        showClose={false}
        cardTitle="Cookie policy"
        Image={cookieIcon}
        cardText="Please accept cookies in order to continue ."
      >
        <div>
          <PrimaryCta
            text="Accept"
            color="orange"
            onClick={acceptCookiesClicked}
          />
        </div>
      </VerificationCard>
    </FullScreenOverlay>
  )
}

export default AcceptCookiesPopup
