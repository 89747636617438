import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AccountHeader } from "../../../../components/headers";
import { AccountBottomAppBar } from "../../../../components/account";
import ClinicsBottomBar from "../../../../components/account/ClinicsBottomBar/ClinicsBottomBar";
import ClinicsBottomBarLinks
  from "../../../../components/account/ClinicsBottomBar/ClinicBottomBarLinks/ClinicsBottomBarLinks";
import { useHistory, useParams } from "react-router-dom";
import {
  OrganisationPrescriptionsTypes,
  EmployeeType,
  OrderStatuses,
  TableHeaders,
  toastTypes,
} from "../../../../enums";
import { callMapper, sortByDateOdestToNewest } from "../../../../util/util";
import Spinner from "../../../../components/sharedComponents/Spinner/Spinner";
import ReusableTable from "../../../../components/sharedComponents/ReusableTable/ReusableTable";
import PaginatedReusableTable from "../../../../components/sharedComponents/ReusableTable/PaginatedReusableTable";
import SidePreview from "../../../../components/account/SidePreview/SidePreview";
import ReusableButton from "../../../../components/buttons/ReusableButton/ReusableButton";
import {
  getPrescriptionsForOrganisation,
  selectClinicsPrescriptions,
  selectClinicsPrescriptionsPaginationData,
  selectClinicsPrescriptionsLoadingFlag,
  selectSelectedPrescription,
  setInStateByKey,
  setSelectedPrescription,
  selectFromPrescriptionSliceByKey,
  selectClinicsFilteredPrescriptions,
  selectClinicFilterNumberOfChecked,
  selectIsGetStartedPagePresented, setProtoPrescriptionForPromoting, deleteProtoPrescription,
} from "../../../../store/slices/prescriptionsSlice";
import { useDispatch, useSelector } from "react-redux";
import PrescriptionPreview from "../../../../components/PrescriptionPreview/PrescriptionPreview";
import {
  CloseIcon,
  ExpandIcon,
  MoreInfoIcon,
  OrderStatusPaperNeededIcon,
} from "../../../../assets/icons";
import { UploadIcon } from "../../../../assets/icons";
import pdfPlaceholder from '../../../../assets/images/pdf_placeholder.jpg';
import moment from "moment";
import ReusableBadge from "../../../../components/sharedComponents/ReusableBadge/ReusableBadge";
import { InputField } from "../../../../components/inputs";
import UploadPrescriptionOrganisationModal
  from "../../../../components/modals/UploadPrescriptionClinicModal/UploadPrescriptionOrganisationModal";
import PrescriptionCardForSidePreview
  from "../../../../components/cards/PrescriptionCards/PrescriptionCardForSidePreview/PrescriptionCardForSidePreview";
import CustomTwoSidedModal from "../../../../components/modals/CustomTwoSidedModal/CustomTwoSidedModal";
import FormSectionTitle from "../../../../components/forms/FormSectionTitle/FormSectionTitle";
import PrescriptionRewriteRequiredCard
  from "../../../../components/cards/PrescriptionCards/PrescriptionCardForSidePreview/PrescriptionRewriteRequiredCard";
import Harmonika from "../../../../components/sharedComponents/Harmonika/Harmonika";
import GenericBanner from "../../../../components/banners/GenericBanner/GenericBanner";
import { selectCurrentOrganisation, selectUserDetails } from "../../../../store/slices/accountSlice";
import { PrimaryCta } from "../../../../components/buttons";
import XIcon from "../../../../assets/icons/XIcon";
import PromoteProtoPrescriptionModal
  from "../../../../components/modals/PromoteProtoPrescriptionModal/PromoteProtoPrescriptionModal";
import TrashBin from "../../../../assets/icons/TrashBin";
import { setToast } from "../../../../store/slices/toastSlice";
import SpinnerGrey from "../../../../components/sharedComponents/Spinner/SpinnerGrey";

function OrganisationsPrescriptions() {
  const { status = OrganisationPrescriptionsTypes.ImageOnly } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDisclaimerBannerSeen, setIsDisclaimerBannerSeen] = useState(!!localStorage.getItem("IsDisclaimerBannerSeen"));
  const [showPreview, setShowPreview] = useState(false);
  const [showUploadPrescriptionModal, setShowUploadPrescriptionModal] = useState(false);
  const [showPromoteProtoPrescriptionModal, setShowPromoteProtoPrescriptionModal] = useState(false);
  const [showPrescriptionPreviewModal, setShowPrescriptionPreviewModal] = useState(false);
  const [showDeletePrescriptionModal, setShowDeletePrescriptionModal] = useState(false);
  const [currentPrescription, setCurrentPrescription] = useState(null);
  const activePrescription = useSelector(selectSelectedPrescription);

  const hideDisclaimerBanner = () => {
    setIsDisclaimerBannerSeen(true);
    localStorage.setItem("IsDisclaimerBannerSeen", "true");
  }

  const statusToSelectorMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: OrganisationPrescriptionsTypes.ImageOnly,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: OrganisationPrescriptionsTypes.ReWriteRequests,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: OrganisationPrescriptionsTypes.ToBeAssigned,
    [OrganisationPrescriptionsTypes.Active]: OrganisationPrescriptionsTypes.Active,
    [OrganisationPrescriptionsTypes.Complete]: OrganisationPrescriptionsTypes.Complete,
  }

  const statusToSortFieldMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: "id",
    [OrganisationPrescriptionsTypes.ReWriteRequests]: "rewriteDate",
    [OrganisationPrescriptionsTypes.ToBeAssigned]: "createdDate",
    [OrganisationPrescriptionsTypes.Active]: "createdDate",
    [OrganisationPrescriptionsTypes.Complete]: "createdDate",
  }

  const statusToSortDirectionMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: 0,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: 1,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: 0,
    [OrganisationPrescriptionsTypes.Active]: 1,
    [OrganisationPrescriptionsTypes.Complete]: 1,
  }

  const prescriptions = useSelector(selectClinicsPrescriptions(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));
  const paginationData = useSelector(selectClinicsPrescriptionsPaginationData(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));
  const loadingPrescriptions = useSelector(selectClinicsPrescriptionsLoadingFlag(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));
  const clinicSearchSelectedValue = useSelector(selectFromPrescriptionSliceByKey("clinicSearchSelectedValue"));
  const clinicSearchParameter = useSelector(selectFromPrescriptionSliceByKey("clinicSearchParameter"));
  const clinicSearchType = useSelector(selectFromPrescriptionSliceByKey("clinicSearchType"));


  const numberOfImageOnly = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ImageOnly)).totalCount;
  const numberOfToBeAssigned = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ToBeAssigned)).totalCount;
  const numberOfActive = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.Active)).totalCount;
  const numberOfComplete = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.Complete)).totalCount;
  const numberOfReWriteRequests = useSelector(selectClinicsPrescriptionsPaginationData(OrganisationPrescriptionsTypes.ReWriteRequests)).totalCount;

  const filteredPrescriptions = useSelector(selectClinicsFilteredPrescriptions(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));
  const clinicFilterNumberOfChecked = useSelector(selectClinicFilterNumberOfChecked(callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)));

  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const userDetails = useSelector(selectUserDetails);

  const isGetStartedPagePresented = useSelector(selectIsGetStartedPagePresented)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    if (currentOrganisation.organisationId > 0) {
      const statusPar = callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)

      dispatch(getPrescriptionsForOrganisation({
        organisationId: currentOrganisation.organisationId,
        organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
          ? userDetails.id
          : null,
        status: statusPar,
        parameter: clinicSearchParameter,
        type: clinicSearchType,
        pageParams: {
          currentPage: newPage,
          pageSize: pageSize,
          sortColumnName: callMapper(statusToSortFieldMapper, status, ""),
          sortDirection: callMapper(statusToSortDirectionMapper, status, 0)
        }
      }))
    }
  }

  const isRowActive = (prescription) => {
    return activePrescription?.index === prescription.index ? "active-row" : ""
  }

  useEffect(() => {
    if (isGetStartedPagePresented)
      history.push("/organisation/getstarted");
  }, [isGetStartedPagePresented])

  useEffect(() => {
    //only fetch data if there is no selected value in the search field, otherwise we want to keep the current state
    if (currentOrganisation.organisationId === null) {
      history.push("/organisation/noaccess")
    }

    if (!clinicSearchSelectedValue && currentOrganisation.organisationId > 0) {
      const statusPar = callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)

      dispatch(getPrescriptionsForOrganisation({
        organisationId: currentOrganisation.organisationId,
        organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
          ? userDetails.id
          : null,
        status: statusPar,
        pageParams: {
          currentPage: currentPage,
          pageSize: pageSize,
          sortColumnName: callMapper(statusToSortFieldMapper, status, ""),
          sortDirection: callMapper(statusToSortDirectionMapper, status, 0)
        }
      }))
    }

    // eslint-disable-next-line
  }, [status, currentOrganisation])

  useEffect(() => {
    function onOutsideClick() {
      setShowPreview(false)
    }

    window.addEventListener("click", onOutsideClick)
    return () => {
      window.removeEventListener("click", onOutsideClick)
    }
  }, [])

  const setActivePrescription = (prescription) => {
    dispatch(setSelectedPrescription(prescription))
  }

  const onCloseSidePreview = () => {
    setShowPreview(!showPreview)
    dispatch(setSelectedPrescription(null))
  }

  const onRowClick = (e, prescription) => {
    e.stopPropagation()
    setCurrentPrescription(prescription)
    dispatch(setSelectedPrescription(prescription))
    setShowPreview(true);
  }



  const links = [
    {
      text: "Image Only",
      active: status === OrganisationPrescriptionsTypes.ImageOnly || status === undefined,
      searching: clinicSearchSelectedValue && !loadingPrescriptions,
      onClick: () => {
        setCurrentPage(1);
        history.push(`/clinicPrescriptions/${OrganisationPrescriptionsTypes.ImageOnly}`)
      },
      resultsCount: clinicSearchSelectedValue ?
        numberOfImageOnly
        : ""
    },
    {
      text: "Unassigned",
      active: status === OrganisationPrescriptionsTypes.ToBeAssigned,
      searching: clinicSearchSelectedValue && !loadingPrescriptions,
      onClick: () => {
        setCurrentPage(1);
        history.push(`/clinicPrescriptions/${OrganisationPrescriptionsTypes.ToBeAssigned}`)
      },
      resultsCount: clinicSearchSelectedValue ?
        numberOfToBeAssigned
        : ""
    },
    {
      text: "Active",
      active: status === OrganisationPrescriptionsTypes.Active,
      searching: clinicSearchSelectedValue && !loadingPrescriptions,
      onClick: () => {
        setCurrentPage(1);
        history.push(`/clinicPrescriptions/${OrganisationPrescriptionsTypes.Active}`)
      },
      resultsCount: clinicSearchSelectedValue ?
        numberOfActive
        : ""
    },
    {
      text: "Complete",
      active: status === OrganisationPrescriptionsTypes.Complete,
      searching: clinicSearchSelectedValue && !loadingPrescriptions,
      onClick: () => {
        setCurrentPage(1);
        history.push(`/clinicPrescriptions/${OrganisationPrescriptionsTypes.Complete}`)
      },
      resultsCount: clinicSearchSelectedValue ?
        numberOfComplete
        : ""
    },
    {
      text: "Re-write requests",
      active: status === OrganisationPrescriptionsTypes.ReWriteRequests,
      searching: clinicSearchSelectedValue && !loadingPrescriptions,
      onClick: () => {
        setCurrentPage(1);
        history.push(`/clinicPrescriptions/${OrganisationPrescriptionsTypes.ReWriteRequests}`)
      },
      resultsCount: clinicSearchSelectedValue ?
        numberOfReWriteRequests
        : ""
    },
  ]

  const linksForBottomClinicsBar = () => {
    return <ClinicsBottomBarLinks links={links} />
  }

  const renderBadge = (prescription) => {
    const textAndClass = callMapper(statusToBadgeTextForActiveMapper, prescription.status, { text: "undefined", className: "" })
    return <ReusableBadge className={textAndClass.className} text={textAndClass.text} />
  }

  const uploadPrescriptionsForBottomClinicsBar = () => {
    return <ReusableButton
      className="reusable_button--upload"
      text={"Upload Prescriptions"}
      icon={<UploadIcon color="white" />}
      onClick={() => {
        dispatch(setInStateByKey({ key: "foundPatientForClinicUser", data: false }))
        setShowUploadPrescriptionModal(!showUploadPrescriptionModal)
      }
      }
    />
  }

  const loadingComponent = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Spinner color={"#13ae7d"} />
      <span className='m-t-s'>LOADING PRESCRIPTIONS</span>
    </div>
  }

  const viewButton = (prescription) => {
    return <ReusableButton
      appendableClassnames={'view_button_hover'}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentPrescription(prescription)
        setActivePrescription(prescription);
        setShowPrescriptionPreviewModal(true)
      }}
    >
      View
    </ReusableButton>
  }

  const thumbnail = (src) => {
    let isPdf = src?.substring(src.lastIndexOf('.')).startsWith('.pdf')
    return <div style={{ maxWidth: '40px', maxHeight: '45px', height: "45px" }} >
      <img alt={""} style={{ maxWidth: '40px', maxHeight: '45px', height: "45px" }} src={isPdf ? pdfPlaceholder : src} />
    </div>
  }

  const constructTableRowForImageOnly = (prescription) => {
    return [
      {
        text: thumbnail(prescription.filePath),
        column: 1,
        classNameForRow: `${isRowActive(prescription)} hoverable`,
        onRowClick: (e) => { onRowClick(e, prescription) }
      },
      {
        text: prescription.prescriptionId || "N/A",
        column: 1,
        left: true
      },
      {
        text: prescription.prescriptionUploadDate || "N/A",
        column: 1,
        left: true
      },
      {
        text: prescription.organisationEmployee ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}` : "N/A",
        column: 8,
        left: true
      },
      {
        text: viewButton(prescription),
        column: 1,
      },
    ]
  }


  const constructTableRowForToBeAssigned = (prescription) => {
    return [
      {
        text: thumbnail(prescription.filePath),
        column: 1,
        classNameForRow: `${isRowActive(prescription)} hoverable`,
        onRowClick: (e) => { onRowClick(e, prescription) }
      },
      {
        text: prescription.prescriptionId || "N/A",
        column: 1,
        left: true
      },
      {
        text: prescription.prescriptionUploadDate || "N/A",
        column: 1,
        left: true
      },
      {
        text: prescription.organisationEmployee ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}` : "N/A",
        column: 3,
        left: true
      },
      {
        text: prescription.isT21Patient ? "YES" : "NO",
        column: 5,
        left: true,
      },
      {
        text: viewButton(prescription),
        column: 1,
      },
    ]
  }

  const constructTableRowForRewrites = (prescription) => {
    return [
      {
        text: moment(prescription.rewriteDateTime).format("DD/MM/YYYY"),
        column: 1,
        classNameForRow: `${isRowActive(prescription)} hoverable`,
        onRowClick: (e) => { onRowClick(e, prescription) }
      },
      {
        text: prescription.patient ? `${prescription.patient.firstName} ${prescription.patient.lastName}` : "N/A",
        column: 1.5,
        left: true
      },
      {
        text: prescription?.patient?.clinicIdNumber ? prescription?.patient?.clinicIdNumber : "N/A",
        column: 2,
        left: true
      },
      {
        text: prescription.paperPrescriptionId || "N/A",
        column: 2,
        left: true
      },
      {
        text: prescription.organisationEmployee ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}` : "N/A",
        column: 1.5,
        left: true,
      },
      {
        text: prescription.rewriteReason || "N/A",
        column: 3,
        left: true,
      },
      {
        text: viewButton(prescription),
        column: 1,
      },
    ]
  }

  const constructTableRowForActive = (prescription) => {
    return [
      {
        text: prescription.createdAt,
        column: 1,
        classNameForRow: `${isRowActive(prescription)} hoverable`,
        onRowClick: (e) => { onRowClick(e, prescription) }
      },
      {
        text: prescription.patient ? `${prescription.patient.firstName} ${prescription.patient.lastName}` : "N/A",
        column: 2,
        left: true
      },
      {
        text: prescription.paperPrescriptionId || "N/A",
        column: 2,
        left: true
      },
      {
        text: prescription.orderId || "N/A",
        column: 2,
        left: true
      },
      {
        text: prescription.organisationEmployee ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}` : "N/A",
        column: 2,
        left: true,
      },
      {
        text: renderBadge(prescription),
        column: 2,
        left: true,
      },
      {
        text: viewButton(prescription),
        column: 1,
      },
    ]
  }

  const rowConstructorMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: constructTableRowForImageOnly,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: constructTableRowForToBeAssigned,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: constructTableRowForRewrites,
    [OrganisationPrescriptionsTypes.Active]: constructTableRowForActive,
    [OrganisationPrescriptionsTypes.Complete]: constructTableRowForActive,
  }

  const constructTableRow = (prescription) => {
    return callMapper(rowConstructorMapper, status, constructTableRowForImageOnly)(prescription)
  }


  const constructTableBody = (prescriptions) => {
    return prescriptions.map((prescription => {
      return constructTableRow(prescription)
    }))
  }

  const buttonForLightBox = () => {
    return <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        setShowPrescriptionPreviewModal(true)
      }}>
      <ExpandIcon />
    </div>
  }

  const renderPatientInfoInputs = () => {
    const patient = currentPrescription?.patient;
    return <>
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Title
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"First name"} value={patient?.title} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        First name
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"First name"} value={patient?.firstName} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Last name
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Last name" className={""} value={patient?.lastName} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Clinic patient ID
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Clinic Patient ID" className={""} value={patient?.clinicIdNumber || "N/A"} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Email
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Email" className={""} value={patient?.user?.email} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Date of birth
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Dob" className={""} value={moment(patient?.dateOfBirth).format("DD/MM/YYYY")} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Contact number
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={patient?.telephoneNo} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Address line 1
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={patient?.address1} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Address line 2
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={patient?.address2} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Post code
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={patient?.zipCode} />
    </>
  }

  const renderPatientInfoInHarmonicaForSidePreview = () => {
    return currentPrescription?.patient &&
      <>
        <div className={"side_preview_content__informations__section_title"}>
          Patient
        </div>
        <Harmonika className={"m-t-s"} title={<>{currentPrescription?.patient.firstName} {currentPrescription?.patient.lastName} &nbsp; <span style={{ fontWeight: "300", color: "#B3B3B3", fontSize: "14px" }} >{currentPrescription?.patient.clinicIdNumber} </span> </>} >
          {renderPatientInfoInputs()}
        </Harmonika>
      </>
  }

  const renderTempPatientInfoInHarmonicaForSidePreview = () => {
    const patient = currentPrescription?.tempPatient;

    return patient &&
      <>
        <div className={"side_preview_content__informations__section_title"}>
          Patient
        </div>
        <Harmonika className={"m-t-s"} title={`Temp patient`} >
          <>
            <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
              Clinic patient ID
            </div>
            <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Clinic Patient ID" className={""} value={patient?.clinicIdNumber || "N/A"} />
            <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
              Email
            </div>
            <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Email" className={""} value={patient?.email} />
            <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
              Contact number
            </div>
            <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={patient?.telephoneNo} />
          </>
        </Harmonika>
      </>
  }

  const renderPrescriptionInfoSectionForSidePreview = () => {
    return <>
      <div className={"side_preview_content__informations__section_title"}>
        Prescription
      </div>
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
        Number
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"Number"} value={currentPrescription?.paperPrescriptionId || "N/A"} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xs "}>
        Date of expiry
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Date of expiry" className={""} value={currentPrescription?.expirationDateTime ? moment(currentPrescription?.expirationDateTime).format("DD/MM/YYYY") : "N/A"} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xs "}>
        Date prescribed
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Date prescribed" className={""} value={currentPrescription?.prescribedDateTime ? moment(currentPrescription?.prescribedDateTime).format("DD/MM/YYYY") : "N/A"} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xs "}>
        Date uploaded
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Date uploaded" className={""} value={currentPrescription?.createdAt} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xs "}>
        T21
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="T21" className={""} value={currentPrescription?.isT21Patient ? "Yes" : "No"} />
      <div className={"side_preview_content__informations__input_label m-t-s m-b-xs "}>
        Paper copy received
      </div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="T21" className={""} value={currentPrescription?.isPaperPrescriptionReceived ? "Yes" : "No"} />
    </>
  }

  const renderPrescriberSectionForSidePreviev = () => {
    return <>
      <div className={"side_preview_content__informations__section_title"}>
        Prescriber
      </div>
      <div className={"side_preview_content__informations__input_label"}>
        {currentPrescription?.prescribedBy}
      </div>
    </>
  }

  const renderEnvelopeTrackingCodeForSidePreview = () => {
    return <>
      {currentPrescription?.envelopeTrackingCode !== null &&
        <>
          <div className={"side_preview_content__informations__section_title"}>
            Envelope Tracking code
          </div>

          <div className={"side_preview_content__informations__input_label"}>
            {currentPrescription?.envelopeTrackingCode}
          </div>
        </>
      }
    </>
  }

  const renderRewriteRequiredCard = (prescription) => {
    return prescription?.rewriteReason ? <PrescriptionRewriteRequiredCard prescription={prescription} /> : <></>
  }

  const renderPrescriptionCard = (prescription) => {
    return <PrescriptionCardForSidePreview prescription={prescription} />
  }

  const onDeleteProtoPrescription = async (prescription) => {
    setShowPreview(false);
    setShowPrescriptionPreviewModal(false);

    const { error } = await dispatch(deleteProtoPrescription({
      id: prescription.prescriptionId,
      organisationId: currentOrganisation.organisationId
    }))

    const statusPar = callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly)

    await dispatch(getPrescriptionsForOrganisation({
      organisationId: currentOrganisation.organisationId,
      organisationEmployeeId: currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
        ? userDetails.id
        : null,
      status: statusPar,
      pageParams: {
        currentPage: currentPage,
        pageSize: pageSize,
        sortColumnName: callMapper(statusToSortFieldMapper, status, ""),
        sortDirection: callMapper(statusToSortDirectionMapper, status, 0)
      }
    }))

    const newToastState = {
      showToast: true,
      title: 'Prescription image deleted successfully!',
      message: 'You deleted prescription image',
      type: toastTypes.Success
    }
    if (!error) {
      dispatch(setToast(newToastState));
    }
  }

  const renderProtoPrescriptionActions = (prescription) => {
    return <div className={"side_preview_content__actions-container"}>
      <hr />
      <div className={"side_preview_content__actions"}>
        <div className={"side_preview_content__actions__cta_container"}>
          <PrimaryCta
            className={"side_preview_content__actions__cta__small"}
            color={"dark_blue"}
            onClick={async () => {
              setShowDeletePrescriptionModal(true);
              setCurrentPrescription(prescription);
            }}
          >
            <div className={"side_preview_content__actions__cta__small__contents"}>
              <TrashBin color={"red"}></TrashBin> <span>Delete</span>
            </div>
          </PrimaryCta>
          {currentOrganisation.employeeType !== EmployeeType.OrganisationPrescriber &&
            <PrimaryCta
              className={"side_preview_content__actions__cta"}
              text={"Add Patient details"}
              color={"dark_blue"}
              onClick={() => {
                dispatch(setProtoPrescriptionForPromoting(prescription));
                setShowPromoteProtoPrescriptionModal(true);
              }}
            />}
        </div>
      </div>
    </div>
  }

  const renderSidePreviewContentForImageOnlyTab = () => {
    return <>
      {renderPrescriberSectionForSidePreviev()}
      {renderEnvelopeTrackingCodeForSidePreview()}
      {renderProtoPrescriptionActions(currentPrescription)}
    </>
  }

  const renderSidePreviewContentForToBeAssignedTab = () => {
    return <>
      <GenericBanner
        className={"m-t-s"}
        appendableClassNames={"blue fontWeight-400"}
        icon={<MoreInfoIcon size={16} color={"#3B5EDB"} />}
        text={"This prescription has not yet been assigned to the patient. Once assigned, it will move into the Active prescriptions tab."}
      />
      {renderPrescriptionCard(currentPrescription)}
      {renderRewriteRequiredCard(currentPrescription)}
      {renderPrescriberSectionForSidePreviev()}
      {renderPatientInfoInHarmonicaForSidePreview()}
      {renderTempPatientInfoInHarmonicaForSidePreview()}
    </>
  }

  const renderSidePreviewContentForRewritesTab = () => {
    return <>
      {renderPrescriptionCard(currentPrescription)}
      {renderRewriteRequiredCard(currentPrescription)}
      {renderPrescriptionInfoSectionForSidePreview()}
      {renderPrescriberSectionForSidePreviev()}
      {renderPatientInfoInHarmonicaForSidePreview()}

    </>
  }

  const renderSidePreviewContentForActiveTab = () => {
    return <>
      {renderPrescriptionCard(currentPrescription)}
      {renderRewriteRequiredCard(currentPrescription)}
      {renderPrescriptionInfoSectionForSidePreview()}
      {renderPrescriberSectionForSidePreviev()}
      {renderPatientInfoInHarmonicaForSidePreview()}
    </>
  }

  const renderSidePreviewContentForCompletedTab = () => {
    return <>
      {renderPrescriptionCard(currentPrescription)}
      {renderRewriteRequiredCard(currentPrescription)}
      {renderPrescriptionInfoSectionForSidePreview()}
      {renderPrescriberSectionForSidePreviev()}
      {renderPatientInfoInHarmonicaForSidePreview()}
    </>
  }

  const sidePreviewContentMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: renderSidePreviewContentForImageOnlyTab,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: renderSidePreviewContentForToBeAssignedTab,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: renderSidePreviewContentForRewritesTab,
    [OrganisationPrescriptionsTypes.Active]: renderSidePreviewContentForActiveTab,
    [OrganisationPrescriptionsTypes.Complete]: renderSidePreviewContentForCompletedTab,
  }

  const renderSidePreviewContentBasedOnPhase = () => {
    return <div className={"side_preview_content__informations"}>
      {callMapper(sidePreviewContentMapper, status, sidePreviewContentMapper[OrganisationPrescriptionsTypes.ImageOnly])()}
    </div>
  }

  const renderPrescriptionInformationForViewPrescriptionModal = () => {
    return renderPrescriptionInfoSectionForSidePreview()
  }

  const contentForPreviewPrescriptionModal = (prescription) => {
    const tempPatient = prescription?.tempPatient;
    if (!prescription) {
      return null
    }
    return <>
      {renderRewriteRequiredCard(prescription)}
      {prescription.patient && renderPrescriptionCard(prescription)}
      {prescription.patient && renderPrescriptionInformationForViewPrescriptionModal()}
      <FormSectionTitle className={"m-t-s"} text={"Prescriber"} />
      <div className={"text_input_title m-b-xs"}>Name</div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"Name"} value={`${prescription?.organisationEmployee?.firstName} ${prescription?.organisationEmployee?.lastName}`} />

      {prescription.patient &&
        <>
          <FormSectionTitle text={"Patient"} className={"m-t-m"} />
          <div className={"text_input_title m-b-xs"}>First name</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"First name"} value={prescription?.patient?.firstName} />
          <div className={"text_input_title m-t-s m-b-xs"}>Last name</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Last name" className={""} value={prescription?.patient?.lastName} />
          <div className={"text_input_title m-t-s m-b-xs"}>Clinic patient id</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Clinic Patient ID" className={""} value={prescription?.patient?.clinicIdNumber || "N/A"} />
          <div className={"text_input_title m-t-s m-b-xs"}>Email</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Email" className={""} value={prescription?.patient?.user?.email} />
          <div className={"text_input_title m-t-s m-b-xs"}>Contact number</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={prescription?.patient?.telephoneNo} />
        </>
      }

      {tempPatient &&
        <>
          <FormSectionTitle className={"m-t-s"} text={"Patient"} />
          <div className={"side_preview_content__informations__input_label  m-b-xxs "}>
            Clinic patient ID
          </div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Clinic Patient ID" className={""} value={tempPatient?.clinicIdNumber || "N/A"} />
          <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
            Email
          </div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Email" className={""} value={tempPatient?.email} />
          <div className={"side_preview_content__informations__input_label m-t-s m-b-xxs "}>
            Contact number
          </div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Contact number" className={""} value={tempPatient?.telephoneNo} />
        </>
      }

      {status === OrganisationPrescriptionsTypes.ImageOnly &&
        <>
          <div className={"text_input_title m-t-s m-b-xs"}>Envelope tracking code</div>
          <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name="Envelope tracking code" className={""} value={prescription.envelopeTrackingCode} />
        </>
      }

      {status === OrganisationPrescriptionsTypes.ImageOnly &&
        renderProtoPrescriptionActions(currentPrescription)
      }
    </>
  }

  const contentForDeletePrescriptionModal = (prescription) => {
    if (!prescription) {
      return null
    }

    return <>
      <div className={"promote_proto-prescription_modal__content__step_explanation m-t-s"}>
        Please confirm you would like to delete this Prescription image.
        <br />
        <br />
        Once deleted, this prescription image cannot be restored.
      </div>
      <FormSectionTitle className={"m-t-s"} text={"Prescriber"} />
      <div className={"text_input_title m-b-xs"}>Name</div>
      <InputField appendableClassNames={"permanently_disabled"} isEnabled={false} name={"Name"} value={`${prescription?.organisationEmployee?.firstName} ${prescription?.organisationEmployee?.lastName}`} />
    </>
  }

  const renderTitleForPreviewPrescriptionModalAndSidePreview = (prescription) => {
    if (!prescription) {
      return ""
    }

    return status === OrganisationPrescriptionsTypes.ToBeAssigned ||
      status === undefined ?
      `Prescription ID: ${prescription?.prescriptionId || "N/A"}`
      :
      `Prescription No: ${prescription?.paperPrescriptionId || "N/A"}`
  }

  const renderPreviewPrescriptionModal = () => {
    return <CustomTwoSidedModal
      title={renderTitleForPreviewPrescriptionModalAndSidePreview(currentPrescription)}
      leftContent={() => contentForPreviewPrescriptionModal(currentPrescription)}
      rightContent={() => <PrescriptionPreview src={currentPrescription?.filePath} />}
      show={showPrescriptionPreviewModal}
      onClose={() => { setShowPrescriptionPreviewModal(false) }}
    />
  }

  const renderDeleteModal = () => {
    return <CustomTwoSidedModal
      title={renderTitleForPreviewPrescriptionModalAndSidePreview(currentPrescription)}
      leftContent={() => contentForDeletePrescriptionModal(currentPrescription)}
      rightContent={() => <PrescriptionPreview src={currentPrescription?.filePath} />}
      buttons={() =>
        <>
          <PrimaryCta color={"white"} onClick={async () => {
            setShowDeletePrescriptionModal(false);
          }}>
            Cancel
          </PrimaryCta>
          <PrimaryCta
            color={"blue"}
            className="m-l-s"
            onClick={async () => {
              await onDeleteProtoPrescription(currentPrescription);
              setShowDeletePrescriptionModal(false);
            }}>
            Delete permanently
          </PrimaryCta>

        </>
      }
      show={showDeletePrescriptionModal}
      onClose={() => { setShowDeletePrescriptionModal(false) }}
    />
  }

  const statusToTableMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? filteredPrescriptions : prescriptions)}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },
    [OrganisationPrescriptionsTypes.ToBeAssigned]: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? filteredPrescriptions : prescriptions)}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },
    [OrganisationPrescriptionsTypes.ReWriteRequests]: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? sortByDateOdestToNewest(filteredPrescriptions) : sortByDateOdestToNewest(prescriptions))}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },

    [OrganisationPrescriptionsTypes.Active]: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? filteredPrescriptions : prescriptions)}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },
    [OrganisationPrescriptionsTypes.Complete]: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? filteredPrescriptions : prescriptions)}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },
    undefined: () => {
      return <PaginatedReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPrescriptions}
        body={constructTableBody(clinicFilterNumberOfChecked > 0 ? filteredPrescriptions : prescriptions)}
        header={callMapper(TableHeaders, status, TableHeaders[OrganisationPrescriptionsTypes.ImageOnly])()}
        resetPaginationDependencies={[status]}
        isSearchActive={typeof clinicSearchSelectedValue !== 'undefined' || clinicFilterNumberOfChecked > 0}

        pageSize={pageSize}
        currentPage={currentPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
      />
    },
  }

  const statusToStatusStringMapper = {
    [OrganisationPrescriptionsTypes.ImageOnly]: "Image Only",
    [OrganisationPrescriptionsTypes.ToBeAssigned]: "Unassigned",
    [OrganisationPrescriptionsTypes.ReWriteRequests]: "Re-write Requests",
    [OrganisationPrescriptionsTypes.Active]: "Active",
    [OrganisationPrescriptionsTypes.Complete]: "Complete"
  }

  const tableHolderRef = useRef();
  const dashboardRef = useRef();

  useLayoutEffect(() => {

    const transformLayout = () => {
      document.body.style.overflow = "hidden"
      const heightForSomething = window.innerHeight - dashboardRef.current.getBoundingClientRect().top
      dashboardRef.current.style.height = `${heightForSomething}px`
      dashboardRef.current.style.overflowY = "hidden"
      if (tableHolderRef.current) {
        const parrentHeight = tableHolderRef.current.parentElement.getBoundingClientRect().height
        tableHolderRef.current.style.height = `${parrentHeight}px`
      }
    }

    transformLayout()
    window.addEventListener("resize", transformLayout)

    return () => {
      document.body.style.overflow = "initial"
      window.removeEventListener("resize", transformLayout)
    }
  }, [prescriptions]);

  return (
    <>
      <AccountHeader />
      <AccountBottomAppBar />
      <ClinicsBottomBar contentLeft={linksForBottomClinicsBar()} contentRight={uploadPrescriptionsForBottomClinicsBar()} />
      {status === OrganisationPrescriptionsTypes.ImageOnly && isDisclaimerBannerSeen === false &&
        <div className="clinic_prescriptions_dashboard__disclaimer-banner">
          <p>When you upload prescriptions without any patient details, they will be sent here in the <strong>Image
            only</strong> tab. These prescriptions are only visible to you. Once you add the patient details, the
            prescription will then be sent to Lyphe Dispensary and will be visible in the Unassigned tab.</p>
          <div
            className="clinic_prescriptions_dashboard__disclaimer-banner__close-button"
            onClick={() => hideDisclaimerBanner()}
          ><XIcon />
          </div>
        </div>
      }
      <section ref={dashboardRef} className='clinic_prescriptions_dashboard'>
        {!loadingPrescriptions ?
          <div ref={tableHolderRef} className='clinic_prescriptions_dashboard__table_holder '>
            {prescriptions ?
              callMapper(statusToTableMapper, status, () => null)() :
              <div className='clinic_prescriptions_dashboard__table_empty'>
                <div className='clinic_prescriptions_dashboard__table_empty__container'>

                  <>
                    <OrderStatusPaperNeededIcon color={"lightgray"} />

                    <p>No Prescriptions to show in {callMapper(statusToStatusStringMapper, status, OrganisationPrescriptionsTypes.ImageOnly)} tab</p>

                    <ReusableButton
                      className="reusable_button--upload"
                      text={"Upload prescription"}
                      onClick={() => {
                        dispatch(setInStateByKey({ key: "foundPatientForClinicUser", data: false }))
                        setShowUploadPrescriptionModal(!showUploadPrescriptionModal)
                      }}
                    />

                  </>
                </div>
              </div>
            }
          </div>
          :
          <div style={{ margin: "auto", height: "100%" }}>
            <SpinnerGrey />
          </div>}

        <SidePreview onClick={(e) => e.stopPropagation()} show={showPreview}  >
          <div className={"side_preview_content"}>
            <div className="side_preview_content__header" >
              <div>
                {renderTitleForPreviewPrescriptionModalAndSidePreview(currentPrescription)}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => onCloseSidePreview()} >
                <CloseIcon />
              </div>
            </div>
            <PrescriptionPreview
              buttons={buttonForLightBox}
              src={currentPrescription?.filePath}
            />
            {renderSidePreviewContentBasedOnPhase()}
          </div>
        </SidePreview>

        {renderPreviewPrescriptionModal()}
        {renderDeleteModal()}
        <UploadPrescriptionOrganisationModal
          show={showUploadPrescriptionModal}
          onClose={() => { setShowUploadPrescriptionModal(!showUploadPrescriptionModal) }}
        />
        <PromoteProtoPrescriptionModal
          show={showPromoteProtoPrescriptionModal}
          onClose={() => {
            setShowPromoteProtoPrescriptionModal(!showPromoteProtoPrescriptionModal)
            setShowPrescriptionPreviewModal(false)
          }}
        />
      </section>
    </>
  );
}

export default OrganisationsPrescriptions;

const statusToBadgeTextForActiveMapper = {
  [OrderStatuses.OutOfStock]: {
    text: "Processing",
    className: "reusable_badge--gray"
  },
  [OrderStatuses.OutOfStockAndPaper]: {
    text: "Processing",
    className: "reusable_badge--gray"
  },
  [OrderStatuses.NotPaidAndNoPaper]: {
    text: "Ready for payment",
    className: "reusable_badge--blue"
  },
  [OrderStatuses.NotPaidAndPaper]: {
    text: "Ready for payment",
    className: "reusable_badge--blue"
  },
  [OrderStatuses.PaidAndNoPaper]: {
    text: "Waiting on paper copy",
    className: "reusable_badge--blue"
  },
  [OrderStatuses.PaidAndPaper]: {
    text: "With pharmacy team",
    className: "reusable_badge--blue"
  },
  [OrderStatuses.Shipped]: {
    text: "Shipped",
    className: "reusable_badge--blue"
  },
  [OrderStatuses.NoPaperAndPaymentMissed]: {
    text: "Payment window missed",
    className: "reusable_badge--red"
  },
  [OrderStatuses.PaperAndPaymentMissed]: {
    text: "Payment window missed",
    className: "reusable_badge--red"
  },
  [OrderStatuses.Void]: {
    text: "Prescription expired",
    className: "reusable_badge--red"
  },
  [OrderStatuses.CompletedAndVoid]: {
    text: "Delivered",
    className: "reusable_badge--green"
  },
  [OrderStatuses.NotApproved]: {
    text: "Processing",
    className: "reusable_badge--gray"
  },

  [OrderStatuses.Complete]: {
    text: "Delivered",
    className: "reusable_badge--green"
  },
  [OrderStatuses.Archived]: {
    text: "Archived",
    className: "reusable_badge--orange"
  },
  [OrderStatuses.Cancelled]: {
    text: "Cancelled",
    className: "reusable_badge--red"
  },

  undefined: {
    text: "undefined",
    className: ""
  },
}

