import React from "react";
import DesktopAccountBottomAppBar from "./DesktopAccountBottomAppBar/DesktopAccountBottomAppBar";
import MobileAccountBottomAppBar from "./MobileAccountBottomAppBar/MobileAccountBottomAppBar";

function DashboardNavigation() {
  return (
    <>
      <DesktopAccountBottomAppBar />
      <MobileAccountBottomAppBar />
    </>
  );
}

export default DashboardNavigation;
