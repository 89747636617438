import React from 'react'
import RepeatPrescriptionDispensaryLogo from '../../assets/icons/RepeatPrescriptionDispensaryLogo'
import RepeatPrescriptionLoaderArrows from '../../assets/icons/RepeatPrescriptionLoaderArrows'
import LypheLogo from '../../assets/icons/LypheLogo'
import Spinner2 from '../../components/sharedComponents/Spinner/Spinner2'

function RepeatPrescriptionLoaderPage(loading) {
  return (
    <div className='repeat_prescription_loader'>
      <div className='repeat_prescription_loader--icons'>
        <RepeatPrescriptionDispensaryLogo />
        <div style={{ margin: "0 16px" }}>
          <RepeatPrescriptionLoaderArrows />
        </div>
        <LypheLogo />
      </div>
      <p>Repeat prescriptions</p>
      <h1>Transferring you to <br></br> Lyphe Clinic</h1>
      {loading && <div style={{ marginTop: "45px" }}>
        <Spinner2 color={"#00a372"} width={"60"} />
      </div>}
    </div>
  )
}
export default RepeatPrescriptionLoaderPage
