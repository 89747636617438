import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountBottomAppBar, OrderTracker } from "../../components/account";
import { AccountFooter } from "../../components/footers";
import { AccountHeader } from "../../components/headers";
import { ViewPrescription } from "../../components/popups";

import {
  selectPrescriptionModal,
  setPrescriptionModal,
} from "../../store/slices/modalsSlice";

function AccountLayout({ showTracker = false, children, showBottomAppBar = true, fullWidth = false }) {
  const dispatch = useDispatch();

  const prescriptionModal = useSelector(selectPrescriptionModal);

  return (
    <>
      <AccountHeader />

      <section className="dashboard">
        {showBottomAppBar && <AccountBottomAppBar />}

        <div className="dashboard__main">
          {showTracker && (
            <aside className="dashboard__status">
              <OrderTracker />
            </aside>
          )}

          <div className={fullWidth ? "dashboard__content__fullwidth" : "dashboard__content"}>{children}</div>
        </div>

        <AccountFooter />
      </section>

      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null }))
        }
      />
    </>
  );
}

export default AccountLayout;
