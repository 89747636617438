import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { PrescriptionCard, UserDetailsCard } from "../../../components/cards";
import { useProps, useAgeSelect, useUploadFile } from "../../../hooks";
import {
  selectCarer,
  setCarer,
  selectPrescription,
  setPrescription as setPrescriptionAction,
} from "../../../store/slices/carerOnboardingSlice";
import { CustomSelect, AgeSelect } from "../../../components/inputs";
import {
  NameForm,
  ContactNumberForm,
  AddressForm,
} from "../../../components/forms";
import {
  addressValidator,
  areaCodeValidator,
  nameValidator,
  phoneNumberValidator,
  zipCodeValidator,
} from "../../../util/validators";
import { isFormValid, propsToValues, trackAnalyticsEvent } from "../../../util/util";
import { ViewPrescription } from "../../../components/popups";
import {
  selectPrescriptionModal,
  setPrescriptionModal,
} from "../../../store/slices/modalsSlice";

function CarerConfirmDetails({ steps, setNextStep }) {
  const dispatch = useDispatch();

  const carer = useSelector(selectCarer);
  const prescriptionFromStore = useSelector(selectPrescription);
  const prescriptionModal = useSelector(selectPrescriptionModal);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const carerCardContent = getCarerDetails(carer);
  const patientCardContent = getPatientDetails(carer);

  const [editingCarerDetails, setEditingCarerDetails] = useState(false);
  const [editingPatientDetails, setEditingPatientDetails] = useState(false);
  const [editingPrescription, setEditingPrescription] = useState(false);

  // CARER
  const title = useProps(carer.title);
  const firstName = useProps(carer.firstName, nameValidator("First name"));
  const lastName = useProps(carer.lastName, nameValidator("Last name"));
  const carerNameForm = { title, firstName, lastName };

  const [
    carerAgeConfig,
    carerAgeValues,
    carerAgeError,
    resetCarerAge,
  ] = useAgeSelect(
    carer.dateOfBirth?.day,
    carer.dateOfBirth?.month,
    carer.dateOfBirth?.year
  );

  const areaCode = useProps(carer.contactNumber?.areaCode, areaCodeValidator());
  const number = useProps(carer.contactNumber?.number, phoneNumberValidator());
  const contactNumberForm = { areaCode, number };

  const patientRelationship = useProps(carer.patientRelationship);

  // PATIENT
  const patientTitle = useProps(carer.patientTitle);
  const patientFirstName = useProps(
    carer.patientFirstName,
    nameValidator("First name")
  );
  const patientLastName = useProps(
    carer.patientLastName,
    nameValidator("Last name")
  );
  const patientNameForm = { patientTitle, patientFirstName, patientLastName };

  const [
    patientAgeConfig,
    patientAgeValues,
    patientAgeError,
    resetPatientAge,
  ] = useAgeSelect(
    carer.patientDateOfBirth?.day,
    carer.patientDateOfBirth?.month,
    carer.patientDateOfBirth?.year,
    false
  );

  const address1 = useProps(carer.address1, addressValidator("Address 1"));
  const address2 = useProps(carer.address2, addressValidator("Address 2"));
  const zipCode = useProps(carer.zipCode, zipCodeValidator());
  const addressForm = { address1, address2, zipCode };

  const fileInputRef = useRef(null);

  const [
    prescription,
    prescriptionError,
    resetPrescription,
    fileSelected,
  ] = useUploadFile(prescriptionFromStore);

  const isSaveButtonEnabled =
    isFormValid(carerNameForm) &&
    isFormValid(patientNameForm) &&
    isFormValid(contactNumberForm) &&
    isFormValid(addressForm) &&
    patientAgeValues.day &&
    patientAgeValues.year &&
    patientAgeValues.month &&
    carerAgeValues.month &&
    carerAgeValues.year &&
    carerAgeValues.day &&
    !patientAgeError &&
    !carerAgeError;

  function saveClicked() {
    const carerPayload = {
      ...propsToValues(carerNameForm),
      patientRelationship: patientRelationship.value,
      ...propsToValues(patientNameForm),
      ...propsToValues(addressForm),
      contactNumber: {
        areaCode: areaCode.value,
        number: number.value,
      },
      confirmedConsent: carer.confirmedConsent,
      patientDateOfBirth: {
        year: patientAgeValues.year,
        month: patientAgeValues.month,
        day: patientAgeValues.day,
      },
      dateOfBirth: {
        year: carerAgeValues.year,
        month: carerAgeValues.month,
        day: carerAgeValues.day,
      },
      email: carer.email,
      secretId: carer.secretId,
    };

    dispatch(setCarer(carerPayload));

    dispatch(setPrescriptionAction(prescription));

    setEditingCarerDetails(false);
    setEditingPatientDetails(false);
    setEditingPrescription(false);
  }

  function cancelClicked() {
    setEditingCarerDetails(false);
    setEditingPatientDetails(false);
    setEditingPrescription(false);

    resetPrescription();

    resetPatientFields();
    resetCarerFields();
  }

  function cancelCarerClicked() {
    resetCarerFields();
    setEditingCarerDetails(false);
  }

  function cancelPatientClicked() {
    resetPatientFields();
    setEditingPatientDetails(false);
  }

  function resetPatientFields() {
    patientTitle.reset();
    patientFirstName.reset();
    patientLastName.reset();
    address1.reset();
    address2.reset();
    zipCode.reset();
    resetPatientAge();
  }

  function resetCarerFields() {
    title.reset();
    firstName.reset();
    lastName.reset();
    patientRelationship.reset();
    areaCode.reset();
    number.reset();
    resetCarerAge();
  }

  function editPrescriptionClicked() {
    fileInputRef.current.click();
  }

  function onFileSelect(e) {
    const error = fileSelected(e);

    if (!error) setEditingPrescription(true);
  }

  return (
    <section className="confirm_details">
      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null }))
        }
      />
      <div className="confirm_details__content">
        <div className="confirm_details__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please confirm that all details are correct</h1>
        </div>

        <div className="confirm_details__cards">
          <div>
            <div className="user_details">
              <div className="user_details__header">
                <p className="p3--semi-bold">Your details</p>
                {editingCarerDetails ? (
                  <p className="text_link" onClick={cancelCarerClicked}>
                    Cancel
                  </p>
                ) : (
                  <p
                    className="text_link"
                    onClick={() => setEditingCarerDetails(true)}
                  >
                    Edit
                  </p>
                )}
              </div>

              {editingCarerDetails ? (
                <div>
                  <NameForm {...carerNameForm} />
                  <AgeSelect {...carerAgeConfig} error={carerAgeError} />
                  <CustomSelect
                    name="Relationship"
                    options={[
                      { text: "Parent", value: "Parent" },
                      { text: "Guardian", value: "Guardian" },
                    ]}
                    {...patientRelationship}
                  />
                  <AddressForm {...addressForm} />
                  <div className="form-spacing">
                    <ContactNumberForm {...contactNumberForm} />
                  </div>
                </div>
              ) : (
                <UserDetailsCard content={carerCardContent} />
              )}
            </div>
            {/* PATIENT */}
            <div className="user_details">
              <div className="user_details__header">
                <p className="p3--semi-bold">Patient details</p>
                {editingPatientDetails ? (
                  <p className="text_link" onClick={cancelPatientClicked}>
                    Cancel
                  </p>
                ) : (
                  <p
                    onClick={() => setEditingPatientDetails(true)}
                    className="text_link"
                  >
                    Edit
                  </p>
                )}
              </div>

              {editingPatientDetails ? (
                <div>
                  <NameForm
                    title={patientTitle}
                    firstName={patientFirstName}
                    lastName={patientLastName}
                  />
                  <AgeSelect {...patientAgeConfig} error={patientAgeError} />
                </div>
              ) : (
                <UserDetailsCard content={patientCardContent} />
              )}
            </div>
          </div>

          {!carer.secretId && (
            <div>
              <input
                ref={(element) => (fileInputRef.current = element)}
                type="file"
                hidden
                onChange={onFileSelect}
                accept="image/*,application/pdf"
              />
              <PrescriptionCard
                title="Prescription"
                image={prescription.url}
                imageName={prescription.name}
                actionName="Edit"
                onClick={editPrescriptionClicked}
              />

              {prescriptionError && (
                <div className="confirm_details__error">
                  Image size must be under 20 MB
                </div>
              )}
            </div>
          )}
        </div>

        {editingCarerDetails || editingPatientDetails || editingPrescription ? (
          <div className="confirm_details__buttons">
            <div>
              <PrimaryCta
                text="Cancel changes"
                color="white"
                onClick={cancelClicked}
              />
            </div>
            <div>
              <PrimaryCta
                text="Save changes"
                color="orange"
                isEnabled={isSaveButtonEnabled}
                onClick={saveClicked}
              />
            </div>
          </div>
        ) : (
          <div className="confirm_details__button">
            <PrimaryCta
              text="Confirm details"
              color="orange"
              onClick={() => {
                setNextStep(steps.carerOneLastStep);
                trackAnalyticsEvent("Carer Onboarding", "Carer Confirmed details");
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
}

export default CarerConfirmDetails;

function getCarerDetails(carer) {
  return [
    {
      title: "FULL NAME",
      text: `${carer?.title} ${carer?.firstName} ${carer?.lastName}`,
    },
    { title: "MEDICATION FOR", text: "Someone I care for" },
    {
      title: "DATE OF BIRTH",
      text: `${carer?.dateOfBirth?.day}-${carer?.dateOfBirth?.month}-${carer?.dateOfBirth?.year}`,
    },
    { title: "RELATIONSHIP", text: carer?.patientRelationship },
    {
      title: "ADDRESS",
      text: `${carer?.address1}, ${carer?.address2}, ${carer?.zipCode}`,
    },
    {
      title: "CONTACT",
      text: `${carer?.contactNumber?.areaCode} ${carer?.contactNumber?.number}`,
    },
  ];
}

function getPatientDetails(carer) {
  return [
    {
      title: "FULL NAME",
      text: `${carer?.patientTitle} ${carer?.patientFirstName} ${carer?.patientLastName}`,
    },
    {
      title: "DATE OF BIRTH",
      text: `${carer?.patientDateOfBirth?.day}-${carer?.patientDateOfBirth?.month}-${carer?.patientDateOfBirth?.year}`,
    },
  ];
}
