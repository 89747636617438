import React from "react";

function PrescriptionRoundIcon({ color = "black" }) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9286 3.5L13.6429 1.21429C12.8571 0.428571 11.7857 0 10.7143 0H3.57143C1.57143 0 0 1.57143 0 3.57143V16.4286C0 18.4286 1.57143 20 3.57143 20H13.5714C15.5714 20 17.1429 18.4286 17.1429 16.4286V6.42857C17.1429 5.35714 16.7143 4.28571 15.9286 3.5ZM14.9286 4.5C15.0714 4.64286 15.1429 4.71429 15.2143 4.85714L15.2857 5C15.4286 5.21429 15.5 5.42857 15.6429 5.71429H12.1429C11.7143 5.71429 11.4286 5.42857 11.4286 5V1.57143C11.5714 1.57143 11.6429 1.64286 11.7857 1.64286C11.9286 1.71429 12.0714 1.78571 12.2143 1.85714L12.2857 1.92857C12.4286 2 12.5714 2.14286 12.6429 2.21429L14.9286 4.5ZM13.5714 18.5714H3.57143C2.35714 18.5714 1.42857 17.6429 1.42857 16.4286V3.57143C1.42857 2.35714 2.35714 1.42857 3.57143 1.42857H10V5C10 6.21429 10.9286 7.14286 12.1429 7.14286H15.7143V16.4286C15.7143 17.6429 14.7857 18.5714 13.5714 18.5714Z"
        fill={color}
      />
    </svg>
  );
}

export default PrescriptionRoundIcon;
