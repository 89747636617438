import { useSelector } from "react-redux";

import {
  selectFilters,
  selectOrderIdFilter,
  selectPatientIdFilter,
  selectSort,
} from "../store/slices/prescriptionsSlice";
import { Filters, OrderStatuses, Sorts } from "../enums";
import moment from "moment";

/**
 * Gets selected filters from the store and filters
 * active and past  prescriptions
 */
function useFilterPrescriptions(prescriptions) {
  const filters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const patientIdFilter = useSelector(selectPatientIdFilter);
  const orderIdFilter = useSelector(selectOrderIdFilter);

  let filteredPrescriptions = [];
  if (prescriptions.length) {
    filteredPrescriptions = applyFiltersAndSorts(
      prescriptions,
      filters,
      sort,
      patientIdFilter,
      orderIdFilter
    );
  }
  return filteredPrescriptions;
}

export default useFilterPrescriptions;

function applyFiltersAndSorts(prescriptions, filters, sort, patientId, orderId) {
  let result = [...prescriptions];

  result = filterPrescriptionsByPatientName(result, patientId);
  result = filterPrescriptionsByOrderStatus(result, filters);
  result = filterPrescriptionsByOrderId(result, orderId);

  sortMapper[sort](result);

  return result;
}

function filterPrescriptionsByPatientName(prescriptions, patientId) {
  const result = [];
  // I didn't put if (!patientId) because
  // patientId can theoretically be 0
  if (patientId === null || patientId === undefined) return prescriptions;
  for (let prescription of prescriptions) {
    if (prescription.patient.id === patientId) {
      result.push(prescription);
    }
  }
  return result;
}

function filterPrescriptionsByOrderId(prescriptions, orderId) {
  const result = [];

  // I didn't put if (!patientId) because
  // patientId can theoretically be 0
  if (!orderId) return prescriptions;
  for (let prescription of prescriptions) {
    // eslint-disable-next-line
    if (prescription.orderId == orderId || prescription.paperPrescriptionId == orderId) {
      result.push(prescription);
    }
  }

  return result;
}

function filterPrescriptionsByOrderStatus(prescriptions, filters) {
  const result = [];

  if (filters[0] === Filters.AllStatuses || filters[1] === Filters.AllStatuses) return prescriptions;

  for (let prescription of prescriptions) {
    if (checkStatusFiltersOnPrescription(prescription, filters)) {
      result.push(prescription);
    }
  }

  return result;
}

function checkStatusFiltersOnPrescription(prescription, filters) {
  // don't filter if there are no products

  for (let filter of filters) {

    if (filter === Filters.Recieved && [OrderStatuses.Complete, OrderStatuses.CompletedAndVoid].includes(prescription.status))
      return true;

    if (filter === Filters.Pending && [OrderStatuses.NotPaidAndNoPaper, OrderStatuses.NotPaidAndPaper].includes(prescription.status))
      return true;

    if (filter === Filters.Dispatched && prescription.status === OrderStatuses.Shipped)
      return true;

    if (filter === Filters.Expired && [OrderStatuses.Void, OrderStatuses.CompletedAndVoid].includes(prescription.status))
      return true;

    if (filter === Filters.AwaitingPaperPrescription && [OrderStatuses.OutOfStock, OrderStatuses.NotPaidAndNoPaper, OrderStatuses.PaidAndNoPaper, OrderStatuses.NoPaperAndPaymentMissed, OrderStatuses.NotApproved].includes(prescription.status))
      return true;

    if (filter === Filters.PaperPrescriptionReceived && [OrderStatuses.OutOfStockAndPaper, OrderStatuses.NotPaidAndPaper, OrderStatuses.PaidAndPaper, OrderStatuses.PaperAndPaymentMissed].includes(prescription.status))
      return true;
  }

  return false;
}

const sortMapper = {
  [Sorts.OldestToNewest]: sortOldestToNewest,
  [Sorts.NewestToOldest]: sortNewestToOldest,
  [Sorts.AlphabeticalASC]: sortByPatientNameAsc,
  [Sorts.AlphabeticalDESC]: sortByPatientNameDesc,
  [Sorts.NewestByPrescribedDate]: sortByPrescribedDate,
};

Object.freeze(sortMapper);

function sortNewestToOldest(prescriptions) { }

function sortOldestToNewest(prescriptions) {
  prescriptions.reverse();
}

function sortByPatientNameAsc(prescriptions) {
  prescriptions.sort(comparePatientNameAscending);
}

function sortByPatientNameDesc(prescriptions) {
  prescriptions.sort(comparePatientNameDescending);
}

function sortByPrescribedDate(prescriptions) {
  prescriptions.sort(comparePrescribedDateTimeDescending);
  prescriptions.sort(compareActive);
  prescriptions.sort(compareFutureDated);
}

function getPatientName(patient) {
  return `${patient.firstName.toLowerCase()} ${patient.lastName.toLowerCase()}`;
}

function comparePatientNameAscending(prescription1, prescription2) {
  const patientName1 = getPatientName(prescription1.patient);
  const patientName2 = getPatientName(prescription2.patient);

  if (patientName1 < patientName2) return -1;
  else if (patientName1 > patientName2) return 1;

  return 0;
}

function comparePrescribedDateTimeDescending(prescription1, prescription2) {
  const date1 = new Date(prescription1.prescribedDateTime);
  const date2 = new Date(prescription2.prescribedDateTime);

  if (date1 > date2) return -1;
  else if (date1 < date2) return 1;

  return 0;
}

function compareActive(prescription1, prescription2) {
  if (isPrescriptionActive(prescription1) == isPrescriptionActive(prescription2))
    return 0;
  else if (isPrescriptionActive(prescription1) && !isPrescriptionActive(prescription2))
    return 1;
  else if (!isPrescriptionActive(prescription1) && isPrescriptionActive(prescription2))
    return -1;
}

function compareFutureDated(prescription1, prescription2) {
  if (isPrescriptionFutureDated(prescription1) == isPrescriptionFutureDated(prescription2))
    return 0;
  else if (isPrescriptionFutureDated(prescription1) && !isPrescriptionFutureDated(prescription2))
    return 1;
  else if (!isPrescriptionFutureDated(prescription1) && isPrescriptionFutureDated(prescription2))
    return -1;
}

function comparePatientNameDescending(prescription1, prescription2) {
  const patientName1 = getPatientName(prescription1.patient);
  const patientName2 = getPatientName(prescription2.patient);

  if (patientName1 > patientName2) return -1;
  else if (patientName1 < patientName2) return 1;

  return 0;
}

function isPrescriptionActive(prescription) {
  return (prescription.status !== OrderStatuses.NotApproved && prescription.status !== OrderStatuses.OutOfStock && prescription.status !== OrderStatuses.OutOfStockAndPaper && prescription.orderId)
}

function isPrescriptionFutureDated(prescription) {
  return (!(moment(Date.now()) > moment(prescription.prescribedDateTime, 'YYYY-MM-DD')))
}
