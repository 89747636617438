import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PrimaryCta } from "../../../components/buttons";

import {
  selectAccountLoading,
  verifyEmail,
} from "../../../store/slices/accountSlice";

import { useGetUserDetails } from "../../../hooks";
import { BigTickIcon, CrossFailIcon } from "../../../assets/icons";

function EmailVerified(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  const dispatch = useDispatch();
  const history = useHistory();

  const { secretId } = useParams();

  const loading = useSelector(selectAccountLoading);

  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    verify();
    //eslint-disable-next-line
  }, [secretId]);

  // check if the user is already verified
  useGetUserDetails();

  async function verify() {
    const { error } = await dispatch(verifyEmail(secretId));

    if (error) setError(true);
  }

  async function loginClicked() {
    history.push("/auth/login");
  }

  return (
    <>
      {!loading && !error && (
        <section className="verify_email">
          <div className="verify_email__content">
            <div className="verify_email__image">
              <BigTickIcon />
            </div>
            <div className="verify_email__title">
              <p className="label--caps--normal">You are good to go</p>
              <h1 className="h3-a">Email verified</h1>
              <p className="p5">You can now sign in to your Lyphe Dispensary account</p>
            </div>

            <div className="verify_email__button">
              <PrimaryCta color="orange" text="Continue to login" link="/auth/login" />
            </div>
          </div>
        </section>
      )}

      {error && (
        <section className="verify_email">
          <div className="verify_email__content">
            <div className="verify_email__image">
              <CrossFailIcon />
            </div>
            <div className="verify_email__title">
              <p className="label--caps--normal">ACCOUNT NOT VERIFIED</p>
              <h1 className="h3-a">We were unable to verify your account</h1>
              <p className="p5">Please log in and resend verification email</p>
            </div>

            <div className="verify_email__button">
              <PrimaryCta color="orange" text="Login" onClick={loginClicked} />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default EmailVerified;
