import React from "react";

function GreenCircle4(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#13ae7d" />
            <path d="M14.0009 24.264V22.716L20.0489 14.04H22.5329V22.482H24.2069V24.264H22.5329V27H20.5169V24.264H14.0009ZM20.6069 16.128L16.3589 22.482H20.6069V16.128Z" fill="black" />
        </svg>

    );
}

export default GreenCircle4;
