import React from "react";
import TableRow from "./TableRow";

function Table({
  tableData = { head: [], body: [] },
  medium = false,
  small = false,
  adjustable = false,
  topContent = null,
  bottomContent = null,
  onItemEdit = null,
  onItemDelete = null,
  onColumnSort = null,
}) {
  function renderTableSizeClass() {
    let classString = "table-size ";

    if (medium) classString += "medium ";
    else if (small) classString += "small ";

    if (!adjustable) classString += "fixed ";

    return classString;
  }

  function renderTableHead() {
    // .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
    const renderRowClass = (item) =>
      `table-col-${item.column * 2} ${item.right ? "right" : ""} ${onColumnSort ? "clickable" : ""
      }`;

    return tableData.head.map((item, index) => (
      <th
        key={index}
        onClick={() => {
          if (onColumnSort) onColumnSort(index);
        }}
        className={renderRowClass(item)}
      >
        {item.text}
      </th>
    ));
  }

  function renderTableBody() {
    return tableData.body.map((item, index) => (
      <TableRow
        key={index}
        onItemEdit={onItemEdit}
        onItemDelete={onItemDelete}
        rowData={item}
      />
    ));
  }

  const tableDataExists = tableData && tableData.body && tableData.body.length;

  return (
    <div className="card">
      {topContent}

      <table cellSpacing="0" border="0" width="100%">
        <tbody>
          <tr className="table-header">
            <td>
              <div>
                <table cellSpacing="0" width="100%">
                  <thead>
                    <tr>{renderTableHead(tableData.head)}</tr>
                  </thead>
                </table>
              </div>
            </td>
          </tr>

          {tableDataExists ? (
            <tr className="table-body">
              <td>
                <div className={renderTableSizeClass()}>
                  <table cellSpacing="0" width="100%">
                    <tbody>{renderTableBody()}</tbody>
                  </table>
                </div>
              </td>
            </tr>
          ) : (
            <tr
              className="empty-table-disclaimer"
              style={{ height: small ? "300px" : medium ? "390px" : "" }}
            >
              <td>
                <span>Table is empty</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {bottomContent}
    </div>
  );
}

export default Table;
