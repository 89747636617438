import React from "react";
import { FullScreenOverlay } from "../../overlays";
import { CloseIcon } from "../../../assets/icons";
import { resolveAppendableClasses } from "../../../util/util";

function ReusableModal(
  { children,
    show = false,
    onClose = () => null,
    title = "",
    footerContent = null, // must be a function that returns a component
    customHeaderContent = null, // must be a function that returns a component
    className = "",
    appendableClassNames = "",
  }
) {
  const renderHeaderContent = () => {
    return customHeaderContent ? customHeaderContent() : <>{title}  <span className={"reusable_modal__header__close"} onClick={() => { onClose() }}><CloseIcon /> </span></>
  }

  return (
    <FullScreenOverlay onClick={() => onClose()} show={show}>
      <div onClick={(e) => e.stopPropagation()} className={`reusable_modal ${className} ${resolveAppendableClasses("reusable_modal", appendableClassNames)}`}>
        <div className="reusable_modal__header">
          {renderHeaderContent()}
        </div>
        <div className="reusable_modal__content p-s" >
          {children}
        </div>

        {footerContent &&
          <div className="reusable_modal__footer">
            {footerContent()}
          </div>
        }
      </div>
    </FullScreenOverlay>
  );
}

export default ReusableModal;