import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Order, OrderTracker } from '../../../components/account'
import OrderDetails from '../../../components/account/OrderDetails/OrderDetails'
import { AccountFooter } from '../../../components/footers'
import { AccountHeader } from '../../../components/headers'
import { ViewPrescription } from '../../../components/popups'
import { selectPrescriptionModal, setPrescriptionModal } from '../../../store/slices/modalsSlice'
import { getAllPrescriptions, selectAllPrescriptions, setTrackingOrderIndex } from '../../../store/slices/prescriptionsSlice'

const SingleOrder = () => {

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const dispatch = useDispatch()
  const { orderId } = useParams()
  const orders = useSelector(selectAllPrescriptions)
  const prescriptionModal = useSelector(selectPrescriptionModal);
  // eslint-disable-next-line
  const [showTracker, setShowTracker] = useState(true);

  const convertedOrder = useMemo(() => {
    // eslint-disable-next-line
    return orders.find(o => o.orderId == orderId)
  }, [orderId, orders])

  useEffect(() => {
    dispatch(getAllPrescriptions());

    return () => {
      dispatch(setTrackingOrderIndex(null));
    }
    // eslint-disable-next-line
  }, [orderId])

  useEffect(() => {
    if (convertedOrder) {
      dispatch(setTrackingOrderIndex(convertedOrder.index))
    }
    // eslint-disable-next-line
  }, [convertedOrder])

  return (
    <>
      <AccountHeader />

      <section className="dashboard dashboard--doctor p-t-s">
        <OrderDetails order={convertedOrder} />
        <div className="dashboard__main">

          {showTracker && (
            <aside className="dashboard__status">
              <OrderTracker />
            </aside>
          )}

          <div className="dashboard__content">
            {convertedOrder && (
              <Order
                key={`p${convertedOrder.prescriptionId}o${convertedOrder.orderId} `}
                order={convertedOrder}
              />
            )}
          </div>
        </div>

        <AccountFooter />
      </section>

      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null }))
        }
      />
    </>
  )
}

export default SingleOrder
