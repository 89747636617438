import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MagnifyingGlass from '../../assets/icons/MagnifyingGlass';
import XIcon from '../../assets/icons/XIcon';
import SpinnerGrey from '../sharedComponents/Spinner/SpinnerGrey';
import SearchOptionsForClinic from '../ClinicSearch/SearchOptionsForClinic';
import { selectFromPrescriptionSliceByKey, setClinicSearchSelectedValue as setPrecriptionSearchSelectedValue, setClinicSearchInputValue, getPrescriptionsForOrganisation, resetPrescriptionsForClinicUser, resetSearchResultsForClinicUser as resetPrescriptionSearchResultsForClinicUser, setClinicSearchParameter, setClinicSearchType } from "../../store/slices/prescriptionsSlice";
import { selectFromProductSliceByKey, resetProductsForClinicUser, getProductsForClinic, setClinicSearchSelectedValue as setProductSearchSelectedValue, resetSearchResultsForClinicUser as resetProductSearchResultsForClinicUser } from "../../store/slices/productSlice";
import { callMapper } from "../../util/util";
import { OrganisationPrescriptionsTypes, EmployeeType, headerPageTypes } from "../../enums"
import { selectCurrentOrganisation, selectUserDetails } from "../../store/slices/accountSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ClinicSearch = ({
    onChange = () => null,
    placeholder = "Search",
    onKeyChange = () => null,
    loading = false,
}) => {
    const dispatch = useDispatch()
    const params = useParams();
    const { status } = params.status ? params : { status: OrganisationPrescriptionsTypes.ImageOnly };
    const [showOptions, setShowOptions] = useState(false);
    const [focused, setFocused] = useState(false);
    const prescriptionSearchSelectedValue = useSelector(selectFromPrescriptionSliceByKey("clinicSearchSelectedValue"));
    const productSearchSelectedValue = useSelector(selectFromProductSliceByKey("clinicSearchSelectedValue"));
    const inputValue = useSelector(selectFromPrescriptionSliceByKey("clinicSearchInputValue"));
    const currentOrganisation = useSelector(selectCurrentOrganisation);
    const userDetails = useSelector(selectUserDetails);

    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(headerPageTypes.prescriptions);
    const [isPageSearchEligible, setIsPageSearchEligible] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("/clinicPrescriptions"))
            setCurrentPage(headerPageTypes.prescriptions);

        if (location.pathname.includes("/clinicPatients"))
            setCurrentPage(headerPageTypes.patients);

        if (location.pathname.includes("/clinicFormulary"))
            setCurrentPage(headerPageTypes.formulary);

        if (inputValue)
            handleClear();
    }, [currentPage])


    useEffect(() => {
        if (location.pathname.includes("/clinicPrescriptions"))
            setIsPageSearchEligible(true);
        else if (location.pathname.includes("/clinicFormulary"))
            setIsPageSearchEligible(true);
        else
            setIsPageSearchEligible(false);
    }, [location])


    const statusToSelectorMapper = {
        [OrganisationPrescriptionsTypes.ImageOnly]: OrganisationPrescriptionsTypes.ImageOnly,
        [OrganisationPrescriptionsTypes.ReWriteRequests]: OrganisationPrescriptionsTypes.ReWriteRequests,
        [OrganisationPrescriptionsTypes.ToBeAssigned]: OrganisationPrescriptionsTypes.ToBeAssigned,
        [OrganisationPrescriptionsTypes.Active]: OrganisationPrescriptionsTypes.Active,
        [OrganisationPrescriptionsTypes.Complete]: OrganisationPrescriptionsTypes.Complete,
    }

    const handleOnChange = (e) => {
        onChange(e);
        dispatch(setClinicSearchInputValue(e.target.value));
        setShowOptions(true);
    }

    const handleOpen = () => {
        setShowOptions(true)
        setFocused(true);
    }

    const handleClose = () => {
        setFocused(false);
        setTimeout(() => {
            setShowOptions(false);
        }, 300);
    }

    const handleClear = () => {
        const organisationId = currentOrganisation.organisationId;
        const organisationEmployeeId = currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
            ? userDetails.id
            : null;

        dispatch(setClinicSearchInputValue(""));
        dispatch(setClinicSearchParameter(null));
        dispatch(setClinicSearchType(null));
        if (prescriptionSearchSelectedValue) {
            dispatch(resetPrescriptionsForClinicUser())
            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: callMapper(statusToSelectorMapper, status, OrganisationPrescriptionsTypes.ImageOnly) }))

        }

        dispatch(setPrecriptionSearchSelectedValue(false));
        dispatch(resetPrescriptionSearchResultsForClinicUser())

        if (currentPage === headerPageTypes.formulary) {
            dispatch(resetProductsForClinicUser())
            dispatch(getProductsForClinic({ query: "", organisationId, organisationEmployeeId }))
        }

        setFocused(true);
    }

    return (
        <div>
            {isPageSearchEligible &&
                <div onBlur={() => handleClose()} className={`clinic_search_field ${focused ? "clinic_search_field--focused" : ""}`}>
                    <label>
                        {(currentPage == headerPageTypes.formulary && loading) && <SpinnerGrey />}
                        <input
                            placeholder={placeholder}
                            value={inputValue}
                            onFocus={() => handleOpen()}
                            onKeyDown={(value) => onKeyChange(value)}
                            className="clinic_search_field__input"
                            type="text"
                            onChange={(e) => handleOnChange(e)}
                            disabled={prescriptionSearchSelectedValue || productSearchSelectedValue}
                        />
                        {inputValue &&
                            <div className="clinic_search_field__icon" onClick={() => { handleClear() }} >
                                <XIcon />
                            </div>
                        }
                        {!inputValue &&
                            <div className="clinic_search_field__icon">
                                <MagnifyingGlass />
                            </div>
                        }
                    </label>
                    {(showOptions && inputValue.length >= 3) &&
                        currentPage !== headerPageTypes.formulary && <div onClick={() => setShowOptions(false)} className="clinic_search_field__options">
                            {loading ?
                                <div className='clinic_search_field__options__spinner' >
                                    <SpinnerGrey />
                                </div>
                                :
                                <SearchOptionsForClinic />
                            }
                        </div>
                    }
                </div>}
        </div>
    )
}

export default ClinicSearch
