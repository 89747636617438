import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { useAgeSelect } from "../../../hooks";
import { AgeSelect } from "../../../components/inputs";
import {
  selectCarer,
  setPatientDateOfBirth,
} from "../../../store/slices/carerOnboardingSlice";
import { trackAnalyticsEvent } from "../../../util/util";

function CarerPatientAgeCheck({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const { patientDateOfBirth: dob } = useSelector(selectCarer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ageConfig, values, ageErrorMessage] = useAgeSelect(
    dob?.day,
    dob?.month,
    dob?.year,
    false
  );

  const { day, month, year } = values;

  const isButtonEnabled = day && month && year && !ageErrorMessage;

  function confirmClicked() {
    dispatch(setPatientDateOfBirth({ year, month, day }));

    trackAnalyticsEvent("Carer Onboarding", "Date Of Birth Added for Patient");
    setNextStep(steps.carerAgeCheck);
  }

  return (
    <section className="is_over_18">
      <div className="is_over_18__content">
        <div className="is_over_18__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please enter their date of birth</h1>
        </div>

        <div className="is_over_18__form">
          <AgeSelect {...ageConfig} error={ageErrorMessage} />
        </div>

        <div className="is_over_18__button">
          <PrimaryCta
            onClick={confirmClicked}
            isEnabled={isButtonEnabled}
            color="orange"
            text="Confirm"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerPatientAgeCheck;
