import React from "react";
import SplitingOrderIcon from "../../../assets/icons/SplitingOrderIcon";

function SplitOrderBanner({ numberOfSplitOrders, splitOrderIndex }) {
  return (
    <div className="notice_banner">
      <div>
        <SplitingOrderIcon />
      </div>
      <div className="label--smaller" style={{ display: "flex" }}>
        <b>{`Order ${splitOrderIndex} / ${numberOfSplitOrders} :`}</b>
        <p>This order has been split so we can send medication that is on hand sooner. <a href="https://lyphe.com/help/part-shipment//">Learn more</a></p>
      </div>
    </div>
  );
}

export default SplitOrderBanner;
