import React from "react";
import { FlowerIcon } from "../../../assets/icons";

function NoPastPrescriptions(props) {
  return (
    <div className="no_prescriptions j-c-c">
      <div style={{ flexDirection: "column", display: "flex", alignItems: "center", maxWidth: "394px", width: "100%" }}>
        <div className='m-b-s'>
          <FlowerIcon />
        </div>
        <p className="h8-a">No past orders</p>
        <p style={{ fontWeight: '400', marginTop: "24px" }} className="p5">
          All completed orders will be listed here so you can review past
          medications. Any prescriptions that expire before we can dispense them
          will also be listed here and marked as "Void".
        </p>
      </div>
    </div>
  );
}

export default NoPastPrescriptions;
