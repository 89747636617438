import React from "react";
import { PrimaryCta } from "../../../components/buttons";
import { getPluralText } from "../../../util/util";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganisation } from "../../../store/slices/accountSlice";
import TickIconSuccess from "../../../assets/icons/TickIconSuccess";
import {
  selectPrescriptionForUploadAsClinicUser,
  setPrescriptionForUploadAsClinicUser,
} from "../../../store/slices/prescriptionsSlice";

function MobileUploadConfirmation({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const prescriptions = useSelector(selectPrescriptionForUploadAsClinicUser);

  return (
    <section className="mobile_upload_confirmation">
      <div className="mobile_upload_confirmation__content">
        <div className='mobile_upload_confirmation__uploaded' >
          <div className={'mobile_upload_confirmation__uploaded-checkmark'}>
            <TickIconSuccess size={60} />
          </div>
          <div className={'mobile_upload_confirmation__title'}>
            <h1>Uploaded</h1>
          </div>
          <div className={'mobile_upload_confirmation__uploaded-text'}>
            {getPluralText('prescription image', prescriptions.length, true)} successfully uploaded to:
            <br />
            <strong>{currentOrganisation && currentOrganisation.organisationName}</strong>
          </div>
        </div>
        <div className="mobile_upload_confirmation__important">
          <div>
            <div className="mobile_upload_confirmation__red_button">
              <p className="p2">Next steps</p>
            </div>
            <div className="mobile_upload_confirmation__please_make_sure">
              <ul>
                <li>
                  Your Clinic’s admin team will be able to see the uploaded prescriptions
                  from their desktop account in the <strong>Image only</strong> section
                </li>
                <li>
                  The admin team will add the patient details for each prescription
                  uploaded and then send them them to Lyphe Dispensary to
                  start processing
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobile_upload_confirmation__button">
          <PrimaryCta text="Start new upload" color="blue" onClick={() => {
            dispatch(setPrescriptionForUploadAsClinicUser(null));
            setNextStep(steps.mobileUpload);
          }} />
        </div>
      </div>
    </section>
  );
}

export default MobileUploadConfirmation;
