import React from 'react'
import { CloseIcon, PrescriptionIcon } from '../../../assets/icons'
import { QuaternaryCta } from '../../buttons'
import { FullScreenOverlay } from '../../overlays'

const ViewPerscriptionPopup = ({ show, close, data }) => {
    const isPdf = data?.filePath.substring(data?.filePath.lastIndexOf('.')) === '.pdf';
    return (
        <div className='viewPerscriptionPopupDoctor'>
            <div>
                <QuaternaryCta
                    text="View prescription"
                    icon={<PrescriptionIcon size={17} />}
                    onClick={close}
                />
            </div>
            <FullScreenOverlay show={show}>
                <div className='order_details_popup'>
                    <div className="verification_card">
                        <>
                            <div className="verification_card__header">
                                <p className="p4-a"></p>
                                <div onClick={close}>
                                    <CloseIcon />
                                </div>
                            </div>
                            <div className="verification_card__close">
                                <div onClick={close}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </>
                        <div style={{ paddingLeft: '0', paddingRight: '0' }} className="verification_card__content ">
                            <div >
                                <h1 className="h8-a viewPerscriptionPopupDoctor__title">View Prescription</h1>
                                <div className="m-b-s viewPerscriptionPopupDoctor__patient_name" >
                                    Patient name: {data?.patient?.firstName + " " + data?.patient?.lastName}
                                </div>
                            </div>
                            <div className="viewPerscriptionPopupDoctor__image_container">
                                {isPdf ?
                                    <iframe
                                        key={data?.filePath}
                                        title='view prescription popup '
                                        className="" src={data?.filePath}
                                        style={{ width: '90%', minHeight: '500px' }}
                                        frameBorder="0" allow="autoplay; fullscreen"
                                        allowFullScreen
                                    />
                                    :
                                    <img src={data?.filePath} alt='prescriptionimage' />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </FullScreenOverlay>
        </div>
    )
}

export default ViewPerscriptionPopup
