import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { QuaternaryCta } from "../../../buttons";
import { selectUser } from "../../../../store/slices/authenticationSlice";

import { useGetNavBarText, useGetSelectedPatientName, useProps } from "../../../../hooks";
import { selectOrderIdFilter, setOrderIdFilter, setPatientIdFilter } from "../../../../store/slices/prescriptionsSlice";
import { UserRoles } from "../../../../enums";
import { callMapper } from "../../../../util/util";
import RepeatPrescriptionIcon from "../../../../assets/icons/RepeatPrescriptionIcon";

function MobileDashboardNavigation() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser);
  const navBarText = useGetNavBarText();


  const patientsName = useGetSelectedPatientName()
  const selectedOrderIdFromStore = useSelector(selectOrderIdFilter)
  const autoComplete = useProps(patientsName ? patientsName : selectedOrderIdFromStore ? String(selectedOrderIdFromStore) : '');

  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    if (!patientsName && !selectedOrderIdFromStore) {
      setShowSearch(false)
    }
    if (patientsName || selectedOrderIdFromStore) {
      setShowSearch(true)
    }
  }, [patientsName, selectedOrderIdFromStore])

  function onValueSelected(patientId, orderPrescriptionId) {

    dispatch(setPatientIdFilter(patientId));
    dispatch(setOrderIdFilter(orderPrescriptionId));
  }

  const patientCarerLeftContent = () => {
    return <QuaternaryCta link="/repeatPrescription" text="Request a repeat prescription" icon={<RepeatPrescriptionIcon />} />
  }

  const clinicUserLeftContent = () => {
    return ""
  }

  const leftContentMapper = {
    [UserRoles.OrganisationEmployee]: clinicUserLeftContent,
    [UserRoles.Patient]: patientCarerLeftContent,
    [UserRoles.Carer]: patientCarerLeftContent,
    [UserRoles.ClinicUser]: clinicUserLeftContent,
  }

  return (
    <nav className="mobile_dashboard_nav">
      <div>
        <div className="mobile_dashboard_nav__left">{callMapper(leftContentMapper, user.role, () => null)()}</div>
        {/* <div className="mobile_dashboard_nav__right">{navBarText}</div> */}
      </div>
    </nav>
  );
}

export default MobileDashboardNavigation;
