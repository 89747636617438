import React from "react";

function ProductNoteTooltipContent({ product }) {

	return (
		<div className={'product-note-tooltip-content'} >
			<div className={'product-note-tooltip-content__title'}>
				Pharmacy comment:
			</div>
			<div className={'product-note-tooltip-content__text'}>
				{product.prescriberNote}
			</div>
		</div>
	);
}

export default ProductNoteTooltipContent;