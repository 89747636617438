import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountBottomAppBar } from '../../../components/account'

import DoctorPatientsTable from '../../../components/DoctorPatientsTable/DoctorPatientsTable'
import DoctorPatientsTableStatusTag from '../../../components/DoctorPatientsTable/DoctorPatientsTableStatusTag'
import { AccountFooter } from '../../../components/footers'
import { AccountHeader } from '../../../components/headers'

import { useFilterPrescriptions } from '../../../hooks'

import { selectUser } from '../../../store/slices/authenticationSlice'
import { getAllPrescriptions, selectAllPrescriptions, selectLoadingAllPrescriptions } from '../../../store/slices/prescriptionsSlice'

import { Link } from 'react-router-dom'


import moment from 'moment'

import { OrderStatuses } from '../../../enums'
import { ReactComponent as ArrowRight } from "../../../assets/images/chevron_right.svg";
import Spinner from '../../../components/sharedComponents/Spinner/Spinner'

const AllPerscriptionsDoctor = (props) => {

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  const dispatch = useDispatch();

  const allPrescriptions = useSelector(selectAllPrescriptions);

  const user = useSelector(selectUser);
  const loadingAllPrescriptions = useSelector(selectLoadingAllPrescriptions);
  const filteredPrescriptions = useFilterPrescriptions(allPrescriptions);

  useEffect(() => {
    if (!user) return;

    dispatch(getAllPrescriptions());
    //eslint-disable-next-line
  }, [user]);

  const getDoctorStatusText = (item) => {

    return doctorStatusTextMapper[item.status]
  }

  const getPatientStatusText = (item) => {

    if (!item.patient?.user?.isIDVerified) {
      return 'Awaiting registration'
    }
    return patientStatusTextMapper[item.status]
  }


  const constructTableRowData = (item) => {
    return [
      {
        text: item.orderId ? item.orderId : '-',
        column: 1,
      },
      {
        text: item.patient?.firstName + " " + item.patient?.lastName,
        column: 2,
        left: true,
        cellClasses: 'td_patient_name'
      },
      {
        text: item.patient?.dateOfBirth ? moment(item.patient?.dateOfBirth).format("DD/MM/YY") : '-',
        column: 1,
        left: true
      },
      {
        text: item.paperPrescriptionId ? item.paperPrescriptionId : '-',
        column: 1,
        left: true
      },
      {
        text: moment(item?.prescriptionUploadDate, "DD/MM/YY").format("DD/MM/YY"),
        column: 1,
      },
      {
        components: [
          <DoctorPatientsTableStatusTag key='sa' order={item} data={{ text: getDoctorStatusText(item) }} />
        ],
        column: 2,
      },
      {
        text: getPatientStatusText(item),
        left: true,
        column: 2,
        cellClasses: 'td_patient_status'
      },
      {
        text: item.products ? item.products.length + ` ITEM${item.products.length > 1 ? 'S' : ''}` : '0 ITEMS',
        column: 1,
        left: true
      },
      {
        text: '',
        column: 1,
        components: [
          renderAction(item)
        ],
      },
    ]
  }

  const renderAction = (item) => {
    return (
      <Link className={item.orderId ? '' : 'disabled'} to={`/order/${item.orderId}`} key='s'>
        View order <ArrowRight />
      </Link>
    )
  }

  const createTableBodyData = (data) => {
    return data.map((rowData, index) => constructTableRowData(rowData, index))
  }

  const table = {
    head: [
      {
        text: 'Order',
        column: 1,
      },
      {
        text: 'NAME',
        column: 2,
        left: true
      },
      {
        text: 'date of birth',
        column: 1,
        left: true
      },
      {
        text: 'Prescription id',
        column: 1,
        left: true
      },
      {
        text: 'prescription upload date',
        column: 1,
      },
      {
        text: 'doctor status',
        column: 2,
      },
      {
        text: 'patient status',
        column: 2,
        left: true
      },
      {
        text: 'order amount',
        column: 1,
        left: true
      },
      {
        text: 'action',
        column: 1,
      },
    ],
    body: createTableBodyData(filteredPrescriptions)
  }

  const loadingComponenta = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Spinner color={"#13ae7d"} />
      <span className='m-t-s'>ACTIVE PRESCRIPTIONS LOADING</span>
    </div>
  }


  return (
    <>
      <AccountHeader />
      <AccountBottomAppBar />
      <section className='active_prescriptions_doctor_dashboard'>
        <div className='active_prescriptions_doctor_dashboard__table_holder '>
          <DoctorPatientsTable
            loadingComponent={{ component: loadingComponenta }}
            loadingData={loadingAllPrescriptions}
            tableData={table}
            body={createTableBodyData(filteredPrescriptions)}
          />
        </div>
      </section>
      <AccountFooter />
    </>
  )
}

export default AllPerscriptionsDoctor


const doctorStatusTextMapper = {
  [OrderStatuses.OutOfStock]: 'Awaiting paper prescription',
  [OrderStatuses.OutOfStockAndPaper]: 'Paper prescription received',
  [OrderStatuses.NotPaidAndNoPaper]: 'Awaiting paper prescription',
  [OrderStatuses.NotPaidAndPaper]: 'Paper prescription received',
  [OrderStatuses.PaidAndNoPaper]: 'Awaiting paper prescription',
  [OrderStatuses.PaidAndPaper]: 'Paper prescription received',
  [OrderStatuses.Shipped]: 'Order dispatched',
  [OrderStatuses.PaperAndPaymentMissed]: 'Paper prescription received',
  [OrderStatuses.NoPaperAndPaymentMissed]: 'Awaiting paper prescription',
  [OrderStatuses.Void]: 'Prescription expired',
  [OrderStatuses.CompletedAndVoid]: 'Prescription expired',
  [OrderStatuses.NotApproved]: 'Awaiting paper prescription',
  [OrderStatuses.Complete]: 'Order completed',
  [OrderStatuses.Archived]: 'Order archived',
  [OrderStatuses.Cancelled]: 'Order cancelled',

  // default
  undefined: 'undefined',
};

const patientStatusTextMapper = {
  [OrderStatuses.OutOfStock]: 'Processing',
  [OrderStatuses.OutOfStockAndPaper]: 'Processing',
  [OrderStatuses.NotPaidAndNoPaper]: 'Payment pending',
  [OrderStatuses.NotPaidAndPaper]: 'Payment pending',
  [OrderStatuses.PaidAndNoPaper]: 'Payment received',
  [OrderStatuses.PaidAndPaper]: 'Payment received',
  [OrderStatuses.Shipped]: 'Order dispatched',
  [OrderStatuses.PaperAndPaymentMissed]: 'Payment window missed',
  [OrderStatuses.NoPaperAndPaymentMissed]: 'Payment window missed',
  [OrderStatuses.Void]: 'Prescription expired',
  [OrderStatuses.CompletedAndVoid]: 'Medication received',
  [OrderStatuses.NotApproved]: 'Awaiting paper prescription',
  [OrderStatuses.Complete]: 'Medication received',
  [OrderStatuses.Archived]: 'Order archived',
  [OrderStatuses.Cancelled]: 'Order cancelled',

  // default
  undefined: 'undefined',
};
