import React, { useState } from "react";

import { StepperHeader } from "../headers";
import MobileAccountHeader from "../headers/AccountHeader/MobileAccountHeader/MobileAccountHeader";

function Stepper({ steps, firstStep, showAccountHeader = false }) {
  const [renderedStep, setRenderedStep] = useState(firstStep);
  return (
    <>
      {showAccountHeader &&
        <MobileAccountHeader />
      }

      {showAccountHeader === false &&
        <StepperHeader
          currentStep={renderedStep.step}
          closeWithPopup={renderedStep.closeWithPopup}
          totalSteps={steps.totalSteps}
          hideClose={renderedStep.hideClose}
          hideProgressBar={renderedStep.hideProgressBar}
        />
      }
      <renderedStep.component setNextStep={setRenderedStep} steps={steps} />
    </>
  );
}

export default Stepper;
