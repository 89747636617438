import React from "react";

function ClipBoardIcon(props) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 8H10.5C9.39543 8 8.5 8.89543 8.5 10V19C8.5 20.1046 9.39543 21 10.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V10C21.5 8.89543 20.6046 8 19.5 8Z" stroke="#D5D5D5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 14H3.5C2.96957 14 2.46086 13.7893 2.08579 13.4142C1.71071 13.0391 1.5 12.5304 1.5 12V3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1H12.5C13.0304 1 13.5391 1.21071 13.9142 1.58579C14.2893 1.96086 14.5 2.46957 14.5 3V4" stroke="#D5D5D5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default ClipBoardIcon;