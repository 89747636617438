import React from 'react'
import { useHistory } from 'react-router-dom'
import { PrimaryCta } from '../../components/buttons'


export default function TMCC() {
    const history = useHistory()
    const handleOpenRequestForm = () => {
        history.push('/repeatPrescriptionForm')
    }

    return (
        <div className='repeat_prescription--content'>
            <div className='content_heading--noTmcc'>
                <span>Request a repeat</span>
                <h1>Repeat prescriptions</h1>
                <p>To request a repeat prescription from your clinic, follow these steps:</p>
            </div>
            <div className='content_text--tmcc'>
                <p><span>1</span>Click the open request form button below</p>
                <p><span>2</span>Select the medication and quantity you would like to request</p>
                <p><span>3</span>Make payment</p>
            </div>
            <div className='content_button'>
                <PrimaryCta
                    text={"Open request form"}
                    color='white'
                    className='primary_cta--orange'
                    onClick={handleOpenRequestForm}
                />
            </div>
        </div>
    )
}

