import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import MobileAccountHeaderFooter from "./MobileAccountHeaderFooter/MobileAccountHeaderFooter";
import { QuaternaryCta } from "../../../buttons";

import { selectUser } from "../../../../store/slices/authenticationSlice";
import { setLogoutModal } from "../../../../store/slices/modalsSlice";

import { Logo } from "../../../../assets/images";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  CloseIcon,
  UploadIcon,
  LearnIcon,
  HamburgerIcon,
} from "../../../../assets/icons";
import { learnUrl, patientFeedback } from '../../../../config.json'

function getActiveLink(links, pathname) {
  return links.find((item) => item.link === pathname);
}

function HeaderMobile(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const [show, setShow] = useState(false);

  const links = user?.role === 3 ? linksDoctor : linksPatient;
  // const bottomLinks = user?.role === 3 ? bottomLinksDoctor : bottomLinksPatient;

  const [activeLink, setActiveLink] = useState(
    getActiveLink(links, pathname)?.link
  );

  const headerStyle = show ? { background: "white" } : null;

  function getClassName(link) {
    return activeLink === link ? "active" : "";
  }

  function onMainLinkClick(link) {
    onLinkCLick(link);
    setActiveLink(link);
  }

  function onLinkCLick(link) {
    turnOffScrollLock();
    setShow(false);
    history.push(link);
  }

  function onLogoutClick() {
    turnOffScrollLock();
    dispatch(setLogoutModal({ show: true }));
  }

  function hamburgerClicked() {
    setShow(true);
    turnOnScrollLock();
  }

  function closeClicked() {
    setShow(false);
    turnOffScrollLock();
  }

  function turnOnScrollLock() {
    document.body.classList.add("no_scroll");
  }

  function turnOffScrollLock() {
    document.body.classList.remove("no_scroll");
  }

  const headerClass = props.color
    ? `mobile_header mobile-header--${props.color}`
    : "mobile_header";

  const textColor = "#1e1e1e";

  return (
    <header className={headerClass}>
      <div className='mobile_header__container' style={headerStyle}>
        <div className="mobile_header__logo">
          <Logo color={textColor} />
        </div>
        <div className="main_header__hamburger">
          {show ? (
            <div onClick={closeClicked}>
              <CloseIcon color={textColor} />
            </div>
          ) : (
            <div onClick={hamburgerClicked}>
              <HamburgerIcon color={textColor} />
            </div>
          )}
        </div>
      </div>

      <nav className={`mobile_nav ${show ? "show_nav" : ""}`}>
        <div className="mobile_nav__up_slot">
          {links.map(({ link, displayName }) => (
            <Link key={link} onClick={() => onMainLinkClick(link)} to={link}>
              <div className={getClassName(link)}>
                <p className="h7-a">{displayName}</p>
              </div>
            </Link>
          ))}

          {user?.role === 3 && (
            <div className="mobile_nav__upload_prescription">
              <QuaternaryCta
                onClick={() => onLinkCLick("/upload/prescriber")}
                text="Upload Prescriptions"
                icon={<UploadIcon size={20} />}
              />
            </div>
          )}
        </div>

        <div className="mobile_nav__down_slot">
          <div>
            <ul>
              <li className="mobile_nav__my_account">My Account</li>

              <li
                onClick={() => onLinkCLick("/profile")}
              >
                My profile
              </li>

              <li
                className="mobile_nav__learn"
              >
                <a href={patientFeedback}>
                  <div>Help us improve</div>
                  <div>
                    <LearnIcon />
                  </div>
                </a>
              </li>

              <li
                className="mobile_nav__learn"
              >
                <a href={learnUrl}>
                  <div>Learn</div>
                  <div>
                    <LearnIcon />
                  </div>
                </a>
              </li>

              <li
                onClick={() => onLinkCLick("/support")}
              >
                Support
              </li>


              <li className="mobile_nav__log_out" onClick={onLogoutClick}>
                Log out
              </li>
            </ul>
          </div>

          <div className="mobile_nav__social">
            <a href="https://www.facebook.com/lypheclinicuk">
              <FacebookIcon />
            </a>
            <a href="https://x.com/lypheclinic">
              <TwitterIcon />
            </a>
            <a href="https://instagram.com/lypheuk">
              <InstagramIcon />
            </a>
          </div>
        </div>
        <MobileAccountHeaderFooter />
      </nav>
    </header>
  );
}

export default HeaderMobile;

const linksPatient = [
  { displayName: "Active prescriptions", link: "/prescriptions/active" },
  { displayName: "Past Prescriptions", link: "/prescriptions/past" },
  { displayName: "Request a repeat prescription", link: "/repeatPrescription" },
  { displayName: "Formulary", link: "/patientFormulary" },
];

const linksDoctor = [
  //   { displayName: "Prescriptions", link: "/prescriptions/all" },
];

// const bottomLinksPatient = [
//   // { text: "Refer a friend", link: "/refer" },
//   { text: "My profile", link: "/profile" },
//   { text: "Support", link: "/support" },
// ];

// const bottomLinksDoctor = [
//   // { text: "Refer a colleague", link: "/refercolleague" },
//   { text: "My profile", link: "/profile" },
//   { text: "Support", link: "/support" },
// ];
