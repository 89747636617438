import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AccountHeader } from "../../../../components/headers";
import { AccountBottomAppBar } from "../../../../components/account";
import ClinicsBottomBar from "../../../../components/account/ClinicsBottomBar/ClinicsBottomBar";
import ClinicsBottomBarLinks
  from "../../../../components/account/ClinicsBottomBar/ClinicBottomBarLinks/ClinicsBottomBarLinks";
import { ClinicPatientTypes, TableHeaders, genericSubscriptionTextMapper } from "../../../../enums";
import { useHistory, useParams } from "react-router-dom";
import ReusableTable from "../../../../components/sharedComponents/ReusableTable/ReusableTable";
import { callMapper } from "../../../../util/util";
import Spinner from "../../../../components/sharedComponents/Spinner/Spinner";
import ReusableButton from "../../../../components/buttons/ReusableButton/ReusableButton";
import SidePreview from "../../../../components/account/SidePreview/SidePreview";
import { CloseIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientsForClinic,
  selectClinicsPatientsLoadingFlag,
  selectClinicPatientsFromPatientSliceByKey,
} from "../../../../store/slices/patientSlice";
import moment from "moment";
import { InputField } from "../../../../components/inputs";
import ReusableModal from "../../../../components/modals/ReusableModal/ReusableModal";
import FormRow from "../../../../components/forms/FormRow/FormRow";
import { selectCurrentOrganisation } from "../../../../store/slices/accountSlice";
import { TMCC_CLINIC_ID } from '../../../../config.json'


function ClinicPatients() {
  const { status } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  const statusToSelectorMapper = {
    [ClinicPatientTypes.Basic]: ClinicPatientTypes.Basic,
    [ClinicPatientTypes.Full]: ClinicPatientTypes.Full,
  }
  const loadingPatients = useSelector(selectClinicsPatientsLoadingFlag(callMapper(statusToSelectorMapper, status, ClinicPatientTypes.Full)));
  const patients = useSelector(selectClinicPatientsFromPatientSliceByKey(callMapper(statusToSelectorMapper, status, ClinicPatientTypes.Full)));

  const currentOrganisation = useSelector(selectCurrentOrganisation);

  useEffect(function () {
    if (currentOrganisation.organisationId === null) {
      history.push("/organisation/noaccess")
    }
  }, [currentOrganisation])


  const viewButton = (patient) => {
    return <ReusableButton
      onClick={(e) => {
        e.stopPropagation();
        setCurrentPatient(patient)
        setShowPatientModal(true)
        /*        setCurrentPrescription(prescription)
                setActivePrescription(prescription);
                setShowPrescriptionPreviewModal(true)*/
      }}
    >
      View
    </ReusableButton>
  }

  const onRowClick = (e, patient) => {
    e.stopPropagation()
    setCurrentPatient(patient)
    setShowPreview(true);
  }

  const onCloseSidePreview = () => {
    setShowPreview(!showPreview)
    /*dispatch(setSelectedPrescription(null))*/
  }



  const renderContentForPatientModalFullPatient = () => {

    return <>
      <div className={"w--100"} >
        <FormRow>
          <div className={"col-2"} >
            <div className={"text_input_title m-b-xs"} >Title</div>
            <InputField isEnabled={false} name="Title" value={currentPatient.title || "N/A"} />
          </div>
          <div className={"col-5"} >
            <div className={"text_input_title m-b-xs"} >First name</div>
            <InputField isEnabled={false} name="First name" value={currentPatient.firstName} />
          </div>
          <div className={"col-5"} >
            <div className={"text_input_title m-b-xs"} >Last name</div>
            <InputField isEnabled={false} name="Last name" value={currentPatient.lastName} />
          </div>
        </FormRow>

        <FormRow>
          <div className={"col-2"} >
            <div className={"text_input_title m-b-xs"} >Date of birth</div>
            <InputField isEnabled={false} name="Date of birth" value={currentPatient.dateOfBirth ? moment(currentPatient.dateOfBirth).format('YYYY-MM-DD') : "N/A"} />
          </div>
          <div className={"col-4"} >
            <div className={"text_input_title m-b-xs"} >Clinic Patient ID</div>
            <InputField isEnabled={false} name="Clinic Patient ID" value={currentPatient.clinicIdNumber || "N/A"} />
          </div>
          <div className={"col-6"} >
            <div className={"text_input_title m-b-xs"} >Email address</div>
            <InputField isEnabled={false} name="Email address" value={currentPatient.email} />
          </div>
        </FormRow>

        <FormRow>
          <div className={"col-5"} >
            <div className={"text_input_title m-b-xs"} >Address line 1</div>
            <InputField isEnabled={false} name="Address line 1" value={currentPatient.address1 || "N/A"} />
          </div>
          <div className={"col-5"} >
            <div className={"text_input_title m-b-xs"} >Address line 1</div>
            <InputField isEnabled={false} name="Contact number" value={currentPatient.address2 || "N/A"} />
          </div>
          <div className={"col-2"} >
            <div className={"text_input_title m-b-xs"} >Post code</div>
            <InputField isEnabled={false} name="Post code" value={currentPatient.zipCode || "N/A"} />
          </div>
        </FormRow>

        {currentOrganisation.organisationId === TMCC_CLINIC_ID && <FormRow>
          <div className={"col-6"} >
            <div className={"text_input_title m-b-xs"} >Subscription</div>
            <InputField isEnabled={false} name="Subscription" value={getSubscriptionString(currentPatient)} />
          </div>
          <div className={"col-3"} >
            <div className={"text_input_title m-b-xs"} >Subscribed since</div>
            <InputField isEnabled={false} name="Subscribed since" value={moment(currentPatient.subscriptionCreatedAt).format("DD/MM/YYYY")} />
          </div>
          <div className={"col-3"} >
            <div className={"text_input_title m-b-xs"} >Subscription updated</div>
            <InputField isEnabled={false} name="Subscription updated" value={moment(currentPatient.subscriptionUpdatedAt).format("DD/MM/YYYY")} />
          </div>
        </FormRow>}
      </div>
    </>
  }
  const renderContentForPatientModalBasicPatient = () => {
    return <>
      <div className={"w--90"} >
        <div className={"text_input_title m-b-xs"} >Clinic patient ID</div>
        <InputField label={"dsfa"} isEnabled={false} name="Clinic Patient ID" value={currentPatient.clinicIdNumber || "N/A"} />
        <div className={"text_input_title m-t-s m-b-xs"} >Email address</div>
        <InputField isEnabled={false} name="Email address" value={currentPatient.email} />
        <div className={"text_input_title m-t-s m-b-xs"} >Contact number</div>
        <InputField isEnabled={false} name="Contact number" value={currentPatient.telephoneNo} />
      </div>
    </>
  }

  const mapperForPatientModalContent = {
    [ClinicPatientTypes.Basic]: renderContentForPatientModalBasicPatient,
    [ClinicPatientTypes.Full]: renderContentForPatientModalFullPatient,
  }

  const isRowActive = (patient) => {
    return currentPatient?.email === patient.email ? "active-row" : ""
  }

  const renderTableBodyForBasicPatientsTable = () => {
    return patients.map(patient => {
      return [
        {
          text: moment(patient.createdAt).format("DD/MM/YYYY"),
          column: 2,
          onRowClick: (e) => { onRowClick(e, patient) },
          classNameForRow: `${isRowActive(patient)} hoverable`,
        },
        {
          text: patient.clinicIdNumber,
          column: 2,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: patient.email,
          column: 3,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: patient.telephoneNo,
          column: 3,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: viewButton(patient),
          column: 2,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
      ]
    })
  }

  const renderTableBodyForFullPatientsTable = () => {
    return patients.map(patient => {
      return [
        {
          text: moment(patient.createdAt).format("DD/MM/YYYY"),
          column: 2,
          onRowClick: (e) => { onRowClick(e, patient) },
          classNameForRow: `${isRowActive(patient)} hoverable`,
        },
        {
          text: patient.clinicIdNumber || "N/A",
          column: 2,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },

        {
          text: `${patient.firstName} ${patient.lastName} `,
          column: 2,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: patient.email,
          column: 2,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: patient.telephoneNo,
          column: 3,
          left: true,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
        {
          text: viewButton(patient),
          column: 1,
          onRowClick: (e) => { onRowClick(e, patient) }
        },
      ]
    })
  }

  const links = [
    {
      text: "Full",
      active: status === ClinicPatientTypes.Full || status === undefined,
      onClick: () => {
        history.push(`/clinicPatients/${ClinicPatientTypes.Full}`)
      }
    },
    {
      text: "Basic",
      active: status === ClinicPatientTypes.Basic,
      onClick: () => {
        history.push(`/clinicPatients/${ClinicPatientTypes.Basic}`)
      }
    },

  ]
  const linksForBottomClinicsBar = () => {
    return <ClinicsBottomBarLinks links={links} />
  }

  const loadingComponent = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Spinner color={"#13ae7d"} />
      <span className='m-t-s'>LOADING PATIENTS</span>
    </div>
  }

  useEffect(() => {
    if (currentOrganisation.organisationId > 0)
      dispatch(getPatientsForClinic({
        organisationId: currentOrganisation.organisationId,
        status: callMapper(statusToSelectorMapper, status, ClinicPatientTypes.Basic)
      }))
    // eslint-disable-next-line
  }, [status, currentOrganisation])

  useEffect(() => {
    function onOutsideClick() {
      setShowPreview(false)
    }

    window.addEventListener("click", onOutsideClick)
    return () => {
      window.removeEventListener("click", onOutsideClick)
    }
  }, [])

  const statusToTableMapper = {
    [ClinicPatientTypes.Full]: () => {
      return <ReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPatients}
        body={renderTableBodyForFullPatientsTable()}
        header={callMapper(TableHeaders, status, TableHeaders[ClinicPatientTypes.Full])()}
        resetPaginationDependencies={[status]}
      />
    },
    [ClinicPatientTypes.Basic]: () => {
      return <ReusableTable
        loadingComponent={{ component: loadingComponent }}
        loadingData={loadingPatients}
        body={renderTableBodyForBasicPatientsTable()}
        header={callMapper(TableHeaders, status, TableHeaders[ClinicPatientTypes.Basic])()}
        resetPaginationDependencies={[status]}
      />
    },

  }

  const mapperForTitlePatientModal = {
    [ClinicPatientTypes.Basic]: () => {
      return `Clinic Patient ID: ${currentPatient.clinicIdNumber || "N/A"}`
    },
    [ClinicPatientTypes.Full]: () => {
      return `Patient name: ${currentPatient.firstName} ${currentPatient.lastName}`
    },
  }

  const tableHolderRef = useRef();
  const dashboardRef = useRef();
  useLayoutEffect(() => {
    const transformLayout = () => {
      document.body.style.overflow = "hidden"
      const heightForSomething = window.innerHeight - dashboardRef.current.getBoundingClientRect().top
      dashboardRef.current.style.height = `${heightForSomething}px`
      dashboardRef.current.style.overflowY = "hidden"
      const parentHeight = tableHolderRef.current.parentElement.getBoundingClientRect().height
      tableHolderRef.current.style.height = `${parentHeight}px`
    }
    transformLayout()
    window.addEventListener("resize", transformLayout)

    return () => {
      document.body.style.overflow = "initial"
      window.removeEventListener("resize", transformLayout)
    }
  }, [patients]);

  return (
    <>
      <AccountHeader />
      <AccountBottomAppBar />
      <ClinicsBottomBar contentLeft={linksForBottomClinicsBar()} contentRight={<></>} />
      <section ref={dashboardRef} className='clinic_prescriptions_dashboard'>
        <div ref={tableHolderRef} className='clinic_prescriptions_dashboard__table_holder '>
          {callMapper(statusToTableMapper, status, statusToTableMapper[ClinicPatientTypes.Basic])()}
        </div>
        <SidePreview onClick={(e) => e.stopPropagation()} show={showPreview}  >
          <div className={"side_preview_content"}>
            <div className="side_preview_content__header" >
              <div>
                Clinic Patient ID: {currentPatient.clinicIdNumber || "N/A"}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => onCloseSidePreview()} >
                <CloseIcon />
              </div>
            </div>
            <div className={"side_preview_content__informations"}>
              <div className={"side_preview_content__informations__input_label m-t-s m-b-xs"}>
                Clinic patient ID
              </div>
              <InputField isEnabled={false} name="Clinic Patient ID" value={currentPatient.clinicIdNumber || "N/A"} />
              <div className={"side_preview_content__informations__input_label m-t-xs m-b-xs"}>
                Email address
              </div>
              <InputField isEnabled={false} name="Email address" value={currentPatient.email} />
              <div className={"side_preview_content__informations__input_label m-t-xs m-b-xs"}>
                Contact number
              </div>
              <InputField isEnabled={false} name="Contact number" value={currentPatient.telephoneNo} />
              {currentOrganisation.organisationId === TMCC_CLINIC_ID && <div>
                <div className={"side_preview_content__informations__input_label m-t-xs m-b-xs"}>
                  Subscription
                </div>
                <InputField isEnabled={false} name="Subscription" value={getSubscriptionString(currentPatient)} />
                {currentPatient.subscriptionCreatedAt && <div>
                  <div className={"side_preview_content__informations__input_label m-t-xs m-b-xs"}>
                    Subscribed since
                  </div>
                  <InputField isEnabled={false} name="Subscribed since" value={moment(currentPatient.subscriptionCreatedAt).format("DD/MM/YYYY")} />
                </div>}
                {currentPatient.subscriptionUpdatedAt && <div>
                  <div className={"side_preview_content__informations__input_label m-t-xs m-b-xs"}>
                    Subscription updated
                  </div>
                  <InputField isEnabled={false} name="Subscribed since" value={moment(currentPatient.UpdatedAt).format("DD/MM/YYYY")} />
                </div>}
              </div>}
            </div>
          </div>
        </SidePreview>
        <ReusableModal
          onClose={() => { setShowPatientModal(!showPatientModal) }}
          className={callMapper(mapperForPatientModalWidth, status, "w--60")}
          title={callMapper(mapperForTitlePatientModal, statusToTableMapper, mapperForTitlePatientModal[ClinicPatientTypes.Basic])()}
          show={showPatientModal}
        >
          <div className={""} style={{ display: 'flex', flexDirection: "column", alignItems: 'center', width: "100%" }}>
            {callMapper(mapperForPatientModalContent, status, renderContentForPatientModalBasicPatient)()}
          </div>
        </ReusableModal>
      </section>
    </>
  );
}

export default ClinicPatients;

const mapperForPatientModalWidth = {
  [ClinicPatientTypes.Basic]: 'w--50',
  [ClinicPatientTypes.Full]: 'w--70',
}

const getSubscriptionString = (patient) => {
  const isPAYG = patient.subscriptionType === 0;
  return `${callMapper(genericSubscriptionTextMapper, patient?.subscriptionType, `Unknown subscription type`)} ${!isPAYG && patient?.subscriptionTier === 1 ? "- Gold" : !isPAYG && patient?.subscriptionTier === 2 ? "- Platinum" : ""}`
}