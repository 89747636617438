import React from 'react'

const GreenCircle3SFPro = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#00a372" />
            <path d="M13.0957 15.6816H14.7656C16.5723 15.6816 17.666 16.5215 17.6562 18.0254C17.6562 19.3145 16.5527 20.2227 14.9414 20.2227C13.2715 20.2227 12.1484 19.3828 12.0508 18.1035H9.63867C9.76562 20.5938 11.8555 22.2832 14.9414 22.2832C18.0273 22.2832 20.2148 20.584 20.2148 18.084C20.2148 16.1211 18.8379 14.8711 16.7578 14.6758V14.627C18.457 14.2754 19.7168 13.1035 19.7168 11.3262C19.7168 9.14844 17.8418 7.625 14.9219 7.625C12.0898 7.625 10.1074 9.22656 9.94141 11.7461H12.2852C12.373 10.418 13.4375 9.62695 14.9219 9.62695C16.4746 9.62695 17.2949 10.4277 17.2949 11.668C17.2949 12.918 16.2598 13.8066 14.707 13.8066H13.0957V15.6816Z" fill="#F6F6F6" />
        </svg>

    )
}

export default GreenCircle3SFPro
