import React from "react";

function AkarArrowClockwise() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M79.647 65.5638C76.4038 72.1208 71.1439 77.4654 64.6395 80.8128C58.1817 84.1285 50.8189 85.2482 43.6673 84.0021C36.4936 82.741 29.9212 79.1905 24.9338 73.8821C19.9002 68.5238 16.6953 61.7073 15.7798 54.4126C14.8501 47.1014 16.2505 39.6833 19.7818 33.2143C23.2807 26.7949 28.7483 21.6685 35.3797 18.5901C41.9665 15.5394 49.3689 14.7211 56.463 16.2595C63.5547 17.7966 69.366 21.2926 74.173 26.8203C74.7518 27.4336 76.9905 29.91 78.8497 33.6475" stroke="#D5D5D5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M60 31.1882L79.32 34.665L82.7355 15" stroke="#D5D5D5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M41.7921 72.9867C37.0098 71.8568 28.3675 64.851 32.0572 45.8665C36.36 48.4741 44.3309 57.5488 41.7921 72.9867Z" stroke="#D5D5D5" stroke-width="2" />
      <path d="M44.9464 72.96C41.7578 69.2209 38.9311 58.4607 53.1327 45.3328C55.0606 49.98 56.1225 62.0115 44.9464 72.96Z" stroke="#D5D5D5" stroke-width="2" />
      <path d="M47.8999 73.7861C47.6143 68.8804 51.8547 58.595 71.1013 56.699C69.8337 61.5679 63.4188 71.8019 47.8999 73.7861Z" stroke="#D5D5D5" stroke-width="2" />
    </svg>

  );
}

export default AkarArrowClockwise;
