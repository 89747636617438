import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectUserDetails,
} from "../../../store/slices/accountSlice";
import { selectUser } from "../../../store/slices/authenticationSlice";
import { AccountHeader } from "../../../components/headers";
import { AccountFooter } from "../../../components/footers";
import { UserDetailsCard } from "../../../components/cards";
import { PrimaryCta } from "../../../components/buttons";
import ChangePasswordCard from "../../../components/cards/ChangePasswordCard/ChangePasswordCard";
import GenericPopup from "../../../components/popups/GenericPopup/GenericPopup";
import { callMapper } from "../../../util/util";
import { genericSubscriptionTextMapper } from "../../../enums";
import moment from "moment";
import { TMCC_CLINIC_ID } from '../../../config.json'

function PatientProfile(props) {
  const [showContactClinicPopup, setShowContactClinicPopup] = useState(false);

  const patient = useSelector(selectUserDetails);
  const user = useSelector(selectUser);

  const userDetailsCardContent = getUserDetailsCard(patient);
  const subscriptionDetailsCardContent = getUserSubscriptionDetailsCard(patient);

  const renderOkButtonForModal = () => {
    return <PrimaryCta color="orange" text={"Ok"} onClick={() => { setShowContactClinicPopup(false) }} />
  }

  const renderParagraphsForModal = () => {
    return <>
      <p style={{ textAlign: 'left', fontSize: '14px' }} className="p4">
        If you need to update your details, please get in touch with your clinic directly.
      </p>
      <p style={{ textAlign: 'left', fontSize: '14px' }} className="p4 m-t-s">
        Once your clinic has updated your records, Lyphe Dispensary will be notified and will make the necessary changes.
      </p>
      <p style={{ textAlign: 'left', fontSize: '14px' }} className="p4 m-t-s">
        Should you have active prescriptions, please contact them as soon as you can to prevent delays in processing your order.
      </p>
    </>
  }

  const renderContactYourClinicModal = () => {
    return <GenericPopup
      show={showContactClinicPopup}
      title={<div className='p-t-s'>Please contact your clinic</div>}
      text={renderParagraphsForModal()}
      buttons={renderOkButtonForModal()}
      onClose={() => { setShowContactClinicPopup(false) }}
    />
  }

  return (
    <section className="profile">
      {renderContactYourClinicModal()}
      <AccountHeader color="green" />
      <div className="profile__banner">
        <div>
          <h1 className="h2-a">My profile</h1>
          <p className="p4">
            Here you can make changes to your profile. Please ensure that your
            contact details and address are up to date so that we can ensure
            your order is processed on time.
          </p>
        </div>
      </div>

      <div className="profile__body">
        <div className="profile__content">
          <div className="profile__cards">
            <div className="user_details__header">
              <p className="p3--semi-bold">Your details</p>
              <p
                className="text_link"
                onClick={() => { setShowContactClinicPopup(true) }}
              >
                Need to update your details?
              </p>
            </div>

            <div>
              <UserDetailsCard content={userDetailsCardContent} />
              {patient.organisationId == TMCC_CLINIC_ID && (<UserDetailsCard content={subscriptionDetailsCardContent} />)}
              <ChangePasswordCard email={user.email} />
            </div>
          </div>

        </div>
      </div>

      <div className="profile__footer">
        <AccountFooter />
      </div>
    </section>
  );
}

export default PatientProfile;

function getUserDetailsCard(patient) {
  return [
    {
      title: "FULL NAME",
      text: `${patient?.title} ${patient?.firstName} ${patient?.lastName}`,
    },
    {
      title: "DATE OF BIRTH",
      text: `${patient?.dateOfBirth?.day}-${patient?.dateOfBirth?.month}-${patient?.dateOfBirth?.year}`,
    },
    {
      title: "ADDRESS",
      text: `${patient?.address1}, ${patient?.address2}, ${patient?.zipCode}`,
    },
    {
      title: "CONTACT NUMBER",
      text: `${patient?.contactNumber?.areaCode} ${patient?.contactNumber?.areaCode ? " " : ""} ${patient?.contactNumber?.number}`,
    },
  ];
}

function getUserSubscriptionDetailsCard(patient) {
  const isPAYG = patient.subscriptionType === 0;

  return [
    {
      title: "MEMBERSHIP",
      text: `
        ${callMapper(genericSubscriptionTextMapper, patient?.subscriptionType, `Unknown subscription type`)}
        ${isPAYG ? `(Upgrade to increase savings)` : " - "}
        ${!isPAYG && patient?.subscriptionTier === 1 ? "Gold" : !isPAYG && patient?.subscriptionTier === 2 ? "Platinum" : ""}`,
    },
    ...!isPAYG ? [{
      title: "MEMBER SINCE",
      text: `${moment(patient?.subscriptionCreatedAt).format("DD-MMMM-YYYY")}`,
    }] : [],
    //This needs to be ommited for phase 1 of subscriptions because the Platinum tier is not yet available
    // {
    //   title: "Change your membership status",
    //   customContent: <p className="p4--normal">
    //     If you haven’t already, upgrade your subscription to Platinum and unlock more prescription order savings.
    //     <br />
    //     <br />
    //     Have questions or need assistance? Reach out to our team through Live Chat on lyphe.com or drop us an email at <a href="mailto:info@lyphe.com" target="_blank" rel="noopener noreferrer">info@lyphe.com</a> to discuss your membership status or to make changes.
    //   </p>
    // },
  ];
}