import React from "react";

function InstagramIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="black" />
      <path d="M9.06 5H14.94C17.18 5 19 6.82 19 9.06V14.94C19 16.0168 18.5723 17.0495 17.8109 17.8109C17.0495 18.5723 16.0168 19 14.94 19H9.06C6.82 19 5 17.18 5 14.94V9.06C5 7.98322 5.42775 6.95054 6.18915 6.18915C6.95054 5.42775 7.98322 5 9.06 5ZM8.92 6.4C8.25165 6.4 7.61068 6.6655 7.13809 7.13809C6.6655 7.61068 6.4 8.25165 6.4 8.92V15.08C6.4 16.473 7.527 17.6 8.92 17.6H15.08C15.7483 17.6 16.3893 17.3345 16.8619 16.8619C17.3345 16.3893 17.6 15.7483 17.6 15.08V8.92C17.6 7.527 16.473 6.4 15.08 6.4H8.92ZM15.675 7.45C15.9071 7.45 16.1296 7.54219 16.2937 7.70628C16.4578 7.87038 16.55 8.09294 16.55 8.325C16.55 8.55706 16.4578 8.77962 16.2937 8.94372C16.1296 9.10781 15.9071 9.2 15.675 9.2C15.4429 9.2 15.2204 9.10781 15.0563 8.94372C14.8922 8.77962 14.8 8.55706 14.8 8.325C14.8 8.09294 14.8922 7.87038 15.0563 7.70628C15.2204 7.54219 15.4429 7.45 15.675 7.45ZM12 8.5C12.9283 8.5 13.8185 8.86875 14.4749 9.52513C15.1313 10.1815 15.5 11.0717 15.5 12C15.5 12.9283 15.1313 13.8185 14.4749 14.4749C13.8185 15.1313 12.9283 15.5 12 15.5C11.0717 15.5 10.1815 15.1313 9.52513 14.4749C8.86875 13.8185 8.5 12.9283 8.5 12C8.5 11.0717 8.86875 10.1815 9.52513 9.52513C10.1815 8.86875 11.0717 8.5 12 8.5ZM12 9.9C11.443 9.9 10.9089 10.1212 10.5151 10.5151C10.1212 10.9089 9.9 11.443 9.9 12C9.9 12.557 10.1212 13.0911 10.5151 13.4849C10.9089 13.8788 11.443 14.1 12 14.1C12.557 14.1 13.0911 13.8788 13.4849 13.4849C13.8788 13.0911 14.1 12.557 14.1 12C14.1 11.443 13.8788 10.9089 13.4849 10.5151C13.0911 10.1212 12.557 9.9 12 9.9Z" fill="white" />
    </svg>
  );
}

export default InstagramIcon;
