import moment from "moment";
import { OrderStatuses, OrderState, ShippingStatus, TransactionTypes } from "../enums";
import { v4 as uuidv4 } from 'uuid';
class PrescriptionConverter {
  processedPrescriptions = [];

  /** used as a unique index for each order/prescription
  it is incremented every time a prescription is added*/
  currentIndex = 0;

  protoPrescriptionApiToClient = (paginatedResult) => {
    // reset data
    this.processedPrescriptions = [];
    this.currentIndex = 0;

    for (let prescription of paginatedResult.items)
      this._processProtoPrescription(prescription);
  };

  _processProtoPrescription = (prescription) => {
    const processedPrescription = this._getProtoPrescription(prescription);

    processedPrescription.index = this.getAndIncrementIndex();

    this.processedPrescriptions.push(processedPrescription);
  };

  _getProtoPrescription = (prescription) => {
    return {
      prescriptionId: prescription.id,
      prescribedBy: prescription.organisationEmployee
        ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}`
        : ``,
      filePath: prescription.filePath,
      createdAt: moment(prescription.createdAt).format("DD/MM/YYYY"),
      prescriptionUploadDate: moment(prescription.createdAt).format("DD/MM/YYYY"),
      organisationEmployee: prescription.organisationEmployee,
      envelopeTrackingCode: prescription.envelopeTrackingCode,
      organisationId: prescription.organisationId
    };
  };

  apiToClient = (prescriptions) => {
    // reset data
    this.processedPrescriptions = [];
    this.currentIndex = 0;

    for (let prescription of prescriptions)
      this._processPrescription(prescription);
  };

  _processPrescription = (prescription) => {
    if (prescription.orders === undefined || prescription.orders.length === 0) {
      this._processUnApprovedPrescription(prescription);

      return;
    }
    this._processOrders(prescription);
  };

  _processUnApprovedPrescription = (prescription) => {
    const unApprovedPrescription = this._getPrescription(prescription);
    unApprovedPrescription.status = unApprovedPrescription.isArchived
      ? OrderStatuses.Archived
      : OrderStatuses.NotApproved;

    // index is used to uniquely identify a prescription/order
    unApprovedPrescription.index = this.getAndIncrementIndex();

    this.processedPrescriptions.push(unApprovedPrescription);
  };

  _processOrders = (prescription) => {
    for (let order of prescription.orders) {
      if (order.status === OrderState.Cancelled) {
        continue
      }
      const processedOrder = this._getProcessedOrder(prescription, order);

      processedOrder.index = this.getAndIncrementIndex();

      this.processedPrescriptions.push(processedOrder);
    }
  };

  _getProcessedOrder = (prescription, order) => {
    const processedPrescription = this._getPrescription(prescription);

    let transaction = order.transactions ? order.transactions.find(t => t.type === TransactionTypes.Payment) : null;

    const products = order.orderedProducts ? this._processProducts(order, prescription) : null;

    const status = this._getOrderStatus(prescription, order);

    return {
      ...processedPrescription,
      isPaymentRequested: order.isPaymentRequested,
      orderId: order.id,
      products,
      status,
      externalTrackingCode: order.externalTrackingCode,
      invoiceFilePath: transaction?.invoiceFilePath,
      guid: order.guid,
      createdAt: moment(order.createdAt).format("DD/MM/YYYY"),
      shipmentCost: order.shipmentCost,
      isSplit: order.isSplit,
    };
  };

  _getPrescription = (prescription) => {
    return {
      prescriptionId: prescription.id,
      paperPrescriptionId: prescription.paperPrescriptionId,
      prescribedBy: prescription.organisationEmployee
        ? `${prescription.organisationEmployee.firstName} ${prescription.organisationEmployee.lastName}`
        : ``,
      patient: prescription.patient,
      tempPatient: prescription.tempPatient,
      filePath: prescription.filePath,
      isProcessed: prescription.isProcessed,
      createdAt: moment(prescription.createdAt).format("DD/MM/YYYY"),
      prescriptionUploadDate: moment(prescription.createdAt).format("DD/MM/YYYY"),
      organisationEmployee: prescription.organisationEmployee,
      isT21Patient: prescription.isT21Patient,
      rewriteReason: prescription.rewriteReason,
      rewriteAdditionalComment: prescription.rewriteAdditionalComment,
      archivedReason: prescription.archivedReason,
      rewriteDateTime: prescription.rewriteDateTime,
      clinicId: prescription.clinic ? prescription.clinic.id : "",
      prescribedDateTime: prescription.prescribedDateTime,
      expirationDateTime: prescription.expirationDateTime,
      isPaperPrescriptionReceived: prescription.isPaperPrescriptionReceived
    };
  };

  _processProducts = (order, prescription) => {
    const date = prescription.prescribedDateTime ? moment(prescription.prescribedDateTime).format("DD/MM/YYYY") : prescription.prescribedDateTime;

    // process products in order
    const result = [];

    for (let orderedProduct of order.orderedProducts) {
      if (orderedProduct.isCancelled) {
        continue;
      }
      const product = this._getProcessedProduct(orderedProduct);

      product.date = date;

      result.push(product);
    }

    return result;
  };

  _getProcessedProduct = (orderedProduct) => {
    return {
      name: orderedProduct.product.name,
      thc: orderedProduct.product.thc,
      cbd: orderedProduct.product.cbd,
      type: orderedProduct.product.type,
      description: orderedProduct.product.description,
      dailyDosage: orderedProduct.dailyDosage,
      medicineDuration: orderedProduct.medicineDuration,
      titration: orderedProduct.titration,
      titrationCadence: orderedProduct.titrationCadence,
      maxTitration: orderedProduct.maxTitration,
      usage: orderedProduct.usage,
      isCanceled: orderedProduct.isCancelled,
      retailPrice: orderedProduct.retailPrice,
      fullPrice: orderedProduct.fullPrice,
      amount: orderedProduct.amount,
    };
  };

  _getOrderStatus = (prescription, order) => {
    let status = this._checkIfPastPrescription(prescription);

    if (status !== undefined) {
      status = this._isPrescriptionVoidAndOrderIsShipped(status, order);
      if (order.status === OrderState.Cancelled) {
        return OrderStatuses.Cancelled
      }
      return status;
    }

    status = this._getOrderStateFromOrderMapper(prescription, order);

    return status;
  };

  _checkIfPastPrescription = (prescription) => {
    if (prescription.isArchived) return OrderStatuses.Archived;

    const isExpired = this._isPrescriptionVoid(prescription.expirationDateTime);

    if (!isExpired && prescription.isCompleted) return OrderStatuses.Complete;

    if (isExpired && prescription.isCompleted)
      return OrderStatuses.CompletedAndVoid;

    if (isExpired && !prescription.isCompleted) return OrderStatuses.Void;
  };

  _isPrescriptionVoid = (expirationDate) => {
    const currentTimeString = moment().format("YYYY-MM-DD");
    const currentDate = moment(currentTimeString, "YYYY-MM-DD");

    expirationDate = moment(expirationDate);

    return expirationDate.isBefore(currentDate);
  };

  _isPrescriptionVoidAndOrderIsShipped(status, order) {
    if (
      status === OrderStatuses.Void &&
      order.shippingStatus === ShippingStatus.SentFromPharmacy
    ) {
      return OrderStatuses.Shipped;
    }

    return status;
  }

  _getOrderStateFromOrderMapper = (prescription, order) => {
    if (order.status === OrderState.Cancelled) return OrderStatuses.Cancelled;

    let status = this._checkIfPaymentDeadlineWasMissed(prescription, order);

    if (status !== undefined) return status;

    const stateString = this._getOrderStatusString(prescription, order);
    status = this.orderStatesMapper[stateString];

    return status;
  };

  _checkIfPaymentDeadlineWasMissed = (prescription, order) => {
    const now = moment();
    const paymentExpirationDate = moment(order.paymentExpirationDate).add(1, 'd'); //one day is added in order to support payment on the day of expiration

    const isPaymentDeadlineMissed =
      !order.isPaymentReceived && paymentExpirationDate.isBefore(now);

    if (isPaymentDeadlineMissed && prescription.isPaperPrescriptionReceived)
      return OrderStatuses.PaperAndPaymentMissed;

    if (isPaymentDeadlineMissed && !prescription.isPaperPrescriptionReceived)
      return OrderStatuses.NoPaperAndPaymentMissed;
  };

  /**
   * Converts a set of flags found in prescription and order
   * to a binary number where True corresponds to 1 and False
   * corresponds to 0.
   *
   * @param {*} prescription
   * @param {*} order
   */
  _getOrderStatusString = (prescription, order) => {
    const { isPaperPrescriptionReceived } = prescription;
    const {
      isStockReserved,
      isPaymentReceived,
      isForceCompleted,
      shippingStatus,
    } = order;

    return (
      // order and prescription flags
      `${+isStockReserved}` +
      `${+isPaymentReceived}` +
      `${+isPaperPrescriptionReceived}` +
      `${+isForceCompleted}` +
      ` ` +
      // shipping status
      `${+(shippingStatus === ShippingStatus.NotShipped)}` +
      `${+(shippingStatus === ShippingStatus.SentFromWarehouse)}` +
      `${+(shippingStatus === ShippingStatus.ReceivedByPharmacy)}` +
      `${+(shippingStatus === ShippingStatus.SentFromPharmacy)}` +
      `${+(shippingStatus === ShippingStatus.ReceivedByCustomer)}`
    );
  };

  getAndIncrementIndex = () => {
    const result = this.currentIndex;

    this.currentIndex++;

    return result;
  };

  getProcessedPrescriptions(toBeExcluded = [OrderStatuses.Cancelled, OrderStatuses.Archived]) {
    //sorting is now done serverside
    //let tempPrescriptions =  this._sortProccessedPrescriptionsNewestToOldest();
    let tempPrescriptions = this.processedPrescriptions;

    let prescriptionsWithoutExcludedOnes = this._excludeCertainStatuses(toBeExcluded, tempPrescriptions);
    /* Assign a new index to each prescription because we are changing
     the order of the prescriptions within the array and removing some
      to which produces a bug with the index not being the correct one . */
    return prescriptionsWithoutExcludedOnes.map((k, index) => {
      k.index = uuidv4()
      return k
    })
  };

  _sortProccessedPrescriptionsNewestToOldest() {

    const sortFunction = (a, b) => {
      let aDate = moment(a.createdAt, 'DD/MM/YYYY');
      let bDate = moment(b.createdAt, 'DD/MM/YYYY');
      return bDate.diff(aDate);
    }

    return this.processedPrescriptions.reverse().sort(sortFunction);
  };

  _excludeCertainStatuses(toBeExcluded = [], prescriptions) {
    return prescriptions.filter((p => {
      return !toBeExcluded.includes(p.status);
    }))
  };

  orderToBePaidApiToClient(order) {

    const orderedProducts = order.orderedProducts.map(((p, i) => {
      return {
        name: p.product ? p.product.name : `Product ${i + 1}`,
        price: p.product ? p.fullPrice : p.price,
        amount: p.amount,
      }
    }))

    let convertedOrder = {
      ...order,
      orderedProducts
    }
    return convertedOrder
  }

  _getPriceWithoutShipping = (order) => {
    let reducer = (accumulator, currentValue) => {
      if (currentValue.isCancelled === true) {
        return accumulator;
      }
      return accumulator + currentValue.amount * currentValue.fullPrice
    }
    return order.orderedProducts.reduce(reducer, 0)
  }

  /**
   * Digits in these numbers (keys of the object) represent boolean values of
   *
   *  Status string format
   *
   * `{isStockReserved}{isPaymentReceived}{isPaperPrescriptionReceived}{isCompleted}
   *  ` `
   *  {notShipped}{SentFromWarehouse}{ReceivedByPharmacy}{SentFromPharmacy}{SentToPatient}`
   */
  orderStatesMapper = {
    "0000 10000": OrderStatuses.OutOfStock,
    "0010 10000": OrderStatuses.OutOfStockAndPaper,
    "1000 10000": OrderStatuses.NotPaidAndNoPaper,
    "1010 10000": OrderStatuses.NotPaidAndPaper,
    "1100 10000": OrderStatuses.PaidAndNoPaper,
    "1110 10000": OrderStatuses.PaidAndPaper,

    "1110 01000": OrderStatuses.PaidAndPaper,
    "1110 00100": OrderStatuses.PaidAndPaper,
    "1110 00010": OrderStatuses.Shipped,
    "1110 00001": OrderStatuses.Complete,
    "1111 00011": OrderStatuses.Complete,
    "1111 00010": OrderStatuses.Complete,
    "1111 00000": OrderStatuses.Complete,
    "1111 00100": OrderStatuses.Complete,
    "1111 01000": OrderStatuses.Complete,
    "1111 10000": OrderStatuses.Complete,
    "1011 10000": OrderStatuses.Complete,
  };
}

export const prescriptionConverter = new PrescriptionConverter();