import React from 'react'

const MagnifyingGlass = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4474 12.0699L19.9399 18.5624C19.959 18.5815 19.9741 18.6041 19.9844 18.6289C19.9947 18.6538 20 18.6805 20 18.7074C20 18.7343 19.9947 18.761 19.9844 18.7859C19.9741 18.8108 19.959 18.8334 19.9399 18.8524L18.8499 19.9399C18.8309 19.959 18.8083 19.9741 18.7834 19.9844C18.7585 19.9947 18.7318 20 18.7049 20C18.678 20 18.6513 19.9947 18.6264 19.9844C18.6016 19.9741 18.579 19.959 18.5599 19.9399L12.0674 13.4499C10.765 14.4549 9.17496 14.9999 7.49997 14.9999C5.49498 14.9999 3.61248 14.2199 2.19749 12.8024C0.782496 11.3874 0 9.50246 0 7.49997C0 5.49498 0.779996 3.61248 2.19749 2.19749C3.61248 0.782496 5.49748 0 7.49997 0C9.50246 0 11.3874 0.779996 12.8024 2.19749C14.2174 3.61498 14.9999 5.49498 14.9999 7.49997C14.9999 9.17496 14.4549 10.7675 13.4474 12.0699ZM7.50009 13.0999C8.99508 13.0999 10.4001 12.5174 11.4601 11.46C12.5175 10.4025 13.1 8.99496 13.1 7.49997C13.1 6.00497 12.5175 4.59998 11.4601 3.53998C10.4026 2.47999 8.99508 1.89999 7.50009 1.89999C6.00509 1.89999 4.6001 2.48249 3.5401 3.53998C2.48261 4.59748 1.90011 6.00497 1.90011 7.49997C1.90011 8.99496 2.48261 10.4 3.5401 11.46C4.6001 12.5174 6.00509 13.0999 7.50009 13.0999Z" fill="black" />
        </svg>

    )
}

export default MagnifyingGlass
