import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { MyAccountDropdown, OrganisationSwitchDropdown } from "../../../dropdowns";
import { selectUser } from "../../../../store/slices/authenticationSlice";

import { Logo } from "../../../../assets/images";
import { UserRoles } from "../../../../enums";
import { callMapper } from "../../../../util/util";

function DesktopAccountHeader(props) {
  const { pathname } = useLocation();
  const history = useHistory();

  const user = useSelector(selectUser);

  const links = callMapper(linksMapper, user?.role, []);

  const test = links.find((item) => item.link === pathname);
  const [activeLink, setActiveLink] = useState(test?.link);

  const headerClass = props.color
    ? `main_header main_header--${props.color}`
    : "main_header";

  const handleLogoClick = () => {
    history.push("/prescriptions/active");
  }
  return (
    <header className={headerClass}>
      <nav className="main_header__content">
        <div className="main_header__nav">
          <ul>
            <div className="main_header__logo">
              <Logo onClick={handleLogoClick} color="black" />
            </div>

            {links.map(({ link, displayName }) => (
              <Link key={link} onClick={() => setActiveLink(link)} to={link}>
                <li className={activeLink === link ? "active" : ""}>
                  {displayName}
                </li>
              </Link>
            ))}

            <div className="main_header__upload">
              {user?.role === 3 && (
                <div style={{ display: "flex" }}>
                  <OrganisationSwitchDropdown />
                  <span
                    style={{
                      margin: "0 16px 0 16px",
                      width: "1.5px",
                    }}
                  ></span>
                </div>
              )}
            </div>
            <div className="main_header__my_account">
              <MyAccountDropdown textColor="black" />
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default DesktopAccountHeader;
// Test
const patientLinks = [
  { displayName: "Active Orders", link: "/prescriptions/active" },
  { displayName: "Past Orders", link: "/prescriptions/past" },
  { displayName: "Formulary", link: "/patientFormulary" },
];

const clinicUserLinks = [
  { displayName: "Prescriptions", link: "/clinicPrescriptions" },
  { displayName: "Patients", link: "/clinicPatients/full" },
  { displayName: "Formulary", link: "/clinicFormulary" },
];

const linksMapper = {
  [UserRoles.OrganisationEmployee]: clinicUserLinks,
  [UserRoles.Patient]: patientLinks,
  [UserRoles.Carer]: patientLinks,
  [UserRoles.ClinicUser]: clinicUserLinks,
}
