import React from 'react';

function FormRow(props) {
  return (
    <div style={props.style} className={`form-row ${props.className ? props.className : ''}`} >
      {props.children}
    </div>
  );
}

export default FormRow;