import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { RadioButton } from "../../../components/inputs";
import { TermsAndConditions } from "../../../components/onboarding";
import { usePasswordInput, useProps, useRadioButton } from "../../../hooks";
import {
  setCredentials,
  uploadPrescriptionPatient,
  selectRegisteringPatient,
  selectUploadingPrescriptionPatient,
  selectPatient,
  completePatientAccount as completePatientAccountAction,
  registerPatient as registerPatientAction,
} from "../../../store/slices/patientOnboardingSlice";
import { emailValidator } from "../../../util/validators";
import { isFormValid, trackAnalyticsEvent } from "../../../util/util";
import { LoadingOverlay } from "../../../components/overlays";
import { CredentialsForm } from "../../../components/forms";
import { NoticeCard } from "../../../components/cards";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";

function PatientCompleteAccount({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const registeringPatient = useSelector(selectRegisteringPatient);
  const uploadingPrescription = useSelector(selectUploadingPrescriptionPatient);
  const patient = useSelector(selectPatient);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const email = useProps(patient?.email || "", emailValidator());
  const [password, confirmPassword, checkIfPasswordsMatch] = usePasswordInput();
  const credentialsForm = { email, password };

  const agreedToTerms = useRadioButton(false);
  const [readTerms, setReadTerms] = useState(false);

  const isButtonEnabled =
    isFormValid(credentialsForm) &&
    confirmPassword.value &&
    agreedToTerms.value &&
    readTerms;

  async function continueClicked() {
    try {
      checkIfPasswordsMatch();

      const payload = {
        email: email.value.trim().toLowerCase(),
        password: password.value,
      };

      dispatch(setCredentials(payload));

      if (patient.secretId) {
        await completePatientAccount();

        return;
      }

      const patientId = await registerPatient();

      await uploadPrescription(patientId);
    } catch (error) { }
  }

  async function completePatientAccount() {
    const { error } = await dispatch(completePatientAccountAction());

    if (error) {
      email.setErrors(["Email is already taken"]);

      throw new Error("Email is already taken");
    }

    setNextStep(steps.end);
  }

  async function registerPatient() {
    const { payload, error } = await dispatch(registerPatientAction());

    if (error) {
      email.setErrors(["Email is already taken"]);

      throw new Error("Email is already taken");
    }

    return payload.id;
  }

  async function uploadPrescription(patientId) {
    const { error } = await dispatch(uploadPrescriptionPatient(patientId));

    if (error) {
      throw new Error("Upload prescription failed");
    }

    trackAnalyticsEvent("Patient Onboarding", "Patient account info added and Agreed Terms");
    setNextStep(steps.end);
  }

  // user needs to scroll to bottom to continue
  function scrolledToBottom() {
    setReadTerms(true);
  }

  return (
    <section className="one_last_step">
      <LoadingOverlay show={registeringPatient} title="Setting you up" />
      <LoadingOverlay
        show={uploadingPrescription}
        title="Uploading your prescription"
      />

      <div className="one_last_step__content">
        <div className="one_last_step__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">One last step</h1>
          <p className="p5">
            Please tell us your email address and create a password to complete
            your account.
          </p>
        </div>

        <div className="one_last_step__form">
          <CredentialsForm
            email={email}
            password={password}
            confirmPassword={confirmPassword}
            isEnabled={!patient.secretId}
          />
        </div>

        <div className="one_last_step__terms">
          <div className="m-b-mm">
            <NoticeCard
              titleTop="Before you proceed"
              textTop="To complete your setup you are required to review the Terms and conditions in their entirety and acknowledge that you accept these conditions. "
            />
          </div>
          <TermsAndConditions scrolledToBottom={scrolledToBottom} />
        </div>

        <div className="one_last_step__accept_terms radio-container">
          <RadioButton
            isEnabled={readTerms}
            id="accept_terms"
            name="I agree to the Lyphe Dispensary Terms and Conditions"
            {...agreedToTerms}
          />
        </div>

        <div className="one_last_step__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={continueClicked}
            text={registeringPatient ? <Spinner /> : "Continue"}
          />
        </div>
      </div>
    </section>
  );
}

export default PatientCompleteAccount;
