import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { useProps } from "../../../hooks";
import {
  setPatientName,
  selectCarer,
} from "../../../store/slices/carerOnboardingSlice";
import { NameForm } from "../../../components/forms";
import { nameValidator } from "../../../util/validators";
import { propsToValues, isFormValid, trackAnalyticsEvent } from "../../../util/util";

function CarerPatientName({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const carer = useSelector(selectCarer);

  // scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = useProps(carer.patientTitle || "");
  const firstName = useProps(
    carer.patientFirstName || "",
    nameValidator("First name")
  );
  const lastName = useProps(
    carer.patientLastName || "",
    nameValidator("Last name")
  );
  const nameForm = { title, firstName, lastName };

  const isButtonEnabled = isFormValid(nameForm);

  function nextClicked() {
    const payload = propsToValues(nameForm);
    dispatch(setPatientName(payload));

    trackAnalyticsEvent("Carer Onboarding", "Patient Name Added by Carer");

    // if completing an account skip prescription upload
    if (carer.secretId) {
      setNextStep(steps.carerPatientsAddress);
      return;
    }

    setNextStep(steps.carerDoYouHavePrescription);
  }

  return (
    <section className="name">
      <div className="name__content">
        <div className="name__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">What is your patients name?</h1>
        </div>

        <form className="name__form">
          <NameForm {...nameForm} />
        </form>
        <div className="name__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={nextClicked}
            text="Next"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerPatientName;
