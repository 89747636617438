import React from "react";
import { useSelector } from "react-redux";

import { selectResubmitLoading } from "../../../store/slices/prescriptionsSlice";
import { FullScreenOverlay } from "../../overlays";
import { LoadingCard } from "../../cards";

import { LoadingIcon } from "../../../assets/icons";

function ResubmitLoadingOverlay(props) {
  const resubmitLoading = useSelector(selectResubmitLoading);

  return (
    <FullScreenOverlay show={resubmitLoading}>
      <LoadingCard
        cardTitle="Checking stock availability"
        Icon={<LoadingIcon />}
      ></LoadingCard>
    </FullScreenOverlay>
  );
}

export default ResubmitLoadingOverlay;
