import React from "react";
import { useSelector } from "react-redux";
import { MoreInfoIcon } from "../../../../../assets/icons";
import { selectUser } from "../../../../../store/slices/authenticationSlice";
import { QuaternaryCta } from "../../../../buttons";
import AreTheseDetailsCorrectButton from "../../../../buttons/AreTheseDetailsCorrectButton/AreTheseDetailsCorrectButton";

import ProductDetails from "../../OrderProducts/Product/ProductDetails/ProductDetails";

function MobileOrderHeader({
  buttons = { left: [], right: [] },
  content = {},
  numberOfProductsText = 0,
  OrderStatusIcon = null,
  productDetails = {},
  isOrderTracked = false,
  order
}) {
  const user = useSelector(selectUser);
  const className = `mobile_order__header__top${isOrderTracked ? " tracked" : ""
    }`;

  return (
    <div className="mobile_order__header">

      <div className={className}>
        <div className="mobile_order__header__top__wrapper">
          <div className="mobile_order__header__icon">
            <OrderStatusIcon />
          </div>
          <p className="p6">{`${numberOfProductsText} IN THIS ORDER`} </p>
        </div>
        <h5 className="h5-a--serif">{content.title}</h5>
        <div className="mobile_order__header__details">
          <ProductDetails content={productDetails} column />
        </div>
      </div>

      <div className="mobile_order__header__text">
        <div className="p5">{content.text}</div>
      </div>

      {(user?.role === 1 || user?.role === 2) ?
        <AreTheseDetailsCorrectButton />
        :
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <QuaternaryCta
            text="Report a problem"
            icon={<MoreInfoIcon size={17} />}
            link={`/report/${order.orderId}`}
          />
        </div>}

      <div className="mobile_order__header__buttons">
        <div className="mobile_order__header__buttons__left">
          {buttons.left}
        </div>
        <div className="mobile_order__header__buttons__right">
          {buttons.right}
        </div>
      </div>
    </div>
  );
}

export default MobileOrderHeader;
