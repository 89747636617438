import React from "react";
import { useDispatch } from "react-redux";

import { QuaternaryCta } from "..";

import { PrescriptionIcon } from "../../../assets/icons";
import { setPrescriptionModal } from "../../../store/slices/modalsSlice";

function ViewPrescriptionButton({ order }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setPrescriptionModal({ show: true, image: order.filePath }));
  }

  return (
    <div>
      <QuaternaryCta
        text="View prescription"
        icon={<PrescriptionIcon size={17} />}
        onClick={handleClick}
      />
    </div>
  );
}

export default ViewPrescriptionButton;
