import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrimaryCta } from '../../components/buttons';
import { getCurrentClinicDetails, selectCurentClinicDetails } from '../../store/slices/repeatPrescriptionSlice';

export default function NoTMCC() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCurrentClinicDetails())

        // eslint-disable-next-line
    }, [])

    const currentClinicDetails = useSelector(
        selectCurentClinicDetails
    );

    const history = useHistory()

    const handleLogoClick = () => {
        history.push("/prescriptions/active");
    }
    return (
        <div className='repeat_prescription--content'>
            <div className='content_heading--noTmcc'>
                <span>Request a repeat prescription</span>
                <h1>Repeat prescriptions</h1>
                <p>Get in touch with your clinic to request a repeat prescription</p>
            </div>
            <div className='content_text--noTmcc'>
                <h3>Clinic details</h3>
                <div className='divider'></div>
                <span>name</span>
                <p>{currentClinicDetails.name}</p>
                <span>email</span>
                <p>{currentClinicDetails.email}</p>
                <span>Contact number</span>
                <p>{currentClinicDetails.telephoneNo}</p>
                <span>Address</span>
                <p>{currentClinicDetails.address1}, {currentClinicDetails.address2}, {currentClinicDetails.zipCode}</p>
            </div>
            <div className='content_button'>
                <PrimaryCta
                    text={"Back to account"}
                    color='white'
                    className='primary_cta--fit_content'
                    onClick={handleLogoClick}
                />
            </div>
        </div>
    )
}
