import React from "react";

function OrderPreview({ data }) {
    return (
        <div className="table-cell-tooltip">
            <div>
                <span>{data.title}</span>
            </div>

            {data.rows.map((row, index) => (
                <div key={index} >
                    <span className={row.color} >{row.text}</span>
                </div>
            ))}
        </div>
    );
}

export default OrderPreview;