import React from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { privacyPolicyUrl } from '../../../config.json'

function TermsAndConditions(props) {
  const { scrolledToBottom } = props;

  // user needs to scroll to the bottom
  const scrollRef = useBottomScrollListener(() => scrolledToBottom());

  return (
    <div className="terms_and_conditions">
      <div ref={scrollRef}>
        <h5><u><strong>Terms &amp; conditions</strong></u></h5>
        <p>Lyphe Dispensary is your one-stop&nbsp;shop for managing special medicine prescriptions. We take pride in the high-level service delivered through our prescription platform, and patient support. Our excellent business standards extend to our terms &amp; conditions, privacy practices, and the safeguarding of your personal information.</p>
        <h3>1. Application of terms</h3>
        <p>These Terms &amp; Conditions (<strong>&ldquo;</strong><strong>T</strong><strong>erms&rdquo;</strong>) apply to your use of the <a href="http://www.Lyphe.com"><u>www.Lyphe.com</u></a> website (<strong>&ldquo;Website&rdquo;</strong>) and every order placed with Lyphe Dispensary as set out below. Lyphe Dispensary means Prescriptions Limited t/a Lyphe Dispensary, a company incorporated in England and Wales under company number&nbsp;<strong>08681796</strong>&nbsp;whose registered office is 20 &ndash; 22 Wenlock Road, London, England, N1 7GU and is referred to in these Terms as&nbsp;<strong>&ldquo;Lyphe Dispensary&rdquo;/ &ldquo;we&rdquo;/ &ldquo;us&rdquo;</strong><strong>/&ldquo;</strong><strong>our&rdquo;</strong>. Our VAT number is 358 3474 69. Lyphe Dispensary is registered with the General Pharmaceutical Council - registration number: 112608).</p>
        <p>These terms apply to:</p>
        <ul>
          <li>Your use of Lyphe Dispensary sites, apps, and services delivered by Lyphe Dispensary as described below in (ii) and (iii) (each or together the&nbsp;<strong>&ldquo;Service&rdquo;</strong>) and by accepting these terms you enter a contract with us for the provision of the Service.</li>
          <li>Any prescriptions ordered with Lyphe Dispensary (contact details set out above). By accepting these terms, when you place an order with Lyphe Dispensary, you enter a contract with Lyphe Dispensary&rsquo;s pharmacy for the provision of that prescription.</li>
          <li>Any prescriptions ordered with Lyphe Dispensary may be delivered by one of our partners (including East Stone Limited) (<strong>&ldquo;Partner&rdquo;</strong>).</li>
          <li>The Service may facilitate the provision of services from prescribing doctors, Partners, Parcel Force, or other members of Lyphe Dispensary&rsquo;s group, being its holding company and subsidiaries of its holding company (<strong>&ldquo;Group&rdquo;</strong>). Those entities are not a party to these Terms and may have separate legal terms and arrangements covering their services as explained in these Terms.</li>
        </ul>
        <p>If you do not accept these Terms, then you should not use Lyphe Dispensary.</p>
        <h3>2. What is Lyphe Dispensary?</h3>
        <p>Lyphe Dispensary is a web-based service that enables you (the&nbsp;<strong>&ldquo;Account Holder&rdquo;</strong>) to process current or repeat prescriptions from your prescribing doctor or other registered doctors for delivery through a Partner.</p>
        <p>Approved prescriptions will be transmitted to and dispensed by Lyphe Dispensary&rsquo;s pharmacy. The Account Holder can also make requests on behalf of someone that they are acting as a carer if they have been given authority to represent them (proxy access). In addition, a doctor may be an Account Holder for the purposes of obtaining products for which he/she has issued a prescription.</p>
        <p>The Account Holders&rsquo; contract with us is only to provide an intermediary/facilitation service.</p>
        <h3>3. How does Lyphe Dispensary work?</h3>
        <p>Access the Lyphe Dispensary website referred to above, register your details, and confirm you have a prescription. The website will prompt you to add the relevant information and verify your identity. If you have a prescription, you must upload a copy of the original prescription.</p>
        <p>If you do not have a prescription, you will be prompted to the services of UKMCCS, a governing body for Medical Cannabis Clinicians where there is a list of clinic providers, although you may access other organisations and prescribing doctors at your discretion to obtain a prescription. Once an order has been placed, the transaction is processed manually at the premises of Lyphe Dispensary or its Partner by its registered pharmacists who are members of the General Pharmaceutical Council.</p>
        <p>This website should be regarded as an invitation to treat. Any order placed by a customer is an offer to purchase, which Lyphe Dispensary may accept or decline in its absolute discretion without having to give a reason. A confirmation email is a notification that we have received your offer on our website.</p>
        <p>A contract is deemed to be made at the point of a sale invoice being issued by Lyphe Dispensary following the checking of orders by a pharmacist. We only deliver prescription medication within the UK.</p>
        <p>To register with and use this site you must be over 18 and the details which you give us must be true. The contents of this website are directed solely to those who access the site from the UK. You will be responsible for your use of this site and for your orders so please ensure that you keep your log-in details secure so that no&nbsp;one else can misuse them.</p>
        <p>Risk and title in the products will pass to you when they are despatched by us to you, from the UK, and you acknowledge and agree to be responsible for complying with any restrictions around the receipt and use by you of the products.</p>
        <p>You agree that Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy may contact you to manage your order and any orders you placed on behalf of a patient that you have been authorised to act as a proxy. This can include contact via the website, telephone, SMS, email, or post. If we are not able to contact you, we may be unable to dispense your prescription.</p>
        <p>Lyphe Dispensary will endeavor to ensure that all prescription and medicines prices are correct on the website. In some cases, the prices may vary due to manufacturer price increases, product availability, and/or special-order requirement. In such cases, Lyphe Dispensary will contact you on receipt of the prescription to inform you of any price difference.</p>
        <p>We are legally only able to dispense medicines for you when we have received either an electronic prescription which has been digitally signed by a prescribing doctor or alternatively a conventional paper prescription signed in writing by a prescribing doctor. prescriptions are issued entirely at the discretion of the prescriber.</p>
        <h3>4. Can I manage other people&rsquo;s medication using Lyphe Dispensary?</h3>
        <p>Yes, if you are at least eighteen (18) years old and have been authorised to act on behalf of another or have the authority to represent someone else (as their proxy). If you wish to sign up as a proxy for someone else, you must first obtain consent from the patient. By signing up for someone as a proxy, you confirm that you have obtained consent from that person to act as their proxy to manage their medication. As part of our safeguarding responsibilities, we may need to verify your identity when handling prescriptions for safe custody-controlled drugs. Please note this may result in an increase in the time taken to dispatch your medication.</p>
        <h3>5. Who is liable if something goes wrong?</h3>
        <p>There are different parties that make up the overall service provision to you and have different responsibilities and liabilities under these Terms or otherwise. We have separated these below.</p>
        <p>You are responsible for complying with these Terms. Please see the section below &ldquo;What are my obligations?&rdquo; for further details.</p>
        <h3>Lyphe Dispensary obligations</h3>
        <p>Lyphe Dispensary: Lyphe Dispensary&rsquo;s pharmacy is liable for ensuring that these prescriptions are dispensed correctly and in accordance with General Pharmaceutical Council standards and guidelines and these Terms.</p>
        <p>Lyphe Dispensary: is responsible for providing the Service in accordance with these Terms.</p>
        <p>Both Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy separately accept liability for death or personal injury caused by their negligence and do not seek to exclude liability for fraudulent misrepresentation. You have certain legal rights. These include that Lyphe Dispensary will provide the Service with reasonable skill and care and within a reasonable time and in accordance with the descriptions provided and that Lyphe Dispensary&rsquo;s pharmacy will dispense the agreed prescription.</p>
        <p>You have certain legal remedies if Lyphe Dispensary or Lyphe Dispensary&rsquo;s pharmacy breaches any of these rights. Nothing in these Terms is intended to affect these legal rights or other rights to which you may also be entitled. For more information on your legal rights contact your local Citizens Advice Service.</p>
        <p>Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy are not responsible for any failures or delays caused by you, the prescribing doctor, a Partner, or Parcel Force or for the failures or delays of each other.</p>
        <p>Neither Lyphe Dispensary nor Lyphe Dispensary&rsquo;s pharmacy are responsible for losses not caused by its breach, indirect losses, or a failure to provide the Service due to any cause beyond its reasonable control which prevents it from fulfilling our obligations including but not limited to fire, storm, riot, civil disturbance, war, nuclear accident, terrorist activity and acts of God.</p>
        <p>If Lyphe Dispensary, or Lyphe Dispensary&rsquo;s pharmacy, does breach these terms it shall only be liable for losses that are a reasonably foreseeable consequence of such a breach. Losses are foreseeable where they could be contemplated by you and Lyphe Dispensary or Lyphe Dispensary&rsquo;s pharmacy as relevant at the time of entering these Terms.</p>
        <p>Lyphe Dispensary has taken care of the preparation of the content of this website. To the fullest extent permitted by the law, Lyphe Dispensary disclaims all warranties of any kind with respect to the content of this website. Lyphe Dispensary and its directors or related companies shall not be liable for any losses or claims arising directly or indirectly from the use of this website except that this exclusion of liability does not apply to any damages in connection with death or personal injury caused by the negligence of Lyphe Dispensary, its directors, or employees.</p>
        <p>All content of this website is for information purposes only and is not a substitute for professional medical advice, rather it is designed to support, not replace, the relationship between you and your healthcare providers. You should make sure that you carefully read all product packaging and labels prior to use.</p>
        <p>The following parties are not subject to these Terms but carry certain responsibilities and obligations to you as set out below. In the event of an issue, you should contact Lyphe Dispensary in the first instance, and we may direct you to contact your prescribing doctor, the relevant Partner, or Parcel Force as appropriate.</p>
        <p>Your prescribing doctor: Your prescribing doctor is not a party to these terms, but they are responsible for issuing prescriptions in a timely manner and providing medical advice. Please note that all prescribing decisions are made by the prescribing doctor, not Lyphe Dispensary, not a Partner or Lyphe Dispensary&rsquo;s pharmacy.</p>
        <p>Our Partners: no Partner is a party to these Terms.</p>
        <p>Parcel Force: is responsible for delivering medication orders that you place via Lyphe Dispensary. They are also responsible for delivering any paper prescriptions posted to Lyphe Dispensary&rsquo;s pharmacy. Parcel Force is not a party to these Terms and has separate terms that cover the provision of their delivery service which you can review here:&nbsp;<a href="https://www.parcelforce.com/conditions-of-carriage"><u>https://www.parcelforce.com/conditions-of-carriage</u></a>.</p>
        <h3>What are my obligations?</h3>
        <p>You, the Account Holder, are responsible for the information you provide to us about yourself, your prescribing doctor, and your medication; this extends to when you are using Lyphe Dispensary on behalf of someone else as their proxy.</p>
        <p>You confirm that all information provided to us is and remains accurate. If you do not provide accurate information, you could be putting yourself or the person you are managing medication for at serious risk.</p>
        <p>By giving your consent for a pharmacy to access your health record from the clinic the pharmacist/pharmacy technician involved in providing care to you would be able to view your Summary Care Record to assess for any contraindications.&nbsp;You can change this permission at any point just by informing the pharmacy.</p>
        <p>You are responsible for checking and taking any medication correctly. You must check the spelling and dosage information and follow the clinical directions provided with all medication. You should review the patient information leaflet provided with the medication.</p>
        <p>If at any point the information that appears on Lyphe Dispensary contradicts the information provided by your prescribing doctor, please contact us immediately. You agree that the information provided by your prescribing doctor and in the patient information leaflet take precedence over any information provided by Lyphe Dispensary.</p>
        <p>You confirm that you are requesting medications for your personal use only or the use of someone you are acting as a proxy for. Medications should never be shared with any other person.</p>
        <p>You agree to keep your Lyphe Dispensary login details confidential and not to share them with any other person.</p>
        <p>If you are exempt from paying the prescription charge, you agree that you are legally responsible for ensuring that your exemption reason is correct, up-to-date, and maintained; this responsibility extends to any other people that are listed on your account.</p>
        <p>You agree not to use any Service provided by us for any unlawful purpose, including requesting medications that have not been prescribed to you or the person you are managing medication for by a registered healthcare professional.</p>
        <p>You agree not to use our websites or applications in any way that will damage, impair, or render them less efficient.</p>
        <p>Parcel Force and its terms and conditions will govern the delivery process.</p>
        <p>You acknowledge that Lyphe Dispensary is not responsible for incidents relating to the storage, handling, manufacture, delivery, use, misuse, or abuse of your medication. We reserve the right to suspend or terminate your access to Lyphe Dispensary at any time without notice if we have reasonable grounds to believe that you have breached any of these terms; this extends to any patients that you, the Account Holder have been authorised to act as a proxy for.</p>
        <h3>6. How does payment work?</h3>
        <p>You will be charged the price shown on the website, subject to changes referred to in these terms. This will appear on your bank statement as &lsquo;TAOMC. No medical information will be passed to your bank or credit card company. Payment for medication for other people that are listed on your account will also be processed in the same way.</p>
        <p>Our payment processing platform, Opayo, may also perform a pre-authorisation on the card you have signed up with to ensure your payment details are correct. Opayo and its terms and conditions will govern the payment processing process. Terms can be found here:&nbsp;<a href="https://www.opayo.co.uk/policies/merchant-terms-conditions"><u>https://www.opayo.co.uk/policies/merchant-terms-conditions</u></a>.</p>
        <h3>7. Your privacy</h3>
        <p>Your privacy is important to us. Please see our&nbsp;<a href="https://lyphe.com/privacy-policy/"><u>Privacy Policy</u></a>&nbsp;for details of what information we collect and how we will use and protect it. The information that you send to us will be processed in accordance with our&nbsp;<a href="https://lyphe.com"><u>Privacy Policy</u></a>.</p>
        <p>You authorise us, at any time, to use any means that we consider necessary to verify your identity and creditworthiness with any third-party providers of information.</p>
        <p><strong>Please note that in an emergency, we may need to contact a healthcare professional, social services, or emergency </strong><strong>services</strong><strong> without your consent, even </strong><strong>if</strong><strong> you have declined any of our offers to contact your prescribing doctor.</strong></p>
        <h3>8. Can I cancel my order?</h3>
        <p>You can always choose to stop using the Service at any time.</p>
        <p>You can cancel an order placed through the Service at any point until the medication is dispensed by Lyphe Dispensary&rsquo;s pharmacy and you will receive a full refund with the same method of payment.</p>
        <p>You can make such a cancellation by contacting us via the website. Alternatively, you can email us at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a>.</p>
        <p>Unfortunately, due to the nature of prescription medications, once your items have been dispensed, you will be unable to cancel the order, return the medication or receive a refund. Any unwanted medicines must be disposed of by returning the medication to a pharmacy or any authorised location.</p>
        <p>If you receive incorrect medication, dispatched in error, or damaged you should contact us via the website or at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a> to discuss a replacement or refund.</p>
        <h3>9. When will I receive my medication?</h3>
        <p>Your prescribing doctor may want to speak to you before prescribing so your prescription request may not be processed immediately. If you consult with your prescribing doctor, you should tell them that you would like your prescription to be sent electronically to your &lsquo;nominated&rsquo; pharmacy (Lyphe Dispensary).</p>
        <p>Your prescribing doctor may be delayed in issuing a repeat prescription. Lyphe Dispensary has no control over these delays; however, where possible, we will direct you to contact your prescribing doctor directly if we are informed of any issues with your request or your repeat prescription.</p>
        <p>Once the original prescription has been received by Lyphe Dispensary, we will dispense the medication. We or our Partner will then pack the medication and dispatch it via Parcel Force. It typically takes up to four working days for us to process your prescription. We cannot guarantee the exact time and day of arrival.</p>
        <h3>10. What laws apply and what rights do I have to make a complaint?</h3>
        <p>These Terms are governed by the laws of England and Wales and any dispute, question or remedy shall be determined exclusively by the courts of England and Wales. These Terms are only available in the English language. If any part of these Terms is disallowed or found to be ineffective by any court or regulator, the other provisions shall continue to apply. If you wish to make a complaint, you can contact us as set out below. These terms do not affect your statutory rights as a consumer.</p>
        <h3>11. How can I contact you?</h3>
        <p>The fastest way to contact us is via the website. Alternatively, you can email us at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a> or write to Prescriptions Limited, 20 &ndash; 22 Wenlock Road, London, N1 7GU. Should you wish to file a complaint please contact us via the methods listed above. We aim to acknowledge complaints within two working days.</p>
        <h3>12. Intellectual property</h3>
        <p>All designs, text, graphics, their selection, and arrangement on this website are the copyright of Lyphe Dispensary or a member of the Group. Permission is granted to users to electronically copy or print portions of this site for their own personal, non-commercial use. Any other use of materials on this site without Lyphe Dispensary&rsquo;s prior written consent is strictly prohibited.</p>
        <p>All brand names, products, service names, titles, and copyrights used in this site are trademarks, trade names, service marks, or copyrights of their respective holders, including members of the Group. No permission is given by Lyphe Dispensary for their use by any person other than the said holders and such use may constitute an infringement of the holders' rights. This contract is governed by English Law.</p>
        <h3>13. Cookies and other technologies</h3>
        <p>Cookies come in a variety of forms but are essentially small data files used to collect and store information about you. We use them on our website for a variety of different functions:</p>
        <ul>
          <li>for the smooth and safe operation of Lyphe Dispensary and our website.</li>
          <li>to manage your preferences and remember you for future visits.</li>
          <li>to analyse how you use Lyphe Dispensary and our website in order to continually make improvements.</li>
        </ul>
        <p>The majority of these cookies are linked to your browser session (session cookies) and disappear once you close your browser Others remain on your device for a longer period (persistent cookies).</p>
        <p>For further information about the cookies we use, please see our Cookie Policy.</p>
      </div>
      <div className="terms_and_conditions__gradient"></div>
    </div>
  );
}

export default TermsAndConditions;
