import React from "react";
import { useSelector } from "react-redux";

import { OrderStatuses } from "../../../../enums";
import { selectUser } from "../../../../store/slices/authenticationSlice";
import { NoticeBanner } from "../../../banners";
import Product from "./Product/Product";

function OrderProducts({ order }) {
  const user = useSelector(selectUser);
  return (
    <div className="order__products">
      {order.products?.map((product, index) => (
        <>
          <Product
            key={`${product.name}-${Math.random()}`}
            product={product}
            orderStatus={order.status}
          />
          {index !== order.products.length - 1 && <hr />}
        </>
      ))}
      {(order.status === OrderStatuses.Void && user?.role !== 3) && (
        <div className="p-b-xxs p-t-s ">
          <NoticeBanner text="Your prescription is now Void. Please ask your Doctor to re-issue your prescription, and resubmit it to us." />
        </div>
      )}
    </div>
  );
}

export default OrderProducts;
