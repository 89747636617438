import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TertiaryCta, PrimaryCta } from "../../../components/buttons";
import { RadioButton } from "../../../components/inputs";
import { PrescriptionCard } from "../../../components/cards";
import { TipsCarousel } from "../../../components/carousels";
import { ViewPrescription } from "../../../components/popups";

import { useRadioButton, useUploadFile } from "../../../hooks";
import { setPrescription as setPrescriptionAction } from "../../../store/slices/carerOnboardingSlice";
import {
  selectPrescriptionModal,
  setPrescriptionModal,
} from "../../../store/slices/modalsSlice";

import { UploadingTips } from "../../../enums";

import { UploadIcon } from "../../../assets/icons";
import { trackAnalyticsEvent } from "../../../util/util";

function CarerUploadPrescription({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const prescriptionModal = useSelector(selectPrescriptionModal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fileInputRef = useRef(null);
  const [
    prescription,
    prescriptionError,
    resetPrescription,
    fileSelected,
  ] = useUploadFile();

  const confirmConsent = useRadioButton(false);

  function deleteImage() {
    resetPrescription();
  }

  function submitClicked() {
    dispatch(setPrescriptionAction(prescription));

    trackAnalyticsEvent("Carer Onboarding", "Prescription image added by Carer");
    setNextStep(steps.carerPatientsAddress);
  }

  return (
    <section className="upload_prescription">
      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null }))
        }
      />
      <div className="upload_prescription__content">
        <div className="upload_prescription__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please upload a photo of the prescription</h1>
          <p className="p5">
            Make sure your photo meets the requirements below to avoid delays in
            processing your order.
          </p>
        </div>
        {prescription.url ? (
          <div className="upload_prescription__submit_prescription">
            <div className="upload_prescription__uploaded">
              <PrescriptionCard
                title="Uploaded prescription"
                image={prescription.url}
                imageName={prescription.name}
                onClick={deleteImage}
                actionName="Delete"
              />
            </div>

            <div className="upload_prescription__radio_button radio-container">
              <RadioButton
                id="accept_terms"
                name="I acknowledge that I have the patients consent to submit their prescription on their behalf"
                {...confirmConsent}
              />
            </div>

            <div className="upload_prescription__primary_button">
              <PrimaryCta
                isEnabled={confirmConsent}
                color="orange"
                text="Submit prescription"
                onClick={submitClicked}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="upload_prescription__carousel">
              <TipsCarousel tips={UploadingTips} />
            </div>

            <div className="upload_prescription__terms radio-container">
              <RadioButton
                id="accept_terms"
                name="I acknowledge that I have the patients consent to submit their prescription on their behalf"
                {...confirmConsent}
              />
            </div>

            <div className="upload_prescription__tertiary_button">
              <input
                ref={(element) => (fileInputRef.current = element)}
                type="file"
                hidden
                id="prescription"
                onChange={fileSelected}
                accept="image/*,application/pdf"
              />
              <TertiaryCta
                color="white"
                text="Upload prescription"
                icon={<UploadIcon />}
                onClick={() => fileInputRef.current.click()}
                isEnabled={confirmConsent.value}
              />

              {prescriptionError && (
                <div className="upload_prescription__error">
                  Image size must be under 20 MB
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default CarerUploadPrescription;
