import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { RadioButton } from "../../../components/inputs";
import { TermsAndConditions } from "../../../components/onboarding";
import { LoadingOverlay } from "../../../components/overlays";
import { CredentialsForm } from "../../../components/forms";
import { NoticeCard } from "../../../components/cards";

import {
  setCredentials,
  registerCarer as registerCarerAction,
  uploadPrescriptionCarer,
  selectRegisteringCarer,
  selectUploadingPrescriptionCarer,
  selectCarer,
  completeCarerAccount,
} from "../../../store/slices/carerOnboardingSlice";
import { emailValidator } from "../../../util/validators";
import { usePasswordInput, useProps, useRadioButton } from "../../../hooks";
import { trackAnalyticsEvent } from "../../../util/util";


function CarerCompleteAccount({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const registeringCarer = useSelector(selectRegisteringCarer);
  const uploadingPrescription = useSelector(selectUploadingPrescriptionCarer);
  const carer = useSelector(selectCarer);

  const email = useProps(carer.email || "", emailValidator());
  const [password, confirmPassword, checkIfPasswordsMatch] = usePasswordInput();

  const agreedToTerms = useRadioButton(false);
  const [readTerms, setReadTerms] = useState(false);

  const isButtonEnabled =
    email.isValid() &&
    password.isValid() &&
    confirmPassword.isValid() &&
    agreedToTerms.value &&
    readTerms;

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function continueClicked() {
    try {
      checkIfPasswordsMatch();

      const credentials = {
        email: email.value.trim().toLowerCase(),
        password: password.value,
        agreedToTerms: agreedToTerms.value,
      };

      dispatch(setCredentials(credentials));

      trackAnalyticsEvent("Carer Onboarding", "Carer Account info added and Agreed Terms");

      if (carer.secretId) {
        await dispatch(completeCarerAccount());

        setNextStep(steps.end);

        return;
      }

      const registeredCarer = await registerCarer();

      await uploadPrescription(registeredCarer.patientId);

      setNextStep(steps.end);
    } catch (error) { }
  }

  async function registerCarer() {
    const { error, payload } = await dispatch(registerCarerAction());
    if (error) {
      email.setErrors(["Email is already taken"]);
      throw new Error("Email is already taken");
    }

    return payload;
  }

  async function uploadPrescription(patientId) {
    const { error } = await dispatch(uploadPrescriptionCarer(patientId));

    if (error) {
      throw new Error("Prescription wasn't uploaded");
    }
  }

  return (
    <section className="one_last_step">
      <LoadingOverlay show={registeringCarer} title="Setting you up" />
      <LoadingOverlay
        show={uploadingPrescription}
        title="Uploading your prescription"
      />

      <div className="one_last_step__content">
        <div className="one_last_step__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">One last step</h1>
          <p className="p5">
            Please tell us your email address and create a password to complete
            your account.
          </p>
        </div>
        <div className="one_last_step__form">
          <CredentialsForm
            email={email}
            password={password}
            confirmPassword={confirmPassword}
            isEnabled={!carer.secretId}
          />
        </div>
        <div className="one_last_step__terms">
          <div className="m-b-mm">
            <NoticeCard
              titleTop="Before you proceed"
              textTop="To complete your setup you are required to review the Terms and conditions in their entirety and acknowledge that you accept these conditions."
            />
          </div>
          <TermsAndConditions scrolledToBottom={() => setReadTerms(true)} />
        </div>
        <div className="one_last_step__accept_terms radio-container">
          <RadioButton
            isEnabled={readTerms}
            id="accept_terms"
            name="I agree to the Lyphe Dispensary Terms and Conditions"
            {...agreedToTerms}
          />
        </div>
        <div className="one_last_step__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={continueClicked}
            text="Continue"
          />
        </div>
      </div>
    </section>
  );
}

export default CarerCompleteAccount;
