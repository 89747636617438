import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountService } from "../../services/AccountServices";
import { onboardingService } from "../../services/OnboardingServices";
import { setUserFromToken } from "./authenticationSlice";

export const registerPatient = createAsyncThunk(
  "patientOnboarding/registerPatient",

  async (payload, { getState, dispatch }) => {
    const { patient } = getState().patientOnboarding;

    const data = await onboardingService.registerPatient(patient);

    // login user
    await dispatch(setUserFromToken({ token: data.token }));

    return data;
  }
);

export const uploadPrescriptionPatient = createAsyncThunk(
  "patientOnboarding/uploadPrescriptionPatient",

  async (patientId, { getState }) => {
    const { prescription } = getState().patientOnboarding;

    const response = await accountService.uploadPrescription(
      patientId,
      prescription
    );

    return response;
  }
);

export const getPatientDataForCompleteAccount = createAsyncThunk(
  "patientOnboarding/getPatientDataForCompleteAccount",

  async (secretId) => {
    const patient = await onboardingService.getPatientDataForCompleteAccount(
      secretId
    );

    return patient;
  }
);

export const completePatientAccount = createAsyncThunk(
  "patientOnboarding/completePatientAccount",

  async (payload, { getState, dispatch }) => {
    const { patient } = getState().patientOnboarding;

    const user = await onboardingService.completePatientAccount(patient);

    await dispatch(setUserFromToken({ token: user.token }));

    return user;
  }
);

export const completePatientAccountSecretIdOnboarding = createAsyncThunk(
  "patientOnboarding/completePatientAccountSecretIdOnboarding",

  async (payload, { getState }) => {
    const { patient } = getState().patientOnboarding;

    await onboardingService.completePatientAccount(patient);
  }
);

export const checkPatientDobClinicNumber = createAsyncThunk(
  "patientOnboarding/checkPatientDobClinicNumber",

  async (payload) => {

    await onboardingService.checkPatientDobClinicNumber(payload);

  }
);

const initialState = {
  patient: {},
  prescription: {},

  registeringPatient: false,
  uploadingPrescription: false,
  checkingPatientData: false,
  completingPatientAccount: false,
};

const patientOnboardingSlice = createSlice({
  name: "patientOnboarding",

  initialState,

  // sync actions
  reducers: {
    resetPatient(state) {
      state.patient = {};
      state.prescription = {};
      state.registeringPatient = false;
      state.uploadPrescription = false;
    },

    setPatient(state, { payload }) {
      state.patient = payload;
    },

    setName(state, { payload }) {
      state.patient.firstName = payload.firstName;
      state.patient.lastName = payload.lastName;
      state.patient.title = payload.title;
    },

    setDateOfBirth(state, { payload }) {
      state.patient.dateOfBirth = payload;
    },

    setPrescription(state, { payload }) {
      state.prescription = payload;
      state.prescription.confirmedConsent = true;
    },

    setAddress(state, { payload }) {
      state.patient.address1 = payload.address1;
      state.patient.address2 = payload.address2;
      state.patient.zipCode = payload.zipCode;
    },

    setTelephoneNo(state, { payload }) {
      state.patient.contactNumber = payload;
    },

    setCredentials(state, { payload }) {
      state.patient.email = payload.email;
      state.patient.password = payload.password;
      state.patient.agreedToTerms = true;
    },
  },

  // async actions
  extraReducers: {
    // registerPatient
    [registerPatient.pending]: (state) => {
      state.registeringPatient = true;
    },

    [registerPatient.fulfilled]: (state) => {
      state.registeringPatient = false;
      state.patient = {};
    },

    [registerPatient.rejected]: (state, action) => {
      state.registeringPatient = false;
    },

    // uploadPrescriptionPatient
    [uploadPrescriptionPatient.pending]: (state) => {
      state.uploadingPrescription = true;
    },

    [uploadPrescriptionPatient.fulfilled]: (state) => {
      state.uploadingPrescription = false;
      state.prescription = {};
    },

    [uploadPrescriptionPatient.rejected]: (state) => {
      state.uploadingPrescription = false;
    },

    // getPatientDataForCompleteAccount
    [getPatientDataForCompleteAccount.fulfilled]: (state, { payload }) => {
      state.patient.firstName = payload.firstName;
      state.patient.lastName = payload.lastName;
      state.patient.title = payload.title;
      state.patient.email = payload.email;
      state.patient.contactNumber = payload.contactNumber;
      state.patient.secretId = payload.secretId;
      state.patient.address1 = payload.address1;
      state.patient.address2 = payload.address2;
      state.patient.zipCode = payload.zipCode;
      state.patient.dateOfBirth = payload.dateOfBirth;
    },

    // completePatientAccount
    [completePatientAccount.pending]: (state) => {
      state.registeringPatient = true;
    },

    [completePatientAccount.fulfilled]: (state) => {
      state.registeringPatient = false;
    },

    [completePatientAccount.rejected]: (state) => {
      state.registeringPatient = false;
    },

    // completePatientAccountSecretIdOnboarding
    [completePatientAccountSecretIdOnboarding.pending]: (state) => {
      state.completingPatientAccount = true;
    },

    [completePatientAccountSecretIdOnboarding.fulfilled]: (state) => {
      state.completingPatientAccount = false;
    },

    [completePatientAccountSecretIdOnboarding.rejected]: (state) => {
      state.completingPatientAccount = false;
    },

    // checkPatientDobClinicNumber
    [checkPatientDobClinicNumber.pending]: (state) => {
      state.checkingPatientData = true;
    },

    [checkPatientDobClinicNumber.fulfilled]: (state) => {
      state.checkingPatientData = false;
    },

    [checkPatientDobClinicNumber.rejected]: (state) => {
      state.checkingPatientData = false;
    },
  },
});

export default patientOnboardingSlice.reducer;

// export sync actions
export const {
  resetPatient,
  setName,
  setPatient,
  setDateOfBirth,
  setPrescription,
  setAddress,
  setTelephoneNo,
  setCredentials,
} = patientOnboardingSlice.actions;

// selectors

export const selectPatient = (state) => state.patientOnboarding.patient;

export const selectPrescription = (state) =>
  state.patientOnboarding.prescription;

export const selectRegistered = (state) => state.patientOnboarding.registered;

export const selectRegisteringPatient = (state) =>
  state.patientOnboarding.registeringPatient;

export const selectUploadingPrescriptionPatient = (state) =>
  state.patientOnboarding.uploadingPrescription;

export const selectCheckingPatientData = (state) =>
  state.patientOnboarding.checkingPatientData;

export const selectCompletingPatientAccount = (state) =>
  state.patientOnboarding.completingPatientAccount;
