import React from 'react'
import {
  TickIcon,
} from "../../../assets/icons";
import { resolveAppendableClasses } from "../../../util/util";
const Checkbox = (
  {
    isChecked = true,
    onChange = () => null,
    name = 'not provided',
    className = '',
    appendableClassNames = "",
    customStyle = '',
  }
) => {
  return (
    <div>
      <label className={`checkbox ${className} ${resolveAppendableClasses("checkbox", appendableClassNames)}`}>
        <div className='checkbox__visual'>{isChecked ? <TickIcon /> : ""}</div>
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        <div className={`label ${customStyle}`}>{name}</div>
      </label>
    </div>
  )
}

export default Checkbox
