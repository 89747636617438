import React from "react";

function CarbonDocumentIcon(props) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="carbon:document">
                <path id="Vector" d="M19.275 7.475L14.025 2.225C13.875 2.075 13.725 2 13.5 2H6C5.175 2 4.5 2.675 4.5 3.5V21.5C4.5 22.325 5.175 23 6 23H18C18.825 23 19.5 22.325 19.5 21.5V8C19.5 7.775 19.425 7.625 19.275 7.475ZM13.5 3.8L17.7 8H13.5V3.8ZM18 21.5H6V3.5H12V8C12 8.825 12.675 9.5 13.5 9.5H18V21.5Z" fill="#3B5EDB" />
                <path id="Vector_2" d="M7.5 17H16.5V18.5H7.5V17ZM7.5 12.5H16.5V14H7.5V12.5Z" fill="#3B5EDB" />
            </g>
        </svg>
    );
}

export default CarbonDocumentIcon;