import React, { useEffect } from "react";

import { AuthHeader } from "../../components/headers";
import { PrimaryCta } from "../../components/buttons";

import { CrossFailIcon } from "../../assets/icons";

function PasswordResetFail(props) {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <AuthHeader hideClose={true} />
      <div className="auth_wrapper">
        <section className="auth">
          <div className="auth__reset_success">
            <div className="m-b-m">
              <CrossFailIcon />
            </div>
            <h1 className="h4-p-a">An error occurred</h1>
            <p className="p5">Please try again</p>
          </div>

          <div className="auth__buttons">
            <div className="h-48">
              <PrimaryCta
                text="Back to login"
                color="orange"
                link="/auth/login"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PasswordResetFail;
