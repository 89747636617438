import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ClinicPatientTypes, OrganisationPrescriptionsTypes, Filters, Sorts } from "../../enums";
import { prescriptionService } from "../../services/PrescriptionServices";
import { setGenericSuccessModal } from "./modalsSlice";
import { clinicSearchTypes } from "../../enums";

export const getActivePrescriptions = createAsyncThunk(
  "prescriptions/getActivePrescriptions",

  async () => {
    const activePrescriptions = await prescriptionService.getActivePrescriptions();
    return activePrescriptions;
  }
);

export const getAllPrescriptions = createAsyncThunk(
  "prescriptions/getAllPrescriptions",

  async () => {
    const allPrescriptions = await prescriptionService.getAllPrescriptions();
    return allPrescriptions;
  }
);

export const getPastPrescriptions = createAsyncThunk(
  "prescriptions/getPastPrescriptions",

  async () => {
    const pastPrescriptions = await prescriptionService.getPastPrescriptions();

    return pastPrescriptions;
  }
);

export const getOrderToBePaid = createAsyncThunk(
  "prescriptions/getOrderToBePaid",

  async ({ orderNumber, callType }) => {
    const orderToBePaid = await prescriptionService.getOrderToBePaid(orderNumber, callType)

    return orderToBePaid;
  }
);

export const payOrder = createAsyncThunk(
  "prescriptions/payOrder",

  async (_, { getState }) => {
    let { prescriptions: { transactionData } } = getState()

    await prescriptionService.payOrder({ ...transactionData });
  }
);

export const uploadPrescription = createAsyncThunk(
  "prescriptions/uploadPrescription",

  async ({ prescription, patientId, doctorId, isT21Patient }) => {
    const response = await prescriptionService.uploadPrescription(
      prescription,
      patientId,
      doctorId,
      isT21Patient
    );

    return response;
  }
);

export const bulkUploadPrescriptionImages = createAsyncThunk(
  "prescriptions/bulkUploadPrescriptionImages",

  async ({ prescriptions, confirmedConsent, doctorId, envelopeTrackingCode }) => {
    const response = await prescriptionService.bulkUploadPrescriptionImages(
      prescriptions,
      doctorId,
      confirmedConsent,
      envelopeTrackingCode
    );

    return response;
  }
);

export const reportProblemWithOrder = createAsyncThunk(
  "prescriptions/reportProblemWithOrder",

  async (report) => {
    const response = await prescriptionService.reportProblemWithOrder(report);

    return response;
  }
);

export const payForOrder = createAsyncThunk(
  "prescriptions/payForOrder",

  async (orderId) => {
    await prescriptionService.payForOrder(orderId);
  }
);

export const requestPaymentLink = createAsyncThunk(
  "prescriptions/requestPaymentLink",

  async (orderNumber, { dispatch, getState }) => {
    const { authentication: { user } } = getState();
    await prescriptionService.requestPaymentLink(orderNumber);
    const newGenericSuccesModalState = {
      show: true,
      title: "Payment link sent",
      text: `Please check your inbox. An email containing a link to pay for your medication has been sent to ${user.email}`
    }
    dispatch(setGenericSuccessModal(newGenericSuccesModalState));
  }
);

export const resubmitOrder = createAsyncThunk(
  "prescriptions/resubmitOrder",

  async (orderId) => {
    const response = await prescriptionService.resubmitOrder(orderId);

    return response;
  }
);

export const getPrescriptionsForOrganisation = createAsyncThunk(
  "prescriptions/getPrescriptionsForClinic",
  async (payload, { dispatch }, rejectWithValue) => {
    //set default pageParams in case it wasnt passed
    if (payload.pageParams === undefined) {
      payload.pageParams = {
        currentPage: 1,
        pageSize: 25,
        sortColumnName: "",
        sortDirection: 0
      }
    }
    try {
      if (payload.type != null) {

        //in case we are using quick search - fetch all tables
        const paginatedResult = await prescriptionService.getPrescriptionsForOrganisation(
          payload.organisationId,
          payload.organisationEmployeeId,
          payload.status,
          payload.parameter,
          payload.type,
          payload.pageParams
        );

        return {
          status: payload.status,
          prescriptions: paginatedResult.prescriptions,
          paginationData: paginatedResult.paginatedData
        };
      }
      //otherwise fetch the current table (param)
      const paginatedResult = await prescriptionService.getPrescriptionsForOrganisation(payload.organisationId, payload.organisationEmployeeId, payload.status, null, null, payload.pageParams);
      return {
        status: payload.status,
        prescriptions: paginatedResult.prescriptions,
        paginationData: paginatedResult.paginatedData
      };
    }
    catch (e) {
      if (e.response.status === 403) {
        dispatch(setIsGetStartedPagePresented(true));
      }
      rejectWithValue(null)

    }
  }
);

export const getPrescribersForOrganisationEmployee = createAsyncThunk(
  "prescriptions/getDoctorsForClinicUser",

  async (organisationId) => {
    const prescribers = await prescriptionService.getPrescribersForOrganisationEmployee(organisationId);

    return prescribers;
  }
);

export const getPatientByClinicId = createAsyncThunk(
  "prescriptions/getPatientByClinicId",

  async (payload) => {
    const patient = await prescriptionService.getPatientByClinicId(payload.organisationId, payload.clinicPatientId);

    return patient;
  }
);

export const uploadPrescriptionAsClinicUser = createAsyncThunk(
  "prescriptions/uploadPrescriptionAsClinicUser",

  async (payload, { getState }) => {
    const { prescriptions: { selectedDoctorForClinicUser, prescriptionForUploadAsClinicUser } } = getState();
    const payloadForApi = {
      prescription: prescriptionForUploadAsClinicUser[0],
      organisationEmployeeId: selectedDoctorForClinicUser.value,
      ...payload
    }

    const prescription = await prescriptionService.uploadPrescriptionAsClinicUser(payloadForApi);

    return prescription;
  }
);

export const uploadProtoPrescriptionAsOrganisationEmployee = createAsyncThunk(
  "prescriptions/uploadProtoPrescriptionAsOrganisationEmployee",

  async (payload, { getState }) => {
    const { prescriptions: { selectedDoctorForClinicUser, prescriptionForUploadAsClinicUser } } = getState();

    //allow bulk upload for prescriber
    if (prescriptionForUploadAsClinicUser.length > 1) {
      const payloadForApi = {
        prescriptions: prescriptionForUploadAsClinicUser,
        organisationEmployeeId: selectedDoctorForClinicUser.value,
        ...payload
      }

      const prescriptions = await prescriptionService.bulkUploadProtoPrescriptionAsOrganisationEmployee(payloadForApi);
      return prescriptions;
    } else {
      const payloadForApi = {
        prescription: prescriptionForUploadAsClinicUser[0],
        organisationEmployeeId: selectedDoctorForClinicUser.value,
        ...payload
      }

      const prescription = await prescriptionService.uploadProtoPrescriptionAsOrganisationEmployee(payloadForApi);
      return prescription;
    }
  }
);

export const promoteProtoPrescription = createAsyncThunk(
  "prescriptions/promoteProtoPrescription",

  async (payload) => {

    const prescription = await prescriptionService.promoteProtoPrescription(payload);

    return prescription;
  }
);

export const deleteProtoPrescription = createAsyncThunk(
  "prescriptions/deleteProtoPrescription",

  async ({ organisationId, id }) => {

    const prescription = await prescriptionService.deleteProtoPrescription(organisationId, id);

    return prescription;
  }
);

export const getAllFilteredPrescriptionsAsClinicUser = createAsyncThunk(
  "prescriptions/getAllFilteredAsClinicUser",

  async (payload) => {
    const result = await prescriptionService.getAllFilteredPrescriptionsAsClinicUser(
      payload.query,
      payload.organisationId,
      payload.organisationEmployeeId
    );

    return result;
  }
);

export function prescriptionsToSearchList(prescriptions) {
  if (!prescriptions) prescriptions = {
    prescriptionNumbers: [],
    prescriptionIds: [],
    prescriptionOrdersIds: [],
    patients: [],
    organisationEmployees: [],
  };

  const prescriptionNumbers = prescriptions.prescriptionNumbers.map(p => {
    return {
      text: p.paperPrescriptionId,
      type: clinicSearchTypes.prescriptionId,
      //we actually send the prescription id as the parameter so its easier to retrieve on the backend
      parameter: p.id
    }
  });

  const prescriptionIds = prescriptions.prescriptionIds.map(pId => {
    return {
      text: pId,
      type: clinicSearchTypes.prescriptionId,
      parameter: pId
    }
  });

  let prescriptionOrdersIds = []

  prescriptions.prescriptionOrdersIds.forEach(p => {
    prescriptionOrdersIds.push({
      text: p.id,
      type: clinicSearchTypes.prescriptionId,
      parameter: p.prescriptionId
    })
  })

  const patients = prescriptions.patients.map(p => {
    return {
      text: `${p.firstName} ${p.lastName}`,
      subtext: `${p.dateOfBirth}`,
      type: clinicSearchTypes.patientId,
      parameter: p.id
    }
  })

  const doctors = prescriptions.organisationEmployees.map(p => {
    return {
      text: `${p.firstName} ${p.lastName}`,
      type: clinicSearchTypes.OrganisationEmployeeId,
      parameter: p.id
    }
  })

  return {
    prescriptionNumbers: prescriptionNumbers.filter(pN => pN.text),
    prescriptionIds,
    prescriptionOrdersIds,
    patients,
    doctors,
  }
}

const initialState = {
  protoPrescriptionForPromoting: {},
  activePrescriptions: [],
  pastPrescriptions: [],
  allPrescriptions: [],
  clinicsPrescriptions: {
    [OrganisationPrescriptionsTypes.ImageOnly]: [],
    [OrganisationPrescriptionsTypes.ToBeAssigned]: [],
    [OrganisationPrescriptionsTypes.ReWriteRequests]: [],
    [OrganisationPrescriptionsTypes.Active]: [],
    [OrganisationPrescriptionsTypes.Complete]: [],
  },
  clinicsPrescriptionsPaginationData: {
    [OrganisationPrescriptionsTypes.ImageOnly]: { totalCount: "" },
    [OrganisationPrescriptionsTypes.ToBeAssigned]: { totalCount: "" },
    [OrganisationPrescriptionsTypes.ReWriteRequests]: { totalCount: "" },
    [OrganisationPrescriptionsTypes.Active]: { totalCount: "" },
    [OrganisationPrescriptionsTypes.Complete]: { totalCount: "" },
  },
  clinicsPrescriptionsLoadingFlags: {
    [OrganisationPrescriptionsTypes.ImageOnly]: false,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: false,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: false,
    [OrganisationPrescriptionsTypes.Active]: false,
    [OrganisationPrescriptionsTypes.Complete]: false,
  },
  clinicPatients: {
    [ClinicPatientTypes.Basic]: [],
    [ClinicPatientTypes.Full]: [],
  },
  clinicPatientsLoadingFlags: {
    [ClinicPatientTypes.Basic]: false,
    [ClinicPatientTypes.Full]: false,
  },
  selectedPrescription: null,

  //upload prescription as clinic user state
  prescriptionForUploadAsClinicUser: null,
  prescribersForOrganisationEmployee: [],
  selectedDoctorForClinicUser: {},
  selectedPatient: {},
  patientForClinicUser: {},

  trackedOrderIndex: null,
  showMobileTracker: false,

  uploadingPrescription: undefined,
  loadingActivePrescriptions: undefined,
  loadingPastPrescriptions: undefined,
  loadingAllPrescriptions: undefined,
  uploadingReport: false,
  resubmitLoading: false,
  requestingPaymentCallback: false,
  loadingPatientForClinicUser: false,
  uploadingPrescriptionAsClinicUser: false,
  foundPatientForClinicUser: false,

  IsGetStartedPagePresented: false,

  sort: Sorts.NewestToOldest,
  filters: [Filters.AllTypes, Filters.AllStatuses],
  patientIdFilter: null,
  orderIdFilter: null,

  orderToBePaid: null,
  loadingOrderToBePaid: false,
  submittingPayment: false,
  transactionData: {},
  paymentFailed: false,
  requestingPaymentLink: false,

  resetPagination: 1,

  submittingBulkUploadImages: false,

  loadingResultsFromClinicSearch: false,
  noResultsFromClinicSearch: false,
  searchListOptions: {
    prescriptionNumbers: [],
    prescriptionOrdersIds: [],
    prescriptionIds: [],
    clinicIds: [],
    patientIds: [],
    patients: [],
    patientTelephoneNumber: [],
    doctors: [],
  },

  clinicSearchInputValue: "",
  clinicFilterOptions: {
    [OrganisationPrescriptionsTypes.ImageOnly]: [],
    [OrganisationPrescriptionsTypes.ToBeAssigned]: [],
    [OrganisationPrescriptionsTypes.ReWriteRequests]: [],
    [OrganisationPrescriptionsTypes.Active]: [],
    [OrganisationPrescriptionsTypes.Complete]: [],
  },
  clinicFilterNumberOfChecked: {
    [OrganisationPrescriptionsTypes.ImageOnly]: 0,
    [OrganisationPrescriptionsTypes.ToBeAssigned]: 0,
    [OrganisationPrescriptionsTypes.ReWriteRequests]: 0,
    [OrganisationPrescriptionsTypes.Active]: 0,
    [OrganisationPrescriptionsTypes.Complete]: 0,
  },
  clinicsFilteredPrescriptions: {
    [OrganisationPrescriptionsTypes.ImageOnly]: [],
    [OrganisationPrescriptionsTypes.ToBeAssigned]: [],
    [OrganisationPrescriptionsTypes.ReWriteRequests]: [],
    [OrganisationPrescriptionsTypes.Active]: [],
    [OrganisationPrescriptionsTypes.Complete]: [],
  },
};

const prescriptionsSlice = createSlice({
  name: "prescriptions",
  initialState,

  reducers: {
    // sync actions
    setTrackingOrderIndex(state, { payload }) {
      state.trackedOrderIndex = payload;
    },

    setShowMobileTracker(state, { payload }) {
      state.showMobileTracker = payload;
    },

    resetPrescriptions(state) {
      state.activePrescriptions = [];
      state.pastPrescriptions = [];
    },

    resetAllPrescriptions(state) {
      state.allPrescriptions = [];
    },

    setSort(state, { payload }) {
      state.sort = payload;
    },

    setFilters(state, { payload }) {
      state.filters = payload;
    },

    setPatientIdFilter(state, { payload }) {
      state.patientIdFilter = payload;
    },

    setOrderIdFilter(state, { payload }) {
      state.orderIdFilter = payload;
    },

    setResetPagination(state) {
      state.resetPagination = state.resetPagination + 1;
    },

    setTransactionData(state, { payload }) {
      state.transactionData = { ...state.orderToBePaid, ...payload }
    },

    setPaymentFailed(state, { payload }) {
      state.paymentFailed = payload;
    },

    setSelectedPrescription(state, { payload }) {
      state.selectedPrescription = payload;
    },

    setPrescriptionForUploadAsClinicUser(state, { payload }) {
      state.prescriptionForUploadAsClinicUser = payload;
    },

    setProtoPrescriptionForPromoting(state, { payload }) {
      state.protoPrescriptionForPromoting = payload;
    },

    setSelectedDoctorForClinicUser(state, { payload }) {
      state.selectedDoctorForClinicUser = payload;
    },

    setInStateByKey(state, { payload }) {
      state[payload.key] = payload.data;
    },

    resetPatientForClinicUser(state) {
      state.patientForClinicUser = {};
    },

    setClinicSearchSelectedValue(state, { payload }) {
      state.clinicSearchSelectedValue = payload;
    },

    setClinicSearchInputValue(state, { payload }) {
      state.clinicSearchInputValue = payload;
    },

    setClinicSearchParameter(state, { payload }) {
      state.clinicSearchParameter = payload;
    },

    setClinicSearchType(state, { payload }) {
      state.clinicSearchType = payload;
    },

    resetPrescriptionsForClinicUser(state) {
      state.clinicsPrescriptions = initialState.clinicsPrescriptions;
      state.clinicsPrescriptionsPaginationData = initialState.clinicsPrescriptionsPaginationData;
    },

    resetSearchResultsForClinicUser(state) {
      state.searchResultsForClinicUser = undefined
    },

    setClinicFilterOptions(state, { payload }) {
      const { status, i, j, checked, filters } = payload;

      if (filters != undefined) {
        //set all filter options
        state.clinicFilterOptions[status] = filters;
      }
      else {
        //update a single selected filter row's checked field
        state.clinicFilterOptions[status][i].values[j] = { ...state.clinicFilterOptions[status][i].values[j], checked: checked };
      }
    },

    setClinicFilterNumberOfChecked(state, { payload }) {
      const { status, value } = payload
      state.clinicFilterNumberOfChecked[status] = value;
    },

    setClinicsFilteredPrescriptions(state, { payload }) {
      const { status, value } = payload
      state.clinicsFilteredPrescriptions[status] = value
    },

    setIsGetStartedPagePresented(state, { payload }) {
      state.IsGetStartedPagePresented = payload
    },
  },

  // async actions
  extraReducers: {
    // getActivePrescriptions
    [getActivePrescriptions.pending]: (state) => {
      state.loadingActivePrescriptions = true;
    },

    [getActivePrescriptions.fulfilled]: (state, { payload }) => {
      state.loadingActivePrescriptions = false;
      state.activePrescriptions = payload;
      state.trackedOrderIndex = 0;
    },

    [getActivePrescriptions.rejected]: (state) => {
      state.loadingActivePrescriptions = false;
    },

    // getAllPrescriptions

    [getAllPrescriptions.pending]: (state) => {
      state.loadingAllPrescriptions = true;
    },

    [getAllPrescriptions.fulfilled]: (state, { payload }) => {
      state.loadingAllPrescriptions = false;
      state.allPrescriptions = payload;
    },

    [getAllPrescriptions.rejected]: (state) => {
      state.loadingAllPrescriptions = false;
    },


    // getPastPrescriptions
    [getPastPrescriptions.pending]: (state) => {
      state.loadingPastPrescriptions = true;
    },

    [getPastPrescriptions.fulfilled]: (state, { payload }) => {
      state.pastPrescriptions = payload;
      state.loadingPastPrescriptions = false;
    },

    [getPastPrescriptions.rejected]: (state) => {
      state.loadingPastPrescriptions = false;
    },

    // uploadPrescription
    [uploadPrescription.pending]: (state) => {
      state.uploadingPrescription = true;
    },

    [uploadPrescription.fulfilled]: (state) => {
      state.uploadingPrescription = false;
    },

    [uploadPrescription.rejected]: (state) => {
      state.uploadingPrescription = false;
    },

    // reportProblemWithOrder
    [reportProblemWithOrder.pending]: (state) => {
      state.uploadingReport = true;
    },

    [reportProblemWithOrder.fulfilled]: (state) => {
      state.uploadingReport = false;
    },

    [reportProblemWithOrder.rejected]: (state) => {
      state.uploadingReport = false;
    },

    // payForOrder
    [payForOrder.pending]: (state) => {
      state.requestingPaymentCallback = true;
    },

    [payForOrder.fulfilled]: (state) => {
      state.requestingPaymentCallback = false;
    },

    [payForOrder.rejected]: (state) => {
      state.requestingPaymentCallback = false;
    },

    // getOrderToBePaid
    [getOrderToBePaid.pending]: (state) => {
      state.loadingOrderToBePaid = true;
    },

    [getOrderToBePaid.fulfilled]: (state, { payload }) => {
      state.paymentProccessing = false;
      state.loadingOrderToBePaid = false;
      state.orderToBePaid = payload;
    },

    [getOrderToBePaid.rejected]: (state) => {
      state.loadingOrderToBePaid = false;
    },

    // payOrder
    [payOrder.pending]: (state) => {
      state.submittingPayment = true;
      state.paymentFailed = false;
    },

    [payOrder.fulfilled]: (state) => {
      state.orderToBePaid.isPaymentReceived = true;
      state.submittingPayment = false;
      state.paymentFailed = false;
    },

    [payOrder.rejected]: (state) => {
      state.submittingPayment = false;
      state.paymentFailed = true;
    },

    // resubmitOrder
    [resubmitOrder.pending]: (state) => {
      state.resubmitLoading = true;
    },

    [resubmitOrder.fulfilled]: (state) => {
      state.resubmitLoading = false;
    },

    [resubmitOrder.rejected]: (state) => {
      state.resubmitLoading = false;
    },

    //requestPaymentLink
    [requestPaymentLink.pending]: (state) => {
      state.requestingPaymentLink = true
    },
    [requestPaymentLink.fulfilled]: (state) => {
      state.requestingPaymentLink = false
    },
    [requestPaymentLink.rejected]: (state) => {
      state.requestingPaymentLink = false
    },

    //bulkUploadPrescriptionImages
    [bulkUploadPrescriptionImages.pending]: (state) => {
      state.submittingBulkUploadImages = true;
    },
    [bulkUploadPrescriptionImages.fulfilled]: (state) => {
      state.submittingBulkUploadImages = false;
    },
    [bulkUploadPrescriptionImages.rejected]: (state) => {
      state.submittingBulkUploadImages = false;
    },

    //getPrescriptionsForClinic

    [getPrescriptionsForOrganisation.pending]: (state, { meta: { arg: status } }) => {
      state.clinicsPrescriptionsLoadingFlags[status.status ? status.status : status] = true;
    },
    [getPrescriptionsForOrganisation.fulfilled]: (state, { payload }) => {
      const { status, prescriptions, paginationData } = payload;
      state.clinicsPrescriptions[status] = prescriptions;
      state.clinicsPrescriptionsPaginationData[status] = paginationData;
      state.clinicsPrescriptionsLoadingFlags[status] = false;
      state.IsGetStartedPagePresented = false;
    },
    [getPrescriptionsForOrganisation.rejected]: (state, { meta: { arg: status } }) => {
      state.clinicsPrescriptionsLoadingFlags[status.status ? status.status : status] = false;
    },

    //getDoctorsForClinicUser

    [getPrescribersForOrganisationEmployee.pending]: (state, { meta: { arg: status } }) => {

    },
    [getPrescribersForOrganisationEmployee.fulfilled]: (state, { payload }) => {
      state.prescribersForOrganisationEmployee = payload;
    },
    [getPrescribersForOrganisationEmployee.rejected]: (state, { meta: { arg: status } }) => {

    },

    //getPatientByClinicId

    [getPatientByClinicId.pending]: (state) => {
      state.loadingPatientForClinicUser = true;
      state.foundPatientForClinicUser = false;
    },

    [getPatientByClinicId.fulfilled]: (state, { payload }) => {
      state.patientForClinicUser = payload;
      state.loadingPatientForClinicUser = false;
      if (payload.id) {
        state.foundPatientForClinicUser = true;
      }
    },
    [getPatientByClinicId.rejected]: (state) => {
      state.loadingPatientForClinicUser = false;
      state.foundPatientForClinicUser = false;
    },

    //uploadPrescriptionAsClinicUser

    [uploadPrescriptionAsClinicUser.pending]: (state) => {
      state.uploadingPrescriptionAsClinicUser = true;
    },
    [uploadPrescriptionAsClinicUser.fulfilled]: (state, { payload }) => {
      state.uploadingPrescriptionAsClinicUser = false;
    },
    [uploadPrescriptionAsClinicUser.rejected]: (state) => {
      state.uploadingPrescriptionAsClinicUser = false;
    },

    //uploadPrescriptionAsClinicUser

    [uploadProtoPrescriptionAsOrganisationEmployee.pending]: (state) => {
      state.uploadingPrescriptionAsClinicUser = true;
    },
    [uploadProtoPrescriptionAsOrganisationEmployee.fulfilled]: (state) => {
      state.uploadingPrescriptionAsClinicUser = false;
    },
    [uploadProtoPrescriptionAsOrganisationEmployee.rejected]: (state) => {
      state.uploadingPrescriptionAsClinicUser = false;
    },

    //getAllFilteredAsClinicUser

    [getAllFilteredPrescriptionsAsClinicUser.pending]: (state) => {
      state.loadingResultsFromClinicSearch = true;
      state.noResultsFromClinicSearch = false;
    },

    [getAllFilteredPrescriptionsAsClinicUser.fulfilled]: (state, { payload }) => {

      const result = prescriptionsToSearchList(payload);

      if (payload == null) {
        state.noResultsFromClinicSearch = true;
        return;
      }

      if (
        !payload.prescriptionNumbers.length &&
        !payload.prescriptionIds.length &&
        !payload.prescriptionOrdersIds.length &&
        !payload.patients.length &&
        !payload.organisationEmployees.length) {
        state.noResultsFromClinicSearch = true;
      }

      state.searchResultsForClinicUser = mapResult(result);
      state.loadingResultsFromClinicSearch = false;

      function mapResult(result) {
        const data = [];
        for (const [key, value] of Object.entries(result)) {
          if (!data.hasOwnProperty(key)) {
            data[key] = [];
          }

          value.forEach(item => {
            data[key].push({
              ...item,
              isSelected: false
            });
          })
        }
        return data;
      }
    },

    [getAllFilteredPrescriptionsAsClinicUser.rejected]: (state, { payload }) => {
      state.loadingResultsFromClinicSearch = false;
      state.noResultsFromClinicSearch = true;
    },

  },
});

// export sync actions
export const {
  setTrackingOrderIndex,
  setShowMobileTracker,
  resetPrescriptions,
  setSort,
  setFilters,
  setPatientIdFilter,
  setOrderIdFilter,
  setResetPagination,
  setTransactionData,
  setPaymentFailed,
  resetAllPrescriptions,
  setSelectedPrescription,
  setPrescriptionForUploadAsClinicUser,
  setProtoPrescriptionForPromoting,
  setInStateByKey,
  resetPatientForClinicUser,
  setClinicSearchSelectedValue,
  setClinicSearchInputValue,
  setClinicSearchParameter,
  setClinicSearchType,
  resetPrescriptionsForClinicUser,
  resetSearchResultsForClinicUser,
  setClinicFilterOptions,
  setClinicFilterNumberOfChecked,
  setClinicsFilteredPrescriptions,
  setIsGetStartedPagePresented
} = prescriptionsSlice.actions;

// selectors

export const selectFilters = (state) => state.prescriptions.filters;

export const selectSort = (state) => state.prescriptions.sort;

export const selectTrackedOrderIndex = (state) =>
  state.prescriptions.trackedOrderIndex;

export const selectActivePrescriptions = (state) =>
  state.prescriptions.activePrescriptions;

export const selectAllPrescriptions = (state) =>
  state.prescriptions.allPrescriptions;

export const selectPastPrescriptions = (state) =>
  state.prescriptions.pastPrescriptions;

export const selectShowTracker = (state) =>
  state.prescriptions.showMobileTracker;

export const selectUploadingPrescription = (state) =>
  state.prescriptions.uploadingPrescription;

export const selectUploadingReport = (state) =>
  state.prescriptions.uploadingReport;

export const selectTracking = (state) => state.prescriptions.tracking;

export const selectPatientIdFilter = (state) =>
  state.prescriptions.patientIdFilter;

export const selectOrderIdFilter = (state) =>
  state.prescriptions.orderIdFilter;

export const selectResubmitLoading = (state) =>
  state.prescriptions.resubmitLoading;

export const selectLoadingActivePrescriptions = (state) =>
  state.prescriptions.loadingActivePrescriptions;

export const selectLoadingAllPrescriptions = (state) =>
  state.prescriptions.loadingAllPrescriptions;

export const selectLoadingPastPrescriptions = (state) =>
  state.prescriptions.loadingPastPrescriptions;

export const selectRequestingPaymentCallback = (state) =>
  state.prescriptions.requestingPaymentCallback;

export const selectLoadingSingleOrder = (state) =>
  state.prescriptions.loadingOrder;

export const selectLoadingOrderToBePaid = (state) =>
  state.prescriptions.loadingOrderToBePaid;

export const selectResetPagination = (state) =>
  state.prescriptions.resetPagination;

export const selectOrderToBePaid = (state) =>
  state.prescriptions.orderToBePaid;

export const selectSubmittingPayment = (state) =>
  state.prescriptions.submittingPayment;

export const selectPaymentFailed = (state) =>
  state.prescriptions.paymentFailed;

export const selectRequestingPaymentLink = (state) =>
  state.prescriptions.requestingPaymentLink;

export const selectSubmittingBulkUploadImages = (state) =>
  state.prescriptions.submittingBulkUploadImages;

export const selectSelectedPrescription = (state) =>
  state.prescriptions.selectedPrescription;

export const selectPrescriptionForUploadAsClinicUser = (state) =>
  state.prescriptions.prescriptionForUploadAsClinicUser;

export const selectProtoPrescriptionForPromoting = (state) =>
  state.prescriptions.protoPrescriptionForPromoting;

export const selectPrescribersForOrganisationEmployee = (state) =>
  state.prescriptions.prescribersForOrganisationEmployee;

export const selectPatientForClinicUser = (state) =>
  state.prescriptions.patientForClinicUser;

export const selectClinicsPrescriptions = (key) => (state) =>
  state.prescriptions.clinicsPrescriptions[key];

export const selectClinicsPrescriptionsPaginationData = (key) => (state) =>
  state.prescriptions.clinicsPrescriptionsPaginationData[key];

export const selectClinicsPrescriptionsLoadingFlag = (key) => (state) =>
  state.prescriptions.clinicsPrescriptionsLoadingFlags[key];

export const selectFromPrescriptionSliceByKey = (key) => (state) =>
  state.prescriptions[key];

export const selectClinicFilterOptions = (key) => (state) =>
  state.prescriptions.clinicFilterOptions[key];

export const selectClinicFilterNumberOfChecked = (key) => (state) =>
  state.prescriptions.clinicFilterNumberOfChecked[key];

export const selectClinicsFilteredPrescriptions = (key) => (state) =>
  state.prescriptions.clinicsFilteredPrescriptions[key];

export const selectIsGetStartedPagePresented = (state) =>
  state.prescriptions.IsGetStartedPagePresented;


export default prescriptionsSlice.reducer;
