import React from "react";
import { Link } from "react-router-dom";

import lyphe from "../../../../../assets/images/lyphe_group_black_logo.png";
import registered_pharmacy from "../../../../../assets/images/registered_pharmacy.png";
import gmpc from "../../../../../assets/images/gphc_logo_1440.png";

function FooterMobile(props) {
  return (
    <footer className="footer_mobile">
      <a href="https://www.pharmacyregulation.org/registers/pharmacy/9012425"
        target="_blank"
        rel="noopener noreferrer"
        className="footer_gmpc"
        style={{ textDecoration: "none" }}>
        <img src={gmpc} alt="gmpc" />
        <p style={{ marginTop: "20px" }} className="label--smaller">We are regulated by GPhC</p>
        <img style={{ marginTop: "10px" }} src={registered_pharmacy} alt="registered pharmacy logo" />
      </a >
      <div className="footer_mobile__content">
        <Link to="/">
          <img src={lyphe} alt="logo" />
        </Link>
      </div>
    </footer>
  );
}

export default FooterMobile;
