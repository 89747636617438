class PatientsConverter {

  tempPatientsApiToClient(patients) {

    return patients.map((patient) => {
      return this.tempPatientApiToClient(patient)
    })
  }

  fullPatientsApiToClient(patients) {
    return patients.map((patient) => {
      return this.fullPatientApiToClient(patient)
    })
  }

  tempPatientApiToClient(patient) {
    return {
      clinicId: patient.clinicId,
      clinicIdNumber: patient.clinicIdNumber,
      email: patient.email,
      id: patient.id,
      telephoneNo: patient.telephoneNo,
      createdAt: patient.createdAt,
    }
  }
  fullPatientApiToClient(patient) {
    return {
      address1: patient.address1,
      address2: patient.address2,
      carerId: patient.carerId,
      clinicIdNumber: patient.clinicIdNumber,
      createdAt: patient.createdAt,
      dateOfBirth: patient.dateOfBirth,
      firstName: patient.firstName,
      id: patient.id,
      lastName: patient.lastName,
      telephoneNo: patient.telephoneNo,
      title: patient.title,
      updatedAt: patient.updatedAt,
      userId: patient.userId,
      zipCode: patient.zipCode,
      user: patient.user,
      email: patient.user.email,

      subscriptionType: patient.subscriptionType,
      subscriptionTier: patient.subscriptionTier,
      subscriptionCreatedAt: patient.subscriptionCreatedAt,
      subscriptionUpdatedAt: patient.subscriptionUpdatedAt,
    }
  }

}

export const patientConverter = new PatientsConverter();
