import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AccountHeader } from "../../../components/headers";
import { VerificationCard } from "../../../components/cards";
import { PrimaryCta } from "../../../components/buttons";
import {
  FullScreenOverlay,
  LoadingOverlay,
} from "../../../components/overlays";

import { selectUser } from "../../../store/slices/authenticationSlice";
import { selectIsIdVerified } from "../../../store/slices/accountSlice";
import { apiClient } from "../../../ApiClient";

import { matiClientId, matiFlowIds, env } from "../../../config.json";
import { UserRoleToFunctionMapper } from "../../../enums";
import { BigTickIcon } from "../../../assets/icons";

const matiFlowId = matiFlowIds[env];

function IdVerification(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(selectUser);
  const [status, setStatus] = useState()

  // Check if the user is already verified
  const isVerified = useSelector(selectIsIdVerified);
  if (isVerified) history.push("/prescriptions/active");

  const [popup, setPopup] = useState({
    show: false,
    title: "",
    text: "",
    buttonText: "",
  });

  const [showVerificationButton, setShowVerificationButton] = useState(false);

  const [verifying, setVerifying] = useState(false);
  // used for useEffect to know when to

  // check initial verification status
  // reset status only if not "review needed"
  useEffect(() => {
    setStatus()
    async function getVerificationStatus() {
      const { verificationStatus } = await apiClient.checkVerificationStatus();
      setStatus(verificationStatus)
      if (verificationStatus !== 2) {
        await apiClient.resetVerificationStatus();

        setShowVerificationButton(true);
      }
    }

    getVerificationStatus();
  }, []);

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);



  // setup interval
  useEffect(() => {
    const interval = setInterval(async () => {
      const { verificationStatus } = await apiClient.checkVerificationStatus();
      setStatus(verificationStatus)
      VerificationStatusMapper[verificationStatus](interval);
    }, 2500);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  const VerificationStatusMapper = {
    0: onNotVerified,
    1: onVerified,
    2: onReviewNeeded,
    3: onRejected,
    4: onVerificationRunning,
  };

  /**
   * This is the initial status, when the user has not
   * completed the process.
   */
  function onNotVerified() {
    if (verifying) setVerifying(false);
  }

  function onVerified(interval) {
    clearInterval(interval);

    setVerifying(false);

    setPopup({
      show: true,

      buttonText: "Great",
      onButtonClick: setVerifiedAndRedirect,

      Image: BigTickIcon,
      title: "ID verification passed",
      text:
        user?.role !== 3
          ? "Congratulations! You can now manage and track your prescriptions from your account"
          : "Congratulations! Your account is now verified and ready to go. If you submitted a prescription already, our pharmacy team are working hard to process it and will notify you as soon as it’s ready. ",
    });
  }

  async function setVerifiedAndRedirect() {
    const getUserDetails = UserRoleToFunctionMapper[user.role];

    if (getUserDetails === undefined) return;

    await dispatch(getUserDetails());

    history.push("/prescriptions/active");
  }

  function onReviewNeeded(interval) {
    clearInterval(interval);

    setShowVerificationButton(false);

    setVerifying(false);

    setPopup({
      show: true,

      buttonText: "Dismiss",
      onButtonClick: () => setPopup({ show: false }),

      title: "We need to check your data manually",
      text: "We'll notify you once your identity check has been completed",
    });
  }

  async function onRejected() {
    setVerifying(false);

    setPopup({
      show: true,

      buttonText: "Try again",
      onButtonClick: async () => {
        setPopup({ show: false });

        await apiClient.resetVerificationStatus();
      },

      title: "We were unable to verify your identity",
      text:
        "We were unable to verify your ID. Please ensure your ID meets the requested parameters. ",
    });
  }

  /**
   * This is triggered when the user completes the verification process,
   * but the system didn't verify his id yet.
   */
  function onVerificationRunning() {
    if (!verifying) setVerifying(true);
  }

  return (
    <>
      <FullScreenOverlay show={popup.show}>
        <VerificationCard
          cardTitle={popup.title}
          cardText={popup.text}
          Image={popup.Image}
          onClick={popup.onButtonClick}
        >
          <div className="h-40">
            <PrimaryCta
              text={popup.buttonText}
              onClick={popup.onButtonClick}
            ></PrimaryCta>
          </div>
        </VerificationCard>
      </FullScreenOverlay>

      <LoadingOverlay
        title="Please wait while your ID is being processed"
        text={
          <b className="p5--semi-bold">
            If you accidentally closed the verification window, please refresh
            the page
          </b>
        }
        show={verifying}
      />

      <AccountHeader />
      <section className="account_upload">
        <div className="account_upload__content">
          {titleMapper[status]}

          {/* mati */}
          {showVerificationButton && status === 0 && (
            <div className="account_upload__button">
              <mati-button
                clientid={matiClientId}
                flowId={matiFlowId}
                metadata={`{"email": "${user?.email}"}`}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default IdVerification;


const titleMapper = {
  0: <div className="account_upload__title">
    <p className="label--caps--normal">one last step</p>
    <h1 className="h3-a">We need to verify your identity</h1>
    <p className="p5">
      In order to make sure we are sending medication to the right patient, verifying a patient’s identity is required during sign up.
    </p>
    <p className="p5">
      All you will need to do is upload a photo of your driving license or passport and a selfie.
    </p>
    <p className="p5">
      If you’re having any issues using the ID verification system, you can email a photo of your ID to <a href="mailto:info@lyphe.com">info@lyphe.com</a>
    </p>
  </div>,
  1: "",
  2: <div className="account_upload__title">
    <p className="label--caps--normal">IT’S with us</p>
    <h1 className="h3-a">Your ID is being checked</h1>
    <p className="p5">
      Your ID is currently being checked by the Lyphe Dispensary team.
      You will be sent an email as soon as your ID has been successfully verified.
      If further information is required, our team will get in touch with you.
    </p>
    <p className="p5 p-t-m">
      If you have not had a response after 24 hours, please get in touch with
      our team at <a href="mailto:info@lyphe.com">info@lyphe.com</a>
    </p>
  </div>,
  3: "",
  4: "",
  undefined: <LoadingOverlay
    title="Please wait while we check your verification status"
    text={""
    }
    show={true}
  />
}