import vanillaAxios from "axios";
import moment from "moment";
import ReactGA from 'react-ga';
import jwt from "jsonwebtoken";

export function createFormData(data) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (!Array.isArray(value)) {
      formData.append(key, value);
      continue;
    }

    // if this is an array, you need to
    // add an entry for each element in array
    for (let element of value) {
      formData.append(key, element);
    }
  }

  return formData;
}

export function getPluralText(text, number, lowerCase = false) {
  const s = lowerCase ? "s" : "S";
  if (number !== 1) return `${number} ${text}${s}`;

  return `${number} ${text}`;
}

export function convertIngredientNumberToText(ingredient) {
  //If ingredient is less then 0, that means it is less then given value (e.g <1 mg/ml)
  return ingredient > 0 ? `${ingredient}` : `<${Math.abs(ingredient)}`;
}

/**
 * Returns an object with all the keys
 * in the passed object and values from props
 * @param {*} object an object of props
 */
export function propsToValues(object) {
  const result = {};

  for (let [key] of Object.entries(object)) {
    result[key] = object[key].value;
  }

  return result;
}

/**
 * Returns true if all props.isValid() are true
 * @param {*} props list of props that you get from
 * @see useProps
 */
export function isFormValid(props) {
  for (let item of Object.entries(props)) {
    if (!item[1].isValid()) return false;
  }

  return true;
}

export async function getImageFromUrl(url, name) {
  const config = { responseType: "blob" };
  const { data } = await vanillaAxios.get(url, config);

  return new File([data], name);
}

export function trackAnalyticsEvent(category, action, label = "") {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

export function getCurrentYear() {
  return moment().format("YYYY");
}

export function callMapper(mapper, key, defaultReturn = null) {
  if (!mapper[key]) {
    return defaultReturn;
  }

  return mapper[key];
}

export function resolveAppendableClasses(baseClassName, classNames = "") {
  const classNamesWithSuffixes = classNames.split(" ").map(suffix => {
    return `${baseClassName}--${suffix}`
  }).join(" ");

  return classNamesWithSuffixes
}

export async function getUserFromToken(token) {
  if (!token) return
  const userDetails = await jwt.decode(token);


  const getValue = (part, o) => Object.entries(o).find(([k, v]) => k.includes(part))?.[1]

  const user = {
    role: parseInt(getValue("role", userDetails)),
    id: parseInt(getValue("actor", userDetails)),
    email: getValue("email", userDetails)
  }
  return user
}

export function sortByDateOdestToNewest(list) {
  const initialList = [...list]
  const sortedList = initialList.sort(compareDatesOldestToNewest)

  return sortedList
}
// Prescription date sort function

function compareDatesOldestToNewest(prescription1, prescription2) {
  if (prescription1.rewriteDateTime < prescription2.rewriteDateTime) return -1;
  else if (prescription1.rewriteDateTime > prescription2.rewriteDateTime) return 1;

  return 0;
}