import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUserDetails } from "../../../store/slices/accountSlice";
import PatientProfile from "./PatientProfile";
import CarerProfile from "./CarerProfile";
import { selectUser } from "../../../store/slices/authenticationSlice";
import OrganisationEmployeeProfile from "./OrganisationEmployeeProfile";

function Profile(props) {
  const user = useSelector(selectUser);
  const userDetails = useSelector(selectUserDetails);

  if (!userDetails) return null;

  switch (user?.role) {
    case 1:
      return <PatientProfile />;
    case 2:
      return <CarerProfile />;
    case 3:
      return <OrganisationEmployeeProfile />;
    default:
      return null;
  }
}

export default Profile;
