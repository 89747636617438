import React from "react";
import { resolveAppendableClasses } from "../../../util/util";

function FormSectionTitle({ text, children, className = "", appendableClassNames = "" }) {
  return (
    <div className={`form_section_title ${className} ${resolveAppendableClasses("form_section_title", appendableClassNames)}`} >
      {text} {children}
    </div>
  );
}

export default FormSectionTitle;