import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

const Input = withStyles((theme) => ({
  input: {
    position: "relative",
    backgroundColor: "#F6F6F6",
    borderBottom: "2px solid #787878",
    width: "100%",
    height: "17px",
    fontSize: "0.875rem",
    lineHeight: "18px",
    color: "black",
    fontWeight: 500,
    padding: "29px 26px 12px 11px",
    paddingLeft: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Poppins",
    "&:focus": {
      backgroundColor: "#E8E8E8",
    },
  },
}))(InputBase);

const InputActive = withStyles((theme) => ({
  input: {
    position: "relative",
    backgroundColor: "#F6F6F6",
    borderBottom: "2px solid #2C495B",
    width: "100%",
    height: "17px",
    fontSize: "0.875rem",
    lineHeight: "18px",
    // when focused important on the label overrides
    // the color here, so we need important on this
    color: "black !important",
    fontWeight: 500,
    padding: "29px 26px 12px 11px",
    paddingLeft: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Poppins",
    "&:focus": {
      backgroundColor: "#E8E8E8",
      color: "#787878",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    lineHeight: "14px",
    // important overrides blue when focused
    color: "#787878 !important",
  },
  formControl: {
    width: "100%",
  },
}));

function CustomSelect({
  id = "",
  name = "",
  value = "",
  onChange = () => null,
  options = [],
  isEnabled = true,
  showInitialValueWithNoValue = true,
  hideLabel = false,
}) {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        {!hideLabel &&
          <InputLabel className={classes.label} htmlFor={id}>
            {name}
          </InputLabel>
        }

        <Select
          id={id}
          native
          value={value}
          onChange={onChange}
          input={!value ? <Input /> : <InputActive />}
          disabled={!isEnabled}
        >
          {showInitialValueWithNoValue &&
            <option aria-label="None" value="" />
          }

          {options.map((option) => (
            <option className="option" key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelect;
