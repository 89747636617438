import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../../components/buttons";
import { AgeSelect, InputField } from "../../../components/inputs";
import { LoadingOverlay } from "../../../components/overlays";
import PatientDetailMismatchPopup from "../../../components/popups/PatientDetailMismatchPopup/PatientDetailMismatchPopup";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";
import { useAgeSelect, useProps } from "../../../hooks";
import {
  checkPatientDobClinicNumber,
  selectCheckingPatientData,
} from "../../../store/slices/patientOnboardingSlice";
import { isFormValid, trackAnalyticsEvent } from "../../../util/util";
import { isNumberValidator } from "../../../util/validators";
import { useParams } from "react-router-dom";

function PatientConfirmDobPatientId({ setNextStep, steps }) {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const patientId = useProps("", isNumberValidator());

  const { secretId } = useParams();

  const checkingPatientData = useSelector(selectCheckingPatientData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ageConfig, values, ageErrorMessage] = useAgeSelect(
    "",
    "",
    ""
  );

  const form = { patientId };

  const { day, month, year } = values;

  const formatedMonth = moment().month(month).format("MM");
  const date = day + "/" + formatedMonth + "/" + year;

  async function confirmClicked() {

    const { error } = await dispatch(checkPatientDobClinicNumber({
      dateOfBirth: moment(date, "D/MM/YYYY").format("MM/DD/YYYY"),
      clinicIdNumber: patientId.value,
      secretId: secretId
    }))

    if (!error) {
      trackAnalyticsEvent("Patient Onboarding", "Patient details confirmed");
      setNextStep(steps.thankYouForConfirming);
    }

    if (error) {
      setShowPopup(true);
    }
  }

  const isButtonEnabled =
    isFormValid(form) &&
    day &&
    month &&
    year &&
    !ageErrorMessage &&
    !checkingPatientData;

  const handleClosePopup = () => {
    setShowPopup(false)
  }

  return (
    <section className="patient_confirm_dob_patient_id">
      <LoadingOverlay title='Checking details' show={checkingPatientData} />
      <PatientDetailMismatchPopup show={showPopup} onClose={handleClosePopup} />
      <div className="patient_confirm_dob_patient_id__content">
        <div className='patient_confirm_dob_patient_id__header'>
          <div className='patient_confirm_dob_patient_id__header__small_title'>
            your details
          </div>
          <div className='patient_confirm_dob_patient_id__header__title'>
            Please confirm your details
          </div>
        </div>
        <div className="patient_confirm_dob_patient_id__form">
          <div className="patient_confirm_dob_patient_id__form__input_name">
            Date of birth
          </div>
          <AgeSelect {...ageConfig} error={ageErrorMessage} />
          <div className="patient_confirm_dob_patient_id__form__input_name">
            Patient ID
          </div>
          <div className="patient_confirm_dob_patient_id__form__hint">
            Please email <a href="mailto:info@lyphe.com?subject=Patient ID Request">info@lyphe.com</a> if you can't locate your patient ID"
          </div>
          <InputField
            {...patientId}
            id="firstName"
            name="Enter your ID"
            type="text"
          />
        </div>
        <div className="patient_confirm_dob_patient_id__button">
          <PrimaryCta
            isEnabled={isButtonEnabled}
            onClick={confirmClicked}
            color="orange"
            text={checkingPatientData ? <Spinner /> : "Submit"}
          />
        </div>
      </div>
    </section>
  );
}

export default PatientConfirmDobPatientId;
