import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RepeatPrescriptionHeader from '../../components/headers/RepeatPrescriptionHeader/RepeatPrescriptionHeader';
import Spinner2 from '../../components/sharedComponents/Spinner/Spinner2';
import { getPastPrescriptions, selectLoadingPastPrescriptions, selectPastPrescriptions } from '../../store/slices/prescriptionsSlice';
import { getCurrentClinicDetails, selectCurentClinicDetails } from '../../store/slices/repeatPrescriptionSlice';
import NoPreviousMedication from './NoPreviousMedication';
import NoTMCC from './NoTMCC';
import TMCC from './TMCC';
import { selectUser } from "../../store/slices/authenticationSlice";
import { TMCC_CLINIC_ID } from '../../config.json'


export default function RepeatPrescription() {

    //Create the Zendesk support widget
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const [patientBelongsToTMCC, setPatientBelongsToTMCC] = useState()
    const [patientHasPreviousMedicationWithDG, setPatientHasPreviousMedicationWithDG] = useState()
    const dispatch = useDispatch()

    const user = useSelector(selectUser);

    useEffect(() => {
        dispatch(getCurrentClinicDetails({ userId: user.id }))
        dispatch(getPastPrescriptions());
        // eslint-disable-next-line
    }, [])

    const currentClinicDetails = useSelector(
        selectCurentClinicDetails
    );

    const pastPrescriptions = useSelector(
        selectPastPrescriptions
    )

    const loadingPastPrescriptions = useSelector(
        selectLoadingPastPrescriptions
    )

    useEffect(() => {
        // the mc clinic is the test one    
        if (currentClinicDetails.id === TMCC_CLINIC_ID) {
            setPatientBelongsToTMCC(true)
        } else {
            setPatientBelongsToTMCC(false)
        }

        if (pastPrescriptions.length > 0) {
            setPatientHasPreviousMedicationWithDG(true)
        } else {
            setPatientHasPreviousMedicationWithDG(false)
        }
    }, [currentClinicDetails, pastPrescriptions])

    return (
        <div className='repeat_prescription'>
            <RepeatPrescriptionHeader />
            {loadingPastPrescriptions ? <div className='spinner_loader'>
                <p>Please wait while we check your information</p>
                <Spinner2 color={"#00a372"} width={"50"} />
            </div> : <>
                {patientBelongsToTMCC ? (patientHasPreviousMedicationWithDG ? <TMCC /> : <NoPreviousMedication />) : <NoTMCC />}
            </>}
        </div>
    )
}

