import React from "react";

function PlusIcon({ color = "black" }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" width="2" height="14" rx="1" fill={color} />
      <rect
        x="14"
        y="6"
        width="2"
        height="14"
        rx="1"
        transform="rotate(90 14 6)"
        fill={color}
      />
    </svg>
  );
}

export default PlusIcon;
