import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AutoComplete, InputField } from "../../../components/inputs";
import { PrimaryCta } from "../../../components/buttons";

import { useProps } from "../../../hooks";
import {
  selectPatientId,
  selectUnsoportedFormatFileError,
} from "../../../store/slices/doctorOnboardingSlice";
import ReusableDropZone from "../../../components/sharedComponents/ReusableDropZone/ReusableDropZone";
import { AttentionIcon, UploadIcon } from "../../../assets/icons";
import { EmployeeType, toastTypes } from "../../../enums";
import { setToast } from "../../../store/slices/toastSlice";
import { OrganisationSwitchDropdown } from "../../../components/dropdowns";
import {
  getPrescribersForOrganisationEmployee,
  selectPatientForClinicUser,
  selectPrescribersForOrganisationEmployee,
  setInStateByKey,
  setPrescriptionForUploadAsClinicUser,
  uploadProtoPrescriptionAsOrganisationEmployee,
  selectPrescriptionForUploadAsClinicUser,
  selectFromPrescriptionSliceByKey,
} from "../../../store/slices/prescriptionsSlice";
import { selectCurrentOrganisation, selectUserDetails } from "../../../store/slices/accountSlice";
import { getPluralText } from "../../../util/util";
import { FullScreenOverlay } from "../../../components/overlays";
import Spinner from "../../../components/sharedComponents/Spinner/Spinner";
import { useHistory } from "react-router-dom";

function MobilePrescriptionImageUpload({ setNextStep, steps }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const uploadingPrescription = useSelector(selectFromPrescriptionSliceByKey("uploadingPrescriptionAsClinicUser"));
  const selectedPatientId = useSelector(selectPatientId);
  const prescriptions = useSelector(selectPrescriptionForUploadAsClinicUser);
  const unsoportedFormatFileError = useSelector(
    selectUnsoportedFormatFileError
  );
  const prescribersForOrganisationEmployee = useSelector(selectPrescribersForOrganisationEmployee);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const userDetails = useSelector(selectUserDetails);
  const patientForClinicUser = useSelector(selectPatientForClinicUser);
  const selectedPrescriber = useSelector(selectFromPrescriptionSliceByKey("selectedDoctorForClinicUser"));

  const prescriberSearchString = useProps("");
  const trackingNumber = useProps(patientForClinicUser.trackingNumber);

  const isButtonEnabled =
    selectedPatientId !== undefined ||
    (prescriptions != null && !unsoportedFormatFileError && Object.keys(selectedPrescriber).length);

  function setCurrentPrescriberAsDefault() {
    if (userDetails && prescribersForOrganisationEmployee.length) {
      const currentPrescriber = constructOptionsForPrescriberInput(prescribersForOrganisationEmployee)
        .find(e => e.value === userDetails.id);
      if (currentPrescriber) {
        const payload = {
          key: "selectedDoctorForClinicUser",
          data: currentPrescriber || {},
        };

        dispatch(setInStateByKey(payload));
        prescriberSearchString.setValue(currentPrescriber.label || "");
      }
    }
  }

  useEffect(() => {
    //only fetch data if there is no selected value in the search field, otherwise we want to keep the current state
    if (currentOrganisation.organisationId === null) {
      history.push("/organisation/noaccess")
    }

    // eslint-disable-next-line
  }, [currentOrganisation])

  useEffect(() => {
    setCurrentPrescriberAsDefault();
  }, [userDetails, prescribersForOrganisationEmployee])

  useEffect(() => {
    if (currentOrganisation.organisationId > 0)
      dispatch(getPrescribersForOrganisationEmployee(currentOrganisation.organisationId))
    // eslint-disable-next-line
  }, [currentOrganisation])

  useEffect(() => {
    if (unsoportedFormatFileError) {
      const newToastState = {
        message: "One or more files contain an unsupported file format",
        title: "Files could not be uploaded",
        showToast: true,
        type: toastTypes.Error,
      };
      dispatch(setToast(newToastState));
    }
    // eslint-disable-next-line
  }, [unsoportedFormatFileError]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPrescriberSelect = (e) => {
    const payload = {
      key: "selectedDoctorForClinicUser",
      data: e
    }

    dispatch(setInStateByKey(payload))
    prescriberSearchString.setValue(e.label)
  }

  const onPrescriberRemove = () => {
    const payload = {
      key: "selectedDoctorForClinicUser",
      data: {}
    }
    dispatch(setInStateByKey(payload))
    prescriberSearchString.setValue("")
  }

  const constructOptionsForPrescriberInput = (prescribers) => {
    return prescribers.map((prescriber) => {
      return {
        label: `${prescriber.organisationEmployee.firstName} ${prescriber.organisationEmployee.lastName}`,
        value: prescriber.organisationEmployee.id,
      }
    })
  }

  const onFilesAccepted = (files) => {
    dispatch(setPrescriptionForUploadAsClinicUser(files))
  }

  const onCancel = () => {
    dispatch(setPrescriptionForUploadAsClinicUser([]));
    trackingNumber.setValue("");
  }

  const dispatchSuccessfullProtoPrescriptionUpload = () => {
    const newToastState = {
      showToast: true,
      title: 'Prescription image(s) uploaded!',
      message: 'Uploaded to your organisation’s image only area',
      type: toastTypes.Success
    }
    dispatch(setToast(newToastState));

  }

  const dispatchFailedProtoPrescriptionUpload = (error) => {
    const newToastState = {
      showToast: true,
      title: 'Prescription image(s) upload error!',
      message: `${error.message}`,
      type: toastTypes.Error
    }
    dispatch(setToast(newToastState));
  }

  const dispatchUploadProtoPrescriptionActionAndRefresh = async () => {
    const { error } = await dispatch(uploadProtoPrescriptionAsOrganisationEmployee({
      organisationId: currentOrganisation.organisationId,
      envelopeTrackingCode: trackingNumber.value,
    }))
    if (error) {
      dispatchFailedProtoPrescriptionUpload(error);
    }
    else {
      dispatchSuccessfullProtoPrescriptionUpload(error);
      setNextStep(steps.end);
    }
  }

  const handleNext = async () => {
    await dispatchUploadProtoPrescriptionActionAndRefresh();
  };

  const textContent = () => {
    return <>
      {prescriptions && prescriptions.length > 0 &&
        <>
          <h3 className='drop_zone__zone__heading'>Ready to upload</h3>
          <div className='drop_zone__zone__number_of_files' >
            {getPluralText('prescription image', prescriptions.length, true)} ready to upload
            to:
            <div>
              <strong>{currentOrganisation && currentOrganisation.organisationName}</strong>
            </div>
          </div>
        </>

      }

      {prescriptions && prescriptions.length === 0 &&
        <div className='reusable_drop_zone__zone__number_of_files' >
          <UploadIcon
            color="#D5D5D5"
          />
        </div>
      }
      <span>Select the prescription images</span>
    </>
  }

  return (
    <section className="upload-image-mobile">
      <div className="upload-image-mobile__content">
        <div className="upload-image-mobile__title">
          <h1>Prescription image upload</h1>
          <p className="p5">
            Quickly upload your prescription images into your clinic's 'image
            only' section. The patient details can be added by the Clinic team
            when on the desktop and then sent to Lyphe Dispensary.
          </p>
        </div>

        <div className="divider"></div>

        <div className="upload-image-mobile__form ">
          <div className="organisation_input">
            <p>UPLOADING TO</p>
            <OrganisationSwitchDropdown />
          </div>
          <div className="prescriber-tracking_input">
            {currentOrganisation.employeeType !== EmployeeType.OrganisationPrescriber && (
              <>
                <AutoComplete
                  filter
                  name={"Search prescriber"}
                  onClick={(e) => {
                    onPrescriberSelect(e);
                  }}
                  onRemove={(e) => {
                    onPrescriberRemove(e);
                  }}
                  options={userDetails && constructOptionsForPrescriberInput(prescribersForOrganisationEmployee)}
                  {...prescriberSearchString}
                  className={"m-t-s prescirber-tracking--input"}
                />
              </>
            )}

            <InputField
              className={"prescirber-tracking--input"}
              name={"Tracking number"}
              {...trackingNumber}
            />
          </div>
        </div>

        <div className="upload-image-mobile__dropzone">
          <ReusableDropZone onFilesAccepted={onFilesAccepted} multiple={true} textContent={textContent} />
        </div>
        {unsoportedFormatFileError && (
          <div className="upload-image-mobile__error">
            <div className="upload-image-mobile__error__icon">
              <AttentionIcon />
            </div>
            <div className="upload-image-mobile__error__message">
              One or more of the selected files are not in a supported format
            </div>
          </div>
        )}
        {prescriptions != null && prescriptions.length > 0 &&
          <>
            <div className="upload-image-mobile__button-xxs">
              <PrimaryCta
                isEnabled={isButtonEnabled}
                text="Upload"
                color={"blue"}
                onClick={() => handleNext()}
              />
            </div>
            <div className=" upload-image-mobile__button-xxs">
              <PrimaryCta
                text="Cancel"
                color={"white"}
                onClick={() => onCancel()}
              />
            </div>
          </>
        }
      </div>
      <FullScreenOverlay show={uploadingPrescription} transparent={false}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Spinner color={"#13ae7d"} />
        </div>
      </FullScreenOverlay>
    </section>
  );
}

export default MobilePrescriptionImageUpload;
