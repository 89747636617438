import React from 'react'

function RepeatPrescriptionDispensaryLogo() {
    return (
        <svg width="100" height="100" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <circle cx="256" cy="256" r="248" fill="#fff" stroke-width="0" />
            <path d="M312.48,260.33c27.56,42.86,40.94,74.4,40.94,96.55,0,55.76-45.41,101.01-101.01,101.01s-101.01-45.41-101.01-101.01c0-18.8,10.51-45.88,31.07-80.46h51.94c-7.49,10.52-10.2,14.34-10.99,15.45-27.72,44.61-29,61.97-29,65,0,32.02,26.13,57.99,57.99,57.99s57.99-26.13,57.99-57.99c0-4.78-2.39-24.06-34.09-73.29-10.67-16.57-23.26-34.25-37.44-52.73l-.32-.48c-.8-.96-1.75-2.23-2.71-3.5-1.27-1.59-2.55-3.19-3.82-4.94l-.16-.32c-6.37-8.28-19.28-25.17-33.3-46.36-7.01-10.67-13.38-20.87-18.96-30.27-18-30.91-28.2-49.71-28.2-75.68v-4.3h43.02v4.3c0,14.18,6.21,26.13,22.3,54.01,5.1,8.76,10.99,18.16,17.68,28.2,9.72,14.66,21.19,30.59,31.39,43.65l.48.64c1.27,1.59,2.39,3.19,3.66,4.62,1.12,1.43,2.07,2.71,3.03,3.82l.16.16c14.66,19.76,28.04,38.4,39.35,55.92h0ZM310.41,65.16v4.3c0,13.7-5.1,28.2-34.09,73.29-3.19,5.1-6.69,10.2-10.36,15.61,5.74,8.13,11.79,16.09,17.37,23.26l.64.8c1.12,1.59,2.39,3.03,3.5,4.46,1.12,1.43,2.23,2.71,3.03,3.98l.16.32c.8,1.12,1.75,2.23,2.55,3.35,6.85-9.72,13.38-19.28,19.28-28.36,28.36-43.97,40.94-68.35,40.94-96.71v-4.3h-43.02Z" fill="#03a171" stroke-width="0" />
        </svg>
    )
}
export default RepeatPrescriptionDispensaryLogo