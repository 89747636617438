import React from "react";
import { resolveAppendableClasses } from "../../../util/util";

function ReusableButton({ onClick = () => null, className = "", appendableClassnames = "", children, text, icon }) {
  return (
    <button onClick={(e) => onClick(e)} className={`reusable_button ${className} ${resolveAppendableClasses("reusable_button", appendableClassnames)}`} >
      {icon} &nbsp;
      {text}
      {children}
    </button>
  );
}

export default ReusableButton;