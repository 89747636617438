import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrimaryCta } from "../../../components/buttons";

import { logout } from "../../../store/slices/authenticationSlice";
import { selectIsVerified } from "../../../store/slices/accountSlice";

import { BigTickIcon } from "../../../assets/icons";

function VerificationEmailResent(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const isEmailVerified = useSelector(selectIsVerified);

  useEffect(() => {
    if (isEmailVerified) history.push("/");
  }, [isEmailVerified, history]);

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  function handleLogout() {
    dispatch(logout());
    history.push("/auth/login");
  }

  return (
    <section className="verify_email">
      <div className="verify_email__content">
        <div className="verify_email__image">
          <BigTickIcon />
        </div>
        <div className="verify_email__title">
          <p className="label--caps--normal">
            Another verification email was sent to you
          </p>
          <h1 className="h3-a">Check you email</h1>
          <p className="p5"></p>
        </div>

        <div className="verify_email__button">
          <PrimaryCta color="orange" text="logout" onClick={handleLogout} />
        </div>
      </div>
    </section>
  );
}

export default VerificationEmailResent;
