import "./App.scss";
import "react-awesome-lightbox/build/style.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import MainRouter from "./routers/MainRouter";

import {
  selectUser,
  setUserFromToken,
} from "./store/slices/authenticationSlice";
import { useGetUserDetails } from "./hooks";

import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

// const tagManagerArgs = {
//   gtmId: "UA-184803219-1"
// }
// TagManager.initialize(tagManagerArgs);

function App() {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    const token = localStorage.getItem("token");

    dispatch(setUserFromToken({ token }));
    //eslint-disable-next-line
  }, []);

  useGetUserDetails();

  return (
    <Router>
      <main>{user !== undefined && <MainRouter />}</main>
    </Router>
  );
}

export default App;
