import React from "react";

import { PrimaryCta } from "../../../components/buttons";

import { ArrowDownIcon, ArrowUpIcon } from "../../../assets/icons";

import { tmcSchedulingLink } from "../../../config.json";
import { trackAnalyticsEvent } from "../../../util/util";

function PatientEligibilityCriteria({ setNextStep, steps }) {
  return (
    <section className="eligibility_criteria">
      <div className="eligibility_criteria__content">
        <div className="eligibility_criteria__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Eligibility criteria</h1>
          <p className="p5">
            Issuing a medical cannabis prescription is solely down to the
            discretion of your specialist doctor. Some of the criteria that your
            doctor may choose to guide their opinion is provided below.
          </p>
        </div>

        <div className="eligibility_criteria__text">
          <div className="eligibility_criteria__group">
            <details>
              <summary>
                <p className="p2">Conditions</p>
                <div className="details_open">
                  <ArrowUpIcon />
                </div>
                <div className="details_closed">
                  <ArrowDownIcon />
                </div>
              </summary>
              <p className="label--light">
                Some of the conditions you need to have been diagnosed with:
              </p>

              <ul>
                <li className="label--light">General Anxiety Disorder (GAD)</li>
                <li className="label--light">
                  Pain, Naturopathic Pain or Chronic Pain
                </li>
                <li className="label--light">
                  Post Traumatic Stress Disorder (PTSD)
                </li>
                <li className="label--light">Sleep Disorder</li>
                <li className="label--light">
                  Attention Deficit Hyperactivity Disorder (ADHD)
                </li>
              </ul>
            </details>
          </div>

          <div className="eligibility_criteria__group">
            <details>
              <summary>
                <p className="p2">Eligibility</p>
                <div className="details_open">
                  <ArrowUpIcon />
                </div>
                <div className="details_closed">
                  <ArrowDownIcon />
                </div>
              </summary>
              <p className="label--light test">
                To be eligible, you must have an unmet medical need where a
                number of marketing authorized medications have been tried by
                your doctor.
              </p>
            </details>
          </div>
          <div className="eligibility_criteria__group">
            <details>
              <summary>
                <p className="p2">Pricing</p>
                <div className="details_open">
                  <ArrowUpIcon />
                </div>
                <div className="details_closed">
                  <ArrowDownIcon />
                </div>
              </summary>
              <p className="label--light">
                Consultation fees with specialist doctors start from £100
              </p>
            </details>
          </div>
        </div>

        <div className="eligibility_criteria__buttons">
          <div>
            <PrimaryCta
              color="white"
              text="Back to previous page"
              onClick={() => setNextStep(steps.patientDoYouHavePrescription)}
            />
          </div>
          &nbsp; &nbsp;
          <div>
            <PrimaryCta
              color="orange"
              text="Proceed"
              link={tmcSchedulingLink}
              isLinkExternalLink
              onClick={() => trackAnalyticsEvent("Patient Onboarding", "Sent to Consultation link")}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PatientEligibilityCriteria;
