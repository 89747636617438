import React from 'react'

function RepeatPrescriptionLoaderArrows() {
    return (
        <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 30L50.5 20L38 10V17.4975H5.5V22.4975H38V30ZM55.5 37.5H23V30L10.5 40L23 50V42.5H55.5V37.5Z" fill="#D5D5D5" />
        </svg>
    )
}

export default RepeatPrescriptionLoaderArrows
