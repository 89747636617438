import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowLeftIcon, ArrowRightIcon } from '../../assets/icons';
import { selectResetPagination, selectOrderIdFilter, selectPatientIdFilter } from '../../store/slices/prescriptionsSlice';
import Spinner from '../sharedComponents/Spinner/Spinner';



const DoctorPatientsTable = ({
  tableData,
  body,
  loadingComponent = { component: Spinner },
  loadingData,
  noDataComponent = { component: noDataDefaultComponent }
}) => {
  const [page, setPage] = useState(1);
  const selectedPatientIdFromStore = useSelector(selectPatientIdFilter);
  const selectedOrderIdFromStore = useSelector(selectOrderIdFilter);
  const resetPagination = useSelector(selectResetPagination);
  const totalPages = Math.ceil(body.length / 10);

  useEffect(() => {
    setPage(1)
  }, [selectedOrderIdFromStore, selectedPatientIdFromStore, resetPagination]);

  function renderTableHead() {
    // .table-col class goes from 1 to 24, which is a 12-grid multiplied by 2. This enables 0.5 steps in tables.
    const renderRowClass = (item) =>
      `table-col-${item.column * 2} ${item.left && "left"} ${item.right && "right"}`;

    return tableData.head.map((item, index) => (
      <th
        key={index}
        className={renderRowClass(item)}
      >
        {item.text}
      </th>
    ));
  }

  const renderTableRow = (cells) => {
    return (
      cells.map((cell, index) =>
        <td key={index} className={renderRowClass(cell)}>
          {cell.text}{cell?.components}
        </td>)
    )
  }

  const renderTableBody = (rows) => {
    return (
      rows.slice((page - 1) * 10, page * 10).map((item, index) => {
        return (
          <tr key={index}>
            {renderTableRow(item)}
          </tr>
        )
      })
    )
  }

  const renderRowClass = (item) => `table-col-${item.column * 2} ${item.left ? "left" : ""} ${item.right ? "right" : ""} ${item.cellClasses ? item.cellClasses : ''}`;

  const paginationButtons = Array(totalPages)
    .fill('1')
    .map((p, i) => {
      return (
        <span key={i} className={page === i + 1 ? 'active_page' : ''} onClick={() => {
          setPage(i + 1)
          if (i + 1 === totalPages) {
            window.scrollTo(0, 0)
          }
        }}>{i + 1} </span>
      )
    })

  const renderPagination = () => {
    if (totalPages <= 3) {
      return paginationButtons
    }
    if (totalPages - 2 < page && totalPages >= page) {
      return paginationButtons.slice(totalPages - 3, totalPages)
    }
    return paginationButtons.slice(page - 1, page + 2)
  }

  return (
    <table className='doctor_patients_table'>
      <thead>
        <tr>
          {renderTableHead()}
        </tr>
      </thead>
      <tbody>
        {body.length ?
          renderTableBody(body)
          :
          loadingData ?
            <tr>
              <td className='table-col-24 loading_row'> <loadingComponent.component /> </td>
            </tr>
            :
            <noDataComponent.component />
        }
      </tbody>
      <tfoot>
        <tr>
          <td className="doctor_patients_table__pagination">
            <button disabled={page === 1} type='button' onClick={() => { setPage(page - 1) }}>
              <ArrowLeftIcon />
            </button>

            {renderPagination()}

            {page !== totalPages && page < totalPages - 2 && <span  >... </span>}
            <button
              disabled={page === totalPages}
              type='button'
              onClick={() => {
                setPage(page + 1)
                if (page + 1 === totalPages) window.scrollTo(0, 0)
              }}>
              <ArrowRightIcon />
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default DoctorPatientsTable

const noDataDefaultComponent = () => {
  return <tr>
    <td className='table-col-24 loading_row'> TABLE IS EMPTY </td>
  </tr>
}