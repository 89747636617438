import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { setLogoutModal } from "../../../../store/slices/modalsSlice";
import nhsLogo from "../../../../assets/images/nhs-logo.svg";
import { termsAndConditionsUrl } from '../../../../config.json'
import { privacyPolicyUrl } from '../../../../config.json'
import { learnUrl } from '../../../../config.json'
import { complaintsUrl, patientFeedback } from '../../../../config.json'
import { nhsPrescriptionServicesUrl } from '../../../../config.json'
import { healthAnexietyPanic } from '../../../../config.json'
import RenderForUserRoles from "../../../sharedComponents/RenderForUserRoles";
import { UserRoles } from "../../../../enums";

function FooterNavigation() {
  const dispatch = useDispatch();

  return (
    <div className="account_footer__navigation">
      <div className="account_footer__navigation__group">
        <p>Account</p>
        <ul>
          <li>
            <Link to="/profile">
              My profile
            </Link>
          </li>
          <li>
            <a href={learnUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn
            </a>
          </li>
          <li>
            <a href={patientFeedback}
              target="_blank"
              rel="noopener noreferrer">
              Help us improve
            </a>
          </li>
          <li>
            <Link to="/support">
              Support
            </Link>
          </li>
          <li onClick={() => dispatch(setLogoutModal({ show: true }))}>
            <Link to="#">
              Log out
            </Link>
          </li>
        </ul>
      </div>
      <RenderForUserRoles userRoles={[UserRoles.Patient, UserRoles.Carer]} >
        <div className="account_footer__navigation__group">
          <p>Help & Support</p>
          <ul>
            <li>
              <a href={complaintsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >Complaints</a>
            </li>
            <li>
              <a href={nhsPrescriptionServicesUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={nhsLogo} alt="NHS logo" /> Prescription services
              </a>
            </li>
            <li>
              <a href={healthAnexietyPanic}
                target="_blank"
                rel="noopener noreferrer">
                Health: Anxiety, fear & panic</a>
            </li>
          </ul>
        </div>
      </RenderForUserRoles>

      <div className="account_footer__navigation__group">
        <p>Legal</p>
        <ul>
          <li>
            <a href={termsAndConditionsUrl}
              target="_blank"
              rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href={privacyPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterNavigation;
