import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PrimaryCta } from "../../buttons";
import { VerificationCard } from "../../cards";
import { FullScreenOverlay } from "../../overlays";

import { logout } from "../../../store/slices/authenticationSlice";
import {
  selectLogoutModal,
  setLogoutModal,
} from "../../../store/slices/modalsSlice";

import { CrossFailIcon } from "../../../assets/icons";

function LogoutPopup(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutModal = useSelector(selectLogoutModal);

  function handleNo() {
    dispatch(setLogoutModal({ show: false }));
  }

  function handleYes() {
    dispatch(setLogoutModal({ show: false }));
    dispatch(logout());

    history.push("/auth/login");
  }

  return (
    <FullScreenOverlay show={logoutModal.show}>
      <VerificationCard
        onClick={() => dispatch(setLogoutModal({ show: false }))}
        cardTitle="Are you sure you want to logout?"
        cardText=""
        Image={CrossFailIcon}
      >
        <div>
          <PrimaryCta text="No" color="white" onClick={handleNo} />
        </div>
        <div>
          <PrimaryCta text="Yes" color="orange" onClick={handleYes} />
        </div>
      </VerificationCard>
    </FullScreenOverlay>
  );
}

export default LogoutPopup;
