import React from "react";

function Toggler({ isChecked = false, onChange = () => null }) {
    return (
        <div>
            <label className="toggler">
                <input type="checkbox" checked={isChecked} onChange={onChange} />
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default Toggler;