import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../../buttons";
import { VerificationCard } from "../../cards";
import { FullScreenOverlay } from "../../overlays";

import {
  selectBookAConsultationModal,
  setBookAConsultationModal,
} from "../../../store/slices/modalsSlice";

import { CrossFailIcon } from "../../../assets/icons";

function ConsultationPopup(props) {
  const dispatch = useDispatch();

  const bookAConsultationModal = useSelector(selectBookAConsultationModal);

  function handleOk() {
    dispatch(setBookAConsultationModal({ show: false }));
    window.location.reload();
  }

  return (
    <FullScreenOverlay show={bookAConsultationModal.show}>
      <VerificationCard
        onClick={() => { dispatch(setBookAConsultationModal({ show: false })); window.location.reload() }}
        cardTitle="Your doctor will be in touch to arrange a consultation!"
        cardText=""
        Image={CrossFailIcon}
      >
        <div>
          <PrimaryCta text="Ok" color="orange" onClick={handleOk} />
        </div>
      </VerificationCard>
    </FullScreenOverlay>
  );
}

export default ConsultationPopup;
