import React from "react";

function DownloadIcon({ color = "black" }) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.86292 0C7.60042 0 7.38166 0.21875 7.38166 0.48125V9.275L4.23166 6.125C4.14417 6.0375 4.01292 5.99375 3.88166 5.99375C3.61917 5.99375 3.40042 6.2125 3.40042 6.475C3.40042 6.60625 3.44416 6.7375 3.53167 6.825L7.51292 10.8063C7.51292 10.8063 7.51292 10.8063 7.55666 10.8063C7.55666 10.8063 7.55666 10.8063 7.60041 10.8063C7.60041 10.8063 7.60042 10.8063 7.64417 10.8063C7.64417 10.8063 7.64417 10.8063 7.68792 10.8063C7.68792 10.8063 7.68791 10.8063 7.73167 10.8063H7.77541H7.81916C7.81916 10.8063 7.81917 10.8063 7.86292 10.8063C7.86292 10.8063 7.86292 10.8063 7.90667 10.8063H7.95042C7.95042 10.8063 7.95041 10.8063 7.99416 10.8063H8.03791C8.03791 10.8063 8.03792 10.8063 8.08167 10.8063C8.08167 10.8063 8.12542 10.8063 8.12542 10.7625C8.12542 10.7625 8.12542 10.7625 8.16917 10.7625L12.1504 6.78125C12.2379 6.69375 12.2817 6.5625 12.2817 6.43125C12.2817 6.16875 12.0629 5.95 11.8004 5.95C11.6692 5.95 11.5379 5.99375 11.4504 6.08125L8.30042 9.23125V0.525C8.34416 0.21875 8.12541 0 7.86292 0ZM1.38792 12.9938C1.38792 12.9938 1.34417 12.9938 1.38792 12.9938C1.12542 12.9938 0.862915 13.2125 0.862915 13.475C0.862915 13.7375 1.08167 14 1.34417 14H14.3379C14.6004 14 14.8192 13.7812 14.8192 13.5188C14.8192 13.2562 14.6004 13.0375 14.3379 13.0375H1.38792V12.9938Z"
        fill={color}
      />
    </svg>
  );
}

export default DownloadIcon;
