import React from "react";

import { CloseIcon } from "../../../assets/icons";

function VerificationCard({
  mainTitle = "",
  cardTitle = "",
  cardText = "",
  children,
  Image = null,
  showClose = true,
  onClick = () => null,
}) {
  return (
    <div className="verification_card">
      <div className="verification_card__header">
        <p className="p4-a">{mainTitle}</p>
        <div onClick={onClick}>
          <CloseIcon />
        </div>
      </div>
      {showClose &&
        <div className="verification_card__close">
          <div onClick={onClick}>
            <CloseIcon />
          </div>
        </div>
      }
      <div className="verification_card__content">
        <div></div>

        {Image ? (
          <div className="m-v-m">
            <Image />
          </div>
        ) : (
          <div className="verification_card__no_image"></div>
        )}

        <div className="verification_card__title">
          <h1 className="h8-a">{cardTitle}</h1>
          <p className="p5">{cardText}</p>
        </div>

        <div className="verification_card__buttons">{children}</div>
      </div>
    </div>
  );
}

export default VerificationCard;
