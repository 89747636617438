import React from "react";

function TwitterIcon({ color = "black" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={color}
      />
      <path
        d="M9.80447 18.3293C15.1253 18.3293 18.0353 13.9212 18.0353 10.0986C18.0353 9.97375 18.0325 9.84915 18.0269 9.72475C18.593 9.31431 19.0817 8.80639 19.4699 8.22476C18.9418 8.45843 18.3819 8.61193 17.8085 8.68015C18.4123 8.31856 18.8641 7.74987 19.0799 7.07996C18.5121 7.41681 17.891 7.65423 17.2433 7.78196C16.8074 7.31702 16.2302 7.00895 15.6014 6.90555C14.9725 6.80214 14.3271 6.90918 13.7653 7.21005C13.2035 7.51093 12.7567 7.98881 12.4942 8.56956C12.2317 9.15031 12.1683 9.80146 12.3137 10.422C11.1634 10.3645 10.0381 10.0658 9.01081 9.54509C7.98353 9.02443 7.07727 8.29349 6.35087 7.39976C5.98093 8.03654 5.86758 8.79038 6.03389 9.5078C6.2002 10.2252 6.63368 10.8523 7.24607 11.2614C6.78673 11.2481 6.3373 11.1247 5.93567 10.9014C5.93567 10.9134 5.93567 10.9254 5.93567 10.9386C5.93602 11.6065 6.16736 12.2537 6.59046 12.7705C7.01356 13.2873 7.60238 13.6419 8.25707 13.7742C7.83103 13.8905 7.38394 13.9076 6.95027 13.824C7.13507 14.3983 7.49457 14.9007 7.9786 15.2609C8.46262 15.6211 9.04703 15.8212 9.65027 15.8334C8.62598 16.6365 7.36147 17.072 6.05987 17.07C5.8293 17.0701 5.59891 17.0569 5.36987 17.0303C6.69279 17.8799 8.23225 18.3309 9.80447 18.3293"
        fill="white"
      />
    </svg>
  );
}

export default TwitterIcon;
