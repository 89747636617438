import React from "react";
import { useHistory } from "react-router-dom";

function TertiaryCta({
  text = "",
  link = "",
  icon = null,
  color = "black",
  onClick = () => null,
  isEnabled = true,
}) {
  const history = useHistory();

  function beforeOnClick() {
    if (link) history.push(link);

    onClick();
  }
  return (
    <button
      onClick={beforeOnClick}
      className={`tertiary_cta tertiary_cta--${color}`}
      disabled={!isEnabled}
    >
      {icon && <span tabIndex="-1">{icon}</span>}
      {text}
    </button>
  );
}

export default TertiaryCta;
