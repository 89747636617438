import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PrimaryCta } from "../../components/buttons";
import { AuthHeader } from "../../components/headers";
import { PasswordInputField } from "../../components/inputs";

import { useProps } from "../../hooks";
import { notEmptyValidator, passwordValidator } from "../../util/validators";

import {
  resetPassword,
  selectAccountLoading,
} from "../../store/slices/accountSlice";

function PasswordReset(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  const { secretId } = useParams();
  const loading = useSelector(selectAccountLoading);

  const password = useProps("", passwordValidator());
  const confirmPassword = useProps("", notEmptyValidator("Confirm password field"));

  const isButtonEnabled =
    password.isValid() &&
    confirmPassword.isValid() &&
    password.value === confirmPassword.value &&
    !loading;

  async function confirmClicked() {
    const payload = { password: password.value, secretId };

    const { error } = await dispatch(resetPassword(payload));

    if (error) {
      history.push("/auth/resetPassword/fail");

      return;
    }

    history.push("/auth/resetPassword/success");
  }

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <AuthHeader hideClose={true} />
      <div className="auth_wrapper">
        <section className="auth">
          <div className="auth__header">
            <h1 className="h4-p-a">Password reset</h1>
            <p className="p5">Please enter your new password.</p>
          </div>
          <form className="auth__form">
            <div className="form-spacing">
              <PasswordInputField
                {...password}
                type="password"
                name="Enter your new password"
              />
            </div>
            <div className="form-spacing">
              <PasswordInputField
                {...confirmPassword}
                type="password"
                name="Confirm your new password"
              />
            </div>
          </form>
          <div className="auth__buttons">
            <div className="h-48">
              <PrimaryCta
                text="Confirm"
                onClick={confirmClicked}
                isEnabled={isButtonEnabled}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PasswordReset;
