import React from "react";

import { AttentionIcon } from "../../../assets/icons";

function NoticeBanner({ text }) {
  return (
    <div className="notice_banner">
      <div>
        <AttentionIcon />
      </div>
      <div className="label--smaller">
        {text}
      </div>
    </div>
  );
}

export default NoticeBanner;
