import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LoadingOverlay } from "../../../components/overlays";
import { PrimaryCta, TertiaryCta } from "../../../components/buttons";
import { TipsCarousel } from "../../../components/carousels";
import { RadioButton } from "../../../components/inputs";
import { ViewPrescription } from "../../../components/popups";
import { NoticeCard, PrescriptionCard } from "../../../components/cards";

import { selectUser } from "../../../store/slices/authenticationSlice";
import {
  selectUploadingPrescription,
  uploadPrescription,
} from "../../../store/slices/prescriptionsSlice";
import {
  selectPrescriptionModal,
  setPrescriptionModal,
} from "../../../store/slices/modalsSlice";
import { useRadioButton, useUploadFile } from "../../../hooks";
import { selectUserDetails } from "../../../store/slices/accountSlice";

import { UploadIcon } from "../../../assets/icons";
import { UploadingTips } from "../../../enums";

function PatientAccountUploadPrescription({ setNextStep, steps }) {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const uploadingPrescription = useSelector(selectUploadingPrescription);
  const prescriptionModal = useSelector(selectPrescriptionModal);
  const userDetails = useSelector(selectUserDetails);

  const fileInputRef = useRef(null);

  const [
    prescription,
    prescriptionError,
    resetPrescription,
    fileSelected,
  ] = useUploadFile();

  const agreed = useRadioButton(false);

  async function submitClicked() {
    const { error } = await dispatch(
      uploadPrescription({
        prescription: { ...prescription, confirmedConsent: true },
        patientId: userDetails.patientId,
      })
    );

    if (!error) {
      setNextStep(steps.uploadSuccess);
    }
  }

  // if user is a patient check agreed to true
  // because patient doesn't need to check that
  useEffect(() => {
    if (user?.role === 1) {
      // set agreed to true
      agreed.onClick();
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <section className="upload_prescription">
      <ViewPrescription
        showPopup={prescriptionModal.show}
        image={prescriptionModal.image}
        onClose={() =>
          dispatch(setPrescriptionModal({ show: false, image: null, imageName: null }))
        }
      />
      <LoadingOverlay
        show={uploadingPrescription}
        title="Uploading your prescription"
      />

      <div className="upload_prescription__content">
        <NoticeCard
          titleTop="Please note"
          textTop={<div>Please note, you will only need to upload your prescription if your Doctor has advised you to do so. Your Doctor may have already done this for you, if you are unsure please contact our patient support team.</div>}
        />
        <div className="upload_prescription__title">
          <p className="label--caps--normal">Getting started</p>
          <h1 className="h3-a">Please upload a photo of the prescription</h1>
          <p className="p5">
            Make sure your photo meets the requirements below to avoid delays in
            processing your order.
          </p>
        </div>
        {prescription.url ? (
          <>
            <div className="upload_prescription__uploaded">
              <div>
                <PrescriptionCard
                  title="Uploaded prescription"
                  image={prescription.url}
                  imageName={prescription.name}
                  onClick={() => resetPrescription()}
                  actionName="Delete"
                />
              </div>
            </div>
            {user?.role === 2 && (
              <div className="upload_prescription__terms radio-container">
                <RadioButton
                  id="accept_terms"
                  name="I acknowledge that I have the patients consent to submit their prescription on their behalf"
                  {...agreed}
                />
              </div>
            )}

            <div className="upload_prescription__primary_button">
              <PrimaryCta
                color="orange"
                text="Submit prescription"
                isEnabled={agreed.value}
                onClick={submitClicked}
              />
            </div>
          </>
        ) : (
          <>
            <div className="upload_prescription__carousel">
              <TipsCarousel tips={UploadingTips} />
            </div>
            {user?.role === 2 && (
              <div className="upload_prescription__terms radio-container">
                <RadioButton
                  id="accept_terms"
                  name="I acknowledge that I have the patients consent to submit their prescription on their behalf"
                  {...agreed}
                />
              </div>
            )}
            <div className="upload_prescription__tertiary_button">
              <input
                ref={(element) => (fileInputRef.current = element)}
                type="file"
                hidden
                id="prescription"
                onChange={fileSelected}
                accept="image/*,application/pdf"
              />
              <TertiaryCta
                color="white"
                text="Upload prescription"
                icon={<UploadIcon />}
                onClick={() => fileInputRef.current.click()}
              />

              {prescriptionError && (
                <div className="upload_prescription__error">
                  Image size must be under 20 MB
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default PatientAccountUploadPrescription;
