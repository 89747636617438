import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserRoles } from "../enums";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/authenticationSlice";

function ProtectedClinicUserRoute({
  component: Component,
  checkEmail = true,
  checkId = true,
  ...rest
}) {

  const user = useSelector(selectUser);

  return (
    <Route
      {...rest}
      render={(props) =>
        (user && user?.role === UserRoles.OrganisationEmployee) ? <Component {...props} /> : <Redirect to="/auth/login" />
      }
    />
  );
}

export default ProtectedClinicUserRoute;