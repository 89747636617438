import React from "react";
import { useHistory } from "react-router-dom";

import { Logo } from "../../../assets/images";
import { CloseIcon } from "../../../assets/icons";

function BeforeWeStartHeader({ showClose = true }) {
  const history = useHistory();

  return (
    <header className="before_we_start_header">
      <div className="before_we_start_header__content">
        <Logo color="white" />
        {showClose &&
          <div
            className="before_we_start_header__close"
            onClick={() => history.goBack()}
          >
            <p className="label">Close</p>
            <div className="close_icon--white">
              <CloseIcon color="white" />
            </div>
            <div className="close_icon--black">
              <CloseIcon color="black" />
            </div>
          </div>
        }
      </div>
    </header>
  );
}

export default BeforeWeStartHeader;
