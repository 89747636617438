import moment from 'moment';
import React from 'react'

const OrderDetails = ({ order }) => {

    return (
        <div className='order_details'>
            <div className="order_details__count"> {order?.products?.length} item{order?.products?.length > 1 && 's'} in this order</div>
            <h4>Order details</h4>
            <div className="order_details__information">
                <div className="order_details__information__column">
                    <div className="order_details__information__column__row">
                        <span className='order_details__information__column__row__title'>ORDER ID:</span> <span>{order?.orderId}</span>
                    </div>
                    <div className="order_details__information__column__row">
                        <span className='order_details__information__column__row__title'>PATIENT:</span> <span>{order?.patient?.firstName} {order?.patient?.lastName}</span>
                    </div>
                    <div className="order_details__information__column__row">
                        <span className='order_details__information__column__row__title'>DOB:</span> <span>{order?.patient?.dateOfBirth && moment(order?.patient?.dateOfBirth).format("DD/MM/YY")}</span>
                    </div>
                </div>
                <div className="order_details__information__column">
                    <div className="order_details__information__column__row">
                        <span className='order_details__information__column__row__title'>EMAIL:</span> <span>{order?.patient?.user?.email}</span>
                    </div>
                    <div className="order_details__information__column__row">
                        <span className='order_details__information__column__row__title'>PHONE:</span> <span>{order?.patient?.telephoneNo}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails
