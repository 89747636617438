import React from "react";

function DetailsIcon({ color = "black" }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5946 0C14.3513 0 14.3514 0 14.1081 0.243243L11.6757 2.67568H0.72973C0.243243 2.67568 0 2.91892 0 3.16216V17.2703C0 17.5135 0.243243 18 0.72973 18H14.8378C15.0811 18 15.5676 17.7568 15.5676 17.2703V6.32432L18 3.89189C18.2432 3.64865 18.2432 3.16216 18 2.91892L15.0811 0.243243C15.0811 0 14.8378 0 14.5946 0ZM14.5946 1.45946L16.2973 3.16216L10.7027 9.24324L8.51351 9.48649L8.75676 7.2973L14.5946 1.45946ZM1.21622 3.89189H10.4595L7.78378 6.56757C7.78378 6.56757 7.54054 6.81081 7.54054 7.05405L7.05405 10.2162C7.05405 10.4595 7.2973 10.9459 7.54054 10.9459H7.78378L10.9459 10.4595C11.1892 10.4595 11.1892 10.4595 11.4324 10.2162L14.1081 7.54054V16.7838H1.21622V3.89189Z"
        fill={color}
      />
    </svg>
  );
}

export default DetailsIcon;
