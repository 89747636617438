import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prescriptionModal: { show: false, image: null },
  resubmitModal: { show: false, title: "", text: "" },
  payModal: { show: false, order: null },
  logoutModal: { show: false },
  bookAConsultationModal: { show: false },
  genericSuccessModal: { show: false, title: "", text: "" },
  startLiveChatPopup: { show: false },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,

  // sync reducers
  reducers: {
    setPrescriptionModal(state, { payload }) {
      state.prescriptionModal = payload;
    },

    setResubmitModal(state, { payload }) {
      state.resubmitModal = payload;
    },

    setPayModal(state, { payload }) {
      state.payModal = payload;
    },

    setLogoutModal(state, { payload }) {
      state.logoutModal = payload;
    },

    setBookAConsultationModal(state, { payload }) {
      state.bookAConsultationModal = payload;
    },

    setGenericSuccessModal(state, { payload }) {
      state.genericSuccessModal = payload;
    },

    setStartLiveChatPopup(state, { payload }) {
      state.startLiveChatPopup = payload;
    },
  },
});

export default modalsSlice.reducer;

// export sync actions
export const {
  setPrescriptionModal,
  setResubmitModal,
  setPayModal,
  setLogoutModal,
  setBookAConsultationModal,
  setGenericSuccessModal,
  setStartLiveChatPopup
} = modalsSlice.actions;

export const selectPrescriptionModal = (state) =>
  state.modals.prescriptionModal;

export const selectResubmitModal = (state) => state.modals.resubmitModal;

export const selectPayModal = (state) => state.modals.payModal;

export const selectLogoutModal = (state) => state.modals.logoutModal;

export const selectBookAConsultationModal = (state) => state.modals.bookAConsultationModal;

export const selectGenericSuccessModal = (state) => state.modals.genericSuccessModal;

export const selectStartLiveChatPopup = (state) => state.modals.startLiveChatPopup;