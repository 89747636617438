import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryCta } from "../buttons";

import {
  selectFilters,
  selectOrderIdFilter,
  selectPatientIdFilter,
  selectSort,
  setFilters,
  setPatientIdFilter,
  setResetPagination,
  setSort,
} from "../../store/slices/prescriptionsSlice";
import { Filters, Sorts } from "../../enums";

import {
  ArrowDownIcon,
  ArrowUpIcon,
  FilterIcon,
  TickIcon,
} from "../../assets/icons";
import { useGetSelectedPatientName, useProps } from "../../hooks";

function Filter(props) {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showStatusMenu, setShowStatusMenu] = useState(true);

  const [isChanged, setIsChanged] = useState(false);

  const selectedPatientIdFromStore = useSelector(selectPatientIdFilter)
  const selectedOrderIdFromStore = useSelector(selectOrderIdFilter)
  const selectedSortFromStore = useSelector(selectSort)
  const filtersFromStore = useSelector(selectFilters)
  /* turn selected field on all sort aptions to false ,
   and than find the sort option that corresponds to the selected sort from store and turn it to true.
  This is so the selected value gets persisted when the url changes. */
  const sortOptionsUpdated = useMemo(() => {
    const tempSortOptions = SortOptions.map(o => { o.selected = false; return o })
    tempSortOptions[selectedSortFromStore].selected = true
    return tempSortOptions
    // eslint-disable-next-line
  }, [])

  const [selectedPatientId, setSelectedPatientId] = useState(selectedPatientIdFromStore ? selectedPatientIdFromStore : null);
  const [sortOptions, setSortOptions] = useState(sortOptionsUpdated);

  const [orderStatusOptions, setOrderStatusOptions] = useState(FilterStatusOptions);

  const selectedPatientsName = useGetSelectedPatientName()
  const autoComplete = useProps(selectedPatientsName ? selectedPatientsName : selectedOrderIdFromStore ? String(selectedOrderIdFromStore) : '');

  const restoreStatusFilters = () => {
    const tempFilterOptions = FilterStatusOptions.map(o => {
      if (filtersFromStore.includes(o.enum)) {
        o.selected = true
      } else {
        o.selected = false
      }
      return o
    })

    setOrderStatusOptions(tempFilterOptions)
  }

  useEffect(() => {
    restoreStatusFilters();

    if (!filtersFromStore.length) {
      FilterStatusOptions[0].selected = true
      dispatch(setFilters([9, 3]))
    }

    // eslint-disable-next-line
  }, [filtersFromStore, dispatch])

  useEffect(() => {
    setSelectedPatientId(selectedPatientIdFromStore)

  }, [selectedPatientIdFromStore])


  /**
 */
  const countSelectedSortItems = useCallback(
    () => {
      let numOfSelectedItems = 0;

      for (let i = 1; i < sortOptions.length; i++) {
        if (sortOptions[i].selected === true) {
          numOfSelectedItems++;

          break;
        }
      }
      return numOfSelectedItems;
    },
    [sortOptions],
  )

  /**
   * Counts selected type items, doesn't count the first one
   */

  const countSelectedOrderStatuses = useCallback(
    () => {
      let numOfSelectedOrderStatuses = 0;
      for (let i = 1; i < orderStatusOptions.length; i++) {
        if (orderStatusOptions[i].selected === true) numOfSelectedOrderStatuses++;
      }

      return numOfSelectedOrderStatuses;
    },
    [orderStatusOptions],
  )


  const numberOfSelectedFilters = useMemo(() => {
    return countSelectedSortItems() + countSelectedOrderStatuses()
  }, [countSelectedOrderStatuses, countSelectedSortItems])


  /**
   * When a user clicks on "All Types" in product type section
   * every product type option is set to unchecked and "All types"
   * is checked
   */

  function onOrderStatusItemClick(index) {
    if (index === 0) {
      onAllStatusesClicked();

      return;
    }

    const temp = [...orderStatusOptions];
    temp[index].selected = !temp[index].selected;
    temp[0].selected = false;
    setAllStatusesIfNoneSelected(temp);
    setOrderStatusOptions(temp);
    setIsChanged(true);
  }

  /**
   * If there are no checked order statuses
   * check the first one: "All statuses"
   * @param {*} tempOrderStatusOptions
   */
  function setAllStatusesIfNoneSelected(tempOrderStatusOptions) {
    const index = tempOrderStatusOptions.findIndex((item) => item.selected);
    if (index < 0) tempOrderStatusOptions[0].selected = true;
  }

  /**
   * When a user clicks on "All statuses" in order status section
   * every order status option is set to unchecked and "All statuses"
   * is checked
   */
  function onAllStatusesClicked() {
    const tempOrderStatuses = [...orderStatusOptions];

    for (const item of tempOrderStatuses) {
      item.selected = false;
    }

    tempOrderStatuses[0].selected = true;
    setOrderStatusOptions(tempOrderStatuses);

    setIsChanged(true);
  }

  function onSortByItemClicked(index) {
    const temp = sortOptions.map((item) => ({ ...item, selected: false }));

    temp[index].selected = true;

    setSortOptions(temp);
    setIsChanged(true);
  }

  function resetFilters() {
    // if there were some filters selected
    // setIsChanged to true, so that the user can apply them
    if (numberOfSelectedFilters > 0 || autoComplete.value) {
      setIsChanged(true);
    }

    const tempSortOptions = [...sortOptions];

    for (const item of tempSortOptions)
      if (item.selected) item.selected = false;

    tempSortOptions[0].selected = true;
    setSortOptions(tempSortOptions);

    const tempOrderStatusOptions = [...orderStatusOptions];

    for (const item of tempOrderStatusOptions)
      if (item.selected === true) item.selected = false;

    tempOrderStatusOptions[0].selected = true;

    setOrderStatusOptions(tempOrderStatusOptions)

    setSelectedPatientId(null);
    autoComplete.setValue('');
  }

  function applyClicked() {
    const selectedSort = sortOptions.find((item) => item.selected);

    let selectedStatusFilters = orderStatusOptions.filter((item) => item.selected);

    let selectedFilters = [...selectedStatusFilters];
    selectedFilters = selectedFilters.map((item) => item.enum);

    dispatch(setSort(selectedSort.enum));
    dispatch(setFilters(selectedFilters));
    dispatch(setPatientIdFilter(selectedPatientId));
    dispatch(setResetPagination());



    setIsChanged(false);
    setShowPopup(false);
  }


  function cancelClicked() {
    restoreAppliedState();
    setShowPopup(false);
  }

  /**
   * Restore prev applied filters and sort
   */
  function restoreAppliedState() {
    const sortTemp = sortOptions.map((item) => {
      if (selectedSortFromStore === item.enum) return { ...item, selected: true };

      return { ...item, selected: false };
    });

    setSortOptions(sortTemp);
    restoreStatusFilters()
  }

  return (
    <div className="filter">
      <div className="filter__content">
        <div className="filter__text" onClick={() => setShowPopup(true)}>
          <FilterIcon />
          <p className="p5">Filters ({numberOfSelectedFilters})</p>
        </div>

        <div>
          <div onClick={() => applyClicked()} className={`filter__overlay ${showPopup ? "show" : ""}`}></div>
          <div className={`filter_card ${showPopup ? "show" : ""}`}>
            <div className="filter_card__content">
              <div className="filter_card__head">
                <p className="label--caps">
                  Filters ({numberOfSelectedFilters})
                </p>
                {(numberOfSelectedFilters > 0 ||
                  selectedPatientId) && (
                    <p className="label_link" onClick={() => resetFilters()}>
                      Clear
                    </p>
                  )}
              </div>
              <div className="filter_card__body">
                <div>

                  <div className="filter_card__filtering">
                    <div className="filter_card__filter_category">
                      <div
                        className="filter_card__sort_by__title"
                        onClick={() => setShowStatusMenu(!showStatusMenu)}
                      >
                        <p className="p5--semi-bold">Show</p>
                        {showStatusMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </div>
                      {showStatusMenu && (
                        <ul className="p5">
                          {orderStatusOptions.map((item, index) => (
                            <li
                              className="filter_card__list_item"
                              onClick={() => onOrderStatusItemClick(index)}
                              key={index}
                            >
                              {item.selected ? (
                                <p className="filter_card__list_item_black">
                                  {item.text}
                                </p>
                              ) : (
                                <p className="p5">{item.text}</p>
                              )}
                              {item.selected && <TickIcon />}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="filter_card__sort_by">
                    <div
                      className="filter_card__sort_by__title"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      <p className="p5--semi-bold">Sort by</p>
                      {showMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </div>
                    {showMenu && (
                      <ul className="p5">
                        {sortOptions.map((item, index) => (
                          <li
                            className="filter_card__list_item"
                            onClick={() => onSortByItemClicked(index)}
                            key={index}
                          >
                            {item.selected ? (
                              <p className="filter_card__list_item_black">
                                {item.text}
                              </p>
                            ) : (
                              <p className="p5">{item.text}</p>
                            )}
                            {item.selected && <TickIcon />}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter_card__buttons">
                <div>
                  <PrimaryCta
                    text="Apply"
                    color={`peach`}
                    isEnabled={isChanged}
                    onClick={applyClicked}
                  ></PrimaryCta>
                </div>
                <div>
                  <PrimaryCta
                    text="Cancel"
                    color="white"
                    onClick={cancelClicked}
                  ></PrimaryCta>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;

const SortOptions = [
  {
    text: "Newest to Oldest",
    selected: true,
    enum: Sorts.NewestToOldest,
  },
  {
    text: "Oldest to Newest",
    selected: false,
    enum: Sorts.OldestToNewest,
  }
];

Object.freeze(SortOptions);

const FilterOptions = [
  {
    text: "All types",
    selected: true,
    enum: Filters.AllTypes,
  },
  {
    text: "Oil",
    selected: false,
    enum: Filters.Oil,
  },
  {
    text: "Flower",
    selected: false,
    enum: Filters.Flower,
  },
  {
    text: "Cartridge",
    selected: false,
    enum: Filters.Cartridge,
  },
];

Object.freeze(FilterOptions);

const FilterStatusOptions = [
  {
    text: "All statuses",
    selected: true,
    enum: Filters.AllStatuses,
  },
  {
    text: "Avaiting paper prescription",
    selected: false,
    enum: Filters.AwaitingPaperPrescription,
  },
  {
    text: "Payment pending",
    selected: false,
    enum: Filters.Pending,
  },
  {
    text: "Paper prescription received",
    selected: false,
    enum: Filters.PaperPrescriptionReceived,
  },
  {
    text: "Dispatched",
    selected: false,
    enum: Filters.Dispatched,
  },
  {
    text: "Prescription expired",
    selected: false,
    enum: Filters.Expired,
  },
];

Object.freeze(FilterStatusOptions);