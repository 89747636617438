import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrderStatuses, Sorts } from "../../../enums";


import { Order, ProcessingFutureDatedPrescription, ProcessingPrescription } from "../../../components/account";
import NoActivePrescriptions from "../NoActivePrescriptions/NoActivePrescriptions";
import AccountLayout from "../../../layouts/AccountLayout/AccountLayout";

import { selectUser } from "../../../store/slices/authenticationSlice";
import { useFilterPrescriptions } from "../../../hooks";
import {
  getActivePrescriptions,
  selectActivePrescriptions,
  selectLoadingActivePrescriptions,
  setSort,
} from "../../../store/slices/prescriptionsSlice";
import Spinner2 from "../../../components/sharedComponents/Spinner/Spinner2";
import moment from "moment/moment";
import { PostDatedBanner } from "../../../components/banners";


function ActivePrescriptions(props) {
  const dispatch = useDispatch();

  const activePrescriptions = useSelector(selectActivePrescriptions);
  const loading = useSelector(selectLoadingActivePrescriptions);
  const user = useSelector(selectUser);
  const filteredPrescriptions = useFilterPrescriptions(activePrescriptions);

  let renderedBanner = false;
  let numberOfFutureDated = filteredPrescriptions.length ? filteredPrescriptions.reduce((a, curr) => {
    if (isPrescriptionFutureDated(curr))
      return a + 1
    return a;
  }, 0) : 0;

  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    dispatch(getActivePrescriptions());
    dispatch(setSort(Sorts.NewestByPrescribedDate))
    //eslint-disable-next-line
  }, [user]);

  function isPrescriptionActive(prescription) {
    return (prescription.status !== OrderStatuses.NotApproved && prescription.status !== OrderStatuses.OutOfStock && prescription.status !== OrderStatuses.OutOfStockAndPaper && prescription.orderId)
  }

  function isPrescriptionFutureDated(prescription) {
    return (!(moment(Date.now()) > moment(prescription.prescribedDateTime, 'YYYY-MM-DD')))
  }

  return (
    <AccountLayout showTracker>
      {props.children}

      {loading ?
        <div className="orders_loading--spinner">
          <Spinner2 color={"#00a372"} width={"60"} />
        </div>
        :
        <>
          <div className="order_page">
            {filteredPrescriptions.length === 0 && !loading && (
              <NoActivePrescriptions />
            )}
            {filteredPrescriptions.map((order) =>
              (isPrescriptionActive(order)) ? (
                <Order
                  key={`p${order.prescriptionId}o${order.orderId}`}
                  order={order}
                  allOrders={filteredPrescriptions}
                />
              ) : (
                (isPrescriptionFutureDated(order)) ?
                  <>

                    {!renderedBanner && (
                      <PostDatedBanner text={numberOfFutureDated}>
                      </PostDatedBanner>
                    )}
                    {renderedBanner = true}
                    <ProcessingFutureDatedPrescription
                      key={`processing${order.index}`}
                      order={order}
                      allOrders={filteredPrescriptions}
                    />
                  </>
                  : (
                    <ProcessingPrescription
                      key={`processing${order.index}`}
                      order={order}
                      allOrders={filteredPrescriptions}
                    />
                  )
              )
            )}
          </div>
        </>
      }
    </AccountLayout>
  );
}

export default ActivePrescriptions;
