import React, { useState } from "react";
import { resolveAppendableClasses } from "../../../util/util";
import { ArrowDownIcon, ArrowUpIcon } from "../../../assets/icons";

function Harmonika({ children, className, appendableClassNames, title = "Title for harmonika" }) {
  const [show, setShow] = useState(false);

  const renderArrowButton = () => {
    return <div style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
      {show ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </div>
  }
  return (
    <div className={`harmonika ${className} ${resolveAppendableClasses("harmonika", appendableClassNames)}`}>
      <div className={"harmonika__header p-b-s"} >
        <div className={"harmonika__header__title"} onClick={() => setShow(!show)} >
          {title}
        </div>
        {renderArrowButton()}
      </div>
      <div className={`harmonika__children ${show ? "harmonika__children--show" : ""}`} >
        {children}
      </div>

    </div>
  );
}

export default Harmonika;