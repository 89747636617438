import React from 'react'
import { OrderStatuses } from '../../enums'

const DoctorPatientsTableStatusTag = ({ data, order }) => {
    const resolveClasses = (order) => {
        if ([OrderStatuses.Void,
        OrderStatuses.CompletedAndVoid].includes(order.status)) {
            return 'doctor_patients_table_status_tag--expired'
        }

        if ([OrderStatuses.OutOfStock,
        OrderStatuses.NotPaidAndNoPaper,
        OrderStatuses.PaidAndNoPaper,
        OrderStatuses.NoPaperAndPaymentMissed,
        OrderStatuses.NotApproved
        ].includes(order.status)) {
            return 'doctor_patients_table_status_tag--awaiting_paper'
        }

        if ([OrderStatuses.OutOfStockAndPaper,
        OrderStatuses.NotPaidAndPaper,
        OrderStatuses.PaidAndPaper,
        OrderStatuses.PaperAndPaymentMissed,].includes(order.status)) {
            return 'doctor_patients_table_status_tag--paper_received'
        }

        return ''

    }
    return (
        <span className={`doctor_patients_table_status_tag ${resolveClasses(order)}`}>
            {data.text}
        </span>
    )
}

export default DoctorPatientsTableStatusTag