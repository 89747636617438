import React, { useState } from "react";
import TooltipContainer from "./TooltipContainer";
import Portal from "../portals/Portal";

function UniversalTooltip({ hoverableContent = "hoverable", contentForTheTooltip = "Tooltip", left = 0, top = 0 }) {
    const [coords, setCoords] = useState({}); // takes current button coordinates
    const [isOn, setOn] = useState(false); // toggles button visibility

    const handleOnMouseEnter = (e) => {
        const rect = e.target.getBoundingClientRect();

        const leftCoord = rect.x + rect.width / 2 + left;
        const windowWidth = window.innerWidth;
        const tooltipWidth = 100;
        const overflow = leftCoord + tooltipWidth - windowWidth;
        const invertedLeft = overflow > 0 ? leftCoord - tooltipWidth - overflow : leftCoord;
        const finalLeft = invertedLeft < 0 ? 0 : invertedLeft;
        setCoords({
            left: finalLeft,
            top: rect.y + window.scrollY + rect.height + top // Adjust to place below
        });
        setOn(true); // [ 3 ]
    }

    return (
        <div style={{ position: 'relative', display: 'flex' }}>
            <div style={{ paddingLeft: '6px' }}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={() => { setOn(false) }}
            >
                {hoverableContent}
            </div>
            {
                isOn &&
                <Portal>
                    <TooltipContainer coords={coords}>
                        {contentForTheTooltip}
                    </TooltipContainer>
                </Portal>
            }
        </div>
    );
}

export default UniversalTooltip;