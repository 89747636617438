import React from "react";

import { CloseIcon } from "../../../assets/icons";

function InformationCard({
  cardTitle = "",
  cardText = "",
  onClick = () => null,
  children = [],
}) {
  return (
    <div className="information_card">
      <div className="information_card__close" onClick={onClick}>
        <CloseIcon />
      </div>
      <div className="information_card__content">
        <div className="information_card__title">
          <h1 className="h8-a">{cardTitle}</h1>
          <div className="p5">{cardText}</div>
        </div>

        {children.length !== 0 &&
          <div className="information_card__buttons">{children}</div>
        }
      </div>
    </div>
  );
}

export default InformationCard;
