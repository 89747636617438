import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import SuccessfulPayment from '../../../components/account/SuccessfulPayment/SuccessfulPayment'
import OrderPaymentForm from '../../../components/forms/OrderPaymentForm/OrderPaymentForm'
import { GetOrderToBePaidCallTypes } from '../../../enums'
import {
    getOrderToBePaid,
    selectLoadingOrderToBePaid,
    selectOrderToBePaid,
} from "../../../store/slices/prescriptionsSlice";
import LoadingOrderToBePaidForm from "../../../components/forms/OrderPaymentForm/LoadingOrderToBePaidForm";

const OrderPayment = () => {
    const history = useHistory();
    const { orderNumber } = useParams();
    const order = useSelector(selectOrderToBePaid);
    const dispatch = useDispatch();
    const location = useLocation();
    const loadingOrderTobePaid = useSelector(selectLoadingOrderToBePaid);

    const areWeOnProtectedPaymentPage = location.pathname.includes('/order/onboardedpayment/');
    const callType = areWeOnProtectedPaymentPage ? GetOrderToBePaidCallTypes.GetProtectedOrderTobePaid : GetOrderToBePaidCallTypes.GetUnprotectedOrderTobePaid;

    const dispatchGetOrerToBePaid = async () => {
        const { error } = await dispatch(getOrderToBePaid({ orderNumber, callType }));
        if (error) {
            history.push('/prescriptions/active');
        }
    }

    //Create the Zendesk support widget
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        localStorage.removeItem("orderBeingPaidForId");
        dispatchGetOrerToBePaid()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (order && order.status === 3) {
            history.push('/ordercancelled');
        }
        // eslint-disable-next-line
    }, [order])

    return (
        <section className='order_payment'>
            {
                loadingOrderTobePaid && <LoadingOrderToBePaidForm />
            }
            {
                order && (
                    <>
                        {order.isPaymentReceived ?
                            <SuccessfulPayment />
                            :
                            <div className='m-t-s m-b-s'>
                                <OrderPaymentForm />
                            </div>
                        }
                    </>

                )
            }

        </section>
    )
}

export default OrderPayment
