import React from "react";

function LoadingCard({ cardTitle = "", cardText = "", Icon = null }) {
  return (
    <div className="loading_card">
      <div className="loading_card__content">
        <div className="loading_card__image">{Icon}</div>
        <div className="loading_card__title">
          <h1 className="h6-a--serif">{cardTitle}</h1>
          <p className="p5">{cardText}</p>
        </div>
      </div>
    </div>
  );
}

export default LoadingCard;
