import React, { useEffect, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import pharmacyLogo from '../../assets/images/download.png'
import axios from "axios";
function PrescriptionPreview({
  src = pharmacyLogo,
  buttons = () => <></>,
  buttonsTop = () => <></>,
}) {
  const [isPDF, setisPDF] = useState(false);

  const isDataFromLocalMachine = () => {
    return !src.includes("amazonaws")
  }

  async function isFileBlobPdf(src) {
    const config = { responseType: "blob" };
    const { data: prescriptionBlob } = await axios.get(src, config);
    if (prescriptionBlob.type === 'application/pdf') {
      setisPDF(true);
    } else {
      setisPDF(false);
    }
  }

  useEffect(() => {
    if (Array.isArray(src) == false) {
      if (src && isDataFromLocalMachine()) {
        isFileBlobPdf(src)
      }
      else {
        if (src.substr(src.lastIndexOf('.')).startsWith('.pdf')) {
          setisPDF(true)
        } else {
          setisPDF(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [src])

  return (
    <div className={"prescription_preview_container"} style={{ width: "100%" }} >
      <div className={"prescription_preview_container__buttons"} >
        {buttons()}
      </div>
      <div className={"prescription_preview_container__buttons prescription_preview_container__buttons--top"} >
        {buttonsTop()}
      </div>
      {isPDF ?
        <div className={"prescription_preview_container"}>
          <iframe key={src} title='view prescription sidebar' className="sidebar_iframe" src={src} height="300px" width="100%"
            frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
        </div>
        :
        Array.isArray(src) ?
          <Lightbox key={src} images={src} />
          :
          <Lightbox key={src} image={src} />
      }
    </div>
  );
}

export default PrescriptionPreview;