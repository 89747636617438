import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../ApiClient";
import { accountService } from "../../services/AccountServices";

export const getPatientDetails = createAsyncThunk(
  "account/getPatientDetails",

  async () => {
    const response = await accountService.getPatientDetails();

    return response;
  }
);

export const getCarerDetails = createAsyncThunk(
  "account/getCarerDetails",

  async () => {
    const response = await accountService.getCarerDetails();

    return response;
  }
);

export const getOrganisationEmployeeDetails = createAsyncThunk(
  "account/getClinicUserDetails",

  async () => {
    const response = await accountService.getClinicUserDetails();

    return response;
  }
);

export const updatePatientDetails = createAsyncThunk(
  "account/updatePatientDetails",

  async ({ patientDetails }) => {
    const updatedPatientDetails = await accountService.updatePatientDetails(
      patientDetails
    );

    return updatedPatientDetails;
  }
);

export const updateCarerDetails = createAsyncThunk(
  "account/updateCarerDetails",

  async ({ carerDetails }) => {
    const updatedCarerDetails = await accountService.updateCarerDetails(
      carerDetails
    );

    return updatedCarerDetails;
  }
);

export const updateDoctorDetails = createAsyncThunk(
  "account/updateDoctorDetails",

  async ({ doctorDetails }) => {
    const updatedDoctor = await accountService.updateDoctorDetails(
      doctorDetails
    );

    return updatedDoctor;
  }
);

export const requestPasswordReset = createAsyncThunk(
  "account/requestPasswordReset",

  async ({ email }) => {
    const data = await accountService.requestPasswordReset(email);

    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "account/resetPassword",

  async (payload) => {
    const data = await accountService.resetPassword(payload);

    return data;
  }
);

export const changePassword = createAsyncThunk(
  "account/changePassword",

  async (payload) => {
    const response = await accountService.changePassword(payload);

    return response;
  }
);

export const verifyEmail = createAsyncThunk(
  "account/verifyEmail",

  async (payload) => {
    const response = await accountService.verifyEmail(payload);

    return response;
  }
);

export const getDoctorsPatients = createAsyncThunk(
  "account/getDoctorsPatients",

  async (doctorId) => {
    const patients = await accountService.getDoctorsPatients(doctorId);

    return patients;
  }
);

export const refer = createAsyncThunk(
  "account/refer",

  async (payload) => {
    const response = await accountService.refer(payload);

    return response;
  }
);

export const doctorAccountRegisterPatient = createAsyncThunk(
  "account/doctorAccountRegisterPatient",

  async (patient, { getState }) => {
    const state = getState();
    const doctorId = state.account.userDetails.id;

    const createdPatient = await accountService.doctorAccountCreatePatient(
      doctorId,
      patient
    );

    return createdPatient;
  }
);

export const doctorAccountUploadPrescription = createAsyncThunk(
  "account/doctorAccountUploadPrescription",

  async ({ patientId, prescription }) => {
    const response = await accountService.uploadPrescription(
      patientId,
      prescription
    );

    return response;
  }
);

export const acceptCookies = createAsyncThunk(
  "account/acceptCookies",

  async (id) => {
    const response = await apiClient.acceptCookies(id)

    return response;
  }
);

const initialState = {
  isEmailVerified: undefined,
  isIDVerified: undefined,
  lastLoggedIn: "",

  loading: undefined,
  uploadingPrescription: undefined,
  updatingPatientDetails: false,
  updatingDoctorDetails: false,
  updatingCarerDetails: false,

  patientsLastRequest: {},

  userDetails: undefined,
  currentOrganisation: {
    organisationId: 0,
    employeeType: -1
  },
  doctorsPatients: [],
};

const accountSlice = createSlice({
  name: "account",
  initialState,

  reducers: {
    // reducers for sync actions
    resetAccountData(state) {
      state.isIDVerified = undefined;
      state.isEmailVerified = undefined;
      state.lastLoggedIn = "";

      state.loading = undefined;
      state.uploadingPrescription = undefined;

      state.userDetails = undefined;
      state.doctorsPatients = [];
    },

    setIsIdVerified(state, { payload }) {
      state.isIDVerified = payload;
    },

    setIsEmailVerified(state, { payload }) {
      state.isEmailVerified = payload;
    },

    setUserDetails(state, { payload }) {
      state.userDetails = payload;
    },

    setCurrentOrganisation(state, { payload }) {
      if (state.userDetails.organisations.some(e => e.organisationId === payload.organisationId)) {
        state.currentOrganisation = payload;
        return;
      }

      state.currentOrganisation = {
        organisationId: 0,
        employeeType: -1,
        organisationName: null
      };
    },

    resetCurrentOrganisation(state) {
      state.currentOrganisation = {
        organisationId: 0,
        employeeType: -1,
        organisationName: null
      };
    },
  },

  // reducers for async actions
  extraReducers: {
    // getPatientDetails
    [getPatientDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.userDetails;
      state.lastLoggedIn = payload.lastLoggedIn;
      state.isEmailVerified = payload.isEmailVerified;
      state.isIDVerified = payload.isIDVerified;
    },

    [getPatientDetails.rejected]: (state) => {
      state.userDetails = null;
    },

    // getCarerDetails
    [getCarerDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.userDetails;
      state.lastLoggedIn = payload.lastLoggedIn;
      state.isEmailVerified = payload.isEmailVerified;
      state.isIDVerified = payload.isIDVerified;
    },

    [getCarerDetails.rejected]: (state) => {
      state.userDetails = null;
    },

    // getClinicUserDetails
    [getOrganisationEmployeeDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.userDetails;
      state.lastLoggedIn = payload.lastLoggedIn;
      state.isEmailVerified = payload.isEmailVerified;
      state.isIDVerified = payload.isIDVerified;
      if (payload.userDetails.organisations.length === 0)
        state.currentOrganisation = {
          organisationId: null,
          employeeType: null,
          organisationName: null
        };
      else
        state.currentOrganisation = state.currentOrganisation.organisationId === 0
          ? payload.userDetails.organisations[0]
          : state.currentOrganisation
    },

    [getOrganisationEmployeeDetails.rejected]: (state) => {
      state.userDetails = null;
    },

    // updateCarerDetails
    [updateCarerDetails.pending]: (state) => {
      state.updatingCarerDetails = true;
    },
    [updateCarerDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload;
      state.updatingCarerDetails = false;
    },
    [updateCarerDetails.fulfilled]: (state) => {
      state.updatingCarerDetails = false;
    },

    // updatePatientDetails
    [updatePatientDetails.pending]: (state) => {
      state.updatingPatientDetails = true;
    },
    [updatePatientDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload;
      state.updatingPatientDetails = false;
    },
    [updatePatientDetails.rejected]: (state) => {
      state.updatingPatientDetails = false;
    },

    // updateDoctorDetails
    [updateDoctorDetails.pending]: (state) => {
      state.updatingDoctorDetails = true;
    },
    [updateDoctorDetails.fulfilled]: (state, { payload }) => {
      state.updatingDoctorDetails = false;
      state.userDetails = payload;
    },
    [updateDoctorDetails.rejected]: (state) => {
      state.updatingDoctorDetails = false;
    },

    // requestPasswordReset
    [requestPasswordReset.pending]: (state) => {
      state.loading = true;
    },

    [requestPasswordReset.fulfilled]: (state) => {
      state.loading = false;
    },

    [requestPasswordReset.rejected]: (state) => {
      state.loading = false;
    },

    // changePassword
    [changePassword.pending]: (state) => {
      state.loading = true;
    },

    [changePassword.fulfilled]: (state) => {
      state.loading = false;
    },

    [changePassword.rejected]: (state) => {
      state.loading = false;
    },

    // verifyEmail
    [verifyEmail.pending]: (state) => {
      state.loading = true;
    },

    [verifyEmail.fulfilled]: (state) => {
      state.loading = false;
    },

    [verifyEmail.rejected]: (state) => {
      state.loading = false;
    },

    // refer
    [refer.pending]: (state) => {
      state.loading = true;
    },

    [refer.fulfilled]: (state) => {
      state.loading = false;
    },

    [refer.rejected]: (state) => {
      state.loading = false;
    },

    // getDoctorsPatients
    [getDoctorsPatients.fulfilled]: (state, { payload }) => {
      state.doctorsPatients = payload;
    },

    // doctorAccountRegisterPatient
    [doctorAccountRegisterPatient.pending]: (state) => {
      state.registeringPatient = true;
    },

    [doctorAccountRegisterPatient.fulfilled]: (state) => {
      state.registeringPatient = false;
    },

    [doctorAccountRegisterPatient.rejected]: (state) => {
      state.registeringPatient = false;
    },

    // doctorAccountUploadPrescription
    [doctorAccountUploadPrescription.pending]: (state) => {
      state.uploadingPrescription = true;
    },

    [doctorAccountUploadPrescription.fulfilled]: (state) => {
      state.uploadingPrescription = false;
    },

    [doctorAccountUploadPrescription.rejected]: (state) => {
      state.uploadingPrescription = false;
    },

    // accept cookies
    [acceptCookies.fulfilled]: (state, { payload }) => {
      state.userDetails = { ...state.userDetails, acceptedCookies: payload.success };
    },
    [acceptCookies.rejected]: (state) => {
      state.userDetails = { ...state.userDetails, acceptedCookies: false };
    },
  },
});

export const {
  resetAccountData,
  setIsIdVerified,
  setUserDetails,
  setIsEmailVerified,
  setCurrentOrganisation,
  resetCurrentOrganisation
} = accountSlice.actions;

export const selectUserDetails = (state) => state.account.userDetails;

export const selectCurrentOrganisation = (state) => state.account.currentOrganisation;

export const selectAccountLoading = (state) => state.account.loading;

export const selectLastLoggedIn = (state) => state.account.lastLoggedIn;

export const selectIsVerified = (state) => state.account.isEmailVerified;

export const selectIsIdVerified = (state) => state.account.isIDVerified;

export const selectPatientsLastRequest = (state) => state.account.patientsLastRequest;

export const selectDoctorsPatients = (state) => state.account.doctorsPatients;

export const selectRegisteringPatient = (state) =>
  state.account.registeringPatient;

export const selectUpdatingPatientDetails = (state) =>
  state.account.updatingPatientDetails;

export const selectUpdatingDoctorDetails = (state) =>
  state.account.updatingDoctorDetails;

export const selectUpdatingCarerDetails = (state) =>
  state.account.updatingCarerDetails;

export default accountSlice.reducer;
