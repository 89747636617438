import React from 'react'
import { FullScreenOverlay } from '../../overlays'

const GenericPopup = ({ show, onClose = () => null, text, icon, title, buttons }) => {

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    return (
        <FullScreenOverlay onClick={() => onClose()} show={show}>
            <div className='generic_popup' >
                <div onClick={(e) => stopPropagation(e)} className="verification_card">
                    {icon && <div className='generic_popup__icon'>
                        {icon}
                    </div>
                    }

                    {title &&
                        <div className='generic_popup__title'>
                            {title}
                        </div>
                    }
                    {text &&
                        <div className="generic_popup__text">
                            {text}
                        </div>
                    }

                    {buttons &&
                        <div className="generic_popup__buttons">
                            {buttons}
                        </div>
                    }
                </div>
            </div>
        </FullScreenOverlay>
    )
}

export default GenericPopup
