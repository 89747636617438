import React from "react";

function AttentionIconToast() {

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8365 32 32 24.8365 32 16C32 7.16344 24.8365 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8365 7.16344 32 16 32ZM16.828 18.7467L17.0706 9.69333H14.812L15.0546 18.7467H16.828ZM15.036 22.4053C15.2973 22.6667 15.6209 22.7973 16.0066 22.7973C16.38 22.7973 16.6973 22.6667 16.9586 22.4053C17.22 22.144 17.3506 21.8204 17.3506 21.4347C17.3506 21.0489 17.22 20.7253 16.9586 20.464C16.6973 20.2027 16.38 20.072 16.0066 20.072C15.6209 20.072 15.2973 20.2027 15.036 20.464C14.7746 20.7253 14.644 21.0489 14.644 21.4347C14.644 21.8204 14.7746 22.144 15.036 22.4053Z" fill="white" />
    </svg>
  );
}

export default AttentionIconToast;
