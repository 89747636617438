import React from 'react'
import { InputField } from '../../inputs'

const CreditCardForm = ({ cardNumber, expiryDate, cvc }) => {
    return (
        <div className='credit_card_form'>
            <div className='credit_card_form__number'>
                <h5>
                    Card number
                </h5>
                <InputField name='Long digit number' {...cardNumber} />
            </div>
            <div className='credit_card_form__date'>
                <h5>
                    Expiry date
                </h5>
                <InputField name='MM/YY' {...expiryDate} />
            </div>
            <div className='credit_card_form__cvc'>
                <h5>
                    CVC
                </h5>
                <InputField name='***' {...cvc} />
            </div>
        </div>
    )
}

export default CreditCardForm
