import { createFormData, getImageFromUrl } from "../util/util";
import { apiClient } from "../ApiClient";
import { usersConverter } from "../converters/UsersConverter";

class AccountService {
  // account
  async getPatientDetails() {
    const patientDetails = await apiClient.getPatientDetails();

    return usersConverter.patientApiToClient(patientDetails);
  }

  async getCarerDetails() {
    const carerDetails = await apiClient.getCarerDetails();

    return usersConverter.carerApiToClient(carerDetails);
  }

  async getDoctorDetails() {
    const doctorDetails = await apiClient.getDoctorDetails();

    return usersConverter.doctorApiToClient(doctorDetails);
  }

  async getClinicUserDetails() {
    const doctorDetails = await apiClient.getClinicUserDetails();

    return usersConverter.clinicUserApiToClient(doctorDetails);
  }

  async updatePatientDetails(patientDetails) {
    patientDetails = usersConverter.patientClientToApi(patientDetails);
    const patientFormData = createFormData(patientDetails);

    const updatedPatient = await apiClient.updatePatientDetails(
      patientFormData
    );

    return usersConverter.updatedPatientApiToClient(updatedPatient);
  }

  async updateCarerDetails(carerDetails) {
    carerDetails = usersConverter.carerClientToApi(carerDetails);
    const carerFormData = createFormData(carerDetails);

    const updatedCarer = await apiClient.updateCarerDetails(carerFormData);

    return usersConverter.updatedCarerApiToClient(updatedCarer);
  }

  async updateDoctorDetails(doctorDetails) {
    doctorDetails = usersConverter.doctorClientToApi(doctorDetails);
    const doctorFormData = createFormData(doctorDetails);

    const updatedDoctor = await apiClient.updateDoctorDetails(doctorFormData);

    return usersConverter.updatedDoctorApiToClient(updatedDoctor);
  }

  async uploadPrescription(
    patientId,
    { url, name, confirmedConsent },
    doctorId = null,
    isT21Patient = null
  ) {
    const prescription = await getImageFromUrl(url, name);

    const formData = createFormData({
      patientId,
      prescription,
      confirmedConsent,
      doctorId,
      isT21Patient,
    });

    const response = await apiClient.uploadPrescription(formData);

    return response;
  }

  async requestPasswordReset(email) {
    const formData = createFormData({ email });

    return apiClient.requestPasswordReset(formData);
  }

  async resetPassword(payload) {
    const formData = createFormData(payload);

    return apiClient.resetPassword(formData);
  }

  async changePassword(payload) {
    const formData = createFormData(payload);

    return apiClient.changePassword(formData);
  }

  async verifyEmail(secretId) {
    const formData = createFormData({ secretId });

    return apiClient.verifyEmail(formData);
  }

  async getDoctorsPatients(doctorId) {
    const patients = await apiClient.getDoctorsPatients(doctorId);

    return usersConverter.patientsForDoctorSelectApiToClient(patients);
  }

  async refer(payload) {
    const formData = createFormData(payload);

    const response = await apiClient.refer(formData);

    return response;
  }

  async doctorAccountCreatePatient(doctorId, patient) {
    const patientPayload = usersConverter.doctorsPatientClientToApi({
      doctorId,
      ...patient,
    });

    const patientFormData = createFormData(patientPayload);

    const createdPatient = await apiClient.createPatient(patientFormData);

    return createdPatient;
  }

  async getDoctorDataForCustomer(patientId) {
    const doctorInfo = await apiClient.getDoctorDataForCustomer(patientId);

    return doctorInfo;
  }
}

export const accountService = new AccountService();
