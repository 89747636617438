import React from "react";

import FooterNavigation from "../FooterNavigation/FooterNavigation";
import FooterSocialMedia from "../FooterSocialMedia/FooterSocialMedia";

import gmpc from "../../../../assets/images/gphc_logo_1440.png";
import lyphe from "../../../../assets/images/lyphe_group_black_logo.png";
import registered_pharmacy from "../../../../assets/images/registered_pharmacy.png";
import { getCurrentYear } from "../../../../util/util";

function AccountFooterDesktop(props) {
  return (
    <footer className="account_footer">
      <div className="account_footer__row">
        <div className="account_footer__row__col">
          <FooterNavigation />
        </div>
        <div className="account_footer__row__col--right">
          <div>
            <img src={lyphe} alt="lyphe" />
          </div>
        </div>
      </div>

      <div className="account_footer__row border-bottom">
        <div className="account_footer__row__col">
          <p className="label--smaller">Copyright © {getCurrentYear()} Lyphe</p>
        </div>
        <div className="account_footer__row__col--right">
          <FooterSocialMedia />
        </div>
      </div>

      <div className="account_footer__row" style={{ paddingTop: "20px", justifyContent: "space-between" }}>
        <a href="https://www.pharmacyregulation.org/registers/pharmacy/9012425"
          target="_blank"
          rel="noopener noreferrer"
          className="account_footer__row__col footer_gmpc m-r-ss"
          style={{ textDecoration: "none" }}>
          <img src={gmpc} alt="gmpc" />
          <p style={{ marginTop: "20px" }} className="label--smaller">We are regulated by GPhC</p>
          <img style={{ marginTop: "10px" }} src={registered_pharmacy} alt="registered pharmacy logo" />
        </a >

        <div className="account_footer__row__col_bottom-right">
          <div className="account_footer__row__col m-r-10">
            <p style={{ fontSize: "13px" }} className="label--semi-bold">Superintendent pharmacist:</p>
            <p className="label--smaller">
              Mehraan Sattar
            </p>
            <a href="https://www.pharmacyregulation.org/registers/pharmacist/name/sattar/mehraan" className="label--smaller">
              GPHC Number: 2211738
            </a>
          </div>

          <div className="account_footer__row__col">
            <p style={{ fontSize: "13px" }} className="label--semi-bold">Registered address:</p>
            <p className="label--smaller">
              Lyphe Dispensary
            </p>
            <p className="label--smaller">
              Unit 12 Farnborough Business Centre, Eelmoor Road, Farnborough, Hampshire
            </p>
            <p className="label--smaller">
              GU14 7XA
            </p>
          </div>
        </div>
      </div>

    </footer>
  )
};

export default AccountFooterDesktop;