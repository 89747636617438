import React from 'react'

const GreenCircle2SFPro = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#00a372" />
            <path d="M10.0098 12.1172H12.373C12.373 10.6816 13.5254 9.64648 15.0488 9.64648C16.4453 9.64648 17.373 10.584 17.373 11.8145C17.373 12.8691 16.9629 13.5723 15.127 15.3789L10.1465 20.2812V22H20.0488V19.9395H13.4766V19.8906L16.582 16.8438C19.043 14.4219 19.834 13.3281 19.834 11.6582C19.834 9.39258 17.9199 7.625 15.0488 7.625C12.1191 7.625 10.0098 9.49023 10.0098 12.1172Z" fill="#F6F6F6" />
        </svg>

    )
}

export default GreenCircle2SFPro
