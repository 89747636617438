import React from "react";

import { InformationCard } from "../../cards";
import { FullScreenOverlay } from "../../overlays";

import { useDispatch, useSelector } from "react-redux";
import { selectStartLiveChatPopup, setStartLiveChatPopup } from "../../../store/slices/modalsSlice";

function StartLiveChatPopup(props) {
  const dispatch = useDispatch();
  const startLiveChatPopup = useSelector(selectStartLiveChatPopup);
  const handleClose = () => {
    dispatch(setStartLiveChatPopup({ show: false }))
  }
  return (
    <div className="start_live_chat">
      <div className="start_live_chat__content">
        <FullScreenOverlay show={startLiveChatPopup.show}>
          <InformationCard
            cardTitle="Any issues? Let us know?"
            cardText={<p className="p5">Email us at <a className="text_link" href="mailto:info@lyphe.com">info@lyphe.com</a> and describe your issue. </p>}
            onClick={() => handleClose()}
          >
          </InformationCard>
        </FullScreenOverlay>
      </div>
    </div>
  );
}

export default StartLiveChatPopup;
