import React from "react";
import { useDispatch } from "react-redux";

import { PrimaryCta } from "..";

import { ResubmitResponse } from "../../../enums";
import { setResubmitModal } from "../../../store/slices/modalsSlice";
import {
  getActivePrescriptions,
  resubmitOrder,
} from "../../../store/slices/prescriptionsSlice";

function ResubmitButton({ order }) {
  const dispatch = useDispatch();

  async function onClick() {
    const { payload, error } = await dispatch(resubmitOrder(order.orderId));
    if (error) return;

    const { text, title } = ResubmitResponseToTextMapper[payload.result];

    dispatch(setResubmitModal({ show: true, text, title }));

    dispatch(getActivePrescriptions());
  }

  return (
    <div className="h-40">
      <PrimaryCta text="Resubmit" color="orange" onClick={onClick} />
    </div>
  );
}

const ResubmitResponseToTextMapper = {
  [ResubmitResponse.UnavailableNotInStock]: {
    title: "Your request is being processed",
    text: "You will receive an email once your medication is ready for payment",
  },

  [ResubmitResponse.AvailableInStock]: {
    title: "Your request to resubmit has been successful",
    text: "You will receive an email to make your payment.",
  },
};

Object.freeze(ResubmitResponseToTextMapper);

export default ResubmitButton;
