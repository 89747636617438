import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../axios";
import { apiClient } from "../../ApiClient";
import { createFormData, getUserFromToken } from "../../util/util";

import {
  resetPrescriptions,
  resetPrescriptionsForClinicUser,
  setIsGetStartedPagePresented,
} from "./prescriptionsSlice";
import { resetAccountData, resetCurrentOrganisation } from "./accountSlice";

export const loginUser = createAsyncThunk(
  "authentication/login",

  async (credentials) => {
    credentials.email = credentials.email.toLowerCase().trim();

    const payload = createFormData(credentials);

    const data = await apiClient.loginUser(payload);

    const { token } = data;

    const user = await getUserFromToken(token);

    return { user, token };
  }
);

export const refreshTokenForUser = createAsyncThunk(
  "authentication/login",

  async (payload, { dispatch }) => {
    const data = await apiClient.refreshTokenForUser();

    const { token } = data;

    const user = await getUserFromToken(token);

    dispatch(setIsGetStartedPagePresented(false));

    return { user, token };
  }
);

export const setUserFromToken = createAsyncThunk(
  "authentication/setUserFromToken",

  async ({ token }) => {
    if (!token) {
      throw new Error("No token");
    }

    const user = await getUserFromToken(token);

    return { token, user };
  }
);

export const logout = createAsyncThunk(
  "authentication/logout",

  async (payload, { dispatch }) => {
    dispatch(resetPrescriptions());
    dispatch(resetAccountData());
    dispatch(resetCurrentOrganisation());
    dispatch(resetPrescriptionsForClinicUser());
  }
);

const initialState = {
  user: undefined,
  token: localStorage.getItem("token"),
  loading: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,

  // reducers for async actions
  extraReducers: {
    // logout
    [logout.fulfilled]: (state, action) => {
      state.token = "";
      state.user = null;
      localStorage.removeItem("token");
      axios.defaults.headers.Authorization = ``;
    },

    // loginUser
    [loginUser.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;
      state.loading = false;
      axios.defaults.headers.Authorization = `Bearer ${payload.token}`;
      localStorage.setItem("token", payload.token);
    },

    [loginUser.rejected]: (state, action) => {
      state.loading = false;
    },

    // refreshTokenForUSer
    [refreshTokenForUser.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [refreshTokenForUser.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;
      state.loading = false;
      axios.defaults.headers.Authorization = `Bearer ${payload.token}`;
      localStorage.setItem("token", payload.token);
    },

    [refreshTokenForUser.rejected]: (state, action) => {
      state.loading = false;
    },

    // setUserFromToken
    [setUserFromToken.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;

      axios.defaults.headers.Authorization = `Bearer ${payload.token}`;

      localStorage.setItem("token", state.token);
    },

    [setUserFromToken.rejected]: (state, { payload }) => {
      localStorage.removeItem("token");
      state.user = null;
    },
  },
});

export const selectToken = (state) => state.authentication.token;

export const selectUser = (state) => state.authentication.user;

export const selectLoginLoading = (state) => state.authentication.loading;

export default authenticationSlice.reducer;
