import React from "react";
import { useHistory } from "react-router-dom";

function PrimaryCta({
  text = "",
  link = "",
  color = "orange",
  onClick = () => null,
  isEnabled = true,
  isLinkExternalLink = false,
  ...props
}) {
  const history = useHistory();

  const className = `primary_cta primary_cta--${color} ${props.className || ""
    } `;

  function beforeOnClick() {
    if (link && isLinkExternalLink) window.open(link);
    else if (link) history.push(link);

    onClick();
  }

  return (
    <button onClick={beforeOnClick} className={className} disabled={!isEnabled}>
      {text} {props.children}
    </button>
  );
}

export default PrimaryCta;
