import React, { useState } from "react";

import { EyeIcon } from "../../../assets/icons";
import EyeIconSlashed from "../../../assets/icons/EyeIconSlashed";

/**
 * A generic input field.
 * Just pass these props: id, name, type, value, onChange.
 * value and onChange should be from useProps hook.
 *  @see useProps in the hooks folder.
 */
function PasswordInputField({
  id = "",
  name = "",
  value = "",
  onChange = () => null,
  onBlur = () => null,
  errors = [],
  isEnabled = true,
  showPassword = false,
  useEyeIcon = true,
  onEnter = () => null,
}) {
  const labelPosition = value ? "label-top" : "";
  const labelColor = isEnabled ? "" : "label-disabled";
  const labelClass = `${labelColor} ${labelPosition}`;

  const [showPass, setShowPass] = useState(showPassword);

  function changeView() {
    setShowPass(!showPass);
  }

  function onKeyUp(e) {
    if (e.key === "Enter") onEnter();
  }

  return (
    <div className="text-input-container">
      {isEnabled ? (
        <input
          autoComplete="off"
          id={id}
          type={showPass ? "text" : "password"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
        />
      ) : (
        <input
          disabled
          id={id}
          type={showPass ? "text" : "password"}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      )}

      <label className={labelClass} htmlFor={id}>
        {name}
      </label>

      <div
        className={`animated-border--${errors.length > 0 ? "red" : "black"} ${value || errors.length > 0 ? "animated-border--show" : ""
          }`}
      ></div>
      <p
        className={`text-input--error text-input--${errors.length > 0 ? "show-error" : "hide-error"
          }`}
      >
        {errors[0]}
      </p>
      <div className={`password_input_field__eye ${useEyeIcon ? "" : "password_input_field__eye--istext"}`} onClick={changeView}>
        {useEyeIcon ? showPass ? <EyeIcon /> : <EyeIconSlashed /> : showPass ? "Hide" : "Show"}
      </div>
    </div>
  );
}

export default PasswordInputField;
