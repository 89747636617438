import { apiClient } from "../ApiClient";
import { callMapper } from "../util/util";
import {
  ClinicPatientTypesToApiCallMapper, ClinicPatientTypesToPatientConverterMapper,
} from "../enums";
import { patientConverter } from "../converters/PatientsConverter";

class PatientService {

  async getPatientsForClinic(organisationId, status) {
    const patients = await callMapper(ClinicPatientTypesToApiCallMapper, status, apiClient.getBasicClinicPatients)(organisationId);
    const convertedPatients = callMapper(ClinicPatientTypesToPatientConverterMapper, status, patientConverter.tempPatientsApiToClient).apply(patientConverter, [patients])
    return convertedPatients.reverse()
  }

  async getAllByPatientsId(patientId) {
    const products = await apiClient.getAllByPatientsId(patientId);

    return products
  }
}

export const patientService = new PatientService();
