import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import RepeatPrescritpionCompletedIcon from '../../../assets/icons/RepeatPrescriptionCompletedIcon'
import { PrimaryCta } from '../../../components/buttons'
import { setShowRepeatPrescriptionFormGuideline } from '../../../store/slices/repeatPrescriptionSlice';

export default function RepeatPrescriptionCompleted() {
    const dispatch = useDispatch();
    const history = useHistory()
    const handleRedirectToActivePrescription = () => {
        history.push("/prescriptions/active")
        dispatch(setShowRepeatPrescriptionFormGuideline());
    }

    return (
        <div className='guideline--completed'>
            <RepeatPrescritpionCompletedIcon />
            <h1>Request sent!</h1>
            <div className='divider'></div>
            <div className='completed-content'>
                <h2>We have received your repeat prescription request</h2>
                <p><span></span>You will receive an email confirming your request and payment</p>
                <p><span></span>If we need any more information, we will contact you</p>
                <p><span></span>A new prescription will be sent to your pharmacy</p>
            </div>
            <div className='completed-button'>
                <PrimaryCta
                    text={"Back to Lyphe Dispensary"}
                    color='form'
                    className='primary_cta--form'
                    onClick={handleRedirectToActivePrescription}
                />
            </div>
        </div>
    )
}
