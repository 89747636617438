import React from 'react'

const PrivacyPolicyTable = ({ headers, rows }) => {

    const resolveClass = (cell) => {
        return `table-col-${cell.col * 2}`
    }

    const renderTableHeaders = () => {
        return headers.map((h, i) => {
            return <div key={i} className={`privacy_policy_table__container__header__cell ${resolveClass(h)}`}>
                {h.text}
            </div>
        })
    }

    const renderTableRows = () => {
        return rows.map((r, i) => {
            return <div key={i} className={`privacy_policy_table__container__row`}>
                {r.map((c, i) => {
                    return <div key={i} className={`privacy_policy_table__container__row__cell ${resolveClass(c)}`} >
                        {c.text}
                    </div>
                })}
            </div>
        })
    }

    return (
        <div className='privacy_policy_table' >
            <div className='privacy_policy_table__container' >
                <div className='privacy_policy_table__container__header'>
                    {renderTableHeaders()}
                </div>
                {renderTableRows()}
            </div>

        </div>
    )
}

export default PrivacyPolicyTable
