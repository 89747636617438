import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountService } from "../../services/AccountServices";
import { onboardingService } from "../../services/OnboardingServices";
import { setUserFromToken } from "./authenticationSlice";

export const registerCarer = createAsyncThunk(
  "carerOnboarding/registerCarer",

  async (payload, { getState, dispatch }) => {
    const { carer } = getState().carerOnboarding;

    const response = await onboardingService.registerCarer(carer);

    await dispatch(setUserFromToken({ token: response.token }));

    return response;
  }
);

export const uploadPrescriptionCarer = createAsyncThunk(
  "carerOnboarding/uploadPrescription",

  async (patientId, { getState }) => {
    const { prescription } = getState().carerOnboarding;

    const response = await accountService.uploadPrescription(
      patientId,
      prescription
    );

    return response;
  }
);

export const completeCarerAccount = createAsyncThunk(
  "carerOnboarding/completeCarerAccount",

  async (payload, { getState, dispatch }) => {
    const { carer } = getState().carerOnboarding;

    const response = await onboardingService.completeCarerAccount(carer);

    await dispatch(setUserFromToken({ token: response.token }));

    return response;
  }
);

const initialState = {
  carer: {},
  prescription: {},

  registeringCarer: false,
  uploadingPrescription: false,
};

const carerOnboardingSlice = createSlice({
  name: "carerOnboarding",
  initialState,

  // reducers for async actions
  extraReducers: {
    // registerCarer
    [registerCarer.pending]: (state) => {
      state.registeringCarer = true;
    },

    [registerCarer.fulfilled]: (state) => {
      state.registeringCarer = false;
      state.carer = {};
    },

    [registerCarer.rejected]: (state) => {
      state.registeringCarer = false;
    },

    // uploadPrescriptionCarer
    [uploadPrescriptionCarer.pending]: (state) => {
      state.uploadingPrescription = true;
    },

    [uploadPrescriptionCarer.fulfilled]: (state) => {
      state.prescription = {};
      state.uploadingPrescription = false;
    },

    [uploadPrescriptionCarer.rejected]: (state) => {
      state.uploadingPrescription = false;
    },

    // completeCarerAccount
    [completeCarerAccount.pending]: (state) => {
      state.registeringCarer = true;
    },

    [completeCarerAccount.fulfilled]: (state) => {
      state.registeringCarer = false;
    },

    [completeCarerAccount.rejected]: (state) => {
      state.registeringCarer = false;
    },
  },

  // reducers for sync actions
  reducers: {
    resetCarer(state) {
      state.carer = {};
      state.prescription = {};
    },

    setCarer(state, { payload }) {
      state.carer = payload;
    },

    setCarerName(state, { payload }) {
      state.carer.firstName = payload.firstName;
      state.carer.lastName = payload.lastName;
      state.carer.title = payload.title;
    },

    setPatientName(state, { payload }) {
      state.carer.patientFirstName = payload.firstName;
      state.carer.patientLastName = payload.lastName;
      state.carer.patientTitle = payload.title;
    },

    setPatientDateOfBirth(state, { payload }) {
      state.carer.patientDateOfBirth = payload;
    },

    setCarerDateOfBirth(state, { payload }) {
      state.carer.dateOfBirth = payload;
    },

    setRelationship(state, { payload }) {
      state.carer.patientRelationship = payload;
    },

    setPrescription(state, { payload }) {
      state.prescription = payload;
      state.prescription.confirmedConsent = true;
    },

    setPatientAddress(state, { payload }) {
      state.carer.address1 = payload.address1;
      state.carer.address2 = payload.address2;
      state.carer.zipCode = payload.zipCode;
    },

    setContactNumber(state, { payload }) {
      state.carer.contactNumber = payload;
    },

    setCredentials(state, { payload }) {
      state.carer.email = payload.email;
      state.carer.password = payload.password;
      state.carer.agreedToTerms = payload.agreedToTerms;
    },

    setSecretId(state, { payload }) {
      state.carer.secretId = payload;
    },
  },
});

// export synchronous actions
export const {
  setCarer,
  setCarerName,
  setPatientAddress,
  setCredentials,
  setPatientDateOfBirth,
  setPatientName,
  setPrescription,
  setContactNumber,
  setRelationship,
  setCarerDateOfBirth,
  resetCarer,
  setSecretId,
} = carerOnboardingSlice.actions;

export default carerOnboardingSlice.reducer;

export const selectCarer = (state) => state.carerOnboarding.carer;

export const selectPrescription = (state) => state.carerOnboarding.prescription;

export const selectUploadingPrescriptionCarer = (state) =>
  state.carerOnboarding.uploadingPrescription;

export const selectRegisteringCarer = (state) =>
  state.carerOnboarding.registeringCarer;
