import React, { useState } from 'react'
import { PrimaryCta } from '../../../components/buttons'
import { PasswordInputField, RadioButton } from '../../../components/inputs'
import Spinner from '../../../components/sharedComponents/Spinner/Spinner'
import { usePasswordInput, useRadioButton } from '../../../hooks'
import TermsAndConditionsPopup from '../../../components/popups/TermsAndConditionsPopup/TermsAndConditionsPopup'
import { useDispatch, useSelector } from 'react-redux'
import { completePatientAccountSecretIdOnboarding, selectCompletingPatientAccount, selectPatient, setCredentials } from '../../../store/slices/patientOnboardingSlice'
import { isFormValid, trackAnalyticsEvent } from '../../../util/util'
import PrivacyPolicyTable from '../../../components/Table/PrivacyPolicyTable'
import { ValidationErrorMessages } from '../../../enums'
import InputValidationBulletPoints from '../../../components/inputs/InputValidationBulletPoints/InputValidationBulletPoints'
import { privacyPolicyUrl } from '../../../config.json'

const ThankYouForConfirming = ({ setNextStep, steps }) => {
    const agreedToTerms = useRadioButton(false);
    const agreedToPrivacyPolicy = useRadioButton(false);
    const dispatch = useDispatch();

    const patient = useSelector(selectPatient);
    const submitting = useSelector(selectCompletingPatientAccount);

    const [showTermsAndConditionsPopup, setShowTermsAndConditionsPopup] = useState(false);
    const [showPrivacyPolicyPopUp, setShowPrivacyPolicyPopUp] = useState(false);

    const [
        password
    ] = usePasswordInput();

    const credentialsForm = { password };

    const isButtonEnabled =
        isFormValid(credentialsForm) &&
        agreedToTerms.value &&
        agreedToPrivacyPolicy.value &&
        !submitting;

    const handleSubmit = async () => {
        try {
            dispatch(setCredentials({
                password: password.value,
                email: patient.email
            }))

            await dispatch(completePatientAccountSecretIdOnboarding());
            trackAnalyticsEvent("Patient Onboarding", "Agreed to terms and passowrd set up");
            setNextStep(steps.end);
        } catch (error) {

        }
    }

    const handleTermsAndConditionsClose = () => {
        setShowTermsAndConditionsPopup(false);
    }

    const handlePrivacyPolicyClose = () => {
        setShowPrivacyPolicyPopUp(false);
    }

    const handleTermsClick = (e) => {
        e.preventDefault();
        setShowTermsAndConditionsPopup(true);
    }

    const handlePolicyClick = (e) => {
        e.preventDefault();
        setShowPrivacyPolicyPopUp(true);
    }

    const renderTermsRadioButtonText = () => {
        return (
            <span> I agree to the Lyphe Dispensary  &nbsp;
                <a href='/' onClick={(e) => { handleTermsClick(e) }} style={{ color: '#0000EE', textDecoration: 'underline' }} >Terms & Conditions</a>
            </span>
        )
    }

    const renderPolicyRadioButtonText = () => {
        return (
            <span >I consent to the processing of my data (see&nbsp;
                <a href='/' onClick={(e) => { handlePolicyClick(e) }} style={{ color: '#0000EE', textDecoration: 'underline' }} >Privacy policy</a> )
            </span>
        )
    }

    return (
        <div className='thank_you_for_confirming' >
            <TermsAndConditionsPopup show={showTermsAndConditionsPopup} onClose={handleTermsAndConditionsClose} title="Terms & conditions" content={termsAndConditionsContent()} />
            <TermsAndConditionsPopup show={showPrivacyPolicyPopUp} onClose={handlePrivacyPolicyClose} title="Privacy policy" content={privacyPopicyContent()} />
            <div className='thank_you_for_confirming__content' >
                <div className='thank_you_for_confirming__content__header'>
                    <div className='thank_you_for_confirming__content__header__small_title'>
                        password setup
                    </div>
                    <div className='thank_you_for_confirming__content__header__title'>
                        Thank you for confirming!
                    </div>
                    <div className='thank_you_for_confirming__content__header__description'>
                        Please enter a strong password which you will use to access your Lyphe Dispensary account
                    </div>
                </div>

                <form className="changing_password__form">
                    <div className="form-spacing">
                        <PasswordInputField
                            {...password}
                            id={'enterPassword'}
                            name="Enter a password"
                            type="password"
                            showPassword={true}
                            useEyeIcon={false}
                        />
                    </div>

                </form>
                <InputValidationBulletPoints errors={password.errors} value={password.value} points={passwordBulletPoints} />

                <div className='radio-container m-t-xl'>
                    <RadioButton
                        id="accept_terms"
                        name={renderTermsRadioButtonText()}
                        {...agreedToTerms}
                    />
                </div>
                <div className='radio-container'>
                    <RadioButton
                        id="accept_privacy"
                        name={renderPolicyRadioButtonText()}
                        {...agreedToPrivacyPolicy}
                        htmlSpecificName='accept_privacy'
                    />
                </div>
                <div className='thank_you_for_confirming__content__buttons' >
                    <PrimaryCta
                        isEnabled={isButtonEnabled}
                        text={submitting ? <Spinner /> : "Continue"}
                        color="orange"
                        onClick={handleSubmit}
                    />
                </div>
            </div>

        </div>
    )
}

export default ThankYouForConfirming

const termsAndConditionsContent = () => {
    return (
        <>
            <p>Lyphe Dispensary is your one-stop&nbsp;shop for managing special medicine prescriptions. We take pride in the high-level service delivered through our prescription platform, and patient support. Our excellent business standards extend to our terms &amp; conditions, privacy practices, and the safeguarding of your personal information.</p>
            <h3>1. Application of terms</h3>
            <p>These Terms &amp; Conditions (<strong>&ldquo;</strong><strong>T</strong><strong>erms&rdquo;</strong>) apply to your use of the <a href="http://www.Lyphe.com"><u>www.Lyphe.com</u></a> website (<strong>&ldquo;Website&rdquo;</strong>) and every order placed with Lyphe Dispensary as set out below. Lyphe Dispensary means Prescriptions Limited t/a Lyphe Dispensary, a company incorporated in England and Wales under company number&nbsp;<strong>08681796</strong>&nbsp;whose registered office is 20 &ndash; 22 Wenlock Road, London, England, N1 7GU and is referred to in these Terms as&nbsp;<strong>&ldquo;Lyphe Dispensary&rdquo;/ &ldquo;we&rdquo;/ &ldquo;us&rdquo;</strong><strong>/&ldquo;</strong><strong>our&rdquo;</strong>. Our VAT number is 358 3474 69. Lyphe Dispensary is registered with the General Pharmaceutical Council - registration number: 112608).</p>
            <p>These terms apply to:</p>
            <ul>
                <li>Your use of Lyphe Dispensary sites, apps, and services delivered by Lyphe Dispensary as described below in (ii) and (iii) (each or together the&nbsp;<strong>&ldquo;Service&rdquo;</strong>) and by accepting these terms you enter a contract with us for the provision of the Service.</li>
                <li>Any prescriptions ordered with Lyphe Dispensary (contact details set out above). By accepting these terms, when you place an order with Lyphe Dispensary, you enter a contract with Lyphe Dispensary&rsquo;s pharmacy for the provision of that prescription.</li>
                <li>Any prescriptions ordered with Lyphe Dispensary may be delivered by one of our partners (including East Stone Limited) (<strong>&ldquo;Partner&rdquo;</strong>).</li>
                <li>The Service may facilitate the provision of services from prescribing doctors, Partners, Parcel Force, or other members of Lyphe Dispensary&rsquo;s group, being its holding company and subsidiaries of its holding company (<strong>&ldquo;Group&rdquo;</strong>). Those entities are not a party to these Terms and may have separate legal terms and arrangements covering their services as explained in these Terms.</li>
            </ul>
            <p>If you do not accept these Terms, then you should not use Lyphe Dispensary.</p>
            <h3>2. What is Lyphe Dispensary?</h3>
            <p>Lyphe Dispensary is a web-based service that enables you (the&nbsp;<strong>&ldquo;Account Holder&rdquo;</strong>) to process current or repeat prescriptions from your prescribing doctor or other registered doctors for delivery through a Partner.</p>
            <p>Approved prescriptions will be transmitted to and dispensed by Lyphe Dispensary&rsquo;s pharmacy. The Account Holder can also make requests on behalf of someone that they are acting as a carer if they have been given authority to represent them (proxy access). In addition, a doctor may be an Account Holder for the purposes of obtaining products for which he/she has issued a prescription.</p>
            <p>The Account Holders&rsquo; contract with us is only to provide an intermediary/facilitation service.</p>
            <h3>3. How does Lyphe Dispensary work?</h3>
            <p>Access the Lyphe Dispensary website referred to above, register your details, and confirm you have a prescription. The website will prompt you to add the relevant information and verify your identity. If you have a prescription, you must upload a copy of the original prescription.</p>
            <p>If you do not have a prescription, you will be prompted to the services of UKMCCS, a governing body for Medical Cannabis Clinicians where there is a list of clinic providers, although you may access other organisations and prescribing doctors at your discretion to obtain a prescription. Once an order has been placed, the transaction is processed manually at the premises of Lyphe Dispensary or its Partner by its registered pharmacists who are members of the General Pharmaceutical Council.</p>
            <p>This website should be regarded as an invitation to treat. Any order placed by a customer is an offer to purchase, which Lyphe Dispensary may accept or decline in its absolute discretion without having to give a reason. A confirmation email is a notification that we have received your offer on our website.</p>
            <p>A contract is deemed to be made at the point of a sale invoice being issued by Lyphe Dispensary following the checking of orders by a pharmacist. We only deliver prescription medication within the UK.</p>
            <p>To register with and use this site you must be over 18 and the details which you give us must be true. The contents of this website are directed solely to those who access the site from the UK. You will be responsible for your use of this site and for your orders so please ensure that you keep your log-in details secure so that no&nbsp;one else can misuse them.</p>
            <p>Risk and title in the products will pass to you when they are despatched by us to you, from the UK, and you acknowledge and agree to be responsible for complying with any restrictions around the receipt and use by you of the products.</p>
            <p>You agree that Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy may contact you to manage your order and any orders you placed on behalf of a patient that you have been authorised to act as a proxy. This can include contact via the website, telephone, SMS, email, or post. If we are not able to contact you, we may be unable to dispense your prescription.</p>
            <p>Lyphe Dispensary will endeavor to ensure that all prescription and medicines prices are correct on the website. In some cases, the prices may vary due to manufacturer price increases, product availability, and/or special-order requirement. In such cases, Lyphe Dispensary will contact you on receipt of the prescription to inform you of any price difference.</p>
            <p>We are legally only able to dispense medicines for you when we have received either an electronic prescription which has been digitally signed by a prescribing doctor or alternatively a conventional paper prescription signed in writing by a prescribing doctor. prescriptions are issued entirely at the discretion of the prescriber.</p>
            <h3>4. Can I manage other people&rsquo;s medication using Lyphe Dispensary?</h3>
            <p>Yes, if you are at least eighteen (18) years old and have been authorised to act on behalf of another or have the authority to represent someone else (as their proxy). If you wish to sign up as a proxy for someone else, you must first obtain consent from the patient. By signing up for someone as a proxy, you confirm that you have obtained consent from that person to act as their proxy to manage their medication. As part of our safeguarding responsibilities, we may need to verify your identity when handling prescriptions for safe custody-controlled drugs. Please note this may result in an increase in the time taken to dispatch your medication.</p>
            <h3>5. Who is liable if something goes wrong?</h3>
            <p>There are different parties that make up the overall service provision to you and have different responsibilities and liabilities under these Terms or otherwise. We have separated these below.</p>
            <p>You are responsible for complying with these Terms. Please see the section below &ldquo;What are my obligations?&rdquo; for further details.</p>
            <h3>Lyphe Dispensary obligations</h3>
            <p>Lyphe Dispensary: Lyphe Dispensary&rsquo;s pharmacy is liable for ensuring that these prescriptions are dispensed correctly and in accordance with General Pharmaceutical Council standards and guidelines and these Terms.</p>
            <p>Lyphe Dispensary: is responsible for providing the Service in accordance with these Terms.</p>
            <p>Both Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy separately accept liability for death or personal injury caused by their negligence and do not seek to exclude liability for fraudulent misrepresentation. You have certain legal rights. These include that Lyphe Dispensary will provide the Service with reasonable skill and care and within a reasonable time and in accordance with the descriptions provided and that Lyphe Dispensary&rsquo;s pharmacy will dispense the agreed prescription.</p>
            <p>You have certain legal remedies if Lyphe Dispensary or Lyphe Dispensary&rsquo;s pharmacy breaches any of these rights. Nothing in these Terms is intended to affect these legal rights or other rights to which you may also be entitled. For more information on your legal rights contact your local Citizens Advice Service.</p>
            <p>Lyphe Dispensary and Lyphe Dispensary&rsquo;s pharmacy are not responsible for any failures or delays caused by you, the prescribing doctor, a Partner, or Parcel Force or for the failures or delays of each other.</p>
            <p>Neither Lyphe Dispensary nor Lyphe Dispensary&rsquo;s pharmacy are responsible for losses not caused by its breach, indirect losses, or a failure to provide the Service due to any cause beyond its reasonable control which prevents it from fulfilling our obligations including but not limited to fire, storm, riot, civil disturbance, war, nuclear accident, terrorist activity and acts of God.</p>
            <p>If Lyphe Dispensary, or Lyphe Dispensary&rsquo;s pharmacy, does breach these terms it shall only be liable for losses that are a reasonably foreseeable consequence of such a breach. Losses are foreseeable where they could be contemplated by you and Lyphe Dispensary or Lyphe Dispensary&rsquo;s pharmacy as relevant at the time of entering these Terms.</p>
            <p>Lyphe Dispensary has taken care of the preparation of the content of this website. To the fullest extent permitted by the law, Lyphe Dispensary disclaims all warranties of any kind with respect to the content of this website. Lyphe Dispensary and its directors or related companies shall not be liable for any losses or claims arising directly or indirectly from the use of this website except that this exclusion of liability does not apply to any damages in connection with death or personal injury caused by the negligence of Lyphe Dispensary, its directors, or employees.</p>
            <p>All content of this website is for information purposes only and is not a substitute for professional medical advice, rather it is designed to support, not replace, the relationship between you and your healthcare providers. You should make sure that you carefully read all product packaging and labels prior to use.</p>
            <p>The following parties are not subject to these Terms but carry certain responsibilities and obligations to you as set out below. In the event of an issue, you should contact Lyphe Dispensary in the first instance, and we may direct you to contact your prescribing doctor, the relevant Partner, or Parcel Force as appropriate.</p>
            <p>Your prescribing doctor: Your prescribing doctor is not a party to these terms, but they are responsible for issuing prescriptions in a timely manner and providing medical advice. Please note that all prescribing decisions are made by the prescribing doctor, not Lyphe Dispensary, not a Partner or Lyphe Dispensary&rsquo;s pharmacy.</p>
            <p>Our Partners: no Partner is a party to these Terms.</p>
            <p>Parcel Force: is responsible for delivering medication orders that you place via Lyphe Dispensary. They are also responsible for delivering any paper prescriptions posted to Lyphe Dispensary&rsquo;s pharmacy. Parcel Force is not a party to these Terms and has separate terms that cover the provision of their delivery service which you can review here:&nbsp;<a href="https://www.parcelforce.com/conditions-of-carriage"><u>https://www.parcelforce.com/conditions-of-carriage</u></a>.</p>
            <h3>What are my obligations?</h3>
            <p>You, the Account Holder, are responsible for the information you provide to us about yourself, your prescribing doctor, and your medication; this extends to when you are using Lyphe Dispensary on behalf of someone else as their proxy.</p>
            <p>You confirm that all information provided to us is and remains accurate. If you do not provide accurate information, you could be putting yourself or the person you are managing medication for at serious risk.</p>
            <p>By giving your consent for a pharmacy to access your health record from the clinic the pharmacist/pharmacy technician involved in providing care to you would be able to view your Summary Care Record to assess for any contraindications.&nbsp;You can change this permission at any point just by informing the pharmacy.</p>
            <p>You are responsible for checking and taking any medication correctly. You must check the spelling and dosage information and follow the clinical directions provided with all medication. You should review the patient information leaflet provided with the medication.</p>
            <p>If at any point the information that appears on Lyphe Dispensary contradicts the information provided by your prescribing doctor, please contact us immediately. You agree that the information provided by your prescribing doctor and in the patient information leaflet take precedence over any information provided by Lyphe Dispensary.</p>
            <p>You confirm that you are requesting medications for your personal use only or the use of someone you are acting as a proxy for. Medications should never be shared with any other person.</p>
            <p>You agree to keep your Lyphe Dispensary login details confidential and not to share them with any other person.</p>
            <p>If you are exempt from paying the prescription charge, you agree that you are legally responsible for ensuring that your exemption reason is correct, up-to-date, and maintained; this responsibility extends to any other people that are listed on your account.</p>
            <p>You agree not to use any Service provided by us for any unlawful purpose, including requesting medications that have not been prescribed to you or the person you are managing medication for by a registered healthcare professional.</p>
            <p>You agree not to use our websites or applications in any way that will damage, impair, or render them less efficient.</p>
            <p>Parcel Force and its terms and conditions will govern the delivery process.</p>
            <p>You acknowledge that Lyphe Dispensary is not responsible for incidents relating to the storage, handling, manufacture, delivery, use, misuse, or abuse of your medication. We reserve the right to suspend or terminate your access to Lyphe Dispensary at any time without notice if we have reasonable grounds to believe that you have breached any of these terms; this extends to any patients that you, the Account Holder have been authorised to act as a proxy for.</p>
            <h3>6. How does payment work?</h3>
            <p>You will be charged the price shown on the website, subject to changes referred to in these terms. This will appear on your bank statement as &lsquo;TAOMC. No medical information will be passed to your bank or credit card company. Payment for medication for other people that are listed on your account will also be processed in the same way.</p>
            <p>Our payment processing platform, Opayo, may also perform a pre-authorisation on the card you have signed up with to ensure your payment details are correct. Opayo and its terms and conditions will govern the payment processing process. Terms can be found here:&nbsp;<a href="https://www.opayo.co.uk/policies/merchant-terms-conditions"><u>https://www.opayo.co.uk/policies/merchant-terms-conditions</u></a>.</p>
            <h3>7. Your privacy</h3>
            <p>Your privacy is important to us. Please see our&nbsp;<a href="https://lyphe.com/privacy-policy"><u>Privacy Policy</u></a>&nbsp;for details of what information we collect and how we will use and protect it. The information that you send to us will be processed in accordance with our&nbsp;<a href="https://lyphe.com/privacy-policy"><u>Privacy Policy</u></a>.</p>
            <p>You authorise us, at any time, to use any means that we consider necessary to verify your identity and creditworthiness with any third-party providers of information.</p>
            <p><strong>Please note that in an emergency, we may need to contact a healthcare professional, social services, or emergency </strong><strong>services</strong><strong> without your consent, even </strong><strong>if</strong><strong> you have declined any of our offers to contact your prescribing doctor.</strong></p>
            <h3>8. Can I cancel my order?</h3>
            <p>You can always choose to stop using the Service at any time.</p>
            <p>You can cancel an order placed through the Service at any point until the medication is dispensed by Lyphe Dispensary&rsquo;s pharmacy and you will receive a full refund with the same method of payment.</p>
            <p>You can make such a cancellation by contacting us via the website. Alternatively, you can email us at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a>.</p>
            <p>Unfortunately, due to the nature of prescription medications, once your items have been dispensed, you will be unable to cancel the order, return the medication or receive a refund. Any unwanted medicines must be disposed of by returning the medication to a pharmacy or any authorised location.</p>
            <p>If you receive incorrect medication, dispatched in error, or damaged you should contact us via the website or at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a> to discuss a replacement or refund.</p>
            <h3>9. When will I receive my medication?</h3>
            <p>Your prescribing doctor may want to speak to you before prescribing so your prescription request may not be processed immediately. If you consult with your prescribing doctor, you should tell them that you would like your prescription to be sent electronically to your &lsquo;nominated&rsquo; pharmacy (Lyphe Dispensary).</p>
            <p>Your prescribing doctor may be delayed in issuing a repeat prescription. Lyphe Dispensary has no control over these delays; however, where possible, we will direct you to contact your prescribing doctor directly if we are informed of any issues with your request or your repeat prescription.</p>
            <p>Once the original prescription has been received by Lyphe Dispensary, we will dispense the medication. We or our Partner will then pack the medication and dispatch it via Parcel Force. It typically takes up to four working days for us to process your prescription. We cannot guarantee the exact time and day of arrival.</p>
            <h3>10. What laws apply and what rights do I have to make a complaint?</h3>
            <p>These Terms are governed by the laws of England and Wales and any dispute, question or remedy shall be determined exclusively by the courts of England and Wales. These Terms are only available in the English language. If any part of these Terms is disallowed or found to be ineffective by any court or regulator, the other provisions shall continue to apply. If you wish to make a complaint, you can contact us as set out below. These terms do not affect your statutory rights as a consumer.</p>
            <h3>11. How can I contact you?</h3>
            <p>The fastest way to contact us is via the website. Alternatively, you can email us at <a href="mailto:info@lyphe.com"><u>info@lyphe.com</u></a> or write to Prescriptions Limited, 20 &ndash; 22 Wenlock Road, London, N1 7GU. Should you wish to file a complaint please contact us via the methods listed above. We aim to acknowledge complaints within two working days.</p>
            <h3>12. Intellectual property</h3>
            <p>All designs, text, graphics, their selection, and arrangement on this website are the copyright of Lyphe Dispensary or a member of the Group. Permission is granted to users to electronically copy or print portions of this site for their own personal, non-commercial use. Any other use of materials on this site without Lyphe Dispensary&rsquo;s prior written consent is strictly prohibited.</p>
            <p>All brand names, products, service names, titles, and copyrights used in this site are trademarks, trade names, service marks, or copyrights of their respective holders, including members of the Group. No permission is given by Lyphe Dispensary for their use by any person other than the said holders and such use may constitute an infringement of the holders' rights. This contract is governed by English Law.</p>
            <h3>13. Cookies and other technologies</h3>
            <p>Cookies come in a variety of forms but are essentially small data files used to collect and store information about you. We use them on our website for a variety of different functions:</p>
            <ul>
                <li>for the smooth and safe operation of Lyphe Dispensary and our website.</li>
                <li>to manage your preferences and remember you for future visits.</li>
                <li>to analyse how you use Lyphe Dispensary and our website in order to continually make improvements.</li>
            </ul>
            <p>The majority of these cookies are linked to your browser session (session cookies) and disappear once you close your browser Others remain on your device for a longer period (persistent cookies).</p>
            <p>For further information about the cookies we use, please see our Cookie Policy.</p>
        </>
    )
}
const privacyPopicyContent = () => {
    return (
        <>
            <p>At Lyphe Dispensary we aim to be your one-stop-shop for managing your medical cannabis prescriptions. We take pride in the quality of our prescription service and our ability to source and supply medical cannabis products to meet your prescription requirements, quickly and affordably. Our high business standards extend to our privacy practices and the safeguarding of your personal information.</p>
            <p>This policy describes the information we collect or you shared with us when you visit our website (regardless of where you visit it from), or use our platform and services to manage your medical cannabis prescriptions, as well as how that data is used, stored and safeguarded, and your choices regarding this information.</p>
            <h3>1. What this policy covers</h3>
            <p>This policy outlines how we at Lyphe Dispensary collect and process your personal information through your use of our website (the “Website”), as well as the platform we use to manage your medical cannabis prescriptions, and such other services associated with the management and supply of prescribed medical cannabis (collectively the “Services”), including any data you may provide to us when you create an account, register to use our prescription management services (whether as a Patient, Carer or Doctor), manage your prescriptions through us, or interact with us in any way.
            </p>
            <p>Our Website and Services are intended for use by patients, carers and doctors and only by those over the age of 18; we do not knowingly collect data relating to children.
            </p>
            <p>It is important that you read this privacy policy together with any other policy on data processing or other notices we may provide on specific occasions so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.
            </p>


            <h3>2. Data controller and contact details</h3>
            <p>Total Health Midland Ltd trading as Lyphe Dispensary (<span className='bold'>“Lyphe Dispensary”</span>, <span className='bold'>“we”</span>, <span className='bold'>“us”</span> or <span className='bold'>“our”</span>) is the ‘data controller’ of the processing of your personal information as described in this policy. As the data controller, we decide why and how your personal information is processed and are responsible to you for that processing under data protection laws. </p>
            <p>Our details are as follows:</p>
            <ul className="ul-romans">
                <li >
                    <span ><span className='bold'>Correspondence address:</span> Lyphe Dispensary. 28A Sherwood Street, NG20 0JW Warsop, Nottinghamshire, England</span>
                </li>
                <li >
                    <span ><span className='bold'>Email:</span> info@lyphe.com</span>
                </li>
                <li >
                    <span ><span className='bold'>Registered office address:</span> 20-22 Wenlock Road, London, N1 7GU, United Kingdom</span>
                </li>
                <li >
                    <span ><span className='bold'>Company number:</span> 09383239</span>
                </li>
            </ul>

            <h3>3. How to contact us about your rights and data</h3>
            <p>
                We have appointed Sophie Gamwell who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact us using the details set out below.
            </p>
            <p>
                <span className='bold'>By email</span> at: info@lyphe.com
            </p>
            <p>
                We are regulated by the <a href="https://ico.org.uk/" target="_blank" rel="noopener noreferrer">Commissioner’s Office (“ICO”)</a> Information  and you have the right to make a complaint at any time to them. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
            </p>

            <h3>4. Third party links</h3>
            <p>Our Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible fo their privacy practices. When you leave our Website, we encourage you to read the privacy policy of every website you visit, connect with, or are referred to.</p>

            <h3>5. Information collected about you</h3>
            <p>We have set out below the personal information about you we may collect, use, store and transfer when you interact with us through Lyphe Dispensary. Personal information means any information from which we can identify you, it does not include information we collected on an anonymous basis.</p>
            <ul className="ul-romans">
                <li >
                    <span ><span className='bold'>Identity & Contact Data:</span> includes your title, first name, last name, date of birth, email address, home address, telephone number, whether you are a Patient, Carer or Doctor, and in the case of Doctors, the name of your clinic or GP practice, and address. As part of our verification checks, we also collect a copy of your valid ID (passport, driving licence etc.) as well as a scan of your face, using secure facial recognition technology.</span>
                </li>
                <li >
                    <span ><span className='bold'>Medical & Prescription Data:</span> as our Services assist you to manage your medical cannabis prescriptions, naturally we collect various information about your medical situation and your prescriptions, including information about your prescription such as its unique ID number and the type, brand and quantity of medication, as well as any other information your Doctor may include on your prescriptions. We will also collect and store the physical copy of your prescription you send to us via the post.</span>
                </li>
                <li >
                    <span ><span className='bold'>Financial Data & Transaction Data:</span>  includes your payment card details, billing address, as well as details about payments to and from you and other details of any purchases you have made from us.</span>
                </li>
                <li >
                    <span ><span className='bold'>Profile Data:</span> includes your username and password, details of your account, information about the prescriptions and orders made by you, your profile and account preferences, and any information provided through direction interactions with us. In addition, if you are a Carer or Doctor we also collect the following information:</span>
                    <ul className="ul-romans">
                        <li><span className='bold'>Carers: </span> details about any patients you add to your account (such as their name, date of birth and their address).</li>
                        <li><span className='bold'>Doctors: </span> your GMC reference number, your field of specialism (e.g., pain, neurology, paediatrics etc.), details on any CQC checks against your clinic or GP practice, details of any patients you add to your account.</li>
                    </ul>
                </li>
                <li >
                    <span ><span className='bold'>Usage Data:</span>   includes information created about you through your use of our Services, such as unique identifiers, activity logs, and your interactions with us.</span>
                </li>
                <li >
                    <span ><span className='bold'>Technical Data</span>   includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our Website or Services.</span>
                </li>
                <li >
                    <span ><span className='bold'>Marketing & Communications Data</span> includes your preferences in receiving marketing from us and our third parties, other interactions with us, such as feedback to surveys or with our technical support or customer services teams, as well as your communication preferences.</span>
                </li>
            </ul>
            <p>
                We also collect, use and share aggregated data about you. This includes statistical or demographic data, which could be derived from your personal information but is not considered personal information in its own right as, on its own, it cannot be linked to you or reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific feature. We may also use software to analyse your Usage Data and Technical Data in order to improve our Website and our Services. However, if we combine or connect Aggregated Data with your personal information so that it can be linked to you or can directly or indirectly identify you, we treat the combined data as personal information which will be used in accordance with this privacy policy.
            </p>
            <p>
                Our Services do involve the collection of certain types of information which we treat particularly sensitively. We refer to this information as “Special Category Data” and it includes:
            </p>
            <ul className="ul-romans">
                <li>
                    <span>information about your health, in particular your health Medical & Prescription Data; and,</span>
                </li>
                <li>
                    <span>biometric data, in particular when we use facial recognition technology to verify your account.</span>
                </li>
            </ul>
            <p>We have implemented additional safeguards with regards to the collection, use and storage of this data.</p>

            <h3 className="italic bold">How your information is collected</h3>
            <p>We use different methods to collect data from and about you including through:</p>
            <p><span>Direct interactions. </span> You may give us your Identity, Contact, Financial, Medical & Prescription, Profile, Marketing and Communications Data by interacting with us through our Website or Services, filling in forms or completing our registration process, or by corresponding with us. If you are a Carer or Doctor, this will also extend to information about your Patients.</p>
            <p>This includes personal information you provide when you:</p>
            <ul className="ul-romans">
                <li>
                    <span>create and verify your account;</span>
                </li>
                <li>
                    <span>upload and manage your prescriptions;</span>
                </li>
                <li>
                    <span>pay for your prescriptions;</span>
                </li>
                <li>
                    <span>subscribe to any of our mailing lists or request marketing to be sent to you;</span>
                </li>
                <li>
                    <span>select your marketing and communications preferences;</span>
                </li>
                <li>
                    <span>complete a survey; or</span>
                </li>
                <li>
                    <span>give us feedback or contact us.</span>
                </li>
            </ul>
            <p><span className='underline bold' >When using the Services. </span> Through your use of our Services we will collect, process and store your Medical & Prescription Data, Usage Data and Transaction Data, specifically:</p>
            <ul className="ul-romans">
                <li>
                    <span>we will create records of your prescriptions when we process any paper copies of those prescriptions you upload using the Services;</span>
                </li>
                <li>
                    <span>we will generate Usage and Transaction Data as you order and pay for subscriptions and, more generally, interact with your account.</span>
                </li>
            </ul>
            <p><span className='underline bold'>Automated technologies or interactions. </span> As you interact with both our Website and Services, we automatically collect various information about you, such as the device you use when you interact with us, browsing actions and patterns. We collect this personal information by using cookies, server logs and other similar technologies.</p>
            <p><span className='underline bold'>Third parties including publicly available sources. </span> We will receive personal information about you from various third parties as set out below:</p>
            <ul className="ul-romans">
                <li>
                    <span>Identity & Contact Data from the following parties:</span>
                    <ul className="ul-romans" >
                        <li><span>Doctors & Carers: when either your Carer or Doctor provides us with your personal information on your behalf.</span></li>
                        <li><span>Get Address: we utilise the Get Address look-up tool to help you complete your address details. This tool works by cross referencing Ordnance Survey's complete list of UK postcodes with various other data sources.</span></li>
                        <li><span>GMC & CQC: for Doctors registering an account with us, we verify both your status as a doctor, as well as that of your clinic or GP practice, with the General Medical Council and Care Quality Commission, respectively.</span></li>
                    </ul>
                </li>
                <li>
                    <span>Technical Data from the following parties:</span>
                    <ul className="ul-romans" >
                        <li><span>analytics providers;</span></li>
                        <li><span>advertising networks; and</span></li>
                        <li><span>search information providers..</span></li>
                    </ul>
                </li>
                <li>
                    <span>Contact, Financial and Transaction Data from providers of technical, payment and delivery services, such as our third-party payment processors, or social media platforms, where you sign up to Lyphe Dispensary through referra from these platforms.</span>
                </li>
            </ul>


            <h3>6. How we use your information</h3>
            <p>We collect, process, store and disclose personal information for a variety of different reasons, but in all cases only to the extent the law allows us to.</p>
            <p>Data protection laws require that organisations processing personal information set out the specific legal reason (known as the ‘lawful basis’) on which they rely to process that information.</p>
            <p>We rely on the following lawful bases to processing your personal information:</p>
            <ul className="ul-romans">
                <li><span><span className='underline'>Consent:</span> we use your consent as a lawful basis for processing your personal information including for the purposes of sending marketing communications to you, in particular, where you register your interest with us through our Website or otherwise. You have the right to withdraw consent at any time by contacting us, including by opting out through any marketing communication we may provide.</span></li>
                <li><span><span className='underline'>Performance of Contract:</span> where we need to perform the contract we are about to enter into or have entered into with you.</span></li>
                <li><span><span className='underline'>Legal Obligations:</span> where we need to comply with a legal obligation</span></li>
                <li><span><span className='underline'>Legitimate Interests:</span> where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests. We rely on a wide range of legitimate interests as a business:</span>
                    <ul className="ul-romans">
                        <li><span>for marketing activities (other than where we rely on your consent);</span></li>
                        <li><span>to correspond or communicate with you;</span></li>
                        <li><span>to verify the accuracy of data that we hold about you;</span></li>
                        <li><span>to preserve the integrity of our network and information security and, in particular, for us to take steps to protect your information against loss or damage, theft or unauthorised access;</span></li>

                        <li><span>to improve our Website, products and Services, in particular through analysing how you interact with us through our Website, products and Services to more generally improve your user experience;</span></li>

                        <li><span>for the management of queries, complaints, or claims, including when complying with a request from you in connection with the exercise of your data protection rights;</span></li>
                        <li><span>for the establishment and defence of our legal rights.</span></li>
                    </ul>
                </li>
            </ul>
            <h3 className='italic'>Special Category Data</h3>
            <p>For certain types of information identified in this policy as Special Category Data, we rely on the following lawful bases:</p>
            <ul className="ul-romans">
                <li><span>Medical & Prescription Data: for all health and medical related data you provide to us by uploading your prescriptions, we process this information in order to supply our Services to you (i.e., <span className='underline'>for the performance of our contract with you</span>), and to deliver health care services to you (i.e., <span className='underline'>the provision of managed pain relief by fulfilling your prescription</span>). As part of our rigorous standards, all services are provided under the ultimate supervision of a superintendent pharmacist.</span></li>
                <li><span>Identity Data: in light of the sensitive nature of prescribed medicines, we want our verification process to be as rigorous as it can be. A key aspect of this process involves our use of facial recognition technology to scan your face against the ID you have submitted. When you scan your face using our verification tool we ask you to confirm that you provide your <span className='underline'>Explicit Consent</span> to your use of your image for this specific purpose. Our verification process is there to ensure we get the right prescriptions to the right people. However, if you are uncomfortable with providing consent to the use of our facial recognition technology, we can provide alternate means of ensuring your prescriptions reach you.</span></li>
            </ul>
            <h3 className='italic'>Summary of how and why we use your information</h3>
            <p>We have summarised below the various ways we use your personal information and our lawful basis for doing so.</p>
            <PrivacyPolicyTable headers={firstTableHeaders} rows={firstTableRows} />
            <h3>7. Cookies and other technologies</h3>
            <p>Cookies come in a variety of forms but are essentially small data files used to collect and store information about you. We use them on our Website for a variety of different functions:</p>
            <ul className="ul-romans">
                <li>
                    <span>for the smooth and safe operation of Lyphe Dispensary and our website;</span>
                </li>
                <li>
                    <span>to manage your preferences and remember you for future visits;</span>
                </li>
                <li>
                    <span>to analyse how you use Lyphe Dispensary and our website in order to continually make improvements.</span>
                </li>
            </ul>
            <p>The majority of these cookies are linked to your browser session (session cookies) and disappear once you close your browser. Others remain on your device for a longer period (persistent cookies).</p>
            <p>For further information about the cookies we use, please see our Cookie Policy.</p>

            <h3>8. Our use of social media</h3>
            <p>We use social media platforms in a variety of different ways, including by publishing pages through which you can interact, running competitions or advertising to you using information you have provide those platforms or which has been provided by us or collected from our Website. Our legal relationship with each platform will vary with the particular way we are using that platform.</p>
            <p>
                In particular, we process your personal information using social media platforms as follows:
            </p>
            <ul className="ul-romans">
                <li>
                    <span>
                        <span className='bold underline'>Pages.</span> We use your personal information when you post content or otherwise interact with us on our official pages on Facebook, Instagram, LinkedIn, Twitter and other social media platforms. We also use the Page Insights service for Facebook, Instagram and LinkedIn to view statistical information and reports regarding your interactions with the pages we administer on those platforms and their content. Where those interactions are recorded and form part of the information we access through the Page Insights services, we and the relevant platform are joint controllers of the processing necessary to provide that service to us.
                    </span>
                </li>
                <li>
                    <span>
                        <span className='bold underline'>Targeted advertising.</span>  We use social media platforms (as well as search engines and third party websites and other platforms to deliver targeted advertising to you via those platforms, unless you object. Yo may receive advertising because you have previously interacted with us (such as by visiting one of our websites) or because of your profile on a social media platform on which you have an account. You can find our more by consulting the help pages of the relevant social media platform but in summary we use social media platforms to send targeted advertising using two methods:
                    </span>
                    <ul className="ul-romans">
                        <li><span>‘Custom audience’ targeting. You may receive advertising based on information about you that we have provided to the platform or allowed it to collect using cookies/pixels or code embedded in this Website (or a combination of the two). For example, we may target you if you have signed up to our mailing list and where cookies have detected that you have visited this Website in the last month. Please see the paragraph below regarding our use of cookies to send targeted advertising using social media.</span></li>
                        <li><span>‘Lookalike audience’ targeting. You may also receive advertising because, at our request, the social media platform has identified you as falling within a group or ‘audience’ whose attributes we have selected, or a group that has similar attributes to you (or a combination of the two).</span></li>
                    </ul>
                </li>
            </ul>

            <h3 className='bold italic'>Information we send using social media cookies</h3>
            <p>We also use cookies and similar technologies to collect and send information to Facebook (who operates the Facebook and Instagram platforms), LinkedIn and Twitter about actions you take on our Website or through our Services. In particular:</p>
            <ul className="ul-romans">
                <li><span>Facebook (who operates the Facebook and Instagram platforms) uses this information to provide services to us and also for further processing for its own business purposes. We and Facebook are joint controllers of the processing involved in collecting and sending your personal information to Facebook using cookies and similar technologies as each of us has a business interest in Facebook receiving this information. You can find out more about these technologies by visiting our Cookie Policy. The services we receive from Facebook that use this information are delivered to us through Facebook’s Business Tools, which include Facebook Login (Facebook’s single sign-on service, see above), Facebook Pixel and Website Custom Audiences (which includes ‘lookalike audience’ targeting). The data from these tools allows us to target advertising to you within Facebook’s social media platform by creating audiences based on your actions on our website and applications. They also allow Facebook to improve and optimise the targeting and delivery of our advertising campaigns for us. Please see the paragraph above for further information.</span></li>
                <li><span>We use LinkedIn Insight Tag, a small piece of code that we embed in this Website that allows us to perform in-depth campaign reporting and unlock insights about users that may visit this Website via our LinkedIn campaigns (e.g. by allowing us to discover business demographics by layering LinkedIn data on data about our Website’s visitors). LinkedIn Insight Tag enables the collection of metadata such as IP addresses, timestamps, and events such as page views. You can find out more about these technologies by visiting our Cookie Policy.</span></li>
            </ul>
            <p><span className='bold underline'>Our relationship with Facebook, LinkedIn and Twitter.</span> As we are joint controllers with these platforms for certain processing, we and each platform have:</p>
            <ul className="ul-romans">
                <li><span>entered into agreements in which we have agreed each of our data protection responsibilities for the processing of your personal information described above;</span></li>
                <li><span>agreed that we are responsible for providing to you the information in this privacy statement about our relationship with each platform; and</span></li>
                <li><span>agreed that each platform is responsible for responding to you when you exercise your rights under data protection law in relation to that platform’s processing of your personal information as a joint controller.</span></li>
            </ul>
            <p>Facebook, LinkedIn and Twitter may also process, as our processors, personal information that we submit for the purposes of matching, online targeting, measurement, reporting and analytics purposes. These services include the processing these platforms carry out when they display our advertisements to you in your news feed at our request after matching contact details for you that we have uploaded to them. These advertisements may include forms through which we collect contact information you give to us.</p>
            <p><span className='bold underline italic'>Further information.</span> The Facebook company that is a joint controller of your personal information is Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland. The LinkedIn company that is a joint controller of your personal information is LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland. For further information regarding these platforms and their use of your personal information, please see:</p>
            <ul className="ul-romans">
                <li><span>Facebook’s <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noopener noreferrer">Controller Addendum for Page Insights </a>  and <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">Controller Addendum for Business Tools</a>  and LinkedIn’s <a href="https://legal.linkedin.com/pages-joint-controller-addendum" target="_blank" rel="noopener noreferrer">Page Insights Joint Controller Addendum</a>  which include information regarding how our and those platforms’ responsibilities to you are allocated as controllers of your personal information;</span></li>
                <li><span>Facebook's Data Policy at <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy</a>  and LinkedIn’s Privacy Policy at <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy</a>  which include details of the legal reasons (known as ‘lawful bases’) on which each platform relies to process your personal information, together with details regarding your data protection rights;•
                </span></li>
                <li><span>Facebook’s <a href="https://www.facebook.com/help/331509497253087" target="_blank" rel="noopener noreferrer">help pages</a>  regarding its <a href="https://www.facebook.com/business/help/144825579583746?id=939256796236247" target="_blank" rel="noopener noreferrer">Page Insights</a>  and Business Tools and its <a href="https://www.facebook.com/legal/terms/businesstools/update" target="_blank" rel="noopener noreferrer">terms and conditions</a>    relating to those tools; and</span></li>
                <li><span>LinkedIn’s <a href="https://www.linkedin.com/help/linkedin/answer/4499/linkedin-page-analytics-overview?lang=en" target="_blank" rel="noopener noreferrer">help pages</a>   regarding its Page Insights and its <a href="https://www.linkedin.com/legal/sas-terms" target="_blank" rel="noopener noreferrer">terms and conditions</a>  relating to its advertising services, <a href="https://www.linkedin.com/help/lms/answer/a427660/the-linkedin-insight-tag-faqs?lang=en" target="_blank" rel="noopener noreferrer">including LinkedIn Insight Tag.</a> </span></li>
            </ul>

            <h3>9. Information sharing and disclosure</h3>
            <p>We share the information we collect or that is provided to us as follows:</p>
            <h3 className='italic'>Sharing with our Group Company</h3>

            <p>Lyphe Dispensary is part of the <a href="https://lyphegroup.com/" target="_blank" rel="noopener noreferrer">Lyphe Group,</a>  a UK-based provider of patient-focused medical cannabis solutions (our “<span className='bold'>Group Company</span>”).</p>
            <p>We share the information we collect and process about you with our Group Company for a variety of reasons. In particular:</p>
            <ul className="ul-romans">
                <li>
                    <span>Our Group Company may assist us in the performance of certain processing activities described in this policy. As the controller of your personal information, when we do share your information for these reasons, we decide why and how it is processed.</span>
                </li>
                <li>
                    <span>If, and to the extent that, your personal information is sent to our Group Company for a processing purpose that is in both our and their interests or where we make decisions together in relation to that particular processing, we will be “joint controllers” with the organisations involved. Where this applies, we and the other organisation will be jointly responsible to you under data protection laws for this processing.</span>
                </li>
                <li>
                    <span>Our Group Company may require your personal information solely for its own business use (such as for auditing or business reporting purposes). In such circumstances it will be an independent controller, separately responsible to you for that processing (and performed in accordance with its own privacy policy).</span>
                </li>
            </ul>

            <h3 className='italic'>Sharing with our Partners</h3>

            <p>We may share your personal information with the organisations listed below for the purposes we have identified above:</p>
            <ul className="ul-romans">
                <li>
                    <span>MedCannID: who cross-refer your details against a centralised database of individuals with prescribed cannabis-based pain relief medication and who issue individual cards explaining this should you get stopped by the police.</span>
                </li>
                <li>
                    <span>Project Twenty21: the international trial into medical cannabis treatment, monitored by Drug Science and with whom we may share aggregated information with (if you are a T21 candidate) for the purpose of them running that trial. This will not identify you.</span>
                </li>
                <li>
                    <span>The Medical Cannabis Clinics: cross-refer with their database for those patients that are due a follow up appointment or running out of medications and require a repeat prescription.</span>
                </li>
            </ul>
            <p>If you are a Patient, we also share your personal information with Carers and Doctors, as necessary, to fulfil prescription orders.</p>

            <h3 className='italic'>Sharing with our Suppliers</h3>
            <p>External Third Parties, who help us provide our Services. Currently, we use the following trusted Partners:</p>
            <PrivacyPolicyTable headers={secondTableHeaders} rows={secondTableRows} />
            <h3 className='italic'>International Transfers</h3>
            <p>
                Some of the information you provide to us may be transferred to countries outside the UK and European Economic Area (“<span className='bold'>EEA</span>”). These countries may not have similar data protection laws to the UK and EEA.
            </p>
            <p>
                Where we transfer your information outside of the UK and EEA in this way, we take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected in the ways required by data protection law as outlined in this policy. These steps include imposing contractual obligations on the recipient of your personal information. Please contact us using the details at the end of this policy for more information about the protections that we put in place and to obtain a copy or access to the relevant documents.
            </p>
            <p>
                If you use our Services whilst you are outside the UK and EEA, your information may be transferred outside the UK and EEA in order to provide you with those services.
            </p>

            <h3>10. How we safeguard your data</h3>
            <p>We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions and they are subject to a duty of confidentiality.</p>
            <p>We have put in place procedures to deal with any suspected personal information breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
            <h3>11. Retention and Deletion</h3>
            <p>
                We will only retain your personal information for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements.
            </p>
            <p>
                To determine the appropriate retention period for personal information, we consider the amount, nature and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
            </p>
            <p>
                As a general rule we retain your personal information for 7 years from the date our relationship with you ends, however we apply shorter/longer retention periods for the following information:
            </p>
            <ul className="ul-romans">
                <li>
                    <span>you exercise your right to have the information erased (where it applies) and we do not need to hold it in connection with any of the reasons permitted or required under the law;</span>
                </li>
                <li>
                    <span>you exercise your right to require us to retain your personal information for a period longer than our stated retention period;</span>
                </li>
                <li>
                    <span>we bring or defend a legal claim or other proceedings or receive complaints during the period we retain your personal information, in which case we will retain your personal information until those proceedings or complaints have concluded and no further appeals are possible;</span>
                </li>
                <li>
                    <span>we archive the information, in which case we will delete it in accordance with our routine deletion cycle; or</span>
                </li>
                <li>
                    <span>in limited cases, existing or future law or a court or regulator requires us to keep your personal information for a longer or shorter period.</span>
                </li>
            </ul>
            <p>In some circumstances you can ask us to delete your data by contacting us.</p>
            <p>In some circumstances we will anonymise your personal information (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
            <h3>12. Your information, your rights</h3>
            <p>
                You have a number of rights in relation to your personal information under data protection law. In relation to certain rights, we may ask you for information to verify your identity and, where applicable, to help us to search for your personal information. Except in rare cases, we will respond to you within 1 month after we have received this information or, where no such information is required, after we have received full details of your request.
            </p>
            <p>
                You can enforce your rights by contacting us, or in most cases, by deleting your account and/or by ending your use of our Website, products or Services.
            </p>
            <p>
                You have the following rights, some of which may only apply in certain circumstances:
            </p>
            <ul className="ul-romans">
                <li>
                    <span>to <span className='bold'>be informed </span>about the processing of your personal information (this is what this statement sets out to do);</span>
                </li>
                <li>
                    <span>to have your personal information <span className='bold'>corrected if it is inaccurate </span> and to have <span className='bold'>incomplete personal information completed</span>;</span>
                </li>
                <li>
                    <span>to  <span className='bold'>object to processing</span> of your personal information;</span>
                </li>
                <li>
                    <span>to  <span className='bold'>withdraw your consent </span> to processing your personal information;</span>
                </li>
                <li>
                    <span>to  <span className='bold'>restrict processing</span> of your personal information;</span>
                </li>
                <li>
                    <span>to have your personal information<span className='bold'>erased</span>;</span>
                </li>
                <li>
                    <span>to <span className='bold'>request access</span> to your personal information and information about how we process it;</span>
                </li>
                <li>
                    <span>to <span className='bold'> electronically move, copy or transfer </span> your personal information in a standard, machine-readable form; and</span>
                </li>
                <li>
                    <span>rights relating to <span className='bold'> automated decision making </span>, including profiling.</span>
                </li>
            </ul>
            <p>
                To find out more about each of your rights, please click the ✓ icon next to each right above. To exercise these rights, please contact us using the details at the end of this policy.
            </p>
            <p>
                You have the right to lodge a complaint with the UK data protection regulator. The contact details for the ICO, the data protection regulator in the UK, are available on the <a href="https://ico.org.uk/" target="_blank" rel="noopener noreferrer">ICO website</a> , where your personal information has or is being used in a way that you believe does not comply with data, however, we encourage you to contact us before making any complaint and we will seek to resolve any issues or concerns you may have.
            </p>
            <h3>
                13. Updates to this Privacy policy
            </h3>
            <p>As we further enhance our Website, our Services and your user experience, we may make changes to this policy from time to time. If we make any major changes, or any changes which directly affect the services provided to you or the data collected or processed by us, we will notify you of those changes directly. For all other changes and enhancements, we will notify you by posting an updated version on our Website. However, we encourage you to periodically review this policy for the most up to date version.</p>

        </>
    )
}


const firstTableHeaders = [
    {
        col: 4,
        text: 'Purpose /Activity'
    },
    {
        col: 4,
        text: 'Type of data'
    },
    {
        col: 4,
        text: 'Lawful basis for processing including basis of legitimate interest'
    },
]

const firstTableRows = [
    [
        {
            col: 4,
            text: 'To register your account for our Services '
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile Data</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: 'Performance of a contract'
        },
    ],
    [
        {
            col: 4,
            text: `To verify your identity and, in
            the case of Doctors, to verify
            your credentials and those of
            your clinic of GP practice`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile Data</li>
                    <li>Special Category Data (namely a facial recognition scan)</li>
                    <li>Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <div>Your Explicit Consent and, additionally, in relation to Doctors,</div>
                <ul>
                    <li>Performance of a contract; and,</li>
                    <li>Necessary for our legitimate interests</li>
                </ul>
            </div>
        },
    ],
    [
        {
            col: 4,
            text: `To manage and process your
            prescriptions`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Special Category Data (namely your Medical & Prescription Data) </li>
                    <li>Special Category Data (namely a facial recognition scan)</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Technical</li>
                    <li>Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Performance of a contract</li>
                    <li>Necessary for our legitimate interests (for running our business, provision of administration, to prevent fraud and for audit purposes) and, for Medical & Prescription Data</li>
                    <li>For the purpose of providing you with health services (in this case, managed pain relief medication), under the supervision of a superintendent pharmacist. </li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: <div>
                <div>
                    To facilitate and process your orders and the delivery of your prescriptions:
                </div>
                <ul>
                    <li>Manage payments, fees and charges</li>
                    <li>Collect and recover money owed to us</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Financial</li>
                    <li>Transaction</li>
                    <li>Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Performance of a contract</li>
                    <li>Necessary for our legitimate interests (for our audit purposes and to act as a record that prescriptions have been processed and payment has been made)</li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: `Where you are using our Services to manage prescriptions on behalf of a patient, either as a Carer or Doctor`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Special Category Data (namely the Medical & Prescription Data of your patient)</li>
                    <li>Financial</li>
                    <li>Transaction</li>
                    <li>Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Performance of a contract</li>
                    <li>Necessary for our legitimate interests (for our audit purposes) and, for Medical & Prescription Data</li>
                    <li>For the purpose of providing your patient with health services (in this case, managed pain relief medication).</li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: <div>
                <div>To manage our relationship with you which will include:</div>
                <ul>
                    <li>Notifying you about changes to our terms or privacy policy</li>
                    <li>Asking you to leave a review or take a survey</li>
                    <li>Notifying you about new releases, planned downtime and other changes to our products and Services.</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Performance of a contract</li>
                    <li>Necessary to comply with a legal obligation</li>
                    <li>Necessary for our legitimate interests (for our audit purposes and to understand how customers use our products/services)</li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: `To enable you to participate in a survey or to obtain feedback from you`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Performance of a contract</li>
                    <li>Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: `To administer and protect our business, our network and your data (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Technical</li>
                    <li>Usage</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                    <li>Necessary to comply with a legal obligation</li>
                </ul>
            </div>
        },
    ],

    [
        {
            col: 4,
            text: `To deliver relevant content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                    <li>Technical</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: `Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)`
        },
    ],

    [
        {
            col: 4,
            text: `To use data analytics to improve our products and Services, our Website, as well as our marketing, customer relationships and user experiences`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Technical</li>
                    <li>Usage</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: `Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)`
        },
    ],

    [
        {
            col: 4,
            text: `To make suggestions and recommendations to you about goods or services that may be of interest to you`
        },
        {
            col: 4,
            text: <div>
                <ul>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Usage</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                </ul>
            </div>
        },
        {
            col: 4,
            text: `Necessary for our legitimate interests (to develop our products/services and grow our business)`
        },
    ],
]

const secondTableHeaders = [
    {
        col: 6,
        text: 'Recepient / relationship to us'
    },
    {
        col: 6,
        text: 'Industry sector sub-sector'
    },
]

const secondTableRows = [
    [
        {
            col: 6,
            text: 'Advertising, PR, digital and creative agencies'
        },
        {
            col: 6,
            text: 'Media (Advertising & PR)'
        },

    ],

    [
        {
            col: 6,
            text: 'Banks, payment processors and financial services providers'
        },
        {
            col: 6,
            text: 'Finance (Banking & Payment Processing)'
        },

    ],

    [
        {
            col: 6,
            text: 'CCTV administration and monitoring service providers'
        },
        {
            col: 6,
            text: 'Surveillance (CCTV)'
        },

    ],

    [
        {
            col: 6,
            text: 'Cloud software system providers, including database, email and document management providers'
        },
        {
            col: 6,
            text: 'IT (Cloud Services)'
        },

    ],

    [
        {
            col: 6,
            text: 'Customer care/services providers'
        },
        {
            col: 6,
            text: 'Customer Services (Support)'
        },

    ],

    [
        {
            col: 6,
            text: 'Delivery and mailing services providers'
        },
        {
            col: 6,
            text: 'Logistics (Delivery Service)'
        },

    ],

    [
        {
            col: 6,
            text: 'Facilities and technology service providers including scanning and data destruction providers'
        },
        {
            col: 6,
            text: 'IT (Data Management)'
        },
    ],

    [
        {
            col: 6,
            text: 'Social media platforms'
        },
        {
            col: 6,
            text: 'Media (Social Media)'
        },
    ],

    [
        {
            col: 6,
            text: 'Gift card service providers'
        },
        {
            col: 6,
            text: 'Customer Services (Support)'
        },
    ],

    [
        {
            col: 6,
            text: 'Health and safety claims administrators and consultants'
        },
        {
            col: 6,
            text: 'Health & Safety (Claims)'
        },
    ],

    [
        {
            col: 6,
            text: 'Insurers and insurance brokers'
        },
        {
            col: 6,
            text: 'Insurance (Underwriting & Broking)'
        },
    ],

    [
        {
            col: 6,
            text: 'Legal, security and other professional advisers and consultants'
        },
        {
            col: 6,
            text: 'Professional Services (Legal & Accounting)'
        },
    ],

    [
        {
            col: 6,
            text: 'Market and customer research providers'
        },
        {
            col: 6,
            text: 'Media (Market Resarch)'
        },
    ],

    [
        {
            col: 6,
            text: 'Website and data analytics platform providers'
        },
        {
            col: 6,
            text: 'IT (Data Analytics)'
        },
    ],

    [
        {
            col: 6,
            text: 'Website and App developers'
        },
        {
            col: 6,
            text: 'IT (Software Development)'
        },
    ],

    [
        {
            col: 6,
            text: 'Website hosting services providers'
        },
        {
            col: 6,
            text: 'IT (Hosting)'
        },
    ],

    [
        {
            col: 6,
            text: 'Wifi and other communication service providers'
        },
        {
            col: 6,
            text: 'IT (Telecommunications)'
        },
    ],
]

const passwordBulletPoints = [
    {
        text: "Minimum 8 characters",
        errorString: ValidationErrorMessages.PasswordLength,
    },
    {
        text: "One number",
        errorString: ValidationErrorMessages.PasswordAtLeastOneNumber,
    },
    {
        text: "One uppercase character",
        errorString: ValidationErrorMessages.PasswordCapitalLetter,
    },
    {
        text: "One special character",
        errorString: ValidationErrorMessages.PasswordAtLeastOneSymbol,
    },
    {
        text: "One lowercase character",
        errorString: ValidationErrorMessages.PasswordLowercaseLetter,
    },
    {
        text: "No space characters",
        errorString: ValidationErrorMessages.PasswordContainsASpace,
    },
]