import React from "react";

function GenericCard({ children, className = "", appendableClassNames = "" }) {
  const resolveClass = () => {
    const suffixesArray = appendableClassNames.split(" ");

    const classNamesWithSuffixes = suffixesArray.map(suffix => {
      return `generic_card--${suffix}`
    }).join(" ");

    return classNamesWithSuffixes
  }

  return (
    <div className={`generic_card ${className} ${resolveClass()}`} >
      {children}
    </div>
  );
}

export default GenericCard;