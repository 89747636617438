import React from "react";
import { useDispatch } from "react-redux";
import {
    setClinicSearchSelectedValue as setClinicSearchPrescriptionsSelectedValue, setClinicSearchInputValue as setClinicSearchPrescriptionsInputValue, setClinicSearchParameter, setClinicSearchType,
    getPrescriptionsForOrganisation, resetPrescriptionsForClinicUser
} from "../../store/slices/prescriptionsSlice";
import {
    setClinicSearchSelectedValue as setClinicSearchProductsSelectedValue,
    setClinicSearchInputValue as setClinicSearchProductsInputValue, resetProductsForClinicUser, getProductsForClinic
} from "../../store/slices/productSlice";
import { EmployeeType, headerPageTypes } from "../../enums";
import { useSearchHandler } from "../../hooks";


const SearchOptionsForClinic = () => {
    const dispatch = useDispatch()
    const {
        searchTerm, setSearchTerm,
        currentPage,
        noPrescriptionResultsFromClinicSearch,
        noProductResultsFromClinicSearch,
        noProductResultsFromPatientSearch,
        searchListPrescriptionOptions,
        searchListProductOptions,
        currentOrganisation,
        userDetails,
        loadingPrescriptionResultsFromClinicSearch,
        loadingProductResultsFromClinicSearch,
        loadingProductResultsFromPatientSearch
    } = useSearchHandler();

    const renderEmpty = () => {
        return (currentPage === headerPageTypes.prescriptions && noPrescriptionResultsFromClinicSearch) ?
            <div className="clinic_search_field__options__section_title">
                No results
            </div> :
            ((currentPage === headerPageTypes.formulary && noProductResultsFromClinicSearch) || noProductResultsFromPatientSearch) ?
                <div className="clinic_search_field__options__section_title">
                    No results
                </div>
                :
                <></>
    }

    const renderOptions = (options, sectionTitle, onClick = () => null) => {
        return <>
            {options.length ?
                <>
                    <div className="clinic_search_field__options__section_title">
                        {sectionTitle}
                        <hr />
                    </div>
                    {
                        options.map((o, i) => {
                            return <div key={i} onClick={() => onClick(o)} className={o.isSelected ? "clinic_search_field__options__option active" : "clinic_search_field__options__option"}>
                                {o.text}
                                &nbsp;<span className="clinic_search_field__options__option_subtext">{o.subtext ? new Date(o.subtext).toLocaleDateString("en-UK") : ""}</span>
                            </div>
                        })
                    }
                </>
                :
                <></>
            }
        </>
    }

    const selectSearchResultForClinicUser = (o) => {
        const organisationId = currentOrganisation.organisationId;
        const organisationEmployeeId = currentOrganisation.employeeType === EmployeeType.OrganisationPrescriber
            ? userDetails.id
            : null;

        if (currentPage === headerPageTypes.prescriptions) {
            dispatch(setClinicSearchPrescriptionsSelectedValue(o.text));
            dispatch(setClinicSearchPrescriptionsInputValue(o.text));
            dispatch(setClinicSearchParameter(o.parameter));
            dispatch(setClinicSearchType(o.type));

            //fetch prescriptions from all tabs, with selected search term
            dispatch(resetPrescriptionsForClinicUser())

            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: "imageOnly", type: o.type, parameter: o.parameter }))
            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: "toBeAssigned", type: o.type, parameter: o.parameter }))
            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: "active", type: o.type, parameter: o.parameter }))
            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: "complete", type: o.type, parameter: o.parameter }))
            dispatch(getPrescriptionsForOrganisation({ organisationId, organisationEmployeeId, status: "reWriteRequests", type: o.type, parameter: o.parameter }))
        }

        if (currentPage === headerPageTypes.formulary) {
            dispatch(setClinicSearchProductsSelectedValue(o.text));
            dispatch(setClinicSearchProductsInputValue(o.text));

            dispatch(resetProductsForClinicUser());
            dispatch(getProductsForClinic({ searchTerm, organisationId, organisationEmployeeId, status: "toBeAssigned" }))
        }
    }

    return !noPrescriptionResultsFromClinicSearch && searchListPrescriptionOptions !== undefined && currentPage === headerPageTypes.prescriptions ?
        <>
            {renderOptions(searchListPrescriptionOptions.patients, "Patients", selectSearchResultForClinicUser)}
            {renderOptions(searchListPrescriptionOptions.prescriptionNumbers, "Prescription Number", selectSearchResultForClinicUser)}
            {renderOptions(searchListPrescriptionOptions.prescriptionIds, "Prescription Id", selectSearchResultForClinicUser)}
            {renderOptions(searchListPrescriptionOptions.prescriptionOrdersIds, "Order Id", selectSearchResultForClinicUser)}
            {renderOptions(searchListPrescriptionOptions.doctors, "Doctors", selectSearchResultForClinicUser)}
        </> :
        !noProductResultsFromClinicSearch && searchListProductOptions !== undefined && currentPage === headerPageTypes.formulary ?
            <>
                {renderOptions(searchListProductOptions.products, "Products", selectSearchResultForClinicUser)}
            </> :
            <>
                {renderEmpty()}
            </>
}

export default SearchOptionsForClinic;