import React, { useEffect } from "react";
import { BlueInfoIcon } from "../../../assets/icons";

function OrderCancelled() {
  //Create the Zendesk support widget
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5fd8aa31-21a7-4af7-9443-a1784d3f1c33';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="order_cancelled">
      <div className="order_cancelled__content">
        <div className="order_cancelled__image">
          <BlueInfoIcon />
        </div>
        <div className="order_cancelled__title">
          <p className="label--caps--normal">
            Order Cancelled
          </p>
          <h1 className="h3-a">Order Cancelled</h1>
          <p className="p5 m-t-s">
            If you were expecting to pay for this order, not to worry, an order can be cancelled by our team if an amendment was required.
          </p>
          <p className="p5 m-t-s">
            If payment is still required, we will let you know as soon as the new order is ready.
          </p>
          <p className="p5 m-t-s">
            Any questions about your prescription, get in touch at <a href={"mailto:info@lyphe.com"}>info@lyphe.com</a>
          </p>
        </div>

      </div>
    </section>
  );
}

export default OrderCancelled;