import React from "react";
import { useHistory } from "react-router-dom";

import { Logo } from "../../../assets/images";
import { CloseIcon } from "../../../assets/icons";

import { homepageUrl } from '../../../config.json';

function AuthHeader(props) {
  const history = useHistory();

  return (
    <header className="auth_header">
      <div className="auth_header__content">
        <div onClick={() => window.open(homepageUrl, 'new_window')} className="auth_header__logo--white">
          <Logo color="white" />
        </div>
        <div onClick={() => window.open(homepageUrl, 'new_window')} className="auth_header__logo--black">
          <Logo color="black" />
        </div>

        {!props.hideClose &&
          <div onClick={() => history.push("/")} className="auth_header__close">
            <p className="label">Close</p>
            <div className="close_icon">
              <div className="auth_header__logo--white">
                <CloseIcon color="white" />
              </div>
              <div className="auth_header__logo--black">
                <CloseIcon color="black" />
              </div>
            </div>
          </div>
        }
      </div>
    </header>
  );
}

export default AuthHeader;
