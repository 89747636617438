import React from 'react'
import RepeatPrescriptionFormInfoBannerIcon from '../../../assets/icons/RepeatPrescriptionFormInfoBannerIcon'

export default function RepeatPrescriptionInfoBanner({ text }) {
    return (
        <div className='repeat_prescription-banner-info'>
            <div style={{ minWidth: "24px" }}>
                <RepeatPrescriptionFormInfoBannerIcon />
            </div>
            <p>{text}</p>
        </div>
    )
}
